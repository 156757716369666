import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PaystackButton, usePaystackPayment } from "react-paystack";
// import { usePaystackPayment } from "react-paystack";
import AllCtx from "../../uti-functions/allCtx";

function PaymentDetails() {
  let navigate = useNavigate();

  const {
    baseUrl,
    user,
    userToken,
    setSending,
    tripType,
    setTheTotalPrice,
    paymentDetails,
    totalPrice,
    flightArray,
    setTheFlightArray,
    chosenFlight,
    chosenFlightID,
    setTheChosenClass,
    setTheChosenClassPrice,
    chosenClass,
    chosenClassName,
    chosenClassPrice,
    setTheChosenFlight,
    setTheChosenFlightID,
    searchBookingResponse,
    setTheSearchBookingResponse,
    setTheChosenClassName,
    setTheClassChosen,
    classChosen,
    setTheSearchResultResponse,
    searchResultResponse,
    chosenClassTax,
    chosenClassFees,
    chosenClassSurge,
    setTheChosenClassTax,
    setTheChosenClassFees,
    setTheChosenClassSurge,
    flightRefID, payStackTotalPrice
  } = AllCtx();

  const [response, setResponse] = useState("");

  const [isChecked, setIsChecked] = useState(false);

  const config = {
    email: paymentDetails.email,
    amount: +payStackTotalPrice * 100,
    publicKey: "pk_test_f85e2b9a0978fa661375128cffac1eb0aa366e6d",
    text: "Continue To Payment",
    subaccount: "ACCT_80a8dch7j4s0wxz",
    bearer: "subaccount",
    reference: flightRefID,
    metadata: {
      name: paymentDetails.name, 
      phone: paymentDetails.phone,
    },
  };

  const initializePayment = usePaystackPayment(config);

  {
  }

  function onSuccess() {
    fetch(
      `http://www.niishcloud.com/iaas2/wp-json/niishcloud_api/v1/verify_transaction?ref_id=${flightRefID}`
    )
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);

        //  IF EVERYTHING GOES WELL WITH VERIFICATION
        if (data.message === "Verification successful") {
          console.log("Payment verification successful");
          fetch(`${baseUrl}/server/api/desunapi/v1/pay_booking`, {
            method: "POST",
            body: JSON.stringify({
              _ref_id: flightRefID,
            }),
            headers: {
              "Content-Type": "application/json",
              Useremail: `${user.email}`,
              Usertoken: `${userToken}`,
            },
          })
            .then((response) => response.json())
            .then((data) => {
              // console.log(data);

              //  IF EVERYTHING GOES WELL WITH DESUN
              if (data.message === "Payment successfully processed.") {
                console.log("Payment registered with Desun successfully.");

                navigate("/success");
              } else {
                // IF SOMETHING GOES WRONG WITH DESUN 1
                navigate("/failed-payment-verification");
              }
            })
            // IF SOMETHING GOES WRONG WITH DESUN 2
            .catch((error) => {
              navigate("/failed-payment-verification");
            });
        } else {
          // IF SOMETHING GOES WRONG WITH VERIFICATION 1
          navigate("/failed-payment-verification");
        }
      })
      // IF SOMETHING GOES WRONG WITH VERIFICATION 2
      .catch((error) => {
        navigate("/failed-payment-verification");
      });
  }

  function onClose() {
    // alert("Wait! You need this oil, don't go!!!!");
  }

  function handlePayment(e) {
    // e.preventDefault();
    console.log(totalPrice);

    if (
      !paymentDetails.email ||
      paymentDetails.email.trim() === "" ||
      !totalPrice ||
      totalPrice.trim() === "" ||
      !flightRefID ||
      flightRefID.trim() === ""
    ) {
      setResponse(
        "Seems your browser refreshed, go back to pick flight again."
      );
      return;
    }
    // console.log(paymentDetails.email);
    // return
    initializePayment(onSuccess, onClose);
  }

  // const componentProps = {
  //   email: paymentDetails.email,
  //   amount: totalPrice * 100,
  //   publicKey: "pk_live_f5fdf4895f7dd40c83d22ad844104c9f4902812a",
  //   text: "Continue To Payment",
  //   subaccount: "ACCT_80a8dch7j4s0wxz",
  //   bearer: "subaccount",
  //   reference: flightRefID,
  //   onSuccess: () =>
  //     alert("Thanks for doing business with us! Come back soon!!"),
  //   onClose: () => alert("You are about to discontinue payment!"),
  // };

  return (
    <div className="md:w-[35%] px-8 md:px-0 mt-10">
      <div className="mb-3 md:mt-0 mt-3 text-pry-color font-semibold">
        <p>Payment Details</p>
      </div>
      <div className="border-2 rounded-md px-3 py-4">
        <div className="mb-3 border-2 space-y-3 px-2 py-2 rounded-md">
          <div className="flex justify-between">
            <p>Base Fare</p>
            <p>₦{chosenClassPrice}</p>
          </div>
          <div className="flex justify-between">
            <p> Surcharges</p>
            <p>₦{chosenClassSurge}</p>
          </div>
          <div className="flex justify-between">
            <p>Fees</p>
            <p>₦{chosenClassFees}</p>
          </div>

          <div className="flex justify-between">
            <p>Tax</p>
            <p>₦{chosenClassTax}</p>
          </div>
        </div>

        <hr className="mb-3" />

        {/* <div className='mb-3'>
        <div className='flex justify-between'><p>Total Fare</p> <p>₦{chosenClassPrice}</p></div>
        <div className='flex justify-between'><p>Add Ons</p> <p>₦0</p></div>
      </div> */}

        <hr className="mb-3" />

        <div className="flex justify-between">
          <p>Price You Pay</p>{" "}
          <p className="font-bold text-pry-color">₦{totalPrice}</p>
        </div>
      </div>
      <div className="mt-2 flex h-6 justify-center items-center text-sec-color">
        <p>{}</p>
      </div>
      <div>
        {/* <PaystackButton
          className="bg-sec-color w-full text-white mt-4 rounded-md py-2 hover:bg-orange-400"
          {...componentProps}
        /> */}
        <div className="mt-2 flex h-6 justify-center items-center text-sec-color">
          <p>{response}</p>
        </div>

        <div className="bg-pry-color mt-12  rounded-md py-3 pl-10 flex items-center ">
          <input
            onChange={(e) => {
              e.target.checked ? setIsChecked(true) : setIsChecked(false);
            }}
            type="checkbox"
            name=""
            id=""
          />{" "}
          <p className="ml-2 text-white text-xs">
            I agree to all Desun Air{" "}
            <span
              onClick={() => {
                window.open("/privacy-policy", "_blank");
              }}
              className="underline cursor-pointer"
            >
              Terms &amp; Conditions
            </span>
            .
          </p>
        </div>

        <button
          disabled={!isChecked}
          onClick={() => {
            handlePayment();
          }}
          className={`${
            !isChecked ? "bg-gray-400" : "bg-sec-color "
          }  w-full text-white mt-4 rounded-md py-2 `}
        >
          Continue To Payment
        </button>
      </div>
    </div>
  );
}

export default PaymentDetails;
