import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import FlyDDImage from "../../images/fly-dd-image.png";
import LilFlightIcon2 from "../../images/lil-flight-icon2.png";
import RightArrow from "../../images/right-arrow.png";
import AllCtx from "../../uti-functions/allCtx";


function FlyDropdown(props) {
const {selectTheTab3, selectTheTab4} = AllCtx();


  return (
    <div onMouseLeave={props.hoverNone} className="fly-whole-div fixed top-[4.7rem] z-30">
    <div className="fly-img-div ">
      <img src={FlyDDImage} alt="" />
    </div>
    <div className="fly-right-div">
      <div className="space-y-5 w-full">
        <div onClick={props.hoverNone} className="fly-right-div-top">
          <div>
            <img src={LilFlightIcon2} alt="" />
          </div>

              <p>Fly</p>
        
        </div>
        <div className="fly-right-div-mid">
          {/* <div onClick={props.hoverNone} className=" cursor-pointer w-1/2 text-sec-color flex items-center space-x-2">
              <Link className='no-underline text-pry-color' to='/'>
              <p className='hover:text-sec-color'>Upgrade Flight</p>
          </Link>
            <div>
              <img src={RightArrow} alt="" />
            </div>
          </div> */}
                <div onClick={props.hoverNone} className=" cursor-pointer w-1/2 flex items-center space-x-2">
              <HashLink  className='no-underline text-pry-color' to='/#tab'>
              <p onClick={selectTheTab4} className='hover:text-sec-color text-xs font-semibold'>Flight Status</p>
         </HashLink>
            <div>
              <img src={RightArrow} alt="" />
            </div>
                      </div>

          <div onClick={props.hoverNone} className=" cursor-pointer w-1/2 flex items-center space-x-2">
              <HashLink className='no-underline text-pry-color' to='/#tab'>
              <p onClick={selectTheTab3} className='hover:text-sec-color text-xs font-semibold'>Check-In</p>
          </HashLink>
            <div>
              <img src={RightArrow} alt="" />
            </div>
          </div>
        </div>
        <div className='fly-right-div-bottom' >
        {/* <div onClick={props.hoverNone} className=" cursor-pointer w-1/2 flex items-center space-x-2">
              <HashLink  className='no-underline text-pry-color' to='/#tab'>
              <p onClick={selectTheTab4} className='hover:text-sec-color'>Flight Status</p>
         </HashLink>
            <div>
              <img src={RightArrow} alt="" />
            </div>
                      </div> */}
           
           
            {/* <div onClick={props.hoverNone} className=" cursor-pointer w-1/2 flex items-center space-x-2">
            <p>Change Bookings</p>
            <div>
              <img src={RightArrow} alt="" />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  </div>
  );
}

export default FlyDropdown;
