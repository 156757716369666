import React, { useState } from "react";

import BookTripTabForm from "./BookTripTabForm";
import CheckInTabForm from "./CheckinInTabForm";
import FlightStatusTab from "./FlightStatusTab";
import ManageBookingTabForm from "./ManageBookingTabForm";
import BookTrip from "../../images/white-booktrip-mobile-icon.png";
import Manage from "../../images/manage.png";
import CheckIn from "../../images/check-in.png";
import Status from "../../images/status.png";
import AllCtx from "../../uti-functions/allCtx";



function TabHeader() {
  const {  tab1Selected,
    tab2Selected,
    tab3Selected,
    tab4Selected,
        selectTheTab1,
    selectTheTab2,
    selectTheTab3,
    selectTheTab4,} = AllCtx();


  return (

    // ${
    //   props.bookingHover ||
    //   props.flyHover ||
    //   props.aboutHover ||
    //   props.destinationHover
    //     ? "-top-[22rem]"
    //     : "-top-[19rem]"
    // }

    <div id='tab'
      className={`whole-tab-view`}
    >
      <div className="tab-header-div">
        <div
          className={`${
            tab1Selected ? "bg-white text-pry-color" : "bg-pry-color"
          } each-tab rounded-tl-md`}
          onClick={selectTheTab1}
        >
          BOOK TRIP
        </div>
        <div
          className={`${
            tab2Selected ? "bg-white text-pry-color" : "bg-pry-color"
          } each-tab`}
          onClick={selectTheTab2}
        >
          MANAGE BOOKING
        </div>
        <div
          className={`${
            tab3Selected ? "bg-white text-pry-color" : "bg-pry-color"
          } each-tab`}
          onClick={selectTheTab3}
        >
          CHECK IN
        </div>
        <div
          className={`${
            tab4Selected ? "bg-white text-pry-color" : "bg-pry-color"
          } each-tab rounded-tr-md`}
          onClick={selectTheTab4}
        >
          FLIGHT STATUS
        </div>
      </div>

      <div className="tab-header-mobile-div ">
        <div
          className={`${
            tab1Selected ? "bg-white text-pry-color" : "bg-pry-color"
          } each-tab rounded-tl-md flex-col text-center text-xs items-center`}
          onClick={selectTheTab1}
        >
         <div className='flex justify-center'> <img src={BookTrip} alt="" />  </div>
    <p>BOOK TRIP</p>
        </div>
        <div
          className={`${
            tab2Selected ? "bg-white text-pry-color" : "bg-pry-color"
          } each-tab flex-col text-center text-xs items-center`}
          onClick={selectTheTab2}
        >
            <div className='flex justify-center'> <img src={Manage} alt="" />  </div>
    <p>MANAGE</p> 
        </div>
        <div
          className={`${
            tab3Selected ? "bg-white text-pry-color" : "bg-pry-color"
          } each-tab flex-col text-center text-xs items-center`}
          onClick={selectTheTab3}
        >
         <div className='flex justify-center'> <img src={CheckIn} alt="" />  </div>
    <p>CHECK IN</p> 
        </div>
        <div 
          className={`${
            tab4Selected ? "bg-white text-pry-color" : "bg-pry-color"
          } each-tab rounded-tr-md flex-col text-center text-xs items-center`}
          onClick={selectTheTab4}
        >
          <div className='flex justify-center'> <img src={Manage} alt="" />  </div>
    <p>STATUS</p> 
        </div>
      </div>


      {tab1Selected && <BookTripTabForm />}
      {tab2Selected && <ManageBookingTabForm />}
      {tab3Selected && <CheckInTabForm />}
      {tab4Selected && <FlightStatusTab/>}
      
    </div>
  );
}

export default TabHeader;
