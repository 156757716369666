import React from "react";
import PrivateHire from "../images/service-private-hire.webp";
import Appointment from "../images/join-us-icon.png";
import { HashLink } from "react-router-hash-link";

function PrivateHirePage() {
  return (
    <div className="">
      <div className="services-header-bg text-2xl md:text-3xl lg:text-4xl">
        {" "}
        <p>Private Charter</p>{" "}
      </div>

      <div className="md:flex  px-8 md:space-x-7 pt-5 md:items-center">
        <div className="md:w-1/2 ">
          <img src={PrivateHire} alt="" />
        </div>
        <div className="md:w-1/2">
          <h2 className="text-pry-color font-bold  mb-4 text-3xl">
            <span className="text-sec-color">Desun</span> Private Charter
          </h2>
          <p className="text-justify text-pry-color text-xl md:leading-10 leading-relaxed">
            Desun Airlines shall acquire by purchase, lease or hire or in any
            other form, aircrafts, hovercrafts, helicopters and simulators. In
            addition, it repairs, overhaul, reconstructs, assembles or
            recondition the same or other ancillary machines, parts and
            accessories.
          </p>
          <HashLink
            to="/#services"
            className="no-underline text-black flex items-center space-x-3 my-8 text-sm "
          >
            <p className="cursor-pointer font-semibold hover:text-sec-color">
              Back to Services
            </p>{" "}
            <img className="h-6 w-6 cursor-pointer" src={Appointment} alt="" />
          </HashLink>
        </div>
      </div>
    </div>
  );
}

export default PrivateHirePage;
