import React, { useRef, useState } from "react";
import AllCtx from "../uti-functions/allCtx";

function ForgotPassword() {
  const { baseUrl } = AllCtx();

  const [response, setResponse] = useState("");
  const [sending, setSending] = useState(false);

  const emailRef = useRef();

  async function forgotPassword(e) {
    e.preventDefault();

    const enteredEmail = emailRef.current.value;

    if (!enteredEmail || enteredEmail.trim() === "") {
      setResponse("Fill all inputs!");
      return;
    }

    try {
      setResponse("Sending...");
      setSending(true);
      const response = await fetch(
        `${baseUrl}/server/api/desunapi/v1/forget_password`,
        {
          method: "POST",
          body: JSON.stringify({
            _email: enteredEmail,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();
      console.log(data);

      if (!response.ok) {
        data.map((dat) => {
          setResponse(dat.message);
          setSending(false);
        });
        return;
      }

      data.map((dat) => {
        setResponse(dat.message);
      });
      setSending(false);
    } catch (error) {
      console.log(error);
      setResponse("Failed to send.");
      setSending(false);
    }
  }

  return (
    <div className="">
      <div className="services-header-bg text-2xl md:text-3xl lg:text-4xl">
        {" "}
        <h2 className="text-3xl md:text-4xl">Forgot Password</h2>{" "}
      </div>

      <form
        onSubmit={forgotPassword}
        className='  bg-[url("../images/contact-bg.png")] bg-cover bg-center bg-no-repeat   mx-8 md:mx-12 lg:mx-20 shadow-lg py-8 px-4 rounded-lg my-4 text-pry-color '
      >
        <div className="flex w-full md:justify-center items-center  mb-4 ">
          <h1 className="font-semibold text-3xl md:text-4xl">
            You Forgot Your Password?
          </h1>
        </div>
        <div className="flex w-full md:justify-center items-center  mb-4 ">
          <p>
            Enter your registered email address bellow and follow the email you
            will receive shortly after.
          </p>
        </div>

        <div className="font-bold md:ml-[20%] mb-6">
          <p className="mb-2" htmlFor="email">
            Registered Email Address
          </p>
          <input
            required
            ref={emailRef}
            className="w-full md:w-[70%] border-pry-color rounded-lg"
            name="email"
            type="text"
          />
        </div>

        <div
          className={`mb-2 w-full items-center text-base flex h-6  justify-center ${
            response !== "Email sent successfully!"
              ? "text-red-700"
              : "text-green-700"
          }`}
        >
          {response}
        </div>
        <div className="mb-6 w-full flex justify-end md:pr-[24%]">
          <button
            type="submit"
            disabled={sending}
            className={`${
              sending
                ? "bg-gray-500 text-gray-300"
                : "bg-sec-color hover:bg-orange-700"
            }  text-white px-10 py-2 rounded-lg `}
          >
            Send
          </button>
        </div>
      </form>
    </div>
  );
}

export default ForgotPassword;
