import React, { useEffect, useState } from "react";
import AllCtx from "../uti-functions/allCtx";
import FlightDetailsOrder from "../components/booking/FlightDetailsOrder";
import PaymentDetails from "../components/booking/PaymentDetails";
import ManageAdultDetails from "../components/manage/ManageAdultDetails";
import ManageChildrenDetails from "../components/manage/ManageChildrenDetails";
import ManageInfantDetails from "../components/manage/ManageInfantDetails";

function ManageBookingPage() {
  const { setTheAdultArray, setTheChildrenArray, setTheInfantArray, setThePassengerFlight,
    passengerFlight } = AllCtx();

  useEffect(() => {
    if (localStorage.getItem("infantArray")) {
      setTheInfantArray(JSON.parse(localStorage.getItem("infantArray")));
    }

    if (localStorage.getItem("childrenArray")) {
      setTheChildrenArray(JSON.parse(localStorage.getItem("childrenArray")));
    }
    if (localStorage.getItem("adultArray")) {
      setTheAdultArray(JSON.parse(localStorage.getItem("adultArray")));
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("passengerFlight")) {
      setThePassengerFlight(
        JSON.parse(localStorage.getItem("passengerFlight"))
      );
    }
  }, []); 

  return (
    <div>
      <div className=" mb-10  services-header-bg text-2xl md:text-3xl lg:text-4xl">
        <p>Manage My Booking</p>
      </div>
      {/* <BookTripTabForm btnText="Search Again" />  */}
      <div className="md:flex w-full md:space-x-2 ">
        <div className=" px-8 md:w-[60%] overflow-y-scroll h-screen">
          <FlightDetailsOrder />

          <ManageAdultDetails />
          <ManageChildrenDetails />
          <ManageInfantDetails />
        </div>

        <PaymentDetails />
      </div>
    </div>
  );
}

export default ManageBookingPage;
