import React, { useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Logo from "../images/logo.svg";
import SearchIcon from "../images/search-icon.png";
import ProfileIcon from "../images/white-profile-icon.png";
import MenuIcon from "../images/white-menu-icon.png";
import CloseNav from "../images/close-nav.png";
import Triangle from "../images/triangle.png";
import Line from "../images/line.png";
import AllCtx from "../uti-functions/allCtx";
import { useNavigate } from "react-router-dom";

function Navbar(props) {
  let navigate = useNavigate();

  const {
    hoverHome,
    toggleMobileNav,
    hoverBooking,
    hoverFly,
    hoverAbout,
    hoverServices,
    hoverDestination,
    mobileNavClicked,
    homeHover,
    bookingHover,
    flyHover,
    aboutHover,
    servicesHover,
    destinationHover,
  } = props;

  const {
    isLoggedIn,
    user,
    setTheUser,
    setTheUserToken,
    userToken,
    setTheIsLoggedIn,
    showNotification,
    notificationText,
    setTheNotificationText,
    setTheShowNotification,
    baseUrl,
  } = AllCtx();

  const [canRunTimeOut, setCanRunTimeOut] = useState(true);

  async function logOut() {
    if (!user.email || !userToken) {
      setTheNotificationText("You are logged out!");
      setTheShowNotification(true);

      localStorage.removeItem("user");
      localStorage.removeItem("userToken");
      localStorage.removeItem("flightRefID");
      localStorage.removeItem("individualRefID");
      localStorage.removeItem("flightStatus");
      localStorage.removeItem("flightRefID");
      localStorage.removeItem("passengerFlight");
      localStorage.removeItem("checkInPassenger");
      localStorage.removeItem("lastLoggedIn");
      setTheUser({});
      setTheUserToken("");

      setTheIsLoggedIn(false);
      setTheShowNotification(false);
      setTheNotificationText("");
      console.log("Logged out");

      // setSigningOut(false);
      navigate("/");
      return;
    }

    try {
      setTheNotificationText("Signing out...");
      setTheShowNotification(true);

      const response = await fetch(`${baseUrl}/server/api/desunapi/v1/logout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",

          Useremail: `${user.email}`,
          Usertoken: `${userToken}`,
        },

        // body: JSON.stringify({ phone: "dddd" }),
      });

      const data = await response.json();
      console.log(data);

      if (!response.ok) {
        setTheNotificationText("Error! Something went wrong.");
        console.log(data.message || "Something went wrong!");
        if (canRunTimeOut) {
          const timer = setTimeout(() => {
            setTheShowNotification(false);
            setTheNotificationText("");
          }, 5000);
          return () => clearTimeout(timer);
        }
        return;
      }

      localStorage.removeItem("user");
      localStorage.removeItem("userToken");
      localStorage.removeItem("flightRefID");
      localStorage.removeItem("individualRefID");
      localStorage.removeItem("flightStatus");
      localStorage.removeItem("flightRefID");
      localStorage.removeItem("passengerFlight");
      localStorage.removeItem("checkInPassenger");
      localStorage.removeItem("lastLoggedIn");
      setTheUser({});
      setTheUserToken("");
      setTheIsLoggedIn(false);
      setTheNotificationText("Signed out successfully!");
      navigate("/");
      if (canRunTimeOut) {
        const timer = setTimeout(() => {
          setTheShowNotification(false);
          setTheNotificationText("");
        }, 2000);
        return () => clearTimeout(timer);
      }
      console.log("Logged out successfully!");
    } catch (error) {
      setTheNotificationText("An error occurred, please retry!");
      if (canRunTimeOut) {
        const timer = setTimeout(() => {
          setTheShowNotification(false);
          setTheNotificationText("");
        }, 2000);
        return () => clearTimeout(timer);
      }
      console.log(error);
    }
  }

  return (
    <nav className="navbar-whole-div transition duration-700">
      <div className="logo-div ">
        <Link to="/">
          <img className="w-20 md:h-14 md:w-28 h-10" src={Logo} alt="" />
        </Link>
      </div>

      <ul className="links-ul">
        <li onClick={hoverHome} onMouseOver={hoverHome} className="link-list">
          <Link className="no-underline text-white" to="/">
            <p className="mt-4">HOME</p>
          </Link>
          <div className="flex justify-center">
            <img
              className={`${homeHover ? "invisible" : "invisible"}`}
              src={Triangle}
              alt=""
            />
          </div>
        </li>
        <li
          onClick={hoverBooking}
          onMouseOver={hoverBooking}
          className={`link-list`}
        >
          <HashLink className="no-underline text-white" to="/#tab">
          <p className="mt-4">BOOKING</p>
         </HashLink>
         
          <div className="flex justify-center">
            <img
              className={`${
                bookingHover ? "md:visible invisible" : "invisible"
              }`}
              src={Triangle}
              alt=""
            />
          </div>
        </li>
        <li onClick={hoverFly} onMouseOver={hoverFly} className="link-list">
        <HashLink className="no-underline text-white" to="/#tab">
          <p className="mt-4">FLY</p>
         </HashLink>
          <div className="flex justify-center">
            <img
              className={`${flyHover ? "md:visible invisible" : "invisible"} `}
              src={Triangle}
              alt=""
            />
          </div>
        </li>
        <li onClick={hoverAbout} onMouseOver={hoverAbout} className="link-list">
          <p className="mt-4" onClick={() => {
            navigate("/about")
          }}>ABOUT</p>

          <div className="flex justify-center">
            <img
              className={`${aboutHover ? "md:visible invisible" : "invisible"}`}
              src={Triangle}
              alt=""
            />
          </div>
        </li>
        <li
          onClick={hoverServices} 
          onMouseOver={hoverServices}
          className="link-list"
        >
           <HashLink className="no-underline text-white" to="/#services">
          <p className="mt-4">SERVICES</p>
         </HashLink>

          <div className="flex justify-center">
            <img
              className={`${
                servicesHover ? "md:visible invisible" : "invisible"
              }`}
              src={Triangle}
              alt=""
            />
          </div>
        </li>
        <li
          onClick={hoverDestination}
          onMouseOver={hoverDestination}
          className="link-list"
        >
           <HashLink className="no-underline text-white" to="/#destination">
          <p className="mt-4">DESTINATIONS</p>
         </HashLink>

          <div className="flex justify-center">
            <img
              className={`${
                destinationHover ? "md:visible invisible" : "invisible"
              }`}
              src={Triangle}
              alt=""
            />
          </div>
        </li>
      </ul>
      <div className="sm:flex justify-end items-center  w-[30%] hidden space-x-2">
        {/* <div className="cursor-pointer w-4 h-4 md:w-5 md:h-5 ">
            <img className='' src={SearchIcon} alt="" />
          </div> */}
        {/* <div>
            <img className=' h-6' src={Line} alt="" />
          </div> */}
        {isLoggedIn ? (
          <div className="flex space-x-4 justify-center items-center">
            <p onClick={logOut} className="cursor-pointer ">
              LOGOUT
            </p>
            <Link className="" to="/profile">
              <div>
                <img src={ProfileIcon} alt="" />
              </div>
            </Link>
          </div>
        ) : (
          <Link to="/login" className="no-underline text-white">
            LOGIN
          </Link>
        )}
      </div>
      <div
        onClick={mobileNavClicked ? toggleMobileNav : undefined}
        className="flex items-center sm:hidden space-x-6"
      >
        {isLoggedIn ? (
          <div className="flex space-x-4 justify-center items-center">
            <p onClick={logOut} className="cursor-pointer">
              LOGOUT
            </p>{" "}
            <Link to="/profile">
              <div>
                <img src={ProfileIcon} alt="" />
              </div>
            </Link>
          </div>
        ) : (
          <Link to="/login" className="no-underline text-white">
            LOGIN
          </Link>
        )}
        {!mobileNavClicked && (
          <div className="cursor-pointer" onClick={toggleMobileNav}>
            <img src={MenuIcon} alt="" />
          </div>
        )}

        {mobileNavClicked && (
          <div className="cursor-pointer" onClick={toggleMobileNav}>
            <img className="w-6 h-6" src={CloseNav} alt="" />
          </div>
        )}
      </div>
    </nav>
  );
}

export default Navbar;
