import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { HashLink } from "react-router-hash-link";

import SliderImage1 from "../images/slider1.webp";
import SliderImage2 from "../images/slider2.webp";
import SliderImage3 from "../images/slider3.webp";

function Carousel() {
  const settings = {
    // dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5000,
    // cssEase: "linear",
  };

  const Slides = [
    {
        "image": SliderImage1,
        "link": "/#tab"
    },
    {
        "image": SliderImage2,
        "link": "/#services"
    },
    {
        "image": SliderImage3,
        "link": "../contact"
    }
  ];

  return (
    <div>
      <Slider {...settings}>
        {Slides.map((slide, i) => (
          <div key={i} className="">
            <HashLink
              to={slide.link}
            >
              <img
                className="w-screen lg:h-max-[800px] "
                src={slide.image}
                alt=""
              />
            </HashLink>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default Carousel;
