import React from 'react'

function PrivacyPolicy() {
  return (
      <div>
                <div className=" mb-10  services-header-bg pl-5 sm:pl-8 md:pl-10 lg:pl-16 text-2xl md:text-3xl lg:text-4xl">
        <p>Privacy Policy</p>
          </div>
          
          <div className='px-5 sm:px-8 md:px10 lg:px-16 leading-10 text-lg text-justify'>
              <h1>Introduction</h1>
              <p>We take your privacy seriously and are committed to protecting your personal information we collect in the course of our doing business with you. We operate in accordance with and comply with all existing privacy laws.  

You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services. 

                  Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information.  </p>
              <br />

              <h1>Information Collection </h1>
              <p>Personal information means any information that may be used to identify an individual, including, but not limited to, a first and last name, title, gender, birth date, email address, a home, postal or other physical address, other contact information, other information when needed to provide a service you requested. 

When you browse our website, you do so anonymously, unless you have previously indicated that you wish Desun Airlines to remember your login details. We do not automatically collect personal information, including your email address(s). We do log your IP address (the Internet address of your computer) to give us an idea of which part of our website you visit and how long you spend there. But we do not link your IP address to any personal information unless you have logged in to our website. 

                  Desun Airlines collects personally identifying information when you make a reservation, participate in an online survey, when you ask to be included in an email or other mailing list, or when you submit your personal information to Desun Airlines for any other reason. Occasionally, Desun Airlines receives personal information from business partners and vendors. Desun Airlines only uses such information if it has been collected in accordance with acceptable privacy practices consistent with this Policy and applicable laws. </p>
              
<br />
              <h1>How we may use your personal data </h1>
              <p>When personal information is collected, we will inform you at the point of collection the purpose for the collection (e.g. reservation, Marketing purposes, special promotions, etc.). Generally, the purposes include: </p>
              <ul className='ml-10'>
                  <li className="list-disc">To help us identify you </li>
                  <li className="list-disc">Administrative purposes </li>
                  <li className="list-disc">Improving our services </li>
                  <li className="list-disc">Statistical analysis </li>
                  <li className="list-disc">Billing and other fulfillment </li>
                  <li className="list-disc">Security vetting </li>
                  <li className="list-disc">Customer profiling and analyzing your purchase preferences </li>
                  <li className="list-disc">Fraud detection and prevention </li>
                  <li className="list-disc">To notify you of any changes to this website or our services which may affect you </li>
              </ul>

              <p>Desun Airlines will not transfer your personal information to third parties without your permission, except under the limited conditions described under the discussion entitled “Information Sharing and Disclosure” below. </p>
              <br />
              
              <h1>Information Sharing and Disclosure </h1>
              <p>Your personal information is never shared outside Desun Airlines without your permission, except under conditions explained below. In Desun Airlines, data is stored in controlled servers with restricted access. 

                  Desun Airlines may send your personal information to other companies or people under any of the following circumstances: when we have your approval to share the information; we need to share your information to provide the service or booking you have requested; we need to send the information to companies or third parties who work on behalf of Desun Airlines to provide the requested service to you (we will only provide those companies the information they need to deliver the service, and they are prohibited from using that information for any other purpose). We will also disclose your personal information if required to do so by law, to enforce our Terms and Conditions, or in urgent circumstances, to protect personal safety, the public or our websites. </p>
              <br />

              <h1>Data Security </h1>
              <p>In specific areas of our websites, Desun Airlines uses industry-standard SSL-encryption to enhance the security of data transmissions (Powered by HITIT). While we strive to protect your personal information, we cannot guarantee the security of the information you convey to us, and so we advise you to take every precaution to protect your personal data when you are on the Internet. Modify your passwords often, use a combination of letters, numbers, and/or special characters and make sure you use a secure browser. </p>

              <br />

              <h1>Changes to this Privacy Policy </h1>
              <p>This Policy is subject to occasional modification as may be necessary for Desun Airlines. If we make any significant changes in the way we use your personal information, we will make that information accessible by posting a notice on this site. We advise you visit this policy occasionally to ensure you are acquainted with the most recent version which will be applicable each time you access this website. </p> <br />


              <h1>Questions or Suggestions </h1>
              <p>If you have questions, suggestions or concerns about how we handle user data and personal information, please email us at info@desunair.com or write to us at Desun Airlines Limited, 62A, Abidjan Street, Wuse Zone 3, FCT-Abuja, Nigeria. </p>
          </div>
          
    </div>
  )
}

export default PrivacyPolicy