import React from "react";
import AllCtx from "../uti-functions/allCtx";
import FailedPayment from "../images/failed-payment.png";
import { useNavigate } from "react-router-dom";

function FailedPaymentVerification() {
  const { flightRefID } = AllCtx();
  let navigate = useNavigate();

  return (
    <div className="">
      <div className=" mb-10 justify-center  services-header-bg  text-2xl md:text-3xl lg:text-4xl ">
        <p className="text-pry-color">Payment Verification Failed</p>
      </div>

      <div className=" px-4  text-red-600 font-semibold text-lg">
        <div className=" flex justify-center items-center">
          <img className="h-20 w-20" src={FailedPayment} alt="" />
        </div>
        <div className=" flex justify-center items-center mt-4 mb-5">
          {" "}
          <p>
            We could not verify the payment for the booking{" "}
            <span className="text-pry-color font-bold">{`${flightRefID}`}</span>{" "}
          </p>
        </div>

        <div className=" flex justify-center items-center">
          {" "} 
          <p>
            Please{" "}
            <span
              className="underline text-blue-700 cursor-pointer"
              onClick={() => {
                navigate("/contact");
              }}
            >
              contact Desun Air{" "}
            </span>{" "}
            for a swift assistance.
          </p>
        </div>
        <div className="flex justify-center mt-16">
          <button
            onClick={() => {
              navigate("/contact");
            }}
            className="bg-sec-color font-bold text-white px-5 py-3 rounded-lg"
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
}

export default FailedPaymentVerification;
