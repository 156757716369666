const COUNTRY_CODES = [
  {
    name: {
      common: "Aruba",
      official: "Aruba",
      native: {
        nld: { official: "Aruba", common: "Aruba" },
        pap: { official: "Aruba", common: "Aruba" },
      },
    },
    tld: [".aw"],
    cca2: "AW",
    ccn3: "533",
    cca3: "ABW",
    cioc: "ARU",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: { AWG: { name: "Aruban florin", symbol: "\u0192" } },
    idd: { root: "+2", suffixes: ["97"] },
    capital: ["Oranjestad"],
    altSpellings: ["AW"],
    region: "Americas",
    subregion: "Caribbean",
    languages: { nld: "Dutch", pap: "Papiamento" },

    latlng: [12.5, -69.96666666],
    landlocked: false,
    borders: [],
    area: 180,
    flag: "\ud83c\udde6\ud83c\uddfc",
    demonyms: {
      eng: { f: "Aruban", m: "Aruban" },
      fra: { f: "Arubaise", m: "Arubais" },
    },
    callingCodes: ["+297"],
  },
  {
    name: {
      common: "Afghanistan",
      official: "Islamic Republic of Afghanistan",
      native: {
        prs: {
          official:
            "\u062c\u0645\u0647\u0648\u0631\u06cc \u0627\u0633\u0644\u0627\u0645\u06cc \u0627\u0641\u063a\u0627\u0646\u0633\u062a\u0627\u0646",
          common: "\u0627\u0641\u063a\u0627\u0646\u0633\u062a\u0627\u0646",
        },
        pus: {
          official:
            "\u062f \u0627\u0641\u063a\u0627\u0646\u0633\u062a\u0627\u0646 \u0627\u0633\u0644\u0627\u0645\u064a \u062c\u0645\u0647\u0648\u0631\u06cc\u062a",
          common: "\u0627\u0641\u063a\u0627\u0646\u0633\u062a\u0627\u0646",
        },
        tuk: {
          official: "Owganystan Yslam Respublikasy",
          common: "Owganystan",
        },
      },
    },
    tld: [".af"],
    cca2: "AF",
    ccn3: "004",
    cca3: "AFG",
    cioc: "AFG",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { AFN: { name: "Afghan afghani", symbol: "\u060b" } },
    idd: { root: "+9", suffixes: ["3"] },
    capital: ["Kabul"],
    altSpellings: ["AF", "Af\u0121\u0101nist\u0101n"],
    region: "Asia",
    subregion: "Southern Asia",
    languages: { prs: "Dari", pus: "Pashto", tuk: "Turkmen" },

    latlng: [33, 65],
    landlocked: true,
    borders: ["IRN", "PAK", "TKM", "UZB", "TJK", "CHN"],
    area: 652230,
    flag: "\ud83c\udde6\ud83c\uddeb",
    demonyms: {
      eng: { f: "Afghan", m: "Afghan" },
      fra: { f: "Afghane", m: "Afghan" },
    },
    callingCodes: ["+93"],
  },
  {
    name: {
      common: "Angola",
      official: "Republic of Angola",
      native: {
        por: { official: "Rep\u00fablica de Angola", common: "Angola" },
      },
    },
    tld: [".ao"],
    cca2: "AO",
    ccn3: "024",
    cca3: "AGO",
    cioc: "ANG",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { AOA: { name: "Angolan kwanza", symbol: "Kz" } },
    idd: { root: "+2", suffixes: ["44"] },
    capital: ["Luanda"],
    altSpellings: [
      "AO",
      "Rep\u00fablica de Angola",
      "\u0281\u025bpublika de an'\u0261\u0254la",
    ],
    region: "Africa",
    subregion: "Middle Africa",
    languages: { por: "Portuguese" },

    latlng: [-12.5, 18.5],
    landlocked: false,
    borders: ["COG", "COD", "ZMB", "NAM"],
    area: 1246700,
    flag: "\ud83c\udde6\ud83c\uddf4",
    demonyms: {
      eng: { f: "Angolan", m: "Angolan" },
      fra: { f: "Angolaise", m: "Angolais" },
    },
    callingCodes: ["+244"],
  },
  {
    name: {
      common: "Anguilla",
      official: "Anguilla",
      native: { eng: { official: "Anguilla", common: "Anguilla" } },
    },
    tld: [".ai"],
    cca2: "AI",
    ccn3: "660",
    cca3: "AIA",
    cioc: "",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: { XCD: { name: "Eastern Caribbean dollar", symbol: "$" } },
    idd: { root: "+1", suffixes: ["264"] },
    capital: ["The Valley"],
    altSpellings: ["AI"],
    region: "Americas",
    subregion: "Caribbean",
    languages: { eng: "English" },

    latlng: [18.25, -63.16666666],
    landlocked: false,
    borders: [],
    area: 91,
    flag: "\ud83c\udde6\ud83c\uddee",
    demonyms: {
      eng: { f: "Anguillian", m: "Anguillian" },
      fra: { f: "Anguillane", m: "Anguillan" },
    },
    callingCodes: ["+1264"],
  },
  {
    name: {
      common: "\u00c5land Islands",
      official: "\u00c5land Islands",
      native: {
        swe: { official: "Landskapet \u00c5land", common: "\u00c5land" },
      },
    },
    tld: [".ax"],
    cca2: "AX",
    ccn3: "248",
    cca3: "ALA",
    cioc: "",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: { EUR: { name: "Euro", symbol: "\u20ac" } },
    idd: { root: "+3", suffixes: ["5818"] },
    capital: ["Mariehamn"],
    altSpellings: ["AX", "Aaland", "Aland", "Ahvenanmaa"],
    region: "Europe",
    subregion: "Northern Europe",
    languages: { swe: "Swedish" },

    latlng: [60.116667, 19.9],
    landlocked: false,
    borders: [],
    area: 1580,
    flag: "\ud83c\udde6\ud83c\uddfd",
    demonyms: {
      eng: { f: "\u00c5landish", m: "\u00c5landish" },
      fra: { f: "\u00c5landaise", m: "\u00c5landais" },
    },
    callingCodes: ["+35818"],
  },
  {
    name: {
      common: "Albania",
      official: "Republic of Albania",
      native: {
        sqi: {
          official: "Republika e Shqip\u00ebris\u00eb",
          common: "Shqip\u00ebria",
        },
      },
    },
    tld: [".al"],
    cca2: "AL",
    ccn3: "008",
    cca3: "ALB",
    cioc: "ALB",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { ALL: { name: "Albanian lek", symbol: "L" } },
    idd: { root: "+3", suffixes: ["55"] },
    capital: ["Tirana"],
    altSpellings: ["AL", "Shqip\u00ebri", "Shqip\u00ebria", "Shqipnia"],
    region: "Europe",
    subregion: "Southeast Europe",
    languages: { sqi: "Albanian" },

    latlng: [41, 20],
    landlocked: false,
    borders: ["MNE", "GRC", "MKD", "UNK"],
    area: 28748,
    flag: "\ud83c\udde6\ud83c\uddf1",
    demonyms: {
      eng: { f: "Albanian", m: "Albanian" },
      fra: { f: "Albanaise", m: "Albanais" },
    },
    callingCodes: ["+355"],
  },
  {
    name: {
      common: "Andorra",
      official: "Principality of Andorra",
      native: { cat: { official: "Principat d'Andorra", common: "Andorra" } },
    },
    tld: [".ad"],
    cca2: "AD",
    ccn3: "020",
    cca3: "AND",
    cioc: "AND",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { EUR: { name: "Euro", symbol: "\u20ac" } },
    idd: { root: "+3", suffixes: ["76"] },
    capital: ["Andorra la Vella"],
    altSpellings: ["AD", "Principality of Andorra", "Principat d'Andorra"],
    region: "Europe",
    subregion: "Southern Europe",
    languages: { cat: "Catalan" },

    latlng: [42.5, 1.5],
    landlocked: true,
    borders: ["FRA", "ESP"],
    area: 468,
    flag: "\ud83c\udde6\ud83c\udde9",
    demonyms: {
      eng: { f: "Andorran", m: "Andorran" },
      fra: { f: "Andorrane", m: "Andorran" },
    },
    callingCodes: ["+376"],
  },
  {
    name: {
      common: "United Arab Emirates",
      official: "United Arab Emirates",
      native: {
        ara: {
          official:
            "\u0627\u0644\u0625\u0645\u0627\u0631\u0627\u062a \u0627\u0644\u0639\u0631\u0628\u064a\u0629 \u0627\u0644\u0645\u062a\u062d\u062f\u0629",
          common:
            "\u062f\u0648\u0644\u0629 \u0627\u0644\u0625\u0645\u0627\u0631\u0627\u062a \u0627\u0644\u0639\u0631\u0628\u064a\u0629 \u0627\u0644\u0645\u062a\u062d\u062f\u0629",
        },
      },
    },
    tld: [".ae", "\u0627\u0645\u0627\u0631\u0627\u062a."],
    cca2: "AE",
    ccn3: "784",
    cca3: "ARE",
    cioc: "UAE",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: {
      AED: { name: "United Arab Emirates dirham", symbol: "\u062f.\u0625" },
    },
    idd: { root: "+9", suffixes: ["71"] },
    capital: ["Abu Dhabi"],
    altSpellings: ["AE", "UAE", "Emirates"],
    region: "Asia",
    subregion: "Western Asia",
    languages: { ara: "Arabic" },
    translations: {
      ces: {
        official: "Spojen\u00e9 arabsk\u00e9 emir\u00e1ty",
        common: "Spojen\u00e9 arabsk\u00e9 emir\u00e1ty",
      },
      deu: {
        official: "Vereinigte Arabische Emirate",
        common: "Vereinigte Arabische Emirate",
      },
      est: {
        official: "Araabia \u00dchendemiraadid",
        common: "Araabia \u00dchendemiraadid",
      },
      fin: {
        official: "Yhdistyneet arabiemiirikunnat",
        common: "Arabiemiraatit",
      },
      fra: {
        official: "\u00c9mirats arabes unis",
        common: "\u00c9mirats arabes unis",
      },
      hrv: {
        official: "Ujedinjeni Arapski Emirati",
        common: "Ujedinjeni Arapski Emirati",
      },
      hun: {
        official: "Egyes\u00fclt Arab Em\u00edrs\u00e9gek",
        common: "Egyes\u00fclt Arab Em\u00edrs\u00e9gek",
      },
      ita: { official: "Emirati Arabi Uniti", common: "Emirati Arabi Uniti" },
      jpn: {
        official: "\u30a2\u30e9\u30d6\u9996\u9577\u56fd\u9023\u90a6",
        common: "\u30a2\u30e9\u30d6\u9996\u9577\u56fd\u9023\u90a6",
      },
      kor: {
        official: "\uc544\ub78d \ud1a0\ud6c4\uad6d \uc5f0\ubc29",
        common: "\uc544\ub78d\uc5d0\ubbf8\ub9ac\ud2b8",
      },
      nld: {
        official: "Verenigde Arabische Emiraten",
        common: "Verenigde Arabische Emiraten",
      },
      per: {
        official:
          "\u0627\u0645\u0627\u0631\u0627\u062a \u0645\u062a\u062d\u062f\u0647 \u0639\u0631\u0628\u06cc",
        common: "\u0627\u0645\u0627\u0631\u0627\u062a",
      },
      pol: {
        official: "Zjednoczone Emiraty Arabskie",
        common: "Zjednoczone Emiraty Arabskie",
      },
      por: {
        official: "Emirados \u00c1rabes Unidos",
        common: "Emirados \u00c1rabes Unidos",
      },
      rus: {
        official:
          "\u041e\u0431\u044a\u0435\u0434\u0438\u043d\u0435\u043d\u043d\u044b\u0435 \u0410\u0440\u0430\u0431\u0441\u043a\u0438\u0435 \u042d\u043c\u0438\u0440\u0430\u0442\u044b",
        common:
          "\u041e\u0431\u044a\u0435\u0434\u0438\u043d\u0451\u043d\u043d\u044b\u0435 \u0410\u0440\u0430\u0431\u0441\u043a\u0438\u0435 \u042d\u043c\u0438\u0440\u0430\u0442\u044b",
      },
      slk: {
        official: "Spojen\u00e9 arabsk\u00e9 emir\u00e1ty",
        common: "Spojen\u00e9 arabsk\u00e9 emir\u00e1ty",
      },
      spa: {
        official: "Emiratos \u00c1rabes Unidos",
        common: "Emiratos \u00c1rabes Unidos",
      },
      swe: {
        official: "F\u00f6renade Arabemiraten",
        common: "F\u00f6renade Arabemiraten",
      },
      urd: {
        official:
          "\u0645\u062a\u062d\u062f\u06c1 \u0639\u0631\u0628 \u0627\u0645\u0627\u0631\u0627\u062a",
        common:
          "\u0645\u062a\u062d\u062f\u06c1 \u0639\u0631\u0628 \u0627\u0645\u0627\u0631\u0627\u062a",
      },
      zho: {
        official: "\u963f\u62c9\u4f2f\u8054\u5408\u914b\u957f\u56fd",
        common: "\u963f\u62c9\u4f2f\u8054\u5408\u914b\u957f\u56fd",
      },
    },
    latlng: [24, 54],
    landlocked: false,
    borders: ["OMN", "SAU"],
    area: 83600,
    flag: "\ud83c\udde6\ud83c\uddea",
    demonyms: {
      eng: { f: "Emirati", m: "Emirati" },
      fra: { f: "Emirienne", m: "Emirien" },
    },
    callingCodes: ["+971"],
  },
  {
    name: {
      common: "Argentina",
      official: "Argentine Republic",
      native: {
        grn: { official: "Argentine Republic", common: "Argentina" },
        spa: { official: "Rep\u00fablica Argentina", common: "Argentina" },
      },
    },
    tld: [".ar"],
    cca2: "AR",
    ccn3: "032",
    cca3: "ARG",
    cioc: "ARG",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { ARS: { name: "Argentine peso", symbol: "$" } },
    idd: { root: "+5", suffixes: ["4"] },
    capital: ["Buenos Aires"],
    altSpellings: ["AR", "Argentine Republic", "Rep\u00fablica Argentina"],
    region: "Americas",
    subregion: "South America",
    languages: { grn: "Guaran\u00ed", spa: "Spanish" },
    translations: {
      ces: { official: "Argentinsk\u00e1 republika", common: "Argentina" },
      cym: { official: "Gweriniaeth yr Ariannin", common: "Ariannin" },
      deu: { official: "Argentinische Republik", common: "Argentinien" },
      est: { official: "Argentina Vabariik", common: "Argentina" },
      fin: { official: "Argentiinan tasavalta", common: "Argentiina" },
      fra: { official: "R\u00e9publique argentine", common: "Argentine" },
      hrv: { official: "Argentinski Republika", common: "Argentina" },
      hun: {
        official: "Argentin K\u00f6zt\u00e1rsas\u00e1g",
        common: "Argent\u00edna",
      },
      ita: { official: "Repubblica Argentina", common: "Argentina" },
      jpn: {
        official: "\u30a2\u30eb\u30bc\u30f3\u30c1\u30f3\u5171\u548c\u56fd",
        common: "\u30a2\u30eb\u30bc\u30f3\u30c1\u30f3",
      },
      kor: {
        official: "\uc544\ub974\ud5e8\ud2f0\ub098 \uacf5\ud654\uad6d",
        common: "\uc544\ub974\ud5e8\ud2f0\ub098",
      },
      nld: { official: "Argentijnse Republiek", common: "Argentini\u00eb" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0622\u0631\u0698\u0627\u0646\u062a\u06cc\u0646",
        common: "\u0622\u0631\u0698\u0627\u0646\u062a\u06cc\u0646",
      },
      pol: { official: "Republika Argenty\u0144ska", common: "Argentyna" },
      por: { official: "Rep\u00fablica Argentina", common: "Argentina" },
      rus: {
        official:
          "\u0410\u0440\u0433\u0435\u043d\u0442\u0438\u043d\u0441\u043a\u0430\u044f \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430",
        common: "\u0410\u0440\u0433\u0435\u043d\u0442\u0438\u043d\u0430",
      },
      slk: { official: "Argent\u00ednska republika", common: "Argent\u00edna" },
      spa: { official: "Rep\u00fablica Argentina", common: "Argentina" },
      swe: { official: "Republiken Argentina", common: "Argentina" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0627\u0631\u062c\u0646\u0679\u0627\u0626\u0646",
        common: "\u0627\u0631\u062c\u0646\u0679\u0627\u0626\u0646",
      },
      zho: {
        official: "\u963f\u6839\u5ef7\u5171\u548c\u56fd",
        common: "\u963f\u6839\u5ef7",
      },
    },
    latlng: [-34, -64],
    landlocked: false,
    borders: ["BOL", "BRA", "CHL", "PRY", "URY"],
    area: 2780400,
    flag: "\ud83c\udde6\ud83c\uddf7",
    demonyms: {
      eng: { f: "Argentine", m: "Argentine" },
      fra: { f: "Argentine", m: "Argentin" },
    },
    callingCodes: ["+54"],
  },
  {
    name: {
      common: "Armenia",
      official: "Republic of Armenia",
      native: {
        hye: {
          official:
            "\u0540\u0561\u0575\u0561\u057d\u057f\u0561\u0576\u056b \u0540\u0561\u0576\u0580\u0561\u057a\u0565\u057f\u0578\u0582\u0569\u0575\u0578\u0582\u0576",
          common: "\u0540\u0561\u0575\u0561\u057d\u057f\u0561\u0576",
        },
      },
    },
    tld: [".am"],
    cca2: "AM",
    ccn3: "051",
    cca3: "ARM",
    cioc: "ARM",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { AMD: { name: "Armenian dram", symbol: "\u058f" } },
    idd: { root: "+3", suffixes: ["74"] },
    capital: ["Yerevan"],
    altSpellings: [
      "AM",
      "Hayastan",
      "Republic of Armenia",
      "\u0540\u0561\u0575\u0561\u057d\u057f\u0561\u0576\u056b \u0540\u0561\u0576\u0580\u0561\u057a\u0565\u057f\u0578\u0582\u0569\u0575\u0578\u0582\u0576",
    ],
    region: "Asia",
    subregion: "Western Asia",
    languages: { hye: "Armenian" },
    translations: {
      ces: { official: "Arm\u00e9nsk\u00e1 republika", common: "Arm\u00e9nie" },
      cym: { official: "Gweriniaeth Armenia", common: "Armenia" },
      deu: { official: "Republik Armenien", common: "Armenien" },
      est: { official: "Armeenia Vabariik", common: "Armeenia" },
      fin: { official: "Armenian tasavalta", common: "Armenia" },
      fra: {
        official: "R\u00e9publique d'Arm\u00e9nie",
        common: "Arm\u00e9nie",
      },
      hrv: { official: "Republika Armenija", common: "Armenija" },
      hun: {
        official: "\u00d6rm\u00e9nyorsz\u00e1g",
        common: "\u00d6rm\u00e9nyorsz\u00e1g",
      },
      ita: { official: "Repubblica di Armenia", common: "Armenia" },
      jpn: {
        official: "\u30a2\u30eb\u30e1\u30cb\u30a2\u5171\u548c\u56fd",
        common: "\u30a2\u30eb\u30e1\u30cb\u30a2",
      },
      kor: {
        official: "\uc544\ub974\uba54\ub2c8\uc544 \uacf5\ud654\uad6d",
        common: "\uc544\ub974\uba54\ub2c8\uc544",
      },
      nld: { official: "Republiek Armeni\u00eb", common: "Armeni\u00eb" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0627\u0631\u0645\u0646\u0633\u062a\u0627\u0646",
        common: "\u0627\u0631\u0645\u0646\u0633\u062a\u0627\u0646",
      },
      pol: { official: "Republika Armenii", common: "Armenia" },
      por: {
        official: "Rep\u00fablica da Arm\u00e9nia",
        common: "Arm\u00e9nia",
      },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0410\u0440\u043c\u0435\u043d\u0438\u044f",
        common: "\u0410\u0440\u043c\u0435\u043d\u0438\u044f",
      },
      slk: { official: "Arm\u00e9nska republika", common: "Arm\u00e9nsko" },
      spa: { official: "Rep\u00fablica de Armenia", common: "Armenia" },
      swe: { official: "Republiken Armenien", common: "Armenien" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0622\u0631\u0645\u06cc\u0646\u06cc\u0627",
        common: "\u0622\u0631\u0645\u06cc\u0646\u06cc\u0627",
      },
      zho: {
        official: "\u4e9a\u7f8e\u5c3c\u4e9a\u5171\u548c\u56fd",
        common: "\u4e9a\u7f8e\u5c3c\u4e9a",
      },
    },
    latlng: [40, 45],
    landlocked: true,
    borders: ["AZE", "GEO", "IRN", "TUR"],
    area: 29743,
    flag: "\ud83c\udde6\ud83c\uddf2",
    demonyms: {
      eng: { f: "Armenian", m: "Armenian" },
      fra: { f: "Arm\u00e9nienne", m: "Arm\u00e9nien" },
    },
    callingCodes: ["+374"],
  },
  {
    name: {
      common: "American Samoa",
      official: "American Samoa",
      native: {
        eng: { official: "American Samoa", common: "American Samoa" },
        smo: { official: "S\u0101moa Amelika", common: "S\u0101moa Amelika" },
      },
    },
    tld: [".as"],
    cca2: "AS",
    ccn3: "016",
    cca3: "ASM",
    cioc: "ASA",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: { USD: { name: "United States dollar", symbol: "$" } },
    idd: { root: "+1", suffixes: ["684"] },
    capital: ["Pago Pago"],
    altSpellings: [
      "AS",
      "Amerika S\u0101moa",
      "Amelika S\u0101moa",
      "S\u0101moa Amelika",
    ],
    region: "Oceania",
    subregion: "Polynesia",
    languages: { eng: "English", smo: "Samoan" },
    translations: {
      ces: { official: "Americk\u00e1 Samoa", common: "Americk\u00e1 Samoa" },
      deu: { official: "Amerikanisch-Samoa", common: "Amerikanisch-Samoa" },
      est: { official: "Ameerika Samoa", common: "Ameerika Samoa" },
      fin: { official: "Amerikan Samoa", common: "Amerikan Samoa" },
      fra: {
        official: "Samoa am\u00e9ricaines",
        common: "Samoa am\u00e9ricaines",
      },
      hrv: { official: "ameri\u010dka Samoa", common: "Ameri\u010dka Samoa" },
      hun: { official: "Szamoa", common: "Szamoa" },
      ita: { official: "Samoa americane", common: "Samoa Americane" },
      jpn: {
        official: "\u7c73\u30b5\u30e2\u30a2",
        common: "\u30a2\u30e1\u30ea\u30ab\u9818\u30b5\u30e2\u30a2",
      },
      kor: {
        official: "\uc544\uba54\ub9ac\uce78\uc0ac\ubaa8\uc544",
        common: "\uc544\uba54\ub9ac\uce78\uc0ac\ubaa8\uc544",
      },
      nld: { official: "Amerikaans Samoa", common: "Amerikaans Samoa" },
      per: {
        official:
          "\u0633\u0627\u0645\u0648\u0622\u06cc \u0622\u0645\u0631\u06cc\u06a9\u0627",
        common:
          "\u0633\u0627\u0645\u0648\u0622\u06cc \u0622\u0645\u0631\u06cc\u06a9\u0627",
      },
      pol: {
        official: "Samoa Ameryka\u0144skie",
        common: "Samoa Ameryka\u0144skie",
      },
      por: { official: "Samoa americana", common: "Samoa Americana" },
      rus: {
        official:
          "\u0430\u043c\u0435\u0440\u0438\u043a\u0430\u043d\u0441\u043a\u043e\u0435 \u0421\u0430\u043c\u043e\u0430",
        common:
          "\u0410\u043c\u0435\u0440\u0438\u043a\u0430\u043d\u0441\u043a\u043e\u0435 \u0421\u0430\u043c\u043e\u0430",
      },
      slk: { official: "Americk\u00e1 Samoa", common: "Americk\u00e1 Samoa" },
      spa: { official: "Samoa Americana", common: "Samoa Americana" },
      swe: { official: "Amerikanska Samoa", common: "Amerikanska Samoa" },
      urd: {
        official:
          "\u0627\u0645\u0631\u06cc\u06a9\u06cc \u0633\u0645\u0648\u0648\u0627",
        common:
          "\u0627\u0645\u0631\u06cc\u06a9\u06cc \u0633\u0645\u0648\u0648\u0627",
      },
      zho: {
        official: "\u7f8e\u5c5e\u8428\u6469\u4e9a",
        common: "\u7f8e\u5c5e\u8428\u6469\u4e9a",
      },
    },
    latlng: [-14.33333333, -170],
    landlocked: false,
    borders: [],
    area: 199,
    flag: "\ud83c\udde6\ud83c\uddf8",
    demonyms: {
      eng: { f: "American Samoan", m: "American Samoan" },
      fra: { f: "Samoane", m: "Samoan" },
    },
    callingCodes: ["+1684"],
  },
  {
    name: { common: "Antarctica", official: "Antarctica", native: {} },
    tld: [".aq"],
    cca2: "AQ",
    ccn3: "010",
    cca3: "ATA",
    cioc: "",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [],
    idd: { root: "", suffixes: [] },
    capital: [""],
    altSpellings: ["AQ"],
    region: "Antarctic",
    subregion: "",
    languages: {},
    translations: {
      ces: { official: "Antarktida", common: "Antarktida" },
      cym: { official: "Yr Antarctig", common: "Yr Antarctig" },
      deu: { official: "Antarktika", common: "Antarktis" },
      est: { official: "Antarktika", common: "Antarktika" },
      fin: { official: "Etel\u00e4manner", common: "Etel\u00e4manner" },
      fra: { official: "Antarctique", common: "Antarctique" },
      hrv: { official: "Antarktika", common: "Antarktika" },
      hun: { official: "Antarktisz", common: "Antarktisz" },
      ita: { official: "Antartide", common: "Antartide" },
      jpn: { official: "\u5357\u6975\u5927\u9678", common: "\u5357\u6975" },
      kor: { official: "\ub0a8\uadf9", common: "\ub0a8\uadf9" },
      nld: { official: "Antarctica", common: "Antarctica" },
      per: {
        official: "\u062c\u0646\u0648\u0628\u06af\u0627\u0646",
        common: "\u062c\u0646\u0648\u0628\u06af\u0627\u0646",
      },
      pol: { official: "Antarktyka", common: "Antarktyka" },
      por: { official: "Ant\u00e1rtica", common: "Ant\u00e1rtida" },
      rus: {
        official:
          "\u0410\u043d\u0442\u0430\u0440\u043a\u0442\u0438\u0434\u0430",
        common: "\u0410\u043d\u0442\u0430\u0440\u043a\u0442\u0438\u0434\u0430",
      },
      slk: { official: "Antarkt\u00edda", common: "Antarkt\u00edda" },
      spa: { official: "Ant\u00e1rtida", common: "Ant\u00e1rtida" },
      swe: { official: "Antarktis", common: "Antarktis" },
      urd: {
        official: "\u0627\u0646\u0679\u0627\u0631\u06a9\u0679\u06a9\u0627",
        common: "\u0627\u0646\u0679\u0627\u0631\u06a9\u0679\u06a9\u0627",
      },
      zho: { official: "\u5357\u6781\u6d32", common: "\u5357\u6781\u6d32" },
    },
    latlng: [-90, 0],
    landlocked: false,
    borders: [],
    area: 14000000,
    flag: "\ud83c\udde6\ud83c\uddf6",
    demonyms: {
      eng: { f: "Antarctican", m: "Antarctican" },
      fra: { f: "Antarcticaine", m: "Antarcticain" },
    },
    callingCodes: [],
  },
  {
    name: {
      common: "French Southern and Antarctic Lands",
      official: "Territory of the French Southern and Antarctic Lands",
      native: {
        fra: {
          official:
            "Territoire des Terres australes et antarctiques fran\u00e7aises",
          common: "Terres australes et antarctiques fran\u00e7aises",
        },
      },
    },
    tld: [".tf"],
    cca2: "TF",
    ccn3: "260",
    cca3: "ATF",
    cioc: "",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: { EUR: { name: "Euro", symbol: "\u20ac" } },
    idd: { root: "+2", suffixes: ["62"] },
    capital: ["Port-aux-Fran\u00e7ais"],
    altSpellings: ["TF", "French Southern Territories"],
    region: "Antarctic",
    subregion: "",
    languages: { fra: "French" },
    translations: {
      ces: {
        official:
          "Teritorium Francouzsk\u00e1 ji\u017en\u00ed a antarktick\u00e1 \u00fazem\u00ed",
        common:
          "Francouzsk\u00e1 ji\u017en\u00ed a antarktick\u00e1 \u00fazem\u00ed",
      },
      deu: {
        official: "Gebiet der Franz\u00f6sisch S\u00fcd- und Antarktisgebiete",
        common: "Franz\u00f6sische S\u00fcd- und Antarktisgebiete",
      },
      est: {
        official: "Prantsuse L\u00f5unaalad",
        common: "Prantsuse L\u00f5unaalad",
      },
      fin: {
        official: "Ranskan etel\u00e4iset ja antarktiset alueet",
        common: "Ranskan etel\u00e4iset ja antarktiset alueet",
      },
      fra: {
        official:
          "Territoire des Terres australes et antarctiques fran\u00e7aises",
        common: "Terres australes et antarctiques fran\u00e7aises",
      },
      hrv: {
        official:
          "Teritoriju Francuski ju\u017eni i antarkti\u010dki teritoriji",
        common: "Francuski ju\u017eni i antarkti\u010dki teritoriji",
      },
      hun: {
        official: "Francia d\u00e9li \u00e9s antarktiszi ter\u00fcletek",
        common: "Francia d\u00e9li \u00e9s antarktiszi ter\u00fcletek",
      },
      ita: {
        official: "Territorio della australi e antartiche francesi Terre",
        common: "Territori Francesi del Sud",
      },
      jpn: {
        official:
          "\u30d5\u30e9\u30f3\u30b9\u9818\u5357\u65b9\u00b7\u5357\u6975\u5730\u57df\u306e\u9818\u571f",
        common:
          "\u30d5\u30e9\u30f3\u30b9\u9818\u5357\u65b9\u30fb\u5357\u6975\u5730\u57df",
      },
      kor: {
        official:
          "\ud504\ub791\uc2a4\ub839 \ub0a8\ubd80\uc640 \ub0a8\uadf9 \uc9c0\uc5ed",
        common:
          "\ud504\ub791\uc2a4\ub839 \ub0a8\ubd80\uc640 \ub0a8\uadf9 \uc9c0\uc5ed",
      },
      nld: {
        official:
          "Grondgebied van de Franse Zuidelijke en Antarctische gebieden",
        common: "Franse Gebieden in de zuidelijke Indische Oceaan",
      },
      per: {
        official:
          "\u0633\u0631\u0632\u0645\u06cc\u0646\u200c\u0647\u0627\u06cc \u062c\u0646\u0648\u0628\u06cc \u0648 \u062c\u0646\u0648\u0628\u06af\u0627\u0646\u06cc \u0641\u0631\u0627\u0646\u0633\u0647",
        common:
          "\u0633\u0631\u0632\u0645\u06cc\u0646\u200c\u0647\u0627\u06cc \u062c\u0646\u0648\u0628\u06cc \u0648 \u062c\u0646\u0648\u0628\u06af\u0627\u0646\u06cc \u0641\u0631\u0627\u0646\u0633\u0647",
      },
      pol: {
        official: "Francuskie Terytoria Po\u0142udniowe i Antarktyczne",
        common: "Francuskie Terytoria Po\u0142udniowe i Antarktyczne",
      },
      por: {
        official: "Territ\u00f3rio do Sul e Ant\u00e1rtica Francesa",
        common: "Terras Austrais e Ant\u00e1rticas Francesas",
      },
      rus: {
        official:
          "\u0422\u0435\u0440\u0440\u0438\u0442\u043e\u0440\u0438\u044f \u0424\u0440\u0430\u043d\u0446\u0443\u0437\u0441\u043a\u0438\u0435 \u042e\u0436\u043d\u044b\u0435 \u0438 \u0410\u043d\u0442\u0430\u0440\u043a\u0442\u0438\u0447\u0435\u0441\u043a\u0438\u0435 \u0437\u0435\u043c\u043b\u0438",
        common:
          "\u0424\u0440\u0430\u043d\u0446\u0443\u0437\u0441\u043a\u0438\u0435 \u042e\u0436\u043d\u044b\u0435 \u0438 \u0410\u043d\u0442\u0430\u0440\u043a\u0442\u0438\u0447\u0435\u0441\u043a\u0438\u0435 \u0442\u0435\u0440\u0440\u0438\u0442\u043e\u0440\u0438\u0438",
      },
      slk: {
        official:
          "Franc\u00fazske ju\u017en\u00e9 a antarktick\u00e9 \u00fazemia",
        common:
          "Franc\u00fazske ju\u017dn\u00e9 a antarktick\u00e9 \u00fazemia",
      },
      spa: {
        official:
          "Territorio del Franc\u00e9s Tierras australes y ant\u00e1rticas",
        common: "Tierras Australes y Ant\u00e1rticas Francesas",
      },
      swe: {
        official: "Franska syd- och Antarktisterritorierna",
        common: "Franska s\u00f6dra territorierna",
      },
      urd: {
        official:
          "\u0633\u0631\u0632\u0645\u06cc\u0646\u0650 \u062c\u0646\u0648\u0628\u06cc \u0641\u0631\u0627\u0646\u0633\u06cc\u0633\u06cc\u06c1 \u0648 \u0627\u0646\u0679\u0627\u0631\u06a9\u0679\u06cc\u06a9\u06c1",
        common:
          "\u0633\u0631\u0632\u0645\u06cc\u0646 \u062c\u0646\u0648\u0628\u06cc \u0641\u0631\u0627\u0646\u0633\u06cc\u0633\u06cc\u06c1 \u0648 \u0627\u0646\u0679\u0627\u0631\u06a9\u0679\u06cc\u06a9\u0627",
      },
      zho: {
        official: "\u6cd5\u56fd\u5357\u90e8\u548c\u5357\u6781\u571f\u5730",
        common: "\u6cd5\u56fd\u5357\u90e8\u548c\u5357\u6781\u571f\u5730",
      },
    },
    latlng: [-49.25, 69.167],
    landlocked: false,
    borders: [],
    area: 7747,
    flag: "\ud83c\uddf9\ud83c\uddeb",
    demonyms: {
      eng: { f: "French", m: "French" },
      fra: { f: "Fran\u00e7aise", m: "Fran\u00e7ais" },
    },
    callingCodes: ["+262"],
  },
  {
    name: {
      common: "Antigua and Barbuda",
      official: "Antigua and Barbuda",
      native: {
        eng: { official: "Antigua and Barbuda", common: "Antigua and Barbuda" },
      },
    },
    tld: [".ag"],
    cca2: "AG",
    ccn3: "028",
    cca3: "ATG",
    cioc: "ANT",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { XCD: { name: "Eastern Caribbean dollar", symbol: "$" } },
    idd: { root: "+1", suffixes: ["268"] },
    capital: ["Saint John's"],
    altSpellings: ["AG"],
    region: "Americas",
    subregion: "Caribbean",
    languages: { eng: "English" },
    translations: {
      ces: { official: "Antigua a Barbuda", common: "Antigua a Barbuda" },
      cym: { official: "Antigwa a Barbiwda", common: "Antigwa a Barbiwda" },
      deu: { official: "Antigua und Barbuda", common: "Antigua und Barbuda" },
      est: { official: "Antigua ja Barbuda", common: "Antigua ja Barbuda" },
      fin: { official: "Antigua ja Barbuda", common: "Antigua ja Barbuda" },
      fra: { official: "Antigua -et-Barbuda", common: "Antigua-et-Barbuda" },
      hrv: { official: "Antigva i Barbuda", common: "Antigva i Barbuda" },
      hun: {
        official: "Antigua \u00e9s Barbuda",
        common: "Antigua \u00e9s Barbuda",
      },
      ita: { official: "Antigua e Barbuda", common: "Antigua e Barbuda" },
      jpn: {
        official:
          "\u30a2\u30f3\u30c1\u30b0\u30a2\u30d0\u30fc\u30d6\u30fc\u30c0",
        common:
          "\u30a2\u30f3\u30c6\u30a3\u30b0\u30a2\u30fb\u30d0\u30fc\u30d6\u30fc\u30c0",
      },
      kor: {
        official: "\uc564\ud2f0\uac00 \ubc14\ubd80\ub2e4",
        common: "\uc564\ud2f0\uac00 \ubc14\ubd80\ub2e4",
      },
      nld: { official: "Antigua en Barbuda", common: "Antigua en Barbuda" },
      per: {
        official:
          "\u0622\u0646\u062a\u06cc\u06af\u0648\u0627 \u0648 \u0628\u0627\u0631\u0628\u0648\u062f\u0627",
        common:
          "\u0622\u0646\u062a\u06cc\u06af\u0648\u0627 \u0648 \u0628\u0627\u0631\u0628\u0648\u062f\u0627",
      },
      pol: { official: "Antigua i Barbuda", common: "Antigua i Barbuda" },
      por: { official: "Antigua e Barbuda", common: "Ant\u00edgua e Barbuda" },
      rus: {
        official:
          "\u0410\u043d\u0442\u0438\u0433\u0443\u0430 \u0438 \u0411\u0430\u0440\u0431\u0443\u0434\u0430",
        common:
          "\u0410\u043d\u0442\u0438\u0433\u0443\u0430 \u0438 \u0411\u0430\u0440\u0431\u0443\u0434\u0430",
      },
      slk: { official: "Antigua a Barbuda", common: "Antigua a Barbuda" },
      spa: { official: "Antigua y Barbuda", common: "Antigua y Barbuda" },
      swe: { official: "Antigua och Barbuda", common: "Antigua och Barbuda" },
      urd: {
        official:
          "\u0627\u06cc\u0646\u0679\u06cc\u06af\u0648\u0627 \u0648 \u0628\u0627\u0631\u0628\u0648\u0688\u0627",
        common:
          "\u0627\u06cc\u0646\u0679\u06cc\u06af\u0648\u0627 \u0648 \u0628\u0627\u0631\u0628\u0648\u0688\u0627",
      },
      zho: {
        official: "\u5b89\u63d0\u74dc\u548c\u5df4\u5e03\u8fbe",
        common: "\u5b89\u63d0\u74dc\u548c\u5df4\u5e03\u8fbe",
      },
    },
    latlng: [17.05, -61.8],
    landlocked: false,
    borders: [],
    area: 442,
    flag: "\ud83c\udde6\ud83c\uddec",
    demonyms: {
      eng: { f: "Antiguan, Barbudan", m: "Antiguan, Barbudan" },
      fra: { f: "Antiguaise et barbudienne", m: "Antiguaise et barbudien" },
    },
    callingCodes: ["+1268"],
  },
  {
    name: {
      common: "Australia",
      official: "Commonwealth of Australia",
      native: {
        eng: { official: "Commonwealth of Australia", common: "Australia" },
      },
    },
    tld: [".au"],
    cca2: "AU",
    ccn3: "036",
    cca3: "AUS",
    cioc: "AUS",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { AUD: { name: "Australian dollar", symbol: "$" } },
    idd: { root: "+6", suffixes: ["1"] },
    capital: ["Canberra"],
    altSpellings: ["AU"],
    region: "Oceania",
    subregion: "Australia and New Zealand",
    languages: { eng: "English" },
    translations: {
      ces: {
        official: "Australsk\u00e9 spole\u010denstv\u00ed",
        common: "Austr\u00e1lie",
      },
      cym: { official: "Cymanwlad Awstralia", common: "Awstralia" },
      deu: { official: "Commonwealth Australien", common: "Australien" },
      est: { official: "Austraalia \u00dchendus", common: "Austraalia" },
      fin: { official: "Australian liittovaltio", common: "Australia" },
      fra: { official: "Australie", common: "Australie" },
      hrv: { official: "Commonwealth of Australia", common: "Australija" },
      hun: {
        official: "Ausztr\u00e1l \u00c1llamsz\u00f6vets\u00e9g",
        common: "Ausztr\u00e1lia",
      },
      ita: { official: "Commonwealth dell'Australia", common: "Australia" },
      jpn: {
        official: "\u30aa\u30fc\u30b9\u30c8\u30e9\u30ea\u30a2\u9023\u90a6",
        common: "\u30aa\u30fc\u30b9\u30c8\u30e9\u30ea\u30a2",
      },
      kor: {
        official: "\uc624\uc2a4\ud2b8\ub808\uc77c\ub9ac\uc544 \uc5f0\ubc29",
        common: "\ud638\uc8fc",
      },
      nld: {
        official: "Gemenebest van Australi\u00eb",
        common: "Australi\u00eb",
      },
      per: {
        official:
          "\u0642\u0644\u0645\u0631\u0648 \u0647\u0645\u0633\u0648\u062f \u0627\u0633\u062a\u0631\u0627\u0644\u06cc\u0627",
        common: "\u0627\u0633\u062a\u0631\u0627\u0644\u06cc\u0627",
      },
      pol: { official: "Zwi\u0105zek Australijski", common: "Australia" },
      por: {
        official: "Comunidade da Austr\u00e1lia",
        common: "Austr\u00e1lia",
      },
      rus: {
        official:
          "\u0421\u043e\u0434\u0440\u0443\u0436\u0435\u0441\u0442\u0432\u043e \u0410\u0432\u0441\u0442\u0440\u0430\u043b\u0438\u0438",
        common: "\u0410\u0432\u0441\u0442\u0440\u0430\u043b\u0438\u044f",
      },
      slk: { official: "Austr\u00e1lsky zv\u00e4z", common: "Austr\u00e1lia" },
      spa: { official: "Mancomunidad de Australia", common: "Australia" },
      swe: {
        official: "Australiska statsf\u00f6rbundet",
        common: "Australien",
      },
      urd: {
        official:
          "\u062f\u0648\u0644\u062a\u0650 \u0645\u0634\u062a\u0631\u06a9\u06c1 \u0622\u0633\u0679\u0631\u06cc\u0644\u06cc\u0627",
        common: "\u0622\u0633\u0679\u0631\u06cc\u0644\u06cc\u0627",
      },
      zho: {
        official: "\u6fb3\u5927\u5229\u4e9a\u8054\u90a6",
        common: "\u6fb3\u5927\u5229\u4e9a",
      },
    },
    latlng: [-27, 133],
    landlocked: false,
    borders: [],
    area: 7692024,
    flag: "\ud83c\udde6\ud83c\uddfa",
    demonyms: {
      eng: { f: "Australian", m: "Australian" },
      fra: { f: "Australienne", m: "Australien" },
    },
    callingCodes: ["+61"],
  },
  {
    name: {
      common: "Austria",
      official: "Republic of Austria",
      native: {
        bar: {
          official: "Republik \u00d6sterreich",
          common: "\u00d6sterreich",
        },
      },
    },
    tld: [".at"],
    cca2: "AT",
    ccn3: "040",
    cca3: "AUT",
    cioc: "AUT",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { EUR: { name: "Euro", symbol: "\u20ac" } },
    idd: { root: "+4", suffixes: ["3"] },
    capital: ["Vienna"],
    altSpellings: ["AT", "Osterreich", "Oesterreich"],
    region: "Europe",
    subregion: "Central Europe",
    languages: { bar: "Austro-Bavarian German" },
    translations: {
      ces: { official: "Rakousk\u00e1 republika", common: "Rakousko" },
      cym: { official: "Gweriniaeth Awstria", common: "Awstria" },
      deu: { official: "Republik \u00d6sterreich", common: "\u00d6sterreich" },
      est: { official: "Austria Vabariik", common: "Austria" },
      fin: { official: "It\u00e4vallan tasavalta", common: "It\u00e4valta" },
      fra: { official: "R\u00e9publique d'Autriche", common: "Autriche" },
      hrv: { official: "Republika Austrija", common: "Austrija" },
      hun: { official: "Ausztria", common: "Ausztria" },
      ita: { official: "Repubblica d'Austria", common: "Austria" },
      jpn: {
        official: "\u30aa\u30fc\u30b9\u30c8\u30ea\u30a2\u5171\u548c\u56fd",
        common: "\u30aa\u30fc\u30b9\u30c8\u30ea\u30a2",
      },
      kor: {
        official: "\uc624\uc2a4\ud2b8\ub9ac\uc544 \uacf5\ud654\uad6d",
        common: "\uc624\uc2a4\ud2b8\ub9ac\uc544",
      },
      nld: { official: "Republiek Oostenrijk", common: "Oostenrijk" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0627\u062a\u0631\u06cc\u0634",
        common: "\u0627\u062a\u0631\u06cc\u0634",
      },
      pol: { official: "Republika Austrii", common: "Austria" },
      por: {
        official: "Rep\u00fablica da \u00c1ustria",
        common: "\u00c1ustria",
      },
      rus: {
        official:
          "\u0410\u0432\u0441\u0442\u0440\u0438\u0439\u0441\u043a\u0430\u044f \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430",
        common: "\u0410\u0432\u0441\u0442\u0440\u0438\u044f",
      },
      slk: { official: "Rak\u00faska republika", common: "Rak\u00fasko" },
      spa: { official: "Rep\u00fablica de Austria", common: "Austria" },
      swe: { official: "Republiken \u00d6sterrike", common: "\u00d6sterrike" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0622\u0633\u0679\u0631\u06cc\u0627",
        common: "\u0622\u0633\u0679\u0631\u06cc\u0627",
      },
      zho: {
        official: "\u5965\u5730\u5229\u5171\u548c\u56fd",
        common: "\u5965\u5730\u5229",
      },
    },
    latlng: [47.33333333, 13.33333333],
    landlocked: true,
    borders: ["CZE", "DEU", "HUN", "ITA", "LIE", "SVK", "SVN", "CHE"],
    area: 83871,
    flag: "\ud83c\udde6\ud83c\uddf9",
    demonyms: {
      eng: { f: "Austrian", m: "Austrian" },
      fra: { f: "Autrichienne", m: "Autrichien" },
    },
    callingCodes: ["+43"],
  },
  {
    name: {
      common: "Azerbaijan",
      official: "Republic of Azerbaijan",
      native: {
        aze: {
          official: "Az\u0259rbaycan Respublikas\u0131",
          common: "Az\u0259rbaycan",
        },
        rus: {
          official:
            "\u0410\u0437\u0435\u0440\u0431\u0430\u0439\u0434\u0436\u0430\u043d\u0441\u043a\u0430\u044f \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430",
          common:
            "\u0410\u0437\u0435\u0440\u0431\u0430\u0439\u0434\u0436\u0430\u043d",
        },
      },
    },
    tld: [".az"],
    cca2: "AZ",
    ccn3: "031",
    cca3: "AZE",
    cioc: "AZE",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { AZN: { name: "Azerbaijani manat", symbol: "\u20bc" } },
    idd: { root: "+9", suffixes: ["94"] },
    capital: ["Baku"],
    altSpellings: [
      "AZ",
      "Republic of Azerbaijan",
      "Az\u0259rbaycan Respublikas\u0131",
    ],
    region: "Asia",
    subregion: "Western Asia",
    languages: { aze: "Azerbaijani", rus: "Russian" },
    translations: {
      ces: {
        official: "\u00c1zerb\u00e1jd\u017e\u00e1nsk\u00e1 republika",
        common: "\u00c1zerb\u00e1jd\u017e\u00e1n",
      },
      cym: { official: "Gweriniaeth Aserbaijan", common: "Aserbaijan" },
      deu: { official: "Republik Aserbaidschan", common: "Aserbaidschan" },
      est: {
        official: "Aserbaid\u017eaani Vabariik",
        common: "Aserbaid\u017eaan",
      },
      fin: { official: "Azerbaidzanin tasavalta", common: "Azerbaidzan" },
      fra: {
        official: "R\u00e9publique d'Azerba\u00efdjan",
        common: "Azerba\u00efdjan",
      },
      hrv: {
        official: "Republika Azerbajd\u017ean",
        common: "Azerbajd\u017ean",
      },
      hun: { official: "Azerbajdzs\u00e1n", common: "Azerbajdzs\u00e1n" },
      ita: { official: "Repubblica dell'Azerbaigian", common: "Azerbaijan" },
      jpn: {
        official:
          "\u30a2\u30bc\u30eb\u30d0\u30a4\u30b8\u30e3\u30f3\u5171\u548c\u56fd",
        common: "\u30a2\u30bc\u30eb\u30d0\u30a4\u30b8\u30e3\u30f3",
      },
      kor: {
        official: "\uc544\uc81c\ub974\ubc14\uc774\uc794 \uacf5\ud654\uad6d",
        common: "\uc544\uc81c\ub974\ubc14\uc774\uc794",
      },
      nld: { official: "Republiek Azerbeidzjan", common: "Azerbeidzjan" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0622\u0630\u0631\u0628\u0627\u06cc\u062c\u0627\u0646",
        common:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0622\u0630\u0631\u0628\u0627\u06cc\u062c\u0627\u0646",
      },
      pol: {
        official: "Republika Azerbejd\u017canu",
        common: "Azerbejd\u017can",
      },
      por: {
        official: "Rep\u00fablica do Azerbaij\u00e3o",
        common: "Azerbeij\u00e3o",
      },
      rus: {
        official:
          "\u0410\u0437\u0435\u0440\u0431\u0430\u0439\u0434\u0436\u0430\u043d\u0441\u043a\u0430\u044f \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430",
        common:
          "\u0410\u0437\u0435\u0440\u0431\u0430\u0439\u0434\u0436\u0430\u043d",
      },
      slk: {
        official: "Azerbaj\u01c6ansk\u00e1 republika",
        common: "Azerbaj\u01c7an",
      },
      spa: {
        official: "Rep\u00fablica de Azerbaiy\u00e1n",
        common: "Azerbaiy\u00e1n",
      },
      swe: { official: "Republiken Azerbajdzjan", common: "Azerbajdzjan" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0622\u0630\u0631\u0628\u0627\u0626\u06cc\u062c\u0627\u0646",
        common: "\u0622\u0630\u0631\u0628\u0627\u0626\u06cc\u062c\u0627\u0646",
      },
      zho: {
        official: "\u963f\u585e\u62dc\u7586\u5171\u548c\u56fd",
        common: "\u963f\u585e\u62dc\u7586",
      },
    },
    latlng: [40.5, 47.5],
    landlocked: true,
    borders: ["ARM", "GEO", "IRN", "RUS", "TUR"],
    area: 86600,
    flag: "\ud83c\udde6\ud83c\uddff",
    demonyms: {
      eng: { f: "Azerbaijani", m: "Azerbaijani" },
      fra: { f: "Azerba\u00efdjanaise", m: "Azerba\u00efdjanais" },
    },
    callingCodes: ["+994"],
  },
  {
    name: {
      common: "Burundi",
      official: "Republic of Burundi",
      native: {
        fra: { official: "R\u00e9publique du Burundi", common: "Burundi" },
        run: { official: "Republika y'Uburundi ", common: "Uburundi" },
      },
    },
    tld: [".bi"],
    cca2: "BI",
    ccn3: "108",
    cca3: "BDI",
    cioc: "BDI",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { BIF: { name: "Burundian franc", symbol: "Fr" } },
    idd: { root: "+2", suffixes: ["57"] },
    capital: ["Gitega"],
    altSpellings: [
      "BI",
      "Republic of Burundi",
      "Republika y'Uburundi",
      "R\u00e9publique du Burundi",
    ],
    region: "Africa",
    subregion: "Eastern Africa",
    languages: { fra: "French", run: "Kirundi" },
    translations: {
      ces: { official: "Burundsk\u00e1 republika", common: "Burundi" },
      cym: { official: "Gweriniaeth Bwrwndi", common: "Bwrwndi" },
      deu: { official: "Republik Burundi", common: "Burundi" },
      est: { official: "Burundi Vabariik", common: "Burundi" },
      fin: { official: "Burundin tasavalta", common: "Burundi" },
      fra: { official: "R\u00e9publique du Burundi", common: "Burundi" },
      hrv: { official: "Burundi", common: "Burundi" },
      hun: { official: "Burundi", common: "Burundi" },
      ita: { official: "Repubblica del Burundi", common: "Burundi" },
      jpn: {
        official: "\u30d6\u30eb\u30f3\u30b8\u5171\u548c\u56fd",
        common: "\u30d6\u30eb\u30f3\u30b8",
      },
      kor: { official: "\ubd80\ub8ec\ub514", common: "\ubd80\ub8ec\ub514" },
      nld: { official: "Republiek Burundi", common: "Burundi" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0628\u0648\u0631\u0648\u0646\u062f\u06cc",
        common: "\u0628\u0648\u0631\u0648\u0646\u062f\u06cc",
      },
      pol: { official: "Republika Burundi", common: "Burundi" },
      por: { official: "Rep\u00fablica do Burundi", common: "Burundi" },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0411\u0443\u0440\u0443\u043d\u0434\u0438",
        common: "\u0411\u0443\u0440\u0443\u043d\u0434\u0438",
      },
      slk: { official: "Burundsk\u00e1 republika", common: "Burundi" },
      spa: { official: "Rep\u00fablica de Burundi", common: "Burundi" },
      swe: { official: "Republiken Burundi", common: "Burundi" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0628\u0631\u0648\u0646\u0688\u06cc",
        common: "\u0628\u0631\u0648\u0646\u0688\u06cc",
      },
      zho: {
        official: "\u5e03\u9686\u8fea\u5171\u548c\u56fd",
        common: "\u5e03\u9686\u8fea",
      },
    },
    latlng: [-3.5, 30],
    landlocked: true,
    borders: ["COD", "RWA", "TZA"],
    area: 27834,
    flag: "\ud83c\udde7\ud83c\uddee",
    demonyms: {
      eng: { f: "Burundian", m: "Burundian" },
      fra: { f: "Burundaise", m: "Burundais" },
    },
    callingCodes: ["+257"],
  },
  {
    name: {
      common: "Belgium",
      official: "Kingdom of Belgium",
      native: {
        deu: { official: "K\u00f6nigreich Belgien", common: "Belgien" },
        fra: { official: "Royaume de Belgique", common: "Belgique" },
        nld: { official: "Koninkrijk Belgi\u00eb", common: "Belgi\u00eb" },
      },
    },
    tld: [".be"],
    cca2: "BE",
    ccn3: "056",
    cca3: "BEL",
    cioc: "BEL",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { EUR: { name: "Euro", symbol: "\u20ac" } },
    idd: { root: "+3", suffixes: ["2"] },
    capital: ["Brussels"],
    altSpellings: [
      "BE",
      "Belgi\u00eb",
      "Belgie",
      "Belgien",
      "Belgique",
      "Kingdom of Belgium",
      "Koninkrijk Belgi\u00eb",
      "Royaume de Belgique",
      "K\u00f6nigreich Belgien",
    ],
    region: "Europe",
    subregion: "Western Europe",
    languages: { deu: "German", fra: "French", nld: "Dutch" },
    translations: {
      ces: { official: "Belgick\u00e9 kr\u00e1lovstv\u00ed", common: "Belgie" },
      cym: { official: "Teyrnas Gwlad Belg", common: "Gwlad Belg" },
      deu: { official: "K\u00f6nigreich Belgien", common: "Belgien" },
      est: { official: "Belgia Kuningriik", common: "Belgia" },
      fin: { official: "Belgian kuningaskunta", common: "Belgia" },
      fra: { official: "Royaume de Belgique", common: "Belgique" },
      hrv: { official: "Kraljevina Belgija", common: "Belgija" },
      hun: { official: "Belga Kir\u00e1lys\u00e1g", common: "Belgium" },
      ita: { official: "Regno del Belgio", common: "Belgio" },
      jpn: {
        official: "\u30d9\u30eb\u30ae\u30fc\u738b\u56fd",
        common: "\u30d9\u30eb\u30ae\u30fc",
      },
      kor: {
        official: "\ubca8\uae30\uc5d0 \uc655\uad6d",
        common: "\ubca8\uae30\uc5d0",
      },
      nld: { official: "Koninkrijk Belgi\u00eb", common: "Belgi\u00eb" },
      per: {
        official:
          "\u067e\u0627\u062f\u0634\u0627\u0647\u06cc \u0628\u0644\u0698\u06cc\u06a9",
        common: "\u0628\u0644\u0698\u06cc\u06a9",
      },
      pol: { official: "Kr\u00f3lestwo Belgii", common: "Belgia" },
      por: { official: "Reino da B\u00e9lgica", common: "B\u00e9lgica" },
      rus: {
        official:
          "\u041a\u043e\u0440\u043e\u043b\u0435\u0432\u0441\u0442\u0432\u043e \u0411\u0435\u043b\u044c\u0433\u0438\u044f",
        common: "\u0411\u0435\u043b\u044c\u0433\u0438\u044f",
      },
      slk: {
        official: "Belgick\u00e9 kr\u00e1\u013eovstvo",
        common: "Belgicko",
      },
      spa: { official: "Reino de B\u00e9lgica", common: "B\u00e9lgica" },
      swe: { official: "Konungariket Belgien", common: "Belgien" },
      urd: {
        official:
          "\u0645\u0645\u0644\u06a9\u062a\u0650 \u0628\u0644\u062c\u0626\u06cc\u0645",
        common: "\u0628\u0644\u062c\u0626\u06cc\u0645",
      },
      zho: {
        official: "\u6bd4\u5229\u65f6\u738b\u56fd",
        common: "\u6bd4\u5229\u65f6",
      },
    },
    latlng: [50.83333333, 4],
    landlocked: false,
    borders: ["FRA", "DEU", "LUX", "NLD"],
    area: 30528,
    flag: "\ud83c\udde7\ud83c\uddea",
    demonyms: {
      eng: { f: "Belgian", m: "Belgian" },
      fra: { f: "Belge", m: "Belge" },
    },
    callingCodes: ["+32"],
  },
  {
    name: {
      common: "Benin",
      official: "Republic of Benin",
      native: {
        fra: {
          official: "R\u00e9publique du B\u00e9nin",
          common: "B\u00e9nin",
        },
      },
    },
    tld: [".bj"],
    cca2: "BJ",
    ccn3: "204",
    cca3: "BEN",
    cioc: "BEN",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { XOF: { name: "West African CFA franc", symbol: "Fr" } },
    idd: { root: "+2", suffixes: ["29"] },
    capital: ["Porto-Novo"],
    altSpellings: ["BJ", "Republic of Benin", "R\u00e9publique du B\u00e9nin"],
    region: "Africa",
    subregion: "Western Africa",
    languages: { fra: "French" },
    translations: {
      ces: { official: "Beninsk\u00e1 republika", common: "Benin" },
      cym: { official: "Gweriniaeth Benin", common: "Benin" },
      deu: { official: "Republik Benin", common: "Benin" },
      est: { official: "Benini Vabariik", common: "Benin" },
      fin: { official: "Beninin tasavalta", common: "Benin" },
      fra: { official: "R\u00e9publique du B\u00e9nin", common: "B\u00e9nin" },
      hrv: { official: "Republika Benin", common: "Benin" },
      hun: { official: "Benini K\u00f6zt\u00e1rsas\u00e1g", common: "Benin" },
      ita: { official: "Repubblica del Benin", common: "Benin" },
      jpn: {
        official: "\u30d9\u30ca\u30f3\u5171\u548c\u56fd",
        common: "\u30d9\u30ca\u30f3",
      },
      kor: {
        official: "\ubca0\ub0c9 \uacf5\ud654\uad6d",
        common: "\ubca0\ub0c9",
      },
      nld: { official: "Republiek Benin", common: "Benin" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0628\u0646\u06cc\u0646",
        common: "\u0628\u0646\u06cc\u0646",
      },
      pol: { official: "Benin", common: "Benin" },
      por: { official: "Rep\u00fablica do Benin", common: "Benin" },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0411\u0435\u043d\u0438\u043d",
        common: "\u0411\u0435\u043d\u0438\u043d",
      },
      slk: { official: "Beninsk\u00e1 republika", common: "Benin" },
      spa: { official: "Rep\u00fablica de Benin", common: "Ben\u00edn" },
      swe: { official: "Republiken Benin", common: "Benin" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0628\u06cc\u0646\u0646",
        common: "\u0628\u06cc\u0646\u0646",
      },
      zho: {
        official: "\u8d1d\u5b81\u5171\u548c\u56fd",
        common: "\u8d1d\u5b81",
      },
    },
    latlng: [9.5, 2.25],
    landlocked: false,
    borders: ["BFA", "NER", "NGA", "TGO"],
    area: 112622,
    flag: "\ud83c\udde7\ud83c\uddef",
    demonyms: {
      eng: { f: "Beninese", m: "Beninese" },
      fra: { f: "B\u00e9ninoise", m: "B\u00e9ninois" },
    },
    callingCodes: ["+229"],
  },
  {
    name: {
      common: "Burkina Faso",
      official: "Burkina Faso",
      native: {
        fra: { official: "R\u00e9publique du Burkina", common: "Burkina Faso" },
      },
    },
    tld: [".bf"],
    cca2: "BF",
    ccn3: "854",
    cca3: "BFA",
    cioc: "BUR",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { XOF: { name: "West African CFA franc", symbol: "Fr" } },
    idd: { root: "+2", suffixes: ["26"] },
    capital: ["Ouagadougou"],
    altSpellings: ["BF"],
    region: "Africa",
    subregion: "Western Africa",
    languages: { fra: "French" },
    translations: {
      ces: { official: "Burkina Faso", common: "Burkina Faso" },
      cym: { official: "Bwrcina Ffaso", common: "Bwrcina Ffaso" },
      deu: { official: "Burkina Faso", common: "Burkina Faso" },
      est: { official: "Burkina Faso", common: "Burkina Faso" },
      fin: { official: "Burkina Faso", common: "Burkina Faso" },
      fra: { official: "R\u00e9publique du Burkina", common: "Burkina Faso" },
      hrv: { official: "Burkina Faso", common: "Burkina Faso" },
      hun: { official: "Burkina Faso", common: "Burkina" },
      ita: { official: "Burkina Faso", common: "Burkina Faso" },
      jpn: {
        official: "\u30d6\u30eb\u30ad\u30ca\u30d5\u30a1\u30bd",
        common: "\u30d6\u30eb\u30ad\u30ca\u30d5\u30a1\u30bd",
      },
      kor: {
        official: "\ubd80\ub974\ud0a4\ub098\ud30c\uc18c",
        common: "\ubd80\ub974\ud0a4\ub098\ud30c\uc18c",
      },
      nld: { official: "Burkina Faso", common: "Burkina Faso" },
      per: {
        official:
          "\u0628\u0648\u0631\u06a9\u06cc\u0646\u0627\u0641\u0627\u0633\u0648",
        common:
          "\u0628\u0648\u0631\u06a9\u06cc\u0646\u0627\u0641\u0627\u0633\u0648",
      },
      pol: { official: "Burkina Faso", common: "Burkina Faso" },
      por: { official: "Burkina Faso", common: "Burkina Faso" },
      rus: {
        official:
          "\u0411\u0443\u0440\u043a\u0438\u043d\u0430 -\u0424\u0430\u0441\u043e",
        common:
          "\u0411\u0443\u0440\u043a\u0438\u043d\u0430-\u0424\u0430\u0441\u043e",
      },
      slk: { official: "Burkina Faso", common: "Burkina Faso" },
      spa: { official: "Burkina Faso", common: "Burkina Faso" },
      swe: { official: "Burkina Faso", common: "Burkina Faso" },
      urd: {
        official:
          "\u0628\u0631\u06a9\u06cc\u0646\u0627 \u0641\u0627\u0633\u0648",
        common: "\u0628\u0631\u06a9\u06cc\u0646\u0627 \u0641\u0627\u0633\u0648",
      },
      zho: {
        official: "\u5e03\u57fa\u7eb3\u6cd5\u7d22",
        common: "\u5e03\u57fa\u7eb3\u6cd5\u7d22",
      },
    },
    latlng: [13, -2],
    landlocked: true,
    borders: ["BEN", "CIV", "GHA", "MLI", "NER", "TGO"],
    area: 272967,
    flag: "\ud83c\udde7\ud83c\uddeb",
    demonyms: {
      eng: { f: "Burkinabe", m: "Burkinabe" },
      fra: { f: "Burkinab\u00e9e", m: "Burkinab\u00e9" },
    },
    callingCodes: ["+226"],
  },
  {
    name: {
      common: "Bangladesh",
      official: "People's Republic of Bangladesh",
      native: {
        ben: {
          official:
            "\u09ac\u09be\u0982\u09b2\u09be\u09a6\u09c7\u09b6 \u0997\u09a3\u09aa\u09cd\u09b0\u099c\u09be\u09a4\u09a8\u09cd\u09a4\u09cd\u09b0\u09c0",
          common: "\u09ac\u09be\u0982\u09b2\u09be\u09a6\u09c7\u09b6",
        },
      },
    },
    tld: [".bd"],
    cca2: "BD",
    ccn3: "050",
    cca3: "BGD",
    cioc: "BAN",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { BDT: { name: "Bangladeshi taka", symbol: "\u09f3" } },
    idd: { root: "+8", suffixes: ["80"] },
    capital: ["Dhaka"],
    altSpellings: [
      "BD",
      "People's Republic of Bangladesh",
      "G\u00f4n\u00f4pr\u00f4jat\u00f4ntri Bangladesh",
    ],
    region: "Asia",
    subregion: "Southern Asia",
    languages: { ben: "Bengali" },
    translations: {
      ces: {
        official: "Banglad\u00e9\u0161sk\u00e1 lidov\u00e1 republika",
        common: "Banglad\u00e9\u0161",
      },
      cym: { official: "Gweriniaeth Pobl Bangladesh", common: "Bangladesh" },
      deu: { official: "Volksrepublik Bangladesch", common: "Bangladesch" },
      est: { official: "Bangladeshi Rahvavabariik", common: "Bangladesh" },
      fin: { official: "Bangladeshin kansantasavalta", common: "Bangladesh" },
      fra: {
        official: "La R\u00e9publique populaire du Bangladesh",
        common: "Bangladesh",
      },
      hrv: {
        official: "Narodna Republika Banglade\u0161",
        common: "Banglade\u0161",
      },
      hun: { official: "Banglades", common: "Banglades" },
      ita: {
        official: "Repubblica popolare del Bangladesh",
        common: "Bangladesh",
      },
      jpn: {
        official:
          "\u30d0\u30f3\u30b0\u30e9\u30c7\u30b7\u30e5\u4eba\u6c11\u5171\u548c\u56fd",
        common: "\u30d0\u30f3\u30b0\u30e9\u30c7\u30b7\u30e5",
      },
      kor: {
        official:
          "\ubc29\uae00\ub77c\ub370\uc2dc \uc778\ubbfc \uacf5\ud654\uad6d",
        common: "\ubc29\uae00\ub77c\ub370\uc2dc",
      },
      nld: { official: "Volksrepubliek Bangladesh", common: "Bangladesh" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u062e\u0644\u0642 \u0628\u0646\u06af\u0644\u0627\u062f\u0634",
        common: "\u0628\u0646\u06af\u0644\u0627\u062f\u0634",
      },
      pol: { official: "Ludowa Republika Bangladeszu", common: "Bangladesz" },
      por: {
        official: "Rep\u00fablica Popular do Bangladesh",
        common: "Bangladesh",
      },
      rus: {
        official:
          "\u041d\u0430\u0440\u043e\u0434\u043d\u0430\u044f \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0411\u0430\u043d\u0433\u043b\u0430\u0434\u0435\u0448",
        common: "\u0411\u0430\u043d\u0433\u043b\u0430\u0434\u0435\u0448",
      },
      slk: {
        official: "Banglad\u00e9\u0161ska \u013eudov\u00e1 republika",
        common: "Banglad\u00e9\u0161",
      },
      spa: {
        official: "Rep\u00fablica Popular de Bangladesh",
        common: "Bangladesh",
      },
      swe: { official: "Folkrepubliken Bangladesh", common: "Bangladesh" },
      urd: {
        official:
          "\u0639\u0648\u0627\u0645\u06cc \u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0628\u0646\u06af\u0644\u06c1 \u062f\u06cc\u0634",
        common: "\u0628\u0646\u06af\u0644\u06c1 \u062f\u06cc\u0634",
      },
      zho: {
        official: "\u5b5f\u52a0\u62c9\u4eba\u6c11\u5171\u548c\u56fd",
        common: "\u5b5f\u52a0\u62c9\u56fd",
      },
    },
    latlng: [24, 90],
    landlocked: false,
    borders: ["MMR", "IND"],
    area: 147570,
    flag: "\ud83c\udde7\ud83c\udde9",
    demonyms: {
      eng: { f: "Bangladeshi", m: "Bangladeshi" },
      fra: { f: "Bangladaise", m: "Bangladais" },
    },
    callingCodes: ["+880"],
  },
  {
    name: {
      common: "Bulgaria",
      official: "Republic of Bulgaria",
      native: {
        bul: {
          official:
            "\u0420\u0435\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0411\u044a\u043b\u0433\u0430\u0440\u0438\u044f",
          common: "\u0411\u044a\u043b\u0433\u0430\u0440\u0438\u044f",
        },
      },
    },
    tld: [".bg"],
    cca2: "BG",
    ccn3: "100",
    cca3: "BGR",
    cioc: "BUL",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { BGN: { name: "Bulgarian lev", symbol: "\u043b\u0432" } },
    idd: { root: "+3", suffixes: ["59"] },
    capital: ["Sofia"],
    altSpellings: [
      "BG",
      "Republic of Bulgaria",
      "\u0420\u0435\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0411\u044a\u043b\u0433\u0430\u0440\u0438\u044f",
    ],
    region: "Europe",
    subregion: "Southeast Europe",
    languages: { bul: "Bulgarian" },
    translations: {
      ces: { official: "Bulharsk\u00e1 republika", common: "Bulharsko" },
      cym: { official: "Gweriniaeth Bwlgaria", common: "Bwlgaria" },
      deu: { official: "Republik Bulgarien", common: "Bulgarien" },
      est: { official: "Bulgaaria Vabariik", common: "Bulgaaria" },
      fin: { official: "Bulgarian tasavalta", common: "Bulgaria" },
      fra: { official: "R\u00e9publique de Bulgarie", common: "Bulgarie" },
      hrv: { official: "Republika Bugarska", common: "Bugarska" },
      hun: {
        official: "Bolg\u00e1r K\u00f6zt\u00e1rsas\u00e1g",
        common: "Bulg\u00e1ria",
      },
      ita: { official: "Repubblica di Bulgaria", common: "Bulgaria" },
      jpn: {
        official: "\u30d6\u30eb\u30ac\u30ea\u30a2\u5171\u548c\u56fd",
        common: "\u30d6\u30eb\u30ac\u30ea\u30a2",
      },
      kor: {
        official: "\ubd88\uac00\ub9ac\uc544 \uacf5\ud654\uad6d",
        common: "\ubd88\uac00\ub9ac\uc544",
      },
      nld: { official: "Republiek Bulgarije", common: "Bulgarije" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0628\u0644\u063a\u0627\u0631\u0633\u062a\u0627\u0646",
        common: "\u0628\u0644\u063a\u0627\u0631\u0633\u062a\u0627\u0646",
      },
      pol: { official: "Republika Bu\u0142garii", common: "Bu\u0142garia" },
      por: {
        official: "Rep\u00fablica da Bulg\u00e1ria",
        common: "Bulg\u00e1ria",
      },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0411\u043e\u043b\u0433\u0430\u0440\u0438\u044f",
        common: "\u0411\u043e\u043b\u0433\u0430\u0440\u0438\u044f",
      },
      slk: { official: "Bulharsk\u00e1 republika", common: "Bulharsko" },
      spa: { official: "Rep\u00fablica de Bulgaria", common: "Bulgaria" },
      swe: { official: "Republiken Bulgarien", common: "Bulgarien" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0628\u0644\u063a\u0627\u0631\u06cc\u06c1",
        common: "\u0628\u0644\u063a\u0627\u0631\u06cc\u06c1",
      },
      zho: {
        official: "\u4fdd\u52a0\u5229\u4e9a\u5171\u548c\u56fd",
        common: "\u4fdd\u52a0\u5229\u4e9a",
      },
    },
    latlng: [43, 25],
    landlocked: false,
    borders: ["GRC", "MKD", "ROU", "SRB", "TUR"],
    area: 110879,
    flag: "\ud83c\udde7\ud83c\uddec",
    demonyms: {
      eng: { f: "Bulgarian", m: "Bulgarian" },
      fra: { f: "Bulgare", m: "Bulgare" },
    },
    callingCodes: ["+359"],
  },
  {
    name: {
      common: "Bahrain",
      official: "Kingdom of Bahrain",
      native: {
        ara: {
          official:
            "\u0645\u0645\u0644\u0643\u0629 \u0627\u0644\u0628\u062d\u0631\u064a\u0646",
          common: "\u200f\u0627\u0644\u0628\u062d\u0631\u064a\u0646",
        },
      },
    },
    tld: [".bh"],
    cca2: "BH",
    ccn3: "048",
    cca3: "BHR",
    cioc: "BRN",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { BHD: { name: "Bahraini dinar", symbol: ".\u062f.\u0628" } },
    idd: { root: "+9", suffixes: ["73"] },
    capital: ["Manama"],
    altSpellings: ["BH", "Kingdom of Bahrain", "Mamlakat al-Ba\u1e25rayn"],
    region: "Asia",
    subregion: "Western Asia",
    languages: { ara: "Arabic" },
    translations: {
      ces: { official: "Kr\u00e1lovstv\u00ed Bahrajn", common: "Bahrajn" },
      cym: { official: "Teyrnas Bahrein", common: "Bahrain" },
      deu: { official: "K\u00f6nigreich Bahrain", common: "Bahrain" },
      est: { official: "Bahreini Kuningriik", common: "Bahrein" },
      fin: { official: "Bahrainin kuningaskunta", common: "Bahrain" },
      fra: { official: "Royaume de Bahre\u00efn", common: "Bahre\u00efn" },
      hrv: { official: "Kraljevina Bahrein", common: "Bahrein" },
      hun: { official: "Bahreini Kir\u00e1lys\u00e1g", common: "Bahrein" },
      ita: { official: "Regno del Bahrain", common: "Bahrein" },
      jpn: {
        official: "\u30d0\u30fc\u30ec\u30fc\u30f3\u738b\u56fd",
        common: "\u30d0\u30fc\u30ec\u30fc\u30f3",
      },
      kor: {
        official: "\ubc14\ub808\uc778 \uc655\uad6d",
        common: "\ubc14\ub808\uc778",
      },
      nld: { official: "Koninkrijk Bahrein", common: "Bahrein" },
      per: {
        official:
          "\u067e\u0627\u062f\u0634\u0627\u0647\u06cc \u0628\u062d\u0631\u06cc\u0646",
        common: "\u0628\u062d\u0631\u06cc\u0646",
      },
      pol: { official: "Kr\u00f3lestwo Bahrajnu", common: "Bahrajn" },
      por: { official: "Reino do Bahrein", common: "Bahrein" },
      rus: {
        official:
          "\u041a\u043e\u0440\u043e\u043b\u0435\u0432\u0441\u0442\u0432\u043e \u0411\u0430\u0445\u0440\u0435\u0439\u043d",
        common: "\u0411\u0430\u0445\u0440\u0435\u0439\u043d",
      },
      slk: {
        official: "Bahrajnsk\u00e9 kr\u00e1\u013eovstvo",
        common: "Bahrajn",
      },
      spa: { official: "Reino de Bahrein", common: "Bahrein" },
      swe: { official: "Konungariket Bahrain", common: "Bahrain" },
      urd: {
        official:
          "\u0645\u0645\u0644\u06a9\u062a\u0650 \u0628\u062d\u0631\u06cc\u0646",
        common: "\u0628\u062d\u0631\u06cc\u0646",
      },
      zho: { official: "\u5df4\u6797\u738b\u56fd", common: "\u5df4\u6797" },
    },
    latlng: [26, 50.55],
    landlocked: false,
    borders: [],
    area: 765,
    flag: "\ud83c\udde7\ud83c\udded",
    demonyms: {
      eng: { f: "Bahraini", m: "Bahraini" },
      fra: { f: "Bahre\u00efnienne", m: "Bahre\u00efnien" },
    },
    callingCodes: ["+973"],
  },
  {
    name: {
      common: "Bahamas",
      official: "Commonwealth of the Bahamas",
      native: {
        eng: { official: "Commonwealth of the Bahamas", common: "Bahamas" },
      },
    },
    tld: [".bs"],
    cca2: "BS",
    ccn3: "044",
    cca3: "BHS",
    cioc: "BAH",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: {
      BSD: { name: "Bahamian dollar", symbol: "$" },
      USD: { name: "United States dollar", symbol: "$" },
    },
    idd: { root: "+1", suffixes: ["242"] },
    capital: ["Nassau"],
    altSpellings: ["BS", "Commonwealth of the Bahamas"],
    region: "Americas",
    subregion: "Caribbean",
    languages: { eng: "English" },
    translations: {
      ces: {
        official: "Bahamsk\u00e9 spole\u010denstv\u00ed",
        common: "Bahamy",
      },
      cym: { official: "Cymanwlad y Bahamas", common: "Bahamas" },
      deu: { official: "Commonwealth der Bahamas", common: "Bahamas" },
      est: { official: "Bahama \u00dchendus", common: "Bahama" },
      fin: { official: "Bahaman liittovaltio", common: "Bahamasaaret" },
      fra: { official: "Commonwealth des Bahamas", common: "Bahamas" },
      hrv: { official: "Zajednica Bahama", common: "Bahami" },
      hun: {
        official: "Bahamai K\u00f6z\u00f6ss\u00e9g",
        common: "Bahama-szigetek",
      },
      ita: { official: "Commonwealth delle Bahamas", common: "Bahamas" },
      jpn: { official: "\u30d0\u30cf\u30de", common: "\u30d0\u30cf\u30de" },
      kor: {
        official: "\ubc14\ud558\ub9c8 \uc5f0\ubc29",
        common: "\ubc14\ud558\ub9c8",
      },
      nld: { official: "Gemenebest van de Bahama's", common: "Bahama\u2019s" },
      per: {
        official:
          "\u0642\u0644\u0645\u0631\u0648 \u0647\u0645\u0633\u0648\u062f \u0628\u0627\u0647\u0627\u0645\u0627",
        common: "\u0628\u0627\u0647\u0627\u0645\u0627",
      },
      pol: { official: "Bahamy", common: "Bahamy" },
      por: { official: "Comunidade das Bahamas", common: "Bahamas" },
      rus: {
        official:
          "\u0421\u043e\u0434\u0440\u0443\u0436\u0435\u0441\u0442\u0432\u043e \u0411\u0430\u0433\u0430\u043c\u0441\u043a\u0438\u0445 \u041e\u0441\u0442\u0440\u043e\u0432\u043e\u0432",
        common:
          "\u0411\u0430\u0433\u0430\u043c\u0441\u043a\u0438\u0435 \u041e\u0441\u0442\u0440\u043e\u0432\u0430",
      },
      slk: { official: "Bahamsk\u00e9 spolo\u010denstvo", common: "Bahamy" },
      spa: { official: "Commonwealth de las Bahamas", common: "Bahamas" },
      swe: { official: "Samv\u00e4ldet Bahamas", common: "Bahamas" },
      urd: {
        official:
          "\u062f\u0648\u0644\u062a\u0650 \u0645\u0634\u062a\u0631\u06a9\u06c1 \u0628\u06c1\u0627\u0645\u0627\u0633",
        common: "\u0628\u06c1\u0627\u0645\u0627\u0633",
      },
      zho: {
        official: "\u5df4\u54c8\u9a6c\u8054\u90a6",
        common: "\u5df4\u54c8\u9a6c",
      },
    },
    latlng: [24.25, -76],
    landlocked: false,
    borders: [],
    area: 13943,
    flag: "\ud83c\udde7\ud83c\uddf8",
    demonyms: {
      eng: { f: "Bahamian", m: "Bahamian" },
      fra: { f: "Bahamienne", m: "Bahamien" },
    },
    callingCodes: ["+1242"],
  },
  {
    name: {
      common: "Bosnia and Herzegovina",
      official: "Bosnia and Herzegovina",
      native: {
        bos: { official: "Bosna i Hercegovina", common: "Bosna i Hercegovina" },
        hrv: { official: "Bosna i Hercegovina", common: "Bosna i Hercegovina" },
        srp: {
          official:
            "\u0411\u043e\u0441\u043da \u0438 \u0425\u0435\u0440\u0446\u0435\u0433\u043e\u0432\u0438\u043d\u0430",
          common:
            "\u0411\u043e\u0441\u043da \u0438 \u0425\u0435\u0440\u0446\u0435\u0433\u043e\u0432\u0438\u043d\u0430",
        },
      },
    },
    tld: [".ba"],
    cca2: "BA",
    ccn3: "070",
    cca3: "BIH",
    cioc: "BIH",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: {
      BAM: { name: "Bosnia and Herzegovina convertible mark", symbol: "" },
    },
    idd: { root: "+3", suffixes: ["87"] },
    capital: ["Sarajevo"],
    altSpellings: [
      "BA",
      "Bosnia-Herzegovina",
      "\u0411\u043e\u0441\u043d\u0430 \u0438 \u0425\u0435\u0440\u0446\u0435\u0433\u043e\u0432\u0438\u043d\u0430",
    ],
    region: "Europe",
    subregion: "Southeast Europe",
    languages: { bos: "Bosnian", hrv: "Croatian", srp: "Serbian" },
    translations: {
      ces: { official: "Bosna a Hercegovina", common: "Bosna a Hercegovina" },
      cym: { official: "Bosnia a Hercegovina", common: "Bosnia a Hercegovina" },
      deu: {
        official: "Bosnien und Herzegowina",
        common: "Bosnien und Herzegowina",
      },
      est: {
        official: "Bosnia ja Hertsegoviina",
        common: "Bosnia ja Hertsegoviina",
      },
      fin: {
        official: "Bosnia ja Hertsegovina",
        common: "Bosnia ja Hertsegovina",
      },
      fra: {
        official: "Bosnie-et-Herz\u00e9govine",
        common: "Bosnie-Herz\u00e9govine",
      },
      hrv: { official: "Bosna i Hercegovina", common: "Bosna i Hercegovina" },
      hun: { official: "Bosznia-Hercegovina", common: "Bosznia-Hercegovina" },
      ita: { official: "Bosnia-Erzegovina", common: "Bosnia ed Erzegovina" },
      jpn: {
        official:
          "\u30dc\u30b9\u30cb\u30a2\u00b7\u30d8\u30eb\u30c4\u30a7\u30b4\u30d3\u30ca",
        common:
          "\u30dc\u30b9\u30cb\u30a2\u30fb\u30d8\u30eb\u30c4\u30a7\u30b4\u30d3\u30ca",
      },
      kor: {
        official:
          "\ubcf4\uc2a4\ub2c8\uc544 \ud5e4\ub974\uccb4\uace0\ube44\ub098",
        common: "\ubcf4\uc2a4\ub2c8\uc544 \ud5e4\ub974\uccb4\uace0\ube44\ub098",
      },
      nld: {
        official: "Bosni\u00eb-Herzegovina",
        common: "Bosni\u00eb en Herzegovina",
      },
      per: {
        official:
          "\u0628\u0648\u0633\u0646\u06cc \u0648 \u0647\u0631\u0632\u06af\u0648\u06cc\u0646",
        common:
          "\u0628\u0648\u0633\u0646\u06cc \u0648 \u0647\u0631\u0632\u06af\u0648\u06cc\u0646",
      },
      pol: {
        official: "Bo\u015bnia i Hercegowina",
        common: "Bo\u015bnia i Hercegowina",
      },
      por: {
        official: "B\u00f3snia e Herzegovina",
        common: "B\u00f3snia e Herzegovina",
      },
      rus: {
        official:
          "\u0411\u043e\u0441\u043d\u0438\u044f \u0438 \u0413\u0435\u0440\u0446\u0435\u0433\u043e\u0432\u0438\u043d\u0430",
        common:
          "\u0411\u043e\u0441\u043d\u0438\u044f \u0438 \u0413\u0435\u0440\u0446\u0435\u0433\u043e\u0432\u0438\u043d\u0430",
      },
      slk: {
        official: "Republika Bosny a Hercegoviny",
        common: "Bosna a Hercegovina",
      },
      spa: { official: "Bosnia y Herzegovina", common: "Bosnia y Herzegovina" },
      swe: {
        official: "Bosnien och Hercegovina",
        common: "Bosnien och Hercegovina",
      },
      urd: {
        official:
          "\u0628\u0648\u0633\u0646\u06cc\u0627 \u0648 \u06c1\u0631\u0632\u06cc\u06af\u0648\u0648\u06cc\u0646\u0627",
        common:
          "\u0628\u0648\u0633\u0646\u06cc\u0627 \u0648 \u06c1\u0631\u0632\u06cc\u06af\u0648\u0648\u06cc\u0646\u0627",
      },
      zho: {
        official:
          "\u6ce2\u65af\u5c3c\u4e9a\u548c\u9ed1\u585e\u54e5\u7ef4\u90a3",
        common: "\u6ce2\u65af\u5c3c\u4e9a\u548c\u9ed1\u585e\u54e5\u7ef4\u90a3",
      },
    },
    latlng: [44, 18],
    landlocked: false,
    borders: ["HRV", "MNE", "SRB"],
    area: 51209,
    flag: "\ud83c\udde7\ud83c\udde6",
    demonyms: {
      eng: { f: "Bosnian, Herzegovinian", m: "Bosnian, Herzegovinian" },
      fra: { f: "Bosnienne", m: "Bosnien" },
    },
    callingCodes: ["+387"],
  },
  {
    name: {
      common: "Saint Barth\u00e9lemy",
      official: "Collectivity of Saint Barth\u00e9lemy",
      native: {
        fra: {
          official: "Collectivit\u00e9 de Saint-Barth\u00e9lemy",
          common: "Saint-Barth\u00e9lemy",
        },
      },
    },
    tld: [".bl"],
    cca2: "BL",
    ccn3: "652",
    cca3: "BLM",
    cioc: "",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: { EUR: { name: "Euro", symbol: "\u20ac" } },
    idd: { root: "+5", suffixes: ["90"] },
    capital: ["Gustavia"],
    altSpellings: [
      "BL",
      "St. Barthelemy",
      "Collectivity of Saint Barth\u00e9lemy",
      "Collectivit\u00e9 de Saint-Barth\u00e9lemy",
    ],
    region: "Americas",
    subregion: "Caribbean",
    languages: { fra: "French" },
    translations: {
      ces: {
        official: "Svat\u00fd Bartolom\u011bj",
        common: "Svat\u00fd Bartolom\u011bj",
      },
      deu: {
        official: "Gebietsk\u00f6rperschaft Saint-Barth\u00e9lemy",
        common: "Saint-Barth\u00e9lemy",
      },
      est: {
        official: "Saint-Barth\u00e9lemy territoriaal\u00fchendus",
        common: "Saint-Barth\u00e9lemy",
      },
      fin: {
        official: "Saint-Barth\u00e9lemyn yhteis\u00f6",
        common: "Saint-Barth\u00e9lemy",
      },
      fra: {
        official: "Collectivit\u00e9 de Saint-Barth\u00e9lemy",
        common: "Saint-Barth\u00e9lemy",
      },
      hrv: {
        official: "Kolektivnost sv Barth\u00e9lemy",
        common: "Saint Barth\u00e9lemy",
      },
      hun: {
        official: "Saint-Barth\u00e9lemy",
        common: "Saint-Barth\u00e9lemy",
      },
      ita: {
        official: "Collettivit\u00e0 di Saint Barth\u00e9lemy",
        common: "Antille Francesi",
      },
      jpn: {
        official:
          "\u30b5\u30f3\u00b7\u30d0\u30eb\u30c6\u30eb\u30df\u30fc\u5cf6\u306e\u96c6\u5408\u4f53",
        common: "\u30b5\u30f3\u30fb\u30d0\u30eb\u30c6\u30eb\u30df\u30fc",
      },
      kor: {
        official: "\uc0dd\ubc14\ub974\ud154\ub808\ubbf8",
        common: "\uc0dd\ubc14\ub974\ud154\ub808\ubbf8",
      },
      nld: {
        official: "Gemeenschap Saint Barth\u00e9lemy",
        common: "Saint Barth\u00e9lemy",
      },
      per: {
        official: "\u0633\u0646 \u0628\u0627\u0631\u062a\u0644\u0645\u06cc",
        common: "\u0633\u0646 \u0628\u0627\u0631\u062a\u0644\u0645\u06cc",
      },
      pol: {
        official: "Saint-Barth\u00e9lemy",
        common: "Saint-Barth\u00e9lemy",
      },
      por: {
        official: "Coletividade de Saint Barth\u00e9lemy",
        common: "S\u00e3o Bartolomeu",
      },
      rus: {
        official:
          "\u041a\u043e\u043b\u043b\u0435\u043a\u0442\u0438\u0432\u043d\u043e\u0441\u0442\u044c \u0421\u0430\u043d\u043a\u0442 -\u0411\u0430\u0440\u0442\u0435\u043b\u044c\u043c\u0438",
        common:
          "\u0421\u0435\u043d-\u0411\u0430\u0440\u0442\u0435\u043b\u0435\u043c\u0438",
      },
      slk: {
        official: "Sv\u00e4t\u00fd Bartolomej",
        common: "Sv\u00e4t\u00fd Bartolomej",
      },
      spa: {
        official: "Colectividad de San Barth\u00e9lemy",
        common: "San Bartolom\u00e9",
      },
      swe: {
        official: "Saint-Barth\u00e9lemy",
        common: "Saint-Barth\u00e9lemy",
      },
      urd: {
        official:
          "\u0633\u06cc\u0646\u0679 \u0628\u0627\u0631\u062a\u06be\u06cc\u0645\u0644\u06d2",
        common:
          "\u0633\u06cc\u0646\u0679 \u0628\u0627\u0631\u062a\u06be\u06cc\u0645\u0644\u06d2",
      },
      zho: {
        official: "\u5723\u5df4\u6cf0\u52d2\u7c73\u96c6\u4f53",
        common: "\u5723\u5df4\u6cf0\u52d2\u7c73",
      },
    },
    latlng: [18.5, -63.41666666],
    landlocked: false,
    borders: [],
    area: 21,
    flag: "\ud83c\udde7\ud83c\uddf1",
    demonyms: {
      eng: {
        f: "Saint Barth\u00e9lemy Islander",
        m: "Saint Barth\u00e9lemy Islander",
      },
      fra: { f: "Barth\u00e9lom\u00e9enne", m: "Barth\u00e9lom\u00e9en" },
    },
    callingCodes: ["+590"],
  },
  {
    name: {
      common: "Saint Helena, Ascension and Tristan da Cunha",
      official: "Saint Helena, Ascension and Tristan da Cunha",
      native: {
        eng: {
          official: "Saint Helena, Ascension and Tristan da Cunha",
          common: "Saint Helena, Ascension and Tristan da Cunha",
        },
      },
    },
    tld: [".sh", ".ac"],
    cca2: "SH",
    ccn3: "654",
    cca3: "SHN",
    cioc: "",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: {
      GBP: { name: "Pound sterling", symbol: "\u00a3" },
      SHP: { name: "Saint Helena pound", symbol: "\u00a3" },
    },
    idd: { root: "+2", suffixes: ["90", "47"] },
    capital: ["Jamestown"],
    altSpellings: [
      "Saint Helena",
      "St. Helena, Ascension and Tristan da Cunha",
    ],
    region: "Africa",
    subregion: "Western Africa",
    languages: { eng: "English" },
    translations: {
      ces: {
        official: "Svat\u00e1 Helena, Ascension a Tristan da Cunha",
        common: "Svat\u00e1 Helena, Ascension a Tristan da Cunha",
      },
      deu: {
        official: "Sankt Helena, Ascension und Tristan da Cunha",
        common: "St. Helena, Ascension und Tristan da Cunha",
      },
      est: {
        official: "Saint Helena, Ascension ja Tristan da Cunha",
        common: "Saint Helena, Ascension ja Tristan da Cunha",
      },
      fin: {
        official: "Saint Helena, Ascension ja Tristan da Cunha",
        common: "Saint Helena, Ascension ja Tristan da Cunha",
      },
      fra: {
        official: "Sainte-H\u00e9l\u00e8ne, Ascension et Tristan da Cunha",
        common: "Sainte-H\u00e9l\u00e8ne, Ascension et Tristan da Cunha",
      },
      hrv: { official: "Sveta Helena", common: "Sveta Helena" },
      hun: { official: "Szent Ilona", common: "Szent Ilona-sziget" },
      ita: {
        official: "Sant'Elena, Ascensione e Tristan da Cunha",
        common: "Sant'Elena, Ascensione e Tristan da Cunha",
      },
      jpn: {
        official:
          "\u30bb\u30f3\u30c8\u30d8\u30ec\u30ca\u30fb\u30a2\u30bb\u30f3\u30b7\u30e7\u30f3\u304a\u3088\u3073\u30c8\u30ea\u30b9\u30bf\u30f3\u30c0\u30af\u30fc\u30cb\u30e3",
        common:
          "\u30bb\u30f3\u30c8\u30d8\u30ec\u30ca\u30fb\u30a2\u30bb\u30f3\u30b7\u30e7\u30f3\u304a\u3088\u3073\u30c8\u30ea\u30b9\u30bf\u30f3\u30c0\u30af\u30fc\u30cb\u30e3",
      },
      kor: {
        official: "\uc138\uc778\ud2b8\ud5ec\ub808\ub098",
        common: "\uc138\uc778\ud2b8\ud5ec\ub808\ub098",
      },
      nld: {
        official: "Sint-Helena, Ascension en Tristan da Cunha",
        common: "Sint-Helena, Ascension en Tristan da Cunha",
      },
      per: {
        official: "\u0633\u0646\u062a \u0647\u0644\u0646",
        common: "\u0633\u0646\u062a \u0647\u0644\u0646",
      },
      pol: {
        official:
          "Wyspa \u015awi\u0119tej Heleny, Wyspa Wniebowst\u0105pienia i Tristan da Cunha",
        common:
          "Wyspa \u015awi\u0119tej Heleny, Wyspa Wniebowst\u0105pienia i Tristan da Cunha",
      },
      por: {
        official: "Santa Helena, Ascens\u00e3o e Trist\u00e3o da Cunha",
        common: "Santa Helena, Ascens\u00e3o e Trist\u00e3o da Cunha",
      },
      rus: {
        official:
          "\u041e\u0441\u0442\u0440\u043e\u0432\u0430 \u0421\u0432\u044f\u0442\u043e\u0439 \u0415\u043b\u0435\u043d\u044b, \u0412\u043e\u0437\u043d\u0435\u0441\u0435\u043d\u0438\u044f \u0438 \u0422\u0440\u0438\u0441\u0442\u0430\u043d-\u0434\u0430-\u041a\u0443\u043d\u044c\u044f",
        common:
          "\u041e\u0441\u0442\u0440\u043e\u0432\u0430 \u0421\u0432\u044f\u0442\u043e\u0439 \u0415\u043b\u0435\u043d\u044b, \u0412\u043e\u0437\u043d\u0435\u0441\u0435\u043d\u0438\u044f \u0438 \u0422\u0440\u0438\u0441\u0442\u0430\u043d-\u0434\u0430-\u041a\u0443\u043d\u044c\u044f",
      },
      slk: {
        official: "Sv\u00e4t\u00e1 Helena (z\u00e1morsk\u00e9 \u00fazemie)",
        common: "Sv\u00e4t\u00e1 Helena (z\u00e1morsk\u00e9 \u00fazemie)",
      },
      spa: {
        official: "Santa Elena, Ascensi\u00f3n y Trist\u00e1n de Acu\u00f1a",
        common: "Santa Elena, Ascensi\u00f3n y Trist\u00e1n de Acu\u00f1a",
      },
      swe: { official: "Sankta Helena", common: "Sankta Helena" },
      urd: {
        official:
          "\u0633\u06cc\u0646\u0679 \u06c1\u0644\u06cc\u0646\u0627\u060c \u0627\u0633\u06cc\u0646\u0634\u0646 \u0648 \u062a\u0631\u0633\u0679\u0627\u0646 \u062f\u0627 \u06a9\u0648\u0646\u06cc\u0627",
        common:
          "\u0633\u06cc\u0646\u0679 \u06c1\u0644\u06cc\u0646\u0627\u060c \u0627\u0633\u06cc\u0646\u0634\u0646 \u0648 \u062a\u0631\u0633\u0679\u0627\u0646 \u062f\u0627 \u06a9\u0648\u0646\u06cc\u0627",
      },
      zho: {
        official:
          "\u5723\u8d6b\u52d2\u62ff\u3001\u963f\u68ee\u677e\u548c\u7279\u91cc\u65af\u5766-\u8fbe\u5e93\u5c3c\u4e9a",
        common:
          "\u5723\u8d6b\u52d2\u62ff\u3001\u963f\u68ee\u677e\u548c\u7279\u91cc\u65af\u5766-\u8fbe\u5e93\u5c3c\u4e9a",
      },
    },
    latlng: [-15.95, -5.72],
    landlocked: false,
    borders: [],
    area: 394,
    flag: "\ud83c\uddf8\ud83c\udded",
    demonyms: {
      eng: { f: "Saint Helenian", m: "Saint Helenian" },
      fra: { f: "Sainte-H\u00e9l\u00e9noise", m: "Sainte-H\u00e9l\u00e8nois" },
    },
    callingCodes: ["+290", "+247"],
  },
  {
    name: {
      common: "Belarus",
      official: "Republic of Belarus",
      native: {
        bel: {
          official:
            "\u0420\u044d\u0441\u043f\u0443\u0431\u043b\u0456\u043a\u0430 \u0411\u0435\u043b\u0430\u0440\u0443\u0441\u044c",
          common: "\u0411\u0435\u043b\u0430\u0440\u0443\u0301\u0441\u044c",
        },
        rus: {
          official:
            "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0411\u0435\u043b\u0430\u0440\u0443\u0441\u044c",
          common: "\u0411\u0435\u043b\u0430\u0440\u0443\u0441\u044c",
        },
      },
    },
    tld: [".by"],
    cca2: "BY",
    ccn3: "112",
    cca3: "BLR",
    cioc: "BLR",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { BYN: { name: "Belarusian ruble", symbol: "Br" } },
    idd: { root: "+3", suffixes: ["75"] },
    capital: ["Minsk"],
    altSpellings: [
      "BY",
      "Bielaru\u015b",
      "Republic of Belarus",
      "\u0411\u0435\u043b\u043e\u0440\u0443\u0441\u0441\u0438\u044f",
      "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0411\u0435\u043b\u043e\u0440\u0443\u0441\u0441\u0438\u044f",
    ],
    region: "Europe",
    subregion: "Eastern Europe",
    languages: { bel: "Belarusian", rus: "Russian" },
    translations: {
      ces: {
        official: "B\u011blorusk\u00e1 republika",
        common: "B\u011blorusko",
      },
      cym: { official: "Gweriniaeth Belarws", common: "Belarws" },
      deu: { official: "Republik Belarus", common: "Wei\u00dfrussland" },
      est: { official: "Valgevene Vabariik", common: "Valgevene" },
      fin: {
        official: "Valko-Ven\u00e4j\u00e4n tasavalta",
        common: "Valko-Ven\u00e4j\u00e4",
      },
      fra: {
        official: "R\u00e9publique de Bi\u00e9lorussie",
        common: "Bi\u00e9lorussie",
      },
      hrv: { official: "Republika Bjelorusija", common: "Bjelorusija" },
      hun: {
        official: "Feh\u00e9rorosz K\u00f6zt\u00e1rsas\u00e1g",
        common: "Feh\u00e9roroszorsz\u00e1g",
      },
      ita: { official: "Repubblica di Belarus", common: "Bielorussia" },
      jpn: {
        official: "\u30d9\u30e9\u30eb\u30fc\u30b7\u5171\u548c\u56fd",
        common: "\u30d9\u30e9\u30eb\u30fc\u30b7",
      },
      kor: {
        official: "\ubca8\ub77c\ub8e8\uc2a4 \uacf5\ud654\uad6d",
        common: "\ubca8\ub77c\ub8e8\uc2a4",
      },
      nld: { official: "Republiek Belarus", common: "Wit-Rusland" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0628\u0644\u0627\u0631\u0648\u0633",
        common: "\u0628\u0644\u0627\u0631\u0648\u0633",
      },
      pol: {
        official: "Republika Bia\u0142orusi",
        common: "Bia\u0142oru\u015b",
      },
      por: {
        official: "Rep\u00fablica da Bielorr\u00fassia",
        common: "Bielor\u00fassia",
      },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0411\u0435\u043b\u0430\u0440\u0443\u0441\u044c",
        common: "\u0411\u0435\u043b\u0430\u0440\u0443\u0441\u044c",
      },
      slk: { official: "Bielorusk\u00e1 republika", common: "Bielorusko" },
      spa: {
        official: "Rep\u00fablica de Belar\u00fas",
        common: "Bielorrusia",
      },
      swe: { official: "Republiken Vitryssland", common: "Belarus" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0628\u06cc\u0644\u0627\u0631\u0648\u0633",
        common: "\u0628\u06cc\u0644\u0627\u0631\u0648\u0633",
      },
      zho: {
        official: "\u767d\u4fc4\u7f57\u65af\u5171\u548c\u56fd",
        common: "\u767d\u4fc4\u7f57\u65af",
      },
    },
    latlng: [53, 28],
    landlocked: true,
    borders: ["LVA", "LTU", "POL", "RUS", "UKR"],
    area: 207600,
    flag: "\ud83c\udde7\ud83c\uddfe",
    demonyms: {
      eng: { f: "Belarusian", m: "Belarusian" },
      fra: { f: "Bi\u00e9lorusse", m: "Bi\u00e9lorusse" },
    },
    callingCodes: ["+375"],
  },
  {
    name: {
      common: "Belize",
      official: "Belize",
      native: {
        bjz: { official: "Belize", common: "Belize" },
        eng: { official: "Belize", common: "Belize" },
        spa: { official: "Belice", common: "Belice" },
      },
    },
    tld: [".bz"],
    cca2: "BZ",
    ccn3: "084",
    cca3: "BLZ",
    cioc: "BIZ",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { BZD: { name: "Belize dollar", symbol: "$" } },
    idd: { root: "+5", suffixes: ["01"] },
    capital: ["Belmopan"],
    altSpellings: ["BZ"],
    region: "Americas",
    subregion: "Central America",
    languages: { bjz: "Belizean Creole", eng: "English", spa: "Spanish" },
    translations: {
      ces: { official: "Belize", common: "Belize" },
      cym: { official: "Bel\u00ees", common: "Bel\u00ees" },
      deu: { official: "Belize", common: "Belize" },
      est: { official: "Belize", common: "Belize" },
      fin: { official: "Belize", common: "Belize" },
      fra: { official: "Belize", common: "Belize" },
      hrv: { official: "Belize", common: "Belize" },
      hun: { official: "Belize", common: "Belize" },
      ita: { official: "Belize", common: "Belize" },
      jpn: {
        official: "\u30d9\u30ea\u30fc\u30ba",
        common: "\u30d9\u30ea\u30fc\u30ba",
      },
      kor: { official: "\ubca8\ub9ac\uc988", common: "\ubca8\ub9ac\uc988" },
      nld: { official: "Belize", common: "Belize" },
      per: {
        official: "\u0628\u0644\u06cc\u0632",
        common: "\u0628\u0644\u06cc\u0632",
      },
      pol: { official: "Belize", common: "Belize" },
      por: { official: "Belize", common: "Belize" },
      rus: {
        official: "\u0411\u0435\u043b\u0438\u0437",
        common: "\u0411\u0435\u043b\u0438\u0437",
      },
      slk: { official: "Belize", common: "Belize" },
      spa: { official: "Belice", common: "Belice" },
      swe: { official: "Belize", common: "Belize" },
      urd: {
        official: "\u0628\u06cc\u0644\u06cc\u0632",
        common: "\u0628\u06cc\u0644\u06cc\u0632",
      },
      zho: { official: "\u4f2f\u5229\u5179", common: "\u4f2f\u5229\u5179" },
    },
    latlng: [17.25, -88.75],
    landlocked: false,
    borders: ["GTM", "MEX"],
    area: 22966,
    flag: "\ud83c\udde7\ud83c\uddff",
    demonyms: {
      eng: { f: "Belizean", m: "Belizean" },
      fra: { f: "B\u00e9lizienne", m: "B\u00e9lizien" },
    },
    callingCodes: ["+501"],
  },
  {
    name: {
      common: "Bermuda",
      official: "Bermuda",
      native: { eng: { official: "Bermuda", common: "Bermuda" } },
    },
    tld: [".bm"],
    cca2: "BM",
    ccn3: "060",
    cca3: "BMU",
    cioc: "BER",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: { BMD: { name: "Bermudian dollar", symbol: "$" } },
    idd: { root: "+1", suffixes: ["441"] },
    capital: ["Hamilton"],
    altSpellings: [
      "BM",
      "The Islands of Bermuda",
      "The Bermudas",
      "Somers Isles",
    ],
    region: "Americas",
    subregion: "North America",
    languages: { eng: "English" },
    translations: {
      ces: { official: "Bermudsk\u00e9 ostrovy", common: "Bermudy" },
      cym: { official: "Bermiwda", common: "Bermiwda" },
      deu: { official: "Bermuda", common: "Bermuda" },
      est: { official: "Bermuda", common: "Bermuda" },
      fin: { official: "Bermuda", common: "Bermuda" },
      fra: { official: "Bermudes", common: "Bermudes" },
      hrv: { official: "Bermuda", common: "Bermudi" },
      hun: { official: "Bermuda", common: "Bermuda" },
      ita: { official: "Bermuda", common: "Bermuda" },
      jpn: {
        official: "\u30d0\u30df\u30e5\u30fc\u30c0",
        common: "\u30d0\u30df\u30e5\u30fc\u30c0",
      },
      kor: { official: "\ubc84\ubba4\ub2e4", common: "\ubc84\ubba4\ub2e4" },
      nld: { official: "Bermuda", common: "Bermuda" },
      per: {
        official:
          "\u062c\u0632\u0627\u06cc\u0631 \u0628\u0631\u0645\u0648\u062f\u0627",
        common: "\u0628\u0631\u0645\u0648\u062f\u0627",
      },
      pol: { official: "Bermudy", common: "Bermudy" },
      por: { official: "Bermudas", common: "Bermudas" },
      rus: {
        official:
          "\u0411\u0435\u0440\u043c\u0443\u0434\u0441\u043a\u0438\u0435 \u043e\u0441\u0442\u0440\u043e\u0432\u0430",
        common:
          "\u0411\u0435\u0440\u043c\u0443\u0434\u0441\u043a\u0438\u0435 \u041e\u0441\u0442\u0440\u043e\u0432\u0430",
      },
      slk: { official: "Bermudy", common: "Bermudy" },
      spa: { official: "Bermuda", common: "Bermudas" },
      swe: { official: "Bermuda", common: "Bermuda" },
      urd: {
        official: "\u0628\u0631\u0645\u0648\u062f\u0627",
        common: "\u0628\u0631\u0645\u0648\u062f\u0627",
      },
      zho: { official: "\u767e\u6155\u5927", common: "\u767e\u6155\u5927" },
    },
    latlng: [32.33333333, -64.75],
    landlocked: false,
    borders: [],
    area: 54,
    flag: "\ud83c\udde7\ud83c\uddf2",
    demonyms: {
      eng: { f: "Bermudian", m: "Bermudian" },
      fra: { f: "Bermudienne", m: "Bermudien" },
    },
    callingCodes: ["+1441"],
  },
  {
    name: {
      common: "Bolivia",
      official: "Plurinational State of Bolivia",
      native: {
        aym: { official: "Wuliwya Suyu", common: "Wuliwya" },
        grn: { official: "Tet\u00e3 Vol\u00edvia", common: "Vol\u00edvia" },
        que: { official: "Buliwya Mamallaqta", common: "Buliwya" },
        spa: { official: "Estado Plurinacional de Bolivia", common: "Bolivia" },
      },
    },
    tld: [".bo"],
    cca2: "BO",
    ccn3: "068",
    cca3: "BOL",
    cioc: "BOL",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { BOB: { name: "Bolivian boliviano", symbol: "Bs." } },
    idd: { root: "+5", suffixes: ["91"] },
    capital: ["Sucre"],
    altSpellings: [
      "BO",
      "Buliwya",
      "Wuliwya",
      "Bolivia, Plurinational State of",
      "Plurinational State of Bolivia",
      "Estado Plurinacional de Bolivia",
      "Buliwya Mamallaqta",
      "Wuliwya Suyu",
      "Tet\u00e3 Vol\u00edvia",
    ],
    region: "Americas",
    subregion: "South America",
    languages: {
      aym: "Aymara",
      grn: "Guaran\u00ed",
      que: "Quechua",
      spa: "Spanish",
    },
    translations: {
      ces: {
        official: "Mnohon\u00e1rodnostn\u00ed st\u00e1t Bol\u00edvie",
        common: "Bol\u00edvie",
      },
      cym: { official: "Gweriniaeth Bolifia", common: "Bolifia" },
      deu: { official: "Plurinationaler Staat Bolivien", common: "Bolivien" },
      est: { official: "Boliivia Paljurahvuseline Riik", common: "Boliivia" },
      fin: { official: "Bolivian monikansainen valtio", common: "Bolivia" },
      fra: {
        official: "\u00c9tat plurinational de Bolivie",
        common: "Bolivie",
      },
      hrv: { official: "Plurinational State of Bolivia", common: "Bolivija" },
      hun: {
        official: "Bol\u00edviai T\u00f6bbnemzetis\u00e9g\u0171 \u00c1llam",
        common: "Bol\u00edvia",
      },
      ita: {
        official: "Stato Plurinazionale della Bolivia",
        common: "Bolivia",
      },
      jpn: {
        official: "\u30dc\u30ea\u30d3\u30a2\u306e\u591a\u6c11\u65cf\u56fd",
        common: "\u30dc\u30ea\u30d3\u30a2\u591a\u6c11\u65cf\u56fd",
      },
      kor: {
        official: "\ubcfc\ub9ac\ube44\uc544 \ub2e4\ubbfc\uc871\uad6d",
        common: "\ubcfc\ub9ac\ube44\uc544",
      },
      nld: { official: "Plurinationale Staat van Bolivia", common: "Bolivia" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0628\u0648\u0644\u06cc\u0648\u06cc",
        common: "\u0628\u0648\u0644\u06cc\u0648\u06cc",
      },
      pol: {
        official: "Wielonarodowe Pa\u0144stwo Boliwia",
        common: "Boliwia",
      },
      por: {
        official: "Estado Plurinacional da Bol\u00edvia",
        common: "Bol\u00edvia",
      },
      rus: {
        official:
          "\u041c\u043d\u043e\u0433\u043e\u043d\u0430\u0446\u0438\u043e\u043d\u0430\u043b\u044c\u043d\u043e\u0435 \u0413\u043e\u0441\u0443\u0434\u0430\u0440\u0441\u0442\u0432\u043e \u0411\u043e\u043b\u0438\u0432\u0438\u044f",
        common: "\u0411\u043e\u043b\u0438\u0432\u0438\u044f",
      },
      slk: {
        official: "Bol\u00edvijsk\u00e1 republika",
        common: "Bol\u00edvia",
      },
      spa: { official: "Estado Plurinacional de Bolivia", common: "Bolivia" },
      swe: {
        official: "M\u00e5ngnationella staten Bolivia",
        common: "Bolivia",
      },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0628\u0648\u0644\u06cc\u0648\u06cc\u0627",
        common: "\u0628\u0648\u0644\u06cc\u0648\u06cc\u0627",
      },
      zho: {
        official: "\u591a\u6c11\u65cf\u73bb\u5229\u7ef4\u4e9a\u56fd",
        common: "\u73bb\u5229\u7ef4\u4e9a",
      },
    },
    latlng: [-17, -65],
    landlocked: true,
    borders: ["ARG", "BRA", "CHL", "PRY", "PER"],
    area: 1098581,
    flag: "\ud83c\udde7\ud83c\uddf4",
    demonyms: {
      eng: { f: "Bolivian", m: "Bolivian" },
      fra: { f: "Bolivienne", m: "Bolivien" },
    },
    callingCodes: ["+591"],
  },
  {
    name: {
      common: "Caribbean Netherlands",
      official: "Bonaire, Sint Eustatius and Saba",
      native: {
        nld: {
          official: "Bonaire, Sint Eustatius en Saba",
          common: "Caribisch Nederland",
        },
        pap: {
          official: "Boneiru, Sint Eustatius y Saba",
          common: "Boneiru, Sint Eustatius y Saba",
        },
      },
    },
    tld: [".bq", ".nl"],
    cca2: "BQ",
    ccn3: "535",
    cca3: "BES",
    cioc: "",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: { USD: { name: "United States dollar", symbol: "$" } },
    idd: { root: "+5", suffixes: ["99"] },
    capital: [],
    altSpellings: ["BES islands"],
    region: "Americas",
    subregion: "Caribbean",
    languages: { eng: "English", nld: "Dutch", pap: "Papiamento" },
    translations: {
      ces: {
        official: "Karibsk\u00e9 Nizozemsko",
        common: "Karibsk\u00e9 Nizozemsko",
      },
      deu: {
        official: "Bonaire, Sint Eustatius und Saba",
        common: "Karibische Niederlande",
      },
      est: {
        official: "Bonaire, Sint Eustatius ja Saba",
        common: "Bonaire, Sint Eustatius ja Saba",
      },
      fin: {
        official: "Bonaire, Sint Eustatius ja Saba",
        common: "Bonaire, Sint Eustatius ja Saba",
      },
      fra: {
        official: "Bonaire, Saint-Eustache et Saba",
        common: "Pays-Bas carib\u00e9ens",
      },
      hrv: {
        official: "Bonaire, Sint Eustatius i Saba",
        common: "Bonaire, Sint Eustatius i Saba",
      },
      hun: { official: "Bonaire", common: "Bonaire" },
      ita: {
        official: "Bonaire, Sint Eustatius e Saba",
        common: "Paesi Bassi caraibici",
      },
      jpn: {
        official:
          "\u30dc\u30cd\u30fc\u30eb\u3001\u30b7\u30f3\u30c8\u30fb\u30e6\u30fc\u30b9\u30bf\u30c6\u30a3\u30a6\u30b9\u304a\u3088\u3073\u30b5\u30d0",
        common:
          "\u30dc\u30cd\u30fc\u30eb\u3001\u30b7\u30f3\u30c8\u30fb\u30e6\u30fc\u30b9\u30bf\u30c6\u30a3\u30a6\u30b9\u304a\u3088\u3073\u30b5\u30d0",
      },
      kor: {
        official:
          "\ubcf4\ub124\ub974, \uc2e0\ud2b8\uc678\uc2a4\ud0c0\ud2f0\uc704\uc2a4, \uc0ac\ubc14",
        common: "\uce74\ub9ac\ube0c \ub124\ub35c\ub780\ub4dc",
      },
      nld: {
        official: "Bonaire, Sint Eustatius en Saba",
        common: "Caribisch Nederland",
      },
      per: {
        official:
          "\u062c\u0632\u0627\u06cc\u0631 \u06a9\u0627\u0631\u0627\u0626\u06cc\u0628 \u0647\u0644\u0646\u062f",
        common:
          "\u062c\u0632\u0627\u06cc\u0631 \u06a9\u0627\u0631\u0627\u0626\u06cc\u0628 \u0647\u0644\u0646\u062f",
      },
      pol: {
        official: "Bonaire, Sint Eustatius i Saba",
        common: "Antyle Holenderskie",
      },
      por: {
        official: "Bonaire, Saba e Santo Eust\u00e1quio",
        common: "Pa\u00edses Baixos Caribenhos",
      },
      rus: {
        official:
          "\u0411\u043e\u043d\u044d\u0439\u0440, \u0421\u0438\u043d\u0442-\u042d\u0441\u0442\u0430\u0442\u0438\u0443\u0441 \u0438 \u0421\u0430\u0431\u0430",
        common:
          "\u041a\u0430\u0440\u0438\u0431\u0441\u043a\u0438\u0435 \u041d\u0438\u0434\u0435\u0440\u043b\u0430\u043d\u0434\u044b",
      },
      slk: {
        official: "Bonaire, Sint Eustatius a Saba",
        common: "Bonaire, Sint Eustatius a Saba",
      },
      spa: {
        official: "Bonaire, San Eustaquio y Saba",
        common: "Caribe Neerland\u00e9s",
      },
      swe: {
        official: "Bonaire, Sint Eustatius and Saba",
        common: "Karibiska Nederl\u00e4nderna",
      },
      urd: {
        official:
          "\u0628\u0648\u0646\u0627\u06cc\u0631\u060c \u0633\u06cc\u0646\u0679 \u0627\u06cc\u0648\u0633\u0679\u0627\u0626\u06cc\u0633 \u0627\u0648\u0631 \u0633\u0627\u0628\u0627",
        common:
          "\u06a9\u06cc\u0631\u06cc\u0628\u06cc\u0646 \u0646\u06cc\u062f\u0631\u0644\u06cc\u0646\u0688\u0632",
      },
      zho: {
        official: "\u8377\u862d\u52a0\u52d2\u6bd4\u5340",
        common: "\u8377\u862d\u52a0\u52d2\u6bd4\u5340",
      },
    },
    latlng: [12.18, -68.25],
    landlocked: false,
    borders: [],
    area: 328,
    flag: "",
    demonyms: {
      eng: { f: "Dutch", m: "Dutch" },
      fra: { f: "N\u00e9erlandaise", m: "N\u00e9erlandais" },
    },
    callingCodes: ["+599"],
  },
  {
    name: {
      common: "Brazil",
      official: "Federative Republic of Brazil",
      native: {
        por: {
          official: "Rep\u00fablica Federativa do Brasil",
          common: "Brasil",
        },
      },
    },
    tld: [".br"],
    cca2: "BR",
    ccn3: "076",
    cca3: "BRA",
    cioc: "BRA",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { BRL: { name: "Brazilian real", symbol: "R$" } },
    idd: { root: "+5", suffixes: ["5"] },
    capital: ["Bras\u00edlia"],
    altSpellings: [
      "BR",
      "Brasil",
      "Federative Republic of Brazil",
      "Rep\u00fablica Federativa do Brasil",
    ],
    region: "Americas",
    subregion: "South America",
    languages: { por: "Portuguese" },
    translations: {
      ces: {
        official: "Brazilsk\u00e1 federativn\u00ed republika",
        common: "Braz\u00edlie",
      },
      cym: { official: "Gweriniaeth Ffederal Brasil", common: "Brasil" },
      deu: {
        official: "F\u00f6derative Republik Brasilien",
        common: "Brasilien",
      },
      est: { official: "Brasiilia Liitvabariik", common: "Brasiilia" },
      fin: { official: "Brasilian liittotasavalta", common: "Brasilia" },
      fra: {
        official: "R\u00e9publique f\u00e9d\u00e9rative du Br\u00e9sil",
        common: "Br\u00e9sil",
      },
      hrv: { official: "Savezne Republike Brazil", common: "Brazil" },
      hun: {
        official: "Brazil Sz\u00f6vets\u00e9gi K\u00f6zt\u00e1rsas\u00e1g",
        common: "Braz\u00edlia",
      },
      ita: { official: "Repubblica federativa del Brasile", common: "Brasile" },
      jpn: {
        official: "\u30d6\u30e9\u30b8\u30eb\u9023\u90a6\u5171\u548c\u56fd",
        common: "\u30d6\u30e9\u30b8\u30eb",
      },
      kor: {
        official: "\ube0c\ub77c\uc9c8 \uc5f0\ubc29 \uacf5\ud654\uad6d",
        common: "\ube0c\ub77c\uc9c8",
      },
      nld: {
        official: "Federale Republiek Brazili\u00eb",
        common: "Brazili\u00eb",
      },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0641\u062f\u0631\u0627\u062a\u06cc\u0648 \u0628\u0631\u0632\u06cc\u0644",
        common: "\u0628\u0631\u0632\u06cc\u0644",
      },
      pol: { official: "Federacyjna Republika Brazylii", common: "Brazylia" },
      por: {
        official: "Rep\u00fablica Federativa do Brasil",
        common: "Brasil",
      },
      rus: {
        official:
          "\u0424\u0435\u0434\u0435\u0440\u0430\u0442\u0438\u0432\u043d\u0430\u044f \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0411\u0440\u0430\u0437\u0438\u043b\u0438\u044f",
        common: "\u0411\u0440\u0430\u0437\u0438\u043b\u0438\u044f",
      },
      slk: {
        official: "Braz\u00edlska federat\u00edvna republika",
        common: "Braz\u00edlia",
      },
      spa: {
        official: "Rep\u00fablica Federativa del Brasil",
        common: "Brasil",
      },
      swe: {
        official: "F\u00f6rbundsrepubliken Brasilien",
        common: "Brasilien",
      },
      urd: {
        official:
          "\u0648\u0641\u0627\u0642\u06cc \u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0628\u0631\u0627\u0632\u06cc\u0644",
        common: "\u0628\u0631\u0627\u0632\u06cc\u0644",
      },
      zho: {
        official: "\u5df4\u897f\u8054\u90a6\u5171\u548c\u56fd",
        common: "\u5df4\u897f",
      },
    },
    latlng: [-10, -55],
    landlocked: false,
    borders: [
      "ARG",
      "BOL",
      "COL",
      "GUF",
      "GUY",
      "PRY",
      "PER",
      "SUR",
      "URY",
      "VEN",
    ],
    area: 8515767,
    flag: "\ud83c\udde7\ud83c\uddf7",
    demonyms: {
      eng: { f: "Brazilian", m: "Brazilian" },
      fra: { f: "Br\u00e9silienne", m: "Br\u00e9silien" },
    },
    callingCodes: ["+55"],
  },
  {
    name: {
      common: "Barbados",
      official: "Barbados",
      native: { eng: { official: "Barbados", common: "Barbados" } },
    },
    tld: [".bb"],
    cca2: "BB",
    ccn3: "052",
    cca3: "BRB",
    cioc: "BAR",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { BBD: { name: "Barbadian dollar", symbol: "$" } },
    idd: { root: "+1", suffixes: ["246"] },
    capital: ["Bridgetown"],
    altSpellings: ["BB"],
    region: "Americas",
    subregion: "Caribbean",
    languages: { eng: "English" },
    translations: {
      ces: { official: "Barbados", common: "Barbados" },
      cym: { official: "Barbados", common: "Barbados" },
      deu: { official: "Barbados", common: "Barbados" },
      est: { official: "Barbados", common: "Barbados" },
      fin: { official: "Barbados", common: "Barbados" },
      fra: { official: "Barbade", common: "Barbade" },
      hrv: { official: "Barbados", common: "Barbados" },
      hun: { official: "Barbados", common: "Barbados" },
      ita: { official: "Barbados", common: "Barbados" },
      jpn: {
        official: "\u30d0\u30eb\u30d0\u30c9\u30b9",
        common: "\u30d0\u30eb\u30d0\u30c9\u30b9",
      },
      kor: {
        official: "\ubc14\ubca0\uc774\ub3c4\uc2a4",
        common: "\ubc14\ubca0\uc774\ub3c4\uc2a4",
      },
      nld: { official: "Barbados", common: "Barbados" },
      per: {
        official: "\u0628\u0627\u0631\u0628\u0627\u062f\u0648\u0633",
        common: "\u0628\u0627\u0631\u0628\u0627\u062f\u0648\u0633",
      },
      pol: { official: "Barbados", common: "Barbados" },
      por: { official: "Barbados", common: "Barbados" },
      rus: {
        official: "\u0411\u0430\u0440\u0431\u0430\u0434\u043e\u0441",
        common: "\u0411\u0430\u0440\u0431\u0430\u0434\u043e\u0441",
      },
      slk: { official: "Barbados", common: "Barbados" },
      spa: { official: "Barbados", common: "Barbados" },
      swe: { official: "Barbados", common: "Barbados" },
      urd: {
        official: "\u0628\u0627\u0631\u0628\u0627\u0688\u0648\u0633",
        common: "\u0628\u0627\u0631\u0628\u0627\u0688\u0648\u0633",
      },
      zho: {
        official: "\u5df4\u5df4\u591a\u65af",
        common: "\u5df4\u5df4\u591a\u65af",
      },
    },
    latlng: [13.16666666, -59.53333333],
    landlocked: false,
    borders: [],
    area: 430,
    flag: "\ud83c\udde7\ud83c\udde7",
    demonyms: {
      eng: { f: "Barbadian", m: "Barbadian" },
      fra: { f: "Barbadienne", m: "Barbadien" },
    },
    callingCodes: ["+1246"],
  },
  {
    name: {
      common: "Brunei",
      official: "Nation of Brunei, Abode of Peace",
      native: {
        msa: {
          official: "Nation of Brunei, Abode Damai",
          common: "Negara Brunei Darussalam",
        },
      },
    },
    tld: [".bn"],
    cca2: "BN",
    ccn3: "096",
    cca3: "BRN",
    cioc: "BRU",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: {
      BND: { name: "Brunei dollar", symbol: "$" },
      SGD: { name: "Singapore dollar", symbol: "$" },
    },
    idd: { root: "+6", suffixes: ["73"] },
    capital: ["Bandar Seri Begawan"],
    altSpellings: [
      "BN",
      "Brunei Darussalam",
      "Nation of Brunei",
      "the Abode of Peace",
    ],
    region: "Asia",
    subregion: "South-Eastern Asia",
    languages: { msa: "Malay" },
    translations: {
      ces: { official: "Sultan\u00e1t Brunej", common: "Brunej" },
      cym: { official: "Teyrnas Brwnei", common: "Brunei" },
      deu: { official: "Sultanat Brunei Darussalam", common: "Brunei" },
      est: { official: "Brunei Darussalami Riik", common: "Brunei" },
      fin: { official: "Brunei Darussalamin valtio", common: "Brunei" },
      fra: { official: "\u00c9tat de Brunei Darussalam", common: "Brunei" },
      hrv: { official: "Nacija od Bruneja, Ku\u0107u Mira", common: "Brunej" },
      hun: { official: "Brunei Szultan\u00e1tus", common: "Brunei" },
      ita: {
        official: "Nazione di Brunei, Dimora della Pace",
        common: "Brunei",
      },
      jpn: {
        official:
          "\u30d6\u30eb\u30cd\u30a4\u3001\u5e73\u548c\u306e\u7cbe\u820e\u306e\u56fd\u5bb6",
        common:
          "\u30d6\u30eb\u30cd\u30a4\u30fb\u30c0\u30eb\u30b5\u30e9\u30fc\u30e0",
      },
      kor: {
        official: "\ube0c\ub8e8\ub098\uc774 \ub2e4\ub8e8\uc0b4\ub78c\uad6d",
        common: "\ube0c\ub8e8\ub098\uc774",
      },
      nld: {
        official: "Natie van Brunei, de verblijfplaats van de Vrede",
        common: "Brunei",
      },
      per: {
        official:
          "\u0628\u0631\u0648\u0646\u0626\u06cc \u0633\u0631\u0627\u06cc \u0635\u0644\u062d",
        common: "\u0628\u0631\u0648\u0646\u0626\u06cc",
      },
      pol: { official: "Pa\u0144stwo Brunei Darussalam", common: "Brunei" },
      por: {
        official: "Na\u00e7\u00e3o do Brunei, Morada da Paz",
        common: "Brunei",
      },
      rus: {
        official:
          "\u041d\u0430\u0446\u0438\u044f \u0411\u0440\u0443\u043d\u0435\u0439, \u043e\u0431\u0438\u0442\u0435\u043b\u044c \u043c\u0438\u0440\u0430",
        common: "\u0411\u0440\u0443\u043d\u0435\u0439",
      },
      slk: { official: "Brunejsk\u00fd sultan\u00e2t", common: "Brunej" },
      spa: {
        official: "Naci\u00f3n de Brunei, Morada de la Paz",
        common: "Brunei",
      },
      swe: { official: "Brunei Darussalam", common: "Brunei" },
      urd: {
        official:
          "\u0631\u06cc\u0627\u0633\u062a\u0650 \u0628\u0631\u0648\u0646\u0627\u0626\u06cc \u062f\u0627\u0631\u0627\u0644\u0633\u0644\u0627\u0645",
        common: "\u0628\u0631\u0648\u0646\u0627\u0626\u06cc",
      },
      zho: {
        official: "\u6587\u83b1\u548c\u5e73\u4e4b\u56fd",
        common: "\u6587\u83b1",
      },
    },
    latlng: [4.5, 114.66666666],
    landlocked: false,
    borders: ["MYS"],
    area: 5765,
    flag: "\ud83c\udde7\ud83c\uddf3",
    demonyms: {
      eng: { f: "Bruneian", m: "Bruneian" },
      fra: { f: "Brun\u00e9ienne", m: "Brun\u00e9ien" },
    },
    callingCodes: ["+673"],
  },
  {
    name: {
      common: "Bhutan",
      official: "Kingdom of Bhutan",
      native: {
        dzo: {
          official:
            "\u0f60\u0f56\u0fb2\u0f74\u0f42\u0f0b\u0f62\u0f92\u0fb1\u0f63\u0f0b\u0f41\u0f56\u0f0b",
          common:
            "\u0f60\u0f56\u0fb2\u0f74\u0f42\u0f0b\u0f61\u0f74\u0f63\u0f0b",
        },
      },
    },
    tld: [".bt"],
    cca2: "BT",
    ccn3: "064",
    cca3: "BTN",
    cioc: "BHU",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: {
      BTN: { name: "Bhutanese ngultrum", symbol: "Nu." },
      INR: { name: "Indian rupee", symbol: "\u20b9" },
    },
    idd: { root: "+9", suffixes: ["75"] },
    capital: ["Thimphu"],
    altSpellings: ["BT", "Kingdom of Bhutan"],
    region: "Asia",
    subregion: "Southern Asia",
    languages: { dzo: "Dzongkha" },
    translations: {
      ces: {
        official: "Bh\u00fat\u00e1nsk\u00e9 kr\u00e1lovstv\u00ed",
        common: "Bh\u00fat\u00e1n",
      },
      cym: { official: "Teyrnas Bhwtan", common: "Bhwtan" },
      deu: { official: "K\u00f6nigreich Bhutan", common: "Bhutan" },
      est: { official: "Bhutani Kuningriik", common: "Bhutan" },
      fin: { official: "Bhutanin kuningaskunta", common: "Bhutan" },
      fra: { official: "Royaume du Bhoutan", common: "Bhoutan" },
      hrv: { official: "Kraljevina Butan", common: "Butan" },
      hun: {
        official: "Bhut\u00e1ni Kir\u00e1lys\u00e1g",
        common: "Bhut\u00e1n",
      },
      ita: { official: "Regno del Bhutan", common: "Bhutan" },
      jpn: {
        official: "\u30d6\u30fc\u30bf\u30f3\u738b\u56fd",
        common: "\u30d6\u30fc\u30bf\u30f3",
      },
      kor: { official: "\ubd80\ud0c4 \uc655\uad6d", common: "\ubd80\ud0c4" },
      nld: { official: "Koninkrijk Bhutan", common: "Bhutan" },
      per: {
        official:
          "\u067e\u0627\u062f\u0634\u0627\u0647\u06cc \u0628\u0648\u062a\u0627\u0646",
        common: "\u0628\u0648\u062a\u0627\u0646",
      },
      pol: { official: "Bhutan", common: "Bhutan" },
      por: { official: "Reino do But\u00e3o", common: "But\u00e3o" },
      rus: {
        official:
          "\u041a\u043e\u0440\u043e\u043b\u0435\u0432\u0441\u0442\u0432\u043e \u0411\u0443\u0442\u0430\u043d",
        common: "\u0411\u0443\u0442\u0430\u043d",
      },
      slk: {
        official: "Bhut\u00e1nske kr\u00e2\u013eovstvo",
        common: "Bhut\u00e1n",
      },
      spa: { official: "Reino de But\u00e1n", common: "But\u00e1n" },
      swe: { official: "Konungariket Bhutan", common: "Bhutan" },
      urd: {
        official:
          "\u0633\u0644\u0637\u0646\u062a \u0628\u06be\u0648\u0679\u0627\u0646",
        common: "\u0628\u06be\u0648\u0679\u0627\u0646",
      },
      zho: { official: "\u4e0d\u4e39\u738b\u56fd", common: "\u4e0d\u4e39" },
    },
    latlng: [27.5, 90.5],
    landlocked: true,
    borders: ["CHN", "IND"],
    area: 38394,
    flag: "\ud83c\udde7\ud83c\uddf9",
    demonyms: {
      eng: { f: "Bhutanese", m: "Bhutanese" },
      fra: { f: "Bhoutanaise", m: "Bhoutanais" },
    },
    callingCodes: ["+975"],
  },
  {
    name: {
      common: "Bouvet Island",
      official: "Bouvet Island",
      native: { nor: { official: "Bouvet\u00f8ya", common: "Bouvet\u00f8ya" } },
    },
    tld: [".bv"],
    cca2: "BV",
    ccn3: "074",
    cca3: "BVT",
    cioc: "",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [],
    idd: { root: "+4", suffixes: ["7"] },
    capital: [""],
    altSpellings: ["BV", "Bouvet\u00f8ya", "Bouvet-\u00f8ya"],
    region: "Antarctic",
    subregion: "",
    languages: { nor: "Norwegian" },
    translations: {
      ces: { official: "Bouvet\u016fv ostrov", common: "Bouvet\u016fv ostrov" },
      deu: { official: "Bouvetinsel", common: "Bouvetinsel" },
      est: { official: "Bouvet\u2019 saar", common: "Bouvet\u2019 saar" },
      fin: { official: "Bouvet'nsaari", common: "Bouvet'nsaari" },
      fra: { official: "\u00cele Bouvet", common: "\u00cele Bouvet" },
      hrv: { official: "Bouvet Island", common: "Otok Bouvet" },
      hun: { official: "Bouvet-sziget", common: "Bouvet-sziget" },
      ita: { official: "Isola Bouvet", common: "Isola Bouvet" },
      jpn: {
        official: "\u30d6\u30fc\u30f4\u30a7\u5cf6",
        common: "\u30d6\u30fc\u30d9\u5cf6",
      },
      kor: { official: "\ubd80\ubca0 \uc12c", common: "\ubd80\ubca0 \uc12c" },
      nld: { official: "Bouvet Island", common: "Bouveteiland" },
      per: {
        official:
          "\u062c\u0632\u06cc\u0631\u0647\u0654 \u0628\u0648\u0648\u0647",
        common: "\u062c\u0632\u06cc\u0631\u0647\u0654 \u0628\u0648\u0648\u0647",
      },
      pol: { official: "Wyspa Bouveta", common: "Wyspa Bouveta" },
      por: { official: "Ilha Bouvet", common: "Ilha Bouvet" },
      rus: {
        official:
          "\u041e\u0441\u0442\u0440\u043e\u0432 \u0411\u0443\u0432\u0435",
        common: "\u041e\u0441\u0442\u0440\u043e\u0432 \u0411\u0443\u0432\u0435",
      },
      slk: { official: "Bouvetov ostrov", common: "Bouvetov ostrov" },
      spa: { official: "Isla Bouvet", common: "Isla Bouvet" },
      swe: { official: "Bouvet\u00f6n", common: "Bouvet\u00f6n" },
      urd: {
        official: "\u062c\u0632\u06cc\u0631\u06c1 \u0628\u0648\u0648\u06c1",
        common: "\u062c\u0632\u06cc\u0631\u06c1 \u0628\u0648\u0648\u06c1",
      },
      zho: { official: "\u5e03\u7ef4\u5c9b", common: "\u5e03\u7ef4\u5c9b" },
    },
    latlng: [-54.43333333, 3.4],
    landlocked: false,
    borders: [],
    area: 49,
    flag: "\ud83c\udde7\ud83c\uddfb",
    demonyms: { eng: { f: "", m: "" }, fra: { f: "", m: "" } },
    callingCodes: ["+47"],
  },
  {
    name: {
      common: "Botswana",
      official: "Republic of Botswana",
      native: {
        eng: { official: "Republic of Botswana", common: "Botswana" },
        tsn: { official: "Lefatshe la Botswana", common: "Botswana" },
      },
    },
    tld: [".bw"],
    cca2: "BW",
    ccn3: "072",
    cca3: "BWA",
    cioc: "BOT",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { BWP: { name: "Botswana pula", symbol: "P" } },
    idd: { root: "+2", suffixes: ["67"] },
    capital: ["Gaborone"],
    altSpellings: ["BW", "Republic of Botswana", "Lefatshe la Botswana"],
    region: "Africa",
    subregion: "Southern Africa",
    languages: { eng: "English", tsn: "Tswana" },
    translations: {
      ces: { official: "Botswansk\u00e1 republika", common: "Botswana" },
      deu: { official: "Republik Botsuana", common: "Botswana" },
      est: { official: "Botswana Vabariik", common: "Botswana" },
      fin: { official: "Botswanan tasavalta", common: "Botswana" },
      fra: { official: "R\u00e9publique du Botswana", common: "Botswana" },
      hrv: { official: "Republika Bocvana", common: "Bocvana" },
      hun: {
        official: "Botswanai K\u00f6zt\u00e1rsas\u00e1g",
        common: "Botswana",
      },
      ita: { official: "Repubblica del Botswana", common: "Botswana" },
      jpn: {
        official: "\u30dc\u30c4\u30ef\u30ca\u5171\u548c\u56fd",
        common: "\u30dc\u30c4\u30ef\u30ca",
      },
      kor: {
        official: "\ubcf4\uce20\uc640\ub098 \uacf5\ud654\uad6d",
        common: "\ubcf4\uce20\uc640\ub098",
      },
      nld: { official: "Republiek Botswana", common: "Botswana" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0628\u0648\u062a\u0633\u0648\u0627\u0646\u0627",
        common: "\u0628\u0648\u062a\u0633\u0648\u0627\u0646\u0627",
      },
      pol: { official: "Republika Botswany", common: "Botswana" },
      por: { official: "Rep\u00fablica do Botswana", common: "Botswana" },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0411\u043e\u0442\u0441\u0432\u0430\u043d\u0430",
        common: "\u0411\u043e\u0442\u0441\u0432\u0430\u043d\u0430",
      },
      slk: { official: "Botswansk\u00e1 republika", common: "Botswana" },
      spa: { official: "Rep\u00fablica de Botswana", common: "Botswana" },
      swe: { official: "Republiken Botswana", common: "Botswana" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0628\u0648\u0679\u0633\u0648\u0627\u0646\u0627",
        common: "\u0628\u0648\u0679\u0633\u0648\u0627\u0646\u0627",
      },
      zho: {
        official: "\u535a\u8328\u74e6\u7eb3\u5171\u548c\u56fd",
        common: "\u535a\u8328\u74e6\u7eb3",
      },
    },
    latlng: [-22, 24],
    landlocked: true,
    borders: ["NAM", "ZAF", "ZMB", "ZWE"],
    area: 582000,
    flag: "\ud83c\udde7\ud83c\uddfc",
    demonyms: {
      eng: { f: "Motswana", m: "Motswana" },
      fra: { f: "Botswanaise", m: "Botswanais" },
    },
    callingCodes: ["+267"],
  },
  {
    name: {
      common: "Central African Republic",
      official: "Central African Republic",
      native: {
        fra: {
          official: "R\u00e9publique centrafricaine",
          common: "R\u00e9publique centrafricaine",
        },
        sag: {
          official: "K\u00f6d\u00f6r\u00f6s\u00ease t\u00ee B\u00eaafr\u00eeka",
          common: "B\u00eaafr\u00eeka",
        },
      },
    },
    tld: [".cf"],
    cca2: "CF",
    ccn3: "140",
    cca3: "CAF",
    cioc: "CAF",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { XAF: { name: "Central African CFA franc", symbol: "Fr" } },
    idd: { root: "+2", suffixes: ["36"] },
    capital: ["Bangui"],
    altSpellings: [
      "CF",
      "Central African Republic",
      "R\u00e9publique centrafricaine",
    ],
    region: "Africa",
    subregion: "Middle Africa",
    languages: { fra: "French", sag: "Sango" },
    translations: {
      ces: {
        official: "St\u0159edoafrick\u00e1 republika",
        common: "St\u0159edoafrick\u00e1 republika",
      },
      cym: {
        official: "Gweriniaeth Canolbarth Affrica",
        common: "Gweriniaeth Canolbarth Affrica",
      },
      deu: {
        official: "Zentralafrikanische Republik",
        common: "Zentralafrikanische Republik",
      },
      est: {
        official: "Kesk-Aafrika Vabariik",
        common: "Kesk-Aafrika Vabariik",
      },
      fin: {
        official: "Keski-Afrikan tasavalta",
        common: "Keski-Afrikan tasavalta",
      },
      fra: {
        official: "R\u00e9publique centrafricaine",
        common: "R\u00e9publique centrafricaine",
      },
      hrv: {
        official: "Centralna Afri\u010dka Republika",
        common: "Srednjoafri\u010dka Republika",
      },
      hun: {
        official: "K\u00f6z\u00e9p-afrikai K\u00f6zt\u00e1rsas\u00e1g",
        common: "K\u00f6z\u00e9p-afrikai K\u00f6zt\u00e1rsas\u00e1g",
      },
      ita: {
        official: "Repubblica Centrafricana",
        common: "Repubblica Centrafricana",
      },
      jpn: {
        official: "\u4e2d\u592e\u30a2\u30d5\u30ea\u30ab\u5171\u548c\u56fd",
        common: "\u4e2d\u592e\u30a2\u30d5\u30ea\u30ab\u5171\u548c\u56fd",
      },
      kor: {
        official: "\uc911\uc559\uc544\ud504\ub9ac\uce74 \uacf5\ud654\uad6d",
        common: "\uc911\uc559\uc544\ud504\ub9ac\uce74 \uacf5\ud654\uad6d",
      },
      nld: {
        official: "Centraal-Afrikaanse Republiek",
        common: "Centraal-Afrikaanse Republiek",
      },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0622\u0641\u0631\u06cc\u0642\u0627\u06cc \u0645\u0631\u06a9\u0632\u06cc",
        common:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0622\u0641\u0631\u06cc\u0642\u0627\u06cc \u0645\u0631\u06a9\u0632\u06cc",
      },
      pol: {
        official: "Republika \u015arodkowoafryka\u0144ska",
        common: "Republika \u015arodkowoafryka\u0144ska",
      },
      por: {
        official: "Rep\u00fablica Centro-Africano",
        common: "Rep\u00fablica Centro-Africana",
      },
      rus: {
        official:
          "\u0426\u0435\u043d\u0442\u0440\u0430\u043b\u044c\u043d\u043e-\u0410\u0444\u0440\u0438\u043a\u0430\u043d\u0441\u043a\u0430\u044f \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430",
        common:
          "\u0426\u0435\u043d\u0442\u0440\u0430\u043b\u044c\u043d\u043e\u0430\u0444\u0440\u0438\u043a\u0430\u043d\u0441\u043a\u0430\u044f \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430",
      },
      slk: {
        official: "Stredoafrick\u00e1 republika",
        common: "Stredoafrick\u00e1 republika",
      },
      spa: {
        official: "Rep\u00fablica Centroafricana",
        common: "Rep\u00fablica Centroafricana",
      },
      swe: {
        official: "Centralafrikanska republiken",
        common: "Centralafrikanska republiken",
      },
      urd: {
        official:
          "\u0648\u0633\u0637\u06cc \u0627\u0641\u0631\u06cc\u0642\u06cc \u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1",
        common:
          "\u0648\u0633\u0637\u06cc \u0627\u0641\u0631\u06cc\u0642\u06cc \u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1",
      },
      zho: {
        official: "\u4e2d\u975e\u5171\u548c\u56fd",
        common: "\u4e2d\u975e\u5171\u548c\u56fd",
      },
    },
    latlng: [7, 21],
    landlocked: true,
    borders: ["CMR", "TCD", "COD", "COG", "SSD", "SDN"],
    area: 622984,
    flag: "\ud83c\udde8\ud83c\uddeb",
    demonyms: {
      eng: { f: "Central African", m: "Central African" },
      fra: { f: "Centrafricaine", m: "Centrafricain" },
    },
    callingCodes: ["+236"],
  },
  {
    name: {
      common: "Canada",
      official: "Canada",
      native: {
        eng: { official: "Canada", common: "Canada" },
        fra: { official: "Canada", common: "Canada" },
      },
    },
    tld: [".ca"],
    cca2: "CA",
    ccn3: "124",
    cca3: "CAN",
    cioc: "CAN",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { CAD: { name: "Canadian dollar", symbol: "$" } },
    idd: { root: "+1", suffixes: [""] },
    capital: ["Ottawa"],
    altSpellings: ["CA"],
    region: "Americas",
    subregion: "North America",
    languages: { eng: "English", fra: "French" },
    translations: {
      ces: { official: "Kanada", common: "Kanada" },
      cym: { official: "Canada", common: "Canada" },
      deu: { official: "Kanada", common: "Kanada" },
      est: { official: "Kanada", common: "Kanada" },
      fin: { official: "Kanada", common: "Kanada" },
      fra: { official: "Canada", common: "Canada" },
      hrv: { official: "Kanada", common: "Kanada" },
      hun: { official: "Kanada", common: "Kanada" },
      ita: { official: "Canada", common: "Canada" },
      jpn: { official: "\u30ab\u30ca\u30c0", common: "\u30ab\u30ca\u30c0" },
      kor: { official: "\uce90\ub098\ub2e4", common: "\uce90\ub098\ub2e4" },
      nld: { official: "Canada", common: "Canada" },
      per: {
        official: "\u06a9\u0627\u0646\u0627\u062f\u0627",
        common: "\u06a9\u0627\u0646\u0627\u062f\u0627",
      },
      pol: { official: "Kanada", common: "Kanada" },
      por: { official: "Canad\u00e1", common: "Canad\u00e1" },
      rus: {
        official: "\u041a\u0430\u043d\u0430\u0434\u0430",
        common: "\u041a\u0430\u043d\u0430\u0434\u0430",
      },
      slk: { official: "Kanada", common: "Kanada" },
      spa: { official: "Canad\u00e1", common: "Canad\u00e1" },
      swe: { official: "Kanada", common: "Kanada" },
      urd: {
        official: "\u06a9\u06cc\u0646\u06cc\u0688\u0627",
        common: "\u06a9\u06cc\u0646\u06cc\u0688\u0627",
      },
      zho: { official: "\u52a0\u62ff\u5927", common: "\u52a0\u62ff\u5927" },
    },
    latlng: [60, -95],
    landlocked: false,
    borders: ["USA"],
    area: 9984670,
    flag: "\ud83c\udde8\ud83c\udde6",
    demonyms: {
      eng: { f: "Canadian", m: "Canadian" },
      fra: { f: "Canadienne", m: "Canadien" },
    },
    callingCodes: ["+1"],
  },
  {
    name: {
      common: "Cocos (Keeling) Islands",
      official: "Territory of the Cocos (Keeling) Islands",
      native: {
        eng: {
          official: "Territory of the Cocos (Keeling) Islands",
          common: "Cocos (Keeling) Islands",
        },
      },
    },
    tld: [".cc"],
    cca2: "CC",
    ccn3: "166",
    cca3: "CCK",
    cioc: "",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: { AUD: { name: "Australian dollar", symbol: "$" } },
    idd: { root: "+6", suffixes: ["1"] },
    capital: ["West Island"],
    altSpellings: ["CC", "Keeling Islands", "Cocos Islands"],
    region: "Oceania",
    subregion: "Australia and New Zealand",
    languages: { eng: "English" },
    translations: {
      ces: {
        official: "Kokosov\u00e9 ostrovy",
        common: "Kokosov\u00e9 ostrovy",
      },
      cym: {
        official: "Tiriogaeth yr Ynysoedd Cocos (Keeling)",
        common: "Ynysoedd Cocos",
      },
      deu: {
        official: "Gebiet der Kokos- (Keeling-) Inseln",
        common: "Kokosinseln",
      },
      est: { official: "Kookossaarte ala", common: "Kookossaared" },
      fin: { official: "Kookossaaret", common: "Kookossaaret" },
      fra: {
        official: "Territoire des \u00eeles Cocos (Keeling)",
        common: "\u00celes Cocos",
      },
      hrv: {
        official: "Teritoriju Kokosovi (Keeling) Islands",
        common: "Kokosovi Otoci",
      },
      hun: { official: "K\u00f3kusz-szigetek", common: "K\u00f3kusz-szigetek" },
      ita: {
        official: "Territorio della (Keeling) Isole Cocos",
        common: "Isole Cocos e Keeling",
      },
      jpn: {
        official: "\u30b3\u30b3\u30b9\u8af8\u5cf6\u306e\u9818\u571f",
        common:
          "\u30b3\u30b3\u30b9\uff08\u30ad\u30fc\u30ea\u30f3\u30b0\uff09\u8af8\u5cf6",
      },
      kor: {
        official: "\ucf54\ucf54\uc2a4 \uc81c\ub3c4",
        common: "\ucf54\ucf54\uc2a4 \uc81c\ub3c4",
      },
      nld: {
        official: "Grondgebied van de Eilanden Cocos (Keeling )",
        common: "Cocoseilanden",
      },
      per: {
        official:
          "\u062c\u0632\u0627\u06cc\u0631 \u06a9\u0648\u06a9\u0648\u0633",
        common: "\u062c\u0632\u0627\u06cc\u0631 \u06a9\u0648\u06a9\u0648\u0633",
      },
      pol: { official: "Wyspy Kokosowe", common: "Wyspy Kokosowe" },
      por: {
        official: "Territ\u00f3rio dos Cocos (Keeling)",
        common: "Ilhas Cocos (Keeling)",
      },
      rus: {
        official:
          "\u0422\u0435\u0440\u0440\u0438\u0442\u043e\u0440\u0438\u044f \u041a\u043e\u043a\u043e\u0441\u043e\u0432\u044b\u0435 (\u041a\u0438\u043b\u0438\u043d\u0433) \u043e\u0441\u0442\u0440\u043e\u0432\u0430",
        common:
          "\u041a\u043e\u043a\u043e\u0441\u043e\u0432\u044b\u0435 \u043e\u0441\u0442\u0440\u043e\u0432\u0430",
      },
      slk: {
        official: "Kokosov\u00e9 ostrovy",
        common: "Kokosov\u00e9 ostrovy",
      },
      spa: {
        official: "Territorio de los (Keeling) Islas Cocos",
        common: "Islas Cocos o Islas Keeling",
      },
      swe: { official: "Kokos\u00f6arna", common: "Kokos\u00f6arna" },
      urd: {
        official:
          "\u062c\u0632\u0627\u0626\u0631 (\u06a9\u06cc\u0644\u0646\u06af) \u06a9\u0648\u06a9\u0648\u0633",
        common: "\u062c\u0632\u0627\u0626\u0631 \u06a9\u0648\u06a9\u0648\u0633",
      },
      zho: { official: "\u79d1\u79d1\u65af", common: "\u79d1\u79d1\u65af" },
    },
    latlng: [-12.5, 96.83333333],
    landlocked: false,
    borders: [],
    area: 14,
    flag: "\ud83c\udde8\ud83c\udde8",
    demonyms: {
      eng: { f: "Cocos Islander", m: "Cocos Islander" },
      fra: { f: "", m: "" },
    },
    callingCodes: ["+61"],
  },
  {
    name: {
      common: "Switzerland",
      official: "Swiss Confederation",
      native: {
        fra: { official: "Conf\u00e9d\u00e9ration suisse", common: "Suisse" },
        gsw: {
          official: "Schweizerische Eidgenossenschaft",
          common: "Schweiz",
        },
        ita: { official: "Confederazione Svizzera", common: "Svizzera" },
        roh: { official: "Confederaziun svizra", common: "Svizra" },
      },
    },
    tld: [".ch"],
    cca2: "CH",
    ccn3: "756",
    cca3: "CHE",
    cioc: "SUI",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { CHF: { name: "Swiss franc", symbol: "Fr." } },
    idd: { root: "+4", suffixes: ["1"] },
    capital: ["Bern"],
    altSpellings: [
      "CH",
      "Swiss Confederation",
      "Schweiz",
      "Suisse",
      "Svizzera",
      "Svizra",
    ],
    region: "Europe",
    subregion: "Western Europe",
    languages: {
      fra: "French",
      gsw: "Swiss German",
      ita: "Italian",
      roh: "Romansh",
    },
    translations: {
      ces: {
        official: "\u0160v\u00fdcarsk\u00e1 konfederace",
        common: "\u0160v\u00fdcarsko",
      },
      deu: { official: "Schweizerische Eidgenossenschaft", common: "Schweiz" },
      est: {
        official: "\u0160veitsi Konf\u00f6deratsioon",
        common: "\u0160veits",
      },
      fin: { official: "Sveitsin valaliitto", common: "Sveitsi" },
      fra: { official: "Conf\u00e9d\u00e9ration suisse", common: "Suisse" },
      hrv: {
        official: "\u0161vicarska Konfederacija",
        common: "\u0160vicarska",
      },
      hun: { official: "Sv\u00e1jc", common: "Sv\u00e1jc" },
      ita: { official: "Confederazione svizzera", common: "Svizzera" },
      jpn: {
        official: "\u30b9\u30a4\u30b9\u9023\u90a6",
        common: "\u30b9\u30a4\u30b9",
      },
      kor: {
        official: "\uc2a4\uc704\uc2a4 \uc5f0\ubc29",
        common: "\uc2a4\uc704\uc2a4",
      },
      nld: { official: "Zwitserse Confederatie", common: "Zwitserland" },
      per: {
        official:
          "\u06a9\u0646\u0641\u062f\u0631\u0627\u0633\u06cc\u0648\u0646 \u0633\u0648\u0626\u06cc\u0633",
        common: "\u0633\u0648\u0626\u06cc\u0633",
      },
      pol: { official: "Konfederacja Szwajcarska", common: "Szwajcaria" },
      por: {
        official: "Confedera\u00e7\u00e3o Su\u00ed\u00e7a",
        common: "Su\u00ed\u00e7a",
      },
      rus: {
        official:
          "\u0428\u0432\u0435\u0439\u0446\u0430\u0440\u0441\u043a\u0430\u044f \u041a\u043e\u043d\u0444\u0435\u0434\u0435\u0440\u0430\u0446\u0438\u044f",
        common: "\u0428\u0432\u0435\u0439\u0446\u0430\u0440\u0438\u044f",
      },
      slk: {
        official: "\u0160vaj\u010diarska konfeder\u00e1cia",
        common: "\u0160vaj\u010diarsko",
      },
      spa: { official: "Confederaci\u00f3n Suiza", common: "Suiza" },
      swe: { official: "Schweiziska edsf\u00f6rbundet", common: "Schweiz" },
      urd: {
        official:
          "\u0633\u0648\u0626\u06cc\u0633  \u0645\u062a\u062d\u062f\u06c1",
        common: "\u0633\u0648\u06cc\u0679\u0630\u0631\u0644\u06cc\u0646\u0688",
      },
      zho: { official: "\u745e\u58eb\u8054\u90a6", common: "\u745e\u58eb" },
    },
    latlng: [47, 8],
    landlocked: true,
    borders: ["AUT", "FRA", "ITA", "LIE", "DEU"],
    area: 41284,
    flag: "\ud83c\udde8\ud83c\udded",
    demonyms: {
      eng: { f: "Swiss", m: "Swiss" },
      fra: { f: "Suisse", m: "Suisse" },
    },
    callingCodes: ["+41"],
  },
  {
    name: {
      common: "Chile",
      official: "Republic of Chile",
      native: { spa: { official: "Rep\u00fablica de Chile", common: "Chile" } },
    },
    tld: [".cl"],
    cca2: "CL",
    ccn3: "152",
    cca3: "CHL",
    cioc: "CHI",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { CLP: { name: "Chilean peso", symbol: "$" } },
    idd: { root: "+5", suffixes: ["6"] },
    capital: ["Santiago"],
    altSpellings: ["CL", "Republic of Chile", "Rep\u00fablica de Chile"],
    region: "Americas",
    subregion: "South America",
    languages: { spa: "Spanish" },
    translations: {
      ces: { official: "Chilsk\u00e1 republika", common: "Chile" },
      cym: { official: "Gweriniaeth Chile", common: "Chile" },
      deu: { official: "Republik Chile", common: "Chile" },
      est: { official: "T\u0161iili Vabariik", common: "T\u0161iili" },
      fin: { official: "Chilen tasavalta", common: "Chile" },
      fra: { official: "R\u00e9publique du Chili", common: "Chili" },
      hrv: { official: "Republika \u010cile", common: "\u010cile" },
      hun: { official: "Chilei K\u00f6zt\u00e1rsas\u00e1g", common: "Chile" },
      ita: { official: "Repubblica del Cile", common: "Cile" },
      jpn: {
        official: "\u30c1\u30ea\u5171\u548c\u56fd",
        common: "\u30c1\u30ea",
      },
      kor: {
        official: "\uce60\ub808 \uacf5\ud654\uad6d",
        common: "\uce60\ub808",
      },
      nld: { official: "Republiek Chili", common: "Chili" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0634\u06cc\u0644\u06cc",
        common: "\u0634\u06cc\u0644\u06cc",
      },
      pol: { official: "Republika Chile", common: "Chile" },
      por: { official: "Rep\u00fablica do Chile", common: "Chile" },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0427\u0438\u043b\u0438",
        common: "\u0427\u0438\u043b\u0438",
      },
      slk: { official: "\u010c\u00edlska republika", common: "\u010cile" },
      spa: { official: "Rep\u00fablica de Chile", common: "Chile" },
      swe: { official: "Republiken Chile", common: "Chile" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0686\u0644\u06cc",
        common: "\u0686\u0644\u06cc",
      },
      zho: {
        official: "\u667a\u5229\u5171\u548c\u56fd",
        common: "\u667a\u5229",
      },
    },
    latlng: [-30, -71],
    landlocked: false,
    borders: ["ARG", "BOL", "PER"],
    area: 756102,
    flag: "\ud83c\udde8\ud83c\uddf1",
    demonyms: {
      eng: { f: "Chilean", m: "Chilean" },
      fra: { f: "Chilienne", m: "Chilien" },
    },
    callingCodes: ["+56"],
  },
  {
    name: {
      common: "China",
      official: "People's Republic of China",
      native: {
        zho: {
          official: "\u4e2d\u534e\u4eba\u6c11\u5171\u548c\u56fd",
          common: "\u4e2d\u56fd",
        },
      },
    },
    tld: [
      ".cn",
      ".\u4e2d\u56fd",
      ".\u4e2d\u570b",
      ".\u516c\u53f8",
      ".\u7f51\u7edc",
    ],
    cca2: "CN",
    ccn3: "156",
    cca3: "CHN",
    cioc: "CHN",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { CNY: { name: "Chinese yuan", symbol: "\u00a5" } },
    idd: { root: "+8", suffixes: ["6"] },
    capital: ["Beijing"],
    altSpellings: [
      "CN",
      "Zh\u014dnggu\u00f3",
      "Zhongguo",
      "Zhonghua",
      "People's Republic of China",
      "\u4e2d\u534e\u4eba\u6c11\u5171\u548c\u56fd",
      "Zh\u014dnghu\u00e1 R\u00e9nm\u00edn G\u00f2ngh\u00e9gu\u00f3",
    ],
    region: "Asia",
    subregion: "Eastern Asia",
    languages: { zho: "Chinese" },
    translations: {
      ces: {
        official: "\u010c\u00ednsk\u00e1 lidov\u00e1 republika",
        common: "\u010c\u00edna",
      },
      cym: { official: "Gweriniaeth Pobl Tsieina", common: "Tsieina" },
      deu: { official: "Volksrepublik China", common: "China" },
      est: { official: "Hiina Rahvavabariik", common: "Hiina" },
      fin: { official: "Kiinan kansantasavalta", common: "Kiina" },
      fra: { official: "R\u00e9publique populaire de Chine", common: "Chine" },
      hrv: { official: "Narodna Republika Kina", common: "Kina" },
      hun: {
        official: "K\u00ednai N\u00e9pk\u00f6zt\u00e1rsas\u00e1g",
        common: "K\u00edna",
      },
      ita: { official: "Repubblica popolare cinese", common: "Cina" },
      jpn: {
        official: "\u4e2d\u83ef\u4eba\u6c11\u5171\u548c\u56fd",
        common: "\u4e2d\u56fd",
      },
      kor: {
        official: "\uc911\ud654\uc778\ubbfc\uacf5\ud654\uad6d",
        common: "\uc911\uad6d",
      },
      nld: { official: "Volksrepubliek China", common: "China" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u062e\u0644\u0642 \u0686\u06cc\u0646",
        common: "\u0686\u06cc\u0646",
      },
      pol: { official: "Chi\u0144ska Republika Ludowa", common: "Chiny" },
      por: { official: "Rep\u00fablica Popular da China", common: "China" },
      rus: {
        official:
          "\u041d\u0430\u0440\u043e\u0434\u043d\u0430\u044f \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u041a\u0438\u0442\u0430\u0439",
        common: "\u041a\u0438\u0442\u0430\u0439",
      },
      slk: {
        official: "\u010c\u00ednska \u013eudov\u00e1 republika",
        common: "\u010c\u00edna",
      },
      spa: { official: "Rep\u00fablica Popular de China", common: "China" },
      swe: { official: "Folkrepubliken Kina", common: "Kina" },
      urd: {
        official:
          "\u0639\u0648\u0627\u0645\u06cc \u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0686\u06cc\u0646",
        common: "\u0686\u06cc\u0646",
      },
    },
    latlng: [35, 105],
    landlocked: false,
    borders: [
      "AFG",
      "BTN",
      "MMR",
      "HKG",
      "IND",
      "KAZ",
      "NPL",
      "PRK",
      "KGZ",
      "LAO",
      "MAC",
      "MNG",
      "PAK",
      "RUS",
      "TJK",
      "VNM",
    ],
    area: 9706961,
    flag: "\ud83c\udde8\ud83c\uddf3",
    demonyms: {
      eng: { f: "Chinese", m: "Chinese" },
      fra: { f: "Chinoise", m: "Chinois" },
    },
    callingCodes: ["+86"],
  },
  {
    name: {
      common: "Ivory Coast",
      official: "Republic of C\u00f4te d'Ivoire",
      native: {
        fra: {
          official: "R\u00e9publique de C\u00f4te d'Ivoire",
          common: "C\u00f4te d'Ivoire",
        },
      },
    },
    tld: [".ci"],
    cca2: "CI",
    ccn3: "384",
    cca3: "CIV",
    cioc: "CIV",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { XOF: { name: "West African CFA franc", symbol: "Fr" } },
    idd: { root: "+2", suffixes: ["25"] },
    capital: ["Yamoussoukro"],
    altSpellings: [
      "CI",
      "C\u00f4te d'Ivoire",
      "Ivory Coast",
      "Republic of C\u00f4te d'Ivoire",
      "R\u00e9publique de C\u00f4te d'Ivoire",
    ],
    region: "Africa",
    subregion: "Western Africa",
    languages: { fra: "French" },
    translations: {
      ces: {
        official: "Republika Pob\u0159e\u017e\u00ed slonoviny",
        common: "Pob\u0159e\u017e\u00ed slonoviny",
      },
      deu: {
        official: "Republik C\u00f4te d'Ivoire",
        common: "Elfenbeink\u00fcste",
      },
      est: {
        official: "C\u00f4te d\u2019Ivoire\u2019i Vabariik",
        common: "Elevandiluurannik",
      },
      fin: {
        official: "Norsunluurannikon tasavalta",
        common: "Norsunluurannikko",
      },
      fra: {
        official: "R\u00e9publique de C\u00f4te d' Ivoire",
        common: "C\u00f4te d'Ivoire",
      },
      hrv: {
        official: "Republika C\u00f4te d'Ivoire",
        common: "Obala Bjelokosti",
      },
      hun: {
        official: "Elef\u00e1ntcsontparti K\u00f6zt\u00e1rsas\u00e1g",
        common: "Elef\u00e1ntcsontpart",
      },
      ita: {
        official: "Repubblica della Costa d'Avorio",
        common: "Costa d'Avorio",
      },
      jpn: {
        official:
          "\u30b3\u30fc\u30c8\u30b8\u30dc\u30ef\u30fc\u30eb\u5171\u548c\u56fd",
        common: "\u30b3\u30fc\u30c8\u30b8\u30dc\u30ef\u30fc\u30eb",
      },
      kor: {
        official: "\ucf54\ud2b8\ub514\ubd80\uc544\ub974 \uacf5\ud654\uad6d",
        common: "\ucf54\ud2b8\ub514\ubd80\uc544\ub974",
      },
      nld: { official: "Republiek Ivoorkust", common: "Ivoorkust" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0633\u0627\u062d\u0644 \u0639\u0627\u062c",
        common: "\u0633\u0627\u062d\u0644 \u0639\u0627\u062c",
      },
      pol: {
        official: "Republika Wybrze\u017ba Ko\u015bci S\u0142oniowej",
        common: "Wybrze\u017be Ko\u015bci S\u0142oniowej",
      },
      por: {
        official: "Rep\u00fablica da C\u00f4te d'Ivoire",
        common: "Costa do Marfim",
      },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u041a\u043e\u0442-\u0434'\u0418\u0432\u0443\u0430\u0440\u0435",
        common: "\u041a\u043e\u0442-\u0434\u2019\u0418\u0432\u0443\u0430\u0440",
      },
      slk: {
        official: "Republika Pobre\u017eie Slonoviny",
        common: "Pobr\u017eie Slonoviny",
      },
      spa: {
        official: "Rep\u00fablica de C\u00f4te d'Ivoire",
        common: "Costa de Marfil",
      },
      swe: {
        official: "Republiken Elfenbenskusten",
        common: "Elfenbenskusten",
      },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u06a9\u0648\u062a \u062f\u06cc\u0648\u0627\u063a",
        common: "\u0622\u0626\u06cc\u0648\u0631\u06cc \u06a9\u0648\u0633\u0679",
      },
      zho: {
        official: "\u79d1\u7279\u8fea\u74e6\u5171\u548c\u56fd",
        common: "\u79d1\u7279\u8fea\u74e6",
      },
    },
    latlng: [8, -5],
    landlocked: false,
    borders: ["BFA", "GHA", "GIN", "LBR", "MLI"],
    area: 322463,
    flag: "\ud83c\udde8\ud83c\uddee",
    demonyms: {
      eng: { f: "Ivorian", m: "Ivorian" },
      fra: { f: "Ivoirienne", m: "Ivoirien" },
    },
    callingCodes: ["+225"],
  },
  {
    name: {
      common: "Cameroon",
      official: "Republic of Cameroon",
      native: {
        eng: { official: "Republic of Cameroon", common: "Cameroon" },
        fra: { official: "R\u00e9publique du Cameroun", common: "Cameroun" },
      },
    },
    tld: [".cm"],
    cca2: "CM",
    ccn3: "120",
    cca3: "CMR",
    cioc: "CMR",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { XAF: { name: "Central African CFA franc", symbol: "Fr" } },
    idd: { root: "+2", suffixes: ["37"] },
    capital: ["Yaound\u00e9"],
    altSpellings: ["CM", "Republic of Cameroon", "R\u00e9publique du Cameroun"],
    region: "Africa",
    subregion: "Middle Africa",
    languages: { eng: "English", fra: "French" },
    translations: {
      ces: { official: "Kamerunsk\u00e1 republika", common: "Kamerun" },
      cym: { official: "Gweriniaeth Camer\u0175n", common: "Camer\u0175n" },
      deu: { official: "Republik Kamerun", common: "Kamerun" },
      est: { official: "Kameruni Vabariik", common: "Kamerun" },
      fin: { official: "Kamerunin tasavalta", common: "Kamerun" },
      fra: { official: "R\u00e9publique du Cameroun", common: "Cameroun" },
      hrv: { official: "Republika Kamerun", common: "Kamerun" },
      hun: {
        official: "Kameruni K\u00f6zt\u00e1rsas\u00e1g",
        common: "Kamerun",
      },
      ita: { official: "Repubblica del Camerun", common: "Camerun" },
      jpn: {
        official: "\u30ab\u30e1\u30eb\u30fc\u30f3\u5171\u548c\u56fd",
        common: "\u30ab\u30e1\u30eb\u30fc\u30f3",
      },
      kor: {
        official: "\uce74\uba54\ub8ec \uacf5\ud654\uad6d",
        common: "\uce74\uba54\ub8ec",
      },
      nld: { official: "Republiek Kameroen", common: "Kameroen" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u06a9\u0627\u0645\u0650\u0631\u0648\u0646",
        common: "\u06a9\u0627\u0645\u0650\u0631\u0648\u0646",
      },
      pol: {
        official: "Republika Wybrze\u017ba Ko\u015bci S\u0142oniowej",
        common: "Wybrze\u017be Ko\u015bci S\u0142oniowej",
      },
      por: {
        official: "Rep\u00fablica dos Camar\u00f5es",
        common: "Camar\u00f5es",
      },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u041a\u0430\u043c\u0435\u0440\u0443\u043d",
        common: "\u041a\u0430\u043c\u0435\u0440\u0443\u043d",
      },
      slk: { official: "Kamerunsk\u00e1 republika", common: "Kamerun" },
      spa: {
        official: "Rep\u00fablica de Camer\u00fan",
        common: "Camer\u00fan",
      },
      swe: { official: "Republiken Kamerun", common: "Kamerun" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u06a9\u06cc\u0645\u0631\u0648\u0646",
        common: "\u06a9\u06cc\u0645\u0631\u0648\u0646",
      },
      zho: {
        official: "\u5580\u9ea6\u9686\u5171\u548c\u56fd",
        common: "\u5580\u9ea6\u9686",
      },
    },
    latlng: [6, 12],
    landlocked: false,
    borders: ["CAF", "TCD", "COG", "GNQ", "GAB", "NGA"],
    area: 475442,
    flag: "\ud83c\udde8\ud83c\uddf2",
    demonyms: {
      eng: { f: "Cameroonian", m: "Cameroonian" },
      fra: { f: "Camerounaise", m: "Camerounais" },
    },
    callingCodes: ["+237"],
  },
  {
    name: {
      common: "DR Congo",
      official: "Democratic Republic of the Congo",
      native: {
        fra: {
          official: "R\u00e9publique d\u00e9mocratique du Congo",
          common: "RD Congo",
        },
        kon: {
          official: "Repubilika ya Kongo Demokratiki",
          common: "Repubilika ya Kongo Demokratiki",
        },
        lin: {
          official: "Republiki ya Kong\u00f3 Demokratiki",
          common: "Republiki ya Kong\u00f3 Demokratiki",
        },
        lua: {
          official: "Ditunga dia Kongu wa Mungalaata",
          common: "Ditunga dia Kongu wa Mungalaata",
        },
        swa: {
          official: "Jamhuri ya Kidemokrasia ya Kongo",
          common: "Jamhuri ya Kidemokrasia ya Kongo",
        },
      },
    },
    tld: [".cd"],
    cca2: "CD",
    ccn3: "180",
    cca3: "COD",
    cioc: "COD",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { CDF: { name: "Congolese franc", symbol: "FC" } },
    idd: { root: "+2", suffixes: ["43"] },
    capital: ["Kinshasa"],
    altSpellings: [
      "CD",
      "DR Congo",
      "Congo-Kinshasa",
      "Congo, the Democratic Republic of the",
      "DRC",
    ],
    region: "Africa",
    subregion: "Middle Africa",
    languages: {
      fra: "French",
      kon: "Kikongo",
      lin: "Lingala",
      lua: "Tshiluba",
      swa: "Swahili",
    },
    translations: {
      ces: {
        official: "Demokratick\u00e1 republika Kongo",
        common: "DR Kongo",
      },
      cym: {
        official: "Gweriniaeth Ddemocrataidd Congo",
        common: "Gweriniaeth Ddemocrataidd Congo",
      },
      deu: {
        official: "Demokratische Republik Kongo",
        common: "Kongo (Dem. Rep.)",
      },
      est: { official: "Kongo Demokraatlik Vabariik", common: "Kongo DV" },
      fin: {
        official: "Kongon demokraattinen tasavalta",
        common: "Kongon demokraattinen tasavalta",
      },
      fra: {
        official: "R\u00e9publique d\u00e9mocratique du Congo",
        common: "Congo (R\u00e9p. d\u00e9m.)",
      },
      hrv: {
        official: "Demokratska Republika Kongo",
        common: "Kongo, Demokratska Republika",
      },
      hun: {
        official: "Kong\u00f3i Demokratikus K\u00f6zt\u00e1rsas\u00e1g",
        common: "Kong\u00f3i Demokratikus K\u00f6zt\u00e1rsas\u00e1g",
      },
      ita: {
        official: "Repubblica Democratica del Congo",
        common: "Congo (Rep. Dem.)",
      },
      jpn: {
        official: "\u30b3\u30f3\u30b4\u6c11\u4e3b\u5171\u548c\u56fd",
        common: "\u30b3\u30f3\u30b4\u6c11\u4e3b\u5171\u548c\u56fd",
      },
      kor: {
        official: "\ucf69\uace0 \ubbfc\uc8fc \uacf5\ud654\uad6d",
        common: "\ucf69\uace0 \ubbfc\uc8fc \uacf5\ud654\uad6d",
      },
      nld: { official: "Democratische Republiek Congo", common: "Congo (DRC)" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u062f\u0645\u0648\u06a9\u0631\u0627\u062a\u06cc\u06a9 \u06a9\u0646\u06af\u0648",
        common:
          "\u06a9\u0646\u06af\u0648 \u062f\u0645\u0648\u06a9\u0631\u0627\u062a\u06cc\u06a9",
      },
      pol: {
        official: "Demokratyczna Republika Konga",
        common: "Demokratyczna Republika Konga",
      },
      por: {
        official: "Rep\u00fablica Democr\u00e1tica do Congo",
        common: "Rep\u00fablica Democr\u00e1tica do Congo",
      },
      rus: {
        official:
          "\u0414\u0435\u043c\u043e\u043a\u0440\u0430\u0442\u0438\u0447\u0435\u0441\u043a\u0430\u044f \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u041a\u043e\u043d\u0433\u043e",
        common:
          "\u0414\u0435\u043c\u043e\u043a\u0440\u0430\u0442\u0438\u0447\u0435\u0441\u043a\u0430\u044f \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u041a\u043e\u043d\u0433\u043e",
      },
      slk: {
        official: "Kon\u017esk\u00e1 demokratick\u00e1 republika",
        common: "Kongo",
      },
      spa: {
        official: "Rep\u00fablica Democr\u00e1tica del Congo",
        common: "Congo (Rep. Dem.)",
      },
      swe: {
        official: "Demokratiska republiken Kongo",
        common: "Kongo-Kinshasa",
      },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc \u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u06a9\u0627\u0646\u06af\u0648",
        common: "\u06a9\u0627\u0646\u06af\u0648",
      },
      zho: {
        official: "\u521a\u679c\u6c11\u4e3b\u5171\u548c\u56fd",
        common: "\u6c11\u4e3b\u521a\u679c",
      },
    },
    latlng: [0, 25],
    landlocked: false,
    borders: ["AGO", "BDI", "CAF", "COG", "RWA", "SSD", "TZA", "UGA", "ZMB"],
    area: 2344858,
    flag: "\ud83c\udde8\ud83c\udde9",
    demonyms: {
      eng: { f: "Congolese", m: "Congolese" },
      fra: { f: "Congolaise", m: "Congolais" },
    },
    callingCodes: ["+243"],
  },
  {
    name: {
      common: "Republic of the Congo",
      official: "Republic of the Congo",
      native: {
        fra: {
          official: "R\u00e9publique du Congo",
          common: "R\u00e9publique du Congo",
        },
        kon: { official: "Repubilika ya Kongo", common: "Repubilika ya Kongo" },
        lin: {
          official: "Republ\u00edki ya Kong\u00f3",
          common: "Republ\u00edki ya Kong\u00f3",
        },
      },
    },
    tld: [".cg"],
    cca2: "CG",
    ccn3: "178",
    cca3: "COG",
    cioc: "CGO",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { XAF: { name: "Central African CFA franc", symbol: "Fr" } },
    idd: { root: "+2", suffixes: ["42"] },
    capital: ["Brazzaville"],
    altSpellings: ["CG", "Congo", "Congo-Brazzaville"],
    region: "Africa",
    subregion: "Middle Africa",
    languages: { fra: "French", kon: "Kikongo", lin: "Lingala" },
    translations: {
      ces: { official: "Kon\u017esk\u00e1 republika", common: "Kongo" },
      cym: { official: "Gweriniaeth y Congo", common: "Gweriniaeth y Congo" },
      deu: { official: "Republik Kongo", common: "Kongo" },
      est: { official: "Kongo Vabariik", common: "Kongo Vabariik" },
      fin: { official: "Kongon tasavalta", common: "Kongo-Brazzaville" },
      fra: { official: "R\u00e9publique du Congo", common: "Congo" },
      hrv: { official: "Republika Kongo", common: "Kongo" },
      hun: {
        official: "Kong\u00f3i K\u00f6zt\u00e1rsas\u00e1g",
        common: "Kong\u00f3i K\u00f6zt\u00e1rsas\u00e1g",
      },
      ita: { official: "Repubblica del Congo", common: "Congo" },
      jpn: {
        official: "\u30b3\u30f3\u30b4\u5171\u548c\u56fd",
        common: "\u30b3\u30f3\u30b4\u5171\u548c\u56fd",
      },
      kor: { official: "\ucf69\uace0", common: "\ucf69\uace0" },
      nld: { official: "Republiek Congo", common: "Congo" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0628\u0631\u0627\u0632\u0627\u0648\u06cc\u0644 \u06a9\u064f\u0646\u06af\u0648",
        common:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u06a9\u064f\u0646\u06af\u0648",
      },
      pol: { official: "Republika Konga", common: "Kongo" },
      por: { official: "Rep\u00fablica do Congo", common: "Congo" },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u041a\u043e\u043d\u0433\u043e",
        common:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u041a\u043e\u043d\u0433\u043e",
      },
      slk: { official: "Kon\u017esk\u00e1 republika", common: "Kongo" },
      spa: { official: "Rep\u00fablica del Congo", common: "Congo" },
      swe: { official: "Republiken Kongo", common: "Kongo-Brazzaville" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u06a9\u0627\u0646\u06af\u0648",
        common:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u06a9\u0627\u0646\u06af\u0648",
      },
      zho: {
        official: "\u521a\u679c\u5171\u548c\u56fd",
        common: "\u521a\u679c",
      },
    },
    latlng: [-1, 15],
    landlocked: false,
    borders: ["AGO", "CMR", "CAF", "COD", "GAB"],
    area: 342000,
    flag: "\ud83c\udde8\ud83c\uddec",
    demonyms: {
      eng: { f: "Congolese", m: "Congolese" },
      fra: { f: "Congolaise", m: "Congolais" },
    },
    callingCodes: ["+242"],
  },
  {
    name: {
      common: "Cook Islands",
      official: "Cook Islands",
      native: {
        eng: { official: "Cook Islands", common: "Cook Islands" },
        rar: {
          official: "K\u016bki '\u0100irani",
          common: "K\u016bki '\u0100irani",
        },
      },
    },
    tld: [".ck"],
    cca2: "CK",
    ccn3: "184",
    cca3: "COK",
    cioc: "COK",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: {
      CKD: { name: "Cook Islands dollar", symbol: "$" },
      NZD: { name: "New Zealand dollar", symbol: "$" },
    },
    idd: { root: "+6", suffixes: ["82"] },
    capital: ["Avarua"],
    altSpellings: ["CK", "K\u016bki '\u0100irani"],
    region: "Oceania",
    subregion: "Polynesia",
    languages: { eng: "English", rar: "Cook Islands M\u0101ori" },
    translations: {
      ces: { official: "Cookovy ostrovy", common: "Cookovy ostrovy" },
      cym: { official: "Ynysoedd Cook", common: "Ynysoedd Cook" },
      deu: { official: "Cookinseln", common: "Cookinseln" },
      est: { official: "Cooki saared", common: "Cooki saared" },
      fin: { official: "Cookinsaaret", common: "Cookinsaaret" },
      fra: { official: "\u00celes Cook", common: "\u00celes Cook" },
      hrv: { official: "Cook Islands", common: "Cookovo Oto\u010dje" },
      hun: { official: "Cook-szigetek", common: "Cook-szigetek" },
      ita: { official: "Isole Cook", common: "Isole Cook" },
      jpn: {
        official: "\u30af\u30c3\u30af\u8af8\u5cf6",
        common: "\u30af\u30c3\u30af\u8af8\u5cf6",
      },
      kor: { official: "\ucfe1 \uc81c\ub3c4", common: "\ucfe1 \uc81c\ub3c4" },
      nld: { official: "Cook eilanden", common: "Cookeilanden" },
      per: {
        official: "\u062c\u0632\u0627\u06cc\u0631 \u06a9\u0648\u06a9",
        common: "\u062c\u0632\u0627\u06cc\u0631 \u06a9\u0648\u06a9",
      },
      pol: { official: "Wyspy Cooka", common: "Wyspy Cooka" },
      por: { official: "Ilhas Cook", common: "Ilhas Cook" },
      rus: {
        official:
          "\u043e\u0441\u0442\u0440\u043e\u0432\u0430 \u041a\u0443\u043a\u0430",
        common:
          "\u041e\u0441\u0442\u0440\u043e\u0432\u0430 \u041a\u0443\u043a\u0430",
      },
      slk: { official: "Cookove ostrovy", common: "Cookove ostrovy" },
      spa: { official: "Islas Cook", common: "Islas Cook" },
      swe: { official: "Cook\u00f6arna", common: "Cook\u00f6arna" },
      urd: {
        official: "\u062c\u0632\u0627\u0626\u0631 \u06a9\u06a9",
        common: "\u062c\u0632\u0627\u0626\u0631 \u06a9\u06a9",
      },
      zho: {
        official: "\u5e93\u514b\u7fa4\u5c9b",
        common: "\u5e93\u514b\u7fa4\u5c9b",
      },
    },
    latlng: [-21.23333333, -159.76666666],
    landlocked: false,
    borders: [],
    area: 236,
    flag: "\ud83c\udde8\ud83c\uddf0",
    demonyms: {
      eng: { f: "Cook Islander", m: "Cook Islander" },
      fra: { f: "Cookienne", m: "Cookien" },
    },
    callingCodes: ["+682"],
  },
  {
    name: {
      common: "Colombia",
      official: "Republic of Colombia",
      native: {
        spa: { official: "Rep\u00fablica de Colombia", common: "Colombia" },
      },
    },
    tld: [".co"],
    cca2: "CO",
    ccn3: "170",
    cca3: "COL",
    cioc: "COL",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { COP: { name: "Colombian peso", symbol: "$" } },
    idd: { root: "+5", suffixes: ["7"] },
    capital: ["Bogot\u00e1"],
    altSpellings: ["CO", "Republic of Colombia", "Rep\u00fablica de Colombia"],
    region: "Americas",
    subregion: "South America",
    languages: { spa: "Spanish" },
    translations: {
      ces: { official: "Kolumbijsk\u00e1 republika", common: "Kolumbie" },
      cym: { official: "Gweriniaeth Colombia", common: "Colombia" },
      deu: { official: "Republik Kolumbien", common: "Kolumbien" },
      est: { official: "Colombia Vabariik", common: "Colombia" },
      fin: { official: "Kolumbian tasavalta", common: "Kolumbia" },
      fra: { official: "R\u00e9publique de Colombie", common: "Colombie" },
      hrv: { official: "Republika Kolumbija", common: "Kolumbija" },
      hun: {
        official: "Kolumbiai K\u00f6zt\u00e1rsas\u00e1g",
        common: "Kolumbia",
      },
      ita: { official: "Repubblica di Colombia", common: "Colombia" },
      jpn: {
        official: "\u30b3\u30ed\u30f3\u30d3\u30a2\u5171\u548c\u56fd",
        common: "\u30b3\u30ed\u30f3\u30d3\u30a2",
      },
      kor: {
        official: "\ucf5c\ub86c\ube44\uc544 \uacf5\ud654\uad6d",
        common: "\ucf5c\ub86c\ube44\uc544",
      },
      nld: { official: "Republiek Colombia", common: "Colombia" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u06a9\u0644\u0645\u0628\u06cc\u0627",
        common: "\u06a9\u0644\u0645\u0628\u06cc\u0627",
      },
      pol: { official: "Republika Kolumbii", common: "Kolumbia" },
      por: {
        official: "Rep\u00fablica da Col\u00f4mbia",
        common: "Col\u00f4mbia",
      },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u041a\u043e\u043b\u0443\u043c\u0431\u0438\u044f",
        common: "\u041a\u043e\u043b\u0443\u043c\u0431\u0438\u044f",
      },
      slk: { official: "Kolumbijsk\u00e1 republika", common: "Kolumbia" },
      spa: { official: "Rep\u00fablica de Colombia", common: "Colombia" },
      swe: { official: "Republiken Colombia", common: "Colombia" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u06a9\u0648\u0644\u0645\u0628\u06cc\u0627",
        common: "\u06a9\u0648\u0644\u0645\u0628\u06cc\u0627",
      },
      zho: {
        official: "\u54e5\u4f26\u6bd4\u4e9a\u5171\u548c\u56fd",
        common: "\u54e5\u4f26\u6bd4\u4e9a",
      },
    },
    latlng: [4, -72],
    landlocked: false,
    borders: ["BRA", "ECU", "PAN", "PER", "VEN"],
    area: 1141748,
    flag: "\ud83c\udde8\ud83c\uddf4",
    demonyms: {
      eng: { f: "Colombian", m: "Colombian" },
      fra: { f: "Colombienne", m: "Colombien" },
    },
    callingCodes: ["+57"],
  },
  {
    name: {
      common: "Comoros",
      official: "Union of the Comoros",
      native: {
        ara: {
          official:
            "\u0627\u0644\u0627\u062a\u062d\u0627\u062f \u0627\u0644\u0642\u0645\u0631\u064a",
          common: "\u0627\u0644\u0642\u0645\u0631\u200e",
        },
        fra: { official: "Union des Comores", common: "Comores" },
        zdj: { official: "Udzima wa Komori", common: "Komori" },
      },
    },
    tld: [".km"],
    cca2: "KM",
    ccn3: "174",
    cca3: "COM",
    cioc: "COM",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { KMF: { name: "Comorian franc", symbol: "Fr" } },
    idd: { root: "+2", suffixes: ["69"] },
    capital: ["Moroni"],
    altSpellings: [
      "KM",
      "Union of the Comoros",
      "Union des Comores",
      "Udzima wa Komori",
      "al-Itti\u1e25\u0101d al-Qumur\u012b",
    ],
    region: "Africa",
    subregion: "Eastern Africa",
    languages: { ara: "Arabic", fra: "French", zdj: "Comorian" },
    translations: {
      ces: { official: "Komorsk\u00fd svaz", common: "Komory" },
      cym: { official: "Undeb y Comoros", common: "Y Comoros" },
      deu: { official: "Union der Komoren", common: "Komoren" },
      est: { official: "Komoori Liit", common: "Komoorid" },
      fin: { official: "Komorien liitto", common: "Komorit" },
      fra: { official: "Union des Comores", common: "Comores" },
      hrv: { official: "Savez Komori", common: "Komori" },
      hun: {
        official: "Comore-szigeteki Uni\u00f3",
        common: "Comore-szigetek",
      },
      ita: { official: "Unione delle Comore", common: "Comore" },
      jpn: {
        official: "\u30b3\u30e2\u30ed\u9023\u5408",
        common: "\u30b3\u30e2\u30ed",
      },
      kor: {
        official: "\ucf54\ubaa8\ub85c \uc5f0\ubc29",
        common: "\ucf54\ubaa8\ub85c",
      },
      nld: { official: "Unie van de Comoren", common: "Comoren" },
      per: {
        official:
          "\u0645\u062c\u0645\u0639\u200c\u0627\u0644\u062c\u0632\u0627\u06cc\u0631 \u0642\u0645\u0631",
        common: "\u0627\u062a\u062d\u0627\u062f \u0642\u064f\u0645\u064f\u0631",
      },
      pol: { official: "Zwi\u0105zek Komor\u00f3w", common: "Komory" },
      por: { official: "Uni\u00e3o das Comores", common: "Comores" },
      rus: {
        official:
          "\u0421\u043e\u044e\u0437 \u041a\u043e\u043c\u043e\u0440\u0441\u043a\u0438\u0445 \u041e\u0441\u0442\u0440\u043e\u0432\u043e\u0432",
        common: "\u041a\u043e\u043c\u043e\u0440\u044b",
      },
      slk: { official: "Komorsk\u00e1 \u00fania", common: "Komory" },
      spa: { official: "Uni\u00f3n de las Comoras", common: "Comoras" },
      swe: { official: "Unionen Komorerna", common: "Komorerna" },
      urd: {
        official:
          "\u0627\u062a\u062d\u0627\u062f \u0627\u0644\u0642\u0645\u0631\u06cc",
        common: "\u0627\u0644\u0642\u0645\u0631\u06cc",
      },
      zho: {
        official: "\u79d1\u6469\u7f57\u8054\u76df",
        common: "\u79d1\u6469\u7f57",
      },
    },
    latlng: [-12.16666666, 44.25],
    landlocked: false,
    borders: [],
    area: 1862,
    flag: "\ud83c\uddf0\ud83c\uddf2",
    demonyms: {
      eng: { f: "Comoran", m: "Comoran" },
      fra: { f: "Comorienne", m: "Comorien" },
    },
    callingCodes: ["+269"],
  },
  {
    name: {
      common: "Cape Verde",
      official: "Republic of Cabo Verde",
      native: {
        por: { official: "Rep\u00fablica de Cabo Verde", common: "Cabo Verde" },
      },
    },
    tld: [".cv"],
    cca2: "CV",
    ccn3: "132",
    cca3: "CPV",
    cioc: "CPV",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { CVE: { name: "Cape Verdean escudo", symbol: "Esc" } },
    idd: { root: "+2", suffixes: ["38"] },
    capital: ["Praia"],
    altSpellings: [
      "CV",
      "Republic of Cabo Verde",
      "Rep\u00fablica de Cabo Verde",
    ],
    region: "Africa",
    subregion: "Western Africa",
    languages: { por: "Portuguese" },
    translations: {
      ces: { official: "Kapverdsk\u00e1 republika", common: "Kapverdy" },
      cym: { official: "Gweriniaeth Cabo Verde", common: "Penrhyn Verde" },
      deu: { official: "Republik Cabo Verde", common: "Kap Verde" },
      est: { official: "Cabo Verde Vabariik", common: "Roheneemesaared" },
      fin: { official: "Kap Verden tasavalta", common: "Kap Verde" },
      fra: {
        official: "R\u00e9publique du Cap-Vert",
        common: "\u00celes du Cap-Vert",
      },
      hrv: {
        official: "Republika Cabo Verde",
        common: "Zelenortska Republika",
      },
      hun: {
        official: "Z\u00f6ld-foki K\u00f6zt\u00e1rsas\u00e1g",
        common: "Z\u00f6ld-foki K\u00f6zt\u00e1rsas\u00e1g",
      },
      ita: { official: "Repubblica di Capo Verde", common: "Capo Verde" },
      jpn: {
        official: "\u30ab\u30fc\u30dc\u30d9\u30eb\u30c7\u5171\u548c\u56fd",
        common: "\u30ab\u30fc\u30dc\u30d9\u30eb\u30c7",
      },
      kor: {
        official: "\uce74\ubcf4\ubca0\ub974\ub370 \uacf5\ud654\uad6d",
        common: "\uce74\ubcf4\ubca0\ub974\ub370",
      },
      nld: { official: "Republiek van Cabo Verde", common: "Kaapverdi\u00eb" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u06a9\u0628\u0648 \u0648\u0631\u062f",
        common: "\u062f\u0645\u0627\u063a\u0647\u0654 \u0633\u0628\u0632",
      },
      pol: {
        official: "Republika Zielonego Przyl\u0105dka",
        common: "Republika Zielonego Przyl\u0105dka",
      },
      por: { official: "Rep\u00fablica de Cabo Verde", common: "Cabo Verde" },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u041a\u0430\u0431\u043e -\u0412\u0435\u0440\u0434\u0435",
        common: "\u041a\u0430\u0431\u043e-\u0412\u0435\u0440\u0434\u0435",
      },
      slk: { official: "Kapverdsk\u00e1 republika", common: "Kapverdy" },
      spa: { official: "Rep\u00fablica de Cabo Verde", common: "Cabo Verde" },
      swe: { official: "Republiken Kap Verde", common: "Kap Verde" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u06a9\u06cc\u067e \u0648\u0631\u0688\u06cc",
        common: "\u06a9\u06cc\u067e \u0648\u0631\u0688\u06cc",
      },
      zho: {
        official: "\u4f5b\u5f97\u89d2\u5171\u548c\u56fd",
        common: "\u4f5b\u5f97\u89d2",
      },
    },
    latlng: [16, -24],
    landlocked: false,
    borders: [],
    area: 4033,
    flag: "\ud83c\udde8\ud83c\uddfb",
    demonyms: {
      eng: { f: "Cape Verdian", m: "Cape Verdian" },
      fra: { f: "Cap-verdienne", m: "Cap-verdien" },
    },
    callingCodes: ["+238"],
  },
  {
    name: {
      common: "Costa Rica",
      official: "Republic of Costa Rica",
      native: {
        spa: { official: "Rep\u00fablica de Costa Rica", common: "Costa Rica" },
      },
    },
    tld: [".cr"],
    cca2: "CR",
    ccn3: "188",
    cca3: "CRI",
    cioc: "CRC",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { CRC: { name: "Costa Rican col\u00f3n", symbol: "\u20a1" } },
    idd: { root: "+5", suffixes: ["06"] },
    capital: ["San Jos\u00e9"],
    altSpellings: [
      "CR",
      "Republic of Costa Rica",
      "Rep\u00fablica de Costa Rica",
    ],
    region: "Americas",
    subregion: "Central America",
    languages: { spa: "Spanish" },
    translations: {
      ces: { official: "Kostarick\u00e1 republika", common: "Kostarika" },
      cym: { official: "Gweriniaeth Costa Rica", common: "Costa Rica" },
      deu: { official: "Republik Costa Rica", common: "Costa Rica" },
      est: { official: "Costa Rica Vabariik", common: "Costa Rica" },
      fin: { official: "Costa Rican tasavalta", common: "Costa Rica" },
      fra: { official: "R\u00e9publique du Costa Rica", common: "Costa Rica" },
      hrv: { official: "Republika Kostarika", common: "Kostarika" },
      hun: {
        official: "Costa Rica-i K\u00f6zt\u00e1rsas\u00e1g",
        common: "Costa Rica",
      },
      ita: { official: "Repubblica di Costa Rica", common: "Costa Rica" },
      jpn: {
        official: "\u30b3\u30b9\u30bf\u30ea\u30ab\u5171\u548c\u56fd",
        common: "\u30b3\u30b9\u30bf\u30ea\u30ab",
      },
      kor: {
        official: "\ucf54\uc2a4\ud0c0\ub9ac\uce74 \uacf5\ud654\uad6d",
        common: "\ucf54\uc2a4\ud0c0\ub9ac\uce74",
      },
      nld: { official: "Republiek Costa Rica", common: "Costa Rica" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u06a9\u0627\u0633\u062a\u0627\u0631\u06cc\u06a9\u0627",
        common: "\u06a9\u0627\u0633\u062a\u0627\u0631\u06cc\u06a9\u0627",
      },
      pol: { official: "Republika Kostaryki", common: "Kostaryka" },
      por: { official: "Rep\u00fablica da Costa Rica", common: "Costa Rica" },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u041a\u043e\u0441\u0442\u0430-\u0420\u0438\u043a\u0430",
        common: "\u041a\u043e\u0441\u0442\u0430-\u0420\u0438\u043a\u0430",
      },
      slk: { official: "Kostarick\u00e1 republika", common: "Kostarika" },
      spa: { official: "Rep\u00fablica de Costa Rica", common: "Costa Rica" },
      swe: { official: "Republiken Costa Rica", common: "Costa Rica" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u06a9\u0648\u0633\u0679\u0627\u0631\u06cc\u06a9\u0627",
        common: "\u06a9\u0648\u0633\u0679\u0627\u0631\u06cc\u06a9\u0627",
      },
      zho: {
        official: "\u54e5\u65af\u8fbe\u9ece\u52a0\u5171\u548c\u56fd",
        common: "\u54e5\u65af\u8fbe\u9ece\u52a0",
      },
    },
    latlng: [10, -84],
    landlocked: false,
    borders: ["NIC", "PAN"],
    area: 51100,
    flag: "\ud83c\udde8\ud83c\uddf7",
    demonyms: {
      eng: { f: "Costa Rican", m: "Costa Rican" },
      fra: { f: "Costaricaine", m: "Costaricain" },
    },
    callingCodes: ["+506"],
  },
  {
    name: {
      common: "Cuba",
      official: "Republic of Cuba",
      native: { spa: { official: "Rep\u00fablica de Cuba", common: "Cuba" } },
    },
    tld: [".cu"],
    cca2: "CU",
    ccn3: "192",
    cca3: "CUB",
    cioc: "CUB",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: {
      CUC: { name: "Cuban convertible peso", symbol: "$" },
      CUP: { name: "Cuban peso", symbol: "$" },
    },
    idd: { root: "+5", suffixes: ["3"] },
    capital: ["Havana"],
    altSpellings: ["CU", "Republic of Cuba", "Rep\u00fablica de Cuba"],
    region: "Americas",
    subregion: "Caribbean",
    languages: { spa: "Spanish" },
    translations: {
      ces: { official: "Kub\u00e1nsk\u00e1 republika", common: "Kuba" },
      cym: { official: "Gweriniaeth Ciwba", common: "Ciwba" },
      deu: { official: "Republik Kuba", common: "Kuba" },
      est: { official: "Kuuba Vabariik", common: "Kuuba" },
      fin: { official: "Kuuban tasavalta", common: "Kuuba" },
      fra: { official: "R\u00e9publique de Cuba", common: "Cuba" },
      hrv: { official: "Republika Kuba", common: "Kuba" },
      hun: { official: "Kubai K\u00f6zt\u00e1rsas\u00e1g", common: "Kuba" },
      ita: { official: "Repubblica di Cuba", common: "Cuba" },
      jpn: {
        official: "\u30ad\u30e5\u30fc\u30d0\u5171\u548c\u56fd",
        common: "\u30ad\u30e5\u30fc\u30d0",
      },
      kor: {
        official: "\ucfe0\ubc14 \uacf5\ud654\uad6d",
        common: "\ucfe0\ubc14",
      },
      nld: { official: "Republiek Cuba", common: "Cuba" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u06a9\u0648\u0628\u0627",
        common: "\u06a9\u0648\u0628\u0627",
      },
      pol: { official: "Republika Kuby", common: "Kuba" },
      por: { official: "Rep\u00fablica de Cuba", common: "Cuba" },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u041a\u0443\u0431\u0430",
        common: "\u041a\u0443\u0431\u0430",
      },
      slk: { official: "Kub\u00e1nska republika", common: "Kuba" },
      spa: { official: "Rep\u00fablica de Cuba", common: "Cuba" },
      swe: { official: "Republiken Kuba", common: "Kuba" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u06a9\u06cc\u0648\u0628\u0627",
        common: "\u06a9\u06cc\u0648\u0628\u0627",
      },
      zho: {
        official: "\u53e4\u5df4\u5171\u548c\u56fd",
        common: "\u53e4\u5df4",
      },
    },
    latlng: [21.5, -80],
    landlocked: false,
    borders: [],
    area: 109884,
    flag: "\ud83c\udde8\ud83c\uddfa",
    demonyms: {
      eng: { f: "Cuban", m: "Cuban" },
      fra: { f: "Cubaine", m: "Cubain" },
    },
    callingCodes: ["+53"],
  },
  {
    name: {
      common: "Cura\u00e7ao",
      official: "Country of Cura\u00e7ao",
      native: {
        eng: { official: "Country of Cura\u00e7ao", common: "Cura\u00e7ao" },
        nld: { official: "Land Cura\u00e7ao", common: "Cura\u00e7ao" },
        pap: { official: "Pais K\u00f2rsou", common: "Pais K\u00f2rsou" },
      },
    },
    tld: [".cw"],
    cca2: "CW",
    ccn3: "531",
    cca3: "CUW",
    cioc: "",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: {
      ANG: { name: "Netherlands Antillean guilder", symbol: "\u0192" },
    },
    idd: { root: "+5", suffixes: ["99"] },
    capital: ["Willemstad"],
    altSpellings: [
      "CW",
      "Curacao",
      "K\u00f2rsou",
      "Country of Cura\u00e7ao",
      "Land Cura\u00e7ao",
      "Pais K\u00f2rsou",
    ],
    region: "Americas",
    subregion: "Caribbean",
    languages: { eng: "English", nld: "Dutch", pap: "Papiamento" },
    translations: {
      ces: {
        official: "Autonomn\u00ed zem\u011b Cura\u00e7ao",
        common: "Cura\u00e7ao",
      },
      deu: { official: "Land Cura\u00e7ao", common: "Cura\u00e7ao" },
      est: { official: "Cura\u00e7ao", common: "Cura\u00e7ao" },
      fin: { official: "Cura\u00e7ao", common: "Cura\u00e7ao" },
      fra: { official: "Pays de Cura\u00e7ao", common: "Cura\u00e7ao" },
      hun: { official: "Cura\u00e7ao", common: "Cura\u00e7ao" },
      ita: { official: "Paese di Cura\u00e7ao", common: "Cura\u00e7ao" },
      kor: { official: "\ud034\ub77c\uc18c", common: "\ud034\ub77c\uc18c" },
      nld: { official: "Land Cura\u00e7ao", common: "Cura\u00e7ao" },
      per: {
        official: "\u06a9\u0648\u0631\u0627\u0633\u0627\u0626\u0648",
        common: "\u06a9\u0648\u0631\u0627\u0633\u0627\u0626\u0648",
      },
      pol: { official: "Cura\u00e7ao", common: "Cura\u00e7ao" },
      por: {
        official: "Pa\u00eds de Cura\u00e7ao",
        common: "ilha da Cura\u00e7\u00e3o",
      },
      rus: {
        official:
          "\u0421\u0442\u0440\u0430\u043d\u0430 \u041a\u044e\u0440\u0430\u0441\u0430\u043e",
        common: "\u041a\u044e\u0440\u0430\u0441\u0430\u043e",
      },
      slk: { official: "Curacao", common: "Curacao" },
      spa: { official: "Pa\u00eds de Curazao", common: "Curazao" },
      swe: { official: "Cura\u00e7ao", common: "Cura\u00e7ao" },
      urd: {
        official:
          "\u0645\u0645\u0644\u06a9\u062a\u0650 \u06a9\u06cc\u0648\u0631\u0627\u0633\u0627\u0624",
        common: "\u06a9\u06cc\u0648\u0631\u0627\u0633\u0627\u0624",
      },
      zho: { official: "\u5e93\u62c9\u7d22", common: "\u5e93\u62c9\u7d22" },
    },
    latlng: [12.116667, -68.933333],
    landlocked: false,
    borders: [],
    area: 444,
    flag: "\ud83c\udde8\ud83c\uddfc",
    demonyms: {
      eng: { f: "Cura\u00e7aoan", m: "Cura\u00e7aoan" },
      fra: { f: "Curacienne", m: "Curacien" },
    },
    callingCodes: ["+599"],
  },
  {
    name: {
      common: "Christmas Island",
      official: "Territory of Christmas Island",
      native: {
        eng: {
          official: "Territory of Christmas Island",
          common: "Christmas Island",
        },
      },
    },
    tld: [".cx"],
    cca2: "CX",
    ccn3: "162",
    cca3: "CXR",
    cioc: "",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: { AUD: { name: "Australian dollar", symbol: "$" } },
    idd: { root: "+6", suffixes: ["1"] },
    capital: ["Flying Fish Cove"],
    altSpellings: ["CX", "Territory of Christmas Island"],
    region: "Oceania",
    subregion: "Australia and New Zealand",
    languages: { eng: "English" },
    translations: {
      ces: {
        official: "Teritorium V\u00e1no\u010dn\u00edho ostrova",
        common: "V\u00e1no\u010dn\u00ed ostrov",
      },
      cym: {
        official: "Tiriogaeth yr Ynys y Nadolig",
        common: "Ynys y Nadolig",
      },
      deu: {
        official: "Gebiet der Weihnachtsinsel",
        common: "Weihnachtsinsel",
      },
      est: { official: "J\u00f5ulusaare ala", common: "J\u00f5ulusaar" },
      fin: { official: "Joulusaaren alue", common: "Joulusaari" },
      fra: {
        official: "Territoire de l'\u00eele Christmas",
        common: "\u00cele Christmas",
      },
      hrv: {
        official: "Teritorij Bo\u017ei\u0107ni otok",
        common: "Bo\u017ei\u0107ni otok",
      },
      hun: {
        official: "Kar\u00e1csony-sziget",
        common: "Kar\u00e1csony-sziget",
      },
      ita: {
        official: "Territorio di Christmas Island",
        common: "Isola di Natale",
      },
      jpn: {
        official: "\u30af\u30ea\u30b9\u30de\u30b9\u5cf6\u306e\u9818\u571f",
        common: "\u30af\u30ea\u30b9\u30de\u30b9\u5cf6",
      },
      kor: {
        official: "\ud06c\ub9ac\uc2a4\ub9c8\uc2a4 \uc12c",
        common: "\ud06c\ub9ac\uc2a4\ub9c8\uc2a4 \uc12c",
      },
      nld: {
        official: "Grondgebied van Christmas Island",
        common: "Christmaseiland",
      },
      per: {
        official:
          "\u062c\u0632\u06cc\u0631\u0647\u0654 \u06a9\u0631\u06cc\u0633\u0645\u0633",
        common:
          "\u062c\u0632\u06cc\u0631\u0647\u0654 \u06a9\u0631\u06cc\u0633\u0645\u0633",
      },
      pol: {
        official: "Wyspa Bo\u017cego Narodzenia",
        common: "Wyspa Bo\u017cego Narodzenia",
      },
      por: {
        official: "Territ\u00f3rio da Ilha Christmas",
        common: "Ilha do Natal",
      },
      rus: {
        official:
          "\u0422\u0435\u0440\u0440\u0438\u0442\u043e\u0440\u0438\u044f \u043e\u0441\u0442\u0440\u043e\u0432\u0430 \u0420\u043e\u0436\u0434\u0435\u0441\u0442\u0432\u0430",
        common:
          "\u041e\u0441\u0442\u0440\u043e\u0432 \u0420\u043e\u0436\u0434\u0435\u0441\u0442\u0432\u0430",
      },
      slk: {
        official: "Terit\u00f3rium Viano\u010dn\u00e9ho ostrova",
        common: "Viano\u010dn\u00fa ostrov",
      },
      spa: {
        official: "Territorio de la Isla de Navidad",
        common: "Isla de Navidad",
      },
      swe: { official: "Jul\u00f6n", common: "Jul\u00f6n" },
      urd: {
        official:
          "\u0631\u06cc\u0627\u0633\u062a\u0650 \u062c\u0632\u06cc\u0631\u06c1 \u06a9\u0631\u0633\u0645\u0633",
        common: "\u062c\u0632\u06cc\u0631\u06c1 \u06a9\u0631\u0633\u0645\u0633",
      },
      zho: { official: "\u5723\u8bde\u5c9b", common: "\u5723\u8bde\u5c9b" },
    },
    latlng: [-10.5, 105.66666666],
    landlocked: false,
    borders: [],
    area: 135,
    flag: "\ud83c\udde8\ud83c\uddfd",
    demonyms: {
      eng: { f: "Christmas Islander", m: "Christmas Islander" },
      fra: { f: "", m: "" },
    },
    callingCodes: ["+61"],
  },
  {
    name: {
      common: "Cayman Islands",
      official: "Cayman Islands",
      native: { eng: { official: "Cayman Islands", common: "Cayman Islands" } },
    },
    tld: [".ky"],
    cca2: "KY",
    ccn3: "136",
    cca3: "CYM",
    cioc: "CAY",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: { KYD: { name: "Cayman Islands dollar", symbol: "$" } },
    idd: { root: "+1", suffixes: ["345"] },
    capital: ["George Town"],
    altSpellings: ["KY"],
    region: "Americas",
    subregion: "Caribbean",
    languages: { eng: "English" },
    translations: {
      ces: {
        official: "Kajmansk\u00e9 ostrovy",
        common: "Kajmansk\u00e9 ostrovy",
      },
      cym: { official: "Ynysoedd Cayman", common: "Ynysoedd Cayman" },
      deu: { official: "Cayman-Inseln", common: "Kaimaninseln" },
      est: { official: "Kaimanisaared", common: "Kaimanisaared" },
      fin: { official: "Caymansaaret", common: "Caymansaaret" },
      fra: {
        official: "\u00celes Ca\u00efmans",
        common: "\u00celes Ca\u00efmans",
      },
      hrv: { official: "Kajmanski otoci", common: "Kajmanski otoci" },
      hun: { official: "Kajm\u00e1n-szigetek", common: "Kajm\u00e1n-szigetek" },
      ita: { official: "Isole Cayman", common: "Isole Cayman" },
      jpn: {
        official: "\u30b1\u30a4\u30de\u30f3\u8af8\u5cf6",
        common: "\u30b1\u30a4\u30de\u30f3\u8af8\u5cf6",
      },
      kor: {
        official: "\ucf00\uc774\ub9e8 \uc81c\ub3c4",
        common: "\ucf00\uc774\ub9e8 \uc81c\ub3c4",
      },
      nld: { official: "Caymaneilanden", common: "Caymaneilanden" },
      per: {
        official: "\u062c\u0632\u0627\u06cc\u0631 \u06a9\u06cc\u0645\u0646",
        common: "\u062c\u0632\u0627\u06cc\u0631 \u06a9\u06cc\u0645\u0646",
      },
      pol: { official: "Kajmany", common: "Kajmany" },
      por: { official: "Ilhas Cayman", common: "Ilhas Caim\u00e3o" },
      rus: {
        official:
          "\u041a\u0430\u0439\u043c\u0430\u043d\u043e\u0432\u044b \u043e\u0441\u0442\u0440\u043e\u0432\u0430",
        common:
          "\u041a\u0430\u0439\u043c\u0430\u043d\u043e\u0432\u044b \u043e\u0441\u0442\u0440\u043e\u0432\u0430",
      },
      slk: { official: "Kajmanie ostrovy", common: "Kajmanie ostrovy" },
      spa: { official: "Islas Caim\u00e1n", common: "Islas Caim\u00e1n" },
      swe: { official: "Cayman\u00f6arna", common: "Cayman\u00f6arna" },
      urd: {
        official:
          "\u062c\u0632\u0627\u0626\u0631 \u06a9\u06cc\u0645\u06cc\u0646",
        common: "\u062c\u0632\u0627\u0626\u0631 \u06a9\u06cc\u0645\u06cc\u0646",
      },
      zho: {
        official: "\u5f00\u66fc\u7fa4\u5c9b",
        common: "\u5f00\u66fc\u7fa4\u5c9b",
      },
    },
    latlng: [19.5, -80.5],
    landlocked: false,
    borders: [],
    area: 264,
    flag: "\ud83c\uddf0\ud83c\uddfe",
    demonyms: {
      eng: { f: "Caymanian", m: "Caymanian" },
      fra: { f: "Ca\u00efmanienne", m: "Ca\u00efmanien" },
    },
    callingCodes: ["+1345"],
  },
  {
    name: {
      common: "Cyprus",
      official: "Republic of Cyprus",
      native: {
        ell: {
          official:
            "\u0394\u03b7\u03bc\u03bf\u03ba\u03c1\u03b1\u03c4\u03af\u03b1 \u03c4\u03b7\u03c2 \u039a\u03cd\u03c0\u03c1\u03bf\u03c2",
          common: "\u039a\u03cd\u03c0\u03c1\u03bf\u03c2",
        },
        tur: {
          official: "K\u0131br\u0131s Cumhuriyeti",
          common: "K\u0131br\u0131s",
        },
      },
    },
    tld: [".cy"],
    cca2: "CY",
    ccn3: "196",
    cca3: "CYP",
    cioc: "CYP",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { EUR: { name: "Euro", symbol: "\u20ac" } },
    idd: { root: "+3", suffixes: ["57"] },
    capital: ["Nicosia"],
    altSpellings: [
      "CY",
      "K\u00fdpros",
      "K\u0131br\u0131s",
      "Republic of Cyprus",
      "\u039a\u03c5\u03c0\u03c1\u03b9\u03b1\u03ba\u03ae \u0394\u03b7\u03bc\u03bf\u03ba\u03c1\u03b1\u03c4\u03af\u03b1",
      "K\u0131br\u0131s Cumhuriyeti",
    ],
    region: "Europe",
    subregion: "Southern Europe",
    languages: { ell: "Greek", tur: "Turkish" },
    translations: {
      ces: { official: "Kypersk\u00e1 republika", common: "Kypr" },
      cym: { official: "Gweriniaeth Cyprus", common: "Cyprus" },
      deu: { official: "Republik Zypern", common: "Zypern" },
      est: { official: "K\u00fcprose Vabariik", common: "K\u00fcpros" },
      fin: { official: "Kyproksen tasavalta", common: "Kypros" },
      fra: { official: "R\u00e9publique de Chypre", common: "Chypre" },
      hrv: { official: "Republika Cipar", common: "Cipar" },
      hun: { official: "Ciprusi K\u00f6zt\u00e1rsas\u00e1g", common: "Ciprus" },
      ita: { official: "Repubblica di Cipro", common: "Cipro" },
      jpn: {
        official: "\u30ad\u30d7\u30ed\u30b9\u5171\u548c\u56fd",
        common: "\u30ad\u30d7\u30ed\u30b9",
      },
      kor: {
        official: "\ud0a4\ud504\ub85c\uc2a4 \uacf5\ud654\uad6d",
        common: "\ud0a4\ud504\ub85c\uc2a4",
      },
      nld: { official: "Republiek Cyprus", common: "Cyprus" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0642\u0628\u0631\u0633",
        common: "\u0642\u0650\u0628\u0631\u0650\u0633",
      },
      pol: { official: "Republika Cypryjska", common: "Cypr" },
      por: { official: "Rep\u00fablica de Chipre", common: "Chipre" },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u041a\u0438\u043f\u0440",
        common: "\u041a\u0438\u043f\u0440",
      },
      slk: { official: "Cypersk\u00e1 republika", common: "Cyprus" },
      spa: { official: "Rep\u00fablica de Chipre", common: "Chipre" },
      swe: { official: "Republiken Cypern", common: "Cypern" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0642\u0628\u0631\u0635",
        common: "\u0642\u0628\u0631\u0635",
      },
      zho: {
        official: "\u585e\u6d66\u8def\u65af\u5171\u548c\u56fd",
        common: "\u585e\u6d66\u8def\u65af",
      },
    },
    latlng: [35, 33],
    landlocked: false,
    borders: [],
    area: 9251,
    flag: "\ud83c\udde8\ud83c\uddfe",
    demonyms: {
      eng: { f: "Cypriot", m: "Cypriot" },
      fra: { f: "Chypriote", m: "Chypriote" },
    },
    callingCodes: ["+357"],
  },
  {
    name: {
      common: "Czechia",
      official: "Czech Republic",
      native: {
        ces: { official: "\u010cesk\u00e1 republika", common: "\u010cesko" },
        slk: { official: "\u010cesk\u00e1 republika", common: "\u010cesko" },
      },
    },
    tld: [".cz"],
    cca2: "CZ",
    ccn3: "203",
    cca3: "CZE",
    cioc: "CZE",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { CZK: { name: "Czech koruna", symbol: "K\u010d" } },
    idd: { root: "+4", suffixes: ["20"] },
    capital: ["Prague"],
    altSpellings: ["CZ", "\u010cesk\u00e1 republika", "\u010cesko"],
    region: "Europe",
    subregion: "Central Europe",
    languages: { ces: "Czech", slk: "Slovak" },
    translations: {
      ces: { official: "\u010cesk\u00e1 republika", common: "\u010cesko" },
      cym: { official: "Y Weriniaeth Tsiec", common: "Y Weriniaeth Tsiec" },
      deu: { official: "Tschechische Republik", common: "Tschechien" },
      est: { official: "T\u0161ehhi Vabariik", common: "T\u0161ehhi" },
      fin: { official: "T\u0161ekin tasavalta", common: "T\u0161ekki" },
      fra: {
        official: "R\u00e9publique tch\u00e8que",
        common: "Tch\u00e9quie",
      },
      hrv: { official: "\u010ce\u0161ka", common: "\u010ce\u0161ka" },
      hun: {
        official: "Cseh K\u00f6zt\u00e1rsas\u00e1g",
        common: "Csehorsz\u00e1g",
      },
      ita: { official: "Repubblica Ceca", common: "Cechia" },
      jpn: {
        official: "\u30c1\u30a7\u30b3\u5171\u548c\u56fd",
        common: "\u30c1\u30a7\u30b3",
      },
      kor: { official: "\uccb4\ucf54", common: "\uccb4\ucf54" },
      nld: { official: "Tsjechische Republiek", common: "Tsjechi\u00eb" },
      per: {
        official: "\u062c\u0645\u0647\u0648\u0631\u06cc \u0686\u06a9",
        common: "\u062c\u0645\u0647\u0648\u0631\u06cc \u0686\u06a9",
      },
      pol: { official: "Republika Czeska", common: "Czechy" },
      por: { official: "Rep\u00fablica Checa", common: "Ch\u00e9quia" },
      rus: {
        official:
          "\u0427\u0435\u0448\u0441\u043a\u0430\u044f \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430",
        common: "\u0427\u0435\u0445\u0438\u044f",
      },
      slk: { official: "\u010cesk\u00e1 republika", common: "\u010cesko" },
      spa: { official: "Rep\u00fablica Checa", common: "Chequia" },
      swe: { official: "Republiken Tjeckien", common: "Tjeckien" },
      urd: {
        official:
          "\u0686\u064a\u06a9 \u062c\u0645\u06c1\u0648\u0631\u064a\u06c1",
        common: "\u0686\u064a\u06a9",
      },
      zho: {
        official: "\u6377\u514b\u5171\u548c\u56fd",
        common: "\u6377\u514b",
      },
    },
    latlng: [49.75, 15.5],
    landlocked: true,
    borders: ["AUT", "DEU", "POL", "SVK"],
    area: 78865,
    flag: "\ud83c\udde8\ud83c\uddff",
    demonyms: {
      eng: { f: "Czech", m: "Czech" },
      fra: { f: "Tch\u00e8que", m: "Tch\u00e8que" },
    },
    callingCodes: ["+420"],
  },
  {
    name: {
      common: "Germany",
      official: "Federal Republic of Germany",
      native: {
        deu: { official: "Bundesrepublik Deutschland", common: "Deutschland" },
      },
    },
    tld: [".de"],
    cca2: "DE",
    ccn3: "276",
    cca3: "DEU",
    cioc: "GER",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { EUR: { name: "Euro", symbol: "\u20ac" } },
    idd: { root: "+4", suffixes: ["9"] },
    capital: ["Berlin"],
    altSpellings: [
      "DE",
      "Federal Republic of Germany",
      "Bundesrepublik Deutschland",
    ],
    region: "Europe",
    subregion: "Western Europe",
    languages: { deu: "German" },
    translations: {
      ces: {
        official: "Spolkov\u00e1 republika N\u011bmecko",
        common: "N\u011bmecko",
      },
      deu: { official: "Bundesrepublik Deutschland", common: "Deutschland" },
      est: { official: "Saksamaa Liitvabariik", common: "Saksamaa" },
      fin: { official: "Saksan liittotasavalta", common: "Saksa" },
      fra: {
        official: "R\u00e9publique f\u00e9d\u00e9rale d'Allemagne",
        common: "Allemagne",
      },
      hrv: {
        official: "Njema\u010dka Federativna Republika",
        common: "Njema\u010dka",
      },
      hun: {
        official: "N\u00e9met Sz\u00f6vets\u00e9gi K\u00f6zt\u00e1rsas\u00e1g",
        common: "N\u00e9metorsz\u00e1g",
      },
      ita: { official: "Repubblica federale di Germania", common: "Germania" },
      jpn: {
        official: "\u30c9\u30a4\u30c4\u9023\u90a6\u5171\u548c\u56fd",
        common: "\u30c9\u30a4\u30c4",
      },
      kor: {
        official: "\ub3c5\uc77c \uc5f0\ubc29 \uacf5\ud654\uad6d",
        common: "\ub3c5\uc77c",
      },
      nld: { official: "Bondsrepubliek Duitsland", common: "Duitsland" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0641\u062f\u0631\u0627\u0644 \u0622\u0644\u0645\u0627\u0646",
        common: "\u0622\u0644\u0645\u0627\u0646",
      },
      pol: { official: "Republika Federalna Niemiec", common: "Niemcy" },
      por: {
        official: "Rep\u00fablica Federal da Alemanha",
        common: "Alemanha",
      },
      rus: {
        official:
          "\u0424\u0435\u0434\u0435\u0440\u0430\u0442\u0438\u0432\u043d\u0430\u044f \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0413\u0435\u0440\u043c\u0430\u043d\u0438\u044f",
        common: "\u0413\u0435\u0440\u043c\u0430\u043d\u0438\u044f",
      },
      slk: {
        official: "Nemeck\u00e1 spolkov\u00e1 republika",
        common: "Nemecko",
      },
      spa: {
        official: "Rep\u00fablica Federal de Alemania",
        common: "Alemania",
      },
      swe: { official: "F\u00f6rbundsrepubliken Tyskland", common: "Tyskland" },
      urd: {
        official:
          "\u0648\u0641\u0627\u0642\u06cc \u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u062c\u0631\u0645\u0646\u06cc",
        common: "\u062c\u0631\u0645\u0646\u06cc",
      },
      zho: {
        official: "\u5fb7\u610f\u5fd7\u8054\u90a6\u5171\u548c\u56fd",
        common: "\u5fb7\u56fd",
      },
    },
    latlng: [51, 9],
    landlocked: false,
    borders: ["AUT", "BEL", "CZE", "DNK", "FRA", "LUX", "NLD", "POL", "CHE"],
    area: 357114,
    flag: "\ud83c\udde9\ud83c\uddea",
    demonyms: {
      eng: { f: "German", m: "German" },
      fra: { f: "Allemande", m: "Allemand" },
    },
    callingCodes: ["+49"],
  },
  {
    name: {
      common: "Djibouti",
      official: "Republic of Djibouti",
      native: {
        ara: {
          official:
            "\u062c\u0645\u0647\u0648\u0631\u064a\u0629 \u062c\u064a\u0628\u0648\u062a\u064a",
          common: "\u062c\u064a\u0628\u0648\u062a\u064a\u200e",
        },
        fra: { official: "R\u00e9publique de Djibouti", common: "Djibouti" },
      },
    },
    tld: [".dj"],
    cca2: "DJ",
    ccn3: "262",
    cca3: "DJI",
    cioc: "DJI",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { DJF: { name: "Djiboutian franc", symbol: "Fr" } },
    idd: { root: "+2", suffixes: ["53"] },
    capital: ["Djibouti"],
    altSpellings: [
      "DJ",
      "Jabuuti",
      "Gabuuti",
      "Republic of Djibouti",
      "R\u00e9publique de Djibouti",
      "Gabuutih Ummuuno",
      "Jamhuuriyadda Jabuuti",
    ],
    region: "Africa",
    subregion: "Eastern Africa",
    languages: { ara: "Arabic", fra: "French" },
    translations: {
      ces: {
        official: "D\u017eibutsk\u00e1 republika",
        common: "D\u017eibutsko",
      },
      cym: { official: "Gweriniaeth Jibwti", common: "Jibwti" },
      deu: { official: "Republik Dschibuti", common: "Dschibuti" },
      est: { official: "Djibouti Vabariik", common: "Djibouti" },
      fin: { official: "Dijiboutin tasavalta", common: "Dijibouti" },
      fra: { official: "R\u00e9publique de Djibouti", common: "Djibouti" },
      hrv: { official: "Republika D\u017eibuti", common: "D\u017eibuti" },
      hun: {
        official: "Dzsibuti K\u00f6zt\u00e1rsas\u00e1g",
        common: "Dzsibuti",
      },
      ita: { official: "Repubblica di Gibuti", common: "Gibuti" },
      jpn: {
        official: "\u30b8\u30d6\u30c1\u5171\u548c\u56fd",
        common: "\u30b8\u30d6\u30c1",
      },
      kor: {
        official: "\uc9c0\ubd80\ud2f0 \uacf5\ud654\uad6d",
        common: "\uc9c0\ubd80\ud2f0",
      },
      nld: { official: "Republiek Djibouti", common: "Djibouti" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u062c\u06cc\u0628\u0648\u062a\u06cc",
        common: "\u062c\u06cc\u0628\u0648\u062a\u06cc",
      },
      pol: { official: "Republika D\u017cibuti", common: "D\u017cibuti" },
      por: { official: "Rep\u00fablica do Djibouti", common: "Djibouti" },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0414\u0436\u0438\u0431\u0443\u0442\u0438",
        common: "\u0414\u0436\u0438\u0431\u0443\u0442\u0438",
      },
      slk: {
        official: "\u01c5ibutsk\u00e1 republika",
        common: "\u01c5ibutsko",
      },
      spa: { official: "Rep\u00fablica de Djibouti", common: "Djibouti" },
      swe: { official: "Republiken Djibouti", common: "Djibouti" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u062c\u0628\u0648\u062a\u06cc",
        common: "\u062c\u0628\u0648\u062a\u06cc",
      },
      zho: {
        official: "\u5409\u5e03\u63d0\u5171\u548c\u56fd",
        common: "\u5409\u5e03\u63d0",
      },
    },
    latlng: [11.5, 43],
    landlocked: false,
    borders: ["ERI", "ETH", "SOM"],
    area: 23200,
    flag: "\ud83c\udde9\ud83c\uddef",
    demonyms: {
      eng: { f: "Djibouti", m: "Djibouti" },
      fra: { f: "Djiboutienne", m: "Djiboutien" },
    },
    callingCodes: ["+253"],
  },
  {
    name: {
      common: "Dominica",
      official: "Commonwealth of Dominica",
      native: {
        eng: { official: "Commonwealth of Dominica", common: "Dominica" },
      },
    },
    tld: [".dm"],
    cca2: "DM",
    ccn3: "212",
    cca3: "DMA",
    cioc: "DMA",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { XCD: { name: "Eastern Caribbean dollar", symbol: "$" } },
    idd: { root: "+1", suffixes: ["767"] },
    capital: ["Roseau"],
    altSpellings: [
      "DM",
      "Dominique",
      "Wai\u2018tu kubuli",
      "Commonwealth of Dominica",
    ],
    region: "Americas",
    subregion: "Caribbean",
    languages: { eng: "English" },
    translations: {
      ces: {
        official: "Dominik\u00e1nsk\u00e9 spole\u010denstv\u00ed",
        common: "Dominika",
      },
      cym: { official: "Cymanwlad Dominica", common: "Dominica" },
      deu: { official: "Commonwealth von Dominica", common: "Dominica" },
      est: { official: "Dominica \u00dchendus", common: "Dominica" },
      fin: { official: "Dominican liittovaltio", common: "Dominica" },
      fra: { official: "Commonwealth de la Dominique", common: "Dominique" },
      hrv: { official: "Zajednica Dominika", common: "Dominika" },
      hun: {
        official: "Dominikai K\u00f6z\u00f6ss\u00e9g",
        common: "Dominikai K\u00f6z\u00f6ss\u00e9g",
      },
      ita: { official: "Commonwealth di Dominica", common: "Dominica" },
      jpn: {
        official: "\u30c9\u30df\u30cb\u30ab\u56fd",
        common: "\u30c9\u30df\u30cb\u30ab\u56fd",
      },
      kor: {
        official: "\ub3c4\ubbf8\ub2c8\uce74 \uacf5\ud654\uad6d",
        common: "\ub3c4\ubbf8\ub2c8\uce74 \uacf5\ud654\uad6d",
      },
      nld: { official: "Gemenebest Dominica", common: "Dominica" },
      per: {
        official:
          "\u0642\u0644\u0645\u0631\u0648 \u0647\u0645\u0633\u0648\u062f \u062f\u0648\u0645\u06cc\u0646\u06cc\u06a9\u0627",
        common: "\u062f\u0648\u0645\u06cc\u0646\u06cc\u06a9\u0627",
      },
      pol: { official: "Wsp\u00f3lnota Dominiki", common: "Dominika" },
      por: { official: "Comunidade da Dominica", common: "Dominica" },
      rus: {
        official:
          "\u0421\u043e\u0434\u0440\u0443\u0436\u0435\u0441\u0442\u0432\u043e \u0414\u043e\u043c\u0438\u043d\u0438\u043a\u0438",
        common: "\u0414\u043e\u043c\u0438\u043d\u0438\u043a\u0430",
      },
      slk: { official: "Dominick\u00e9 spolo\u010denstvo", common: "Dominika" },
      spa: { official: "Mancomunidad de Dominica", common: "Dominica" },
      swe: { official: "Samv\u00e4ldet Dominica", common: "Dominica" },
      urd: {
        official:
          "\u062f\u0648\u0644\u062a\u0650 \u0645\u0634\u062a\u0631\u06a9\u06c1 \u0688\u0648\u0645\u06cc\u0646\u06cc\u06a9\u0627",
        common: "\u0688\u0648\u0645\u06cc\u0646\u06cc\u06a9\u0627",
      },
      zho: {
        official: "\u591a\u7c73\u5c3c\u52a0\u5171\u548c\u56fd",
        common: "\u591a\u7c73\u5c3c\u52a0",
      },
    },
    latlng: [15.41666666, -61.33333333],
    landlocked: false,
    borders: [],
    area: 751,
    flag: "\ud83c\udde9\ud83c\uddf2",
    demonyms: {
      eng: { f: "Dominican", m: "Dominican" },
      fra: { f: "Dominiquaise", m: "Dominiquais" },
    },
    callingCodes: ["+1767"],
  },
  {
    name: {
      common: "Denmark",
      official: "Kingdom of Denmark",
      native: { dan: { official: "Kongeriget Danmark", common: "Danmark" } },
    },
    tld: [".dk"],
    cca2: "DK",
    ccn3: "208",
    cca3: "DNK",
    cioc: "DEN",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { DKK: { name: "Danish krone", symbol: "kr" } },
    idd: { root: "+4", suffixes: ["5"] },
    capital: ["Copenhagen"],
    altSpellings: ["DK", "Danmark", "Kingdom of Denmark", "Kongeriget Danmark"],
    region: "Europe",
    subregion: "Northern Europe",
    languages: { dan: "Danish" },
    translations: {
      ces: {
        official: "D\u00e1nsk\u00e9 kr\u00e1lovstv\u00ed",
        common: "D\u00e1nsko",
      },
      cym: { official: "Teyrnas Denmarc", common: "Denmarc" },
      deu: {
        official: "K\u00f6nigreich D\u00e4nemark",
        common: "D\u00e4nemark",
      },
      est: { official: "Taani Kuningriik", common: "Taani" },
      fin: { official: "Tanskan kuningaskunta", common: "Tanska" },
      fra: { official: "Royaume du Danemark", common: "Danemark" },
      hrv: { official: "Kraljevina Danska", common: "Danska" },
      hun: { official: "D\u00e1n Kir\u00e1lys\u00e1g", common: "D\u00e1nia" },
      ita: { official: "Regno di Danimarca", common: "Danimarca" },
      jpn: {
        official: "\u30c7\u30f3\u30de\u30fc\u30af\u738b\u56fd",
        common: "\u30c7\u30f3\u30de\u30fc\u30af",
      },
      kor: {
        official: "\ub374\ub9c8\ud06c \uc655\uad6d",
        common: "\ub374\ub9c8\ud06c",
      },
      nld: { official: "Koninkrijk Denemarken", common: "Denemarken" },
      per: {
        official:
          "\u067e\u0627\u062f\u0634\u0627\u0647\u06cc \u062f\u0627\u0646\u0645\u0627\u0631\u06a9",
        common: "\u062f\u0627\u0646\u0645\u0627\u0631\u06a9",
      },
      pol: { official: "Kr\u00f3lestwo Danii", common: "Dania" },
      por: { official: "Reino da Dinamarca", common: "Dinamarca" },
      rus: {
        official:
          "\u041a\u043e\u0440\u043e\u043b\u0435\u0432\u0441\u0442\u0432\u043e \u0414\u0430\u043d\u0438\u044f",
        common: "\u0414\u0430\u043d\u0438\u044f",
      },
      slk: {
        official: "D\u00e1nske kr\u00e1\u013eovstvo",
        common: "D\u00e1nsko",
      },
      spa: { official: "Reino de Dinamarca", common: "Dinamarca" },
      swe: { official: "Konungariket Danmark", common: "Danmark" },
      urd: {
        official:
          "\u0645\u0645\u0644\u06a9\u062a\u0650 \u0688\u0646\u0645\u0627\u0631\u06a9",
        common: "\u0688\u0646\u0645\u0627\u0631\u06a9",
      },
      zho: { official: "\u4e39\u9ea6\u738b\u56fd", common: "\u4e39\u9ea6" },
    },
    latlng: [56, 10],
    landlocked: false,
    borders: ["DEU"],
    area: 43094,
    flag: "\ud83c\udde9\ud83c\uddf0",
    demonyms: {
      eng: { f: "Danish", m: "Danish" },
      fra: { f: "Danoise", m: "Danois" },
    },
    callingCodes: ["+45"],
  },
  {
    name: {
      common: "Dominican Republic",
      official: "Dominican Republic",
      native: {
        spa: {
          official: "Rep\u00fablica Dominicana",
          common: "Rep\u00fablica Dominicana",
        },
      },
    },
    tld: [".do"],
    cca2: "DO",
    ccn3: "214",
    cca3: "DOM",
    cioc: "DOM",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { DOP: { name: "Dominican peso", symbol: "$" } },
    idd: { root: "+1", suffixes: ["809", "829", "849"] },
    capital: ["Santo Domingo"],
    altSpellings: ["DO"],
    region: "Americas",
    subregion: "Caribbean",
    languages: { spa: "Spanish" },
    translations: {
      ces: {
        official: "Dominik\u00e1nsk\u00e1 republika",
        common: "Dominik\u00e1nsk\u00e1 republika",
      },
      cym: { official: "Gweriniaeth Dominica", common: "Gweriniaeth Dominica" },
      deu: {
        official: "Dominikanische Republik",
        common: "Dominikanische Republik",
      },
      est: { official: "Dominikaani Vabariik", common: "Dominikaani Vabariik" },
      fin: {
        official: "Dominikaaninen tasavalta",
        common: "Dominikaaninen tasavalta",
      },
      fra: {
        official: "R\u00e9publique Dominicaine",
        common: "R\u00e9publique dominicaine",
      },
      hrv: {
        official: "Dominikanska Republika",
        common: "Dominikanska Republika",
      },
      hun: {
        official: "Dominikai K\u00f6zt\u00e1rsas\u00e1g",
        common: "Dominikai K\u00f6zt\u00e1rsas\u00e1g",
      },
      ita: {
        official: "Repubblica Dominicana",
        common: "Repubblica Dominicana",
      },
      jpn: {
        official: "\u30c9\u30df\u30cb\u30ab\u5171\u548c\u56fd",
        common: "\u30c9\u30df\u30cb\u30ab\u5171\u548c\u56fd",
      },
      kor: {
        official: "\ub3c4\ubbf8\ub2c8\uce74 \uacf5\ud654\uad6d",
        common: "\ub3c4\ubbf8\ub2c8\uce74 \uacf5\ud654\uad6d",
      },
      nld: {
        official: "Dominicaanse Republiek",
        common: "Dominicaanse Republiek",
      },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u062f\u0648\u0645\u06cc\u0646\u06cc\u06a9\u0646",
        common:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u062f\u0648\u0645\u06cc\u0646\u06cc\u06a9\u0646",
      },
      pol: { official: "Republika Dominika\u0144ska", common: "Dominikana" },
      por: {
        official: "Rep\u00fablica Dominicana",
        common: "Rep\u00fablica Dominicana",
      },
      rus: {
        official:
          "\u0414\u043e\u043c\u0438\u043d\u0438\u043a\u0430\u043d\u0441\u043a\u0430\u044f \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430",
        common:
          "\u0414\u043e\u043c\u0438\u043d\u0438\u043a\u0430\u043d\u0441\u043a\u0430\u044f \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430",
      },
      slk: {
        official: "Dominik\u00e1nska republika",
        common: "Dominik\u00e1nska republika",
      },
      spa: {
        official: "Rep\u00fablica Dominicana",
        common: "Rep\u00fablica Dominicana",
      },
      swe: {
        official: "Dominikanska republiken",
        common: "Dominikanska republiken",
      },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0688\u0648\u0645\u06cc\u0646\u06cc\u06a9\u0646",
        common: "\u0688\u0648\u0645\u06cc\u0646\u06cc\u06a9\u0646",
      },
      zho: {
        official: "\u591a\u660e\u5c3c\u52a0\u5171\u548c\u56fd",
        common: "\u591a\u660e\u5c3c\u52a0",
      },
    },
    latlng: [19, -70.66666666],
    landlocked: false,
    borders: ["HTI"],
    area: 48671,
    flag: "\ud83c\udde9\ud83c\uddf4",
    demonyms: {
      eng: { f: "Dominican", m: "Dominican" },
      fra: { f: "Dominicaine", m: "Dominicain" },
    },
    callingCodes: ["+1809", "+1829", "+1849"],
  },
  {
    name: {
      common: "Algeria",
      official: "People's Democratic Republic of Algeria",
      native: {
        ara: {
          official:
            "\u0627\u0644\u062c\u0645\u0647\u0648\u0631\u064a\u0629 \u0627\u0644\u062f\u064a\u0645\u0642\u0631\u0627\u0637\u064a\u0629 \u0627\u0644\u0634\u0639\u0628\u064a\u0629 \u0627\u0644\u062c\u0632\u0627\u0626\u0631\u064a\u0629",
          common: "\u0627\u0644\u062c\u0632\u0627\u0626\u0631",
        },
      },
    },
    tld: [".dz", "\u0627\u0644\u062c\u0632\u0627\u0626\u0631."],
    cca2: "DZ",
    ccn3: "012",
    cca3: "DZA",
    cioc: "ALG",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { DZD: { name: "Algerian dinar", symbol: "\u062f.\u062c" } },
    idd: { root: "+2", suffixes: ["13"] },
    capital: ["Algiers"],
    altSpellings: ["DZ", "Dzayer", "Alg\u00e9rie"],
    region: "Africa",
    subregion: "Northern Africa",
    languages: { ara: "Arabic" },
    translations: {
      ces: {
        official:
          "Al\u017e\u00edrsk\u00e1 demokratick\u00e1 a lidov\u00e1 republika",
        common: "Al\u017e\u00edrsko",
      },
      cym: {
        official: "Gweriniaeth Ddemocrataidd Pobl Algeria",
        common: "Algeria",
      },
      deu: {
        official: "Demokratische Volksrepublik Algerien",
        common: "Algerien",
      },
      est: {
        official: "Al\u017eeeria Demokraatlik Rahvavabariik",
        common: "Al\u017eeeria",
      },
      fin: {
        official: "Algerian demokraattinen kansantasavalta",
        common: "Algeria",
      },
      fra: {
        official:
          "R\u00e9publique d\u00e9mocratique et populaire d'Alg\u00e9rie",
        common: "Alg\u00e9rie",
      },
      hrv: {
        official: "Narodna Demokratska Republika Al\u017eir",
        common: "Al\u017eir",
      },
      hun: {
        official:
          "Alg\u00e9riai N\u00e9pi Demokratikus K\u00f6zt\u00e1rsas\u00e1g",
        common: "Alg\u00e9ria",
      },
      ita: {
        official: "Repubblica popolare democratica di Algeria",
        common: "Algeria",
      },
      jpn: {
        official:
          "\u30a2\u30eb\u30b8\u30a7\u30ea\u30a2\u4eba\u6c11\u6c11\u4e3b\u5171\u548c\u56fd",
        common: "\u30a2\u30eb\u30b8\u30a7\u30ea\u30a2",
      },
      kor: {
        official:
          "\uc54c\uc81c\ub9ac \uc778\ubbfc \ubbfc\uc8fc \uacf5\ud654\uad6d",
        common: "\uc54c\uc81c\ub9ac",
      },
      nld: {
        official: "Democratische Volksrepubliek Algerije",
        common: "Algerije",
      },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u062f\u0645\u0648\u06a9\u0631\u0627\u062a\u06cc\u06a9 \u062e\u0644\u0642 \u0627\u0644\u062c\u0632\u0627\u06cc\u0631",
        common: "\u0627\u0644\u062c\u0632\u0627\u06cc\u0631",
      },
      pol: {
        official: "Algierska Republika Ludowo-Demokratyczna",
        common: "Algieria",
      },
      por: {
        official: "Rep\u00fablica Argelina Democr\u00e1tica e Popular",
        common: "Arg\u00e9lia",
      },
      rus: {
        official:
          "\u041d\u0430\u0440\u043e\u0434\u043d\u043e-\u0414\u0435\u043c\u043e\u043a\u0440\u0430\u0442\u0438\u0447\u0435\u0441\u043a\u0430\u044f \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0410\u043b\u0436\u0438\u0440",
        common: "\u0410\u043b\u0436\u0438\u0440",
      },
      slk: {
        official:
          "Al\u017e\u00edrska demokratick\u00e1 \u013eudov\u00e1 republika",
        common: "Al\u017e\u00edrsko",
      },
      spa: {
        official: "Rep\u00fablica Argelina Democr\u00e1tica y Popular",
        common: "Argelia",
      },
      swe: {
        official: "Demokratiska folkrepubliken Algeriet",
        common: "Algeriet",
      },
      urd: {
        official:
          "\u0639\u0648\u0627\u0645\u06cc \u062c\u0645\u06c1\u0648\u0631\u06cc \u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0627\u0644\u062c\u0632\u0627\u0626\u0631",
        common: "\u0627\u0644\u062c\u0632\u0627\u0626\u0631",
      },
      zho: {
        official:
          "\u963f\u5c14\u53ca\u5229\u4e9a\u4eba\u6c11\u6c11\u4e3b\u5171\u548c\u56fd",
        common: "\u963f\u5c14\u53ca\u5229\u4e9a",
      },
    },
    latlng: [28, 3],
    landlocked: false,
    borders: ["TUN", "LBY", "NER", "ESH", "MRT", "MLI", "MAR"],
    area: 2381741,
    flag: "\ud83c\udde9\ud83c\uddff",
    demonyms: {
      eng: { f: "Algerian", m: "Algerian" },
      fra: { f: "Alg\u00e9rienne", m: "Alg\u00e9rien" },
    },
    callingCodes: ["+213"],
  },
  {
    name: {
      common: "Ecuador",
      official: "Republic of Ecuador",
      native: {
        spa: { official: "Rep\u00fablica del Ecuador", common: "Ecuador" },
      },
    },
    tld: [".ec"],
    cca2: "EC",
    ccn3: "218",
    cca3: "ECU",
    cioc: "ECU",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { USD: { name: "United States dollar", symbol: "$" } },
    idd: { root: "+5", suffixes: ["93"] },
    capital: ["Quito"],
    altSpellings: ["EC", "Republic of Ecuador", "Rep\u00fablica del Ecuador"],
    region: "Americas",
    subregion: "South America",
    languages: { spa: "Spanish" },
    translations: {
      ces: {
        official: "Ekv\u00e1dorsk\u00e1 republika",
        common: "Ekv\u00e1dor",
      },
      cym: { official: "Gweriniaeth Ecwador", common: "Ecwador" },
      deu: { official: "Republik Ecuador", common: "Ecuador" },
      est: { official: "Ecuadori Vabariik", common: "Ecuador" },
      fin: { official: "Ecuadorin tasavalta", common: "Ecuador" },
      fra: {
        official: "R\u00e9publique de l'\u00c9quateur",
        common: "\u00c9quateur",
      },
      hrv: { official: "Republika Ekvador", common: "Ekvador" },
      hun: {
        official: "Ecuadori K\u00f6zt\u00e1rsas\u00e1g",
        common: "Ecuador",
      },
      ita: { official: "Repubblica dell'Ecuador", common: "Ecuador" },
      jpn: {
        official: "\u30a8\u30af\u30a2\u30c9\u30eb\u5171\u548c\u56fd",
        common: "\u30a8\u30af\u30a2\u30c9\u30eb",
      },
      kor: {
        official: "\uc5d0\ucf70\ub3c4\ub974 \uacf5\ud654\uad6d",
        common: "\uc5d0\ucf70\ub3c4\ub974",
      },
      nld: { official: "Republiek Ecuador", common: "Ecuador" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0627\u06a9\u0648\u0627\u062f\u0648\u0631",
        common: "\u0627\u06a9\u0648\u0627\u062f\u0648\u0631",
      },
      pol: { official: "Ekwador", common: "Ekwador" },
      por: { official: "Rep\u00fablica do Equador", common: "Equador" },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u042d\u043a\u0432\u0430\u0434\u043e\u0440",
        common: "\u042d\u043a\u0432\u0430\u0434\u043e\u0440",
      },
      slk: {
        official: "Ekv\u00e1dorsk\u00e1 republika",
        common: "Ekv\u00e1dor",
      },
      spa: { official: "Rep\u00fablica del Ecuador", common: "Ecuador" },
      swe: { official: "Republiken Ecuador", common: "Ecuador" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0627\u06cc\u06a9\u0648\u0688\u0648\u0631",
        common: "\u0627\u06cc\u06a9\u0648\u0627\u0688\u0648\u0631",
      },
      zho: {
        official: "\u5384\u74dc\u591a\u5c14\u5171\u548c\u56fd",
        common: "\u5384\u74dc\u591a\u5c14",
      },
    },
    latlng: [-2, -77.5],
    landlocked: false,
    borders: ["COL", "PER"],
    area: 276841,
    flag: "\ud83c\uddea\ud83c\udde8",
    demonyms: {
      eng: { f: "Ecuadorean", m: "Ecuadorean" },
      fra: { f: "\u00c9quatorienne", m: "\u00c9quatorien" },
    },
    callingCodes: ["+593"],
  },
  {
    name: {
      common: "Egypt",
      official: "Arab Republic of Egypt",
      native: {
        ara: {
          official:
            "\u062c\u0645\u0647\u0648\u0631\u064a\u0629 \u0645\u0635\u0631 \u0627\u0644\u0639\u0631\u0628\u064a\u0629",
          common: "\u0645\u0635\u0631",
        },
      },
    },
    tld: [".eg", ".\u0645\u0635\u0631"],
    cca2: "EG",
    ccn3: "818",
    cca3: "EGY",
    cioc: "EGY",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { EGP: { name: "Egyptian pound", symbol: "\u00a3" } },
    idd: { root: "+2", suffixes: ["0"] },
    capital: ["Cairo"],
    altSpellings: ["EG", "Arab Republic of Egypt"],
    region: "Africa",
    subregion: "Northern Africa",
    languages: { ara: "Arabic" },
    translations: {
      ces: {
        official: "Egyptsk\u00e1 arabsk\u00e1 republika",
        common: "Egypt",
      },
      cym: { official: "Gweriniaeth Arabaidd yr Aifft", common: "Yr Aifft" },
      deu: {
        official: "Arabische Republik \u00c4gypten",
        common: "\u00c4gypten",
      },
      est: { official: "Egiptuse Araabia Vabariik", common: "Egiptus" },
      fin: { official: "Egyptin arabitasavalta", common: "Egypti" },
      fra: {
        official: "R\u00e9publique arabe d'\u00c9gypte",
        common: "\u00c9gypte",
      },
      hrv: { official: "Arapska Republika Egipat", common: "Egipat" },
      hun: {
        official: "Egyiptomi Arab K\u00f6zt\u00e1rsas\u00e1g",
        common: "Egyiptom",
      },
      ita: { official: "Repubblica araba d'Egitto", common: "Egitto" },
      jpn: {
        official:
          "\u30a8\u30b8\u30d7\u30c8\u00b7\u30a2\u30e9\u30d6\u5171\u548c\u56fd",
        common: "\u30a8\u30b8\u30d7\u30c8",
      },
      kor: {
        official: "\uc774\uc9d1\ud2b8 \uc544\ub78d \uacf5\ud654\uad6d",
        common: "\uc774\uc9d1\ud2b8",
      },
      nld: { official: "Arabische Republiek Egypte", common: "Egypte" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0639\u0631\u0628\u06cc \u0645\u0635\u0631",
        common: "\u0645\u0635\u0631",
      },
      pol: { official: "Arabska Republika Egiptu", common: "Egipt" },
      por: { official: "Rep\u00fablica \u00c1rabe do Egipto", common: "Egito" },
      rus: {
        official:
          "\u0410\u0440\u0430\u0431\u0441\u043a\u0430\u044f \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0415\u0433\u0438\u043f\u0435\u0442",
        common: "\u0415\u0433\u0438\u043f\u0435\u0442",
      },
      slk: {
        official: "Egyptsk\u00e1 arabsk\u00e1 republika",
        common: "Egypt",
      },
      spa: {
        official: "Rep\u00fablica \u00c1rabe de Egipto",
        common: "Egipto",
      },
      swe: { official: "Arabrepubliken Egypten", common: "Egypten" },
      urd: {
        official:
          "\u0645\u0635\u0631\u06cc \u0639\u0631\u0628 \u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1",
        common: "\u0645\u0635\u0631",
      },
      zho: {
        official: "\u963f\u62c9\u4f2f\u57c3\u53ca\u5171\u548c\u56fd",
        common: "\u57c3\u53ca",
      },
    },
    latlng: [27, 30],
    landlocked: false,
    borders: ["ISR", "LBY", "PSE", "SDN"],
    area: 1002450,
    flag: "\ud83c\uddea\ud83c\uddec",
    demonyms: {
      eng: { f: "Egyptian", m: "Egyptian" },
      fra: { f: "\u00c9gyptienne", m: "\u00c9gyptien" },
    },
    callingCodes: ["+20"],
  },
  {
    name: {
      common: "Eritrea",
      official: "State of Eritrea",
      native: {
        ara: {
          official:
            "\u062f\u0648\u0644\u0629 \u0625\u0631\u062a\u0631\u064a\u0627",
          common: "\u0625\u0631\u062a\u0631\u064a\u0627\u200e",
        },
        eng: { official: "State of Eritrea", common: "Eritrea" },
        tir: {
          official: "\u1203\u1308\u1228 \u12a4\u122d\u1275\u122b",
          common: "\u12a4\u122d\u1275\u122b",
        },
      },
    },
    tld: [".er"],
    cca2: "ER",
    ccn3: "232",
    cca3: "ERI",
    cioc: "ERI",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { ERN: { name: "Eritrean nakfa", symbol: "Nfk" } },
    idd: { root: "+2", suffixes: ["91"] },
    capital: ["Asmara"],
    altSpellings: [
      "ER",
      "State of Eritrea",
      "\u1203\u1308\u1228 \u12a4\u122d\u1275\u122b",
      "Dawlat Iritriy\u00e1",
      "\u02beErtr\u0101",
      "Iritriy\u0101",
    ],
    region: "Africa",
    subregion: "Eastern Africa",
    languages: { ara: "Arabic", eng: "English", tir: "Tigrinya" },
    translations: {
      ces: { official: "St\u00e1t Eritrea", common: "Eritrea" },
      cym: { official: "Gwladwriaeth Eritrea", common: "Eritrea" },
      deu: { official: "Staat Eritrea", common: "Eritrea" },
      est: { official: "Eritrea Riik", common: "Eritrea" },
      fin: { official: "Eritrean valtio", common: "Eritrea" },
      fra: {
        official: "\u00c9tat d'\u00c9rythr\u00e9e",
        common: "\u00c9rythr\u00e9e",
      },
      hrv: { official: "Dr\u017eava Eritreji", common: "Eritreja" },
      hun: { official: "Eritrea", common: "Eritrea" },
      ita: { official: "Stato di Eritrea", common: "Eritrea" },
      jpn: {
        official: "\u30a8\u30ea\u30c8\u30ea\u30a2\u56fd",
        common: "\u30a8\u30ea\u30c8\u30ea\u30a2",
      },
      kor: {
        official: "\uc5d0\ub9ac\ud2b8\ub808\uc544\uad6d",
        common: "\uc5d0\ub9ac\ud2b8\ub808\uc544",
      },
      nld: { official: "Staat Eritrea", common: "Eritrea" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0627\u0631\u06cc\u062a\u0631\u0647",
        common: "\u0627\u0631\u06cc\u062a\u0631\u0647",
      },
      pol: { official: "Pa\u0144stwo Erytrea", common: "Erytrea" },
      por: { official: "Estado da Eritreia", common: "Eritreia" },
      rus: {
        official:
          "\u0413\u043e\u0441\u0443\u0434\u0430\u0440\u0441\u0442\u0432\u043e \u042d\u0440\u0438\u0442\u0440\u0435\u044f",
        common: "\u042d\u0440\u0438\u0442\u0440\u0435\u044f",
      },
      slk: { official: "Eritrejsk\u00fd \u0161t\u00e1t", common: "Eritrea" },
      spa: { official: "Estado de Eritrea", common: "Eritrea" },
      swe: { official: "Staten Eritrea", common: "Eritrea" },
      urd: {
        official:
          "\u0631\u06cc\u0627\u0633\u062a\u0650 \u0627\u0631\u062a\u0631\u06cc\u0627",
        common: "\u0627\u0631\u062a\u0631\u06cc\u0627",
      },
      zho: {
        official: "\u5384\u7acb\u7279\u91cc\u4e9a",
        common: "\u5384\u7acb\u7279\u91cc\u4e9a",
      },
    },
    latlng: [15, 39],
    landlocked: false,
    borders: ["DJI", "ETH", "SDN"],
    area: 117600,
    flag: "\ud83c\uddea\ud83c\uddf7",
    demonyms: {
      eng: { f: "Eritrean", m: "Eritrean" },
      fra: { f: "\u00c9rythr\u00e9enne", m: "\u00c9rythr\u00e9en" },
    },
    callingCodes: ["+291"],
  },
  {
    name: {
      common: "Western Sahara",
      official: "Sahrawi Arab Democratic Republic",
      native: {
        ber: {
          official: "Sahrawi Arab Democratic Republic",
          common: "Western Sahara",
        },
        mey: {
          official:
            "\u0627\u0644\u062c\u0645\u0647\u0648\u0631\u064a\u0629 \u0627\u0644\u0639\u0631\u0628\u064a\u0629 \u0627\u0644\u0635\u062d\u0631\u0627\u0648\u064a\u0629 \u0627\u0644\u062f\u064a\u0645\u0642\u0631\u0627\u0637\u064a\u0629",
          common:
            "\u0627\u0644\u0635\u062d\u0631\u0627\u0621 \u0627\u0644\u063a\u0631\u0628\u064a\u0629",
        },
        spa: {
          official: "Rep\u00fablica \u00c1rabe Saharaui Democr\u00e1tica",
          common: "Sahara Occidental",
        },
      },
    },
    tld: [".eh"],
    cca2: "EH",
    ccn3: "732",
    cca3: "ESH",
    cioc: "",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: {
      DZD: { name: "Algerian dinar", symbol: "\u062f\u062c" },
      MAD: { name: "Moroccan dirham", symbol: "DH" },
      MRU: { name: "Mauritanian ouguiya", symbol: "UM" },
    },
    idd: { root: "+2", suffixes: ["125288", "125289"] },
    capital: ["El Aai\u00fan"],
    altSpellings: ["EH", "Tane\u1e93roft Tutrimt"],
    region: "Africa",
    subregion: "Northern Africa",
    languages: { ber: "Berber", mey: "Hassaniya", spa: "Spanish" },
    translations: {
      ces: {
        official: "Z\u00e1padn\u00ed Sahara",
        common: "Z\u00e1padn\u00ed Sahara",
      },
      deu: {
        official: "Demokratische Arabische Republik Sahara",
        common: "Westsahara",
      },
      est: {
        official: "L\u00e4\u00e4ne-Sahara",
        common: "L\u00e4\u00e4ne-Sahara",
      },
      fin: { official: "L\u00e4nsi-Sahara", common: "L\u00e4nsi-Sahara" },
      fra: {
        official: "R\u00e9publique arabe sahraouie d\u00e9mocratique",
        common: "Sahara Occidental",
      },
      hrv: {
        official: "Sahrawi Arab Demokratska Republika",
        common: "Zapadna Sahara",
      },
      hun: { official: "Nyugat-Szahara", common: "Nyugat-Szahara" },
      ita: {
        official: "Repubblica Araba Saharawi Democratica",
        common: "Sahara Occidentale",
      },
      jpn: {
        official:
          "\u30b5\u30cf\u30e9\u30a2\u30e9\u30d6\u6c11\u4e3b\u5171\u548c\u56fd",
        common: "\u897f\u30b5\u30cf\u30e9",
      },
      kor: {
        official:
          "\uc0ac\ud558\ub77c \uc544\ub78d \ubbfc\uc8fc \uacf5\ud654\uad6d",
        common: "\uc11c\uc0ac\ud558\ub77c",
      },
      nld: {
        official: "Sahrawi Arabische Democratische Republiek",
        common: "Westelijke Sahara",
      },
      per: {
        official: "\u0635\u062d\u0631\u0627\u06cc \u063a\u0631\u0628\u06cc",
        common: "\u0635\u062d\u0631\u0627\u06cc \u063a\u0631\u0628\u06cc",
      },
      pol: {
        official: "Saharyjska Arabska Republika Demokratyczna",
        common: "Sahara Zachodnia",
      },
      por: {
        official: "Rep\u00fablica \u00c1rabe Saharaui Democr\u00e1tica",
        common: "Saara Ocidental",
      },
      rus: {
        official:
          "Sahrawi \u0410\u0440\u0430\u0431\u0441\u043a\u0430\u044f \u0414\u0435\u043c\u043e\u043a\u0440\u0430\u0442\u0438\u0447\u0435\u0441\u043a\u0430\u044f \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430",
        common:
          "\u0417\u0430\u043f\u0430\u0434\u043d\u0430\u044f \u0421\u0430\u0445\u0430\u0440\u0430",
      },
      slk: {
        official: "Z\u00e1padn\u00e1 Sahara",
        common: "Z\u00e1padn\u00e1 Sahara",
      },
      spa: {
        official: "Rep\u00fablica \u00c1rabe Saharaui Democr\u00e1tica",
        common: "Sahara Occidental",
      },
      swe: { official: "V\u00e4stsahara", common: "V\u00e4stsahara" },
      urd: {
        official:
          "\u0635\u062d\u0631\u0627\u0648\u06cc \u0639\u0631\u0628 \u0639\u0648\u0627\u0645\u06cc \u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1",
        common: "\u0645\u063a\u0631\u0628\u06cc \u0635\u062d\u0627\u0631\u0627",
      },
      zho: {
        official:
          "\u963f\u62c9\u4f2f\u6492\u54c8\u62c9\u6c11\u4e3b\u5171\u548c\u56fd",
        common: "\u897f\u6492\u54c8\u62c9",
      },
    },
    latlng: [24.5, -13],
    landlocked: false,
    borders: ["DZA", "MRT", "MAR"],
    area: 266000,
    flag: "\ud83c\uddea\ud83c\udded",
    demonyms: { eng: { f: "Sahrawi", m: "Sahrawi" }, fra: { f: "", m: "" } },
    callingCodes: ["+2125288", "+2125289"],
  },
  {
    name: {
      common: "Spain",
      official: "Kingdom of Spain",
      native: {
        spa: { official: "Reino de Espa\u00f1a", common: "Espa\u00f1a" },
      },
    },
    tld: [".es"],
    cca2: "ES",
    ccn3: "724",
    cca3: "ESP",
    cioc: "ESP",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { EUR: { name: "Euro", symbol: "\u20ac" } },
    idd: { root: "+3", suffixes: ["4"] },
    capital: ["Madrid"],
    altSpellings: ["ES", "Kingdom of Spain", "Reino de Espa\u00f1a"],
    region: "Europe",
    subregion: "Southern Europe",
    languages: { spa: "Spanish" },
    translations: {
      ces: {
        official: "\u0160pan\u011blsk\u00e9 kr\u00e1lovstv\u00ed",
        common: "\u0160pan\u011blsko",
      },
      deu: { official: "K\u00f6nigreich Spanien", common: "Spanien" },
      est: { official: "Hispaania Kuningriik", common: "Hispaania" },
      fin: { official: "Espanjan kuningaskunta", common: "Espanja" },
      fra: { official: "Royaume d'Espagne", common: "Espagne" },
      hrv: {
        official: "Kraljevina \u0160panjolska",
        common: "\u0160panjolska",
      },
      hun: {
        official: "Spanyol Kir\u00e1lys\u00e1g",
        common: "Spanyolorsz\u00e1g",
      },
      ita: { official: "Regno di Spagna", common: "Spagna" },
      jpn: {
        official: "\u30b9\u30da\u30a4\u30f3\u738b\u56fd",
        common: "\u30b9\u30da\u30a4\u30f3",
      },
      kor: {
        official: "\uc5d0\uc2a4\ud30c\ub0d0 \uc655\uad6d",
        common: "\uc2a4\ud398\uc778",
      },
      nld: { official: "Koninkrijk Spanje", common: "Spanje" },
      per: {
        official:
          "\u067e\u0627\u062f\u0634\u0627\u0647\u06cc \u0627\u0633\u067e\u0627\u0646\u06cc\u0627",
        common: "\u0627\u0633\u067e\u0627\u0646\u06cc\u0627",
      },
      pol: { official: "Kr\u00f3lestwo Hiszpanii ", common: "Hiszpania" },
      por: { official: "Reino de Espanha", common: "Espanha" },
      rus: {
        official:
          "\u041a\u043e\u0440\u043e\u043b\u0435\u0432\u0441\u0442\u0432\u043e \u0418\u0441\u043f\u0430\u043d\u0438\u044f",
        common: "\u0418\u0441\u043f\u0430\u043d\u0438\u044f",
      },
      slk: {
        official: "\u0160panielske kr\u00e1\u013eovstvo",
        common: "\u0160panielsko",
      },
      spa: { official: "Reino de Espa\u00f1a", common: "Espa\u00f1a" },
      swe: { official: "Konungariket Spanien", common: "Spanien" },
      urd: {
        official:
          "\u0645\u0645\u0644\u06a9\u062a\u0650 \u06c1\u0633\u067e\u0627\u0646\u06cc\u06c1",
        common: "\u06c1\u0633\u067e\u0627\u0646\u06cc\u06c1",
      },
      zho: {
        official: "\u897f\u73ed\u7259\u738b\u56fd",
        common: "\u897f\u73ed\u7259",
      },
    },
    latlng: [40, -4],
    landlocked: false,
    borders: ["AND", "FRA", "GIB", "PRT", "MAR"],
    area: 505992,
    flag: "\ud83c\uddea\ud83c\uddf8",
    demonyms: {
      eng: { f: "Spanish", m: "Spanish" },
      fra: { f: "Espagnole", m: "Espagnol" },
    },
    callingCodes: ["+34"],
  },
  {
    name: {
      common: "Estonia",
      official: "Republic of Estonia",
      native: { est: { official: "Eesti Vabariik", common: "Eesti" } },
    },
    tld: [".ee"],
    cca2: "EE",
    ccn3: "233",
    cca3: "EST",
    cioc: "EST",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { EUR: { name: "Euro", symbol: "\u20ac" } },
    idd: { root: "+3", suffixes: ["72"] },
    capital: ["Tallinn"],
    altSpellings: ["EE", "Eesti", "Republic of Estonia", "Eesti Vabariik"],
    region: "Europe",
    subregion: "Northern Europe",
    languages: { est: "Estonian" },
    translations: {
      ces: { official: "Estonsk\u00e1 republika", common: "Estonsko" },
      cym: { official: "Gweriniaeth Estonia", common: "Estonia" },
      deu: { official: "Republik Estland", common: "Estland" },
      est: { official: "Eesti Vabariik", common: "Eesti" },
      fin: { official: "Viron tasavalta", common: "Viro" },
      fra: { official: "R\u00e9publique d'Estonie", common: "Estonie" },
      hrv: { official: "Republika Estonija", common: "Estonija" },
      hun: {
        official: "\u00c9szt K\u00f6zt\u00e1rsas\u00e1g",
        common: "\u00c9sztorsz\u00e1g",
      },
      ita: { official: "Repubblica di Estonia", common: "Estonia" },
      jpn: {
        official: "\u30a8\u30b9\u30c8\u30cb\u30a2\u5171\u548c\u56fd",
        common: "\u30a8\u30b9\u30c8\u30cb\u30a2",
      },
      kor: {
        official: "\uc5d0\uc2a4\ud1a0\ub2c8\uc544 \uacf5\ud654\uad6d",
        common: "\uc5d0\uc2a4\ud1a0\ub2c8\uc544",
      },
      nld: { official: "Republiek Estland", common: "Estland" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0627\u0633\u062a\u0648\u0646\u06cc",
        common: "\u0627\u0650\u0633\u062a\u0648\u0646\u06cc",
      },
      pol: { official: "Republika Esto\u0144ska", common: "Estonia" },
      por: {
        official: "Rep\u00fablica da Est\u00f3nia",
        common: "Est\u00f3nia",
      },
      rus: {
        official:
          "\u042d\u0441\u0442\u043e\u043d\u0441\u043a\u0430\u044f \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430",
        common: "\u042d\u0441\u0442\u043e\u043d\u0438\u044f",
      },
      slk: { official: "Est\u00f3nska republika", common: "Est\u00f3nsko" },
      spa: { official: "Rep\u00fablica de Estonia", common: "Estonia" },
      swe: { official: "Republiken Estland", common: "Estland" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0627\u0633\u0679\u0648\u0646\u06cc\u0627",
        common: "\u0627\u0633\u0679\u0648\u0646\u06cc\u0627",
      },
      zho: {
        official: "\u7231\u6c99\u5c3c\u4e9a\u5171\u548c\u56fd",
        common: "\u7231\u6c99\u5c3c\u4e9a",
      },
    },
    latlng: [59, 26],
    landlocked: false,
    borders: ["LVA", "RUS"],
    area: 45227,
    flag: "\ud83c\uddea\ud83c\uddea",
    demonyms: {
      eng: { f: "Estonian", m: "Estonian" },
      fra: { f: "Estonienne", m: "Estonien" },
    },
    callingCodes: ["+372"],
  },
  {
    name: {
      common: "Ethiopia",
      official: "Federal Democratic Republic of Ethiopia",
      native: {
        amh: {
          official:
            "\u12e8\u12a2\u1275\u12ee\u1335\u12eb \u134c\u12f4\u122b\u120b\u12ca \u12f2\u121e\u12ad\u122b\u1232\u12eb\u12ca \u122a\u1350\u1265\u120a\u12ad",
          common: "\u12a2\u1275\u12ee\u1335\u12eb",
        },
      },
    },
    tld: [".et"],
    cca2: "ET",
    ccn3: "231",
    cca3: "ETH",
    cioc: "ETH",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { ETB: { name: "Ethiopian birr", symbol: "Br" } },
    idd: { root: "+2", suffixes: ["51"] },
    capital: ["Addis Ababa"],
    altSpellings: [
      "ET",
      "\u02be\u012aty\u014d\u1e57\u1e57y\u0101",
      "Federal Democratic Republic of Ethiopia",
      "\u12e8\u12a2\u1275\u12ee\u1335\u12eb \u134c\u12f4\u122b\u120b\u12ca \u12f2\u121e\u12ad\u122b\u1232\u12eb\u12ca \u122a\u1350\u1265\u120a\u12ad",
    ],
    region: "Africa",
    subregion: "Eastern Africa",
    languages: { amh: "Amharic" },
    translations: {
      ces: {
        official: "Etiopsk\u00e1 federativn\u00ed demokratick\u00e1 republika",
        common: "Etiopie",
      },
      cym: {
        official: "Gweriniaeth Ddemocrataidd Ffederal Ethiopia",
        common: "Ethiopia",
      },
      deu: {
        official: "Demokratische Bundesrepublik \u00c4thiopien",
        common: "\u00c4thiopien",
      },
      est: {
        official: "Etioopia Demokraatlik Liitvabariik",
        common: "Etioopia",
      },
      fin: {
        official: "Etiopian demokraattinen liittotasavalta",
        common: "Etiopia",
      },
      fra: {
        official:
          "R\u00e9publique f\u00e9d\u00e9rale d\u00e9mocratique d'\u00c9thiopie",
        common: "\u00c9thiopie",
      },
      hrv: {
        official: "Savezna Demokratska Republika Etiopija",
        common: "Etiopija",
      },
      hun: {
        official:
          "Eti\u00f3p Sz\u00f6vets\u00e9gi Demokratikus K\u00f6zt\u00e1rsas\u00e1g",
        common: "Eti\u00f3pia",
      },
      ita: {
        official: "Repubblica federale democratica di Etiopia",
        common: "Etiopia",
      },
      jpn: {
        official:
          "\u30a8\u30c1\u30aa\u30d4\u30a2\u9023\u90a6\u6c11\u4e3b\u5171\u548c\u56fd",
        common: "\u30a8\u30c1\u30aa\u30d4\u30a2",
      },
      kor: {
        official:
          "\uc5d0\ud2f0\uc624\ud53c\uc544 \uc5f0\ubc29 \ubbfc\uc8fc \uacf5\ud654\uad6d",
        common: "\uc5d0\ud2f0\uc624\ud53c\uc544",
      },
      nld: {
        official: "Federale Democratische Republiek Ethiopi\u00eb",
        common: "Ethiopi\u00eb",
      },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0641\u062f\u0631\u0627\u0644 \u062f\u0645\u0648\u06a9\u0631\u0627\u062a\u06cc\u06a9 \u0627\u062a\u06cc\u0648\u067e\u06cc",
        common: "\u0627\u0650\u062a\u06cc\u0648\u067e\u06cc",
      },
      pol: {
        official: "Federalna Demokratyczna Republika Etiopii",
        common: "Etiopia",
      },
      por: {
        official: "Rep\u00fablica Federal Democr\u00e1tica da Eti\u00f3pia",
        common: "Eti\u00f3pia",
      },
      rus: {
        official:
          "\u0424\u0435\u0434\u0435\u0440\u0430\u0442\u0438\u0432\u043d\u0430\u044f \u0414\u0435\u043c\u043e\u043a\u0440\u0430\u0442\u0438\u0447\u0435\u0441\u043a\u0430\u044f \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u042d\u0444\u0438\u043e\u043f\u0438\u044f",
        common: "\u042d\u0444\u0438\u043e\u043f\u0438\u044f",
      },
      slk: {
        official: "Eti\u00f3pska federat\u00edvna demokratick\u00e1 republika",
        common: "Eti\u00f3pia",
      },
      spa: {
        official: "Rep\u00fablica Democr\u00e1tica Federal de Etiop\u00eda",
        common: "Etiop\u00eda",
      },
      swe: {
        official: "Demokratiska f\u00f6rbundsrepubliken Etiopien",
        common: "Etiopien",
      },
      urd: {
        official:
          "\u0648\u0641\u0627\u0642\u06cc \u062c\u0645\u06c1\u0648\u0631\u06cc \u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0627\u06cc\u062a\u06be\u0648\u067e\u06cc\u0627",
        common: "\u0627\u06cc\u062a\u06be\u0648\u067e\u06cc\u0627",
      },
      zho: {
        official:
          "\u57c3\u585e\u4fc4\u6bd4\u4e9a\u8054\u90a6\u6c11\u4e3b\u5171\u548c\u56fd",
        common: "\u57c3\u585e\u4fc4\u6bd4\u4e9a",
      },
    },
    latlng: [8, 38],
    landlocked: true,
    borders: ["DJI", "ERI", "KEN", "SOM", "SSD", "SDN"],
    area: 1104300,
    flag: "\ud83c\uddea\ud83c\uddf9",
    demonyms: {
      eng: { f: "Ethiopian", m: "Ethiopian" },
      fra: { f: "\u00c9thiopienne", m: "\u00c9thiopien" },
    },
    callingCodes: ["+251"],
  },
  {
    name: {
      common: "Finland",
      official: "Republic of Finland",
      native: {
        fin: { official: "Suomen tasavalta", common: "Suomi" },
        swe: { official: "Republiken Finland", common: "Finland" },
      },
    },
    tld: [".fi"],
    cca2: "FI",
    ccn3: "246",
    cca3: "FIN",
    cioc: "FIN",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { EUR: { name: "Euro", symbol: "\u20ac" } },
    idd: { root: "+3", suffixes: ["58"] },
    capital: ["Helsinki"],
    altSpellings: [
      "FI",
      "Suomi",
      "Republic of Finland",
      "Suomen tasavalta",
      "Republiken Finland",
    ],
    region: "Europe",
    subregion: "Northern Europe",
    languages: { fin: "Finnish", swe: "Swedish" },
    translations: {
      ces: { official: "Finsk\u00e1 republika", common: "Finsko" },
      deu: { official: "Republik Finnland", common: "Finnland" },
      est: { official: "Soome Vabariik", common: "Soome" },
      fin: { official: "Suomen tasavalta", common: "Suomi" },
      fra: { official: "R\u00e9publique de Finlande", common: "Finlande" },
      hrv: { official: "Republika Finska", common: "Finska" },
      hun: {
        official: "Finn K\u00f6zt\u00e1rsas\u00e1g",
        common: "Finnorsz\u00e1g",
      },
      ita: { official: "Repubblica di Finlandia", common: "Finlandia" },
      jpn: {
        official: "\u30d5\u30a3\u30f3\u30e9\u30f3\u30c9\u5171\u548c\u56fd",
        common: "\u30d5\u30a3\u30f3\u30e9\u30f3\u30c9",
      },
      kor: {
        official: "\ud540\ub780\ub4dc \uacf5\ud654\uad6d",
        common: "\ud540\ub780\ub4dc",
      },
      nld: { official: "Republiek Finland", common: "Finland" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0641\u0646\u0644\u0627\u0646\u062f",
        common: "\u0641\u0646\u0644\u0627\u0646\u062f",
      },
      pol: { official: "Republika Finlandii", common: "Finlandia" },
      por: {
        official: "Rep\u00fablica da Finl\u00e2ndia",
        common: "Finl\u00e2ndia",
      },
      rus: {
        official:
          "\u0424\u0438\u043d\u043b\u044f\u043d\u0434\u0441\u043a\u0430\u044f \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430",
        common: "\u0424\u0438\u043d\u043b\u044f\u043d\u0434\u0438\u044f",
      },
      slk: { official: "F\u00ednska republika", common: "F\u00ednsko" },
      spa: { official: "Rep\u00fablica de Finlandia", common: "Finlandia" },
      swe: { official: "Republiken Finland", common: "Finland" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0641\u0646 \u0644\u06cc\u0646\u0688",
        common: "\u0641\u0646 \u0644\u06cc\u0646\u0688",
      },
      zho: {
        official: "\u82ac\u5170\u5171\u548c\u56fd",
        common: "\u82ac\u5170",
      },
    },
    latlng: [64, 26],
    landlocked: false,
    borders: ["NOR", "SWE", "RUS"],
    area: 338424,
    flag: "\ud83c\uddeb\ud83c\uddee",
    demonyms: {
      eng: { f: "Finnish", m: "Finnish" },
      fra: { f: "Finlandaise", m: "Finlandais" },
    },
    callingCodes: ["+358"],
  },
  {
    name: {
      common: "Fiji",
      official: "Republic of Fiji",
      native: {
        eng: { official: "Republic of Fiji", common: "Fiji" },
        fij: { official: "Matanitu Tugalala o Viti", common: "Viti" },
        hif: {
          official:
            "\u0930\u093f\u092a\u092c\u094d\u0932\u093f\u0915 \u0911\u092b \u092b\u0940\u091c\u0940",
          common: "\u092b\u093f\u091c\u0940",
        },
      },
    },
    tld: [".fj"],
    cca2: "FJ",
    ccn3: "242",
    cca3: "FJI",
    cioc: "FIJ",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { FJD: { name: "Fijian dollar", symbol: "$" } },
    idd: { root: "+6", suffixes: ["79"] },
    capital: ["Suva"],
    altSpellings: [
      "FJ",
      "Viti",
      "Republic of Fiji",
      "Matanitu ko Viti",
      "Fij\u012b Ga\u1e47ar\u0101jya",
    ],
    region: "Oceania",
    subregion: "Melanesia",
    languages: { eng: "English", fij: "Fijian", hif: "Fiji Hindi" },
    translations: {
      ces: {
        official: "Republika Fid\u017eijsk\u00fdch ostrov\u016f",
        common: "Fid\u017ei",
      },
      deu: { official: "Republik Fidschi", common: "Fidschi" },
      est: { official: "Fid\u017ei Vabariik", common: "Fid\u017ei" },
      fin: { official: "Fid\u017ein tasavalta", common: "Fid\u017ei" },
      fra: { official: "R\u00e9publique des Fidji", common: "Fidji" },
      hrv: { official: "Republika Fid\u017ei", common: "Fi\u0111i" },
      hun: {
        official: "Fidzsi-szigeteki K\u00f6zt\u00e1rsas\u00e1g",
        common: "Fidzsi-szigetek",
      },
      ita: { official: "Repubblica di Figi", common: "Figi" },
      jpn: {
        official: "\u30d5\u30a3\u30b8\u30fc\u5171\u548c\u56fd",
        common: "\u30d5\u30a3\u30b8\u30fc",
      },
      kor: {
        official: "\ud53c\uc9c0 \uacf5\ud654\uad6d",
        common: "\ud53c\uc9c0",
      },
      nld: { official: "Republiek Fiji", common: "Fiji" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u062c\u0632\u0627\u06cc\u0631 \u0641\u06cc\u062c\u06cc",
        common: "\u0641\u06cc\u062c\u06cc",
      },
      pol: { official: "Republika Fid\u017ci", common: "Fid\u017ci" },
      por: { official: "Rep\u00fablica de Fiji", common: "Fiji" },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0424\u0438\u0434\u0436\u0438",
        common: "\u0424\u0438\u0434\u0436\u0438",
      },
      slk: { official: "Fi\u01c6ijsk\u00e1 republika", common: "Fi\u01c6i" },
      spa: { official: "Rep\u00fablica de Fiji", common: "Fiyi" },
      swe: { official: "Republiken Fiji", common: "Fiji" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0641\u062c\u06cc",
        common: "\u0641\u062c\u06cc",
      },
      zho: {
        official: "\u6590\u6d4e\u5171\u548c\u56fd",
        common: "\u6590\u6d4e",
      },
    },
    latlng: [-18, 175],
    landlocked: false,
    borders: [],
    area: 18272,
    flag: "\ud83c\uddeb\ud83c\uddef",
    demonyms: {
      eng: { f: "Fijian", m: "Fijian" },
      fra: { f: "Fidjienne", m: "Fidjien" },
    },
    callingCodes: ["+679"],
  },
  {
    name: {
      common: "Falkland Islands",
      official: "Falkland Islands",
      native: {
        eng: { official: "Falkland Islands", common: "Falkland Islands" },
      },
    },
    tld: [".fk"],
    cca2: "FK",
    ccn3: "238",
    cca3: "FLK",
    cioc: "",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: { FKP: { name: "Falkland Islands pound", symbol: "\u00a3" } },
    idd: { root: "+5", suffixes: ["00"] },
    capital: ["Stanley"],
    altSpellings: ["FK", "Islas Malvinas", "Falkland Islands (Malvinas)"],
    region: "Americas",
    subregion: "South America",
    languages: { eng: "English" },
    translations: {
      ces: { official: "Falklandsk\u00e9 ostrovy", common: "Falklandy" },
      deu: { official: "Falklandinseln", common: "Falklandinseln" },
      est: { official: "Falklandi saared", common: "Falklandi saared" },
      fin: { official: "Falkandinsaaret", common: "Falkandinsaaret" },
      fra: { official: "\u00celes Malouines", common: "\u00celes Malouines" },
      hrv: { official: "Falklandski otoci", common: "Falklandski Otoci" },
      hun: { official: "Falkland-szigetek", common: "Falkland-szigetek" },
      ita: {
        official: "Isole Falkland",
        common: "Isole Falkland o Isole Malvine",
      },
      jpn: {
        official: "\u30d5\u30a9\u30fc\u30af\u30e9\u30f3\u30c9",
        common:
          "\u30d5\u30a9\u30fc\u30af\u30e9\u30f3\u30c9\uff08\u30de\u30eb\u30d3\u30ca\u30b9\uff09\u8af8\u5cf6",
      },
      kor: {
        official: "\ud3ec\ud074\ub79c\ub4dc \uc81c\ub3c4",
        common: "\ud3ec\ud074\ub79c\ub4dc \uc81c\ub3c4",
      },
      nld: { official: "Falkland eilanden", common: "Falklandeilanden" },
      per: {
        official:
          "\u062c\u0632\u0627\u06cc\u0631 \u0641\u0627\u0644\u06a9\u0644\u0646\u062f",
        common:
          "\u062c\u0632\u0627\u06cc\u0631 \u0641\u0627\u0644\u06a9\u0644\u0646\u062f",
      },
      pol: { official: "Falklandy", common: "Falklandy" },
      por: { official: "Ilhas Malvinas", common: "Ilhas Malvinas" },
      rus: {
        official:
          "\u0424\u043e\u043b\u043a\u043b\u0435\u043d\u0434\u0441\u043a\u0438\u0435 \u043e\u0441\u0442\u0440\u043e\u0432\u0430",
        common:
          "\u0424\u043e\u043b\u043a\u043b\u0435\u043d\u0434\u0441\u043a\u0438\u0435 \u043e\u0441\u0442\u0440\u043e\u0432\u0430",
      },
      slk: { official: "Falklandsk\u00e9 ostrovy", common: "Falklandy" },
      spa: { official: "islas Malvinas", common: "Islas Malvinas" },
      swe: { official: "Falklands\u00f6arna", common: "Falklands\u00f6arna" },
      urd: {
        official:
          "\u062c\u0632\u0627\u0626\u0631 \u0641\u0627\u06a9\u0644\u06cc\u0646\u0688",
        common:
          "\u062c\u0632\u0627\u0626\u0631 \u0641\u0627\u06a9\u0644\u06cc\u0646\u0688",
      },
      zho: {
        official: "\u798f\u514b\u5170\u7fa4\u5c9b",
        common: "\u798f\u514b\u5170\u7fa4\u5c9b",
      },
    },
    latlng: [-51.75, -59],
    landlocked: false,
    borders: [],
    area: 12173,
    flag: "\ud83c\uddeb\ud83c\uddf0",
    demonyms: {
      eng: { f: "Falkland Islander", m: "Falkland Islander" },
      fra: { f: "Malouinne", m: "Malouin" },
    },
    callingCodes: ["+500"],
  },
  {
    name: {
      common: "France",
      official: "French Republic",
      native: {
        fra: { official: "R\u00e9publique fran\u00e7aise", common: "France" },
      },
    },
    tld: [".fr"],
    cca2: "FR",
    ccn3: "250",
    cca3: "FRA",
    cioc: "FRA",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { EUR: { name: "Euro", symbol: "\u20ac" } },
    idd: { root: "+3", suffixes: ["3"] },
    capital: ["Paris"],
    altSpellings: ["FR", "French Republic", "R\u00e9publique fran\u00e7aise"],
    region: "Europe",
    subregion: "Western Europe",
    languages: { fra: "French" },
    translations: {
      ces: { official: "Francouzsk\u00e1 republika", common: "Francie" },
      deu: { official: "Franz\u00f6sische Republik", common: "Frankreich" },
      est: { official: "Prantsuse Vabariik", common: "Prantsusmaa" },
      fin: { official: "Ranskan tasavalta", common: "Ranska" },
      fra: { official: "R\u00e9publique fran\u00e7aise", common: "France" },
      hrv: { official: "Francuska Republika", common: "Francuska" },
      hun: {
        official: "Francia K\u00f6zt\u00e1rsas\u00e1g",
        common: "Franciaorsz\u00e1g",
      },
      ita: { official: "Repubblica francese", common: "Francia" },
      jpn: {
        official: "\u30d5\u30e9\u30f3\u30b9\u5171\u548c\u56fd",
        common: "\u30d5\u30e9\u30f3\u30b9",
      },
      kor: {
        official: "\ud504\ub791\uc2a4 \uacf5\ud654\uad6d",
        common: "\ud504\ub791\uc2a4",
      },
      nld: { official: "Franse Republiek", common: "Frankrijk" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0641\u0631\u0627\u0646\u0633\u0647",
        common: "\u0641\u0631\u0627\u0646\u0633\u0647",
      },
      pol: { official: "Republika Francuska", common: "Francja" },
      por: { official: "Rep\u00fablica Francesa", common: "Fran\u00e7a" },
      rus: {
        official:
          "\u0424\u0440\u0430\u043d\u0446\u0443\u0437\u0441\u043a\u0430\u044f \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430",
        common: "\u0424\u0440\u0430\u043d\u0446\u0438\u044f",
      },
      slk: { official: "Franc\u00fazska republika", common: "Franc\u00fazsko" },
      spa: { official: "Rep\u00fablica franc\u00e9s", common: "Francia" },
      swe: { official: "Republiken Frankrike", common: "Frankrike" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0641\u0631\u0627\u0646\u0633",
        common: "\u0641\u0631\u0627\u0646\u0633",
      },
      zho: {
        official: "\u6cd5\u5170\u897f\u5171\u548c\u56fd",
        common: "\u6cd5\u56fd",
      },
    },
    latlng: [46, 2],
    landlocked: false,
    borders: ["AND", "BEL", "DEU", "ITA", "LUX", "MCO", "ESP", "CHE"],
    area: 551695,
    flag: "\ud83c\uddeb\ud83c\uddf7",
    demonyms: {
      eng: { f: "French", m: "French" },
      fra: { f: "Fran\u00e7aise", m: "Fran\u00e7ais" },
    },
    callingCodes: ["+33"],
  },
  {
    name: {
      common: "Faroe Islands",
      official: "Faroe Islands",
      native: {
        dan: { official: "F\u00e6r\u00f8erne", common: "F\u00e6r\u00f8erne" },
        fao: { official: "F\u00f8royar", common: "F\u00f8royar" },
      },
    },
    tld: [".fo"],
    cca2: "FO",
    ccn3: "234",
    cca3: "FRO",
    cioc: "",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: {
      DKK: { name: "Danish krone", symbol: "kr" },
      FOK: { name: "Faroese kr\u00f3na", symbol: "kr" },
    },
    idd: { root: "+2", suffixes: ["98"] },
    capital: ["T\u00f3rshavn"],
    altSpellings: ["FO", "F\u00f8royar", "F\u00e6r\u00f8erne"],
    region: "Europe",
    subregion: "Northern Europe",
    languages: { dan: "Danish", fao: "Faroese" },
    translations: {
      ces: { official: "Faersk\u00e9 ostrovy", common: "Faersk\u00e9 ostrovy" },
      deu: { official: "F\u00e4r\u00f6er", common: "F\u00e4r\u00f6er-Inseln" },
      est: {
        official: "F\u00e4\u00e4ri saared",
        common: "F\u00e4\u00e4ri saared",
      },
      fin: { official: "F\u00e4rsaaret", common: "F\u00e4rsaaret" },
      fra: {
        official: "\u00celes F\u00e9ro\u00e9",
        common: "\u00celes F\u00e9ro\u00e9",
      },
      hrv: { official: "Farski Otoci", common: "Farski Otoci" },
      hun: { official: "Fer\u00f6er", common: "Fer\u00f6er" },
      ita: { official: "Isole Faroe", common: "Isole Far Oer" },
      jpn: {
        official: "\u30d5\u30a7\u30ed\u30fc\u8af8\u5cf6",
        common: "\u30d5\u30a7\u30ed\u30fc\u8af8\u5cf6",
      },
      kor: {
        official: "\ud398\ub85c \uc81c\ub3c4",
        common: "\ud398\ub85c \uc81c\ub3c4",
      },
      nld: { official: "Faer\u00f6er", common: "Faer\u00f6er" },
      per: {
        official:
          "\u062c\u0632\u0627\u06cc\u0631 \u0641\u0627\u0631\u0648\u0626\u0647",
        common:
          "\u062c\u0632\u0627\u06cc\u0631 \u0641\u0627\u0631\u0648\u0626\u0647",
      },
      pol: { official: "Wyspy Owcze", common: "Wyspy Owcze" },
      por: { official: "Ilhas Faroe", common: "Ilhas Faro\u00e9" },
      rus: {
        official:
          "\u0424\u0430\u0440\u0435\u0440\u0441\u043a\u0438\u0435 \u043e\u0441\u0442\u0440\u043e\u0432\u0430",
        common:
          "\u0424\u0430\u0440\u0435\u0440\u0441\u043a\u0438\u0435 \u043e\u0441\u0442\u0440\u043e\u0432\u0430",
      },
      slk: { official: "Faersk\u00e9 ostrovy", common: "Faersk\u00e9 ostrovy" },
      spa: { official: "Islas Feroe", common: "Islas Faroe" },
      swe: { official: "F\u00e4r\u00f6arna", common: "F\u00e4r\u00f6arna" },
      urd: {
        official: "\u062c\u0632\u0627\u0626\u0631 \u0641\u0627\u0631\u0648",
        common: "\u062c\u0632\u0627\u0626\u0631 \u0641\u0627\u0631\u0648",
      },
      zho: {
        official: "\u6cd5\u7f57\u7fa4\u5c9b",
        common: "\u6cd5\u7f57\u7fa4\u5c9b",
      },
    },
    latlng: [62, -7],
    landlocked: false,
    borders: [],
    area: 1393,
    flag: "\ud83c\uddeb\ud83c\uddf4",
    demonyms: {
      eng: { f: "Faroese", m: "Faroese" },
      fra: { f: "F\u00e9ro\u00efenne", m: "F\u00e9ro\u00efen" },
    },
    callingCodes: ["+298"],
  },
  {
    name: {
      common: "Micronesia",
      official: "Federated States of Micronesia",
      native: {
        eng: {
          official: "Federated States of Micronesia",
          common: "Micronesia",
        },
      },
    },
    tld: [".fm"],
    cca2: "FM",
    ccn3: "583",
    cca3: "FSM",
    cioc: "FSM",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: [],
    idd: { root: "+6", suffixes: ["91"] },
    capital: ["Palikir"],
    altSpellings: [
      "FM",
      "Federated States of Micronesia",
      "Micronesia, Federated States of",
    ],
    region: "Oceania",
    subregion: "Micronesia",
    languages: { eng: "English" },
    translations: {
      ces: {
        official: "Federativn\u00ed st\u00e1ty Mikron\u00e9sie",
        common: "Mikron\u00e9sie",
      },
      deu: {
        official: "F\u00f6derierte Staaten von Mikronesien",
        common: "Mikronesien",
      },
      est: { official: "Mikroneesia Liiduriigid", common: "Mikroneesia" },
      fin: { official: "Mikronesian liittovaltio", common: "Mikronesia" },
      fra: {
        official: "\u00c9tats f\u00e9d\u00e9r\u00e9s de Micron\u00e9sie",
        common: "Micron\u00e9sie",
      },
      hrv: {
        official: "Savezne Dr\u017eave Mikronezije",
        common: "Mikronezija",
      },
      hun: {
        official: "Mikron\u00e9ziai Sz\u00f6vets\u00e9gi \u00c1llamok",
        common: "Mikron\u00e9ziai Sz\u00f6vets\u00e9gi \u00c1llamok",
      },
      ita: { official: "Stati federati di Micronesia", common: "Micronesia" },
      jpn: {
        official: "\u30df\u30af\u30ed\u30cd\u30b7\u30a2\u9023\u90a6",
        common: "\u30df\u30af\u30ed\u30cd\u30b7\u30a2\u9023\u90a6",
      },
      kor: {
        official: "\ubbf8\ud06c\ub85c\ub124\uc2dc\uc544 \uc5f0\ubc29",
        common: "\ubbf8\ud06c\ub85c\ub124\uc2dc\uc544",
      },
      nld: {
        official: "Federale Staten van Micronesia",
        common: "Micronesi\u00eb",
      },
      per: {
        official:
          "\u0627\u06cc\u0627\u0644\u0627\u062a \u0641\u062f\u0631\u0627\u0644 \u0645\u06cc\u06a9\u0631\u0648\u0646\u0632\u06cc",
        common: "\u0645\u06cc\u06a9\u0631\u0648\u0646\u0632\u06cc",
      },
      pol: { official: "Sfederowane Stany Mikronezji", common: "Mikronezja" },
      por: {
        official: "Estados Federados da Micron\u00e9sia",
        common: "Micron\u00e9sia",
      },
      rus: {
        official:
          "\u0424\u0435\u0434\u0435\u0440\u0430\u0442\u0438\u0432\u043d\u044b\u0435 \u0428\u0442\u0430\u0442\u044b \u041c\u0438\u043a\u0440\u043e\u043d\u0435\u0437\u0438\u0438",
        common:
          "\u0424\u0435\u0434\u0435\u0440\u0430\u0442\u0438\u0432\u043d\u044b\u0435 \u0428\u0442\u0430\u0442\u044b \u041c\u0438\u043a\u0440\u043e\u043d\u0435\u0437\u0438\u0438",
      },
      slk: {
        official: "Mikron\u00e9zske federat\u00edvne \u0161t\u00e1ty",
        common: "Mikron\u00e9zia",
      },
      spa: {
        official: "Estados Federados de Micronesia",
        common: "Micronesia",
      },
      swe: {
        official: "Mikronesiska federationen",
        common: "Mikronesiska federationen",
      },
      urd: {
        official:
          "\u0631\u06cc\u0627\u0633\u062a\u06c1\u0627\u0626\u06d2 \u0648\u0641\u0627\u0642\u06cc\u06c1 \u0645\u0627\u0626\u06a9\u0631\u0648\u0646\u06cc\u0634\u06cc\u0627",
        common:
          "\u0645\u0627\u0626\u06a9\u0631\u0648\u0646\u06cc\u0634\u06cc\u0627",
      },
      zho: {
        official: "\u5bc6\u514b\u7f57\u5c3c\u897f\u4e9a\u8054\u90a6",
        common: "\u5bc6\u514b\u7f57\u5c3c\u897f\u4e9a",
      },
    },
    latlng: [6.91666666, 158.25],
    landlocked: false,
    borders: [],
    area: 702,
    flag: "\ud83c\uddeb\ud83c\uddf2",
    demonyms: {
      eng: { f: "Micronesian", m: "Micronesian" },
      fra: { f: "Micron\u00e9sienne", m: "Micron\u00e9sien" },
    },
    callingCodes: ["+691"],
  },
  {
    name: {
      common: "Gabon",
      official: "Gabonese Republic",
      native: {
        fra: { official: "R\u00e9publique gabonaise", common: "Gabon" },
      },
    },
    tld: [".ga"],
    cca2: "GA",
    ccn3: "266",
    cca3: "GAB",
    cioc: "GAB",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { XAF: { name: "Central African CFA franc", symbol: "Fr" } },
    idd: { root: "+2", suffixes: ["41"] },
    capital: ["Libreville"],
    altSpellings: ["GA", "Gabonese Republic", "R\u00e9publique Gabonaise"],
    region: "Africa",
    subregion: "Middle Africa",
    languages: { fra: "French" },
    translations: {
      ces: { official: "Gabonsk\u00e1 republika", common: "Gabon" },
      deu: { official: "Gabunische Republik", common: "Gabun" },
      est: { official: "Gaboni Vabariik", common: "Gabon" },
      fin: { official: "Gabonin tasavalta", common: "Gabon" },
      fra: { official: "R\u00e9publique gabonaise", common: "Gabon" },
      hrv: { official: "Gabon Republika", common: "Gabon" },
      hun: { official: "Gaboni K\u00f6zt\u00e1rsas\u00e1g", common: "Gabon" },
      ita: { official: "Repubblica gabonese", common: "Gabon" },
      jpn: {
        official: "\u30ac\u30dc\u30f3\u5171\u548c\u56fd",
        common: "\u30ac\u30dc\u30f3",
      },
      kor: {
        official: "\uac00\ubd09 \uacf5\ud654\uad6d",
        common: "\uac00\ubd09",
      },
      nld: { official: "Republiek Gabon", common: "Gabon" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u06af\u0627\u0628\u064f\u0646",
        common: "\u06af\u0627\u0628\u0646",
      },
      pol: { official: "Republika Gabo\u0144ska", common: "Gabon" },
      por: { official: "Rep\u00fablica do Gab\u00e3o", common: "Gab\u00e3o" },
      rus: {
        official:
          "\u0413\u0430\u0431\u043e\u043d\u0430 \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430",
        common: "\u0413\u0430\u0431\u043e\u043d",
      },
      slk: { official: "Gabonsk\u00e1 republika", common: "Gabon" },
      spa: { official: "Rep\u00fablica de Gab\u00f3n", common: "Gab\u00f3n" },
      swe: { official: "Republiken Gabon", common: "Gabon" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u06af\u06cc\u0628\u0648\u0646",
        common: "\u06af\u06cc\u0628\u0648\u0646",
      },
      zho: {
        official: "\u52a0\u84ec\u5171\u548c\u56fd",
        common: "\u52a0\u84ec",
      },
    },
    latlng: [-1, 11.75],
    landlocked: false,
    borders: ["CMR", "COG", "GNQ"],
    area: 267668,
    flag: "\ud83c\uddec\ud83c\udde6",
    demonyms: {
      eng: { f: "Gabonese", m: "Gabonese" },
      fra: { f: "Gabonaise", m: "Gabonais" },
    },
    callingCodes: ["+241"],
  },
  {
    name: {
      common: "United Kingdom",
      official: "United Kingdom of Great Britain and Northern Ireland",
      native: {
        eng: {
          official: "United Kingdom of Great Britain and Northern Ireland",
          common: "United Kingdom",
        },
      },
    },
    tld: [".uk"],
    cca2: "GB",
    ccn3: "826",
    cca3: "GBR",
    cioc: "GBR",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { GBP: { name: "British pound", symbol: "\u00a3" } },
    idd: { root: "+4", suffixes: ["4"] },
    capital: ["London"],
    altSpellings: ["GB", "UK", "Great Britain"],
    region: "Europe",
    subregion: "Northern Europe",
    languages: { eng: "English" },
    translations: {
      ces: {
        official:
          "Spojen\u00e9 kr\u00e1lovstv\u00ed Velk\u00e9 Brit\u00e1nie a Severn\u00edho Irska",
        common: "Spojen\u00e9 kr\u00e1lovstv\u00ed",
      },
      deu: {
        official:
          "Vereinigtes K\u00f6nigreich Gro\u00dfbritannien und Nordirland",
        common: "Vereinigtes K\u00f6nigreich",
      },
      est: {
        official: "Suurbritannia ja P\u00f5hja-Iiri \u00dchendkuningriik",
        common: "Suurbritannia",
      },
      fin: {
        official:
          "Ison-Britannian ja Pohjois-Irlannin yhdistynyt kuningaskunta",
        common: "Yhdistynyt kuningaskunta",
      },
      fra: {
        official: "Royaume-Uni de Grande-Bretagne et d'Irlande du Nord",
        common: "Royaume-Uni",
      },
      hrv: {
        official: "Ujedinjeno Kraljevstvo Velike Britanije i Sjeverne Irske",
        common: "Ujedinjeno Kraljevstvo",
      },
      hun: {
        official:
          "Nagy-Britannia \u00e9s \u00c9szak-\u00cdrorsz\u00e1g Egyes\u00fclt Kir\u00e1lys\u00e1ga",
        common: "Egyes\u00fclt Kir\u00e1lys\u00e1g",
      },
      ita: {
        official: "Regno Unito di Gran Bretagna e Irlanda del Nord",
        common: "Regno Unito",
      },
      jpn: {
        official:
          "\u30b0\u30ec\u30fc\u30c8\u00b7\u30d6\u30ea\u30c6\u30f3\u304a\u3088\u3073\u5317\u30a2\u30a4\u30eb\u30e9\u30f3\u30c9\u9023\u5408\u738b\u56fd",
        common: "\u30a4\u30ae\u30ea\u30b9",
      },
      kor: {
        official:
          "\uadf8\ub808\uc774\ud2b8\ube0c\ub9ac\ud2bc \ubd81\uc544\uc77c\ub79c\ub4dc \uc5f0\ud569 \uc655\uad6d",
        common: "\uc601\uad6d",
      },
      nld: {
        official:
          "Verenigd Koninkrijk van Groot-Brittanni\u00eb en Noord-Ierland",
        common: "Verenigd Koninkrijk",
      },
      per: {
        official:
          "\u067e\u0627\u062f\u0634\u0627\u0647\u06cc \u0645\u062a\u062d\u062f \u0628\u0631\u06cc\u062a\u0627\u0646\u06cc\u0627\u06cc \u06a9\u0628\u06cc\u0631 \u0648 \u0627\u06cc\u0631\u0644\u0646\u062f \u0634\u0645\u0627\u0644\u06cc",
        common: "\u0627\u0646\u06af\u0644\u06cc\u0633",
      },
      pol: {
        official:
          "Zjednoczone Kr\u00f3lestwo Wielkiej Brytanii i Irlandii P\u00f3\u0142nocnej",
        common: "Zjednoczone Kr\u0142lestwo",
      },
      por: {
        official: "Reino Unido da Gr\u00e3-Bretanha e Irlanda do Norte",
        common: "Reino Unido",
      },
      rus: {
        official:
          "\u0421\u043e\u0435\u0434\u0438\u043d\u0435\u043d\u043d\u043e\u0435 \u041a\u043e\u0440\u043e\u043b\u0435\u0432\u0441\u0442\u0432\u043e \u0412\u0435\u043b\u0438\u043a\u043e\u0431\u0440\u0438\u0442\u0430\u043d\u0438\u0438 \u0438 \u0421\u0435\u0432\u0435\u0440\u043d\u043e\u0439 \u0418\u0440\u043b\u0430\u043d\u0434\u0438\u0438",
        common:
          "\u0412\u0435\u043b\u0438\u043a\u043e\u0431\u0440\u0438\u0442\u0430\u043d\u0438\u044f",
      },
      slk: {
        official:
          "Spojen\u00e9 kr\u00e1\u013eovstvo Ve\u013ekej Brit\u00e1nie a Severn\u00e9ho\u00ccrska",
        common:
          "Ve\u013ek\u00e1 Brit\u00e1nia (Spojen\u00e9 kr\u00e1\u013eovstvo)",
      },
      spa: {
        official: "Reino Unido de Gran Breta\u00f1a e Irlanda del Norte",
        common: "Reino Unido",
      },
      swe: {
        official: "F\u00f6renade konungariket Storbritannien och Nordirland",
        common: "Storbritannien",
      },
      urd: {
        official:
          "\u0645\u0645\u0644\u06a9\u062a\u0650 \u0645\u062a\u062d\u062f\u06c1 \u0628\u0631\u0637\u0627\u0646\u06cc\u06c1 \u0639\u0638\u0645\u06cc \u0648 \u0634\u0645\u0627\u0644\u06cc \u0622\u0626\u0631\u0644\u06cc\u0646\u0688",
        common:
          "\u0645\u0645\u0644\u06a9\u062a\u0650 \u0645\u062a\u062d\u062f\u06c1",
      },
      zho: {
        official:
          "\u5927\u4e0d\u5217\u98a0\u53ca\u5317\u7231\u5c14\u5170\u8054\u5408\u738b\u56fd",
        common: "\u82f1\u56fd",
      },
    },
    latlng: [54, -2],
    landlocked: false,
    borders: ["IRL"],
    area: 242900,
    flag: "\ud83c\uddec\ud83c\udde7",
    demonyms: {
      eng: { f: "British", m: "British" },
      fra: { f: "Britannique", m: "Britannique" },
    },
    callingCodes: ["+44"],
  },
  {
    name: {
      common: "Georgia",
      official: "Georgia",
      native: {
        kat: {
          official:
            "\u10e1\u10d0\u10e5\u10d0\u10e0\u10d7\u10d5\u10d4\u10da\u10dd",
          common:
            "\u10e1\u10d0\u10e5\u10d0\u10e0\u10d7\u10d5\u10d4\u10da\u10dd",
        },
      },
    },
    tld: [".ge"],
    cca2: "GE",
    ccn3: "268",
    cca3: "GEO",
    cioc: "GEO",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { GEL: { name: "lari", symbol: "\u20be" } },
    idd: { root: "+9", suffixes: ["95"] },
    capital: ["Tbilisi"],
    altSpellings: ["GE", "Sakartvelo"],
    region: "Asia",
    subregion: "Western Asia",
    languages: { kat: "Georgian" },
    translations: {
      ces: { official: "Gruzie", common: "Gruzie" },
      deu: { official: "Georgien", common: "Georgien" },
      est: { official: "Gruusia", common: "Gruusia" },
      fin: { official: "Georgia", common: "Georgia" },
      fra: {
        official: "R\u00e9publique de G\u00e9orgie",
        common: "G\u00e9orgie",
      },
      hrv: { official: "Gruzija", common: "Gruzija" },
      hun: { official: "Gr\u00fazia", common: "Gr\u00fazia" },
      ita: { official: "Georgia", common: "Georgia" },
      jpn: {
        official: "\u30b0\u30eb\u30b8\u30a2",
        common: "\u30b0\u30eb\u30b8\u30a2",
      },
      kor: { official: "\uc870\uc9c0\uc544", common: "\uc870\uc9c0\uc544" },
      nld: { official: "Georgia", common: "Georgi\u00eb" },
      per: {
        official: "\u06af\u0631\u062c\u0633\u062a\u0627\u0646",
        common: "\u06af\u0631\u062c\u0633\u062a\u0627\u0646",
      },
      pol: { official: "Gruzja", common: "Gruzja" },
      por: { official: "Georgia", common: "Ge\u00f3rgia" },
      rus: {
        official: "\u0413\u0440\u0443\u0437\u0438\u044f",
        common: "\u0413\u0440\u0443\u0437\u0438\u044f",
      },
      slk: { official: "Gruz\u00ednsko", common: "Gruz\u00ednsko" },
      spa: { official: "Georgia", common: "Georgia" },
      swe: { official: "Georgien", common: "Georgien" },
      urd: {
        official: "\u062c\u0627\u0631\u062c\u06cc\u0627",
        common: "\u062c\u0627\u0631\u062c\u06cc\u0627",
      },
      zho: {
        official: "\u683c\u9c81\u5409\u4e9a",
        common: "\u683c\u9c81\u5409\u4e9a",
      },
    },
    latlng: [42, 43.5],
    landlocked: false,
    borders: ["ARM", "AZE", "RUS", "TUR"],
    area: 69700,
    flag: "\ud83c\uddec\ud83c\uddea",
    demonyms: {
      eng: { f: "Georgian", m: "Georgian" },
      fra: { f: "G\u00e9orgienne", m: "G\u00e9orgien" },
    },
    callingCodes: ["+995"],
  },
  {
    name: {
      common: "Guernsey",
      official: "Bailiwick of Guernsey",
      native: {
        eng: { official: "Bailiwick of Guernsey", common: "Guernsey" },
        fra: { official: "Bailliage de Guernesey", common: "Guernesey" },
        nfr: {
          official: "Dg\u00e8rn\u00e9siais",
          common: "Dg\u00e8rn\u00e9siais",
        },
      },
    },
    tld: [".gg"],
    cca2: "GG",
    ccn3: "831",
    cca3: "GGY",
    cioc: "",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: {
      GBP: { name: "British pound", symbol: "\u00a3" },
      GGP: { name: "Guernsey pound", symbol: "\u00a3" },
    },
    idd: { root: "+4", suffixes: ["4"] },
    capital: ["St. Peter Port"],
    altSpellings: ["GG", "Bailiwick of Guernsey", "Bailliage de Guernesey"],
    region: "Europe",
    subregion: "Northern Europe",
    languages: { eng: "English", fra: "French", nfr: "Guern\u00e9siais" },
    translations: {
      ces: {
        official: "Rycht\u00e1\u0159stv\u00ed Guernsey",
        common: "Guernsey",
      },
      deu: { official: "Vogtei Guernsey", common: "Guernsey" },
      est: { official: "Guernsey foogtkond", common: "Guernsey" },
      fin: { official: "Guernsey", common: "Guernsey" },
      fra: { official: "Bailliage de Guernesey", common: "Guernesey" },
      hrv: { official: "Struka Guernsey", common: "Guernsey" },
      hun: { official: "Guernsey", common: "Guernsey" },
      ita: { official: "Baliato di Guernsey", common: "Guernsey" },
      jpn: {
        official:
          "\u30ac\u30fc\u30f3\u30b8\u30fc\u306e\u5f97\u610f\u5206\u91ce",
        common: "\u30ac\u30fc\u30f3\u30b8\u30fc",
      },
      kor: { official: "\uac74\uc9c0 \uc12c", common: "\uac74\uc9c0 \uc12c" },
      nld: { official: "Baljuwschap Guernsey", common: "Guernsey" },
      per: {
        official: "\u06af\u0631\u0646\u0632\u06cc",
        common: "\u06af\u0631\u0646\u0632\u06cc",
      },
      pol: { official: "Baliwat Guernsey", common: "Guernsey" },
      por: { official: "Bailiado de Guernsey", common: "Guernsey" },
      rus: {
        official:
          "\u041a\u043e\u0440\u043e\u043d\u043d\u043e\u0435 \u0432\u043b\u0430\u0434\u0435\u043d\u0438\u0435 \u0413\u0435\u0440\u043d\u0441\u0438",
        common: "\u0413\u0435\u0440\u043d\u0441\u0438",
      },
      slk: { official: "Guernsey", common: "Guernsey" },
      spa: { official: "Bail\u00eda de Guernsey", common: "Guernsey" },
      swe: { official: "Guernsey", common: "Guernsey" },
      urd: {
        official:
          "\u06af\u0631\u0646\u0632\u06cc \u0631\u0648\u062f\u0628\u0627\u0631",
        common: "\u06af\u0631\u0646\u0632\u06cc",
      },
      zho: { official: "\u6839\u897f\u5c9b", common: "\u6839\u897f\u5c9b" },
    },
    latlng: [49.46666666, -2.58333333],
    landlocked: false,
    borders: [],
    area: 78,
    flag: "\ud83c\uddec\ud83c\uddec",
    demonyms: {
      eng: { f: "Channel Islander", m: "Channel Islander" },
      fra: { f: "Guernesiaise", m: "Guernesiais" },
    },
    callingCodes: ["+44"],
  },
  {
    name: {
      common: "Ghana",
      official: "Republic of Ghana",
      native: { eng: { official: "Republic of Ghana", common: "Ghana" } },
    },
    tld: [".gh"],
    cca2: "GH",
    ccn3: "288",
    cca3: "GHA",
    cioc: "GHA",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { GHS: { name: "Ghanaian cedi", symbol: "\u20b5" } },
    idd: { root: "+2", suffixes: ["33"] },
    capital: ["Accra"],
    altSpellings: ["GH"],
    region: "Africa",
    subregion: "Western Africa",
    languages: { eng: "English" },
    translations: {
      ces: { official: "Ghansk\u00e1 republika", common: "Ghana" },
      deu: { official: "Republik Ghana", common: "Ghana" },
      est: { official: "Ghana Vabariik", common: "Ghana" },
      fin: { official: "Ghanan tasavalta", common: "Ghana" },
      fra: { official: "R\u00e9publique du Ghana", common: "Ghana" },
      hrv: { official: "Republika Gana", common: "Gana" },
      hun: {
        official: "Gh\u00e1nai K\u00f6zt\u00e1rsas\u00e1g",
        common: "Gh\u00e1na",
      },
      ita: { official: "Repubblica del Ghana", common: "Ghana" },
      jpn: {
        official: "\u30ac\u30fc\u30ca\u5171\u548c\u56fd",
        common: "\u30ac\u30fc\u30ca",
      },
      kor: {
        official: "\uac00\ub098 \uacf5\ud654\uad6d",
        common: "\uac00\ub098",
      },
      nld: { official: "Republiek Ghana", common: "Ghana" },
      per: {
        official: "\u062c\u0645\u0647\u0648\u0631\u06cc \u063a\u0646\u0627",
        common: "\u063a\u0646\u0627",
      },
      pol: { official: "Republika Ghany", common: "Ghana" },
      por: { official: "Rep\u00fablica do Gana", common: "Gana" },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0413\u0430\u043d\u0430",
        common: "\u0413\u0430\u043d\u0430",
      },
      slk: { official: "Ghansk\u00e1 republika", common: "Ghana" },
      spa: { official: "Rep\u00fablica de Ghana", common: "Ghana" },
      swe: { official: "Republiken Ghana", common: "Ghana" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u06af\u06be\u0627\u0646\u0627",
        common: "\u06af\u06be\u0627\u0646\u0627",
      },
      zho: {
        official: "\u52a0\u7eb3\u5171\u548c\u56fd",
        common: "\u52a0\u7eb3",
      },
    },
    latlng: [8, -2],
    landlocked: false,
    borders: ["BFA", "CIV", "TGO"],
    area: 238533,
    flag: "\ud83c\uddec\ud83c\udded",
    demonyms: {
      eng: { f: "Ghanaian", m: "Ghanaian" },
      fra: { f: "Ghan\u00e9enne", m: "Ghan\u00e9en" },
    },
    callingCodes: ["+233"],
  },
  {
    name: {
      common: "Gibraltar",
      official: "Gibraltar",
      native: { eng: { official: "Gibraltar", common: "Gibraltar" } },
    },
    tld: [".gi"],
    cca2: "GI",
    ccn3: "292",
    cca3: "GIB",
    cioc: "",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: { GIP: { name: "Gibraltar pound", symbol: "\u00a3" } },
    idd: { root: "+3", suffixes: ["50"] },
    capital: ["Gibraltar"],
    altSpellings: ["GI"],
    region: "Europe",
    subregion: "Southern Europe",
    languages: { eng: "English" },
    translations: {
      ces: { official: "Gibraltar", common: "Gibraltar" },
      deu: { official: "Gibraltar", common: "Gibraltar" },
      est: { official: "Gibraltar", common: "Gibraltar" },
      fin: { official: "Gibraltar", common: "Gibraltar" },
      fra: { official: "Gibraltar", common: "Gibraltar" },
      hrv: { official: "Gibraltar", common: "Gibraltar" },
      hun: { official: "Gibralt\u00e1r", common: "Gibralt\u00e1r" },
      ita: { official: "Gibilterra", common: "Gibilterra" },
      jpn: {
        official: "\u30b8\u30d6\u30e9\u30eb\u30bf\u30eb",
        common: "\u30b8\u30d6\u30e9\u30eb\u30bf\u30eb",
      },
      kor: {
        official: "\uc9c0\ube0c\ub864\ud130",
        common: "\uc9c0\ube0c\ub864\ud130",
      },
      nld: { official: "Gibraltar", common: "Gibraltar" },
      per: {
        official: "\u062c\u0628\u0644 \u0637\u0627\u0631\u0642",
        common: "\u062c\u0628\u0644 \u0637\u0627\u0631\u0642",
      },
      pol: { official: "Gibraltar", common: "Gibraltar" },
      por: { official: "Gibraltar", common: "Gibraltar" },
      rus: {
        official: "\u0413\u0438\u0431\u0440\u0430\u043b\u0442\u0430\u0440",
        common: "\u0413\u0438\u0431\u0440\u0430\u043b\u0442\u0430\u0440",
      },
      slk: { official: "Gibralt\u00e1r", common: "Gibralt\u00e1r" },
      spa: { official: "Gibraltar", common: "Gibraltar" },
      swe: { official: "Gibraltar", common: "Gibraltar" },
      urd: {
        official: "\u062c\u0628\u0644 \u0627\u0644\u0637\u0627\u0631\u0642",
        common: "\u062c\u0628\u0644 \u0627\u0644\u0637\u0627\u0631\u0642",
      },
      zho: {
        official: "\u76f4\u5e03\u7f57\u9640",
        common: "\u76f4\u5e03\u7f57\u9640",
      },
    },
    latlng: [36.13333333, -5.35],
    landlocked: false,
    borders: ["ESP"],
    area: 6,
    flag: "\ud83c\uddec\ud83c\uddee",
    demonyms: {
      eng: { f: "Gibraltar", m: "Gibraltar" },
      fra: { f: "Gibraltarienne", m: "Gibraltarien" },
    },
    callingCodes: ["+350"],
  },
  {
    name: {
      common: "Guinea",
      official: "Republic of Guinea",
      native: {
        fra: {
          official: "R\u00e9publique de Guin\u00e9e",
          common: "Guin\u00e9e",
        },
      },
    },
    tld: [".gn"],
    cca2: "GN",
    ccn3: "324",
    cca3: "GIN",
    cioc: "GUI",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { GNF: { name: "Guinean franc", symbol: "Fr" } },
    idd: { root: "+2", suffixes: ["24"] },
    capital: ["Conakry"],
    altSpellings: [
      "GN",
      "Republic of Guinea",
      "R\u00e9publique de Guin\u00e9e",
    ],
    region: "Africa",
    subregion: "Western Africa",
    languages: { fra: "French" },
    translations: {
      ces: { official: "Guinejsk\u00e1 republika", common: "Guinea" },
      deu: { official: "Republik Guinea", common: "Guinea" },
      est: { official: "Guinea Vabariik", common: "Guinea" },
      fin: { official: "Guinean tasavalta", common: "Guinea" },
      fra: {
        official: "R\u00e9publique de Guin\u00e9e",
        common: "Guin\u00e9e",
      },
      hrv: { official: "Republika Gvineja", common: "Gvineja" },
      hun: { official: "Guineai K\u00f6zt\u00e1rsas\u00e1g", common: "Guinea" },
      ita: { official: "Repubblica di Guinea", common: "Guinea" },
      jpn: {
        official: "\u30ae\u30cb\u30a2\u5171\u548c\u56fd",
        common: "\u30ae\u30cb\u30a2",
      },
      kor: {
        official: "\uae30\ub2c8 \uacf5\ud654\uad6d",
        common: "\uae30\ub2c8",
      },
      nld: { official: "Republiek Guinee", common: "Guinee" },
      per: {
        official:
          "\u0645\u0645\u0644\u06a9\u062a \u0645\u0633\u062a\u0642\u0644 \u067e\u0627\u067e\u0648\u0622 \u06af\u06cc\u0646\u0647 \u0646\u0648",
        common:
          "\u067e\u0627\u067e\u0648\u0622 \u06af\u06cc\u0646\u0647 \u0646\u0648",
      },
      pol: { official: "Republika Gwinei", common: "Gwinea" },
      por: { official: "Rep\u00fablica da Guin\u00e9", common: "Guin\u00e9" },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0413\u0432\u0438\u043d\u0435\u044f",
        common: "\u0413\u0432\u0438\u043d\u0435\u044f",
      },
      slk: { official: "Guinejsk\u00e1 republika", common: "Guinea" },
      spa: { official: "Rep\u00fablica de Guinea", common: "Guinea" },
      swe: { official: "Republiken Guinea", common: "Guinea" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u06af\u0646\u06cc",
        common: "\u06af\u0646\u06cc",
      },
      zho: {
        official: "\u51e0\u5185\u4e9a\u5171\u548c\u56fd",
        common: "\u51e0\u5185\u4e9a",
      },
    },
    latlng: [11, -10],
    landlocked: false,
    borders: ["CIV", "GNB", "LBR", "MLI", "SEN", "SLE"],
    area: 245857,
    flag: "\ud83c\uddec\ud83c\uddf3",
    demonyms: {
      eng: { f: "Guinean", m: "Guinean" },
      fra: { f: "Guin\u00e9enne", m: "Guin\u00e9en" },
    },
    callingCodes: ["+224"],
  },
  {
    name: {
      common: "Guadeloupe",
      official: "Guadeloupe",
      native: { fra: { official: "Guadeloupe", common: "Guadeloupe" } },
    },
    tld: [".gp"],
    cca2: "GP",
    ccn3: "312",
    cca3: "GLP",
    cioc: "",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: { EUR: { name: "Euro", symbol: "\u20ac" } },
    idd: { root: "+5", suffixes: ["90"] },
    capital: ["Basse-Terre"],
    altSpellings: ["GP", "Gwadloup"],
    region: "Americas",
    subregion: "Caribbean",
    languages: { fra: "French" },
    translations: {
      ces: { official: "Guadeloupe", common: "Guadeloupe" },
      deu: { official: "Guadeloupe", common: "Guadeloupe" },
      est: {
        official: "Guadeloupe\u2019i ja s\u00f5ltkondade departemang",
        common: "Guadeloupe",
      },
      fin: { official: "Guadeloupen departmentti", common: "Guadeloupe" },
      fra: { official: "Guadeloupe", common: "Guadeloupe" },
      hrv: { official: "Gvadalupa", common: "Gvadalupa" },
      hun: { official: "Guadeloupe", common: "Guadeloupe" },
      ita: { official: "Guadeloupe", common: "Guadeloupa" },
      jpn: {
        official: "\u30b0\u30a2\u30c9\u30eb\u30fc\u30d7\u5cf6",
        common: "\u30b0\u30a2\u30c9\u30eb\u30fc\u30d7",
      },
      kor: {
        official: "\uacfc\ub4e4\ub8e8\ud504",
        common: "\uacfc\ub4e4\ub8e8\ud504",
      },
      nld: { official: "Guadeloupe", common: "Guadeloupe" },
      per: {
        official: "\u06af\u0648\u0627\u062f\u0644\u0648\u067e",
        common: "\u06af\u0648\u0627\u062f\u0644\u0648\u067e",
      },
      pol: { official: "Gwadelupa", common: "Gwadelupa" },
      por: { official: "Guadalupe", common: "Guadalupe" },
      rus: {
        official: "\u0413\u0432\u0430\u0434\u0435\u043b\u0443\u043f\u0430",
        common: "\u0413\u0432\u0430\u0434\u0435\u043b\u0443\u043f\u0430",
      },
      slk: { official: "Guadeloupe", common: "Guadeloupe" },
      spa: { official: "Guadalupe", common: "Guadalupe" },
      swe: { official: "Guadeloupe", common: "Guadeloupe" },
      urd: {
        official: "\u06af\u0648\u0627\u0688\u06cc\u0644\u0648\u067e",
        common: "\u06af\u0648\u0627\u0688\u06cc\u0644\u0648\u067e",
      },
      zho: {
        official: "\u74dc\u5fb7\u7f57\u666e\u5c9b",
        common: "\u74dc\u5fb7\u7f57\u666e\u5c9b",
      },
    },
    latlng: [16.25, -61.583333],
    landlocked: false,
    borders: [],
    area: 1628,
    flag: "\ud83c\uddec\ud83c\uddf5",
    demonyms: {
      eng: { f: "Guadeloupian", m: "Guadeloupian" },
      fra: { f: "Guadeloup\u00e9enne", m: "Guadeloup\u00e9en" },
    },
    callingCodes: ["+590"],
  },
  {
    name: {
      common: "Gambia",
      official: "Republic of the Gambia",
      native: { eng: { official: "Republic of the Gambia", common: "Gambia" } },
    },
    tld: [".gm"],
    cca2: "GM",
    ccn3: "270",
    cca3: "GMB",
    cioc: "GAM",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { GMD: { name: "dalasi", symbol: "D" } },
    idd: { root: "+2", suffixes: ["20"] },
    capital: ["Banjul"],
    altSpellings: ["GM", "Republic of the Gambia"],
    region: "Africa",
    subregion: "Western Africa",
    languages: { eng: "English" },
    translations: {
      ces: { official: "Gambijsk\u00e1 republika", common: "Gambie" },
      deu: { official: "Republik Gambia", common: "Gambia" },
      est: { official: "Gambia Vabariik", common: "Gambia" },
      fin: { official: "Gambian tasavalta", common: "Gambia" },
      fra: { official: "R\u00e9publique de Gambie", common: "Gambie" },
      hrv: { official: "Republika Gambija", common: "Gambija" },
      hun: { official: "Gambiai K\u00f6zt\u00e1rsas\u00e1g", common: "Gambia" },
      ita: { official: "Repubblica del Gambia", common: "Gambia" },
      jpn: {
        official: "\u30ac\u30f3\u30d3\u30a2\u5171\u548c\u56fd",
        common: "\u30ac\u30f3\u30d3\u30a2",
      },
      kor: {
        official: "\uac10\ube44\uc544 \uacf5\ud654\uad6d",
        common: "\uac10\ube44\uc544",
      },
      nld: { official: "Republiek Gambia", common: "Gambia" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u06af\u0627\u0645\u0628\u06cc\u0627",
        common: "\u06af\u0627\u0645\u0628\u06cc\u0627",
      },
      pol: { official: "Republika Gambii", common: "Gambia" },
      por: { official: "Rep\u00fablica da G\u00e2mbia", common: "G\u00e2mbia" },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0413\u0430\u043c\u0431\u0438\u044f",
        common: "\u0413\u0430\u043c\u0431\u0438\u044f",
      },
      slk: { official: "Gambijsk\u00e1 republika", common: "Gambia" },
      spa: { official: "Rep\u00fablica de Gambia", common: "Gambia" },
      swe: { official: "Republiken Gambia", common: "Gambia" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u06af\u06cc\u0645\u0628\u06cc\u0627",
        common: "\u06af\u06cc\u0645\u0628\u06cc\u0627",
      },
      zho: {
        official: "\u5188\u6bd4\u4e9a\u5171\u548c\u56fd",
        common: "\u5188\u6bd4\u4e9a",
      },
    },
    latlng: [13.46666666, -16.56666666],
    landlocked: false,
    borders: ["SEN"],
    area: 10689,
    flag: "\ud83c\uddec\ud83c\uddf2",
    demonyms: {
      eng: { f: "Gambian", m: "Gambian" },
      fra: { f: "Gambienne", m: "Gambien" },
    },
    callingCodes: ["+220"],
  },
  {
    name: {
      common: "Guinea-Bissau",
      official: "Republic of Guinea-Bissau",
      native: {
        por: {
          official: "Rep\u00fablica da Guin\u00e9-Bissau",
          common: "Guin\u00e9-Bissau",
        },
        pov: {
          official: "Rep\u00fablica da Guin\u00e9-Bissau",
          common: "Guin\u00e9-Bissau",
        },
      },
    },
    tld: [".gw"],
    cca2: "GW",
    ccn3: "624",
    cca3: "GNB",
    cioc: "GBS",
    independent: true,
    status: "officially-assigned",
    unMember: false,
    currencies: { XOF: { name: "West African CFA franc", symbol: "Fr" } },
    idd: { root: "+2", suffixes: ["45"] },
    capital: ["Bissau"],
    altSpellings: [
      "GW",
      "Republic of Guinea-Bissau",
      "Rep\u00fablica da Guin\u00e9-Bissau",
    ],
    region: "Africa",
    subregion: "Western Africa",
    languages: { por: "Portuguese", pov: "Upper Guinea Creole" },
    translations: {
      ces: { official: "Republika Guinea-Bissau", common: "Guinea-Bissau" },
      deu: { official: "Republik Guinea-Bissau", common: "Guinea-Bissau" },
      est: { official: "Guinea-Bissau Vabariik", common: "Guinea-Bissau" },
      fin: { official: "Guinea-Bissaun tasavalta", common: "Guinea-Bissau" },
      fra: {
        official: "R\u00e9publique de Guin\u00e9e-Bissau",
        common: "Guin\u00e9e-Bissau",
      },
      hrv: { official: "Republika Gvineja Bisau", common: "Gvineja Bisau" },
      hun: {
        official: "Bissau-Guineai K\u00f6zt\u00e1rsas\u00e1g",
        common: "Bissau-Guinea",
      },
      ita: { official: "Repubblica di Guinea-Bissau", common: "Guinea-Bissau" },
      jpn: {
        official: "\u30ae\u30cb\u30a2\u30d3\u30b5\u30a6\u5171\u548c\u56fd",
        common: "\u30ae\u30cb\u30a2\u30d3\u30b5\u30a6",
      },
      kor: {
        official: "\uae30\ub2c8\ube44\uc0ac\uc6b0 \uacf5\ud654\uad6d",
        common: "\uae30\ub2c8\ube44\uc0ac\uc6b0",
      },
      nld: { official: "Republiek Guinee-Bissau", common: "Guinee-Bissau" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u06af\u06cc\u0646\u0647 \u0628\u06cc\u0633\u0627\u0626\u0648",
        common: "\u06af\u06cc\u0646\u0647 \u0628\u06cc\u0633\u0627\u0626\u0648",
      },
      pol: { official: "Republika Gwinei Bissau", common: "Gwinea Bissau" },
      por: {
        official: "Rep\u00fablica da Guin\u00e9-Bissau",
        common: "Guin\u00e9-Bissau",
      },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0413\u0432\u0438\u043d\u0435\u044f -\u0411\u0438\u0441\u0430\u0443",
        common:
          "\u0413\u0432\u0438\u043d\u0435\u044f-\u0411\u0438\u0441\u0430\u0443",
      },
      slk: {
        official: "Guinejsko-bissausk\u00e1 republika",
        common: "Guinea-Bissau",
      },
      spa: {
        official: "Rep\u00fablica de Guinea-Bissau",
        common: "Guinea-Bis\u00e1u",
      },
      swe: { official: "Republiken Guinea-Bissau", common: "Guinea-Bissau" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u06af\u0646\u06cc \u0628\u0633\u0627\u0624",
        common: "\u06af\u0646\u06cc \u0628\u0633\u0627\u0624",
      },
      zho: {
        official: "\u51e0\u5185\u4e9a\u6bd4\u7ecd\u5171\u548c\u56fd",
        common: "\u51e0\u5185\u4e9a\u6bd4\u7ecd",
      },
    },
    latlng: [12, -15],
    landlocked: false,
    borders: ["GIN", "SEN"],
    area: 36125,
    flag: "\ud83c\uddec\ud83c\uddfc",
    demonyms: {
      eng: { f: "Guinea-Bissauan", m: "Guinea-Bissauan" },
      fra: { f: "Bissau-Guin\u00e9enne", m: "Bissau-Guin\u00e9en" },
    },
    callingCodes: ["+245"],
  },
  {
    name: {
      common: "Equatorial Guinea",
      official: "Republic of Equatorial Guinea",
      native: {
        fra: {
          official: "R\u00e9publique de la Guin\u00e9e \u00c9quatoriale",
          common: "Guin\u00e9e \u00e9quatoriale",
        },
        por: {
          official: "Rep\u00fablica da Guin\u00e9 Equatorial",
          common: "Guin\u00e9 Equatorial",
        },
        spa: {
          official: "Rep\u00fablica de Guinea Ecuatorial",
          common: "Guinea Ecuatorial",
        },
      },
    },
    tld: [".gq"],
    cca2: "GQ",
    ccn3: "226",
    cca3: "GNQ",
    cioc: "GEQ",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { XAF: { name: "Central African CFA franc", symbol: "Fr" } },
    idd: { root: "+2", suffixes: ["40"] },
    capital: ["Malabo"],
    altSpellings: [
      "GQ",
      "Republic of Equatorial Guinea",
      "Rep\u00fablica de Guinea Ecuatorial",
      "R\u00e9publique de Guin\u00e9e \u00e9quatoriale",
      "Rep\u00fablica da Guin\u00e9 Equatorial",
    ],
    region: "Africa",
    subregion: "Middle Africa",
    languages: { fra: "French", por: "Portuguese", spa: "Spanish" },
    translations: {
      ces: {
        official: "Republika Rovn\u00edkov\u00e1 Guinea",
        common: "Rovn\u00edkov\u00e1 Guinea",
      },
      cym: {
        official: "Gweriniaeth Gini Gyhydeddol",
        common: "Gini Gyhydeddol",
      },
      deu: {
        official: "Republik \u00c4quatorialguinea",
        common: "\u00c4quatorialguinea",
      },
      est: {
        official: "Ekvatoriaal-Guinea Vabariik",
        common: "Ekvatoriaal-Guinea",
      },
      fin: {
        official: "P\u00e4iv\u00e4ntasaajan Guinean tasavalta",
        common: "P\u00e4iv\u00e4ntasaajan Guinea",
      },
      fra: {
        official: "R\u00e9publique de Guin\u00e9e \u00e9quatoriale",
        common: "Guin\u00e9e \u00e9quatoriale",
      },
      hrv: {
        official: "Republika Ekvatorska Gvineja",
        common: "Ekvatorijalna Gvineja",
      },
      hun: {
        official: "Egyenl\u00edt\u0151i-Guinea-i K\u00f6zt\u00e1rsas\u00e1g",
        common: "Egyenl\u00edt\u0151i-Guinea",
      },
      ita: {
        official: "Repubblica della Guinea Equatoriale",
        common: "Guinea Equatoriale",
      },
      jpn: {
        official: "\u8d64\u9053\u30ae\u30cb\u30a2\u5171\u548c\u56fd",
        common: "\u8d64\u9053\u30ae\u30cb\u30a2",
      },
      kor: {
        official: "\uc801\ub3c4 \uae30\ub2c8 \uacf5\ud654\uad6d",
        common: "\uc801\ub3c4 \uae30\ub2c8",
      },
      nld: {
        official: "Republiek Equatoriaal-Guinea",
        common: "Equatoriaal-Guinea",
      },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u06af\u06cc\u0646\u0647 \u0627\u0633\u062a\u0648\u0627\u06cc\u06cc",
        common:
          "\u06af\u06cc\u0646\u0647 \u0627\u0633\u062a\u0648\u0627\u06cc\u06cc",
      },
      pol: {
        official: "Republika Gwinei R\u00f3wnikowej",
        common: "Gwinea R\u00f3wnikowa",
      },
      por: {
        official: "Rep\u00fablica da Guin\u00e9 Equatorial",
        common: "Guin\u00e9 Equatorial",
      },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u042d\u043a\u0432\u0430\u0442\u043e\u0440\u0438\u0430\u043b\u044c\u043d\u0430\u044f \u0413\u0432\u0438\u043d\u0435\u044f",
        common:
          "\u042d\u043a\u0432\u0430\u0442\u043e\u0440\u0438\u0430\u043b\u044c\u043d\u0430\u044f \u0413\u0432\u0438\u043d\u0435\u044f",
      },
      slk: {
        official: "Republika rovn\u00edkovej Guiney",
        common: "Rovn\u00edkov\u00e1 Guinea",
      },
      spa: {
        official: "Rep\u00fablica de Guinea Ecuatorial",
        common: "Guinea Ecuatorial",
      },
      swe: {
        official: "Republiken Ekvatorialguinea",
        common: "Ekvatorialguinea",
      },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0627\u0633\u062a\u0648\u0627\u0626\u06cc \u06af\u0646\u06cc",
        common: "\u0627\u0633\u062a\u0648\u0627\u0626\u06cc \u06af\u0646\u06cc",
      },
      zho: {
        official: "\u8d64\u9053\u51e0\u5185\u4e9a\u5171\u548c\u56fd",
        common: "\u8d64\u9053\u51e0\u5185\u4e9a",
      },
    },
    latlng: [2, 10],
    landlocked: false,
    borders: ["CMR", "GAB"],
    area: 28051,
    flag: "\ud83c\uddec\ud83c\uddf6",
    demonyms: {
      eng: { f: "Equatorial Guinean", m: "Equatorial Guinean" },
      fra: { f: "\u00c9quato-guin\u00e9enne", m: "\u00c9quato-guin\u00e9en" },
    },
    callingCodes: ["+240"],
  },
  {
    name: {
      common: "Greece",
      official: "Hellenic Republic",
      native: {
        ell: {
          official:
            "\u0395\u03bb\u03bb\u03b7\u03bd\u03b9\u03ba\u03ae \u0394\u03b7\u03bc\u03bf\u03ba\u03c1\u03b1\u03c4\u03af\u03b1",
          common: "\u0395\u03bb\u03bb\u03ac\u03b4\u03b1",
        },
      },
    },
    tld: [".gr"],
    cca2: "GR",
    ccn3: "300",
    cca3: "GRC",
    cioc: "GRE",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { EUR: { name: "Euro", symbol: "\u20ac" } },
    idd: { root: "+3", suffixes: ["0"] },
    capital: ["Athens"],
    altSpellings: [
      "GR",
      "Ell\u00e1da",
      "Hellenic Republic",
      "\u0395\u03bb\u03bb\u03b7\u03bd\u03b9\u03ba\u03ae \u0394\u03b7\u03bc\u03bf\u03ba\u03c1\u03b1\u03c4\u03af\u03b1",
    ],
    region: "Europe",
    subregion: "Southern Europe",
    languages: { ell: "Greek" },
    translations: {
      ces: { official: "\u0158eck\u00e1 republika", common: "\u0158ecko" },
      deu: { official: "Hellenische Republik", common: "Griechenland" },
      est: { official: "Kreeka Vabariik", common: "Kreeka" },
      fin: { official: "Helleenien tasavalta", common: "Kreikka" },
      fra: {
        official: "R\u00e9publique hell\u00e9nique",
        common: "Gr\u00e8ce",
      },
      hrv: { official: "Helenska Republika", common: "Gr\u010dka" },
      hun: {
        official: "G\u00f6r\u00f6g K\u00f6zt\u00e1rsas\u00e1g",
        common: "G\u00f6r\u00f6gorsz\u00e1g",
      },
      ita: { official: "Repubblica ellenica", common: "Grecia" },
      jpn: {
        official: "\u30ae\u30ea\u30b7\u30e3\u5171\u548c\u56fd",
        common: "\u30ae\u30ea\u30b7\u30e3",
      },
      kor: {
        official: "\uadf8\ub9ac\uc2a4 \uacf5\ud654\uad6d",
        common: "\uadf8\ub9ac\uc2a4",
      },
      nld: { official: "Helleense Republiek", common: "Griekenland" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u06cc\u0648\u0646\u0627\u0646",
        common: "\u06cc\u0648\u0646\u0627\u0646",
      },
      pol: { official: "Republika Grecka", common: "Grecja" },
      por: { official: "Rep\u00fablica Hel\u00e9nica", common: "Gr\u00e9cia" },
      rus: {
        official:
          "\u0413\u0440\u0435\u0447\u0435\u0441\u043a\u0430\u044f \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430",
        common: "\u0413\u0440\u0435\u0446\u0438\u044f",
      },
      slk: { official: "Gr\u00e9cka republika", common: "Gre\u00e9cko" },
      spa: { official: "Rep\u00fablica Hel\u00e9nica", common: "Grecia" },
      swe: { official: "Republiken Grekland", common: "Grekland" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u06c1\u06cc\u0644\u06cc\u0646\u06cc\u06c1",
        common: "\u06cc\u0648\u0646\u0627\u0646",
      },
      zho: {
        official: "\u5e0c\u814a\u5171\u548c\u56fd",
        common: "\u5e0c\u814a",
      },
    },
    latlng: [39, 22],
    landlocked: false,
    borders: ["ALB", "BGR", "TUR", "MKD"],
    area: 131990,
    flag: "\ud83c\uddec\ud83c\uddf7",
    demonyms: {
      eng: { f: "Greek", m: "Greek" },
      fra: { f: "Grecque", m: "Grec" },
    },
    callingCodes: ["+30"],
  },
  {
    name: {
      common: "Grenada",
      official: "Grenada",
      native: { eng: { official: "Grenada", common: "Grenada" } },
    },
    tld: [".gd"],
    cca2: "GD",
    ccn3: "308",
    cca3: "GRD",
    cioc: "GRN",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { XCD: { name: "Eastern Caribbean dollar", symbol: "$" } },
    idd: { root: "+1", suffixes: ["473"] },
    capital: ["St. George's"],
    altSpellings: ["GD"],
    region: "Americas",
    subregion: "Caribbean",
    languages: { eng: "English" },
    translations: {
      ces: { official: "Grenada", common: "Grenada" },
      deu: { official: "Grenada", common: "Grenada" },
      est: { official: "Grenada", common: "Grenada" },
      fin: { official: "Grenada", common: "Grenada" },
      fra: { official: "Grenade", common: "Grenade" },
      hrv: { official: "Grenada", common: "Grenada" },
      hun: { official: "Grenada", common: "Grenada" },
      ita: { official: "Grenada", common: "Grenada" },
      jpn: {
        official: "\u30b0\u30ec\u30ca\u30c0",
        common: "\u30b0\u30ec\u30ca\u30c0",
      },
      kor: {
        official: "\uadf8\ub808\ub098\ub2e4",
        common: "\uadf8\ub808\ub098\ub2e4",
      },
      nld: { official: "Grenada", common: "Grenada" },
      per: {
        official: "\u06af\u0631\u0646\u0627\u062f\u0627",
        common: "\u06af\u0631\u0646\u0627\u062f\u0627",
      },
      pol: { official: "Grenada", common: "Grenada" },
      por: { official: "Grenada", common: "Granada" },
      rus: {
        official: "\u0413\u0440\u0435\u043d\u0430\u0434\u0430",
        common: "\u0413\u0440\u0435\u043d\u0430\u0434\u0430",
      },
      slk: { official: "Grenada", common: "Grenada" },
      spa: { official: "Granada", common: "Grenada" },
      swe: { official: "Grenada", common: "Grenada" },
      urd: {
        official: "\u06af\u0631\u06cc\u0646\u0627\u0688\u0627",
        common: "\u06af\u0631\u06cc\u0646\u0627\u0688\u0627",
      },
      zho: {
        official: "\u683c\u6797\u7eb3\u8fbe",
        common: "\u683c\u6797\u7eb3\u8fbe",
      },
    },
    latlng: [12.11666666, -61.66666666],
    landlocked: false,
    borders: [],
    area: 344,
    flag: "\ud83c\uddec\ud83c\udde9",
    demonyms: {
      eng: { f: "Grenadian", m: "Grenadian" },
      fra: { f: "Grenadienne", m: "Grenadien" },
    },
    callingCodes: ["+1473"],
  },
  {
    name: {
      common: "Greenland",
      official: "Greenland",
      native: {
        kal: { official: "Kalaallit Nunaat", common: "Kalaallit Nunaat" },
      },
    },
    tld: [".gl"],
    cca2: "GL",
    ccn3: "304",
    cca3: "GRL",
    cioc: "",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: { DKK: { name: "krone", symbol: "kr." } },
    idd: { root: "+2", suffixes: ["99"] },
    capital: ["Nuuk"],
    altSpellings: ["GL", "Gr\u00f8nland"],
    region: "Americas",
    subregion: "North America",
    languages: { kal: "Greenlandic" },
    translations: {
      ces: { official: "Gr\u00f3nsko", common: "Gr\u00f3nsko" },
      deu: { official: "Gr\u00f6nland", common: "Gr\u00f6nland" },
      est: { official: "Gr\u00f6\u00f6nimaa", common: "Gr\u00f6\u00f6nimaa" },
      fin: { official: "Gro\u00f6nlanti", common: "Gro\u00f6nlanti" },
      fra: { official: "Groenland", common: "Groenland" },
      hrv: { official: "Grenland", common: "Grenland" },
      hun: { official: "Gr\u00f6nland", common: "Gr\u00f6nland" },
      ita: { official: "Groenlandia", common: "Groenlandia" },
      jpn: {
        official: "\u30b0\u30ea\u30fc\u30f3\u30e9\u30f3\u30c9",
        common: "\u30b0\u30ea\u30fc\u30f3\u30e9\u30f3\u30c9",
      },
      kor: {
        official: "\uadf8\ub9b0\ub780\ub4dc",
        common: "\uadf8\ub9b0\ub780\ub4dc",
      },
      nld: { official: "Groenland", common: "Groenland" },
      per: {
        official: "\u06af\u0631\u0648\u0626\u0646\u0644\u0646\u062f",
        common: "\u06af\u0631\u06cc\u0646\u0644\u0646\u062f",
      },
      pol: { official: "Grenlandia", common: "Grenlandia" },
      por: { official: "Groenl\u00e2ndia", common: "Gronel\u00e2ndia" },
      rus: {
        official:
          "\u0413\u0440\u0435\u043d\u043b\u0430\u043d\u0434\u0438\u044f",
        common: "\u0413\u0440\u0435\u043d\u043b\u0430\u043d\u0434\u0438\u044f",
      },
      slk: { official: "Gr\u00f3nsko", common: "Gr\u00f3nsko" },
      spa: { official: "Groenlandia", common: "Groenlandia" },
      swe: { official: "Gr\u00f6nland", common: "Gr\u00f6nland" },
      urd: {
        official: "\u06af\u0631\u06cc\u0646 \u0644\u06cc\u0646\u0688",
        common: "\u06af\u0631\u06cc\u0646 \u0644\u06cc\u0646\u0688",
      },
      zho: { official: "\u683c\u9675\u5170", common: "\u683c\u9675\u5170" },
    },
    latlng: [72, -40],
    landlocked: false,
    borders: [],
    area: 2166086,
    flag: "\ud83c\uddec\ud83c\uddf1",
    demonyms: {
      eng: { f: "Greenlandic", m: "Greenlandic" },
      fra: { f: "Groenlandaise", m: "Groenlandais" },
    },
    callingCodes: ["+299"],
  },
  {
    name: {
      common: "Guatemala",
      official: "Republic of Guatemala",
      native: {
        spa: { official: "Rep\u00fablica de Guatemala", common: "Guatemala" },
      },
    },
    tld: [".gt"],
    cca2: "GT",
    ccn3: "320",
    cca3: "GTM",
    cioc: "GUA",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { GTQ: { name: "Guatemalan quetzal", symbol: "Q" } },
    idd: { root: "+5", suffixes: ["02"] },
    capital: ["Guatemala City"],
    altSpellings: ["GT"],
    region: "Americas",
    subregion: "Central America",
    languages: { spa: "Spanish" },
    translations: {
      ces: { official: "Republika Guatemala", common: "Guatemala" },
      deu: { official: "Republik Guatemala", common: "Guatemala" },
      est: { official: "Guatemala Vabariik", common: "Guatemala" },
      fin: { official: "Guatemalan tasavalta", common: "Guatemala" },
      fra: { official: "R\u00e9publique du Guatemala", common: "Guatemala" },
      hrv: { official: "Republika Gvatemala", common: "Gvatemala" },
      hun: {
        official: "Guatemalai K\u00f6zt\u00e1rsas\u00e1g",
        common: "Guatemala",
      },
      ita: { official: "Repubblica del Guatemala", common: "Guatemala" },
      jpn: {
        official: "\u30b0\u30a2\u30c6\u30de\u30e9\u5171\u548c\u56fd",
        common: "\u30b0\u30a2\u30c6\u30de\u30e9",
      },
      kor: {
        official: "\uacfc\ud14c\ub9d0\ub77c \uacf5\ud654\uad6d",
        common: "\uacfc\ud14c\ub9d0\ub77c",
      },
      nld: { official: "Republiek Guatemala", common: "Guatemala" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u06af\u0648\u0627\u062a\u0650\u0645\u0627\u0644\u0627",
        common: "\u06af\u0648\u0627\u062a\u0650\u0645\u0627\u0644\u0627",
      },
      pol: { official: "Republika Gwatemali", common: "Gwatemala" },
      por: { official: "Rep\u00fablica da Guatemala", common: "Guatemala" },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0413\u0432\u0430\u0442\u0435\u043c\u0430\u043b\u0430",
        common: "\u0413\u0432\u0430\u0442\u0435\u043c\u0430\u043b\u0430",
      },
      slk: { official: "Guatemalsk\u00e1 republika", common: "Guatemala" },
      spa: { official: "Rep\u00fablica de Guatemala", common: "Guatemala" },
      swe: { official: "Republiken Guatemala", common: "Guatemala" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u06af\u0648\u0627\u062a\u06cc\u0645\u0627\u0644\u0627",
        common: "\u06af\u0648\u0627\u062a\u06cc\u0645\u0627\u0644\u0627",
      },
      zho: {
        official: "\u5371\u5730\u9a6c\u62c9\u5171\u548c\u56fd",
        common: "\u5371\u5730\u9a6c\u62c9",
      },
    },
    latlng: [15.5, -90.25],
    landlocked: false,
    borders: ["BLZ", "SLV", "HND", "MEX"],
    area: 108889,
    flag: "\ud83c\uddec\ud83c\uddf9",
    demonyms: {
      eng: { f: "Guatemalan", m: "Guatemalan" },
      fra: { f: "Guat\u00e9malt\u00e8que", m: "Guat\u00e9malt\u00e8que" },
    },
    callingCodes: ["+502"],
  },
  {
    name: {
      common: "French Guiana",
      official: "Guiana",
      native: { fra: { official: "Guyane", common: "Guyane fran\u00e7aise" } },
    },
    tld: [".gf"],
    cca2: "GF",
    ccn3: "254",
    cca3: "GUF",
    cioc: "",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: { EUR: { name: "Euro", symbol: "\u20ac" } },
    idd: { root: "+5", suffixes: ["94"] },
    capital: ["Cayenne"],
    altSpellings: ["GF", "Guiana", "Guyane"],
    region: "Americas",
    subregion: "South America",
    languages: { fra: "French" },
    translations: {
      ces: {
        official: "Francouzsk\u00e1 Guyana",
        common: "Francouzsk\u00e1 Guyana",
      },
      deu: {
        official: "Franz\u00f6sisch-Guayana",
        common: "Franz\u00f6sisch-Guayana",
      },
      est: { official: "Guajaana departemang", common: "Prantsuse Guajaana" },
      fin: { official: "Ranskan Guayana", common: "Ranskan Guayana" },
      fra: { official: "Guyane", common: "Guyane" },
      hrv: { official: "Gijana", common: "Francuska Gvajana" },
      hun: { official: "Francia Guyana", common: "Francia Guyana" },
      ita: { official: "Guiana", common: "Guyana francese" },
      jpn: {
        official: "\u30ae\u30a2\u30ca",
        common: "\u30d5\u30e9\u30f3\u30b9\u9818\u30ae\u30a2\u30ca",
      },
      kor: {
        official: "\ud504\ub791\uc2a4\ub839 \uae30\uc544\ub098",
        common: "\ud504\ub791\uc2a4\ub839 \uae30\uc544\ub098",
      },
      nld: { official: "Guyana", common: "Frans-Guyana" },
      per: {
        official:
          "\u06af\u0648\u06cc\u0627\u0646 \u0641\u0631\u0627\u0646\u0633\u0647",
        common:
          "\u06af\u0648\u06cc\u0627\u0646 \u0641\u0631\u0627\u0646\u0633\u0647",
      },
      pol: { official: "Gujana Francuska", common: "Gujana Francuska" },
      por: { official: "Guiana", common: "Guiana Francesa" },
      rus: {
        official: "\u0413\u0432\u0438\u0430\u043d\u0430",
        common:
          "\u0424\u0440\u0430\u043d\u0446\u0443\u0437\u0441\u043a\u0430\u044f \u0413\u0432\u0438\u0430\u043d\u0430",
      },
      slk: { official: "Franc\u00fazska Guyana", common: "Guyana" },
      spa: { official: "Guayana", common: "Guayana Francesa" },
      swe: { official: "Franska Guyana", common: "Franska Guyana" },
      urd: {
        official: "\u06af\u06cc\u0627\u0646\u0627",
        common:
          "\u0641\u0631\u0627\u0646\u0633\u06cc\u0633\u06cc \u06af\u06cc\u0627\u0646\u0627",
      },
      zho: {
        official: "\u6cd5\u5c5e\u572d\u4e9a\u90a3",
        common: "\u6cd5\u5c5e\u572d\u4e9a\u90a3",
      },
    },
    latlng: [4, -53],
    landlocked: false,
    borders: ["BRA", "SUR"],
    area: 83534,
    flag: "\ud83c\uddec\ud83c\uddeb",
    demonyms: {
      eng: { f: "Guianan", m: "Guianan" },
      fra: { f: "Guyanaise", m: "Guyanais" },
    },
    callingCodes: ["+594"],
  },
  {
    name: {
      common: "Guam",
      official: "Guam",
      native: {
        cha: { official: "Gu\u00e5h\u00e5n", common: "Gu\u00e5h\u00e5n" },
        eng: { official: "Guam", common: "Guam" },
        spa: { official: "Guam", common: "Guam" },
      },
    },
    tld: [".gu"],
    cca2: "GU",
    ccn3: "316",
    cca3: "GUM",
    cioc: "GUM",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: { USD: { name: "United States dollar", symbol: "$" } },
    idd: { root: "+1", suffixes: ["671"] },
    capital: ["Hag\u00e5t\u00f1a"],
    altSpellings: ["GU", "Gu\u00e5h\u00e5n"],
    region: "Oceania",
    subregion: "Micronesia",
    languages: { cha: "Chamorro", eng: "English", spa: "Spanish" },
    translations: {
      ces: { official: "Guam", common: "Guam" },
      deu: { official: "Guam", common: "Guam" },
      est: { official: "Guami ala", common: "Guam" },
      fin: { official: "Guam", common: "Guam" },
      fra: { official: "Guam", common: "Guam" },
      hrv: { official: "Guam", common: "Guam" },
      hun: { official: "Guam", common: "Guam" },
      ita: { official: "Guam", common: "Guam" },
      jpn: { official: "\u30b0\u30a2\u30e0", common: "\u30b0\u30a2\u30e0" },
      kor: { official: "\uad0c", common: "\uad0c" },
      nld: { official: "Guam", common: "Guam" },
      per: {
        official: "\u06af\u0648\u0622\u0645",
        common: "\u06af\u0648\u0622\u0645",
      },
      pol: { official: "Terytorium Guamu", common: "Guam" },
      por: { official: "Guam", common: "Guam" },
      rus: {
        official: "\u0413\u0443\u0430\u043c",
        common: "\u0413\u0443\u0430\u043c",
      },
      slk: { official: "Guam", common: "Guam" },
      spa: { official: "Guam", common: "Guam" },
      swe: { official: "Guam", common: "Guam" },
      urd: {
        official: "\u06af\u0648\u0627\u0645",
        common: "\u06af\u0648\u0627\u0645",
      },
      zho: { official: "\u5173\u5c9b", common: "\u5173\u5c9b" },
    },
    latlng: [13.46666666, 144.78333333],
    landlocked: false,
    borders: [],
    area: 549,
    flag: "\ud83c\uddec\ud83c\uddfa",
    demonyms: {
      eng: { f: "Guamanian", m: "Guamanian" },
      fra: { f: "", m: "" },
    },
    callingCodes: ["+1671"],
  },
  {
    name: {
      common: "Guyana",
      official: "Co-operative Republic of Guyana",
      native: {
        eng: { official: "Co-operative Republic of Guyana", common: "Guyana" },
      },
    },
    tld: [".gy"],
    cca2: "GY",
    ccn3: "328",
    cca3: "GUY",
    cioc: "GUY",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { GYD: { name: "Guyanese dollar", symbol: "$" } },
    idd: { root: "+5", suffixes: ["92"] },
    capital: ["Georgetown"],
    altSpellings: ["GY", "Co-operative Republic of Guyana"],
    region: "Americas",
    subregion: "South America",
    languages: { eng: "English" },
    translations: {
      ces: { official: "Kooperativn\u00ed republika Guyana", common: "Guyana" },
      deu: { official: "Kooperative Republik Guyana", common: "Guyana" },
      est: { official: "Guyana Vabariik", common: "Guyana" },
      fin: {
        official: "Guayanan osuustoiminnallinen tasavalta",
        common: "Guayana",
      },
      fra: {
        official: "R\u00e9publique coop\u00e9rative de Guyana",
        common: "Guyana",
      },
      hrv: { official: "Zadruga Republika Gvajana", common: "Gvajana" },
      hun: {
        official: "Guyanai Sz\u00f6vetkezeti K\u00f6zt\u00e1rsas\u00e1g",
        common: "Guyana",
      },
      ita: { official: "Co -operative Republic of Guyana", common: "Guyana" },
      jpn: {
        official:
          "\u30ac\u30a4\u30a2\u30ca\u306e\u5354\u540c\u5171\u548c\u56fd",
        common: "\u30ac\u30a4\u30a2\u30ca",
      },
      kor: {
        official: "\uac00\uc774\uc544\ub098 \ud611\ub3d9 \uacf5\ud654\uad6d",
        common: "\uac00\uc774\uc544\ub098",
      },
      nld: { official: "Co\u00f6peratieve Republiek Guyana", common: "Guyana" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u062a\u0639\u0627\u0648\u0646\u06cc \u06af\u0648\u06cc\u0627\u0646",
        common: "\u06af\u0648\u06cc\u0627\u0646",
      },
      pol: { official: "Kooperacyjna Republika Gujany", common: "Gujana" },
      por: {
        official: "Co -operative Rep\u00fablica da Guiana",
        common: "Guiana",
      },
      rus: {
        official:
          "\u041a\u043e\u043e\u043f\u0435\u0440\u0430\u0442\u0438\u0432\u043d\u0430\u044f \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0413\u0430\u0439\u0430\u043d\u0430",
        common: "\u0413\u0430\u0439\u0430\u043d\u0430",
      },
      slk: {
        official: "Guyansk\u00e1 kooperat\u00edvna republika",
        common: "Guyana",
      },
      spa: {
        official: "Rep\u00fablica Cooperativa de Guyana",
        common: "Guyana",
      },
      swe: { official: "Kooperativa republiken Guyana", common: "Guyana" },
      urd: {
        official:
          "\u062a\u0639\u0627\u0648\u0646\u06cc \u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u06af\u06cc\u0627\u0646\u0627",
        common: "\u06af\u06cc\u0627\u0646\u0627",
      },
      zho: {
        official: "\u572d\u4e9a\u90a3\u5171\u548c\u56fd",
        common: "\u572d\u4e9a\u90a3",
      },
    },
    latlng: [5, -59],
    landlocked: false,
    borders: ["BRA", "SUR", "VEN"],
    area: 214969,
    flag: "\ud83c\uddec\ud83c\uddfe",
    demonyms: {
      eng: { f: "Guyanese", m: "Guyanese" },
      fra: { f: "Guyanienne", m: "Guyanien" },
    },
    callingCodes: ["+592"],
  },
  {
    name: {
      common: "Hong Kong",
      official:
        "Hong Kong Special Administrative Region of the People's Republic of China",
      native: {
        eng: {
          official:
            "Hong Kong Special Administrative Region of the People's Republic of China",
          common: "Hong Kong",
        },
        zho: {
          official:
            "\u4e2d\u534e\u4eba\u6c11\u5171\u548c\u56fd\u9999\u6e2f\u7279\u522b\u884c\u653f\u533a",
          common: "\u9999\u6e2f",
        },
      },
    },
    tld: [".hk", ".\u9999\u6e2f"],
    cca2: "HK",
    ccn3: "344",
    cca3: "HKG",
    cioc: "HKG",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: { HKD: { name: "Hong Kong dollar", symbol: "$" } },
    idd: { root: "+8", suffixes: ["52"] },
    capital: ["City of Victoria"],
    altSpellings: ["HK"],
    region: "Asia",
    subregion: "Eastern Asia",
    languages: { eng: "English", zho: "Chinese" },
    translations: {
      ces: {
        official:
          "Zvl\u00e1\u0161tn\u00ed administrativn\u00ed oblast \u010c\u00ednsk\u00e9 lidov\u00e9 republiky Hongkong",
        common: "Hongkong",
      },
      deu: {
        official: "Sonderverwaltungszone Hongkong der Volksrepublik China",
        common: "Hongkong",
      },
      est: { official: "Hongkongi erihalduspiirkond", common: "Hongkong" },
      fin: { official: "Hong Kongin erityishallintoalue", common: "Hongkong" },
      fra: {
        official:
          "R\u00e9gion administrative sp\u00e9ciale de Hong Kong de la R\u00e9publique populaire de Chine",
        common: "Hong Kong",
      },
      hrv: {
        official:
          "Hong Kong Posebnog upravnog podru\u010djaNarodne Republike Kine",
        common: "Hong Kong",
      },
      hun: { official: "Hongkong", common: "Hongkong" },
      ita: {
        official:
          "Hong Kong Regione amministrativa speciale della Repubblica Popolare Cinese",
        common: "Hong Kong",
      },
      jpn: {
        official:
          "\u4e2d\u83ef\u4eba\u6c11\u5171\u548c\u56fd\u9999\u6e2f\u7279\u5225\u884c\u653f\u533a",
        common: "\u9999\u6e2f",
      },
      kor: {
        official:
          "\uc911\ud654\uc778\ubbfc\uacf5\ud654\uad6d \ud64d\ucf69 \ud2b9\ubcc4\ud589\uc815\uad6c",
        common: "\ud64d\ucf69",
      },
      nld: {
        official:
          "Hong Kong Speciale Administratieve Regio van de Volksrepubliek China",
        common: "Hongkong",
      },
      per: {
        official: "\u0647\u064f\u0646\u06af \u06a9\u064f\u0646\u06af",
        common: "\u0647\u064f\u0646\u06af \u06a9\u064f\u0646\u06af",
      },
      pol: {
        official:
          "Specjalny Region Administracyjny Chi\u0144skiej Republiki Ludowej Hongkong",
        common: "Hongkong",
      },
      por: {
        official:
          "Hong Kong Regi\u00e3o Administrativa Especial da Rep\u00fablica Popular da China",
        common: "Hong Kong",
      },
      rus: {
        official:
          "Hong Kong \u0421\u043f\u0435\u0446\u0438\u0430\u043b\u044c\u043d\u044b\u0439 \u0430\u0434\u043c\u0438\u043d\u0438\u0441\u0442\u0440\u0430\u0442\u0438\u0432\u043d\u044b\u0439 \u0440\u0430\u0439\u043e\u043d \u041a\u0438\u0442\u0430\u0439\u0441\u043a\u043e\u0439 \u041d\u0430\u0440\u043e\u0434\u043d\u043e\u0439 \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0438 \u041a\u0438\u0442\u0430\u044f",
        common: "\u0413\u043e\u043d\u043a\u043e\u043d\u0433",
      },
      slk: {
        official:
          "\u0160peci\u00e1lna administrat\u00edvna oblas\u0165\u010c\u00ednskej \u013eudovej republiky Hongkong",
        common: "Hongkong",
      },
      spa: {
        official:
          "Hong Kong Regi\u00f3n Administrativa Especial de la Rep\u00fablica Popular China",
        common: "Hong Kong",
      },
      swe: { official: "Hongkong", common: "Hongkong" },
      urd: {
        official:
          "\u06c1\u0627\u0646\u06af \u06a9\u0627\u0646\u06af \u0639\u0648\u0627\u0645\u06cc \u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0686\u06cc\u0646 \u06a9\u0627 \u062e\u0635\u0648\u0635\u06cc \u0627\u0646\u062a\u0638\u0627\u0645\u06cc \u0639\u0644\u0627\u0642\u06c1",
        common: "\u06c1\u0627\u0646\u06af \u06a9\u0627\u0646\u06af",
      },
    },
    latlng: [22.267, 114.188],
    landlocked: false,
    borders: ["CHN"],
    area: 1104,
    flag: "\ud83c\udded\ud83c\uddf0",
    demonyms: {
      eng: { f: "Hong Konger", m: "Hong Konger" },
      fra: { f: "Hongkongaise", m: "Hongkongais" },
    },
    callingCodes: ["+852"],
  },
  {
    name: {
      common: "Heard Island and McDonald Islands",
      official: "Heard Island and McDonald Islands",
      native: {
        eng: {
          official: "Heard Island and McDonald Islands",
          common: "Heard Island and McDonald Islands",
        },
      },
    },
    tld: [".hm", ".aq"],
    cca2: "HM",
    ccn3: "334",
    cca3: "HMD",
    cioc: "",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: [],
    idd: { root: "", suffixes: [""] },
    capital: [""],
    altSpellings: ["HM", "Heard Island and McDonald Islands"],
    region: "Antarctic",
    subregion: "",
    languages: { eng: "English" },
    translations: {
      ces: {
        official: "Heard\u016fv ostrov a McDonaldovy ostrovy",
        common: "Heard\u016fv ostrov a McDonaldovy ostrovy",
      },
      deu: {
        official: "Heard und McDonaldinseln",
        common: "Heard und die McDonaldinseln",
      },
      est: {
        official: "Heardi ja McDonaldi saarte ala",
        common: "Heard ja McDonald",
      },
      fin: {
        official: "Heard ja McDonaldinsaaret",
        common: "Heard ja McDonaldinsaaret",
      },
      fra: {
        official: "Des \u00eeles Heard et McDonald",
        common: "\u00celes Heard-et-MacDonald",
      },
      hrv: {
        official: "Otok Heard i oto\u010dje McDonald",
        common: "Otok Heard i oto\u010dje McDonald",
      },
      hun: {
        official: "Heard-sziget \u00e9s McDonald-szigetek",
        common: "Heard-sziget \u00e9s McDonald-szigetek",
      },
      ita: {
        official: "Isole Heard e McDonald",
        common: "Isole Heard e McDonald",
      },
      jpn: {
        official:
          "\u30cf\u30fc\u30c9\u5cf6\u3068\u30de\u30af\u30c9\u30ca\u30eb\u30c9\u8af8\u5cf6",
        common:
          "\u30cf\u30fc\u30c9\u5cf6\u3068\u30de\u30af\u30c9\u30ca\u30eb\u30c9\u8af8\u5cf6",
      },
      kor: {
        official: "\ud5c8\ub4dc \ub9e5\ub3c4\ub110\ub4dc \uc81c\ub3c4",
        common: "\ud5c8\ub4dc \ub9e5\ub3c4\ub110\ub4dc \uc81c\ub3c4",
      },
      nld: {
        official: "Heard en McDonaldeilanden",
        common: "Heard-en McDonaldeilanden",
      },
      per: {
        official:
          "\u062c\u0632\u06cc\u0631\u0647 \u0647\u0631\u062f \u0648 \u062c\u0632\u0627\u06cc\u0631 \u0645\u06a9\u200c\u062f\u0648\u0646\u0627\u0644\u062f",
        common:
          "\u062c\u0632\u06cc\u0631\u0647 \u0647\u0631\u062f \u0648 \u062c\u0632\u0627\u06cc\u0631 \u0645\u06a9\u200c\u062f\u0648\u0646\u0627\u0644\u062f",
      },
      pol: {
        official: "Terytorium Wysp Heard i McDonalda",
        common: "Wyspy Heard i McDonalda",
      },
      por: {
        official: "Ilha Heard e Ilhas McDonald",
        common: "Ilha Heard e Ilhas McDonald",
      },
      rus: {
        official:
          "\u041e\u0441\u0442\u0440\u043e\u0432 \u0425\u0435\u0440\u0434 \u0438 \u043e\u0441\u0442\u0440\u043e\u0432\u0430 \u041c\u0430\u043a\u0434\u043e\u043d\u0430\u043b\u044c\u0434",
        common:
          "\u041e\u0441\u0442\u0440\u043e\u0432 \u0425\u0435\u0440\u0434 \u0438 \u043e\u0441\u0442\u0440\u043e\u0432\u0430 \u041c\u0430\u043a\u0434\u043e\u043d\u0430\u043b\u044c\u0434",
      },
      slk: {
        official:
          "Terit\u00f3rium Heardovho ostrova a Macdonaldov\u00fdch ostrovov",
        common: "Heardov ostrov",
      },
      spa: {
        official: "Islas Heard y McDonald",
        common: "Islas Heard y McDonald",
      },
      swe: {
        official: "Heard- och McDonald\u00f6arna",
        common: "Heard- och McDonald\u00f6arna",
      },
      urd: {
        official:
          "\u062c\u0632\u06cc\u0631\u06c1 \u06c1\u0631\u0688 \u0648 \u062c\u0632\u0627\u0626\u0631 \u0645\u06a9\u0688\u0648\u0646\u0644\u0688",
        common:
          "\u062c\u0632\u06cc\u0631\u06c1 \u06c1\u0631\u0688 \u0648 \u062c\u0632\u0627\u0626\u0631 \u0645\u06a9\u0688\u0648\u0646\u0644\u0688",
      },
      zho: {
        official: "\u8d6b\u5fb7\u5c9b\u548c\u9ea6\u5f53\u52b3\u7fa4\u5c9b",
        common: "\u8d6b\u5fb7\u5c9b\u548c\u9ea6\u5f53\u52b3\u7fa4\u5c9b",
      },
    },
    latlng: [-53.1, 72.51666666],
    landlocked: false,
    borders: [],
    area: 412,
    flag: "\ud83c\udded\ud83c\uddf2",
    demonyms: {
      eng: {
        f: "Heard and McDonald Islander",
        m: "Heard and McDonald Islander",
      },
      fra: { f: "", m: "" },
    },
    callingCodes: [""],
  },
  {
    name: {
      common: "Honduras",
      official: "Republic of Honduras",
      native: {
        spa: { official: "Rep\u00fablica de Honduras", common: "Honduras" },
      },
    },
    tld: [".hn"],
    cca2: "HN",
    ccn3: "340",
    cca3: "HND",
    cioc: "HON",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { HNL: { name: "Honduran lempira", symbol: "L" } },
    idd: { root: "+5", suffixes: ["04"] },
    capital: ["Tegucigalpa"],
    altSpellings: ["HN", "Republic of Honduras", "Rep\u00fablica de Honduras"],
    region: "Americas",
    subregion: "Central America",
    languages: { spa: "Spanish" },
    translations: {
      ces: { official: "Hondurask\u00e1 republika", common: "Honduras" },
      deu: { official: "Republik Honduras", common: "Honduras" },
      est: { official: "Hondurase Vabariik", common: "Honduras" },
      fin: { official: "Hondurasin tasavalta", common: "Honduras" },
      fra: { official: "R\u00e9publique du Honduras", common: "Honduras" },
      hrv: { official: "Republika Honduras", common: "Honduras" },
      hun: {
        official: "Hondurasi K\u00f6zt\u00e1rsas\u00e1g",
        common: "Honduras",
      },
      ita: { official: "Repubblica di Honduras", common: "Honduras" },
      jpn: {
        official: "\u30db\u30f3\u30b8\u30e5\u30e9\u30b9\u5171\u548c\u56fd",
        common: "\u30db\u30f3\u30b8\u30e5\u30e9\u30b9",
      },
      kor: {
        official: "\uc628\ub450\ub77c\uc2a4 \uacf5\ud654\uad6d",
        common: "\uc628\ub450\ub77c\uc2a4",
      },
      nld: { official: "Republiek Honduras", common: "Honduras" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0647\u0646\u062f\u0648\u0631\u0627\u0633",
        common: "\u0647\u064f\u0646\u062f\u0648\u0631\u0627\u0633",
      },
      pol: { official: "Republika Hondurasu", common: "Honduras" },
      por: { official: "Rep\u00fablica de Honduras", common: "Honduras" },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0413\u043e\u043d\u0434\u0443\u0440\u0430\u0441",
        common: "\u0413\u043e\u043d\u0434\u0443\u0440\u0430\u0441",
      },
      slk: { official: "Hondurask\u00e1 republika", common: "Honduras" },
      spa: { official: "Rep\u00fablica de Honduras", common: "Honduras" },
      swe: { official: "Republiken Honduras", common: "Honduras" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u06c1\u0648\u0646\u0688\u0648\u0631\u0627\u0633",
        common: "\u06c1\u0648\u0646\u0688\u0648\u0631\u0627\u0633",
      },
      zho: {
        official: "\u6d2a\u90fd\u62c9\u65af\u5171\u548c\u56fd",
        common: "\u6d2a\u90fd\u62c9\u65af",
      },
    },
    latlng: [15, -86.5],
    landlocked: false,
    borders: ["GTM", "SLV", "NIC"],
    area: 112492,
    flag: "\ud83c\udded\ud83c\uddf3",
    demonyms: {
      eng: { f: "Honduran", m: "Honduran" },
      fra: { f: "Hondurienne", m: "Hondurien" },
    },
    callingCodes: ["+504"],
  },
  {
    name: {
      common: "Croatia",
      official: "Republic of Croatia",
      native: { hrv: { official: "Republika Hrvatska", common: "Hrvatska" } },
    },
    tld: [".hr"],
    cca2: "HR",
    ccn3: "191",
    cca3: "HRV",
    cioc: "CRO",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { HRK: { name: "Croatian kuna", symbol: "kn" } },
    idd: { root: "+3", suffixes: ["85"] },
    capital: ["Zagreb"],
    altSpellings: [
      "HR",
      "Hrvatska",
      "Republic of Croatia",
      "Republika Hrvatska",
    ],
    region: "Europe",
    subregion: "Southeast Europe",
    languages: { hrv: "Croatian" },
    translations: {
      ces: { official: "Chorvatsk\u00e1 republika", common: "Chorvatsko" },
      cym: { official: "Gweriniaeth Croatia", common: "Croatia" },
      deu: { official: "Republik Kroatien", common: "Kroatien" },
      est: { official: "Horvaatia Vabariik", common: "Horvaatia" },
      fin: { official: "Kroatian tasavalta", common: "Kroatia" },
      fra: { official: "R\u00e9publique de Croatie", common: "Croatie" },
      hrv: { official: "Republika Hrvatska", common: "Hrvatska" },
      hun: {
        official: "Horv\u00e1t K\u00f6zt\u00e1rsas\u00e1g",
        common: "Horv\u00e1torsz\u00e1g",
      },
      ita: { official: "Repubblica di Croazia", common: "Croazia" },
      jpn: {
        official: "\u30af\u30ed\u30a2\u30c1\u30a2\u5171\u548c\u56fd",
        common: "\u30af\u30ed\u30a2\u30c1\u30a2",
      },
      kor: {
        official: "\ud06c\ub85c\uc544\ud2f0\uc544 \uacf5\ud654\uad6d",
        common: "\ud06c\ub85c\uc544\ud2f0\uc544",
      },
      nld: { official: "Republiek Kroati\u00eb", common: "Kroati\u00eb" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u06a9\u0631\u0648\u0627\u0633\u06cc",
        common: "\u06a9\u0631\u064f\u0648\u0627\u0633\u06cc",
      },
      pol: { official: "Republika Chorwacji", common: "Chorwacja" },
      por: {
        official: "Rep\u00fablica da Cro\u00e1cia",
        common: "Cro\u00e1cia",
      },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0425\u043e\u0440\u0432\u0430\u0442\u0438\u044f",
        common: "\u0425\u043e\u0440\u0432\u0430\u0442\u0438\u044f",
      },
      slk: { official: "Chorv\u00e1tska republika", common: "Chorv\u00e1tsko" },
      spa: { official: "Rep\u00fablica de Croacia", common: "Croacia" },
      swe: { official: "Republiken Kroatien", common: "Kroatien" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u06a9\u0631\u0648\u06cc\u0626\u0634\u0627",
        common: "\u06a9\u0631\u0648\u06cc\u0626\u0634\u0627",
      },
      zho: {
        official: "\u514b\u7f57\u5730\u4e9a\u5171\u548c\u56fd",
        common: "\u514b\u7f57\u5730\u4e9a",
      },
    },
    latlng: [45.16666666, 15.5],
    landlocked: false,
    borders: ["BIH", "HUN", "MNE", "SRB", "SVN"],
    area: 56594,
    flag: "\ud83c\udded\ud83c\uddf7",
    demonyms: {
      eng: { f: "Croatian", m: "Croatian" },
      fra: { f: "Croate", m: "Croate" },
    },
    callingCodes: ["+385"],
  },
  {
    name: {
      common: "Haiti",
      official: "Republic of Haiti",
      native: {
        fra: { official: "R\u00e9publique d'Ha\u00efti", common: "Ha\u00efti" },
        hat: { official: "Repiblik Ayiti", common: "Ayiti" },
      },
    },
    tld: [".ht"],
    cca2: "HT",
    ccn3: "332",
    cca3: "HTI",
    cioc: "HAI",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { HTG: { name: "Haitian gourde", symbol: "G" } },
    idd: { root: "+5", suffixes: ["09"] },
    capital: ["Port-au-Prince"],
    altSpellings: [
      "HT",
      "Republic of Haiti",
      "R\u00e9publique d'Ha\u00efti",
      "Repiblik Ayiti",
    ],
    region: "Americas",
    subregion: "Caribbean",
    languages: { fra: "French", hat: "Haitian Creole" },
    translations: {
      ces: { official: "Republika Haiti", common: "Haiti" },
      deu: { official: "Republik Haiti", common: "Haiti" },
      est: { official: "Haiti Vabariik", common: "Haiti" },
      fin: { official: "Haitin tasavalta", common: "Haiti" },
      fra: { official: "R\u00e9publique d'Ha\u00efti", common: "Ha\u00efti" },
      hrv: { official: "Republika Haiti", common: "Haiti" },
      hun: { official: "Haiti K\u00f6zt\u00e1rsas\u00e1g", common: "Haiti" },
      ita: { official: "Repubblica di Haiti", common: "Haiti" },
      jpn: {
        official: "\u30cf\u30a4\u30c1\u5171\u548c\u56fd",
        common: "\u30cf\u30a4\u30c1",
      },
      kor: {
        official: "\uc544\uc774\ud2f0 \uacf5\ud654\uad6d",
        common: "\uc544\uc774\ud2f0",
      },
      nld: { official: "Republiek Ha\u00efti", common: "Ha\u00efti" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0647\u0627\u0626\u06cc\u062a\u06cc",
        common: "\u0647\u0627\u0626\u06cc\u062a\u06cc",
      },
      pol: { official: "Republika Haiti", common: "Haiti" },
      por: { official: "Rep\u00fablica do Haiti", common: "Haiti" },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0413\u0430\u0438\u0442\u0438",
        common: "\u0413\u0430\u0438\u0442\u0438",
      },
      slk: { official: "Haitsk\u00e1 republika", common: "Haiti" },
      spa: { official: "Rep\u00fablica de Hait\u00ed", common: "Hait\u00ed" },
      swe: { official: "Republiken Haiti", common: "Haiti" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u06c1\u06cc\u0679\u06cc",
        common: "\u06c1\u06cc\u0679\u06cc",
      },
      zho: {
        official: "\u6d77\u5730\u5171\u548c\u56fd",
        common: "\u6d77\u5730",
      },
    },
    latlng: [19, -72.41666666],
    landlocked: false,
    borders: ["DOM"],
    area: 27750,
    flag: "\ud83c\udded\ud83c\uddf9",
    demonyms: {
      eng: { f: "Haitian", m: "Haitian" },
      fra: { f: "Ha\u00eftienne", m: "Ha\u00eftien" },
    },
    callingCodes: ["+509"],
  },
  {
    name: {
      common: "Hungary",
      official: "Hungary",
      native: {
        hun: { official: "Magyarorsz\u00e1g", common: "Magyarorsz\u00e1g" },
      },
    },
    tld: [".hu"],
    cca2: "HU",
    ccn3: "348",
    cca3: "HUN",
    cioc: "HUN",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { HUF: { name: "Hungarian forint", symbol: "Ft" } },
    idd: { root: "+3", suffixes: ["6"] },
    capital: ["Budapest"],
    altSpellings: ["HU"],
    region: "Europe",
    subregion: "Central Europe",
    languages: { hun: "Hungarian" },
    translations: {
      ces: { official: "Ma\u010farsko", common: "Ma\u010farsko" },
      deu: { official: "Ungarn", common: "Ungarn" },
      est: { official: "Ungari", common: "Ungari" },
      fin: { official: "Unkari", common: "Unkari" },
      fra: { official: "Hongrie", common: "Hongrie" },
      hrv: { official: "Mad\u017earska", common: "Ma\u0111arska" },
      hun: { official: "Magyarorsz\u00e1g", common: "Magyarorsz\u00e1g" },
      ita: { official: "Ungheria", common: "Ungheria" },
      jpn: {
        official: "\u30cf\u30f3\u30ac\u30ea\u30fc",
        common: "\u30cf\u30f3\u30ac\u30ea\u30fc",
      },
      kor: { official: "\ud5dd\uac00\ub9ac", common: "\ud5dd\uac00\ub9ac" },
      nld: { official: "Hongarije", common: "Hongarije" },
      per: {
        official: "\u0645\u062c\u0627\u0631\u0633\u062a\u0627\u0646",
        common: "\u0645\u062c\u0627\u0631\u0633\u062a\u0627\u0646",
      },
      pol: { official: "W\u0119gry", common: "W\u0119gry" },
      por: { official: "Hungria", common: "Hungria" },
      rus: {
        official: "\u0412\u0435\u043d\u0433\u0440\u0438\u044f",
        common: "\u0412\u0435\u043d\u0433\u0440\u0438\u044f",
      },
      slk: { official: "Ma\u010farsko", common: "Ma\u010farsko" },
      spa: { official: "Hungr\u00eda", common: "Hungr\u00eda" },
      swe: { official: "Ungern", common: "Ungern" },
      urd: {
        official: "\u0645\u062c\u0627\u0631\u0633\u062a\u0627\u0646",
        common: "\u0645\u062c\u0627\u0631\u0633\u062a\u0627\u0646",
      },
      zho: { official: "\u5308\u7259\u5229", common: "\u5308\u7259\u5229" },
    },
    latlng: [47, 20],
    landlocked: true,
    borders: ["AUT", "HRV", "ROU", "SRB", "SVK", "SVN", "UKR"],
    area: 93028,
    flag: "\ud83c\udded\ud83c\uddfa",
    demonyms: {
      eng: { f: "Hungarian", m: "Hungarian" },
      fra: { f: "Hongroise", m: "Hongrois" },
    },
    callingCodes: ["+36"],
  },
  {
    name: {
      common: "Indonesia",
      official: "Republic of Indonesia",
      native: { ind: { official: "Republik Indonesia", common: "Indonesia" } },
    },
    tld: [".id"],
    cca2: "ID",
    ccn3: "360",
    cca3: "IDN",
    cioc: "INA",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { IDR: { name: "Indonesian rupiah", symbol: "Rp" } },
    idd: { root: "+6", suffixes: ["2"] },
    capital: ["Jakarta"],
    altSpellings: ["ID", "Republic of Indonesia", "Republik Indonesia"],
    region: "Asia",
    subregion: "South-Eastern Asia",
    languages: { ind: "Indonesian" },
    translations: {
      ces: {
        official: "Indon\u00e9sk\u00e1 republika",
        common: "Indon\u00e9sie",
      },
      deu: { official: "Republik Indonesien", common: "Indonesien" },
      est: { official: "Indoneesia Vabariik", common: "Indoneesia" },
      fin: { official: "Indonesian tasavalta", common: "Indonesia" },
      fra: {
        official: "R\u00e9publique d'Indon\u00e9sie",
        common: "Indon\u00e9sie",
      },
      hrv: { official: "Republika Indonezija", common: "Indonezija" },
      hun: {
        official: "Indon\u00e9z K\u00f6zt\u00e1rsas\u00e1g",
        common: "Indon\u00e9zia",
      },
      ita: { official: "Repubblica di Indonesia", common: "Indonesia" },
      jpn: {
        official: "\u30a4\u30f3\u30c9\u30cd\u30b7\u30a2\u5171\u548c\u56fd",
        common: "\u30a4\u30f3\u30c9\u30cd\u30b7\u30a2",
      },
      kor: {
        official: "\uc778\ub3c4\ub124\uc2dc\uc544 \uacf5\ud654\uad6d",
        common: "\uc778\ub3c4\ub124\uc2dc\uc544",
      },
      nld: { official: "Republiek Indonesi\u00eb", common: "Indonesi\u00eb" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0627\u0646\u062f\u0648\u0646\u0632\u06cc",
        common: "\u0627\u0646\u062f\u0648\u0646\u0632\u06cc",
      },
      pol: { official: "Republika Indonezji", common: "Indonezja" },
      por: {
        official: "Rep\u00fablica da Indon\u00e9sia",
        common: "Indon\u00e9sia",
      },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0418\u043d\u0434\u043e\u043d\u0435\u0437\u0438\u044f",
        common: "\u0418\u043d\u0434\u043e\u043d\u0435\u0437\u0438\u044f",
      },
      slk: { official: "Indon\u00e9zska republika", common: "Indon\u00e9zia" },
      spa: { official: "Rep\u00fablica de Indonesia", common: "Indonesia" },
      swe: { official: "Republiken Indonesien", common: "Indonesien" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0627\u0646\u0688\u0648\u0646\u06cc\u0634\u06cc\u0627",
        common: "\u0627\u0646\u0688\u0648\u0646\u06cc\u0634\u06cc\u0627",
      },
      zho: {
        official: "\u5370\u5ea6\u5c3c\u897f\u4e9a\u5171\u548c\u56fd",
        common: "\u5370\u5ea6\u5c3c\u897f\u4e9a",
      },
    },
    latlng: [-5, 120],
    landlocked: false,
    borders: ["TLS", "MYS", "PNG"],
    area: 1904569,
    flag: "\ud83c\uddee\ud83c\udde9",
    demonyms: {
      eng: { f: "Indonesian", m: "Indonesian" },
      fra: { f: "Indon\u00e9sienne", m: "Indon\u00e9sien" },
    },
    callingCodes: ["+62"],
  },
  {
    name: {
      common: "Isle of Man",
      official: "Isle of Man",
      native: {
        eng: { official: "Isle of Man", common: "Isle of Man" },
        glv: { official: "Ellan Vannin or Mannin", common: "Mannin" },
      },
    },
    tld: [".im"],
    cca2: "IM",
    ccn3: "833",
    cca3: "IMN",
    cioc: "",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: {
      GBP: { name: "British pound", symbol: "\u00a3" },
      IMP: { name: "Manx pound", symbol: "\u00a3" },
    },
    idd: { root: "+4", suffixes: ["4"] },
    capital: ["Douglas"],
    altSpellings: ["IM", "Ellan Vannin", "Mann", "Mannin"],
    region: "Europe",
    subregion: "Northern Europe",
    languages: { eng: "English", glv: "Manx" },
    translations: {
      ces: { official: "Ostrov Man", common: "Ostrov Man" },
      deu: { official: "Isle of Man", common: "Insel Man" },
      est: { official: "Mani saar", common: "Mani saar" },
      fin: { official: "Mansaari", common: "Mansaari" },
      fra: { official: "Isle of Man", common: "\u00cele de Man" },
      hrv: { official: "Mana ostrvo", common: "Otok Man" },
      hun: { official: "Man", common: "Man" },
      ita: { official: "Isola di Man", common: "Isola di Man" },
      jpn: { official: "\u30de\u30f3\u5cf6", common: "\u30de\u30f3\u5cf6" },
      kor: { official: "\ub9e8\uc12c", common: "\ub9e8\uc12c" },
      nld: { official: "Isle of Man", common: "Isle of Man" },
      per: {
        official: "\u062c\u0632\u06cc\u0631\u0647\u0654 \u0645\u064e\u0646",
        common: "\u062c\u0632\u06cc\u0631\u0647\u0654 \u0645\u064e\u0646",
      },
      pol: { official: "Wyspa Man", common: "Wyspa Man" },
      por: { official: "Isle of Man", common: "Ilha de Man" },
      rus: {
        official: "\u041e\u0441\u0442\u0440\u043e\u0432 \u041c\u044d\u043d",
        common: "\u041e\u0441\u0442\u0440\u043e\u0432 \u041c\u044d\u043d",
      },
      slk: { official: "Ostrov Man", common: "Man" },
      spa: { official: "Isla de Man", common: "Isla de Man" },
      swe: { official: "Isle of Man", common: "Isle of Man" },
      urd: {
        official: "\u0622\u0626\u0644 \u0622\u0641 \u0645\u06cc\u0646",
        common: "\u0622\u0626\u0644 \u0622\u0641 \u0645\u06cc\u0646",
      },
      zho: { official: "\u9a6c\u6069\u5c9b", common: "\u9a6c\u6069\u5c9b" },
    },
    latlng: [54.25, -4.5],
    landlocked: false,
    borders: [],
    area: 572,
    flag: "\ud83c\uddee\ud83c\uddf2",
    demonyms: { eng: { f: "Manx", m: "Manx" }, fra: { f: "", m: "" } },
    callingCodes: ["+44"],
  },
  {
    name: {
      common: "India",
      official: "Republic of India",
      native: {
        eng: { official: "Republic of India", common: "India" },
        hin: {
          official:
            "\u092d\u093e\u0930\u0924 \u0917\u0923\u0930\u093e\u091c\u094d\u092f",
          common: "\u092d\u093e\u0930\u0924",
        },
        tam: {
          official:
            "\u0b87\u0ba8\u0bcd\u0ba4\u0bbf\u0baf\u0b95\u0bcd \u0b95\u0bc1\u0b9f\u0bbf\u0baf\u0bb0\u0b9a\u0bc1",
          common: "\u0b87\u0ba8\u0bcd\u0ba4\u0bbf\u0baf\u0bbe",
        },
      },
    },
    tld: [".in"],
    cca2: "IN",
    ccn3: "356",
    cca3: "IND",
    cioc: "IND",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { INR: { name: "Indian rupee", symbol: "\u20b9" } },
    idd: { root: "+9", suffixes: ["1"] },
    capital: ["New Delhi"],
    altSpellings: [
      "IN",
      "Bh\u0101rat",
      "Republic of India",
      "Bharat Ganrajya",
      "\u0b87\u0ba8\u0bcd\u0ba4\u0bbf\u0baf\u0bbe",
    ],
    region: "Asia",
    subregion: "Southern Asia",
    languages: { eng: "English", hin: "Hindi", tam: "Tamil" },
    translations: {
      ces: { official: "Indick\u00e1 republika", common: "Indie" },
      deu: { official: "Republik Indien", common: "Indien" },
      est: { official: "India Vabariik", common: "India" },
      fin: { official: "Intian tasavalta", common: "Intia" },
      fra: { official: "R\u00e9publique de l'Inde", common: "Inde" },
      hrv: { official: "Republika Indija", common: "Indija" },
      hun: { official: "Indiai K\u00f6zt\u00e1rsas\u00e1g", common: "India" },
      ita: { official: "Repubblica dell'India", common: "India" },
      jpn: {
        official: "\u30a4\u30f3\u30c9\u5171\u548c\u56fd",
        common: "\u30a4\u30f3\u30c9",
      },
      kor: {
        official: "\uc778\ub3c4 \uacf5\ud654\uad6d",
        common: "\uc778\ub3c4",
      },
      nld: { official: "Republiek India", common: "India" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0647\u0646\u062f\u0648\u0633\u062a\u0627\u0646",
        common: "\u0647\u0646\u062f",
      },
      pol: { official: "Republika Indii", common: "Indie" },
      por: { official: "Rep\u00fablica da \u00cdndia", common: "\u00cdndia" },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0418\u043d\u0434\u0438\u044f",
        common: "\u0418\u043d\u0434\u0438\u044f",
      },
      slk: { official: "Indick\u00e1 republika", common: "India" },
      spa: { official: "Rep\u00fablica de la India", common: "India" },
      swe: { official: "Republiken Indien", common: "Indien" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0628\u06be\u0627\u0631\u062a",
        common: "\u0628\u06be\u0627\u0631\u062a",
      },
      zho: {
        official: "\u5370\u5ea6\u5171\u548c\u56fd",
        common: "\u5370\u5ea6",
      },
    },
    latlng: [20, 77],
    landlocked: false,
    borders: ["BGD", "BTN", "MMR", "CHN", "NPL", "PAK"],
    area: 3287590,
    flag: "\ud83c\uddee\ud83c\uddf3",
    demonyms: {
      eng: { f: "Indian", m: "Indian" },
      fra: { f: "Indienne", m: "Indien" },
    },
    callingCodes: ["+91"],
  },
  {
    name: {
      common: "British Indian Ocean Territory",
      official: "British Indian Ocean Territory",
      native: {
        eng: {
          official: "British Indian Ocean Territory",
          common: "British Indian Ocean Territory",
        },
      },
    },
    tld: [".io"],
    cca2: "IO",
    ccn3: "086",
    cca3: "IOT",
    cioc: "",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: { USD: { name: "United States dollar", symbol: "$" } },
    idd: { root: "+2", suffixes: ["46"] },
    capital: ["Diego Garcia"],
    altSpellings: ["IO"],
    region: "Africa",
    subregion: "Eastern Africa",
    languages: { eng: "English" },
    translations: {
      ces: {
        official: "Britsk\u00e9 indickooce\u00e1nsk\u00e9 \u00fazem\u00ed",
        common: "Britsk\u00e9 indickooce\u00e1nsk\u00e9 \u00fazem\u00ed",
      },
      cym: {
        official: "Tiriogaeth Brydeinig Cefnfor India",
        common: "Tiriogaeth Brydeinig Cefnfor India",
      },
      deu: {
        official: "Britisches Territorium im Indischen Ozean",
        common: "Britisches Territorium im Indischen Ozean",
      },
      est: {
        official: "Briti India ookeani ala",
        common: "Briti India ookeani ala",
      },
      fin: {
        official: "Brittil\u00e4inen Intian valtameren alue",
        common: "Brittil\u00e4inen Intian valtameren alue",
      },
      fra: {
        official: "Territoire britannique de l' oc\u00e9an Indien",
        common: "Territoire britannique de l'oc\u00e9an Indien",
      },
      hrv: {
        official: "British Indian Ocean Territory",
        common: "Britanski Indijskooceanski teritorij",
      },
      hun: {
        official: "Brit Indiai-\u00f3ce\u00e1ni Ter\u00fclet",
        common: "Brit Indiai-\u00f3ce\u00e1ni Ter\u00fclet",
      },
      ita: {
        official: "Territorio britannico dell'Oceano Indiano",
        common: "Territorio britannico dell'oceano indiano",
      },
      jpn: {
        official:
          "\u30a4\u30ae\u30ea\u30b9\u9818\u30a4\u30f3\u30c9\u6d0b\u5730\u57df",
        common:
          "\u30a4\u30ae\u30ea\u30b9\u9818\u30a4\u30f3\u30c9\u6d0b\u5730\u57df",
      },
      kor: {
        official: "\uc778\ub3c4 \uacf5\ud654\uad6d",
        common: "\uc778\ub3c4",
      },
      nld: {
        official: "Brits Indische Oceaan Territorium",
        common: "Britse Gebieden in de Indische Oceaan",
      },
      per: {
        official:
          "\u0642\u0644\u0645\u0631\u0648 \u0628\u0631\u06cc\u062a\u0627\u0646\u06cc\u0627 \u062f\u0631 \u0627\u0642\u06cc\u0627\u0646\u0648\u0633 \u0647\u0646\u062f",
        common:
          "\u0642\u0644\u0645\u0631\u0648 \u0628\u0631\u06cc\u062a\u0627\u0646\u06cc\u0627 \u062f\u0631 \u0627\u0642\u06cc\u0627\u0646\u0648\u0633 \u0647\u0646\u062f",
      },
      pol: {
        official: "Brytyjskie Terytorium Oceanu Indyjskiego",
        common: "Brytyjskie Terytorium Oceanu Indyjskiego",
      },
      por: {
        official: "British Indian Ocean Territory",
        common: "Territ\u00f3rio Brit\u00e2nico do Oceano \u00cdndico",
      },
      rus: {
        official:
          "\u0411\u0440\u0438\u0442\u0430\u043d\u0441\u043a\u0430\u044f \u0442\u0435\u0440\u0440\u0438\u0442\u043e\u0440\u0438\u044f \u0418\u043d\u0434\u0438\u0439\u0441\u043a\u043e\u0433\u043e \u043e\u043a\u0435\u0430\u043d\u0430",
        common:
          "\u0411\u0440\u0438\u0442\u0430\u043d\u0441\u043a\u0430\u044f \u0442\u0435\u0440\u0440\u0438\u0442\u043e\u0440\u0438\u044f \u0432 \u0418\u043d\u0434\u0438\u0439\u0441\u043a\u043e\u043c \u043e\u043a\u0435\u0430\u043d\u0435",
      },
      slk: {
        official: "Britsk\u00e9 indickooce\u00e1nske \u00fazemie",
        common: "Britsk\u00e9 indickooce\u00e1nske \u00fazemie",
      },
      spa: {
        official: "Territorio Brit\u00e1nico del Oc\u00e9ano \u00cdndico",
        common: "Territorio Brit\u00e1nico del Oc\u00e9ano \u00cdndico",
      },
      swe: {
        official: "Brittiska territoriet i Indiska Oceanen",
        common: "Brittiska territoriet i Indiska Oceanen",
      },
      urd: {
        official:
          "\u0628\u0631\u0637\u0627\u0646\u0648\u06cc \u0628\u062d\u0631\u06c1\u0646\u062f \u062e\u0637\u06c1",
        common:
          "\u0628\u0631\u0637\u0627\u0646\u0648\u06cc \u0628\u062d\u0631\u06c1\u0646\u062f \u062e\u0637\u06c1",
      },
      zho: {
        official: "\u82f1\u5c5e\u5370\u5ea6\u6d0b\u9886\u5730",
        common: "\u82f1\u5c5e\u5370\u5ea6\u6d0b\u9886\u5730",
      },
    },
    latlng: [-6, 71.5],
    landlocked: false,
    borders: [],
    area: 60,
    flag: "\ud83c\uddee\ud83c\uddf4",
    demonyms: { eng: { f: "Indian", m: "Indian" }, fra: { f: "", m: "" } },
    callingCodes: ["+246"],
  },
  {
    name: {
      common: "Ireland",
      official: "Republic of Ireland",
      native: {
        eng: { official: "Republic of Ireland", common: "Ireland" },
        gle: { official: "Poblacht na h\u00c9ireann", common: "\u00c9ire" },
      },
    },
    tld: [".ie"],
    cca2: "IE",
    ccn3: "372",
    cca3: "IRL",
    cioc: "IRL",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { EUR: { name: "Euro", symbol: "\u20ac" } },
    idd: { root: "+3", suffixes: ["53"] },
    capital: ["Dublin"],
    altSpellings: [
      "IE",
      "\u00c9ire",
      "Republic of Ireland",
      "Poblacht na h\u00c9ireann",
    ],
    region: "Europe",
    subregion: "Northern Europe",
    languages: { eng: "English", gle: "Irish" },
    translations: {
      ces: { official: "Irsko", common: "Irsko" },
      deu: { official: "Republik Irland", common: "Irland" },
      est: { official: "Iirimaa", common: "Iirimaa" },
      fin: { official: "Irlannin tasavalta", common: "Irlanti" },
      fra: { official: "R\u00e9publique d'Irlande", common: "Irlande" },
      hrv: { official: "Republika Irska", common: "Irska" },
      hun: {
        official: "\u00cdr K\u00f6zt\u00e1rsas\u00e1g",
        common: "\u00cdrorsz\u00e1g",
      },
      ita: { official: "Repubblica d'Irlanda", common: "Irlanda" },
      jpn: {
        official: "\u30a2\u30a4\u30eb\u30e9\u30f3\u30c9\u5171\u548c\u56fd",
        common: "\u30a2\u30a4\u30eb\u30e9\u30f3\u30c9",
      },
      kor: {
        official: "\uc544\uc77c\ub79c\ub4dc \uacf5\ud654\uad6d",
        common: "\uc544\uc77c\ub79c\ub4dc",
      },
      nld: { official: "Republic of Ireland", common: "Ierland" },
      per: {
        official: "\u0627\u06cc\u0631\u0644\u0646\u062f",
        common: "\u0627\u06cc\u0631\u0644\u0646\u062f",
      },
      pol: { official: "Republika Irlandii", common: "Irlandia" },
      por: { official: "Rep\u00fablica da Irlanda", common: "Irlanda" },
      rus: {
        official: "\u0418\u0440\u043b\u0430\u043d\u0434\u0438\u044f",
        common: "\u0418\u0440\u043b\u0430\u043d\u0434\u0438\u044f",
      },
      slk: { official: "\u00cdrska republika", common: "\u00cdrsko" },
      spa: { official: "Rep\u00fablica de Irlanda", common: "Irlanda" },
      swe: { official: "Irland", common: "Irland" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u062c\u0632\u06cc\u0631\u06c1 \u0622\u0626\u0631\u0644\u06cc\u0646\u0688",
        common:
          "\u062c\u0632\u06cc\u0631\u06c1 \u0622\u0626\u0631\u0644\u06cc\u0646\u0688",
      },
      zho: {
        official: "\u7231\u5c14\u5170\u5171\u548c\u56fd",
        common: "\u7231\u5c14\u5170",
      },
    },
    latlng: [53, -8],
    landlocked: false,
    borders: ["GBR"],
    area: 70273,
    flag: "\ud83c\uddee\ud83c\uddea",
    demonyms: {
      eng: { f: "Irish", m: "Irish" },
      fra: { f: "Irlandaise", m: "Irlandais" },
    },
    callingCodes: ["+353"],
  },
  {
    name: {
      common: "Iran",
      official: "Islamic Republic of Iran",
      native: {
        fas: {
          official:
            "\u062c\u0645\u0647\u0648\u0631\u06cc \u0627\u0633\u0644\u0627\u0645\u06cc \u0627\u06cc\u0631\u0627\u0646",
          common: "\u0627\u06cc\u0631\u0627\u0646",
        },
      },
    },
    tld: [".ir", "\u0627\u06cc\u0631\u0627\u0646."],
    cca2: "IR",
    ccn3: "364",
    cca3: "IRN",
    cioc: "IRI",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { IRR: { name: "Iranian rial", symbol: "\ufdfc" } },
    idd: { root: "+9", suffixes: ["8"] },
    capital: ["Tehran"],
    altSpellings: [
      "IR",
      "Islamic Republic of Iran",
      "Iran, Islamic Republic of",
      "Jomhuri-ye Esl\u0101mi-ye Ir\u0101n",
    ],
    region: "Asia",
    subregion: "Southern Asia",
    languages: { fas: "Persian (Farsi)" },
    translations: {
      ces: {
        official: "Isl\u00e1msk\u00e1 republika \u00cdr\u00e1n",
        common: "\u00cdr\u00e1n",
      },
      deu: { official: "Islamische Republik Iran", common: "Iran" },
      est: { official: "Iraani Islamivabariik", common: "Iraan" },
      fin: { official: "Iranin islamilainen tasavalta", common: "Iran" },
      fra: { official: "R\u00e9publique islamique d'Iran", common: "Iran" },
      hrv: { official: "Islamska Republika Iran", common: "Iran" },
      hun: {
        official: "Ir\u00e1ni Iszl\u00e1m K\u00f6zt\u00e1rsas\u00e1g",
        common: "Ir\u00e1n",
      },
      ita: { official: "Repubblica islamica dell'Iran", common: "Iran" },
      jpn: {
        official:
          "\u30a4\u30e9\u30f3\u00b7\u30a4\u30b9\u30e9\u30e0\u5171\u548c\u56fd",
        common:
          "\u30a4\u30e9\u30f3\u30fb\u30a4\u30b9\u30e9\u30e0\u5171\u548c\u56fd",
      },
      kor: {
        official: "\uc774\ub780 \uc774\uc2ac\ub78c \uacf5\ud654\uad6d",
        common: "\uc774\ub780",
      },
      nld: { official: "Islamitische Republiek Iran", common: "Iran" },
      pol: { official: "Islamska Republika Iranu", common: "Iran" },
      por: {
        official: "Rep\u00fablica Isl\u00e2mica do Ir\u00e3",
        common: "Ir\u00e3o",
      },
      rus: {
        official:
          "\u0418\u0441\u043b\u0430\u043c\u0441\u043a\u0430\u044f \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0418\u0440\u0430\u043d",
        common: "\u0418\u0440\u0430\u043d",
      },
      slk: {
        official: "Ir\u00e1nska islamsk\u00e1 republika",
        common: "Ir\u00e1n",
      },
      spa: {
        official: "Rep\u00fablica Isl\u00e1mica de Ir\u00e1n",
        common: "Iran",
      },
      swe: { official: "Islamiska republiken Iran", common: "Iran" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0627\u06cc\u0631\u0627\u0646",
        common: "\u0627\u06cc\u0631\u0627\u0646",
      },
      zho: {
        official: "\u4f0a\u6717\u4f0a\u65af\u5170\u5171\u548c\u56fd",
        common: "\u4f0a\u6717",
      },
    },
    latlng: [32, 53],
    landlocked: false,
    borders: ["AFG", "ARM", "AZE", "IRQ", "PAK", "TUR", "TKM"],
    area: 1648195,
    flag: "\ud83c\uddee\ud83c\uddf7",
    demonyms: {
      eng: { f: "Iranian", m: "Iranian" },
      fra: { f: "Iranienne", m: "Iranien" },
    },
    callingCodes: ["+98"],
  },
  {
    name: {
      common: "Iraq",
      official: "Republic of Iraq",
      native: {
        ara: {
          official:
            "\u062c\u0645\u0647\u0648\u0631\u064a\u0629 \u0627\u0644\u0639\u0631\u0627\u0642",
          common: "\u0627\u0644\u0639\u0631\u0627\u0642",
        },
        arc: {
          official:
            "\u0729\u0718\u073c\u071b\u0722\u0735\u0710 \u0710\u071d\u073c\u072a\u0732\u0729",
          common: "\u0729\u0718\u073c\u071b\u0722\u0735\u0710",
        },
        ckb: {
          official:
            "\u06a9\u06c6\u0645\u0627\u0631\u06cc \u0639\u06ce\u0631\u0627\u0642",
          common: "\u06a9\u06c6\u0645\u0627\u0631\u06cc",
        },
      },
    },
    tld: [".iq"],
    cca2: "IQ",
    ccn3: "368",
    cca3: "IRQ",
    cioc: "IRQ",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { IQD: { name: "Iraqi dinar", symbol: "\u0639.\u062f" } },
    idd: { root: "+9", suffixes: ["64"] },
    capital: ["Baghdad"],
    altSpellings: [
      "IQ",
      "Republic of Iraq",
      "Jumh\u016briyyat al-\u2018Ir\u0101q",
    ],
    region: "Asia",
    subregion: "Western Asia",
    languages: { ara: "Arabic", arc: "Aramaic", ckb: "Sorani" },
    translations: {
      ces: { official: "Ir\u00e1ck\u00e1 republika", common: "Ir\u00e1k" },
      deu: { official: "Republik Irak", common: "Irak" },
      est: { official: "Iraagi Vabariik", common: "Iraak" },
      fin: { official: "Irakin tasavalta", common: "Irak" },
      fra: { official: "R\u00e9publique d'Irak", common: "Irak" },
      hrv: { official: "Republika Irak", common: "Irak" },
      hun: { official: "Iraki K\u00f6zt\u00e1rsas\u00e1g", common: "Irak" },
      ita: { official: "Repubblica dell'Iraq", common: "Iraq" },
      jpn: {
        official: "\u30a4\u30e9\u30af\u5171\u548c\u56fd",
        common: "\u30a4\u30e9\u30af",
      },
      kor: {
        official: "\uc774\ub77c\ud06c \uacf5\ud654\uad6d",
        common: "\uc774\ub77c\ud06c",
      },
      nld: { official: "Republiek Irak", common: "Irak" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0639\u0631\u0627\u0642",
        common: "\u0639\u0631\u0627\u0642",
      },
      pol: { official: "Republika Iraku", common: "Irak" },
      por: { official: "Rep\u00fablica do Iraque", common: "Iraque" },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0418\u0440\u0430\u043a",
        common: "\u0418\u0440\u0430\u043a",
      },
      slk: { official: "Irack\u00e1 republika", common: "Irak" },
      spa: { official: "Rep\u00fablica de Irak", common: "Irak" },
      swe: { official: "Republiken Irak", common: "Irak" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0639\u0631\u0627\u0642",
        common: "\u0639\u0631\u0627\u0642",
      },
      zho: {
        official: "\u4f0a\u62c9\u514b\u5171\u548c\u56fd",
        common: "\u4f0a\u62c9\u514b",
      },
    },
    latlng: [33, 44],
    landlocked: false,
    borders: ["IRN", "JOR", "KWT", "SAU", "SYR", "TUR"],
    area: 438317,
    flag: "\ud83c\uddee\ud83c\uddf6",
    demonyms: {
      eng: { f: "Iraqi", m: "Iraqi" },
      fra: { f: "Irakienne", m: "Irakien" },
    },
    callingCodes: ["+964"],
  },
  {
    name: {
      common: "Iceland",
      official: "Iceland",
      native: { isl: { official: "\u00cdsland", common: "\u00cdsland" } },
    },
    tld: [".is"],
    cca2: "IS",
    ccn3: "352",
    cca3: "ISL",
    cioc: "ISL",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { ISK: { name: "Icelandic kr\u00f3na", symbol: "kr" } },
    idd: { root: "+3", suffixes: ["54"] },
    capital: ["Reykjavik"],
    altSpellings: [
      "IS",
      "Island",
      "Republic of Iceland",
      "L\u00fd\u00f0veldi\u00f0 \u00cdsland",
    ],
    region: "Europe",
    subregion: "Northern Europe",
    languages: { isl: "Icelandic" },
    translations: {
      ces: { official: "Island", common: "Island" },
      deu: { official: "Island", common: "Island" },
      est: { official: "Islandi Vabariik", common: "Island" },
      fin: { official: "Islanti", common: "Islanti" },
      fra: { official: "R\u00e9publique d'Islande", common: "Islande" },
      hrv: { official: "Island", common: "Island" },
      hun: { official: "Izland", common: "Izland" },
      ita: { official: "Islanda", common: "Islanda" },
      jpn: {
        official: "\u30a2\u30a4\u30b9\u30e9\u30f3\u30c9",
        common: "\u30a2\u30a4\u30b9\u30e9\u30f3\u30c9",
      },
      kor: {
        official: "\uc544\uc774\uc2ac\ub780\ub4dc \uacf5\ud654\uad6d",
        common: "\uc544\uc774\uc2ac\ub780\ub4dc",
      },
      nld: { official: "IJsland", common: "IJsland" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0627\u06cc\u0633\u0644\u0646\u062f",
        common: "\u0627\u06cc\u0633\u0644\u0646\u062f",
      },
      pol: { official: "Republika Islandii", common: "Islandia" },
      por: { official: "Isl\u00e2ndia", common: "Isl\u00e2ndia" },
      rus: {
        official: "\u0418\u0441\u043b\u0430\u043d\u0434\u0438\u044f",
        common: "\u0418\u0441\u043b\u0430\u043d\u0434\u0438\u044f",
      },
      slk: { official: "Islandsk\u00e1 republika", common: "Island" },
      spa: { official: "Islandia", common: "Islandia" },
      swe: { official: "Island", common: "Island" },
      urd: {
        official: "\u0622\u0626\u0633 \u0644\u06cc\u0646\u0688",
        common: "\u0622\u0626\u0633 \u0644\u06cc\u0646\u0688",
      },
      zho: { official: "\u51b0\u5c9b", common: "\u51b0\u5c9b" },
    },
    latlng: [65, -18],
    landlocked: false,
    borders: [],
    area: 103000,
    flag: "\ud83c\uddee\ud83c\uddf8",
    demonyms: {
      eng: { f: "Icelander", m: "Icelander" },
      fra: { f: "Islandaise", m: "Islandais" },
    },
    callingCodes: ["+354"],
  },
  {
    name: {
      common: "Israel",
      official: "State of Israel",
      native: {
        ara: {
          official:
            "\u062f\u0648\u0644\u0629 \u0625\u0633\u0631\u0627\u0626\u064a\u0644",
          common: "\u0625\u0633\u0631\u0627\u0626\u064a\u0644",
        },
        heb: {
          official:
            "\u05de\u05d3\u05d9\u05e0\u05ea \u05d9\u05e9\u05e8\u05d0\u05dc",
          common: "\u05d9\u05e9\u05e8\u05d0\u05dc",
        },
      },
    },
    tld: [".il"],
    cca2: "IL",
    ccn3: "376",
    cca3: "ISR",
    cioc: "ISR",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { ILS: { name: "Israeli new shekel", symbol: "\u20aa" } },
    idd: { root: "+9", suffixes: ["72"] },
    capital: ["Jerusalem"],
    altSpellings: ["IL", "State of Israel", "Med\u012bnat Yisr\u0101'el"],
    region: "Asia",
    subregion: "Western Asia",
    languages: { ara: "Arabic", heb: "Hebrew" },
    translations: {
      ces: { official: "St\u00e1t Izrael", common: "Izrael" },
      deu: { official: "Staat Israel", common: "Israel" },
      est: { official: "Iisraeli Riik", common: "Iisrael" },
      fin: { official: "Israelin valtio", common: "Israel" },
      fra: { official: "\u00c9tat d'Isra\u00ebl", common: "Isra\u00ebl" },
      hrv: { official: "Dr\u017eava Izrael", common: "Izrael" },
      hun: { official: "Izrael", common: "Izrael" },
      ita: { official: "Stato di Israele", common: "Israele" },
      jpn: {
        official: "\u30a4\u30b9\u30e9\u30a8\u30eb\u56fd",
        common: "\u30a4\u30b9\u30e9\u30a8\u30eb",
      },
      kor: {
        official: "\uc774\uc2a4\ub77c\uc5d8\uad6d",
        common: "\uc774\uc2a4\ub77c\uc5d8",
      },
      nld: { official: "Staat Isra\u00ebl", common: "Isra\u00ebl" },
      per: {
        official:
          "\u0641\u0644\u0633\u0637\u064a\u0646 \u0627\u0634\u063a\u0627\u0644\u06cc",
        common:
          "\u0641\u0644\u0633\u0637\u064a\u0646 \u0627\u0634\u063a\u0627\u0644\u06cc",
      },
      pol: { official: "Pa\u0144stwo Izrael", common: "Izrael" },
      por: { official: "Estado de Israel", common: "Israel" },
      rus: {
        official:
          "\u0413\u043e\u0441\u0443\u0434\u0430\u0440\u0441\u0442\u0432\u043e \u0418\u0437\u0440\u0430\u0438\u043b\u044c",
        common: "\u0418\u0437\u0440\u0430\u0438\u043b\u044c",
      },
      slk: { official: "Izraelsk\u00fd \u0161t\u00e1t", common: "Izrael" },
      spa: { official: "Estado de Israel", common: "Israel" },
      swe: { official: "Staten Israel", common: "Israel" },
      urd: {
        official:
          "\u0631\u06cc\u0627\u0633\u062a\u0650 \u0627\u0633\u0631\u0627\u0626\u06cc\u0644",
        common: "\u0627\u0633\u0631\u0627\u0626\u06cc\u0644",
      },
      zho: {
        official: "\u4ee5\u8272\u5217\u56fd",
        common: "\u4ee5\u8272\u5217",
      },
    },
    latlng: [31.47, 35.13],
    landlocked: false,
    borders: ["EGY", "JOR", "LBN", "PSE", "SYR"],
    area: 20770,
    flag: "\ud83c\uddee\ud83c\uddf1",
    demonyms: {
      eng: { f: "Israeli", m: "Israeli" },
      fra: { f: "Isra\u00e9lienne", m: "Isra\u00e9lien" },
    },
    callingCodes: ["+972"],
  },
  {
    name: {
      common: "Italy",
      official: "Italian Republic",
      native: { ita: { official: "Repubblica italiana", common: "Italia" } },
    },
    tld: [".it"],
    cca2: "IT",
    ccn3: "380",
    cca3: "ITA",
    cioc: "ITA",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { EUR: { name: "Euro", symbol: "\u20ac" } },
    idd: { root: "+3", suffixes: ["9"] },
    capital: ["Rome"],
    altSpellings: ["IT", "Italian Republic", "Repubblica italiana"],
    region: "Europe",
    subregion: "Southern Europe",
    languages: { ita: "Italian" },
    translations: {
      ces: { official: "Italsk\u00e1 republika", common: "It\u00e1lie" },
      deu: { official: "Italienische Republik", common: "Italien" },
      est: { official: "Itaalia Vabariik", common: "Itaalia" },
      fin: { official: "Italian tasavalta", common: "Italia" },
      fra: { official: "R\u00e9publique italienne", common: "Italie" },
      hrv: { official: "talijanska Republika", common: "Italija" },
      hun: {
        official: "Olasz K\u00f6zt\u00e1rsas\u00e1g",
        common: "Olaszorsz\u00e1g",
      },
      ita: { official: "Repubblica italiana", common: "Italia" },
      jpn: {
        official: "\u30a4\u30bf\u30ea\u30a2\u5171\u548c\u56fd",
        common: "\u30a4\u30bf\u30ea\u30a2",
      },
      kor: {
        official: "\uc774\ud0c8\ub9ac\uc544 \uacf5\ud654\uad6d",
        common: "\uc774\ud0c8\ub9ac\uc544",
      },
      nld: { official: "Italiaanse Republiek", common: "Itali\u00eb" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0627\u06cc\u062a\u0627\u0644\u06cc\u0627",
        common: "\u0627\u06cc\u062a\u0627\u0644\u06cc\u0627",
      },
      pol: { official: "Republika W\u0142oska", common: "W\u0142ochy" },
      por: { official: "Rep\u00fablica Italiana", common: "It\u00e1lia" },
      rus: {
        official:
          "\u0438\u0442\u0430\u043b\u044c\u044f\u043d\u0441\u043a\u0430\u044f \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430",
        common: "\u0418\u0442\u0430\u043b\u0438\u044f",
      },
      slk: { official: "Talianska republika", common: "Taliansko" },
      spa: { official: "Rep\u00fablica Italiana", common: "Italia" },
      swe: { official: "Republiken Italien", common: "Italien" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0627\u0637\u0627\u0644\u06cc\u06c1",
        common: "\u0627\u0637\u0627\u0644\u06cc\u06c1",
      },
      zho: {
        official: "\u610f\u5927\u5229\u5171\u548c\u56fd",
        common: "\u610f\u5927\u5229",
      },
    },
    latlng: [42.83333333, 12.83333333],
    landlocked: false,
    borders: ["AUT", "FRA", "SMR", "SVN", "CHE", "VAT"],
    area: 301336,
    flag: "\ud83c\uddee\ud83c\uddf9",
    demonyms: {
      eng: { f: "Italian", m: "Italian" },
      fra: { f: "Italienne", m: "Italien" },
    },
    callingCodes: ["+39"],
  },
  {
    name: {
      common: "Jamaica",
      official: "Jamaica",
      native: {
        eng: { official: "Jamaica", common: "Jamaica" },
        jam: { official: "Jamaica", common: "Jamaica" },
      },
    },
    tld: [".jm"],
    cca2: "JM",
    ccn3: "388",
    cca3: "JAM",
    cioc: "JAM",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { JMD: { name: "Jamaican dollar", symbol: "$" } },
    idd: { root: "+1", suffixes: ["876"] },
    capital: ["Kingston"],
    altSpellings: ["JM"],
    region: "Americas",
    subregion: "Caribbean",
    languages: { eng: "English", jam: "Jamaican Patois" },
    translations: {
      ces: { official: "Jamajka", common: "Jamajka" },
      deu: { official: "Jamaika", common: "Jamaika" },
      est: { official: "Jamaica", common: "Jamaica" },
      fin: { official: "Jamaika", common: "Jamaika" },
      fra: { official: "Jama\u00efque", common: "Jama\u00efque" },
      hrv: { official: "Jamajka", common: "Jamajka" },
      hun: { official: "Jamaica", common: "Jamaica" },
      ita: { official: "Giamaica", common: "Giamaica" },
      jpn: {
        official: "\u30b8\u30e3\u30de\u30a4\u30ab",
        common: "\u30b8\u30e3\u30de\u30a4\u30ab",
      },
      kor: {
        official: "\uc790\uba54\uc774\uce74",
        common: "\uc790\uba54\uc774\uce74",
      },
      nld: { official: "Jamaica", common: "Jamaica" },
      per: {
        official: "\u062c\u0627\u0645\u0627\u0626\u06cc\u06a9\u0627",
        common: "\u062c\u0627\u0645\u0627\u0626\u06cc\u06a9\u0627",
      },
      pol: { official: "Jamajka", common: "Jamajka" },
      por: { official: "Jamaica", common: "Jamaica" },
      rus: {
        official: "\u042f\u043c\u0430\u0439\u043a\u0430",
        common: "\u042f\u043c\u0430\u0439\u043a\u0430",
      },
      slk: { official: "Jamajka", common: "Jamajka" },
      spa: { official: "Jamaica", common: "Jamaica" },
      swe: { official: "Jamaica", common: "Jamaica" },
      urd: {
        official: "\u062c\u0645\u06cc\u06a9\u0627",
        common: "\u062c\u0645\u06cc\u06a9\u0627",
      },
      zho: { official: "\u7259\u4e70\u52a0", common: "\u7259\u4e70\u52a0" },
    },
    latlng: [18.25, -77.5],
    landlocked: false,
    borders: [],
    area: 10991,
    flag: "\ud83c\uddef\ud83c\uddf2",
    demonyms: {
      eng: { f: "Jamaican", m: "Jamaican" },
      fra: { f: "Jama\u00efcaine", m: "Jama\u00efcain" },
    },
    callingCodes: ["+1876"],
  },
  {
    name: {
      common: "Jersey",
      official: "Bailiwick of Jersey",
      native: {
        eng: { official: "Bailiwick of Jersey", common: "Jersey" },
        fra: { official: "Bailliage de Jersey", common: "Jersey" },
        nrf: { official: "Bailliage d\u00e9 J\u00e8rri", common: "J\u00e8rri" },
      },
    },
    tld: [".je"],
    cca2: "JE",
    ccn3: "832",
    cca3: "JEY",
    cioc: "",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: {
      GBP: { name: "British pound", symbol: "\u00a3" },
      JEP: { name: "Jersey pound", symbol: "\u00a3" },
    },
    idd: { root: "+4", suffixes: ["4"] },
    capital: ["Saint Helier"],
    altSpellings: [
      "JE",
      "Bailiwick of Jersey",
      "Bailliage de Jersey",
      "Bailliage d\u00e9 J\u00e8rri",
    ],
    region: "Europe",
    subregion: "Northern Europe",
    languages: { eng: "English", fra: "French", nrf: "J\u00e8rriais" },
    translations: {
      ces: { official: "Rycht\u00e1\u0159stv\u00ed Jersey", common: "Jersey" },
      deu: { official: "Vogtei Jersey", common: "Jersey" },
      est: { official: "Jersey foogtkond", common: "Jersey" },
      fin: { official: "Jersey", common: "Jersey" },
      fra: { official: "Bailliage de Jersey", common: "Jersey" },
      hrv: { official: "Struka od Jersey", common: "Jersey" },
      hun: { official: "Jersey", common: "Jersey" },
      ita: { official: "Baliato di Jersey", common: "Isola di Jersey" },
      jpn: {
        official: "\u30b8\u30e3\u30fc\u30b8\u306e\u5f97\u610f\u5206\u91ce",
        common: "\u30b8\u30e3\u30fc\u30b8\u30fc",
      },
      kor: { official: "\uc800\uc9c0 \uc12c", common: "\uc800\uc9c0 \uc12c" },
      nld: { official: "Baljuwschap Jersey", common: "Jersey" },
      per: {
        official: "\u062c\u0631\u0632\u06cc",
        common: "\u062c\u0631\u0632\u06cc",
      },
      pol: { official: "Jersey", common: "Jersey" },
      por: { official: "Bailiado de Jersey", common: "Jersey" },
      rus: {
        official:
          "\u041a\u043e\u0440\u043e\u043d\u043d\u043e\u0435 \u0432\u043b\u0430\u0434\u0435\u043d\u0438\u0435 \u0414\u0436\u0435\u0440\u0441\u0438",
        common: "\u0414\u0436\u0435\u0440\u0441\u0438",
      },
      slk: { official: "Bailiwick Jersey", common: "Jersey" },
      spa: { official: "Bail\u00eda de Jersey", common: "Jersey" },
      swe: { official: "Jersey", common: "Jersey" },
      urd: {
        official: "\u062c\u0631\u0632\u06cc",
        common: "\u062c\u0631\u0632\u06cc",
      },
      zho: { official: "\u6cfd\u897f\u5c9b", common: "\u6cfd\u897f\u5c9b" },
    },
    latlng: [49.25, -2.16666666],
    landlocked: false,
    borders: [],
    area: 116,
    flag: "\ud83c\uddef\ud83c\uddea",
    demonyms: {
      eng: { f: "Channel Islander", m: "Channel Islander" },
      fra: { f: "Jersiaise", m: "Jersiais" },
    },
    callingCodes: ["+44"],
  },
  {
    name: {
      common: "Jordan",
      official: "Hashemite Kingdom of Jordan",
      native: {
        ara: {
          official:
            "\u0627\u0644\u0645\u0645\u0644\u0643\u0629 \u0627\u0644\u0623\u0631\u062f\u0646\u064a\u0629 \u0627\u0644\u0647\u0627\u0634\u0645\u064a\u0629",
          common: "\u0627\u0644\u0623\u0631\u062f\u0646",
        },
      },
    },
    tld: [".jo", "\u0627\u0644\u0627\u0631\u062f\u0646."],
    cca2: "JO",
    ccn3: "400",
    cca3: "JOR",
    cioc: "JOR",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { JOD: { name: "Jordanian dinar", symbol: "\u062f.\u0627" } },
    idd: { root: "+9", suffixes: ["62"] },
    capital: ["Amman"],
    altSpellings: [
      "JO",
      "Hashemite Kingdom of Jordan",
      "al-Mamlakah al-Urdun\u012byah al-H\u0101shim\u012byah",
    ],
    region: "Asia",
    subregion: "Western Asia",
    languages: { ara: "Arabic" },
    translations: {
      ces: {
        official:
          "Jord\u00e1nsk\u00e9 h\u00e1\u0161imovsk\u00e9 kr\u00e1lovstv\u00ed",
        common: "Jord\u00e1nsko",
      },
      deu: {
        official: "Haschemitisches K\u00f6nigreich Jordanien",
        common: "Jordanien",
      },
      est: {
        official: "Jordaania Ha\u0161imiidi Kuningriik",
        common: "Jordaania",
      },
      fin: {
        official: "Jordanian ha\u0161emiittinen kunigaskunta",
        common: "Jordania",
      },
      fra: {
        official: "Royaume hach\u00e9mite de Jordanie",
        common: "Jordanie",
      },
      hrv: { official: "Ha\u0161emitske Kraljevine Jordan", common: "Jordan" },
      hun: { official: "Jord\u00e1nia", common: "Jord\u00e1nia" },
      ita: { official: "Regno hascemita di Giordania", common: "Giordania" },
      jpn: {
        official:
          "\u30e8\u30eb\u30c0\u30f3\u00b7\u30cf\u30b7\u30df\u30c6\u738b\u56fd",
        common: "\u30e8\u30eb\u30c0\u30f3",
      },
      kor: {
        official: "\uc694\ub974\ub2e8 \ud558\uc2ec \uc655\uad6d",
        common: "\uc694\ub974\ub2e8",
      },
      nld: {
        official: "Hasjemitisch Koninkrijk Jordani\u00eb",
        common: "Jordani\u00eb",
      },
      per: {
        official:
          "\u067e\u0627\u062f\u0634\u0627\u0647\u06cc \u0627\u064f\u0631\u062f\u064f\u0646 \u0647\u0627\u0634\u0645\u06cc",
        common: "\u0627\u0631\u062f\u0646",
      },
      pol: {
        official: "Jorda\u0144skie Kr\u00f3lestwo Haszymidzkie",
        common: "Jordania",
      },
      por: {
        official: "Reino Hachemita da Jord\u00e2nia",
        common: "Jord\u00e2nia",
      },
      rus: {
        official:
          "\u0418\u043e\u0440\u0434\u0430\u043d\u0441\u043a\u043e\u0433\u043e \u0425\u0430\u0448\u0438\u043c\u0438\u0442\u0441\u043a\u043e\u0433\u043e \u041a\u043e\u0440\u043e\u043b\u0435\u0432\u0441\u0442\u0432\u0430",
        common: "\u0418\u043e\u0440\u0434\u0430\u043d\u0438\u044f",
      },
      slk: {
        official:
          "Jord\u00e1nske h\u00e1\u0161imovsk\u00e9 kr\u00e1\u013eovstvo",
        common: "Jord\u00e1nsko",
      },
      spa: { official: "Reino Hachemita de Jordania", common: "Jordania" },
      swe: {
        official: "Hashimitiska kungad\u00f6met Jordanien",
        common: "Jordanien",
      },
      urd: {
        official:
          "\u06be\u0627\u0634\u0645\u06cc \u0645\u0645\u0644\u06a9\u062a\u0650 \u0627\u0631\u062f\u0646",
        common: "\u0627\u0631\u062f\u0646",
      },
      zho: {
        official: "\u7ea6\u65e6\u54c8\u5e0c\u59c6\u738b\u56fd",
        common: "\u7ea6\u65e6",
      },
    },
    latlng: [31, 36],
    landlocked: false,
    borders: ["IRQ", "ISR", "PSE", "SAU", "SYR"],
    area: 89342,
    flag: "\ud83c\uddef\ud83c\uddf4",
    demonyms: {
      eng: { f: "Jordanian", m: "Jordanian" },
      fra: { f: "Jordanienne", m: "Jordanien" },
    },
    callingCodes: ["+962"],
  },
  {
    name: {
      common: "Japan",
      official: "Japan",
      native: { jpn: { official: "\u65e5\u672c", common: "\u65e5\u672c" } },
    },
    tld: [".jp", ".\u307f\u3093\u306a"],
    cca2: "JP",
    ccn3: "392",
    cca3: "JPN",
    cioc: "JPN",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { JPY: { name: "Japanese yen", symbol: "\u00a5" } },
    idd: { root: "+8", suffixes: ["1"] },
    capital: ["Tokyo"],
    altSpellings: ["JP", "Nippon", "Nihon"],
    region: "Asia",
    subregion: "Eastern Asia",
    languages: { jpn: "Japanese" },
    translations: {
      ces: { official: "Japonsko", common: "Japonsko" },
      deu: { official: "Japan", common: "Japan" },
      est: { official: "Jaapan", common: "Jaapan" },
      fin: { official: "Japani", common: "Japani" },
      fra: { official: "Japon", common: "Japon" },
      hrv: { official: "Japan", common: "Japan" },
      hun: { official: "Jap\u00e1n", common: "Jap\u00e1n" },
      ita: { official: "Giappone", common: "Giappone" },
      jpn: { official: "\u65e5\u672c", common: "\u65e5\u672c" },
      kor: { official: "\uc77c\ubcf8\uad6d", common: "\uc77c\ubcf8" },
      nld: { official: "Japan", common: "Japan" },
      per: {
        official: "\u0698\u0627\u067e\u0646",
        common: "\u0698\u0627\u067e\u0646",
      },
      pol: { official: "Japonia", common: "Japonia" },
      por: { official: "Jap\u00e3o", common: "Jap\u00e3o" },
      rus: {
        official: "\u042f\u043f\u043e\u043d\u0438\u044f",
        common: "\u042f\u043f\u043e\u043d\u0438\u044f",
      },
      slk: { official: "Japonsko", common: "Japonsko" },
      spa: { official: "Jap\u00f3n", common: "Jap\u00f3n" },
      swe: { official: "Japan", common: "Japan" },
      urd: {
        official: "\u062c\u0627\u067e\u0627\u0646",
        common: "\u062c\u0627\u067e\u0627\u0646",
      },
      zho: { official: "\u65e5\u672c\u56fd", common: "\u65e5\u672c" },
    },
    latlng: [36, 138],
    landlocked: false,
    borders: [],
    area: 377930,
    flag: "\ud83c\uddef\ud83c\uddf5",
    demonyms: {
      eng: { f: "Japanese", m: "Japanese" },
      fra: { f: "Japonaise", m: "Japonais" },
    },
    callingCodes: ["+81"],
  },
  {
    name: {
      common: "Kazakhstan",
      official: "Republic of Kazakhstan",
      native: {
        kaz: {
          official:
            "\u049a\u0430\u0437\u0430\u049b\u0441\u0442\u0430\u043d \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430\u0441\u044b",
          common: "\u049a\u0430\u0437\u0430\u049b\u0441\u0442\u0430\u043d",
        },
        rus: {
          official:
            "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u041a\u0430\u0437\u0430\u0445\u0441\u0442\u0430\u043d",
          common: "\u041a\u0430\u0437\u0430\u0445\u0441\u0442\u0430\u043d",
        },
      },
    },
    tld: [".kz", ".\u049b\u0430\u0437"],
    cca2: "KZ",
    ccn3: "398",
    cca3: "KAZ",
    cioc: "KAZ",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { KZT: { name: "Kazakhstani tenge", symbol: "\u20b8" } },
    idd: { root: "+7", suffixes: ["6", "7"] },
    capital: ["Nur-Sultan"],
    altSpellings: [
      "KZ",
      "Qazaqstan",
      "\u041a\u0430\u0437\u0430\u0445\u0441\u0442\u0430\u043d",
      "Republic of Kazakhstan",
      "\u049a\u0430\u0437\u0430\u049b\u0441\u0442\u0430\u043d \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430\u0441\u044b",
      "Qazaqstan Respubl\u00efkas\u0131",
      "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u041a\u0430\u0437\u0430\u0445\u0441\u0442\u0430\u043d",
      "Respublika Kazakhstan",
    ],
    region: "Asia",
    subregion: "Central Asia",
    languages: { kaz: "Kazakh", rus: "Russian" },
    translations: {
      ces: { official: "Republika Kazachst\u00e1n", common: "Kazachst\u00e1n" },
      deu: { official: "Republik Kasachstan", common: "Kasachstan" },
      est: { official: "Kasahstani Vabariik", common: "Kasahstan" },
      fin: { official: "Kazakstanin tasavalta", common: "Kazakstan" },
      fra: { official: "R\u00e9publique du Kazakhstan", common: "Kazakhstan" },
      hrv: { official: "Republika Kazahstan", common: "Kazahstan" },
      hun: {
        official: "Kazah K\u00f6zt\u00e1rsas\u00e1g",
        common: "Kazahszt\u00e1n",
      },
      ita: { official: "Repubblica del Kazakhstan", common: "Kazakistan" },
      jpn: {
        official: "\u30ab\u30b6\u30d5\u30b9\u30bf\u30f3\u5171\u548c\u56fd",
        common: "\u30ab\u30b6\u30d5\u30b9\u30bf\u30f3",
      },
      kor: {
        official: "\uce74\uc790\ud750\uc2a4\ud0c4 \uacf5\ud654\uad6d",
        common: "\uce74\uc790\ud750\uc2a4\ud0c4",
      },
      nld: { official: "Republiek Kazachstan", common: "Kazachstan" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0642\u0632\u0627\u0642\u0633\u062a\u0627\u0646",
        common: "\u0642\u0632\u0627\u0642\u0633\u062a\u0627\u0646",
      },
      pol: { official: "Republika Kazachstanu", common: "Kazachstan" },
      por: {
        official: "Rep\u00fablica do Cazaquist\u00e3o",
        common: "Cazaquist\u00e3o",
      },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u041a\u0430\u0437\u0430\u0445\u0441\u0442\u0430\u043d",
        common: "\u041a\u0430\u0437\u0430\u0445\u0441\u0442\u0430\u043d",
      },
      slk: { official: "Kaza\u0161sk\u00e1 republika", common: "Kazachstan" },
      spa: {
        official: "Rep\u00fablica de Kazajst\u00e1n",
        common: "Kazajist\u00e1n",
      },
      swe: { official: "Republiken Kazakstan", common: "Kazakstan" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0642\u0627\u0632\u0642\u0633\u062a\u0627\u0646",
        common: "\u0642\u0627\u0632\u0642\u0633\u062a\u0627\u0646",
      },
      zho: {
        official: "\u54c8\u8428\u514b\u65af\u5766\u5171\u548c\u56fd",
        common: "\u54c8\u8428\u514b\u65af\u5766",
      },
    },
    latlng: [48, 68],
    landlocked: true,
    borders: ["CHN", "KGZ", "RUS", "TKM", "UZB"],
    area: 2724900,
    flag: "\ud83c\uddf0\ud83c\uddff",
    demonyms: {
      eng: { f: "Kazakhstani", m: "Kazakhstani" },
      fra: { f: "Kazakhstanaise", m: "Kazakhstanais" },
    },
    callingCodes: ["+76", "+77"],
  },
  {
    name: {
      common: "Kenya",
      official: "Republic of Kenya",
      native: {
        eng: { official: "Republic of Kenya", common: "Kenya" },
        swa: { official: "Republic of Kenya", common: "Kenya" },
      },
    },
    tld: [".ke"],
    cca2: "KE",
    ccn3: "404",
    cca3: "KEN",
    cioc: "KEN",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { KES: { name: "Kenyan shilling", symbol: "Sh" } },
    idd: { root: "+2", suffixes: ["54"] },
    capital: ["Nairobi"],
    altSpellings: ["KE", "Republic of Kenya", "Jamhuri ya Kenya"],
    region: "Africa",
    subregion: "Eastern Africa",
    languages: { eng: "English", swa: "Swahili" },
    translations: {
      ces: { official: "Ke\u0148sk\u00e1 republika", common: "Ke\u0148a" },
      deu: { official: "Republik Kenia", common: "Kenia" },
      est: { official: "Keenia Vabariik", common: "Keenia" },
      fin: { official: "Kenian tasavalta", common: "Kenia" },
      fra: { official: "R\u00e9publique du Kenya", common: "Kenya" },
      hrv: { official: "Republika Kenija", common: "Kenija" },
      hun: { official: "Kenyai K\u00f6zt\u00e1rsas\u00e1g", common: "Kenya" },
      ita: { official: "Repubblica del Kenya", common: "Kenya" },
      jpn: {
        official: "\u30b1\u30cb\u30a2\u5171\u548c\u56fd",
        common: "\u30b1\u30cb\u30a2",
      },
      kor: {
        official: "\ucf00\ub0d0 \uacf5\ud654\uad6d",
        common: "\ucf00\ub0d0",
      },
      nld: { official: "Republiek Kenia", common: "Kenia" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u06a9\u0646\u06cc\u0627",
        common: "\u06a9\u0646\u06cc\u0627",
      },
      pol: { official: "Republika Kenii", common: "Kenia" },
      por: { official: "Rep\u00fablica do Qu\u00e9nia", common: "Qu\u00e9nia" },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u041a\u0435\u043d\u0438\u044f",
        common: "\u041a\u0435\u043d\u0438\u044f",
      },
      slk: { official: "Kensk\u00e1 republika", common: "Ke\u0148a" },
      spa: { official: "Rep\u00fablica de Kenya", common: "Kenia" },
      swe: { official: "Republiken Kenya", common: "Kenya" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u06a9\u06cc\u0646\u06cc\u0627",
        common: "\u06a9\u06cc\u0646\u06cc\u0627",
      },
      zho: {
        official: "\u80af\u5c3c\u4e9a\u5171\u548c\u56fd",
        common: "\u80af\u5c3c\u4e9a",
      },
    },
    latlng: [1, 38],
    landlocked: false,
    borders: ["ETH", "SOM", "SSD", "TZA", "UGA"],
    area: 580367,
    flag: "\ud83c\uddf0\ud83c\uddea",
    demonyms: {
      eng: { f: "Kenyan", m: "Kenyan" },
      fra: { f: "K\u00e9nyane", m: "K\u00e9nyan" },
    },
    callingCodes: ["+254"],
  },
  {
    name: {
      common: "Kyrgyzstan",
      official: "Kyrgyz Republic",
      native: {
        kir: {
          official:
            "\u041a\u044b\u0440\u0433\u044b\u0437 \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430\u0441\u044b",
          common:
            "\u041a\u044b\u0440\u0433\u044b\u0437\u0441\u0442\u0430\u043d",
        },
        rus: {
          official:
            "\u041a\u044b\u0440\u0433\u044b\u0437\u0441\u043a\u0430\u044f \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430",
          common: "\u041a\u0438\u0440\u0433\u0438\u0437\u0438\u044f",
        },
      },
    },
    tld: [".kg"],
    cca2: "KG",
    ccn3: "417",
    cca3: "KGZ",
    cioc: "KGZ",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { KGS: { name: "Kyrgyzstani som", symbol: "\u0441" } },
    idd: { root: "+9", suffixes: ["96"] },
    capital: ["Bishkek"],
    altSpellings: [
      "KG",
      "\u041a\u0438\u0440\u0433\u0438\u0437\u0438\u044f",
      "Kyrgyz Republic",
      "\u041a\u044b\u0440\u0433\u044b\u0437 \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430\u0441\u044b",
      "Kyrgyz Respublikasy",
    ],
    region: "Asia",
    subregion: "Central Asia",
    languages: { kir: "Kyrgyz", rus: "Russian" },
    translations: {
      ces: { official: "Kyrgyzsk\u00e1 republika", common: "Kyrgyzst\u00e1n" },
      deu: { official: "Kirgisische Republik", common: "Kirgisistan" },
      est: { official: "Kirgiisi Vabariik", common: "K\u00f5rg\u00f5zstan" },
      fin: { official: "Kirgisian tasavalta", common: "Kirgisia" },
      fra: { official: "R\u00e9publique kirghize", common: "Kirghizistan" },
      hrv: { official: "Kirgistanu", common: "Kirgistan" },
      hun: {
        official: "Kirgiz K\u00f6zt\u00e1rsas\u00e1g",
        common: "Kirgiziszt\u00e1n",
      },
      ita: { official: "Kirghizistan", common: "Kirghizistan" },
      jpn: {
        official: "\u30ad\u30eb\u30ae\u30b9\u5171\u548c\u56fd",
        common: "\u30ad\u30eb\u30ae\u30b9",
      },
      kor: {
        official: "\ud0a4\ub974\uae30\uc2a4 \uacf5\ud654\uad6d",
        common: "\ud0a4\ub974\uae30\uc2a4\uc2a4\ud0c4",
      },
      nld: { official: "Kirgizische Republiek", common: "Kirgizi\u00eb" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0642\u0650\u0631\u0642\u06cc\u0632\u0633\u062a\u0627\u0646",
        common: "\u0642\u0631\u0642\u06cc\u0632\u0633\u062a\u0627\u0646",
      },
      pol: { official: "Republika Kirgiska", common: "Kirgistan" },
      por: {
        official: "Rep\u00fablica do Quirguist\u00e3o",
        common: "Quirguist\u00e3o",
      },
      rus: {
        official:
          "\u041a\u044b\u0440\u0433\u044b\u0437\u0441\u043a\u0430\u044f \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430",
        common: "\u041a\u0438\u0440\u0433\u0438\u0437\u0438\u044f",
      },
      slk: { official: "Kirgizsk\u00e1 republika", common: "Kirgizsko" },
      spa: { official: "Rep\u00fablica Kirguisa", common: "Kirguizist\u00e1n" },
      swe: { official: "Republiken Kirgizistan", common: "Kirgizistan" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u06a9\u0631\u063a\u06cc\u0632\u0633\u062a\u0627\u0646",
        common: "\u06a9\u0631\u063a\u06cc\u0632\u0633\u062a\u0627\u0646",
      },
      zho: {
        official: "\u5409\u5c14\u5409\u65af\u65af\u5766\u5171\u548c\u56fd",
        common: "\u5409\u5c14\u5409\u65af\u65af\u5766",
      },
    },
    latlng: [41, 75],
    landlocked: true,
    borders: ["CHN", "KAZ", "TJK", "UZB"],
    area: 199951,
    flag: "\ud83c\uddf0\ud83c\uddec",
    demonyms: {
      eng: { f: "Kirghiz", m: "Kirghiz" },
      fra: { f: "Kirghize", m: "Kirghize" },
    },
    callingCodes: ["+996"],
  },
  {
    name: {
      common: "Cambodia",
      official: "Kingdom of Cambodia",
      native: {
        khm: {
          official:
            "\u1796\u17d2\u179a\u17c7\u179a\u17b6\u1787\u17b6\u178e\u17b6\u1785\u1780\u17d2\u179a\u1780\u1798\u17d2\u1796\u17bb\u1787\u17b6",
          common: "K\u00e2mp\u016dch\u00e9a",
        },
      },
    },
    tld: [".kh"],
    cca2: "KH",
    ccn3: "116",
    cca3: "KHM",
    cioc: "CAM",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: {
      KHR: { name: "Cambodian riel", symbol: "\u17db" },
      USD: { name: "United States dollar", symbol: "$" },
    },
    idd: { root: "+8", suffixes: ["55"] },
    capital: ["Phnom Penh"],
    altSpellings: ["KH", "Kingdom of Cambodia"],
    region: "Asia",
    subregion: "South-Eastern Asia",
    languages: { khm: "Khmer" },
    translations: {
      ces: {
        official: "Kambod\u017esk\u00e9 kr\u00e1lovstv\u00ed",
        common: "Kambod\u017ea",
      },
      cym: { official: "Teyrnas Cambodia", common: "Cambodia" },
      deu: { official: "K\u00f6nigreich Kambodscha", common: "Kambodscha" },
      est: { official: "Kambod\u017ea Kuningriik", common: "Kambod\u017ea" },
      fin: {
        official: "Kambod\u017ean kuningaskunta",
        common: "Kambod\u017ea",
      },
      fra: { official: "Royaume du Cambodge", common: "Cambodge" },
      hrv: { official: "Kraljevina Kambod\u017ea", common: "Kambod\u017ea" },
      hun: { official: "Kambodzsai Kir\u00e1lys\u00e1g", common: "Kambodzsa" },
      ita: { official: "Regno di Cambogia", common: "Cambogia" },
      jpn: {
        official: "\u30ab\u30f3\u30dc\u30b8\u30a2\u738b\u56fd",
        common: "\u30ab\u30f3\u30dc\u30b8\u30a2",
      },
      kor: {
        official: "\uce84\ubcf4\ub514\uc544 \uc655\uad6d",
        common: "\uce84\ubcf4\ub514\uc544",
      },
      nld: { official: "Koninkrijk Cambodja", common: "Cambodja" },
      per: {
        official:
          "\u067e\u0627\u062f\u0634\u0627\u0647\u06cc \u06a9\u0627\u0645\u0628\u0648\u062c",
        common: "\u06a9\u0627\u0645\u0628\u0648\u062c",
      },
      pol: {
        official: "Kr\u00f3lestwo Kambod\u017cy",
        common: "Kambod\u017ca",
      },
      por: { official: "Reino do Camboja", common: "Camboja" },
      rus: {
        official:
          "\u041a\u043e\u0440\u043e\u043b\u0435\u0432\u0441\u0442\u0432\u043e \u041a\u0430\u043c\u0431\u043e\u0434\u0436\u0430",
        common: "\u041a\u0430\u043c\u0431\u043e\u0434\u0436\u0430",
      },
      slk: {
        official: "Kambo\u01c6sk\u00e9 kr\u00e1\u013eovstvo",
        common: "Kambo\u01c6a",
      },
      spa: { official: "Reino de Camboya", common: "Camboya" },
      swe: { official: "Konungariket Kambodja", common: "Kambodja" },
      urd: {
        official:
          "\u0645\u0645\u0644\u06a9\u062a\u0650 \u06a9\u0645\u0628\u0648\u0688\u06cc\u0627",
        common: "\u06a9\u0645\u0628\u0648\u0688\u06cc\u0627",
      },
      zho: {
        official: "\u67ec\u57d4\u5be8\u738b\u56fd",
        common: "\u67ec\u57d4\u5be8",
      },
    },
    latlng: [13, 105],
    landlocked: false,
    borders: ["LAO", "THA", "VNM"],
    area: 181035,
    flag: "\ud83c\uddf0\ud83c\udded",
    demonyms: {
      eng: { f: "Cambodian", m: "Cambodian" },
      fra: { f: "Cambodgienne", m: "Cambodgien" },
    },
    callingCodes: ["+855"],
  },
  {
    name: {
      common: "Kiribati",
      official: "Independent and Sovereign Republic of Kiribati",
      native: {
        eng: {
          official: "Independent and Sovereign Republic of Kiribati",
          common: "Kiribati",
        },
        gil: { official: "Ribaberiki Kiribati", common: "Kiribati" },
      },
    },
    tld: [".ki"],
    cca2: "KI",
    ccn3: "296",
    cca3: "KIR",
    cioc: "KIR",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: {
      AUD: { name: "Australian dollar", symbol: "$" },
      KID: { name: "Kiribati dollar", symbol: "$" },
    },
    idd: { root: "+6", suffixes: ["86"] },
    capital: ["South Tarawa"],
    altSpellings: ["KI", "Republic of Kiribati", "Ribaberiki Kiribati"],
    region: "Oceania",
    subregion: "Micronesia",
    languages: { eng: "English", gil: "Gilbertese" },
    translations: {
      ces: { official: "Republika Kiribati", common: "Kiribati" },
      deu: { official: "Republik Kiribati", common: "Kiribati" },
      est: { official: "Kiribati Vabariik", common: "Kiribati" },
      fin: { official: "Kiribatin tasavalta", common: "Kiribati" },
      fra: { official: "R\u00e9publique de Kiribati", common: "Kiribati" },
      hrv: {
        official: "Samostalne i suverene Republike Kiribati",
        common: "Kiribati",
      },
      hun: {
        official: "Kiribati K\u00f6zt\u00e1rsas\u00e1g",
        common: "Kiribati",
      },
      ita: {
        official: "Repubblica indipendente e sovrano di Kiribati",
        common: "Kiribati",
      },
      jpn: {
        official:
          "\u30ad\u30ea\u30d0\u30b9\u306e\u72ec\u7acb\u3068\u4e3b\u6a29\u5171\u548c\u56fd",
        common: "\u30ad\u30ea\u30d0\u30b9",
      },
      kor: {
        official: "\ud0a4\ub9ac\ubc14\uc2dc \uacf5\ud654\uad6d",
        common: "\ud0a4\ub9ac\ubc14\uc2dc",
      },
      nld: {
        official: "Onafhankelijke en soevereine republiek Kiribati",
        common: "Kiribati",
      },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u06a9\u06cc\u0631\u06cc\u0628\u0627\u062a\u06cc",
        common: "\u06a9\u06cc\u0631\u06cc\u0628\u0627\u062a\u06cc",
      },
      pol: { official: "Republika Kiribati", common: "Kiribati" },
      por: {
        official: "Independente e soberano Rep\u00fablica de Kiribati",
        common: "Kiribati",
      },
      rus: {
        official:
          "\u041d\u0435\u0437\u0430\u0432\u0438\u0441\u0438\u043c\u043e\u0439 \u0438 \u0441\u0443\u0432\u0435\u0440\u0435\u043d\u043d\u043e\u0439 \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0438 \u041a\u0438\u0440\u0438\u0431\u0430\u0442\u0438",
        common: "\u041a\u0438\u0440\u0438\u0431\u0430\u0442\u0438",
      },
      slk: { official: "Kiribatsk\u00e1 republika", common: "Kiribati" },
      spa: {
        official: "Rep\u00fablica Independiente y Soberano de Kiribati",
        common: "Kiribati",
      },
      swe: { official: "Republiken Kiribati", common: "Kiribati" },
      urd: {
        official:
          "\u0633\u0644\u0637\u0646\u062a \u0622\u0632\u0627\u062f \u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u06a9\u06cc\u0631\u06cc\u0628\u0627\u062a\u06cc",
        common: "\u06a9\u06cc\u0631\u06cc\u0628\u0627\u062a\u06cc",
      },
      zho: {
        official: "\u57fa\u91cc\u5df4\u65af\u5171\u548c\u56fd",
        common: "\u57fa\u91cc\u5df4\u65af",
      },
    },
    latlng: [1.41666666, 173],
    landlocked: false,
    borders: [],
    area: 811,
    flag: "\ud83c\uddf0\ud83c\uddee",
    demonyms: {
      eng: { f: "I-Kiribati", m: "I-Kiribati" },
      fra: { f: "Kiribatienne", m: "Kiribatien" },
    },
    callingCodes: ["+686"],
  },
  {
    name: {
      common: "Saint Kitts and Nevis",
      official: "Federation of Saint Christopher and Nevis",
      native: {
        eng: {
          official: "Federation of Saint Christopher and Nevis",
          common: "Saint Kitts and Nevis",
        },
      },
    },
    tld: [".kn"],
    cca2: "KN",
    ccn3: "659",
    cca3: "KNA",
    cioc: "SKN",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { XCD: { name: "Eastern Caribbean dollar", symbol: "$" } },
    idd: { root: "+1", suffixes: ["869"] },
    capital: ["Basseterre"],
    altSpellings: ["KN", "Federation of Saint Christopher and Nevis"],
    region: "Americas",
    subregion: "Caribbean",
    languages: { eng: "English" },
    translations: {
      ces: {
        official: "Federace Sv. Kry\u0161tof a Nevis",
        common: "Svat\u00fd Kry\u0161tof a Nevis",
      },
      deu: {
        official: "F\u00f6deration von St. Kitts und Nevis",
        common: "St. Kitts und Nevis",
      },
      est: {
        official: "Saint Kittsi ja Nevise F\u00f6deratsioon",
        common: "Saint Kitts ja Nevis",
      },
      fin: {
        official: "Saint Christopherin ja Nevisin federaatio",
        common: "Saint Kitts ja Nevis",
      },
      fra: {
        official:
          "F\u00e9d\u00e9ration de Saint-Christophe-et-Ni\u00e9v\u00e8s",
        common: "Saint-Christophe-et-Ni\u00e9v\u00e8s",
      },
      hrv: {
        official: "Federacija Sv.Kristofora i Nevisa",
        common: "Sveti Kristof i Nevis",
      },
      hun: {
        official:
          "Saint Christopher \u00e9s Nevis \u00c1llamsz\u00f6vets\u00e9g",
        common: "Saint Kitts \u00e9s Nevis",
      },
      ita: {
        official: "Federazione di Saint Christopher e Nevis",
        common: "Saint Kitts e Nevis",
      },
      jpn: {
        official:
          "\u30bb\u30f3\u30c8\u30af\u30ea\u30b9\u30c8\u30d5\u30a1\u30fcNevis\u9023\u76df",
        common:
          "\u30bb\u30f3\u30c8\u30af\u30ea\u30b9\u30c8\u30d5\u30a1\u30fc\u30fb\u30cd\u30a4\u30d3\u30b9",
      },
      kor: {
        official:
          "\uc138\uc778\ud2b8\ud0a4\uce20 \ub124\ube44\uc2a4 \uc5f0\ubc29",
        common: "\uc138\uc778\ud2b8\ud0a4\uce20 \ub124\ube44\uc2a4",
      },
      nld: {
        official: "Federatie van Saint Kitts en Nevis",
        common: "Saint Kitts en Nevis",
      },
      per: {
        official:
          "\u0641\u062f\u0631\u0627\u0633\u06cc\u0648\u0646 \u0633\u0646\u062a \u06a9\u06cc\u062a\u0633 \u0648 \u0646\u0648\u06cc\u0633",
        common:
          "\u0633\u0646\u062a \u06a9\u06cc\u062a\u0633 \u0648 \u0646\u0648\u06cc\u0633",
      },
      pol: {
        official: "Federacja Saint Kitts i Nevis",
        common: "Saint Kitts i Nevis",
      },
      por: {
        official: "Federa\u00e7\u00e3o de S\u00e3o Crist\u00f3v\u00e3o e Nevis",
        common: "S\u00e3o Crist\u00f3v\u00e3o e Nevis",
      },
      rus: {
        official:
          "\u0424\u0435\u0434\u0435\u0440\u0430\u0446\u0438\u044f \u0421\u0435\u043d\u0442-\u041a\u0440\u0438\u0441\u0442\u043e\u0444\u0435\u0440 \u0438 \u041d \u0435 \u0432 \u0438 \u0441",
        common:
          "\u0421\u0435\u043d\u0442-\u041a\u0438\u0442\u0441 \u0438 \u041d\u0435\u0432\u0438\u0441",
      },
      slk: {
        official: "Feder\u0ee1cia Sv\u00e4t\u00e9ho Kri\u0161tofa a Nevisu",
        common: "Sv\u00e4t\u00fd Kri\u0161tof a Nevis",
      },
      spa: {
        official: "Federaci\u00f3n de San Crist\u00f3bal y Nevis",
        common: "San Crist\u00f3bal y Nieves",
      },
      swe: {
        official: "Federationen Saint Kitts och Nevis",
        common: "Saint Kitts och Nevis",
      },
      urd: {
        official:
          "\u0648\u0641\u0627\u0642\u0650 \u0633\u06cc\u0646\u0679 \u06a9\u06cc\u0679\u0632 \u0648 \u0646\u0627\u0648\u06cc\u0633",
        common:
          "\u0633\u06cc\u0646\u0679 \u06a9\u06cc\u0679\u0632 \u0648 \u0646\u0627\u0648\u06cc\u0633",
      },
      zho: {
        official:
          "\u5723\u514b\u91cc\u65af\u6258\u5f17\u548c\u5c3c\u7ef4\u65af\u8054\u90a6",
        common: "\u5723\u57fa\u8328\u548c\u5c3c\u7ef4\u65af",
      },
    },
    latlng: [17.33333333, -62.75],
    landlocked: false,
    borders: [],
    area: 261,
    flag: "\ud83c\uddf0\ud83c\uddf3",
    demonyms: {
      eng: { f: "Kittitian or Nevisian", m: "Kittitian or Nevisian" },
      fra: { f: "Kittitienne-et-nevicienne", m: "Kittitien-et-nevicien" },
    },
    callingCodes: ["+1869"],
  },
  {
    name: {
      common: "South Korea",
      official: "Republic of Korea",
      native: {
        kor: { official: "\ub300\ud55c\ubbfc\uad6d", common: "\ud55c\uad6d" },
      },
    },
    tld: [".kr", ".\ud55c\uad6d"],
    cca2: "KR",
    ccn3: "410",
    cca3: "KOR",
    cioc: "KOR",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { KRW: { name: "South Korean won", symbol: "\u20a9" } },
    idd: { root: "+8", suffixes: ["2"] },
    capital: ["Seoul"],
    altSpellings: [
      "KR",
      "Korea, Republic of",
      "Republic of Korea",
      "\ub0a8\ud55c",
      "\ub0a8\uc870\uc120",
    ],
    region: "Asia",
    subregion: "Eastern Asia",
    languages: { kor: "Korean" },
    translations: {
      ces: {
        official: "Korejsk\u00e1 republika",
        common: "Ji\u017en\u00ed Korea",
      },
      deu: { official: "Republik Korea", common: "S\u00fcdkorea" },
      est: { official: "Korea Vabariik", common: "L\u00f5una-Korea" },
      fin: { official: "Korean tasavalta", common: "Etel\u00e4-Korea" },
      fra: {
        official: "R\u00e9publique de Cor\u00e9e",
        common: "Cor\u00e9e du Sud",
      },
      hrv: { official: "Republika Koreja", common: "Ju\u017ena Koreja" },
      hun: {
        official: "Koreai K\u00f6zt\u00e1rsas\u00e1g",
        common: "D\u00e9l-Korea",
      },
      ita: { official: "Repubblica di Corea", common: "Corea del Sud" },
      jpn: { official: "\u5927\u97d3\u6c11\u56fd", common: "\u97d3\u56fd" },
      kor: { official: "\ub300\ud55c\ubbfc\uad6d", common: "\ud55c\uad6d" },
      nld: { official: "Republiek Korea", common: "Zuid-Korea" },
      per: {
        official: "\u062c\u0645\u0647\u0648\u0631\u06cc \u06a9\u0631\u0647",
        common: "\u06a9\u0631\u0647\u0654 \u062c\u0646\u0648\u0628\u06cc",
      },
      pol: { official: "Republika Korei", common: "Korea Po\u0142udniowa" },
      por: { official: "Rep\u00fablica da Coreia", common: "Coreia do Sul" },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u041a\u043e\u0440\u0435\u044f",
        common: "\u042e\u0436\u043d\u0430\u044f \u041a\u043e\u0440\u0435\u044f",
      },
      slk: {
        official: "K\u00f3rejsk\u00e1 republika",
        common: "Ju\u017en\u00e1 K\u00f3rea",
      },
      spa: { official: "Rep\u00fablica de Corea", common: "Corea del Sur" },
      swe: { official: "Republiken Korea", common: "Sydkorea" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u06a9\u0648\u0631\u06cc\u0627 ",
        common: "\u062c\u0646\u0648\u0628\u06cc \u06a9\u0648\u0631\u06cc\u0627",
      },
      zho: { official: "\u5927\u97e9\u6c11\u56fd", common: "\u97e9\u56fd" },
    },
    latlng: [37, 127.5],
    landlocked: false,
    borders: ["PRK"],
    area: 100210,
    flag: "\ud83c\uddf0\ud83c\uddf7",
    demonyms: {
      eng: { f: "South Korean", m: "South Korean" },
      fra: { f: "Sud-cor\u00e9enne", m: "Sud-cor\u00e9en" },
    },
    callingCodes: ["+82"],
  },
  {
    name: {
      common: "Kosovo",
      official: "Republic of Kosovo",
      native: {
        sqi: { official: "Republika e Kosov\u00ebs", common: "Kosova" },
        srp: {
          official:
            "\u0420\u0435\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u041a\u043e\u0441\u043e\u0432\u043e",
          common: "\u041a\u043e\u0441\u043e\u0432\u043e",
        },
      },
    },
    tld: [],
    cca2: "XK",
    ccn3: "",
    cca3: "UNK",
    cioc: "KOS",
    independent: null,
    status: "user-assigned",
    unMember: false,
    currencies: { EUR: { name: "Euro", symbol: "\u20ac" } },
    idd: { root: "+3", suffixes: ["83"] },
    capital: ["Pristina"],
    altSpellings: [
      "XK",
      "\u0420\u0435\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u041a\u043e\u0441\u043e\u0432\u043e",
    ],
    region: "Europe",
    subregion: "Southeast Europe",
    languages: { sqi: "Albanian", srp: "Serbian" },
    translations: {
      ces: { official: "Kosovsk\u00e1 republika", common: "Kosovo" },
      deu: { official: "Republik Kosovo", common: "Kosovo" },
      est: { official: "Kosovo Vabariik", common: "Kosovo" },
      fin: { official: "Kosovon tasavalta", common: "Kosovo" },
      fra: { official: "R\u00e9publique du Kosovo", common: "Kosovo" },
      hrv: { official: "Republika Kosovo", common: "Kosovo" },
      hun: { official: "Koszov\u00f3", common: "Koszov\u00f3" },
      ita: { official: "Repubblica del Kosovo", common: "Kosovo" },
      kor: {
        official: "\ucf54\uc18c\ubcf4 \uacf5\ud654\uad6d",
        common: "\ucf54\uc18c\ubcf4",
      },
      nld: { official: "Republiek Kosovo", common: "Kosovo" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u06a9\u0648\u0632\u0648\u0648",
        common: "\u06a9\u0648\u0632\u0648\u0648",
      },
      pol: { official: "Republika Kosowa", common: "Kosowo" },
      por: { official: "Rep\u00fablica do Kosovo", common: "Kosovo" },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u041a\u043e\u0441\u043e\u0432\u043e",
        common:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u041a\u043e\u0441\u043e\u0432\u043e",
      },
      slk: { official: "Republika Kosovo", common: "Kosovo" },
      spa: { official: "Rep\u00fablica de Kosovo", common: "Kosovo" },
      swe: { official: "Republiken Kosovo", common: "Kosovo" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u06a9\u0648\u0633\u0648\u0648\u06c1",
        common: "\u06a9\u0648\u0633\u0648\u0648\u06c1",
      },
      zho: {
        official: "\u79d1\u7d22\u6c83\u5171\u548c\u56fd",
        common: "\u79d1\u7d22\u6c83",
      },
    },
    latlng: [42.666667, 21.166667],
    landlocked: true,
    borders: ["ALB", "MKD", "MNE", "SRB"],
    area: 10908,
    flag: "\ud83c\uddfd\ud83c\uddf0",
    demonyms: {
      eng: { f: "Kosovar", m: "Kosovar" },
      fra: { f: "Kosovare", m: "Kosovar" },
    },
    callingCodes: ["+383"],
  },
  {
    name: {
      common: "Kuwait",
      official: "State of Kuwait",
      native: {
        ara: {
          official:
            "\u062f\u0648\u0644\u0629 \u0627\u0644\u0643\u0648\u064a\u062a",
          common: "\u0627\u0644\u0643\u0648\u064a\u062a",
        },
      },
    },
    tld: [".kw"],
    cca2: "KW",
    ccn3: "414",
    cca3: "KWT",
    cioc: "KUW",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { KWD: { name: "Kuwaiti dinar", symbol: "\u062f.\u0643" } },
    idd: { root: "+9", suffixes: ["65"] },
    capital: ["Kuwait City"],
    altSpellings: ["KW", "State of Kuwait", "Dawlat al-Kuwait"],
    region: "Asia",
    subregion: "Western Asia",
    languages: { ara: "Arabic" },
    translations: {
      ces: { official: "St\u00e1t Kuvajt", common: "Kuvajt" },
      deu: { official: "Staat Kuwait", common: "Kuwait" },
      est: { official: "Kuveidi Riik", common: "Kuveit" },
      fin: { official: "Kuwaitin valtio", common: "Kuwait" },
      fra: { official: "\u00c9tat du Kowe\u00eft", common: "Kowe\u00eft" },
      hrv: { official: "Dr\u017eava Kuvajt", common: "Kuvajt" },
      hun: { official: "Kuvaiti \u00c1llam", common: "Kuvait" },
      ita: { official: "Stato del Kuwait", common: "Kuwait" },
      jpn: {
        official: "\u30af\u30a6\u30a7\u30fc\u30c8\u56fd",
        common: "\u30af\u30a6\u30a7\u30fc\u30c8",
      },
      kor: {
        official: "\ucfe0\uc6e8\uc774\ud2b8\uad6d",
        common: "\ucfe0\uc6e8\uc774\ud2b8",
      },
      nld: { official: "Staat Koeweit", common: "Koeweit" },
      per: {
        official: "\u062f\u0648\u0644\u062a \u06a9\u0648\u06cc\u062a",
        common: "\u06a9\u064f\u0648\u06cc\u062a",
      },
      pol: { official: "Pa\u0144stwo Kuwejt", common: "Kuwejt" },
      por: { official: "Estado do Kuwait", common: "Kuwait" },
      rus: {
        official:
          "\u0413\u043e\u0441\u0443\u0434\u0430\u0440\u0441\u0442\u0432\u043e \u041a\u0443\u0432\u0435\u0439\u0442",
        common: "\u041a\u0443\u0432\u0435\u0439\u0442",
      },
      slk: { official: "Kuvajtsk\u00fd \u0161t\u00e1t", common: "Kuvajt" },
      spa: { official: "Estado de Kuwait", common: "Kuwait" },
      swe: { official: "Staten Kuwait", common: "Kuwait" },
      urd: {
        official: "\u062f\u0648\u0644\u062a\u0650 \u06a9\u0648\u06cc\u062a",
        common: "\u06a9\u0648\u06cc\u062a",
      },
      zho: {
        official: "\u79d1\u5a01\u7279\u56fd",
        common: "\u79d1\u5a01\u7279",
      },
    },
    latlng: [29.5, 45.75],
    landlocked: false,
    borders: ["IRQ", "SAU"],
    area: 17818,
    flag: "\ud83c\uddf0\ud83c\uddfc",
    demonyms: {
      eng: { f: "Kuwaiti", m: "Kuwaiti" },
      fra: { f: "Kowe\u00eftienne", m: "Kowe\u00eftien" },
    },
    callingCodes: ["+965"],
  },
  {
    name: {
      common: "Laos",
      official: "Lao People's Democratic Republic",
      native: {
        lao: {
          official:
            "\u0eaa\u0eb2\u0e97\u0eb2\u0ea5\u0eb0\u0e99\u0eb0 \u0e8a\u0eb2\u0e97\u0eb4\u0e9b\u0eb0\u0ec4\u0e95 \u0e84\u0ebb\u0e99\u0ea5\u0eb2\u0ea7 \u0e82\u0ead\u0e87",
          common: "\u0eaa\u0e9b\u0e9b\u0ea5\u0eb2\u0ea7",
        },
      },
    },
    tld: [".la"],
    cca2: "LA",
    ccn3: "418",
    cca3: "LAO",
    cioc: "LAO",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { LAK: { name: "Lao kip", symbol: "\u20ad" } },
    idd: { root: "+8", suffixes: ["56"] },
    capital: ["Vientiane"],
    altSpellings: [
      "LA",
      "Lao",
      "Lao People's Democratic Republic",
      "Sathalanalat Paxathipatai Paxaxon Lao",
    ],
    region: "Asia",
    subregion: "South-Eastern Asia",
    languages: { lao: "Lao" },
    translations: {
      ces: {
        official: "Laosk\u00e1 lidov\u011b demokratick\u00e1 republika",
        common: "Laos",
      },
      deu: { official: "Demokratische Volksrepublik Laos", common: "Laos" },
      est: { official: "Laose Demokraatlik Rahvavabariik", common: "Laos" },
      fin: {
        official: "Laosin demokraattinen kansantasavalta",
        common: "Laos",
      },
      fra: {
        official: "R\u00e9publique d\u00e9mocratique populaire lao",
        common: "Laos",
      },
      hrv: { official: "Narodna Demokratska Republika", common: "Laos" },
      hun: {
        official: "Laoszi N\u00e9pi Demokratikus K\u00f6zt\u00e1rsas\u00e1g",
        common: "Laosz",
      },
      ita: {
        official: "Repubblica democratica popolare del Laos",
        common: "Laos",
      },
      jpn: {
        official:
          "\u30e9\u30aa\u30b9\u4eba\u6c11\u6c11\u4e3b\u5171\u548c\u56fd",
        common: "\u30e9\u30aa\u30b9\u4eba\u6c11\u6c11\u4e3b\u5171\u548c\u56fd",
      },
      kor: {
        official: "\ub77c\uc624 \uc778\ubbfc \ubbfc\uc8fc \uacf5\ud654\uad6d",
        common: "\ub77c\uc624\uc2a4",
      },
      nld: { official: "Lao Democratische Volksrepubliek", common: "Laos" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u062f\u0645\u0648\u06a9\u0631\u0627\u062a\u06cc\u06a9 \u062e\u0644\u0642 \u0644\u0627\u0626\u0648\u0633",
        common: "\u0644\u0627\u0626\u0648\u0633",
      },
      pol: {
        official: "Laota\u0144ska Republika Ludowo-Demokratyczna",
        common: "Laos",
      },
      por: {
        official: "Laos, Rep\u00fablica Democr\u00e1tica",
        common: "Laos",
      },
      rus: {
        official:
          "\u041b\u0430\u043e\u0441\u0441\u043a\u0430\u044f \u041d\u0430\u0440\u043e\u0434\u043d\u043e-\u0414\u0435\u043c\u043e\u043a\u0440\u0430\u0442\u0438\u0447\u0435\u0441\u043a\u0430\u044f \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430",
        common: "\u041b\u0430\u043e\u0441",
      },
      slk: {
        official: "Laosk\u00e1 \u013eudovodemokratick\u00e1 republika",
        common: "Laos",
      },
      spa: {
        official: "Rep\u00fablica Democr\u00e1tica Popular Lao",
        common: "Laos",
      },
      swe: { official: "Demokratiska folkrepubliken Laos", common: "Laos" },
      urd: {
        official:
          "\u0639\u0648\u0627\u0645\u06cc \u062c\u0645\u06c1\u0648\u0631\u06cc \u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0644\u0627\u0624",
        common: "\u0644\u0627\u0624\u0633",
      },
      zho: {
        official: "\u8001\u631d\u4eba\u6c11\u6c11\u4e3b\u5171\u548c\u56fd",
        common: "\u8001\u631d",
      },
    },
    latlng: [18, 105],
    landlocked: true,
    borders: ["MMR", "KHM", "CHN", "THA", "VNM"],
    area: 236800,
    flag: "\ud83c\uddf1\ud83c\udde6",
    demonyms: {
      eng: { f: "Laotian", m: "Laotian" },
      fra: { f: "Laotienne", m: "Laotien" },
    },
    callingCodes: ["+856"],
  },
  {
    name: {
      common: "Lebanon",
      official: "Lebanese Republic",
      native: {
        ara: {
          official:
            "\u0627\u0644\u062c\u0645\u0647\u0648\u0631\u064a\u0629 \u0627\u0644\u0644\u0628\u0646\u0627\u0646\u064a\u0629",
          common: "\u0644\u0628\u0646\u0627\u0646",
        },
        fra: { official: "R\u00e9publique libanaise", common: "Liban" },
      },
    },
    tld: [".lb"],
    cca2: "LB",
    ccn3: "422",
    cca3: "LBN",
    cioc: "LIB",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { LBP: { name: "Lebanese pound", symbol: "\u0644.\u0644" } },
    idd: { root: "+9", suffixes: ["61"] },
    capital: ["Beirut"],
    altSpellings: [
      "LB",
      "Lebanese Republic",
      "Al-Jumh\u016br\u012byah Al-Libn\u0101n\u012byah",
    ],
    region: "Asia",
    subregion: "Western Asia",
    languages: { ara: "Arabic", fra: "French" },
    translations: {
      ces: { official: "Libanonsk\u00e1 republika", common: "Libanon" },
      deu: { official: "Libanesische Republik", common: "Libanon" },
      est: { official: "Liibanoni Vabariik", common: "Liibanon" },
      fin: { official: "Libanonin tasavalta", common: "Libanon" },
      fra: { official: "R\u00e9publique libanaise", common: "Liban" },
      hrv: { official: "Libanonska Republika", common: "Libanon" },
      hun: {
        official: "Libanoni K\u00f6zt\u00e1rsas\u00e1g",
        common: "Libanon",
      },
      ita: { official: "Repubblica libanese", common: "Libano" },
      jpn: {
        official: "\u30ec\u30d0\u30ce\u30f3\u5171\u548c\u56fd",
        common: "\u30ec\u30d0\u30ce\u30f3",
      },
      kor: {
        official: "\ub808\ubc14\ub17c \uacf5\ud654\uad6d",
        common: "\ub808\ubc14\ub17c",
      },
      nld: { official: "Libanese Republiek", common: "Libanon" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0644\u0628\u0646\u0627\u0646",
        common: "\u0644\u0628\u0646\u0627\u0646",
      },
      pol: { official: "Republika Liba\u0144ska", common: "Liban" },
      por: { official: "Rep\u00fablica Libanesa", common: "L\u00edbano" },
      rus: {
        official:
          "\u041b\u0438\u0432\u0430\u043d\u0441\u043a\u0430\u044f \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430",
        common: "\u041b\u0438\u0432\u0430\u043d",
      },
      slk: { official: "Libanonsk\u00e1 republika", common: "Libanon" },
      spa: { official: "Rep\u00fablica Libanesa", common: "L\u00edbano" },
      swe: { official: "Republiken Libanon", common: "Libanon" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0644\u0628\u0646\u0627\u0646",
        common: "\u0644\u0628\u0646\u0627\u0646",
      },
      zho: {
        official: "\u9ece\u5df4\u5ae9\u5171\u548c\u56fd",
        common: "\u9ece\u5df4\u5ae9",
      },
    },
    latlng: [33.83333333, 35.83333333],
    landlocked: false,
    borders: ["ISR", "SYR"],
    area: 10452,
    flag: "\ud83c\uddf1\ud83c\udde7",
    demonyms: {
      eng: { f: "Lebanese", m: "Lebanese" },
      fra: { f: "Libanaise", m: "Libanais" },
    },
    callingCodes: ["+961"],
  },
  {
    name: {
      common: "Liberia",
      official: "Republic of Liberia",
      native: { eng: { official: "Republic of Liberia", common: "Liberia" } },
    },
    tld: [".lr"],
    cca2: "LR",
    ccn3: "430",
    cca3: "LBR",
    cioc: "LBR",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { LRD: { name: "Liberian dollar", symbol: "$" } },
    idd: { root: "+2", suffixes: ["31"] },
    capital: ["Monrovia"],
    altSpellings: ["LR", "Republic of Liberia"],
    region: "Africa",
    subregion: "Western Africa",
    languages: { eng: "English" },
    translations: {
      ces: { official: "Liberijsk\u00e1 republika", common: "Lib\u00e9rie" },
      deu: { official: "Republik Liberia", common: "Liberia" },
      est: { official: "Libeeria Vabariik", common: "Libeeria" },
      fin: { official: "Liberian tasavalta", common: "Liberia" },
      fra: { official: "R\u00e9publique du Lib\u00e9ria", common: "Liberia" },
      hrv: { official: "Republika Liberija", common: "Liberija" },
      hun: {
        official: "Lib\u00e9riai K\u00f6zt\u00e1rsas\u00e1g",
        common: "Lib\u00e9ria",
      },
      ita: { official: "Repubblica di Liberia", common: "Liberia" },
      jpn: {
        official: "\u30ea\u30d9\u30ea\u30a2\u5171\u548c\u56fd",
        common: "\u30ea\u30d9\u30ea\u30a2",
      },
      kor: {
        official: "\ub77c\uc774\ubca0\ub9ac\uc544 \uacf5\ud654\uad6d",
        common: "\ub77c\uc774\ubca0\ub9ac\uc544",
      },
      nld: { official: "Republiek Liberia", common: "Liberia" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0644\u06cc\u0628\u0631\u06cc\u0627",
        common: "\u0644\u06cc\u0628\u0640\u0650\u0631\u06cc\u0627",
      },
      pol: { official: "Republika Liberii", common: "Liberia" },
      por: {
        official: "Rep\u00fablica da Lib\u00e9ria",
        common: "Lib\u00e9ria",
      },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u041b\u0438\u0431\u0435\u0440\u0438\u044f",
        common: "\u041b\u0438\u0431\u0435\u0440\u0438\u044f",
      },
      slk: {
        official: "Lib\u00e9rijsk\u00e1 republika",
        common: "Lib\u00e9ria",
      },
      spa: { official: "Rep\u00fablica de Liberia", common: "Liberia" },
      swe: { official: "Republiken Liberia", common: "Liberia" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0644\u0627\u0626\u0628\u06cc\u0631\u06cc\u0627",
        common: "\u0644\u0627\u0626\u0628\u06cc\u0631\u06cc\u0627",
      },
      zho: {
        official: "\u5229\u6bd4\u91cc\u4e9a\u5171\u548c\u56fd",
        common: "\u5229\u6bd4\u91cc\u4e9a",
      },
    },
    latlng: [6.5, -9.5],
    landlocked: false,
    borders: ["GIN", "CIV", "SLE"],
    area: 111369,
    flag: "\ud83c\uddf1\ud83c\uddf7",
    demonyms: {
      eng: { f: "Liberian", m: "Liberian" },
      fra: { f: "Lib\u00e9rienne", m: "Lib\u00e9rien" },
    },
    callingCodes: ["+231"],
  },
  {
    name: {
      common: "Libya",
      official: "State of Libya",
      native: {
        ara: {
          official:
            "\u0627\u0644\u062f\u0648\u0644\u0629 \u0644\u064a\u0628\u064a\u0627",
          common: "\u200f\u0644\u064a\u0628\u064a\u0627",
        },
      },
    },
    tld: [".ly"],
    cca2: "LY",
    ccn3: "434",
    cca3: "LBY",
    cioc: "LBA",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { LYD: { name: "Libyan dinar", symbol: "\u0644.\u062f" } },
    idd: { root: "+2", suffixes: ["18"] },
    capital: ["Tripoli"],
    altSpellings: ["LY", "State of Libya", "Dawlat Libya"],
    region: "Africa",
    subregion: "Northern Africa",
    languages: { ara: "Arabic" },
    translations: {
      ces: { official: "St\u00e1t Libye", common: "Libye" },
      deu: { official: "Staat Libyen", common: "Libyen" },
      est: { official: "Liib\u00fca", common: "Liib\u00fca" },
      fin: { official: "Libyan valtio", common: "Libya" },
      fra: {
        official:
          "Grande R\u00e9publique arabe libyenne populaire et socialiste",
        common: "Libye",
      },
      hrv: { official: "Dr\u017eava Libiji", common: "Libija" },
      hun: { official: "L\u00edbia \u00c1llam", common: "L\u00edbia" },
      ita: { official: "Stato della Libia", common: "Libia" },
      jpn: {
        official: "\u30ea\u30d3\u30a2\u306e\u56fd\u5bb6",
        common: "\u30ea\u30d3\u30a2",
      },
      kor: { official: "\ub9ac\ube44\uc544", common: "\ub9ac\ube44\uc544" },
      nld: { official: "Staat van Libi\u00eb", common: "Libi\u00eb" },
      per: {
        official: "\u062f\u0648\u0644\u062a \u0644\u06cc\u0628\u06cc",
        common: "\u0644\u06cc\u0628\u06cc",
      },
      pol: { official: "Pa\u0144stwo Libia", common: "Libia" },
      por: { official: "Estado da L\u00edbia", common: "L\u00edbia" },
      rus: {
        official:
          "\u0413\u043e\u0441\u0443\u0434\u0430\u0440\u0441\u0442\u0432\u043e \u041b\u0438\u0432\u0438\u0438",
        common: "\u041b\u0438\u0432\u0438\u044f",
      },
      slk: { official: "L\u00edbya", common: "L\u00edbya" },
      spa: { official: "Estado de Libia", common: "Libia" },
      swe: { official: "Staten Libyen", common: "Libyen" },
      urd: {
        official:
          "\u0631\u06cc\u0627\u0633\u062a\u0650 \u0644\u06cc\u0628\u06cc\u0627",
        common: "\u0644\u06cc\u0628\u06cc\u0627",
      },
      zho: {
        official: "\u5229\u6bd4\u4e9a\u56fd",
        common: "\u5229\u6bd4\u4e9a",
      },
    },
    latlng: [25, 17],
    landlocked: false,
    borders: ["DZA", "TCD", "EGY", "NER", "SDN", "TUN"],
    area: 1759540,
    flag: "\ud83c\uddf1\ud83c\uddfe",
    demonyms: {
      eng: { f: "Libyan", m: "Libyan" },
      fra: { f: "Libyenne", m: "Libyen" },
    },
    callingCodes: ["+218"],
  },
  {
    name: {
      common: "Saint Lucia",
      official: "Saint Lucia",
      native: { eng: { official: "Saint Lucia", common: "Saint Lucia" } },
    },
    tld: [".lc"],
    cca2: "LC",
    ccn3: "662",
    cca3: "LCA",
    cioc: "LCA",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { XCD: { name: "Eastern Caribbean dollar", symbol: "$" } },
    idd: { root: "+1", suffixes: ["758"] },
    capital: ["Castries"],
    altSpellings: ["LC"],
    region: "Americas",
    subregion: "Caribbean",
    languages: { eng: "English" },
    translations: {
      ces: { official: "Svat\u00e1 Lucie", common: "Svat\u00e1 Lucie" },
      deu: { official: "St. Lucia", common: "St. Lucia" },
      est: { official: "Saint Lucia", common: "Saint Lucia" },
      fin: { official: "Saint Lucia", common: "Saint Lucia" },
      fra: { official: "Sainte-Lucie", common: "Sainte-Lucie" },
      hrv: { official: "Sveta Lucija", common: "Sveta Lucija" },
      hun: { official: "Saint Lucia", common: "Saint Lucia" },
      ita: { official: "Santa Lucia", common: "Santa Lucia" },
      jpn: {
        official: "\u30bb\u30f3\u30c8\u30eb\u30b7\u30a2",
        common: "\u30bb\u30f3\u30c8\u30eb\u30b7\u30a2",
      },
      kor: {
        official: "\uc138\uc778\ud2b8\ub8e8\uc2dc\uc544",
        common: "\uc138\uc778\ud2b8\ub8e8\uc2dc\uc544",
      },
      nld: { official: "Saint Lucia", common: "Saint Lucia" },
      per: {
        official: "\u0633\u0646\u062a \u0644\u0648\u0633\u06cc\u0627",
        common: "\u0633\u0646\u062a \u0644\u0648\u0633\u06cc\u0627",
      },
      pol: { official: "Saint Lucia", common: "Saint Lucia" },
      por: { official: "Santa L\u00facia", common: "Santa L\u00facia" },
      rus: {
        official: "\u0421\u0435\u043d\u0442-\u041b\u044e\u0441\u0438\u044f",
        common: "\u0421\u0435\u043d\u0442-\u041b\u044e\u0441\u0438\u044f",
      },
      slk: {
        official: "Sv\u00e4t\u00e1 Lucia",
        common: "Sv\u00e4t\u00e1 Lucia",
      },
      spa: { official: "Santa Luc\u00eda", common: "Santa Luc\u00eda" },
      swe: { official: "Saint Lucia", common: "Saint Lucia" },
      urd: {
        official: "\u0633\u06cc\u0646\u0679 \u0644\u0648\u0633\u06cc\u0627",
        common: "\u0633\u06cc\u0646\u0679 \u0644\u0648\u0633\u06cc\u0627",
      },
      zho: {
        official: "\u5723\u5362\u897f\u4e9a",
        common: "\u5723\u5362\u897f\u4e9a",
      },
    },
    latlng: [13.88333333, -60.96666666],
    landlocked: false,
    borders: [],
    area: 616,
    flag: "\ud83c\uddf1\ud83c\udde8",
    demonyms: {
      eng: { f: "Saint Lucian", m: "Saint Lucian" },
      fra: { f: "Saint-Lucienne", m: "Saint-Lucien" },
    },
    callingCodes: ["+1758"],
  },
  {
    name: {
      common: "Liechtenstein",
      official: "Principality of Liechtenstein",
      native: {
        deu: {
          official: "F\u00fcrstentum Liechtenstein",
          common: "Liechtenstein",
        },
      },
    },
    tld: [".li"],
    cca2: "LI",
    ccn3: "438",
    cca3: "LIE",
    cioc: "LIE",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { CHF: { name: "Swiss franc", symbol: "Fr" } },
    idd: { root: "+4", suffixes: ["23"] },
    capital: ["Vaduz"],
    altSpellings: [
      "LI",
      "Principality of Liechtenstein",
      "F\u00fcrstentum Liechtenstein",
    ],
    region: "Europe",
    subregion: "Western Europe",
    languages: { deu: "German" },
    translations: {
      ces: {
        official: "Kn\u00ed\u017eectv\u00ed Lichten\u0161tejnsk\u00e9",
        common: "Lichten\u0161tejnsko",
      },
      deu: {
        official: "F\u00fcrstentum Liechtenstein",
        common: "Liechtenstein",
      },
      est: {
        official: "Liechtensteini V\u00fcrstiriik",
        common: "Liechtenstein",
      },
      fin: { official: "Liechensteinin ruhtinaskunta", common: "Liechenstein" },
      fra: {
        official: "Principaut\u00e9 du Liechtenstein",
        common: "Liechtenstein",
      },
      hrv: {
        official: "Kne\u017eevina Lihten\u0161tajn",
        common: "Lihten\u0161tajn",
      },
      hun: {
        official: "Liechtensteini Hercegs\u00e9g",
        common: "Liechtenstein",
      },
      ita: {
        official: "Principato del Liechtenstein",
        common: "Liechtenstein",
      },
      jpn: {
        official:
          "\u30ea\u30d2\u30c6\u30f3\u30b7\u30e5\u30bf\u30a4\u30f3\u516c\u56fd",
        common: "\u30ea\u30d2\u30c6\u30f3\u30b7\u30e5\u30bf\u30a4\u30f3",
      },
      kor: {
        official: "\ub9ac\ud788\ud150\uc288\ud0c0\uc778 \uacf5\uad6d",
        common: "\ub9ac\ud788\ud150\uc288\ud0c0\uc778",
      },
      nld: { official: "Vorstendom Liechtenstein", common: "Liechtenstein" },
      per: {
        official:
          "\u0634\u0627\u0647\u0632\u0627\u062f\u0647\u200c\u0646\u0634\u06cc\u0646 \u0644\u06cc\u062e\u062a\u0646\u200c\u0627\u0634\u062a\u0627\u06cc\u0646",
        common:
          "\u0644\u06cc\u062e\u062a\u0646\u200c\u0627\u0634\u062a\u0627\u06cc\u0646",
      },
      pol: {
        official: "Ksi\u0119stwo Liechtensteinu",
        common: "Liechtenstein",
      },
      por: { official: "Principado de Liechtenstein", common: "Liechtenstein" },
      rus: {
        official:
          "\u041a\u043d\u044f\u0436\u0435\u0441\u0442\u0432\u043e \u041b\u0438\u0445\u0442\u0435\u043d\u0448\u0442\u0435\u0439\u043d",
        common:
          "\u041b\u0438\u0445\u0442\u0435\u043d\u0448\u0442\u0435\u0439\u043d",
      },
      slk: {
        official: "Lichten\u0161tajnsk\u00e9 knie\u017eatstvo",
        common: "Lichten\u0161tajnsko",
      },
      spa: { official: "Principado de Liechtenstein", common: "Liechtenstein" },
      swe: {
        official: "Furstend\u00f6met Liechtenstein",
        common: "Liechtenstein",
      },
      urd: {
        official:
          "\u0627\u0645\u0627\u0631\u0627\u062a \u0644\u06cc\u062e\u062a\u06cc\u0646\u0633\u062a\u0627\u0626\u0646",
        common:
          "\u0644\u06cc\u062e\u062a\u06cc\u0646\u0633\u062a\u0627\u0626\u0646",
      },
      zho: {
        official: "\u5217\u652f\u6566\u58eb\u767b\u516c\u56fd",
        common: "\u5217\u652f\u6566\u58eb\u767b",
      },
    },
    latlng: [47.26666666, 9.53333333],
    landlocked: true,
    borders: ["AUT", "CHE"],
    area: 160,
    flag: "\ud83c\uddf1\ud83c\uddee",
    demonyms: {
      eng: { f: "Liechtensteiner", m: "Liechtensteiner" },
      fra: { f: "Liechtensteinoise", m: "Liechtensteinois" },
    },
    callingCodes: ["+423"],
  },
  {
    name: {
      common: "Sri Lanka",
      official: "Democratic Socialist Republic of Sri Lanka",
      native: {
        sin: {
          official:
            "\u0dc1\u0dca\u200d\u0dbb\u0dd3 \u0dbd\u0d82\u0d9a\u0dcf \u0db4\u0dca\u200d\u0dbb\u0da2\u0dcf\u0dad\u0dcf\u0db1\u0dca\u0dad\u0dca\u200d\u0dbb\u0dd2\u0d9a \u0dc3\u0db8\u0dcf\u0da2\u0dc0\u0dcf\u0daf\u0dd3 \u0da2\u0db1\u0dbb\u0da2\u0dba",
          common:
            "\u0dc1\u0dca\u200d\u0dbb\u0dd3 \u0dbd\u0d82\u0d9a\u0dcf\u0dc0",
        },
        tam: {
          official:
            "\u0b87\u0bb2\u0b99\u0bcd\u0b95\u0bc8 \u0b9a\u0ba9\u0ba8\u0bbe\u0baf\u0b95 \u0b9a\u0bcb\u0b9a\u0bb2\u0bbf\u0b9a\u0b95\u0bcd \u0b95\u0bc1\u0b9f\u0bbf\u0baf\u0bb0\u0b9a\u0bc1",
          common: "\u0b87\u0bb2\u0b99\u0bcd\u0b95\u0bc8",
        },
      },
    },
    tld: [
      ".lk",
      ".\u0b87\u0bb2\u0b99\u0bcd\u0b95\u0bc8",
      ".\u0dbd\u0d82\u0d9a\u0dcf",
    ],
    cca2: "LK",
    ccn3: "144",
    cca3: "LKA",
    cioc: "SRI",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: {
      LKR: { name: "Sri Lankan rupee", symbol: "Rs  \u0dbb\u0dd4" },
    },
    idd: { root: "+9", suffixes: ["4"] },
    capital: ["Colombo"],
    altSpellings: [
      "LK",
      "ila\u1e45kai",
      "Democratic Socialist Republic of Sri Lanka",
    ],
    region: "Asia",
    subregion: "Southern Asia",
    languages: { sin: "Sinhala", tam: "Tamil" },
    translations: {
      ces: {
        official:
          "Sr\u00edlansk\u00e1 demokratick\u00e1 socialistick\u00e1 republika",
        common: "Sr\u00ed Lanka",
      },
      deu: {
        official: "Demokratische Sozialistische Republik Sri Lanka",
        common: "Sri Lanka",
      },
      est: {
        official: "Sri Lanka Demokraatlik Sotsialistlik Vabariik",
        common: "Sri Lanka",
      },
      fin: {
        official: "Sri Lankan demokraattinen sosialistinen tasavalta",
        common: "Sri Lanka",
      },
      fra: {
        official: "R\u00e9publique d\u00e9mocratique socialiste du Sri Lanka",
        common: "Sri Lanka",
      },
      hrv: {
        official: "Demokratska Socijalisti\u010dke Republike \u0160ri Lanke",
        common: "\u0160ri Lanka",
      },
      hun: {
        official:
          "Sr\u00ed Lanka-i Demokratikus Szocialista K\u00f6zt\u00e1rsas\u00e1g",
        common: "Sr\u00ed Lanka",
      },
      ita: {
        official: "Repubblica democratica socialista dello Sri Lanka",
        common: "Sri Lanka",
      },
      jpn: {
        official:
          "\u30b9\u30ea\u30e9\u30f3\u30ab\u6c11\u4e3b\u793e\u4f1a\u4e3b\u7fa9\u5171\u548c\u56fd",
        common: "\u30b9\u30ea\u30e9\u30f3\u30ab",
      },
      kor: {
        official:
          "\uc2a4\ub9ac\ub791\uce74 \ubbfc\uc8fc \uc0ac\ud68c\uc8fc\uc758 \uacf5\ud654\uad6d",
        common: "\uc2a4\ub9ac\ub791\uce74",
      },
      nld: {
        official: "Democratische Socialistische Republiek Sri Lanka",
        common: "Sri Lanka",
      },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u062f\u0645\u0648\u06a9\u0631\u0627\u062a\u06cc\u06a9 \u0633\u0648\u0633\u06cc\u0627\u0644\u06cc\u0633\u062a\u06cc \u0633\u0631\u06cc\u200c\u0644\u0627\u0646\u06a9\u0627",
        common: "\u0633\u0631\u06cc\u200c\u0644\u0627\u0646\u06a9\u0627",
      },
      pol: {
        official: "Demokratyczno-Socjalistyczna Republika Sri Lanki",
        common: "Sri Lanka",
      },
      por: {
        official: "Rep\u00fablica Democr\u00e1tica Socialista do Sri Lanka",
        common: "Sri Lanka",
      },
      rus: {
        official:
          "\u0414\u0435\u043c\u043e\u043a\u0440\u0430\u0442\u0438\u0447\u0435\u0441\u043a\u0430\u044f \u0421\u043e\u0446\u0438\u0430\u043b\u0438\u0441\u0442\u0438\u0447\u0435\u0441\u043a\u0430\u044f \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0428\u0440\u0438-\u041b\u0430\u043d\u043a\u0430",
        common: "\u0428\u0440\u0438-\u041b\u0430\u043d\u043a\u0430",
      },
      slk: {
        official:
          "Sr\u00edlansk\u00e1 demokratick\u00e1 socialistick\u00e1 republika",
        common: "Sr\u00ed Lanka",
      },
      spa: {
        official: "Rep\u00fablica Democr\u00e1tica Socialista de Sri Lanka",
        common: "Sri Lanka",
      },
      swe: {
        official: "Demokratiska socialistiska republiken Sri Lanka",
        common: "Sri Lanka",
      },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc \u0648 \u0627\u0634\u062a\u0631\u0627\u06a9\u06cc \u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0633\u0631\u06cc \u0644\u0646\u06a9\u0627",
        common: "\u0633\u0631\u06cc \u0644\u0646\u06a9\u0627",
      },
      zho: {
        official:
          "\u65af\u91cc\u5170\u5361\u6c11\u4e3b\u793e\u4f1a\u4e3b\u4e49\u5171\u548c\u56fd",
        common: "\u65af\u91cc\u5170\u5361",
      },
    },
    latlng: [7, 81],
    landlocked: false,
    borders: ["IND"],
    area: 65610,
    flag: "\ud83c\uddf1\ud83c\uddf0",
    demonyms: {
      eng: { f: "Sri Lankan", m: "Sri Lankan" },
      fra: { f: "Sri-lankaise", m: "Sri-lankais" },
    },
    callingCodes: ["+94"],
  },
  {
    name: {
      common: "Lesotho",
      official: "Kingdom of Lesotho",
      native: {
        eng: { official: "Kingdom of Lesotho", common: "Lesotho" },
        sot: { official: "Kingdom of Lesotho", common: "Lesotho" },
      },
    },
    tld: [".ls"],
    cca2: "LS",
    ccn3: "426",
    cca3: "LSO",
    cioc: "LES",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: {
      LSL: { name: "Lesotho loti", symbol: "L" },
      ZAR: { name: "South African rand", symbol: "R" },
    },
    idd: { root: "+2", suffixes: ["66"] },
    capital: ["Maseru"],
    altSpellings: ["LS", "Kingdom of Lesotho", "Muso oa Lesotho"],
    region: "Africa",
    subregion: "Southern Africa",
    languages: { eng: "English", sot: "Sotho" },
    translations: {
      ces: {
        official: "Lesothsk\u00e9 kr\u00e1lovstv\u00ed",
        common: "Lesotho",
      },
      deu: { official: "K\u00f6nigreich Lesotho", common: "Lesotho" },
      est: { official: "Lesotho Kuningriik", common: "Lesotho" },
      fin: { official: "Lesothon kuningaskunta", common: "Lesotho" },
      fra: { official: "Royaume du Lesotho", common: "Lesotho" },
      hrv: { official: "Kraljevina Lesoto", common: "Lesoto" },
      hun: { official: "Lesoth\u00f3i Kir\u00e1lys\u00e1g", common: "Lesotho" },
      ita: { official: "Regno del Lesotho", common: "Lesotho" },
      jpn: {
        official: "\u30ec\u30bd\u30c8\u738b\u56fd",
        common: "\u30ec\u30bd\u30c8",
      },
      kor: {
        official: "\ub808\uc18c\ud1a0 \uc655\uad6d",
        common: "\ub808\uc18c\ud1a0",
      },
      nld: { official: "Koninkrijk Lesotho", common: "Lesotho" },
      per: {
        official:
          "\u067e\u0627\u062f\u0634\u0627\u0647\u06cc \u0644\u0633\u0648\u062a\u0648",
        common: "\u0644\u0633\u0648\u062a\u0648",
      },
      pol: { official: "Kr\u00f3lestwo Lesotho", common: "Lesotho" },
      por: { official: "Reino do Lesoto", common: "Lesoto" },
      rus: {
        official:
          "\u041a\u043e\u0440\u043e\u043b\u0435\u0432\u0441\u0442\u0432\u043e \u041b\u0435\u0441\u043e\u0442\u043e",
        common: "\u041b\u0435\u0441\u043e\u0442\u043e",
      },
      slk: {
        official: "Lesothsk\u00e9 kr\u00e1\u013eovstvo",
        common: "Lesotho",
      },
      spa: { official: "Reino de Lesotho", common: "Lesotho" },
      swe: { official: "Konungariket Lesotho", common: "Lesotho" },
      urd: {
        official:
          "\u0645\u0645\u0644\u06a9\u062a\u0650 \u0644\u06cc\u0633\u0648\u062a\u06be\u0648",
        common: "\u0644\u06cc\u0633\u0648\u062a\u06be\u0648",
      },
      zho: {
        official: "\u83b1\u7d22\u6258\u738b\u56fd",
        common: "\u83b1\u7d22\u6258",
      },
    },
    latlng: [-29.5, 28.5],
    landlocked: true,
    borders: ["ZAF"],
    area: 30355,
    flag: "\ud83c\uddf1\ud83c\uddf8",
    demonyms: {
      eng: { f: "Mosotho", m: "Mosotho" },
      fra: { f: "L\u00e9sothienne", m: "L\u00e9sothien" },
    },
    callingCodes: ["+266"],
  },
  {
    name: {
      common: "Lithuania",
      official: "Republic of Lithuania",
      native: { lit: { official: "Lietuvos Respublikos", common: "Lietuva" } },
    },
    tld: [".lt"],
    cca2: "LT",
    ccn3: "440",
    cca3: "LTU",
    cioc: "LTU",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { EUR: { name: "Euro", symbol: "\u20ac" } },
    idd: { root: "+3", suffixes: ["70"] },
    capital: ["Vilnius"],
    altSpellings: ["LT", "Republic of Lithuania", "Lietuvos Respublika"],
    region: "Europe",
    subregion: "Northern Europe",
    languages: { lit: "Lithuanian" },
    translations: {
      ces: { official: "Litevsk\u00e1 republika", common: "Litva" },
      deu: { official: "Republik Litauen", common: "Litauen" },
      est: { official: "Leedu Vabariik", common: "Leedu" },
      fin: { official: "Liettuan tasavalta", common: "Liettua" },
      fra: { official: "R\u00e9publique de Lituanie", common: "Lituanie" },
      hrv: { official: "Republika Litva", common: "Litva" },
      hun: {
        official: "Litv\u00e1n K\u00f6zt\u00e1rsas\u00e1g",
        common: "Litv\u00e1nia",
      },
      ita: { official: "Repubblica di Lituania", common: "Lituania" },
      jpn: {
        official: "\u30ea\u30c8\u30a2\u30cb\u30a2\u5171\u548c\u56fd",
        common: "\u30ea\u30c8\u30a2\u30cb\u30a2",
      },
      kor: {
        official: "\ub9ac\ud22c\uc544\ub2c8\uc544 \uacf5\ud654\uad6d",
        common: "\ub9ac\ud22c\uc544\ub2c8\uc544",
      },
      nld: { official: "Republiek Litouwen", common: "Litouwen" },
      per: {
        official:
          "\u0644\u06cc\u062a\u0648\u0627\u0646\u06cc\u0627\u06cc\u06cc\u200c\u0647\u0627",
        common:
          "\u0644\u06cc\u062a\u0648\u0627\u0646\u06cc\u0627\u06cc\u06cc\u200c\u0647\u0627",
      },
      pol: { official: "Republika Litewska", common: "Litwa" },
      por: {
        official: "Rep\u00fablica da Litu\u00e2nia",
        common: "Litu\u00e2nia",
      },
      rus: {
        official:
          "\u041b\u0438\u0442\u043e\u0432\u0441\u043a\u0430\u044f \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430",
        common: "\u041b\u0438\u0442\u0432\u0430",
      },
      slk: { official: "Litovsk\u00e1 republika", common: "Litva" },
      spa: { official: "Rep\u00fablica de Lituania", common: "Lituania" },
      swe: { official: "Republiken Litauen", common: "Litauen" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0644\u062a\u06be\u0648\u0648\u06cc\u0646\u06cc\u0627",
        common: "\u0644\u062a\u06be\u0648\u0648\u06cc\u0646\u06cc\u0627",
      },
      zho: {
        official: "\u7acb\u9676\u5b9b\u5171\u548c\u56fd",
        common: "\u7acb\u9676\u5b9b",
      },
    },
    latlng: [56, 24],
    landlocked: false,
    borders: ["BLR", "LVA", "POL", "RUS"],
    area: 65300,
    flag: "\ud83c\uddf1\ud83c\uddf9",
    demonyms: {
      eng: { f: "Lithuanian", m: "Lithuanian" },
      fra: { f: "Lituanienne", m: "Lituanien" },
    },
    callingCodes: ["+370"],
  },
  {
    name: {
      common: "Luxembourg",
      official: "Grand Duchy of Luxembourg",
      native: {
        deu: { official: "Gro\u00dfherzogtum Luxemburg", common: "Luxemburg" },
        fra: {
          official: "Grand-Duch\u00e9 de Luxembourg",
          common: "Luxembourg",
        },
        ltz: {
          official: "Groussherzogtum L\u00ebtzebuerg",
          common: "L\u00ebtzebuerg",
        },
      },
    },
    tld: [".lu"],
    cca2: "LU",
    ccn3: "442",
    cca3: "LUX",
    cioc: "LUX",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { EUR: { name: "Euro", symbol: "\u20ac" } },
    idd: { root: "+3", suffixes: ["52"] },
    capital: ["Luxembourg"],
    altSpellings: [
      "LU",
      "Grand Duchy of Luxembourg",
      "Grand-Duch\u00e9 de Luxembourg",
      "Gro\u00dfherzogtum Luxemburg",
      "Groussherzogtum L\u00ebtzebuerg",
    ],
    region: "Europe",
    subregion: "Western Europe",
    languages: { deu: "German", fra: "French", ltz: "Luxembourgish" },
    translations: {
      ces: {
        official: "Lucembursk\u00e9 velkov\u00e9vodstv\u00ed",
        common: "Lucembursko",
      },
      deu: { official: "Gro\u00dfherzogtum Luxemburg,", common: "Luxemburg" },
      est: { official: "Luksemburgi Suurhertsogiriik", common: "Luksemburg" },
      fin: { official: "Luxemburgin suurherttuakunta", common: "Luxemburg" },
      fra: { official: "Grand-Duch\u00e9 de Luxembourg", common: "Luxembourg" },
      hrv: { official: "Veliko Vojvodstvo Luksemburg", common: "Luksemburg" },
      hun: { official: "Luxemburgi Nagyhercegs\u00e9g", common: "Luxemburg" },
      ita: { official: "Granducato di Lussemburgo", common: "Lussemburgo" },
      jpn: {
        official:
          "\u30eb\u30af\u30bb\u30f3\u30d6\u30eb\u30af\u5927\u516c\u56fd",
        common: "\u30eb\u30af\u30bb\u30f3\u30d6\u30eb\u30af",
      },
      kor: {
        official: "\ub8e9\uc148\ubd80\ub974\ud06c \ub300\uacf5\uad6d",
        common: "\ub8e9\uc148\ubd80\ub974\ud06c",
      },
      nld: { official: "Groothertogdom Luxemburg", common: "Luxemburg" },
      per: {
        official:
          "\u062f\u0648\u06a9\u200c\u0646\u0634\u06cc\u0646 \u0644\u0648\u06a9\u0632\u0627\u0645\u0628\u0648\u0631\u06af",
        common: "\u0644\u0648\u06a9\u0632\u0627\u0645\u0628\u0648\u0631\u06af",
      },
      pol: {
        official: "Wielkie Ksi\u0119stwo Luksemburga",
        common: "Luksemburg",
      },
      por: { official: "Gr\u00e3o-Ducado do Luxemburgo", common: "Luxemburgo" },
      rus: {
        official:
          "\u0412\u0435\u043b\u0438\u043a\u043e\u0435 \u0413\u0435\u0440\u0446\u043e\u0433\u0441\u0442\u0432\u043e \u041b\u044e\u043a\u0441\u0435\u043c\u0431\u0443\u0440\u0433",
        common: "\u041b\u044e\u043a\u0441\u0435\u043c\u0431\u0443\u0440\u0433",
      },
      slk: {
        official: "Luxembursk\u00e9 ve\u013ekovojvodstvo",
        common: "Luxembursko",
      },
      spa: { official: "Gran Ducado de Luxemburgo", common: "Luxemburgo" },
      swe: { official: "Storhertigd\u00f6met Luxemburg", common: "Luxemburg" },
      urd: {
        official:
          "\u062f\u0648\u0642\u06cc\u06c1 \u06a9\u0628\u06cc\u0631\u0644\u06a9\u0633\u0645\u0628\u0631\u06af",
        common: "\u0644\u06a9\u0633\u0645\u0628\u0631\u06af",
      },
      zho: {
        official: "\u5362\u68ee\u5821\u5927\u516c\u56fd",
        common: "\u5362\u68ee\u5821",
      },
    },
    latlng: [49.75, 6.16666666],
    landlocked: true,
    borders: ["BEL", "FRA", "DEU"],
    area: 2586,
    flag: "\ud83c\uddf1\ud83c\uddfa",
    demonyms: {
      eng: { f: "Luxembourger", m: "Luxembourger" },
      fra: { f: "Luxembourgeoise", m: "Luxembourgeois" },
    },
    callingCodes: ["+352"],
  },
  {
    name: {
      common: "Latvia",
      official: "Republic of Latvia",
      native: { lav: { official: "Latvijas Republikas", common: "Latvija" } },
    },
    tld: [".lv"],
    cca2: "LV",
    ccn3: "428",
    cca3: "LVA",
    cioc: "LAT",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { EUR: { name: "Euro", symbol: "\u20ac" } },
    idd: { root: "+3", suffixes: ["71"] },
    capital: ["Riga"],
    altSpellings: ["LV", "Republic of Latvia", "Latvijas Republika"],
    region: "Europe",
    subregion: "Northern Europe",
    languages: { lav: "Latvian" },
    translations: {
      ces: {
        official: "Loty\u0161sk\u00e1 republika",
        common: "Loty\u0161sko",
      },
      deu: { official: "Republik Lettland", common: "Lettland" },
      est: { official: "L\u00e4ti Vabariik", common: "L\u00e4ti" },
      fin: { official: "Latvian tasavalta", common: "Latvia" },
      fra: { official: "R\u00e9publique de Lettonie", common: "Lettonie" },
      hrv: { official: "Republika Latvija", common: "Latvija" },
      hun: {
        official: "Lett K\u00f6zt\u00e1rsas\u00e1g",
        common: "Lettorsz\u00e1g",
      },
      ita: { official: "Repubblica di Lettonia", common: "Lettonia" },
      jpn: {
        official: "\u30e9\u30c8\u30d3\u30a2\u5171\u548c\u56fd",
        common: "\u30e9\u30c8\u30d3\u30a2",
      },
      kor: {
        official: "\ub77c\ud2b8\ube44\uc544 \uacf5\ud654\uad6d",
        common: "\ub77c\ud2b8\ube44\uc544",
      },
      nld: { official: "Republiek Letland", common: "Letland" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0644\u062a\u0648\u0646\u06cc",
        common: "\u0644\u062a\u0648\u0646\u06cc",
      },
      pol: { official: "Republika \u0141otewska", common: "\u0141otwa" },
      por: {
        official: "Rep\u00fablica da Let\u00f3nia",
        common: "Let\u00f3nia",
      },
      rus: {
        official:
          "\u041b\u0430\u0442\u0432\u0438\u0439\u0441\u043a\u0430\u044f \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430",
        common: "\u041b\u0430\u0442\u0432\u0438\u044f",
      },
      slk: {
        official: "Loty\u0161sk\u00e1 republika",
        common: "Loty\u0161sko",
      },
      spa: { official: "Rep\u00fablica de Letonia", common: "Letonia" },
      swe: { official: "Republiken Lettland", common: "Lettland" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0644\u0679\u0648\u06cc\u0627",
        common: "\u0644\u0679\u0648\u06cc\u0627",
      },
      zho: {
        official: "\u62c9\u8131\u7ef4\u4e9a\u5171\u548c\u56fd",
        common: "\u62c9\u8131\u7ef4\u4e9a",
      },
    },
    latlng: [57, 25],
    landlocked: false,
    borders: ["BLR", "EST", "LTU", "RUS"],
    area: 64559,
    flag: "\ud83c\uddf1\ud83c\uddfb",
    demonyms: {
      eng: { f: "Latvian", m: "Latvian" },
      fra: { f: "Lettone", m: "Letton" },
    },
    callingCodes: ["+371"],
  },
  {
    name: {
      common: "Macau",
      official:
        "Macao Special Administrative Region of the People's Republic of China",
      native: {
        por: {
          official:
            "Regi\u00e3o Administrativa Especial de Macau da Rep\u00fablica Popular da China",
          common: "Macau",
        },
        zho: {
          official:
            "\u4e2d\u534e\u4eba\u6c11\u5171\u548c\u56fd\u6fb3\u95e8\u7279\u522b\u884c\u653f\u533a",
          common: "\u6fb3\u95e8",
        },
      },
    },
    tld: [".mo"],
    cca2: "MO",
    ccn3: "446",
    cca3: "MAC",
    cioc: "",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: { MOP: { name: "Macanese pataca", symbol: "P" } },
    idd: { root: "+8", suffixes: ["53"] },
    capital: [""],
    altSpellings: [
      "MO",
      "\u6fb3\u95e8",
      "Macao",
      "Macao Special Administrative Region of the People's Republic of China",
      "\u4e2d\u83ef\u4eba\u6c11\u5171\u548c\u570b\u6fb3\u9580\u7279\u5225\u884c\u653f\u5340",
      "Regi\u00e3o Administrativa Especial de Macau da Rep\u00fablica Popular da China",
    ],
    region: "Asia",
    subregion: "Eastern Asia",
    languages: { por: "Portuguese", zho: "Chinese" },
    translations: {
      ces: {
        official:
          "Zvl\u00e1\u0161tn\u00ed spr\u00e1vn\u00ed oblast \u010c\u00ednsk\u00e9 lidov\u00e9 republiky Macao",
        common: "Macao",
      },
      deu: {
        official: "Sonderverwaltungsregion Macau der Volksrepublik China",
        common: "Macao",
      },
      est: { official: "Macau erihalduspiirkond", common: "Macau" },
      fin: {
        official: "Macaon Kiinan kansantasavallan erityishallintoalue",
        common: "Macao",
      },
      fra: {
        official:
          "R\u00e9gion administrative sp\u00e9ciale de Macao de la R\u00e9publique populaire de Chine",
        common: "Macao",
      },
      hrv: {
        official: "Makao Posebnog upravnog podru\u010djaNarodne Republike Kine",
        common: "Makao",
      },
      hun: { official: "Maka\u00f3", common: "Maka\u00f3" },
      ita: {
        official:
          "Macao Regione amministrativa speciale della Repubblica Popolare Cinese",
        common: "Macao",
      },
      jpn: {
        official:
          "\u4e2d\u83ef\u4eba\u6c11\u5171\u548c\u56fd\u30de\u30ab\u30aa\u7279\u5225\u884c\u653f\u533a",
        common: "\u30de\u30ab\u30aa",
      },
      kor: {
        official:
          "\uc911\ud654\uc778\ubbfc\uacf5\ud654\uad6d \ub9c8\uce74\uc624 \ud2b9\ubcc4\ud589\uc815\uad6c",
        common: "\ub9c8\uce74\uc624",
      },
      nld: {
        official:
          "Speciale Administratieve Regio Macau van de Volksrepubliek China",
        common: "Macao",
      },
      per: {
        official: "\u0645\u0627\u06a9\u0627\u0626\u0648",
        common: "\u0645\u0627\u06a9\u0627\u0626\u0648",
      },
      pol: {
        official:
          "Specjalny Region Administracyjny Chi\u0144skiej Republiki Ludowej Makau",
        common: "Makau",
      },
      por: {
        official:
          "Macau Regi\u00e3o Administrativa Especial da Rep\u00fablica Popular da China",
        common: "Macau",
      },
      rus: {
        official:
          "\u0421\u043f\u0435\u0446\u0438\u0430\u043b\u044c\u043d\u044b\u0439 \u0430\u0434\u043c\u0438\u043d\u0438\u0441\u0442\u0440\u0430\u0442\u0438\u0432\u043d\u044b\u0439 \u0440\u0430\u0439\u043e\u043d \u041c\u0430\u043a\u0430\u043e \u041a\u0438\u0442\u0430\u0439\u0441\u043a\u043e\u0439 \u041d\u0430\u0440\u043e\u0434\u043d\u043e\u0439 \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0438 \u041a\u0438\u0442\u0430\u0439",
        common: "\u041c\u0430\u043a\u0430\u043e",
      },
      slk: {
        official: "Macao, \u0160peci\u00e0lna administrat\u00edvna oblas\u0166",
        common: "Macao",
      },
      spa: {
        official:
          "Macao, Regi\u00f3n Administrativa Especial de la Rep\u00fablica Popular China",
        common: "Macao",
      },
      swe: { official: "Macao", common: "Macao" },
      urd: {
        official:
          "\u0645\u06a9\u0627\u0624 \u0639\u0648\u0627\u0645\u06cc \u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0686\u06cc\u0646 \u06a9\u0627 \u062e\u0635\u0648\u0635\u06cc \u0627\u0646\u062a\u0638\u0627\u0645\u06cc \u0639\u0644\u0627\u0642\u06c1",
        common: "\u0645\u06a9\u0627\u0624",
      },
    },
    latlng: [22.16666666, 113.55],
    landlocked: false,
    borders: ["CHN"],
    area: 30,
    flag: "\ud83c\uddf2\ud83c\uddf4",
    demonyms: {
      eng: { f: "Macanese", m: "Macanese" },
      fra: { f: "Macanaise", m: "Macanais" },
    },
    callingCodes: ["+853"],
  },
  {
    name: {
      common: "Saint Martin",
      official: "Saint Martin",
      native: { fra: { official: "Saint-Martin", common: "Saint-Martin" } },
    },
    tld: [".fr", ".gp"],
    cca2: "MF",
    ccn3: "663",
    cca3: "MAF",
    cioc: "",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: { EUR: { name: "Euro", symbol: "\u20ac" } },
    idd: { root: "+5", suffixes: ["90"] },
    capital: ["Marigot"],
    altSpellings: [
      "MF",
      "Collectivity of Saint Martin",
      "Collectivit\u00e9 de Saint-Martin",
      "Saint Martin (French part)",
    ],
    region: "Americas",
    subregion: "Caribbean",
    languages: { fra: "French" },
    translations: {
      ces: {
        official: "Svat\u00fd Martin",
        common: "Svat\u00fd Martin (Francie)",
      },
      deu: { official: "Saint-Martin", common: "Saint-Martin" },
      est: { official: "Saint-Martini \u00fchendus", common: "Saint-Martin" },
      fin: { official: "Saint-Martin", common: "Saint-Martin" },
      fra: { official: "Saint-Martin", common: "Saint-Martin" },
      hrv: { official: "Saint Martin", common: "Sveti Martin" },
      hun: {
        official: "Saint-Martin K\u00f6z\u00f6ss\u00e9g",
        common: "Saint-Martin",
      },
      ita: { official: "saint Martin", common: "Saint Martin" },
      jpn: {
        official: "\u30b5\u30f3\u30de\u30eb\u30bf\u30f3\u5cf6",
        common:
          "\u30b5\u30f3\u30fb\u30de\u30eb\u30bf\u30f3\uff08\u30d5\u30e9\u30f3\u30b9\u9818\uff09",
      },
      kor: {
        official: "\uc0dd\ub9c8\ub974\ud0f1",
        common: "\uc0dd\ub9c8\ub974\ud0f1",
      },
      nld: { official: "Saint Martin", common: "Saint-Martin" },
      per: {
        official: "\u0633\u0646 \u0645\u0627\u0631\u062a\u0646",
        common: "\u0633\u0646 \u0645\u0627\u0631\u062a\u0646",
      },
      pol: { official: "Wsp\u00f3lnota Saint-Martin", common: "Saint-Martin" },
      por: { official: "saint Martin", common: "S\u00e3o Martinho" },
      rus: {
        official: "\u0421\u0435\u043d-\u041c\u0430\u0440\u0442\u0435\u043d",
        common: "\u0421\u0435\u043d-\u041c\u0430\u0440\u0442\u0435\u043d",
      },
      slk: { official: "Saint-Martin", common: "Saint-Martin" },
      spa: { official: "Saint Martin", common: "Saint Martin" },
      swe: {
        official: "F\u00f6rvaltningsomr\u00e5det Saint-Martin",
        common: "Saint-Martin",
      },
      urd: {
        official: "\u0633\u06cc\u0646\u0679 \u0645\u0627\u0631\u0679\u0646",
        common: "\u0633\u06cc\u0646\u0679 \u0645\u0627\u0631\u0679\u0646",
      },
      zho: { official: "\u5723\u9a6c\u4e01", common: "\u5723\u9a6c\u4e01" },
    },
    latlng: [18.08333333, -63.95],
    landlocked: false,
    borders: ["SXM"],
    area: 53,
    flag: "\ud83c\uddf2\ud83c\uddeb",
    demonyms: {
      eng: { f: "Saint Martin Islander", m: "Saint Martin Islander" },
      fra: { f: "Saint-Martinoise", m: "Saint-Martinois" },
    },
    callingCodes: ["+590"],
  },
  {
    name: {
      common: "Morocco",
      official: "Kingdom of Morocco",
      native: {
        ara: {
          official:
            "\u0627\u0644\u0645\u0645\u0644\u0643\u0629 \u0627\u0644\u0645\u063a\u0631\u0628\u064a\u0629",
          common: "\u0627\u0644\u0645\u063a\u0631\u0628",
        },
        ber: {
          official:
            "\u2d5c\u2d30\u2d33\u2d4d\u2d37\u2d49\u2d5c \u2d4f \u2d4d\u2d4e\u2d56\u2d54\u2d49\u2d31",
          common: "\u2d4d\u2d4e\u2d30\u2d56\u2d54\u2d49\u2d31",
        },
      },
    },
    tld: [".ma", "\u0627\u0644\u0645\u063a\u0631\u0628."],
    cca2: "MA",
    ccn3: "504",
    cca3: "MAR",
    cioc: "MAR",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { MAD: { name: "Moroccan dirham", symbol: "\u062f.\u0645." } },
    idd: { root: "+2", suffixes: ["12"] },
    capital: ["Rabat"],
    altSpellings: [
      "MA",
      "Kingdom of Morocco",
      "Al-Mamlakah al-Ma\u0121ribiyah",
    ],
    region: "Africa",
    subregion: "Northern Africa",
    languages: { ara: "Arabic", ber: "Berber" },
    translations: {
      ces: { official: "Marock\u00e9 kr\u00e1lovstv\u00ed", common: "Maroko" },
      deu: { official: "K\u00f6nigreich Marokko", common: "Marokko" },
      est: { official: "Maroko Kuningriik", common: "Maroko" },
      fin: { official: "Marokon kuningaskunta", common: "Marokko" },
      fra: { official: "Royaume du Maroc", common: "Maroc" },
      hrv: { official: "Kraljevina Maroko", common: "Maroko" },
      hun: {
        official: "Marokk\u00f3i Kir\u00e1lys\u00e1g",
        common: "Marokk\u00f3",
      },
      ita: { official: "Regno del Marocco", common: "Marocco" },
      jpn: {
        official: "\u30e2\u30ed\u30c3\u30b3\u738b\u56fd",
        common: "\u30e2\u30ed\u30c3\u30b3",
      },
      kor: {
        official: "\ubaa8\ub85c\ucf54 \uc655\uad6d",
        common: "\ubaa8\ub85c\ucf54",
      },
      nld: { official: "Koninkrijk Marokko", common: "Marokko" },
      per: {
        official:
          "\u067e\u0627\u062f\u0634\u0627\u0647\u06cc \u0645\u0631\u0627\u06a9\u0634",
        common: "\u0645\u0631\u0627\u06a9\u0634",
      },
      pol: { official: "Kr\u00f3lestwo Maroka\u0144skie", common: "Maroko" },
      por: { official: "Reino de Marrocos", common: "Marrocos" },
      rus: {
        official:
          "\u041a\u043e\u0440\u043e\u043b\u0435\u0432\u0441\u0442\u0432\u043e \u041c\u0430\u0440\u043e\u043a\u043a\u043e",
        common: "\u041c\u0430\u0440\u043e\u043a\u043a\u043e",
      },
      slk: { official: "Marock\u00e9 knie\u017eatstvo", common: "Maroko" },
      spa: { official: "Reino de Marruecos", common: "Marruecos" },
      swe: { official: "Konungariket Marocko", common: "Marocko" },
      urd: {
        official:
          "\u0645\u0645\u0644\u06a9\u062a\u0650 \u0645\u0631\u0627\u06a9\u0634",
        common: "\u0645\u0631\u0627\u06a9\u0634",
      },
      zho: {
        official: "\u6469\u6d1b\u54e5\u738b\u56fd",
        common: "\u6469\u6d1b\u54e5",
      },
    },
    latlng: [32, -5],
    landlocked: false,
    borders: ["DZA", "ESH", "ESP"],
    area: 446550,
    flag: "\ud83c\uddf2\ud83c\udde6",
    demonyms: {
      eng: { f: "Moroccan", m: "Moroccan" },
      fra: { f: "Marocaine", m: "Marocain" },
    },
    callingCodes: ["+212"],
  },
  {
    name: {
      common: "Monaco",
      official: "Principality of Monaco",
      native: {
        fra: { official: "Principaut\u00e9 de Monaco", common: "Monaco" },
      },
    },
    tld: [".mc"],
    cca2: "MC",
    ccn3: "492",
    cca3: "MCO",
    cioc: "MON",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { EUR: { name: "Euro", symbol: "\u20ac" } },
    idd: { root: "+3", suffixes: ["77"] },
    capital: ["Monaco"],
    altSpellings: [
      "MC",
      "Principality of Monaco",
      "Principaut\u00e9 de Monaco",
    ],
    region: "Europe",
    subregion: "Western Europe",
    languages: { fra: "French" },
    translations: {
      ces: {
        official: "Monack\u00e9 kn\u00ed\u017eectv\u00ed",
        common: "Monako",
      },
      deu: { official: "F\u00fcrstentum Monaco", common: "Monaco" },
      est: { official: "Monaco V\u00fcrstiriik", common: "Monaco" },
      fin: { official: "Monacon ruhtinaskunta", common: "Monaco" },
      fra: { official: "Principaut\u00e9 de Monaco", common: "Monaco" },
      hrv: { official: "Kne\u017eevina Monako", common: "Monako" },
      hun: { official: "Monac\u00f3i Hercegs\u00e9g", common: "Monaco" },
      ita: { official: "Principato di Monaco", common: "Principato di Monaco" },
      jpn: {
        official: "\u30e2\u30ca\u30b3\u516c\u56fd",
        common: "\u30e2\u30ca\u30b3",
      },
      kor: {
        official: "\ubaa8\ub098\ucf54 \uacf5\uad6d",
        common: "\ubaa8\ub098\ucf54",
      },
      nld: { official: "Vorstendom Monaco", common: "Monaco" },
      per: {
        official:
          "\u0634\u0627\u0647\u0632\u0627\u062f\u0647\u200c\u0646\u0634\u06cc\u0646 \u0645\u0648\u0646\u0627\u06a9\u0648",
        common: "\u0645\u0648\u0646\u0627\u06a9\u0648",
      },
      pol: { official: "Ksi\u0119stwo Monako", common: "Monako" },
      por: { official: "Principado do M\u00f3naco", common: "M\u00f3naco" },
      rus: {
        official:
          "\u041a\u043d\u044f\u0436\u0435\u0441\u0442\u0432\u043e \u041c\u043e\u043d\u0430\u043a\u043e",
        common: "\u041c\u043e\u043d\u0430\u043a\u043e",
      },
      slk: { official: "Monack\u00e9 knie\u017eatstvo", common: "Monako" },
      spa: { official: "Principado de M\u00f3naco", common: "M\u00f3naco" },
      swe: { official: "Furstend\u00f6met Monaco", common: "Monaco" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0645\u0646\u0627\u06a9\u0648",
        common: "\u0645\u0648\u0646\u0627\u06a9\u0648",
      },
      zho: {
        official: "\u6469\u7eb3\u54e5\u516c\u56fd",
        common: "\u6469\u7eb3\u54e5",
      },
    },
    latlng: [43.73333333, 7.4],
    landlocked: false,
    borders: ["FRA"],
    area: 2.02,
    flag: "\ud83c\uddf2\ud83c\udde8",
    demonyms: {
      eng: { f: "Monegasque", m: "Monegasque" },
      fra: { f: "Mon\u00e9gasque", m: "Mon\u00e9gasque" },
    },
    callingCodes: ["+377"],
  },
  {
    name: {
      common: "Moldova",
      official: "Republic of Moldova",
      native: { ron: { official: "Republica Moldova", common: "Moldova" } },
    },
    tld: [".md"],
    cca2: "MD",
    ccn3: "498",
    cca3: "MDA",
    cioc: "MDA",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { MDL: { name: "Moldovan leu", symbol: "L" } },
    idd: { root: "+3", suffixes: ["73"] },
    capital: ["Chi\u0219in\u0103u"],
    altSpellings: [
      "MD",
      "Moldova, Republic of",
      "Republic of Moldova",
      "Republica Moldova",
    ],
    region: "Europe",
    subregion: "Eastern Europe",
    languages: { ron: "Moldavian" },
    translations: {
      ces: { official: "Moldavsk\u00e1 republika", common: "Moldavsko" },
      deu: { official: "Republik Moldau", common: "Moldawien" },
      est: { official: "Moldova Vabariik", common: "Moldova" },
      fin: { official: "Moldovan tasavalta", common: "Moldova" },
      fra: { official: "R\u00e9publique de Moldavie", common: "Moldavie" },
      hrv: { official: "Moldavija", common: "Moldova" },
      hun: {
        official: "Moldovai K\u00f6zt\u00e1rsas\u00e1g",
        common: "Moldova",
      },
      ita: { official: "Repubblica di Moldova", common: "Moldavia" },
      jpn: {
        official: "\u30e2\u30eb\u30c9\u30d0\u5171\u548c\u56fd",
        common: "\u30e2\u30eb\u30c9\u30d0\u5171\u548c\u56fd",
      },
      kor: {
        official: "\ubab0\ub3c4\ubc14 \uacf5\ud654\uad6d",
        common: "\ubab0\ub3c4\ubc14",
      },
      nld: { official: "Republiek Moldavi\u00eb", common: "Moldavi\u00eb" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0645\u0648\u0644\u062f\u0627\u0648\u06cc",
        common: "\u0645\u0648\u0644\u062f\u0627\u0648\u06cc",
      },
      pol: { official: "Republika Mo\u0142dawii", common: "Mo\u0142dawia" },
      por: {
        official: "Rep\u00fablica da Mold\u00e1via",
        common: "Mold\u00e1via",
      },
      rus: {
        official: "\u041c\u043e\u043b\u0434\u043e\u0432\u0430",
        common: "\u041c\u043e\u043b\u0434\u0430\u0432\u0438\u044f",
      },
      slk: { official: "Moldavsk\u00e1 republika", common: "Moldavsko" },
      spa: { official: "Rep\u00fablica de Moldova", common: "Moldavia" },
      swe: { official: "Republiken Moldavien", common: "Moldavien" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0645\u0627\u0644\u062f\u0648\u0648\u0627",
        common: "\u0645\u0627\u0644\u062f\u0648\u0648\u0627",
      },
      zho: {
        official: "\u6469\u5c14\u591a\u74e6\u5171\u548c\u56fd",
        common: "\u6469\u5c14\u591a\u74e6",
      },
    },
    latlng: [47, 29],
    landlocked: true,
    borders: ["ROU", "UKR"],
    area: 33846,
    flag: "\ud83c\uddf2\ud83c\udde9",
    demonyms: {
      eng: { f: "Moldovan", m: "Moldovan" },
      fra: { f: "Moldave", m: "Moldave" },
    },
    callingCodes: ["+373"],
  },
  {
    name: {
      common: "Madagascar",
      official: "Republic of Madagascar",
      native: {
        fra: {
          official: "R\u00e9publique de Madagascar",
          common: "Madagascar",
        },
        mlg: { official: "Repoblikan'i Madagasikara", common: "Madagasikara" },
      },
    },
    tld: [".mg"],
    cca2: "MG",
    ccn3: "450",
    cca3: "MDG",
    cioc: "MAD",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { MGA: { name: "Malagasy ariary", symbol: "Ar" } },
    idd: { root: "+2", suffixes: ["61"] },
    capital: ["Antananarivo"],
    altSpellings: [
      "MG",
      "Republic of Madagascar",
      "Repoblikan'i Madagasikara",
      "R\u00e9publique de Madagascar",
    ],
    region: "Africa",
    subregion: "Eastern Africa",
    languages: { fra: "French", mlg: "Malagasy" },
    translations: {
      ces: { official: "Madagaskarsk\u00e1 republika", common: "Madagaskar" },
      deu: { official: "Republik Madagaskar", common: "Madagaskar" },
      est: { official: "Madagaskari Vabariik", common: "Madagaskar" },
      fin: { official: "Madagaskarin tasavalta", common: "Madagaskar" },
      fra: { official: "R\u00e9publique de Madagascar", common: "Madagascar" },
      hrv: { official: "Republika Madagaskar", common: "Madagaskar" },
      hun: {
        official: "Madagaszk\u00e1ri K\u00f6zt\u00e1rsas\u00e1g",
        common: "Madagaszk\u00e1r",
      },
      ita: { official: "Repubblica del Madagascar", common: "Madagascar" },
      jpn: {
        official: "\u30de\u30c0\u30ac\u30b9\u30ab\u30eb\u5171\u548c\u56fd",
        common: "\u30de\u30c0\u30ac\u30b9\u30ab\u30eb",
      },
      kor: {
        official: "\ub9c8\ub2e4\uac00\uc2a4\uce74\ub974 \uacf5\ud654\uad6d",
        common: "\ub9c8\ub2e4\uac00\uc2a4\uce74\ub974",
      },
      nld: { official: "Republiek Madagaskar", common: "Madagaskar" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0645\u0627\u062f\u0627\u06af\u0627\u0633\u06a9\u0627\u0631",
        common: "\u0645\u0627\u062f\u0627\u06af\u0627\u0633\u06a9\u0627\u0631",
      },
      pol: { official: "Republika Madagaskaru", common: "Madagaskar" },
      por: {
        official: "Rep\u00fablica de Madag\u00e1scar",
        common: "Madag\u00e1scar",
      },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u041c\u0430\u0434\u0430\u0433\u0430\u0441\u043a\u0430\u0440",
        common: "\u041c\u0430\u0434\u0430\u0433\u0430\u0441\u043a\u0430\u0440",
      },
      slk: { official: "Madagaskarsk\u00e1 republika", common: "Madagaskar" },
      spa: { official: "Rep\u00fablica de Madagascar", common: "Madagascar" },
      swe: { official: "Republiken Madagaskar", common: "Madagaskar" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0645\u0688\u063a\u0627\u0633\u06a9\u0631",
        common: "\u0645\u0688\u063a\u0627\u0633\u06a9\u0631",
      },
      zho: {
        official: "\u9a6c\u8fbe\u52a0\u65af\u52a0\u5171\u548c\u56fd",
        common: "\u9a6c\u8fbe\u52a0\u65af\u52a0",
      },
    },
    latlng: [-20, 47],
    landlocked: false,
    borders: [],
    area: 587041,
    flag: "\ud83c\uddf2\ud83c\uddec",
    demonyms: {
      eng: { f: "Malagasy", m: "Malagasy" },
      fra: { f: "Malgache", m: "Malgache" },
    },
    callingCodes: ["+261"],
  },
  {
    name: {
      common: "Maldives",
      official: "Republic of the Maldives",
      native: {
        div: {
          official:
            "\u078b\u07a8\u0788\u07ac\u0780\u07a8\u0783\u07a7\u0787\u07b0\u0796\u07ad\u078e\u07ac \u0796\u07aa\u0789\u07b0\u0780\u07ab\u0783\u07a8\u0787\u07b0\u0794\u07a7",
          common:
            "\u078b\u07a8\u0788\u07ac\u0780\u07a8\u0783\u07a7\u0787\u07b0\u0796\u07ad\u078e\u07ac",
        },
      },
    },
    tld: [".mv"],
    cca2: "MV",
    ccn3: "462",
    cca3: "MDV",
    cioc: "MDV",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { MVR: { name: "Maldivian rufiyaa", symbol: ".\u0783" } },
    idd: { root: "+9", suffixes: ["60"] },
    capital: ["Mal\u00e9"],
    altSpellings: [
      "MV",
      "Maldive Islands",
      "Republic of the Maldives",
      "Dhivehi Raajjeyge Jumhooriyya",
    ],
    region: "Asia",
    subregion: "Southern Asia",
    languages: { div: "Maldivian" },
    translations: {
      ces: { official: "Maledivsk\u00e1 republika", common: "Maledivy" },
      deu: { official: "Republik Malediven", common: "Malediven" },
      est: { official: "Maldiivi Vabariik", common: "Maldiivid" },
      fin: { official: "Malediivien tasavalta", common: "Malediivit" },
      fra: { official: "R\u00e9publique des Maldives", common: "Maldives" },
      hrv: { official: "Republika Maldivi", common: "Maldivi" },
      hun: { official: "Mald\u00edv-szigetek", common: "Mald\u00edv-szigetek" },
      ita: { official: "Repubblica delle Maldive", common: "Maldive" },
      jpn: {
        official: "\u30e2\u30eb\u30c7\u30a3\u30d6\u5171\u548c\u56fd",
        common: "\u30e2\u30eb\u30c7\u30a3\u30d6",
      },
      kor: {
        official: "\ubab0\ub514\ube0c \uacf5\ud654\uad6d",
        common: "\ubab0\ub514\ube0c",
      },
      nld: { official: "Republiek van de Malediven", common: "Maldiven" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0645\u0627\u0644\u062f\u06cc\u0648",
        common: "\u0645\u0627\u0644\u062f\u06cc\u0648",
      },
      pol: { official: "Republika Malediw\u00f3w", common: "Malediwy" },
      por: { official: "Rep\u00fablica das Maldivas", common: "Maldivas" },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u041c\u0430\u043b\u044c\u0434\u0438\u0432\u044b",
        common: "\u041c\u0430\u043b\u044c\u0434\u0438\u0432\u044b",
      },
      slk: { official: "Maldivsk\u00e1 republika", common: "Maldivy" },
      spa: { official: "Rep\u00fablica de las Maldivas", common: "Maldivas" },
      swe: { official: "Republiken Maldiverna", common: "Maldiverna" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0645\u0627\u0644\u062f\u06cc\u067e",
        common: "\u0645\u0627\u0644\u062f\u06cc\u067e",
      },
      zho: {
        official: "\u9a6c\u5c14\u4ee3\u592b\u5171\u548c\u56fd",
        common: "\u9a6c\u5c14\u4ee3\u592b",
      },
    },
    latlng: [3.25, 73],
    landlocked: false,
    borders: [],
    area: 300,
    flag: "\ud83c\uddf2\ud83c\uddfb",
    demonyms: {
      eng: { f: "Maldivan", m: "Maldivan" },
      fra: { f: "Maldivienne", m: "Maldivien" },
    },
    callingCodes: ["+960"],
  },
  {
    name: {
      common: "Mexico",
      official: "United Mexican States",
      native: {
        spa: { official: "Estados Unidos Mexicanos", common: "M\u00e9xico" },
      },
    },
    tld: [".mx"],
    cca2: "MX",
    ccn3: "484",
    cca3: "MEX",
    cioc: "MEX",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { MXN: { name: "Mexican peso", symbol: "$" } },
    idd: { root: "+5", suffixes: ["2"] },
    capital: ["Mexico City"],
    altSpellings: [
      "MX",
      "Mexicanos",
      "United Mexican States",
      "Estados Unidos Mexicanos",
    ],
    region: "Americas",
    subregion: "North America",
    languages: { spa: "Spanish" },
    translations: {
      ces: {
        official: "Spojen\u00e9 st\u00e1ty mexick\u00e9",
        common: "Mexiko",
      },
      deu: { official: "Vereinigte Mexikanische Staaten", common: "Mexiko" },
      est: { official: "Mehhiko \u00dchendriigid", common: "Mehhiko" },
      fin: { official: "Meksikon yhdysvallat", common: "Meksiko" },
      fra: { official: "\u00c9tats-Unis du Mexique", common: "Mexique" },
      hrv: {
        official: "Sjedinjene Meksi\u010dke Dr\u017eave",
        common: "Meksiko",
      },
      hun: {
        official: "Mexik\u00f3i Egyes\u00fclt \u00c1llamok",
        common: "Mexik\u00f3",
      },
      ita: { official: "Stati Uniti del Messico", common: "Messico" },
      jpn: {
        official: "\u30e1\u30ad\u30b7\u30b3\u5408\u8846\u56fd",
        common: "\u30e1\u30ad\u30b7\u30b3",
      },
      kor: {
        official: "\uba55\uc2dc\ucf54 \ud569\uc911\uad6d",
        common: "\uba55\uc2dc\ucf54",
      },
      nld: { official: "Verenigde Mexicaanse Staten", common: "Mexico" },
      per: {
        official:
          "\u0627\u06cc\u0627\u0644\u0627\u062a \u0645\u062a\u062d\u062f \u0645\u06a9\u0632\u06cc\u06a9",
        common: "\u0645\u06a9\u0632\u06cc\u06a9",
      },
      pol: {
        official: "Meksyka\u0144skie Stany Zjednoczone",
        common: "Meksyk",
      },
      por: { official: "Estados Unidos Mexicanos", common: "M\u00e9xico" },
      rus: {
        official:
          "\u041c\u0435\u043a\u0441\u0438\u043a\u0430\u043d\u0441\u043a\u0438\u0435 \u0421\u043e\u0435\u0434\u0438\u043d\u0451\u043d\u043d\u044b\u0435 \u0428\u0442\u0430\u0442\u044b",
        common: "\u041c\u0435\u043a\u0441\u0438\u043a\u0430",
      },
      slk: {
        official: "Spojen\u00e9 \u0161t\u00e1\u0161y mexick\u00e9",
        common: "Mexiko",
      },
      spa: { official: "Estados Unidos Mexicanos", common: "M\u00e9xico" },
      swe: { official: "Mexikos f\u00f6renta stater", common: "Mexiko" },
      urd: {
        official:
          "\u0631\u06cc\u0627\u0633\u062a\u06c1\u0627\u0626\u06d2 \u0645\u062a\u062d\u062f\u06c1 \u0645\u06cc\u06a9\u0633\u06cc\u06a9\u0648",
        common: "\u0645\u06cc\u06a9\u0633\u06cc\u06a9\u0648",
      },
      zho: {
        official: "\u58a8\u897f\u54e5\u5408\u4f17\u56fd",
        common: "\u58a8\u897f\u54e5",
      },
    },
    latlng: [23, -102],
    landlocked: false,
    borders: ["BLZ", "GTM", "USA"],
    area: 1964375,
    flag: "\ud83c\uddf2\ud83c\uddfd",
    demonyms: {
      eng: { f: "Mexican", m: "Mexican" },
      fra: { f: "Mexicaine", m: "Mexicain" },
    },
    callingCodes: ["+52"],
  },
  {
    name: {
      common: "Marshall Islands",
      official: "Republic of the Marshall Islands",
      native: {
        eng: {
          official: "Republic of the Marshall Islands",
          common: "Marshall Islands",
        },
        mah: {
          official: "Republic of the Marshall Islands",
          common: "M\u0327aje\u013c",
        },
      },
    },
    tld: [".mh"],
    cca2: "MH",
    ccn3: "584",
    cca3: "MHL",
    cioc: "MHL",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { USD: { name: "United States dollar", symbol: "$" } },
    idd: { root: "+6", suffixes: ["92"] },
    capital: ["Majuro"],
    altSpellings: [
      "MH",
      "Republic of the Marshall Islands",
      "Aolep\u0101n Aor\u014dkin M\u0327aje\u013c",
    ],
    region: "Oceania",
    subregion: "Micronesia",
    languages: { eng: "English", mah: "Marshallese" },
    translations: {
      ces: {
        official: "Republika Marshallovy ostrovy",
        common: "Marshallovy ostrovy",
      },
      deu: { official: "Republik Marshallinseln", common: "Marshallinseln" },
      est: {
        official: "Marshalli Saarte Vabariik",
        common: "Marshalli Saared",
      },
      fin: {
        official: "Marshallinsaarten tasavalta",
        common: "Marshallinsaaret",
      },
      fra: {
        official: "R\u00e9publique des \u00celes Marshall",
        common: "\u00celes Marshall",
      },
      hrv: {
        official: "Republika Mar\u0161alovi Otoci",
        common: "Mar\u0161alovi Otoci",
      },
      hun: { official: "Marshall-szigetek", common: "Marshall-szigetek" },
      ita: {
        official: "Repubblica delle Isole Marshall",
        common: "Isole Marshall",
      },
      jpn: {
        official:
          "\u30de\u30fc\u30b7\u30e3\u30eb\u8af8\u5cf6\u5171\u548c\u56fd",
        common: "\u30de\u30fc\u30b7\u30e3\u30eb\u8af8\u5cf6",
      },
      kor: {
        official: "\ub9c8\uc15c \uc81c\ub3c4 \uacf5\ud654\uad6d",
        common: "\ub9c8\uc15c \uc81c\ub3c4",
      },
      nld: {
        official: "Republiek van de Marshall-eilanden",
        common: "Marshalleilanden",
      },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u062c\u0632\u0627\u06cc\u0631 \u0645\u0627\u0631\u0634\u0627\u0644",
        common:
          "\u062c\u0632\u0627\u06cc\u0631 \u0645\u0627\u0631\u0634\u0627\u0644",
      },
      pol: { official: "Republika Wysp Marshalla", common: "Wyspy Marshalla" },
      por: {
        official: "Rep\u00fablica das Ilhas Marshall",
        common: "Ilhas Marshall",
      },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u041c\u0430\u0440\u0448\u0430\u043b\u043b\u043e\u0432\u044b \u043e\u0441\u0442\u0440\u043e\u0432\u0430",
        common:
          "\u041c\u0430\u0440\u0448\u0430\u043b\u043b\u043e\u0432\u044b \u041e\u0441\u0442\u0440\u043e\u0432\u0430",
      },
      slk: {
        official: "Republika Marshallov\u00fdch ostrovov",
        common: "Marshallove ostrovy",
      },
      spa: {
        official: "Rep\u00fablica de las Islas Marshall",
        common: "Islas Marshall",
      },
      swe: {
        official: "Republiken Marshall\u00f6arna",
        common: "Marshall\u00f6arna",
      },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u062c\u0632\u0627\u0626\u0631 \u0645\u0627\u0631\u0634\u0644",
        common: "\u062c\u0632\u0627\u0626\u0631 \u0645\u0627\u0631\u0634\u0644",
      },
      zho: {
        official: "\u9a6c\u7ecd\u5c14\u7fa4\u5c9b\u5171\u548c\u56fd",
        common: "\u9a6c\u7ecd\u5c14\u7fa4\u5c9b",
      },
    },
    latlng: [9, 168],
    landlocked: false,
    borders: [],
    area: 181,
    flag: "\ud83c\uddf2\ud83c\udded",
    demonyms: {
      eng: { f: "Marshallese", m: "Marshallese" },
      fra: { f: "Marshallaise", m: "Marshallais" },
    },
    callingCodes: ["+692"],
  },
  {
    name: {
      common: "North Macedonia",
      official: "Republic of North Macedonia",
      native: {
        mkd: {
          official:
            "\u0420\u0435\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0421\u0435\u0432\u0435\u0440\u043d\u0430 \u041c\u0430\u043a\u0435\u0434\u043e\u043d\u0438\u0458\u0430",
          common:
            "\u041c\u0430\u043a\u0435\u0434\u043e\u043d\u0438\u0458\u0430",
        },
      },
    },
    tld: [".mk"],
    cca2: "MK",
    ccn3: "807",
    cca3: "MKD",
    cioc: "MKD",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { MKD: { name: "denar", symbol: "den" } },
    idd: { root: "+3", suffixes: ["89"] },
    capital: ["Skopje"],
    altSpellings: [
      "MK",
      "The former Yugoslav Republic of Macedonia",
      "Republic of North Macedonia",
      "Macedonia, The Former Yugoslav Republic of",
      "\u0420\u0435\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0421\u0435\u0432\u0435\u0440\u043d\u0430 \u041c\u0430\u043a\u0435\u0434\u043e\u043d\u0438\u0458\u0430",
    ],
    region: "Europe",
    subregion: "Southeast Europe",
    languages: { mkd: "Macedonian" },
    translations: {
      ces: {
        official: "Republika Severn\u00ed Makedonie",
        common: "Severn\u00ed Makedonie",
      },
      deu: { official: "Republik Nordmazedonien", common: "Nordmazedonien" },
      est: {
        official: "P\u00f5hja-Makedoonia Vabariik",
        common: "P\u00f5hja-Makedoonia",
      },
      fin: {
        official: "Pohjois-Makedonian tasavalta",
        common: "Pohjois-Makedonia",
      },
      fra: {
        official: "R\u00e9publique de Mac\u00e9doine du Nord",
        common: "Mac\u00e9doine du Nord",
      },
      hrv: {
        official: "Republika Sjeverna Makedonija",
        common: "Sjeverna Makedonija",
      },
      hun: {
        official: "\u00c9szak-maced\u00f3n K\u00f6zt\u00e1rsas\u00e1g",
        common: "\u00c9szak-Maced\u00f3nia",
      },
      ita: {
        official: "Repubblica di Macedonia del Nord",
        common: "Macedonia del Nord",
      },
      jpn: {
        official: "\u5317\u30de\u30b1\u30c9\u30cb\u30a2\u5171\u548c\u56fd",
        common: "\u5317\u30de\u30b1\u30c9\u30cb\u30a2 ",
      },
      kor: {
        official: "\ubd81\ub9c8\ucf00\ub3c4\ub2c8\uc544 \uacf5\ud654\uad6d",
        common: "\ubd81\ub9c8\ucf00\ub3c4\ub2c8\uc544",
      },
      nld: {
        official: "Republiek Noord-Macedoni\u00eb",
        common: "Noord-Macedoni\u00eb",
      },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0645\u0642\u062f\u0648\u0646\u06cc\u0647 \u0634\u0645\u0627\u0644\u06cc",
        common:
          "\u0645\u0642\u062f\u0648\u0646\u06cc\u0647 \u0634\u0645\u0627\u0644\u06cc",
      },
      pol: {
        official: "Republika Macedonii P\u00f3\u0142nocnej",
        common: "Macedonia P\u00f3\u0142nocna",
      },
      por: {
        official: "Rep\u00fablica da Maced\u00f4nia do Norte",
        common: "Maced\u00f3nia do Norte",
      },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0421\u0435\u0432\u0435\u0440\u043d\u0430\u044f \u041c\u0430\u043a\u0435\u0434\u043e\u043d\u0438\u044f",
        common:
          "\u0421\u0435\u0432\u0435\u0440\u043d\u0430\u044f \u041c\u0430\u043a\u0435\u0434\u043e\u043d\u0438\u044f",
      },
      slk: {
        official: "Severomaced\u00f3nska republika",
        common: "Severn\u00e9 Maced\u00f3nsko",
      },
      spa: {
        official: "Rep\u00fablica de Macedonia del Norte",
        common: "Macedonia del Norte",
      },
      swe: { official: "Republiken Nordmakedonien", common: "Nordmakedonien" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0645\u0642\u062f\u0648\u0646\u06cc\u06c1",
        common:
          "\u0634\u0645\u0627\u0644\u06cc \u0645\u0642\u062f\u0648\u0646\u06cc\u06c1",
      },
      zho: {
        official: "\u5317\u99ac\u5176\u9813\u5171\u548c\u570b",
        common: "\u5317\u99ac\u5176\u9813",
      },
    },
    latlng: [41.83333333, 22],
    landlocked: true,
    borders: ["ALB", "BGR", "GRC", "UNK", "SRB"],
    area: 25713,
    flag: "\ud83c\uddf2\ud83c\uddf0",
    demonyms: {
      eng: { f: "Macedonian", m: "Macedonian" },
      fra: { f: "Mac\u00e9donienne", m: "Mac\u00e9donien" },
    },
    callingCodes: ["+389"],
  },
  {
    name: {
      common: "Mali",
      official: "Republic of Mali",
      native: { fra: { official: "R\u00e9publique du Mali", common: "Mali" } },
    },
    tld: [".ml"],
    cca2: "ML",
    ccn3: "466",
    cca3: "MLI",
    cioc: "MLI",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { XOF: { name: "West African CFA franc", symbol: "Fr" } },
    idd: { root: "+2", suffixes: ["23"] },
    capital: ["Bamako"],
    altSpellings: ["ML", "Republic of Mali", "R\u00e9publique du Mali"],
    region: "Africa",
    subregion: "Western Africa",
    languages: { fra: "French" },
    translations: {
      ces: { official: "Republika Mali", common: "Mali" },
      deu: { official: "Republik Mali", common: "Mali" },
      est: { official: "Mali Vabariik", common: "Mali" },
      fin: { official: "Malin tasavalta", common: "Mali" },
      fra: { official: "R\u00e9publique du Mali", common: "Mali" },
      hrv: { official: "Republika Mali", common: "Mali" },
      hun: { official: "Mali K\u00f6zt\u00e1rsas\u00e1g", common: "Mali" },
      ita: { official: "Repubblica del Mali", common: "Mali" },
      jpn: {
        official: "\u30de\u30ea\u5171\u548c\u56fd",
        common: "\u30de\u30ea",
      },
      kor: {
        official: "\ub9d0\ub9ac \uacf5\ud654\uad6d",
        common: "\ub9d0\ub9ac",
      },
      nld: { official: "Republiek Mali", common: "Mali" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0645\u0627\u0644\u06cc",
        common: "\u0645\u0627\u0644\u06cc",
      },
      pol: { official: "Republika Mali", common: "Mali" },
      por: { official: "Rep\u00fablica do Mali", common: "Mali" },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u041c\u0430\u043b\u0438",
        common: "\u041c\u0430\u043b\u0438",
      },
      slk: { official: "Malijsk\u00e1 republika", common: "Mali" },
      spa: { official: "Rep\u00fablica de Mal\u00ed", common: "Mali" },
      swe: { official: "Republiken Mali", common: "Mali" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0645\u0627\u0644\u06cc",
        common: "\u0645\u0627\u0644\u06cc",
      },
      zho: {
        official: "\u9a6c\u91cc\u5171\u548c\u56fd",
        common: "\u9a6c\u91cc",
      },
    },
    latlng: [17, -4],
    landlocked: true,
    borders: ["DZA", "BFA", "GIN", "CIV", "MRT", "NER", "SEN"],
    area: 1240192,
    flag: "\ud83c\uddf2\ud83c\uddf1",
    demonyms: {
      eng: { f: "Malian", m: "Malian" },
      fra: { f: "Malienne", m: "Malien" },
    },
    callingCodes: ["+223"],
  },
  {
    name: {
      common: "Malta",
      official: "Republic of Malta",
      native: {
        eng: { official: "Republic of Malta", common: "Malta" },
        mlt: { official: "Repubblika ta ' Malta", common: "Malta" },
      },
    },
    tld: [".mt"],
    cca2: "MT",
    ccn3: "470",
    cca3: "MLT",
    cioc: "MLT",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { EUR: { name: "Euro", symbol: "\u20ac" } },
    idd: { root: "+3", suffixes: ["56"] },
    capital: ["Valletta"],
    altSpellings: ["MT", "Republic of Malta", "Repubblika ta' Malta"],
    region: "Europe",
    subregion: "Southern Europe",
    languages: { eng: "English", mlt: "Maltese" },
    translations: {
      ces: { official: "Maltsk\u00e1 republika", common: "Malta" },
      deu: { official: "Republik Malta", common: "Malta" },
      est: { official: "Malta Vabariik", common: "Malta" },
      fin: { official: "Maltan tasavalta", common: "Malta" },
      fra: { official: "R\u00e9publique de Malte", common: "Malte" },
      hrv: { official: "Republika Malta", common: "Malta" },
      hun: {
        official: "M\u00e1ltai K\u00f6zt\u00e1rsas\u00e1g",
        common: "M\u00e1lta",
      },
      ita: { official: "Repubblica di Malta", common: "Malta" },
      jpn: {
        official: "\u30de\u30eb\u30bf\u5171\u548c\u56fd",
        common: "\u30de\u30eb\u30bf",
      },
      kor: {
        official: "\ubab0\ud0c0 \uacf5\ud654\uad6d",
        common: "\ubab0\ud0c0",
      },
      nld: { official: "Republiek Malta", common: "Malta" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0645\u0627\u0644\u062a",
        common: "\u0645\u0627\u0644\u062a",
      },
      pol: { official: "Republika Malty", common: "Malta" },
      por: { official: "Rep\u00fablica de Malta", common: "Malta" },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u041c\u0430\u043b\u044c\u0442\u0430",
        common: "\u041c\u0430\u043b\u044c\u0442\u0430",
      },
      slk: { official: "Maltsk\u00e1 republika", common: "Malta" },
      spa: { official: "Rep\u00fablica de Malta", common: "Malta" },
      swe: { official: "Republiken Malta", common: "Malta" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0645\u0627\u0644\u0679\u0627",
        common: "\u0645\u0627\u0644\u0679\u0627",
      },
      zho: {
        official: "\u9a6c\u8033\u4ed6\u5171\u548c\u56fd",
        common: "\u9a6c\u8033\u4ed6",
      },
    },
    latlng: [35.83333333, 14.58333333],
    landlocked: false,
    borders: [],
    area: 316,
    flag: "\ud83c\uddf2\ud83c\uddf9",
    demonyms: {
      eng: { f: "Maltese", m: "Maltese" },
      fra: { f: "Maltaise", m: "Maltais" },
    },
    callingCodes: ["+356"],
  },
  {
    name: {
      common: "Myanmar",
      official: "Republic of the Union of Myanmar",
      native: {
        mya: {
          official:
            "\u1015\u103c\u100a\u103a\u1011\u1031\u102c\u1004\u103a\u1005\u102f \u101e\u1019\u1039\u1019\u1010 \u1019\u103c\u1014\u103a\u1019\u102c\u1014\u102d\u102f\u1004\u103a\u1004\u1036\u1010\u1031\u102c\u103a",
          common: "\u1019\u103c\u1014\u103a\u1019\u102c",
        },
      },
    },
    tld: [".mm"],
    cca2: "MM",
    ccn3: "104",
    cca3: "MMR",
    cioc: "MYA",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { MMK: { name: "Burmese kyat", symbol: "Ks" } },
    idd: { root: "+9", suffixes: ["5"] },
    capital: ["Naypyidaw"],
    altSpellings: [
      "MM",
      "Burma",
      "Republic of the Union of Myanmar",
      "Pyidaunzu Thanm\u0103da My\u0103ma Nainngandaw",
    ],
    region: "Asia",
    subregion: "South-Eastern Asia",
    languages: { mya: "Burmese" },
    translations: {
      ces: { official: "Republika Myanmarsk\u00fd svaz", common: "Myanmar" },
      deu: { official: "Republik der Union Myanmar", common: "Myanmar" },
      est: { official: "Myanmari Liidu Vabariik", common: "Myanmar" },
      fin: { official: "Myanmarin liiton tasavalta", common: "Myanmar" },
      fra: {
        official: "R\u00e9publique de l'Union du Myanmar",
        common: "Birmanie",
      },
      hrv: { official: "Republika Unije Mijanmar", common: "Mijanmar" },
      hun: {
        official:
          "Mianmari \u00c1llamsz\u00f6vets\u00e9g K\u00f6zt\u00e1rsas\u00e1ga",
        common: "Mianmar",
      },
      ita: {
        official: "Repubblica dell'Unione di Myanmar",
        common: "Birmania",
      },
      jpn: {
        official:
          "\u30df\u30e3\u30f3\u30de\u30fc\u9023\u90a6\u5171\u548c\u56fd",
        common: "\u30df\u30e3\u30f3\u30de\u30fc",
      },
      kor: {
        official: "\ubbf8\uc580\ub9c8 \uc5f0\ubc29 \uacf5\ud654\uad6d",
        common: "\ubbf8\uc580\ub9c8",
      },
      nld: { official: "Republiek van de Unie van Myanmar", common: "Myanmar" },
      per: {
        official:
          "\u0627\u062a\u062d\u0627\u062f\u06cc\u0647 \u062c\u0645\u0647\u0648\u0631\u06cc \u0645\u06cc\u0627\u0646\u0645\u0627\u0631",
        common: "\u0645\u06cc\u0627\u0646\u0645\u0627\u0631",
      },
      pol: { official: "Republika Zwi\u0105zku Mjanmy", common: "Mjanma" },
      por: {
        official: "Rep\u00fablica da Uni\u00e3o de Myanmar",
        common: "Myanmar",
      },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0421\u043e\u044e\u0437\u0430 \u041c\u044c\u044f\u043d\u043c\u0430",
        common: "\u041c\u044c\u044f\u043d\u043c\u0430",
      },
      slk: {
        official: "Mjanmarsk\u00e1 zv\u00e4zov\u00e1 republika",
        common: "Mjanmarsko",
      },
      spa: {
        official: "Rep\u00fablica de la Uni\u00f3n de Myanmar",
        common: "Myanmar",
      },
      swe: { official: "Republiken Unionen Myanmar", common: "Myanmar" },
      urd: {
        official:
          "\u0645\u062a\u062d\u062f\u06c1 \u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0645\u06cc\u0627\u0646\u0645\u0627\u0631",
        common: "\u0645\u06cc\u0627\u0646\u0645\u0627\u0631",
      },
      zho: {
        official: "\u7f05\u7538\u8054\u90a6\u5171\u548c\u56fd",
        common: "\u7f05\u7538",
      },
    },
    latlng: [22, 98],
    landlocked: false,
    borders: ["BGD", "CHN", "IND", "LAO", "THA"],
    area: 676578,
    flag: "\ud83c\uddf2\ud83c\uddf2",
    demonyms: {
      eng: { f: "Burmese", m: "Burmese" },
      fra: { f: "Birmane", m: "Birman" },
    },
    callingCodes: ["+95"],
  },
  {
    name: {
      common: "Montenegro",
      official: "Montenegro",
      native: {
        cnr: {
          official: "\u0426\u0440\u043d\u0430 \u0413\u043e\u0440\u0430",
          common: "\u0426\u0440\u043d\u0430 \u0413\u043e\u0440\u0430",
        },
      },
    },
    tld: [".me"],
    cca2: "ME",
    ccn3: "499",
    cca3: "MNE",
    cioc: "MNE",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { EUR: { name: "Euro", symbol: "\u20ac" } },
    idd: { root: "+3", suffixes: ["82"] },
    capital: ["Podgorica"],
    altSpellings: ["ME", "Crna Gora"],
    region: "Europe",
    subregion: "Southeast Europe",
    languages: { cnr: "Montenegrin" },
    translations: {
      ces: { official: "\u010cern\u00e1 Hora", common: "\u010cern\u00e1 Hora" },
      deu: { official: "Montenegro", common: "Montenegro" },
      est: { official: "Montenegro", common: "Montenegro" },
      fin: { official: "Montenegro", common: "Montenegro" },
      fra: { official: "Mont\u00e9n\u00e9gro", common: "Mont\u00e9n\u00e9gro" },
      hrv: { official: "Crna Gora", common: "Crna Gora" },
      hun: { official: "Montenegr\u00f3", common: "Montenegr\u00f3" },
      ita: { official: "Montenegro", common: "Montenegro" },
      jpn: {
        official: "\u30e2\u30f3\u30c6\u30cd\u30b0\u30ed",
        common: "\u30e2\u30f3\u30c6\u30cd\u30b0\u30ed",
      },
      kor: {
        official: "\ubaac\ud14c\ub124\uadf8\ub85c",
        common: "\ubaac\ud14c\ub124\uadf8\ub85c",
      },
      nld: { official: "Montenegro", common: "Montenegro" },
      per: {
        official:
          "\u0645\u0648\u0646\u062a\u0647\u200c\u0646\u06af\u0631\u0648",
        common: "\u0645\u0648\u0646\u062a\u0647\u200c\u0646\u06af\u0631\u0648",
      },
      pol: { official: "Czarnog\u00f3ra", common: "Czarnog\u00f3ra" },
      por: { official: "Montenegro", common: "Montenegro" },
      rus: {
        official:
          "\u0427\u0435\u0440\u043d\u043e\u0433\u043e\u0440\u0438\u044f",
        common: "\u0427\u0435\u0440\u043d\u043e\u0433\u043e\u0440\u0438\u044f",
      },
      slk: { official: "\u010cierna Hora", common: "\u010cierna Hora" },
      spa: { official: "Montenegro", common: "Montenegro" },
      swe: { official: "Montenegro", common: "Montenegro" },
      urd: {
        official:
          "\u0645\u0648\u0646\u0679\u06cc\u0646\u06cc\u06af\u0631\u0648",
        common: "\u0645\u0648\u0646\u0679\u06cc\u0646\u06cc\u06af\u0631\u0648",
      },
      zho: { official: "\u9ed1\u5c71", common: "\u9ed1\u5c71" },
    },
    latlng: [42.5, 19.3],
    landlocked: false,
    borders: ["ALB", "BIH", "HRV", "UNK", "SRB"],
    area: 13812,
    flag: "\ud83c\uddf2\ud83c\uddea",
    demonyms: {
      eng: { f: "Montenegrin", m: "Montenegrin" },
      fra: { f: "Mont\u00e9n\u00e9grine", m: "Mont\u00e9n\u00e9grin" },
    },
    callingCodes: ["+382"],
  },
  {
    name: {
      common: "Mongolia",
      official: "Mongolia",
      native: {
        mon: {
          official: "\u041c\u043e\u043d\u0433\u043e\u043b \u0443\u043b\u0441",
          common: "\u041c\u043e\u043d\u0433\u043e\u043b \u0443\u043b\u0441",
        },
      },
    },
    tld: [".mn"],
    cca2: "MN",
    ccn3: "496",
    cca3: "MNG",
    cioc: "MGL",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: {
      MNT: { name: "Mongolian t\u00f6gr\u00f6g", symbol: "\u20ae" },
    },
    idd: { root: "+9", suffixes: ["76"] },
    capital: ["Ulan Bator"],
    altSpellings: ["MN"],
    region: "Asia",
    subregion: "Eastern Asia",
    languages: { mon: "Mongolian" },
    translations: {
      ces: { official: "St\u00e1t Mongolsko", common: "Mongolsko" },
      deu: { official: "Mongolei", common: "Mongolei" },
      est: { official: "Mongoolia", common: "Mongoolia" },
      fin: { official: "Mongolian tasavalta", common: "Mongolia" },
      fra: { official: "Mongolie", common: "Mongolie" },
      hrv: { official: "Mongolija", common: "Mongolija" },
      hun: { official: "Mong\u00f3lia", common: "Mong\u00f3lia" },
      ita: { official: "Mongolia", common: "Mongolia" },
      jpn: {
        official: "\u30e2\u30f3\u30b4\u30eb",
        common: "\u30e2\u30f3\u30b4\u30eb",
      },
      kor: { official: "\ubabd\uace8", common: "\ubabd\uace8\uad6d" },
      nld: { official: "Mongoli\u00eb", common: "Mongoli\u00eb" },
      per: {
        official: "\u0645\u063a\u0648\u0644\u0633\u062a\u0627\u0646",
        common: "\u0645\u063a\u0648\u0644\u0633\u062a\u0627\u0646",
      },
      pol: { official: "Mongolia", common: "Mongolia" },
      por: { official: "Mong\u00f3lia", common: "Mong\u00f3lia" },
      rus: {
        official: "\u041c\u043e\u043d\u0433\u043e\u043b\u0438\u044f",
        common: "\u041c\u043e\u043d\u0433\u043e\u043b\u0438\u044f",
      },
      slk: { official: "Mongolsko", common: "Mongolsko" },
      spa: { official: "Mongolia", common: "Mongolia" },
      swe: { official: "Mongoliet", common: "Mongoliet" },
      urd: {
        official: "\u0645\u0646\u06af\u0648\u0644\u06cc\u0627",
        common: "\u0645\u0646\u06af\u0648\u0644\u06cc\u0627",
      },
      zho: { official: "\u8499\u53e4", common: "\u8499\u53e4" },
    },
    latlng: [46, 105],
    landlocked: true,
    borders: ["CHN", "RUS"],
    area: 1564110,
    flag: "\ud83c\uddf2\ud83c\uddf3",
    demonyms: {
      eng: { f: "Mongolian", m: "Mongolian" },
      fra: { f: "Mongole", m: "Mongol" },
    },
    callingCodes: ["+976"],
  },
  {
    name: {
      common: "Northern Mariana Islands",
      official: "Commonwealth of the Northern Mariana Islands",
      native: {
        cal: {
          official: "Commonwealth of the Northern Mariana Islands",
          common: "Northern Mariana Islands",
        },
        cha: {
          official: "Sankattan Siha Na Islas Mari\u00e5nas",
          common: "Na Islas Mari\u00e5nas",
        },
        eng: {
          official: "Commonwealth of the Northern Mariana Islands",
          common: "Northern Mariana Islands",
        },
      },
    },
    tld: [".mp"],
    cca2: "MP",
    ccn3: "580",
    cca3: "MNP",
    cioc: "",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: { USD: { name: "United States dollar", symbol: "$" } },
    idd: { root: "+1", suffixes: ["670"] },
    capital: ["Saipan"],
    altSpellings: [
      "MP",
      "Commonwealth of the Northern Mariana Islands",
      "Sankattan Siha Na Islas Mari\u00e5nas",
    ],
    region: "Oceania",
    subregion: "Micronesia",
    languages: { cal: "Carolinian", cha: "Chamorro", eng: "English" },
    translations: {
      ces: {
        official: "Spole\u010denstv\u00ed Severn\u00edch Marian",
        common: "Severn\u00ed Mariany",
      },
      deu: {
        official: "Commonwealth der N\u00f6rdlichen Marianen",
        common: "N\u00f6rdliche Marianen",
      },
      est: {
        official: "P\u00f5hja-Mariaani \u00dchendus",
        common: "P\u00f5hja-Mariaanid",
      },
      fin: { official: "Pohjois-Mariaanit", common: "Pohjois-Mariaanit" },
      fra: {
        official: "Commonwealth des \u00eeles Mariannes du Nord",
        common: "\u00celes Mariannes du Nord",
      },
      hrv: {
        official: "Zajednica je Sjeverni Marijanski otoci",
        common: "Sjevernomarijanski otoci",
      },
      hun: {
        official: "\u00c9szaki-Mariana-szigetek",
        common: "\u00c9szaki-Mariana-szigetek",
      },
      ita: {
        official: "Commonwealth delle Isole Marianne Settentrionali",
        common: "Isole Marianne Settentrionali",
      },
      jpn: {
        official: "\u5317\u30de\u30ea\u30a2\u30ca\u8af8\u5cf6",
        common: "\u5317\u30de\u30ea\u30a2\u30ca\u8af8\u5cf6",
      },
      kor: {
        official: "\ubd81\ub9c8\ub9ac\uc544\ub098 \uc81c\ub3c4",
        common: "\ubd81\ub9c8\ub9ac\uc544\ub098 \uc81c\ub3c4",
      },
      nld: {
        official: "Commonwealth van de Noordelijke Marianen",
        common: "Noordelijke Marianeneilanden",
      },
      per: {
        official:
          "\u062c\u0632\u0627\u06cc\u0631 \u0645\u0627\u0631\u06cc\u0627\u0646\u0627\u06cc \u0634\u0645\u0627\u0644\u06cc",
        common:
          "\u062c\u0632\u0627\u06cc\u0631 \u0645\u0627\u0631\u06cc\u0627\u0646\u0627\u06cc \u0634\u0645\u0627\u0644\u06cc",
      },
      pol: {
        official: "Wsp\u00f3lnota Marian\u00f3w P\u00f3\u0142nocnych",
        common: "Mariany P\u00f3\u0142nocne",
      },
      por: {
        official: "Comunidade das Ilhas Marianas do Norte",
        common: "Marianas Setentrionais",
      },
      rus: {
        official:
          "\u0421\u043e\u0434\u0440\u0443\u0436\u0435\u0441\u0442\u0432\u043e \u0421\u0435\u0432\u0435\u0440\u043d\u044b\u0445 \u041c\u0430\u0440\u0438\u0430\u043d\u0441\u043a\u0438\u0445 \u043e\u0441\u0442\u0440\u043e\u0432\u043e\u0432",
        common:
          "\u0421\u0435\u0432\u0435\u0440\u043d\u044b\u0435 \u041c\u0430\u0440\u0438\u0430\u043d\u0441\u043a\u0438\u0435 \u043e\u0441\u0442\u0440\u043e\u0432\u0430",
      },
      slk: {
        official: "Spolo\u010denstvo ostrovov Severn\u00e9 Mari\u00e1ny",
        common: "Severn\u00e9 Mari\u00e1ny",
      },
      spa: {
        official: "Mancomunidad de las Islas Marianas del Norte",
        common: "Islas Marianas del Norte",
      },
      swe: { official: "Nordmarianerna", common: "Nordmarianerna" },
      urd: {
        official:
          "\u062f\u0648\u0644\u062a\u0650 \u0645\u0634\u062a\u0631\u06a9\u06c1 \u062c\u0632\u0627\u0626\u0631 \u0634\u0645\u0627\u0644\u06cc \u0645\u0627\u0631\u06cc\u0627\u0646\u0627",
        common:
          "\u062c\u0632\u0627\u0626\u0631 \u0634\u0645\u0627\u0644\u06cc \u0645\u0627\u0631\u06cc\u0627\u0646\u0627",
      },
      zho: {
        official: "\u5317\u9a6c\u91cc\u4e9a\u7eb3\u7fa4\u5c9b",
        common: "\u5317\u9a6c\u91cc\u4e9a\u7eb3\u7fa4\u5c9b",
      },
    },
    latlng: [15.2, 145.75],
    landlocked: false,
    borders: [],
    area: 464,
    flag: "\ud83c\uddf2\ud83c\uddf5",
    demonyms: {
      eng: { f: "American", m: "American" },
      fra: { f: "Am\u00e9ricaine", m: "Am\u00e9rican" },
    },
    callingCodes: ["+1670"],
  },
  {
    name: {
      common: "Mozambique",
      official: "Republic of Mozambique",
      native: {
        por: {
          official: "Rep\u00fablica de Mo\u00e7ambique",
          common: "Mo\u00e7ambique",
        },
      },
    },
    tld: [".mz"],
    cca2: "MZ",
    ccn3: "508",
    cca3: "MOZ",
    cioc: "MOZ",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { MZN: { name: "Mozambican metical", symbol: "MT" } },
    idd: { root: "+2", suffixes: ["58"] },
    capital: ["Maputo"],
    altSpellings: [
      "MZ",
      "Republic of Mozambique",
      "Rep\u00fablica de Mo\u00e7ambique",
    ],
    region: "Africa",
    subregion: "Eastern Africa",
    languages: { por: "Portuguese" },
    translations: {
      ces: { official: "Mosambick\u00e1 republika", common: "Mosambik" },
      deu: { official: "Republik Mosambik", common: "Mosambik" },
      est: { official: "Mosambiigi Vabariik", common: "Mosambiik" },
      fin: { official: "Mosambikin tasavalta", common: "Mosambik" },
      fra: { official: "R\u00e9publique du Mozambique", common: "Mozambique" },
      hrv: { official: "Republika Mozambiku", common: "Mozambik" },
      hun: {
        official: "Mozambiki K\u00f6zt\u00e1rsas\u00e1g",
        common: "Mozambik",
      },
      ita: { official: "Repubblica del Mozambico", common: "Mozambico" },
      jpn: {
        official: "\u30e2\u30b6\u30f3\u30d3\u30fc\u30af\u5171\u548c\u56fd",
        common: "\u30e2\u30b6\u30f3\u30d3\u30fc\u30af",
      },
      kor: {
        official: "\ubaa8\uc7a0\ube44\ud06c \uacf5\ud654\uad6d",
        common: "\ubaa8\uc7a0\ube44\ud06c",
      },
      nld: { official: "Republiek Mozambique", common: "Mozambique" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0645\u0648\u0632\u0627\u0645\u0628\u06cc\u06a9",
        common: "\u0645\u0648\u0632\u0627\u0645\u0628\u06cc\u06a9",
      },
      pol: { official: "Republika Mozambiku", common: "Mozambik" },
      por: {
        official: "Rep\u00fablica de Mo\u00e7ambique",
        common: "Mo\u00e7ambique",
      },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u041c\u043e\u0437\u0430\u043c\u0431\u0438\u043a",
        common: "\u041c\u043e\u0437\u0430\u043c\u0431\u0438\u043a",
      },
      slk: { official: "Mozambick\u00e1 republika", common: "Mozambik" },
      spa: { official: "Rep\u00fablica de Mozambique", common: "Mozambique" },
      swe: {
        official: "Republiken Mo\u00e7ambique",
        common: "Mo\u00e7ambique",
      },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0645\u0648\u0632\u0645\u0628\u06cc\u0642",
        common: "\u0645\u0648\u0632\u0645\u0628\u06cc\u0642",
      },
      zho: {
        official: "\u83ab\u6851\u6bd4\u514b\u5171\u548c\u56fd",
        common: "\u83ab\u6851\u6bd4\u514b",
      },
    },
    latlng: [-18.25, 35],
    landlocked: false,
    borders: ["MWI", "ZAF", "SWZ", "TZA", "ZMB", "ZWE"],
    area: 801590,
    flag: "\ud83c\uddf2\ud83c\uddff",
    demonyms: {
      eng: { f: "Mozambican", m: "Mozambican" },
      fra: { f: "Mozambicaine", m: "Mozambicain" },
    },
    callingCodes: ["+258"],
  },
  {
    name: {
      common: "Mauritania",
      official: "Islamic Republic of Mauritania",
      native: {
        ara: {
          official:
            "\u0627\u0644\u062c\u0645\u0647\u0648\u0631\u064a\u0629 \u0627\u0644\u0625\u0633\u0644\u0627\u0645\u064a\u0629 \u0627\u0644\u0645\u0648\u0631\u064a\u062a\u0627\u0646\u064a\u0629",
          common: "\u0645\u0648\u0631\u064a\u062a\u0627\u0646\u064a\u0627",
        },
      },
    },
    tld: [".mr"],
    cca2: "MR",
    ccn3: "478",
    cca3: "MRT",
    cioc: "MTN",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { MRU: { name: "Mauritanian ouguiya", symbol: "UM" } },
    idd: { root: "+2", suffixes: ["22"] },
    capital: ["Nouakchott"],
    altSpellings: [
      "MR",
      "Islamic Republic of Mauritania",
      "al-Jumh\u016briyyah al-\u02beIsl\u0101miyyah al-M\u016br\u012bt\u0101niyyah",
    ],
    region: "Africa",
    subregion: "Western Africa",
    languages: { ara: "Arabic" },
    translations: {
      ces: {
        official: "Maurit\u00e1nsk\u00e1 isl\u00e1msk\u00e1 republika",
        common: "Maurit\u00e1nie",
      },
      deu: {
        official: "Islamische Republik Mauretanien",
        common: "Mauretanien",
      },
      est: { official: "Mauritaania Islamivabariik", common: "Mauritaania" },
      fin: {
        official: "Mauritanian islamilainen tasavalta",
        common: "Mauritania",
      },
      fra: {
        official: "R\u00e9publique islamique de Mauritanie",
        common: "Mauritanie",
      },
      hrv: {
        official: "Islamska Republika Mauritanija",
        common: "Mauritanija",
      },
      hun: {
        official: "Maurit\u00e1niai Iszl\u00e1m K\u00f6zt\u00e1rsas\u00e1g",
        common: "Maurit\u00e1nia",
      },
      ita: {
        official: "Repubblica islamica di Mauritania",
        common: "Mauritania",
      },
      jpn: {
        official:
          "\u30e2\u30fc\u30ea\u30bf\u30cb\u30a2\u00b7\u30a4\u30b9\u30e9\u30e0\u5171\u548c\u56fd",
        common: "\u30e2\u30fc\u30ea\u30bf\u30cb\u30a2",
      },
      kor: {
        official:
          "\ubaa8\ub9ac\ud0c0\ub2c8 \uc774\uc2ac\ub78c \uacf5\ud654\uad6d",
        common: "\ubaa8\ub9ac\ud0c0\ub2c8",
      },
      nld: {
        official: "Islamitische Republiek Mauritani\u00eb",
        common: "Mauritani\u00eb",
      },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0627\u0633\u0644\u0627\u0645\u06cc \u0645\u0648\u0631\u06cc\u062a\u0627\u0646\u06cc",
        common: "\u0645\u0648\u0631\u06cc\u062a\u0627\u0646\u06cc",
      },
      pol: {
        official: "Islamska Republika Maureta\u0144ska",
        common: "Mauretania",
      },
      por: {
        official: "Rep\u00fablica Isl\u00e2mica da Maurit\u00e2nia",
        common: "Maurit\u00e2nia",
      },
      rus: {
        official:
          "\u0418\u0441\u043b\u0430\u043c\u0441\u043a\u0430\u044f \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u041c\u0430\u0432\u0440\u0438\u0442\u0430\u043d\u0438\u044f",
        common: "\u041c\u0430\u0432\u0440\u0438\u0442\u0430\u043d\u0438\u044f",
      },
      slk: {
        official: "Maurit\u00e1nska islamsk\u00e1 republika",
        common: "Maurit\u00e1nia",
      },
      spa: {
        official: "Rep\u00fablica Isl\u00e1mica de Mauritania",
        common: "Mauritania",
      },
      swe: {
        official: "Islamiska republiken Mauretanien",
        common: "Mauretanien",
      },
      urd: {
        official:
          "\u0627\u0633\u0644\u0627\u0645\u06cc \u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0645\u0648\u0631\u06cc\u062a\u0627\u0646\u06cc\u06c1",
        common: "\u0645\u0648\u0631\u06cc\u062a\u0627\u0646\u06cc\u06c1",
      },
      zho: {
        official:
          "\u6bdb\u91cc\u5854\u5c3c\u4e9a\u4f0a\u65af\u5170\u5171\u548c\u56fd",
        common: "\u6bdb\u91cc\u5854\u5c3c\u4e9a",
      },
    },
    latlng: [20, -12],
    landlocked: false,
    borders: ["DZA", "MLI", "SEN", "ESH"],
    area: 1030700,
    flag: "\ud83c\uddf2\ud83c\uddf7",
    demonyms: {
      eng: { f: "Mauritanian", m: "Mauritanian" },
      fra: { f: "Mauritanienne", m: "Mauritanien" },
    },
    callingCodes: ["+222"],
  },
  {
    name: {
      common: "Montserrat",
      official: "Montserrat",
      native: { eng: { official: "Montserrat", common: "Montserrat" } },
    },
    tld: [".ms"],
    cca2: "MS",
    ccn3: "500",
    cca3: "MSR",
    cioc: "",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: { XCD: { name: "Eastern Caribbean dollar", symbol: "$" } },
    idd: { root: "+1", suffixes: ["664"] },
    capital: ["Plymouth"],
    altSpellings: ["MS"],
    region: "Americas",
    subregion: "Caribbean",
    languages: { eng: "English" },
    translations: {
      ces: { official: "Montserrat", common: "Montserrat" },
      deu: { official: "Montserrat", common: "Montserrat" },
      est: { official: "Montserrat", common: "Montserrat" },
      fin: { official: "Montserrat", common: "Montserrat" },
      fra: { official: "Montserrat", common: "Montserrat" },
      hrv: { official: "Montserrat", common: "Montserrat" },
      hun: { official: "Montserrat", common: "Montserrat" },
      ita: { official: "Montserrat", common: "Montserrat" },
      jpn: {
        official: "\u30e2\u30f3\u30c8\u30bb\u30e9\u30c8",
        common: "\u30e2\u30f3\u30c8\u30bb\u30e9\u30c8",
      },
      kor: {
        official: "\ubaac\ud2b8\uc138\ub7ab",
        common: "\ubaac\ud2b8\uc138\ub7ab",
      },
      nld: { official: "Montserrat", common: "Montserrat" },
      per: {
        official: "\u0645\u0648\u0646\u062a\u0633\u0631\u0627\u062a",
        common: "\u0645\u0648\u0646\u062a\u0633\u0631\u0627\u062a",
      },
      pol: { official: "Montserrat", common: "Montserrat" },
      por: { official: "Montserrat", common: "Montserrat" },
      rus: {
        official:
          "\u041c\u043e\u043d\u0442\u0441\u0435\u0440\u0440\u0430\u0442",
        common: "\u041c\u043e\u043d\u0442\u0441\u0435\u0440\u0440\u0430\u0442",
      },
      slk: { official: "Montserrat", common: "Montserrat" },
      spa: { official: "Montserrat", common: "Montserrat" },
      swe: { official: "Montserrat", common: "Montserrat" },
      urd: {
        official: "\u0645\u0627\u0646\u0679\u0633\u0631\u06cc\u0679",
        common: "\u0645\u0627\u0646\u0679\u0633\u0631\u06cc\u0679",
      },
      zho: {
        official: "\u8499\u7279\u585e\u62c9\u7279",
        common: "\u8499\u7279\u585e\u62c9\u7279",
      },
    },
    latlng: [16.75, -62.2],
    landlocked: false,
    borders: [],
    area: 102,
    flag: "\ud83c\uddf2\ud83c\uddf8",
    demonyms: {
      eng: { f: "Montserratian", m: "Montserratian" },
      fra: { f: "Montserratienne", m: "Montserratien" },
    },
    callingCodes: ["+1664"],
  },
  {
    name: {
      common: "Martinique",
      official: "Martinique",
      native: { fra: { official: "Martinique", common: "Martinique" } },
    },
    tld: [".mq"],
    cca2: "MQ",
    ccn3: "474",
    cca3: "MTQ",
    cioc: "",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: { EUR: { name: "Euro", symbol: "\u20ac" } },
    idd: { root: "+5", suffixes: ["96"] },
    capital: ["Fort-de-France"],
    altSpellings: ["MQ"],
    region: "Americas",
    subregion: "Caribbean",
    languages: { fra: "French" },
    translations: {
      ces: { official: "Martinik", common: "Martinik" },
      deu: { official: "Martinique", common: "Martinique" },
      est: { official: "Martinique\u2019i departemang", common: "Martinique" },
      fin: { official: "Martinique", common: "Martinique" },
      fra: { official: "Martinique", common: "Martinique" },
      hrv: { official: "Martinique", common: "Martinique" },
      hun: { official: "Martinique", common: "Martinique" },
      ita: { official: "Martinique", common: "Martinica" },
      jpn: {
        official: "\u30de\u30eb\u30c6\u30a3\u30cb\u30fc\u30af\u5cf6",
        common: "\u30de\u30eb\u30c6\u30a3\u30cb\u30fc\u30af",
      },
      kor: {
        official: "\ub9c8\ub974\ud2f0\ub2c8\ud06c",
        common: "\ub9c8\ub974\ud2f0\ub2c8\ud06c",
      },
      nld: { official: "Martinique", common: "Martinique" },
      per: {
        official: "\u0645\u0627\u0631\u062a\u06cc\u0646\u06cc\u06a9",
        common: "\u0645\u0627\u0631\u062a\u06cc\u0646\u06cc\u06a9",
      },
      pol: { official: "Martynika", common: "Martynika" },
      por: { official: "Martinique", common: "Martinica" },
      rus: {
        official: "\u041c\u0430\u0440\u0442\u0438\u043d\u0438\u043a\u0430",
        common: "\u041c\u0430\u0440\u0442\u0438\u043d\u0438\u043a\u0430",
      },
      slk: { official: "Martinique", common: "Martinique" },
      spa: { official: "Martinica", common: "Martinica" },
      swe: { official: "Martinique", common: "Martinique" },
      urd: {
        official: "\u0645\u0627\u0631\u0679\u06cc\u0646\u06cc\u06a9",
        common: "\u0645\u0627\u0631\u0679\u06cc\u0646\u06cc\u06a9",
      },
      zho: {
        official: "\u9a6c\u63d0\u5c3c\u514b",
        common: "\u9a6c\u63d0\u5c3c\u514b",
      },
    },
    latlng: [14.666667, -61],
    landlocked: false,
    borders: [],
    area: 1128,
    flag: "\ud83c\uddf2\ud83c\uddf6",
    demonyms: {
      eng: { f: "Martinican", m: "Martinican" },
      fra: { f: "Martiniquaise", m: "Martiniquais" },
    },
    callingCodes: ["+596"],
  },
  {
    name: {
      common: "Mauritius",
      official: "Republic of Mauritius",
      native: {
        eng: { official: "Republic of Mauritius", common: "Mauritius" },
        fra: { official: "R\u00e9publique de Maurice", common: "Maurice" },
        mfe: { official: "Republik Moris", common: "Moris" },
      },
    },
    tld: [".mu"],
    cca2: "MU",
    ccn3: "480",
    cca3: "MUS",
    cioc: "MRI",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { MUR: { name: "Mauritian rupee", symbol: "\u20a8" } },
    idd: { root: "+2", suffixes: ["30"] },
    capital: ["Port Louis"],
    altSpellings: ["MU", "Republic of Mauritius", "R\u00e9publique de Maurice"],
    region: "Africa",
    subregion: "Eastern Africa",
    languages: { eng: "English", fra: "French", mfe: "Mauritian Creole" },
    translations: {
      ces: { official: "Mauricijsk\u00e1 republika", common: "Mauricius" },
      deu: { official: "Republik Mauritius", common: "Mauritius" },
      est: { official: "Mauritiuse Vabariik", common: "Mauritius" },
      fin: { official: "Mauritiuksen tasavalta", common: "Mauritius" },
      fra: {
        official: "R\u00e9publique de Maurice",
        common: "\u00cele Maurice",
      },
      hrv: { official: "Republika Mauricijus", common: "Mauricijus" },
      hun: {
        official: "Mauritiusi K\u00f6zt\u00e1rsas\u00e1g",
        common: "Mauritius",
      },
      ita: { official: "Repubblica di Mauritius", common: "Mauritius" },
      jpn: {
        official: "\u30e2\u30fc\u30ea\u30b7\u30e3\u30b9\u5171\u548c\u56fd",
        common: "\u30e2\u30fc\u30ea\u30b7\u30e3\u30b9",
      },
      kor: {
        official: "\ubaa8\ub9ac\uc154\uc2a4 \uacf5\ud654\uad6d",
        common: "\ubaa8\ub9ac\uc154\uc2a4",
      },
      nld: { official: "Republiek Mauritius", common: "Mauritius" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0645\u0648\u0631\u06cc\u0633",
        common: "\u0645\u0648\u0631\u06cc\u0633",
      },
      pol: { official: "Republika Mauritiusu", common: "Mauritius" },
      por: {
        official: "Rep\u00fablica das Maur\u00edcias",
        common: "Maur\u00edcio",
      },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u041c\u0430\u0432\u0440\u0438\u043a\u0438\u0439",
        common: "\u041c\u0430\u0432\u0440\u0438\u043a\u0438\u0439",
      },
      slk: {
        official: "Maur\u00edcijsk\u00e1 republika",
        common: "Maur\u00edcius",
      },
      spa: { official: "Rep\u00fablica de Mauricio", common: "Mauricio" },
      swe: { official: "Republiken Mauritius", common: "Mauritius" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0645\u0648\u0631\u06cc\u0634\u0633",
        common: "\u0645\u0648\u0631\u06cc\u0634\u0633",
      },
      zho: {
        official: "\u6bdb\u91cc\u6c42\u65af\u5171\u548c\u56fd",
        common: "\u6bdb\u91cc\u6c42\u65af",
      },
    },
    latlng: [-20.28333333, 57.55],
    landlocked: false,
    borders: [],
    area: 2040,
    flag: "\ud83c\uddf2\ud83c\uddfa",
    demonyms: {
      eng: { f: "Mauritian", m: "Mauritian" },
      fra: { f: "Mauricienne", m: "Mauricien" },
    },
    callingCodes: ["+230"],
  },
  {
    name: {
      common: "Malawi",
      official: "Republic of Malawi",
      native: {
        eng: { official: "Republic of Malawi", common: "Malawi" },
        nya: {
          official: "Chalo cha Malawi, Dziko la Mala\u0175i",
          common: "Mala\u0175i",
        },
      },
    },
    tld: [".mw"],
    cca2: "MW",
    ccn3: "454",
    cca3: "MWI",
    cioc: "MAW",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { MWK: { name: "Malawian kwacha", symbol: "MK" } },
    idd: { root: "+2", suffixes: ["65"] },
    capital: ["Lilongwe"],
    altSpellings: ["MW", "Republic of Malawi"],
    region: "Africa",
    subregion: "Eastern Africa",
    languages: { eng: "English", nya: "Chewa" },
    translations: {
      ces: { official: "Malawisk\u00e1 republika", common: "Malawi" },
      deu: { official: "Republik Malawi", common: "Malawi" },
      est: { official: "Malawi Vabariik", common: "Malawi" },
      fin: { official: "Malawin tasavalta", common: "Malawi" },
      fra: { official: "R\u00e9publique du Malawi", common: "Malawi" },
      hrv: { official: "Republika Malavi", common: "Malavi" },
      hun: { official: "Malawi K\u00f6zt\u00e1rsas\u00e1g", common: "Malawi" },
      ita: { official: "Repubblica del Malawi", common: "Malawi" },
      jpn: {
        official: "\u30de\u30e9\u30a6\u30a4\u5171\u548c\u56fd",
        common: "\u30de\u30e9\u30a6\u30a4",
      },
      kor: {
        official: "\ub9d0\ub77c\uc704 \uacf5\ud654\uad6d",
        common: "\ub9d0\ub77c\uc704",
      },
      nld: { official: "Republiek Malawi", common: "Malawi" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0645\u0627\u0644\u0627\u0648\u06cc",
        common: "\u0645\u0627\u0644\u0627\u0648\u06cc",
      },
      pol: { official: "Republika Malawi", common: "Malawi" },
      por: { official: "Rep\u00fablica do Malawi", common: "Malawi" },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u041c\u0430\u043b\u0430\u0432\u0438",
        common: "\u041c\u0430\u043b\u0430\u0432\u0438",
      },
      slk: { official: "Malawijsk\u00e1 republika", common: "Malawi" },
      spa: { official: "Rep\u00fablica de Malawi", common: "Malawi" },
      swe: { official: "Republiken Malawi", common: "Malawi" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0645\u0644\u0627\u0648\u06cc",
        common: "\u0645\u0644\u0627\u0648\u06cc",
      },
      zho: {
        official: "\u9a6c\u62c9\u7ef4\u5171\u548c\u56fd",
        common: "\u9a6c\u62c9\u7ef4",
      },
    },
    latlng: [-13.5, 34],
    landlocked: true,
    borders: ["MOZ", "TZA", "ZMB"],
    area: 118484,
    flag: "\ud83c\uddf2\ud83c\uddfc",
    demonyms: {
      eng: { f: "Malawian", m: "Malawian" },
      fra: { f: "Malawienne", m: "Malawien" },
    },
    callingCodes: ["+265"],
  },
  {
    name: {
      common: "Malaysia",
      official: "Malaysia",
      native: {
        eng: { official: "Malaysia", common: "Malaysia" },
        msa: {
          official: "\u0645\u0644\u064a\u0633\u064a\u0627",
          common: "\u0645\u0644\u064a\u0633\u064a\u0627",
        },
      },
    },
    tld: [".my"],
    cca2: "MY",
    ccn3: "458",
    cca3: "MYS",
    cioc: "MAS",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { MYR: { name: "Malaysian ringgit", symbol: "RM" } },
    idd: { root: "+6", suffixes: ["0"] },
    capital: ["Kuala Lumpur"],
    altSpellings: ["MY"],
    region: "Asia",
    subregion: "South-Eastern Asia",
    languages: { eng: "English", msa: "Malay" },
    translations: {
      ces: { official: "Malajsie", common: "Malajsie" },
      deu: { official: "Malaysia", common: "Malaysia" },
      est: { official: "Malaisia", common: "Malaisia" },
      fin: { official: "Malesia", common: "Malesia" },
      fra: { official: "F\u00e9d\u00e9ration de Malaisie", common: "Malaisie" },
      hrv: { official: "Malezija", common: "Malezija" },
      hun: { official: "Malajzia", common: "Malajzia" },
      ita: { official: "Malaysia", common: "Malesia" },
      jpn: {
        official: "\u30de\u30ec\u30fc\u30b7\u30a2",
        common: "\u30de\u30ec\u30fc\u30b7\u30a2",
      },
      kor: {
        official: "\ub9d0\ub808\uc774\uc2dc\uc544",
        common: "\ub9d0\ub808\uc774\uc2dc\uc544",
      },
      nld: { official: "Maleisi\u00eb", common: "Maleisi\u00eb" },
      per: {
        official:
          "\u0641\u062f\u0631\u0627\u0633\u06cc\u0648\u0646 \u0645\u0627\u0644\u0632\u06cc",
        common: "\u0645\u0627\u0644\u0632\u06cc",
      },
      pol: { official: "Malezja", common: "Malezja" },
      por: { official: "Mal\u00e1sia", common: "Mal\u00e1sia" },
      rus: {
        official: "\u041c\u0430\u043b\u0430\u0439\u0437\u0438\u044f",
        common: "\u041c\u0430\u043b\u0430\u0439\u0437\u0438\u044f",
      },
      slk: { official: "Malajzia", common: "Malajzia" },
      spa: { official: "Malasia", common: "Malasia" },
      swe: { official: "Malaysia", common: "Malaysia" },
      urd: {
        official: "\u0645\u0644\u0627\u0626\u06cc\u0634\u06cc\u0627",
        common: "\u0645\u0644\u0627\u0626\u06cc\u0634\u06cc\u0627",
      },
      zho: {
        official: "\u9a6c\u6765\u897f\u4e9a",
        common: "\u9a6c\u6765\u897f\u4e9a",
      },
    },
    latlng: [2.5, 112.5],
    landlocked: false,
    borders: ["BRN", "IDN", "THA"],
    area: 330803,
    flag: "\ud83c\uddf2\ud83c\uddfe",
    demonyms: {
      eng: { f: "Malaysian", m: "Malaysian" },
      fra: { f: "Malaisienne", m: "Malaisien" },
    },
    callingCodes: ["+60"],
  },
  {
    name: {
      common: "Mayotte",
      official: "Department of Mayotte",
      native: {
        fra: { official: "D\u00e9partement de Mayotte", common: "Mayotte" },
      },
    },
    tld: [".yt"],
    cca2: "YT",
    ccn3: "175",
    cca3: "MYT",
    cioc: "",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: { EUR: { name: "Euro", symbol: "\u20ac" } },
    idd: { root: "+2", suffixes: ["62"] },
    capital: ["Mamoudzou"],
    altSpellings: [
      "YT",
      "Department of Mayotte",
      "D\u00e9partement de Mayotte",
    ],
    region: "Africa",
    subregion: "Eastern Africa",
    languages: { fra: "French" },
    translations: {
      ces: { official: "Mayotte", common: "Mayotte" },
      deu: {
        official: "\u00dcbersee-D\u00e9partement Mayotte",
        common: "Mayotte",
      },
      est: { official: "Mayotte", common: "Mayotte" },
      fin: { official: "Mayotte", common: "Mayotte" },
      fra: { official: "D\u00e9partement de Mayotte", common: "Mayotte" },
      hrv: { official: "Odjel Mayotte", common: "Mayotte" },
      hun: { official: "Mayotte", common: "Mayotte" },
      ita: { official: "Dipartimento di Mayotte", common: "Mayotte" },
      jpn: {
        official: "\u30de\u30e8\u30c3\u30c8\u79d1",
        common: "\u30de\u30e8\u30c3\u30c8",
      },
      kor: { official: "\ub9c8\uc694\ud2b8", common: "\ub9c8\uc694\ud2b8" },
      nld: { official: "Afdeling Mayotte", common: "Mayotte" },
      per: {
        official:
          "\u0645\u062c\u0645\u0648\u0639\u0647 \u0634\u0647\u0631\u0633\u062a\u0627\u0646\u06cc \u0645\u0627\u06cc\u0648\u062a",
        common: "\u0645\u0627\u06cc\u0648\u062a",
      },
      pol: { official: "Majotta", common: "Majotta" },
      por: { official: "Departamento de Mayotte", common: "Mayotte" },
      rus: {
        official:
          "\u0414\u0435\u043f\u0430\u0440\u0442\u0430\u043c\u0435\u043d\u0442 \u041c\u0430\u0439\u043e\u0442\u0442\u0430",
        common: "\u041c\u0430\u0439\u043e\u0442\u0442\u0430",
      },
      slk: { official: "Department Mayotte", common: "Mayotte" },
      spa: { official: "Departamento de Mayotte", common: "Mayotte" },
      swe: { official: "Departementsomr\u00e5det Mayotte", common: "Mayotte" },
      urd: {
        official: "\u0645\u0627\u06cc\u0648\u0679",
        common: "\u0645\u0627\u06cc\u0648\u0679",
      },
      zho: { official: "\u9a6c\u7ea6\u7279", common: "\u9a6c\u7ea6\u7279" },
    },
    latlng: [-12.83333333, 45.16666666],
    landlocked: false,
    borders: [],
    area: 374,
    flag: "\ud83c\uddfe\ud83c\uddf9",
    demonyms: {
      eng: { f: "Mahoran", m: "Mahoran" },
      fra: { f: "Mahoraise", m: "Mahorais" },
    },
    callingCodes: ["+262"],
  },
  {
    name: {
      common: "Namibia",
      official: "Republic of Namibia",
      native: {
        afr: { official: "Republiek van Namibi\u00eb", common: "Namibi\u00eb" },
        deu: { official: "Republik Namibia", common: "Namibia" },
        eng: { official: "Republic of Namibia", common: "Namibia" },
        her: { official: "Republic of Namibia", common: "Namibia" },
        hgm: { official: "Republic of Namibia", common: "Namibia" },
        kwn: { official: "Republic of Namibia", common: "Namibia" },
        loz: { official: "Republic of Namibia", common: "Namibia" },
        ndo: { official: "Republic of Namibia", common: "Namibia" },
        tsn: { official: "Lefatshe la Namibia", common: "Namibia" },
      },
    },
    tld: [".na"],
    cca2: "NA",
    ccn3: "516",
    cca3: "NAM",
    cioc: "NAM",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: {
      NAD: { name: "Namibian dollar", symbol: "$" },
      ZAR: { name: "South African rand", symbol: "R" },
    },
    idd: { root: "+2", suffixes: ["64"] },
    capital: ["Windhoek"],
    altSpellings: ["NA", "Namibi\u00eb", "Republic of Namibia"],
    region: "Africa",
    subregion: "Southern Africa",
    languages: {
      afr: "Afrikaans",
      deu: "German",
      eng: "English",
      her: "Herero",
      hgm: "Khoekhoe",
      kwn: "Kwangali",
      loz: "Lozi",
      ndo: "Ndonga",
      tsn: "Tswana",
    },
    translations: {
      ces: { official: "Namibijsk\u00e1 republika", common: "Namibie" },
      deu: { official: "Republik Namibia", common: "Namibia" },
      est: { official: "Namiibia Vabariik", common: "Namiibia" },
      fin: { official: "Namibian tasavalta", common: "Namibia" },
      fra: { official: "R\u00e9publique de Namibie", common: "Namibie" },
      hrv: { official: "Republika Namibija", common: "Namibija" },
      hun: {
        official: "Nam\u00edbiai K\u00f6zt\u00e1rsas\u00e1g",
        common: "Nam\u00edbia",
      },
      ita: { official: "Repubblica di Namibia", common: "Namibia" },
      jpn: {
        official: "\u30ca\u30df\u30d3\u30a2\u5171\u548c\u56fd",
        common: "\u30ca\u30df\u30d3\u30a2",
      },
      kor: {
        official: "\ub098\ubbf8\ube44\uc544 \uacf5\ud654\uad6d",
        common: "\ub098\ubbf8\ube44\uc544",
      },
      nld: { official: "Republiek Namibi\u00eb", common: "Namibi\u00eb" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0646\u0627\u0645\u06cc\u0628\u06cc\u0627",
        common: "\u0646\u0627\u0645\u06cc\u0628\u06cc\u0627",
      },
      pol: { official: "Republika Namibii", common: "Namibia" },
      por: {
        official: "Rep\u00fablica da Nam\u00edbia",
        common: "Nam\u00edbia",
      },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u041d\u0430\u043c\u0438\u0431\u0438\u044f",
        common: "\u041d\u0430\u043c\u0438\u0431\u0438\u044f",
      },
      slk: {
        official: "Nam\u00edbijsk\u00e1 republika",
        common: "Nam\u00edbia",
      },
      spa: { official: "Rep\u00fablica de Namibia", common: "Namibia" },
      swe: { official: "Republiken Namibia", common: "Namibia" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0646\u0645\u06cc\u0628\u06cc\u0627",
        common: "\u0646\u0645\u06cc\u0628\u06cc\u0627",
      },
      zho: {
        official: "\u7eb3\u7c73\u6bd4\u4e9a\u5171\u548c\u56fd",
        common: "\u7eb3\u7c73\u6bd4\u4e9a",
      },
    },
    latlng: [-22, 17],
    landlocked: false,
    borders: ["AGO", "BWA", "ZAF", "ZMB"],
    area: 825615,
    flag: "\ud83c\uddf3\ud83c\udde6",
    demonyms: {
      eng: { f: "Namibian", m: "Namibian" },
      fra: { f: "Namibienne", m: "Namibien" },
    },
    callingCodes: ["+264"],
  },
  {
    name: {
      common: "New Caledonia",
      official: "New Caledonia",
      native: {
        fra: {
          official: "Nouvelle-Cal\u00e9donie",
          common: "Nouvelle-Cal\u00e9donie",
        },
      },
    },
    tld: [".nc"],
    cca2: "NC",
    ccn3: "540",
    cca3: "NCL",
    cioc: "",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: { XPF: { name: "CFP franc", symbol: "\u20a3" } },
    idd: { root: "+6", suffixes: ["87"] },
    capital: ["Noum\u00e9a"],
    altSpellings: ["NC"],
    region: "Oceania",
    subregion: "Melanesia",
    languages: { fra: "French" },
    translations: {
      ces: { official: "Nov\u00e1 Kaledonie", common: "Nov\u00e1 Kaledonie" },
      deu: { official: "Neukaledonien", common: "Neukaledonien" },
      est: { official: "Uus-Kaledoonia", common: "Uus-Kaledoonia" },
      fin: { official: "Uusi-Kaledonia", common: "Uusi-Kaledonia" },
      fra: {
        official: "Nouvelle-Cal\u00e9donie",
        common: "Nouvelle-Cal\u00e9donie",
      },
      hrv: { official: "Nova Kaledonija", common: "Nova Kaledonija" },
      hun: {
        official: "\u00daj-Kaled\u00f3nia",
        common: "\u00daj-Kaled\u00f3nia",
      },
      ita: { official: "Nuova Caledonia", common: "Nuova Caledonia" },
      jpn: {
        official: "\u30cb\u30e5\u30fc\u30ab\u30ec\u30c9\u30cb\u30a2",
        common: "\u30cb\u30e5\u30fc\u30ab\u30ec\u30c9\u30cb\u30a2",
      },
      kor: {
        official: "\ub204\ubca8\uce7c\ub808\ub3c4\ub2c8",
        common: "\ub204\ubca8\uce7c\ub808\ub3c4\ub2c8",
      },
      nld: {
        official: "nieuw -Caledoni\u00eb",
        common: "Nieuw-Caledoni\u00eb",
      },
      per: {
        official:
          "\u06a9\u0627\u0644\u062f\u0648\u0646\u06cc\u0627\u06cc \u062c\u062f\u06cc\u062f",
        common:
          "\u06a9\u0627\u0644\u062f\u0648\u0646\u06cc\u0627\u06cc \u062c\u062f\u06cc\u062f",
      },
      pol: { official: "Nowa Kaledonia", common: "Nowa Kaledonia" },
      por: { official: "New Caledonia", common: "Nova Caled\u00f3nia" },
      rus: {
        official:
          "\u041d\u043e\u0432\u0430\u044f \u041a\u0430\u043b\u0435\u0434\u043e\u043d\u0438\u044f",
        common:
          "\u041d\u043e\u0432\u0430\u044f \u041a\u0430\u043b\u0435\u0434\u043e\u043d\u0438\u044f",
      },
      slk: {
        official: "Nov\u00e1 Kaled\u00f3nia",
        common: "Nov\u00e1 Kaled\u00f3nia",
      },
      spa: { official: "nueva Caledonia", common: "Nueva Caledonia" },
      swe: { official: "Nya Kaledonien", common: "Nya Kaledonien" },
      urd: {
        official:
          "\u0646\u06cc\u0648 \u06a9\u06cc\u0644\u06cc\u0688\u0648\u0646\u06cc\u0627",
        common:
          "\u0646\u06cc\u0648 \u06a9\u06cc\u0644\u06cc\u0688\u0648\u0646\u06cc\u0627",
      },
      zho: {
        official: "\u65b0\u5580\u91cc\u591a\u5c3c\u4e9a",
        common: "\u65b0\u5580\u91cc\u591a\u5c3c\u4e9a",
      },
    },
    latlng: [-21.5, 165.5],
    landlocked: false,
    borders: [],
    area: 18575,
    flag: "\ud83c\uddf3\ud83c\udde8",
    demonyms: {
      eng: { f: "New Caledonian", m: "New Caledonian" },
      fra: { f: "N\u00e9o-Cal\u00e9donienne", m: "N\u00e9o-Cal\u00e9donien" },
    },
    callingCodes: ["+687"],
  },
  {
    name: {
      common: "Niger",
      official: "Republic of Niger",
      native: {
        fra: { official: "R\u00e9publique du Niger", common: "Niger" },
      },
    },
    tld: [".ne"],
    cca2: "NE",
    ccn3: "562",
    cca3: "NER",
    cioc: "NIG",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { XOF: { name: "West African CFA franc", symbol: "Fr" } },
    idd: { root: "+2", suffixes: ["27"] },
    capital: ["Niamey"],
    altSpellings: ["NE", "Nijar"],
    region: "Africa",
    subregion: "Western Africa",
    languages: { fra: "French" },
    translations: {
      ces: { official: "Nigersk\u00e1 republika", common: "Niger" },
      deu: { official: "Republik Niger", common: "Niger" },
      est: { official: "Nigeri Vabariik", common: "Niger" },
      fin: { official: "Nigerin tasavalta", common: "Niger" },
      fra: { official: "R\u00e9publique du Niger", common: "Niger" },
      hrv: { official: "Republika Niger", common: "Niger" },
      hun: { official: "Nigeri K\u00f6zt\u00e1rsas\u00e1g", common: "Niger" },
      ita: { official: "Repubblica del Niger", common: "Niger" },
      jpn: {
        official: "\u30cb\u30b8\u30a7\u30fc\u30eb\u5171\u548c\u56fd",
        common: "\u30cb\u30b8\u30a7\u30fc\u30eb",
      },
      kor: {
        official: "\ub2c8\uc81c\ub974 \uacf5\ud654\uad6d",
        common: "\ub2c8\uc81c\ub974",
      },
      nld: { official: "Republiek Niger", common: "Niger" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0646\u06cc\u062c\u0631",
        common: "\u0646\u06cc\u062c\u0631",
      },
      pol: { official: "Republika Nigru", common: "Niger" },
      por: { official: "Rep\u00fablica do N\u00edger", common: "N\u00edger" },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u041d\u0438\u0433\u0435\u0440",
        common: "\u041d\u0438\u0433\u0435\u0440",
      },
      slk: { official: "Nig\u00e9rsk\u00e1 republika", common: "Niger" },
      spa: { official: "Rep\u00fablica de N\u00edger", common: "N\u00edger" },
      swe: { official: "Republiken Niger", common: "Niger" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0646\u0627\u0626\u062c\u0631",
        common: "\u0646\u0627\u0626\u062c\u0631",
      },
      zho: {
        official: "\u5c3c\u65e5\u5c14\u5171\u548c\u56fd",
        common: "\u5c3c\u65e5\u5c14",
      },
    },
    latlng: [16, 8],
    landlocked: true,
    borders: ["DZA", "BEN", "BFA", "TCD", "LBY", "MLI", "NGA"],
    area: 1267000,
    flag: "\ud83c\uddf3\ud83c\uddea",
    demonyms: {
      eng: { f: "Nigerien", m: "Nigerien" },
      fra: { f: "Nig\u00e9rienne", m: "Nig\u00e9rien" },
    },
    callingCodes: ["+227"],
  },
  {
    name: {
      common: "Norfolk Island",
      official: "Territory of Norfolk Island",
      native: {
        eng: {
          official: "Territory of Norfolk Island",
          common: "Norfolk Island",
        },
        pih: { official: "Teratri of Norf'k Ailen", common: "Norf'k Ailen" },
      },
    },
    tld: [".nf"],
    cca2: "NF",
    ccn3: "574",
    cca3: "NFK",
    cioc: "",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: { AUD: { name: "Australian dollar", symbol: "$" } },
    idd: { root: "+6", suffixes: ["72"] },
    capital: ["Kingston"],
    altSpellings: [
      "NF",
      "Territory of Norfolk Island",
      "Teratri of Norf'k Ailen",
    ],
    region: "Oceania",
    subregion: "Australia and New Zealand",
    languages: { eng: "English", pih: "Norfuk" },
    translations: {
      ces: { official: "Teritorium ostrova Norfolk", common: "Norfolk" },
      deu: { official: "Gebiet der Norfolkinsel", common: "Norfolkinsel" },
      est: { official: "Norfolki saare ala", common: "Norfolk" },
      fin: { official: "Norfolkinsaaren territorio", common: "Norfolkinsaari" },
      fra: {
        official: "Territoire de l'\u00eele Norfolk",
        common: "\u00cele Norfolk",
      },
      hrv: { official: "Teritorij Norfolk Island", common: "Otok Norfolk" },
      hun: { official: "Norfolk-sziget", common: "Norfolk-sziget" },
      ita: {
        official: "Territorio di Norfolk Island",
        common: "Isola Norfolk",
      },
      jpn: {
        official:
          "\u30ce\u30fc\u30d5\u30a9\u30fc\u30af\u5cf6\u306e\u9818\u571f",
        common: "\u30ce\u30fc\u30d5\u30a9\u30fc\u30af\u5cf6",
      },
      kor: { official: "\ub178\ud37d \uc12c", common: "\ub178\ud37d \uc12c" },
      nld: {
        official: "Grondgebied van Norfolk Island",
        common: "Norfolkeiland",
      },
      per: {
        official:
          "\u0642\u0644\u0645\u0631\u0648 \u062c\u0632\u0627\u06cc\u0631 \u0646\u0648\u0631\u0641\u06a9",
        common: "\u062c\u0632\u06cc\u0631\u0647 \u0646\u0648\u0631\u0641\u06a9",
      },
      pol: { official: "Terytorium Wyspy Norfolk", common: "Wyspa Norfolk" },
      por: {
        official: "Territ\u00f3rio da Ilha Norfolk",
        common: "Ilha Norfolk",
      },
      rus: {
        official:
          "\u0422\u0435\u0440\u0440\u0438\u0442\u043e\u0440\u0438\u044f \u043e\u0441\u0442\u0440\u043e\u0432\u0430 \u041d\u043e\u0440\u0444\u043e\u043b\u043a",
        common: "\u041d\u043e\u0440\u0444\u043e\u043b\u043a",
      },
      slk: { official: "Terit\u00f3rium ostrova Norfolk", common: "Norfolk" },
      spa: {
        official: "Territorio de la Isla Norfolk",
        common: "Isla de Norfolk",
      },
      swe: { official: "Norfolk\u00f6n", common: "Norfolk\u00f6n" },
      urd: {
        official:
          "\u062c\u0632\u06cc\u0631\u06c1 \u0646\u0648\u0631\u0641\u06a9 \u062e\u0637\u06c1",
        common: "\u062c\u0632\u06cc\u0631\u06c1 \u0646\u0648\u0631\u0641\u06a9",
      },
      zho: {
        official: "\u8bfa\u798f\u514b\u5c9b",
        common: "\u8bfa\u798f\u514b\u5c9b",
      },
    },
    latlng: [-29.03333333, 167.95],
    landlocked: false,
    borders: [],
    area: 36,
    flag: "\ud83c\uddf3\ud83c\uddeb",
    demonyms: {
      eng: { f: "Norfolk Islander", m: "Norfolk Islander" },
      fra: { f: "Norfolkaise", m: "Norfolkais" },
    },
    callingCodes: ["+672"],
  },
  {
    name: {
      common: "Nigeria",
      official: "Federal Republic of Nigeria",
      native: {
        eng: { official: "Federal Republic of Nigeria", common: "Nigeria" },
      },
    },
    tld: [".ng"],
    cca2: "NG",
    ccn3: "566",
    cca3: "NGA",
    cioc: "NGR",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { NGN: { name: "Nigerian naira", symbol: "\u20a6" } },
    idd: { root: "+2", suffixes: ["34"] },
    capital: ["Abuja"],
    altSpellings: [
      "NG",
      "Nijeriya",
      "Na\u00edj\u00edr\u00ed\u00e0",
      "Federal Republic of Nigeria",
    ],
    region: "Africa",
    subregion: "Western Africa",
    languages: { eng: "English" },

    latlng: [10, 8],
    landlocked: false,
    borders: ["BEN", "CMR", "TCD", "NER"],
    area: 923768,
    flag: "\ud83c\uddf3\ud83c\uddec",
    demonyms: {
      eng: { f: "Nigerian", m: "Nigerian" },
      fra: { f: "Nig\u00e9riane", m: "Nig\u00e9rian" },
    },
    callingCodes: ["+234"],
  },
  {
    name: {
      common: "Nicaragua",
      official: "Republic of Nicaragua",
      native: {
        spa: { official: "Rep\u00fablica de Nicaragua", common: "Nicaragua" },
      },
    },
    tld: [".ni"],
    cca2: "NI",
    ccn3: "558",
    cca3: "NIC",
    cioc: "NCA",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { NIO: { name: "Nicaraguan c\u00f3rdoba", symbol: "C$" } },
    idd: { root: "+5", suffixes: ["05"] },
    capital: ["Managua"],
    altSpellings: [
      "NI",
      "Republic of Nicaragua",
      "Rep\u00fablica de Nicaragua",
    ],
    region: "Americas",
    subregion: "Central America",
    languages: { spa: "Spanish" },

    latlng: [13, -85],
    landlocked: false,
    borders: ["CRI", "HND"],
    area: 130373,
    flag: "\ud83c\uddf3\ud83c\uddee",
    demonyms: {
      eng: { f: "Nicaraguan", m: "Nicaraguan" },
      fra: { f: "Nicaraguayenne", m: "Nicaraguayen" },
    },
    callingCodes: ["+505"],
  },
  {
    name: {
      common: "Niue",
      official: "Niue",
      native: {
        eng: { official: "Niue", common: "Niue" },
        niu: { official: "Niu\u0113", common: "Niu\u0113" },
      },
    },
    tld: [".nu"],
    cca2: "NU",
    ccn3: "570",
    cca3: "NIU",
    cioc: "",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: { NZD: { name: "New Zealand dollar", symbol: "$" } },
    idd: { root: "+6", suffixes: ["83"] },
    capital: ["Alofi"],
    altSpellings: ["NU"],
    region: "Oceania",
    subregion: "Polynesia",
    languages: { eng: "English", niu: "Niuean" },
    translations: {
      ces: { official: "Niue", common: "Niue" },
      deu: { official: "Niue", common: "Niue" },
      est: { official: "Niue", common: "Niue" },
      fin: { official: "Niue", common: "Niue" },
      fra: { official: "Niue", common: "Niue" },
      hrv: { official: "Niue", common: "Niue" },
      hun: { official: "Niue", common: "Niue" },
      ita: { official: "Niue", common: "Niue" },
      jpn: { official: "\u30cb\u30a6\u30a8", common: "\u30cb\u30a6\u30a8" },
      kor: { official: "\ub2c8\uc6b0\uc5d0", common: "\ub2c8\uc6b0\uc5d0" },
      nld: { official: "Niue", common: "Niue" },
      per: {
        official: "\u0646\u06cc\u0648\u0648\u06cc",
        common: "\u0646\u06cc\u0648\u0648\u06cc",
      },
      pol: { official: "Niue", common: "Niue" },
      por: { official: "Niue", common: "Niue" },
      rus: {
        official: "\u041d\u0438\u0443\u044d",
        common: "\u041d\u0438\u0443\u044d",
      },
      slk: { official: "Niue", common: "Niue" },
      spa: { official: "Niue", common: "Niue" },
      swe: { official: "Niue", common: "Niue" },
      urd: {
        official: "\u0646\u06cc\u0648\u0648\u06d2",
        common: "\u0646\u06cc\u0648\u0648\u06d2",
      },
      zho: { official: "\u7ebd\u57c3", common: "\u7ebd\u57c3" },
    },
    latlng: [-19.03333333, -169.86666666],
    landlocked: false,
    borders: [],
    area: 260,
    flag: "\ud83c\uddf3\ud83c\uddfa",
    demonyms: {
      eng: { f: "Niuean", m: "Niuean" },
      fra: { f: "Niu\u00e9enne", m: "Niu\u00e9en" },
    },
    callingCodes: ["+683"],
  },
  {
    name: {
      common: "Netherlands",
      official: "Kingdom of the Netherlands",
      native: {
        nld: { official: "Koninkrijk der Nederlanden", common: "Nederland" },
      },
    },
    tld: [".nl"],
    cca2: "NL",
    ccn3: "528",
    cca3: "NLD",
    cioc: "NED",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { EUR: { name: "Euro", symbol: "\u20ac" } },
    idd: { root: "+3", suffixes: ["1"] },
    capital: ["Amsterdam"],
    altSpellings: ["NL", "Holland", "Nederland", "The Netherlands"],
    region: "Europe",
    subregion: "Western Europe",
    languages: { nld: "Dutch" },
    translations: {
      ces: {
        official: "Nizozemsk\u00e9 kr\u00e1lovstv\u00ed",
        common: "Nizozemsko",
      },
      deu: { official: "Niederlande", common: "Niederlande" },
      est: { official: "Madalmaade Kuningriik", common: "Holland" },
      fin: { official: "Alankomaat", common: "Alankomaat" },
      fra: { official: "Pays-Bas", common: "Pays-Bas" },
      hrv: { official: "Holandija", common: "Nizozemska" },
      hun: { official: "Holland Kir\u00e1lys\u00e1g", common: "Hollandia" },
      ita: { official: "Paesi Bassi", common: "Paesi Bassi" },
      jpn: {
        official: "\u30aa\u30e9\u30f3\u30c0",
        common: "\u30aa\u30e9\u30f3\u30c0",
      },
      kor: {
        official: "\ub124\ub35c\ub780\ub4dc \uc655\uad6d",
        common: "\ub124\ub35c\ub780\ub4dc",
      },
      nld: { official: "Nederland", common: "Nederland" },
      per: {
        official: "\u0647\u0644\u0646\u062f",
        common: "\u0647\u0644\u0646\u062f",
      },
      pol: { official: "Kr\u00f3lestwo Niderland\u00f3w", common: "Holandia" },
      por: { official: "Holanda", common: "Holanda" },
      rus: {
        official:
          "\u041d\u0438\u0434\u0435\u0440\u043b\u0430\u043d\u0434\u044b",
        common: "\u041d\u0438\u0434\u0435\u0440\u043b\u0430\u043d\u0434\u044b",
      },
      slk: {
        official: "Holandsk\u00e9 kr\u00e1\u013eovstvo",
        common: "Holansko",
      },
      spa: { official: "Pa\u00edses Bajos", common: "Pa\u00edses Bajos" },
      swe: { official: "Nederl\u00e4nderna", common: "Nederl\u00e4nderna" },
      urd: {
        official:
          "\u0645\u0645\u0644\u06a9\u062a\u0650 \u0646\u06cc\u062f\u0631\u0644\u06cc\u0646\u0688\u0632",
        common: "\u0646\u06cc\u062f\u0631\u0644\u06cc\u0646\u0688\u0632",
      },
      zho: { official: "\u8377\u5170", common: "\u8377\u5170" },
    },
    latlng: [52.5, 5.75],
    landlocked: false,
    borders: ["BEL", "DEU"],
    area: 41850,
    flag: "\ud83c\uddf3\ud83c\uddf1",
    demonyms: {
      eng: { f: "Dutch", m: "Dutch" },
      fra: { f: "N\u00e9erlandaise", m: "N\u00e9erlandais" },
    },
    callingCodes: ["+31"],
  },
  {
    name: {
      common: "Norway",
      official: "Kingdom of Norway",
      native: {
        nno: { official: "Kongeriket Noreg", common: "Noreg" },
        nob: { official: "Kongeriket Norge", common: "Norge" },
        smi: { official: "Norgga gonagasriika", common: "Norgga" },
      },
    },
    tld: [".no"],
    cca2: "NO",
    ccn3: "578",
    cca3: "NOR",
    cioc: "NOR",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { NOK: { name: "Norwegian krone", symbol: "kr" } },
    idd: { root: "+4", suffixes: ["7"] },
    capital: ["Oslo"],
    altSpellings: [
      "NO",
      "Norge",
      "Noreg",
      "Kingdom of Norway",
      "Kongeriket Norge",
      "Kongeriket Noreg",
    ],
    region: "Europe",
    subregion: "Northern Europe",
    languages: {
      nno: "Norwegian Nynorsk",
      nob: "Norwegian Bokm\u00e5l",
      smi: "Sami",
    },
    translations: {
      ces: { official: "Norsk\u00e9 kr\u00e1lovstv\u00ed", common: "Norsko" },
      deu: { official: "K\u00f6nigreich Norwegen", common: "Norwegen" },
      est: { official: "Norra Kuningriik", common: "Norra" },
      fin: { official: "Norjan kuningaskunta", common: "Norja" },
      fra: { official: "Royaume de Norv\u00e8ge", common: "Norv\u00e8ge" },
      hrv: { official: "Kraljevina Norve\u0161ka", common: "Norve\u0161ka" },
      hun: {
        official: "Norv\u00e9g Kir\u00e1lys\u00e1g",
        common: "Norv\u00e9gia",
      },
      ita: { official: "Regno di Norvegia", common: "Norvegia" },
      jpn: {
        official: "\u30ce\u30eb\u30a6\u30a7\u30fc\u738b\u56fd",
        common: "\u30ce\u30eb\u30a6\u30a7\u30fc",
      },
      kor: {
        official: "\ub178\ub974\uc6e8\uc774 \uc655\uad6d",
        common: "\ub178\ub974\uc6e8\uc774",
      },
      nld: { official: "Koninkrijk Noorwegen", common: "Noorwegen" },
      per: {
        official:
          "\u067e\u0627\u062f\u0634\u0627\u0647\u06cc \u0646\u0631\u0648\u0698",
        common: "\u0646\u0631\u0648\u0698",
      },
      pol: { official: "Kr\u00f3lestwo Norwegii", common: "Norwegia" },
      por: { official: "Reino da Noruega", common: "Noruega" },
      rus: {
        official:
          "\u041a\u043e\u0440\u043e\u043b\u0435\u0432\u0441\u0442\u0432\u043e \u041d\u043e\u0440\u0432\u0435\u0433\u0438\u044f",
        common: "\u041d\u043e\u0440\u0432\u0435\u0433\u0438\u044f",
      },
      slk: {
        official: "N\u00f3rske kr\u00e1\u013eovstvo",
        common: "N\u00f3rsko",
      },
      spa: { official: "Reino de Noruega", common: "Noruega" },
      swe: { official: "Konungariket Norge", common: "Norge" },
      urd: {
        official:
          "\u0645\u0645\u0644\u06a9\u062a\u0650 \u0646\u0627\u0631\u0648\u06d2",
        common: "\u0646\u0627\u0631\u0648\u06d2",
      },
      zho: { official: "\u632a\u5a01\u738b\u56fd", common: "\u632a\u5a01" },
    },
    latlng: [62, 10],
    landlocked: false,
    borders: ["FIN", "SWE", "RUS"],
    area: 323802,
    flag: "\ud83c\uddf3\ud83c\uddf4",
    demonyms: {
      eng: { f: "Norwegian", m: "Norwegian" },
      fra: { f: "Norv\u00e9gienne", m: "Norv\u00e9gien" },
    },
    callingCodes: ["+47"],
  },
  {
    name: {
      common: "Nepal",
      official: "Federal Democratic Republic of Nepal",
      native: {
        nep: {
          official:
            "\u0928\u0947\u092a\u093e\u0932 \u0938\u0902\u0918\u0940\u092f \u0932\u094b\u0915\u0924\u093e\u0928\u094d\u0924\u094d\u0930\u093f\u0915 \u0917\u0923\u0924\u0928\u094d\u0924\u094d\u0930",
          common: "\u0928\u0947\u092a\u093e\u0932",
        },
      },
    },
    tld: [".np"],
    cca2: "NP",
    ccn3: "524",
    cca3: "NPL",
    cioc: "NEP",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { NPR: { name: "Nepalese rupee", symbol: "\u20a8" } },
    idd: { root: "+9", suffixes: ["77"] },
    capital: ["Kathmandu"],
    altSpellings: [
      "NP",
      "Federal Democratic Republic of Nepal",
      "Lokt\u0101ntrik Ganatantra Nep\u0101l",
    ],
    region: "Asia",
    subregion: "Southern Asia",
    languages: { nep: "Nepali" },
    translations: {
      ces: {
        official: "Federativn\u00ed demokratick\u00e1 republika Nep\u00e1l",
        common: "Nep\u00e1l",
      },
      deu: { official: "Demokratische Bundesrepublik Nepal", common: "Nepal" },
      est: { official: "Nepali Demokraatlik Liitvabariik", common: "Nepal" },
      fin: {
        official: "Nepalin demokraattinen liittotasavalta",
        common: "Nepal",
      },
      fra: { official: "R\u00e9publique du N\u00e9pal", common: "N\u00e9pal" },
      hrv: { official: "Savezna Demokratska Republika Nepal", common: "Nepal" },
      hun: {
        official:
          "Nep\u00e1li Sz\u00f6vets\u00e9gi Demokratikus K\u00f6zt\u00e1rsas\u00e1g",
        common: "Nep\u00e1l",
      },
      ita: {
        official: "Repubblica federale democratica del Nepal",
        common: "Nepal",
      },
      jpn: {
        official:
          "\u30cd\u30d1\u30fc\u30eb\u9023\u90a6\u6c11\u4e3b\u5171\u548c\u56fd",
        common: "\u30cd\u30d1\u30fc\u30eb",
      },
      kor: {
        official: "\ub124\ud314 \uc5f0\ubc29 \ubbfc\uc8fc \uacf5\ud654\uad6d",
        common: "\ub124\ud314",
      },
      nld: {
        official: "Federale Democratische Republiek Nepal",
        common: "Nepal",
      },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0641\u062f\u0631\u0627\u0644 \u062f\u0645\u0648\u06a9\u0631\u0627\u062a\u06cc\u06a9 \u0646\u067e\u0627\u0644",
        common: "\u0646\u067e\u0627\u0644",
      },
      pol: {
        official: "Federalna Demokratyczna Republika Nepalu",
        common: "Nepal",
      },
      por: {
        official: "Rep\u00fablica Democr\u00e1tica Federal do Nepal",
        common: "Nepal",
      },
      rus: {
        official:
          "\u0424\u0435\u0434\u0435\u0440\u0430\u0442\u0438\u0432\u043d\u0430\u044f \u0414\u0435\u043c\u043e\u043a\u0440\u0430\u0442\u0438\u0447\u0435\u0441\u043a\u0430\u044f \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u041d\u0435\u043f\u0430\u043b",
        common: "\u041d\u0435\u043f\u0430\u043b",
      },
      slk: {
        official: "Nep\u00e1lska federat\u00edvna demokratick\u00e1 republika",
        common: "Nep\u00e1l",
      },
      spa: {
        official: "Rep\u00fablica Democr\u00e1tica Federal de Nepal",
        common: "Nepal",
      },
      swe: {
        official: "Demokratiska f\u00f6rbundsrepubliken Nepal",
        common: "Nepal",
      },
      urd: {
        official:
          "\u0648\u0641\u0627\u0642\u06cc \u062c\u0645\u06c1\u0648\u0631\u06cc \u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0646\u06cc\u067e\u0627\u0644",
        common: "\u0646\u06cc\u067e\u0627\u0644",
      },
      zho: {
        official:
          "\u5c3c\u6cca\u5c14\u8054\u90a6\u6c11\u4e3b\u5171\u548c\u56fd",
        common: "\u5c3c\u6cca\u5c14",
      },
    },
    latlng: [28, 84],
    landlocked: true,
    borders: ["CHN", "IND"],
    area: 147181,
    flag: "\ud83c\uddf3\ud83c\uddf5",
    demonyms: {
      eng: { f: "Nepalese", m: "Nepalese" },
      fra: { f: "N\u00e9palaise", m: "N\u00e9palais" },
    },
    callingCodes: ["+977"],
  },
  {
    name: {
      common: "Nauru",
      official: "Republic of Nauru",
      native: {
        eng: { official: "Republic of Nauru", common: "Nauru" },
        nau: { official: "Republic of Nauru", common: "Nauru" },
      },
    },
    tld: [".nr"],
    cca2: "NR",
    ccn3: "520",
    cca3: "NRU",
    cioc: "NRU",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { AUD: { name: "Australian dollar", symbol: "$" } },
    idd: { root: "+6", suffixes: ["74"] },
    capital: ["Yaren"],
    altSpellings: [
      "NR",
      "Naoero",
      "Pleasant Island",
      "Republic of Nauru",
      "Ripublik Naoero",
    ],
    region: "Oceania",
    subregion: "Micronesia",
    languages: { eng: "English", nau: "Nauru" },
    translations: {
      ces: { official: "Republika Nauru", common: "Nauru" },
      deu: { official: "Republik Nauru", common: "Nauru" },
      est: { official: "Nauru Vabariik", common: "Nauru" },
      fin: { official: "Naurun tasavalta", common: "Nauru" },
      fra: { official: "R\u00e9publique de Nauru", common: "Nauru" },
      hrv: { official: "Republika Nauru", common: "Nauru" },
      hun: { official: "Naurui K\u00f6zt\u00e1rsas\u00e1g", common: "Nauru" },
      ita: { official: "Repubblica di Nauru", common: "Nauru" },
      jpn: {
        official: "\u30ca\u30a6\u30eb\u5171\u548c\u56fd",
        common: "\u30ca\u30a6\u30eb",
      },
      kor: {
        official: "\ub098\uc6b0\ub8e8 \uacf5\ud654\uad6d",
        common: "\ub098\uc6b0\ub8e8",
      },
      nld: { official: "Republiek Nauru", common: "Nauru" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0646\u0627\u0626\u0648\u0631\u0648",
        common: "\u0646\u0627\u0626\u0648\u0631\u0648",
      },
      pol: { official: "Republika Nauru", common: "Nauru" },
      por: { official: "Rep\u00fablica de Nauru", common: "Nauru" },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u041d\u0430\u0443\u0440\u0443",
        common: "\u041d\u0430\u0443\u0440\u0443",
      },
      slk: { official: "Naursk\u00e1 republika", common: "Nauru" },
      spa: { official: "Rep\u00fablica de Nauru", common: "Nauru" },
      swe: { official: "Republiken Nauru", common: "Nauru" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0646\u0627\u0648\u0631\u0648",
        common: "\u0646\u0627\u0648\u0631\u0648",
      },
      zho: {
        official: "\u7459\u9c81\u5171\u548c\u56fd",
        common: "\u7459\u9c81",
      },
    },
    latlng: [-0.53333333, 166.91666666],
    landlocked: false,
    borders: [],
    area: 21,
    flag: "\ud83c\uddf3\ud83c\uddf7",
    demonyms: {
      eng: { f: "Nauruan", m: "Nauruan" },
      fra: { f: "Nauruane", m: "Nauruan" },
    },
    callingCodes: ["+674"],
  },
  {
    name: {
      common: "New Zealand",
      official: "New Zealand",
      native: {
        eng: { official: "New Zealand", common: "New Zealand" },
        mri: { official: "Aotearoa", common: "Aotearoa" },
        nzs: { official: "New Zealand", common: "New Zealand" },
      },
    },
    tld: [".nz"],
    cca2: "NZ",
    ccn3: "554",
    cca3: "NZL",
    cioc: "NZL",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { NZD: { name: "New Zealand dollar", symbol: "$" } },
    idd: { root: "+6", suffixes: ["4"] },
    capital: ["Wellington"],
    altSpellings: ["NZ", "Aotearoa"],
    region: "Oceania",
    subregion: "Australia and New Zealand",
    languages: {
      eng: "English",
      mri: "M\u0101ori",
      nzs: "New Zealand Sign Language",
    },
    translations: {
      ces: {
        official: "Nov\u00fd Z\u00e9land",
        common: "Nov\u00fd Z\u00e9land",
      },
      deu: { official: "Neuseeland", common: "Neuseeland" },
      est: { official: "Uus-Meremaa", common: "Uus-Meremaa" },
      fin: { official: "Uusi-Seelanti", common: "Uusi-Seelanti" },
      fra: {
        official: "Nouvelle-Z\u00e9lande",
        common: "Nouvelle-Z\u00e9lande",
      },
      hrv: { official: "Novi Zeland", common: "Novi Zeland" },
      hun: { official: "\u00daj-Z\u00e9land", common: "\u00daj-Z\u00e9land" },
      ita: { official: "Nuova Zelanda", common: "Nuova Zelanda" },
      jpn: {
        official: "\u30cb\u30e5\u30fc\u30b8\u30fc\u30e9\u30f3\u30c9",
        common: "\u30cb\u30e5\u30fc\u30b8\u30fc\u30e9\u30f3\u30c9",
      },
      kor: {
        official: "\ub274\uc9c8\ub79c\ub4dc",
        common: "\ub274\uc9c8\ub79c\ub4dc",
      },
      nld: { official: "Nieuw Zeeland", common: "Nieuw-Zeeland" },
      per: {
        official: "\u0646\u06cc\u0648\u0632\u06cc\u0644\u0646\u062f",
        common: "\u0646\u06cc\u0648\u0632\u06cc\u0644\u0646\u062f",
      },
      pol: { official: "Nowa Zelandia", common: "Nowa Zelandia" },
      por: { official: "nova Zel\u00e2ndia", common: "Nova Zel\u00e2ndia" },
      rus: {
        official:
          "\u041d\u043e\u0432\u0430\u044f \u0417\u0435\u043b\u0430\u043d\u0434\u0438\u044f",
        common:
          "\u041d\u043e\u0432\u0430\u044f \u0417\u0435\u043b\u0430\u043d\u0434\u0438\u044f",
      },
      slk: {
        official: "Nov\u00fd Z\u00e9land",
        common: "Nov\u00fd Z\u00e9land",
      },
      spa: { official: "nueva Zelanda", common: "Nueva Zelanda" },
      swe: { official: "Nya Zeeland", common: "Nya Zeeland" },
      urd: {
        official: "\u0646\u06cc\u0648\u0632\u06cc \u0644\u06cc\u0646\u0688",
        common: "\u0646\u06cc\u0648\u0632\u06cc \u0644\u06cc\u0646\u0688",
      },
      zho: { official: "\u65b0\u897f\u5170", common: "\u65b0\u897f\u5170" },
    },
    latlng: [-41, 174],
    landlocked: false,
    borders: [],
    area: 270467,
    flag: "\ud83c\uddf3\ud83c\uddff",
    demonyms: {
      eng: { f: "New Zealander", m: "New Zealander" },
      fra: { f: "Neo-Z\u00e9landaise", m: "Neo-Z\u00e9landais" },
    },
    callingCodes: ["+64"],
  },
  {
    name: {
      common: "Oman",
      official: "Sultanate of Oman",
      native: {
        ara: {
          official: "\u0633\u0644\u0637\u0646\u0629 \u0639\u0645\u0627\u0646",
          common: "\u0639\u0645\u0627\u0646",
        },
      },
    },
    tld: [".om"],
    cca2: "OM",
    ccn3: "512",
    cca3: "OMN",
    cioc: "OMA",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { OMR: { name: "Omani rial", symbol: "\u0631.\u0639." } },
    idd: { root: "+9", suffixes: ["68"] },
    capital: ["Muscat"],
    altSpellings: ["OM", "Sultanate of Oman", "Sal\u1e6danat \u02bbUm\u0101n"],
    region: "Asia",
    subregion: "Western Asia",
    languages: { ara: "Arabic" },
    translations: {
      ces: { official: "Sultan\u00e1t Om\u00e1n", common: "Om\u00e1n" },
      deu: { official: "Sultanat Oman", common: "Oman" },
      est: { official: "Omaani Sultaniriik", common: "Omaan" },
      fin: { official: "Omanin sulttaanikunta", common: "Oman" },
      fra: { official: "Sultanat d'Oman", common: "Oman" },
      hrv: { official: "Sultanat Oman", common: "Oman" },
      hun: { official: "Om\u00e1ni Szultan\u00e1tus", common: "Om\u00e1n" },
      ita: { official: "Sultanato dell'Oman", common: "oman" },
      jpn: {
        official:
          "\u30aa\u30de\u30fc\u30f3\u00b7\u30b9\u30eb\u30bf\u30f3\u56fd",
        common: "\u30aa\u30de\u30fc\u30f3",
      },
      kor: {
        official: "\uc624\ub9cc \uc220\ud0c4\uad6d",
        common: "\uc624\ub9cc",
      },
      nld: { official: "Sultanaat van Oman", common: "Oman" },
      per: {
        official:
          "\u0633\u0644\u0637\u0627\u0646\u200c\u0646\u0634\u06cc\u0646 \u0639\u064f\u0645\u0627\u0646",
        common: "\u0639\u0645\u0627\u0646",
      },
      pol: { official: "Su\u0142tanat Omanu", common: "Oman" },
      por: { official: "Sultanato de Om\u00e3", common: "Om\u00e3" },
      rus: {
        official:
          "\u0421\u0443\u043b\u0442\u0430\u043d\u0430\u0442 \u041e\u043c\u0430\u043d",
        common: "\u041e\u043c\u0430\u043d",
      },
      slk: { official: "Om\u00e1nsky sultan\u00e1t", common: "Om\u00e1n" },
      spa: { official: "Sultanato de Om\u00e1n", common: "Om\u00e1n" },
      swe: { official: "Sultanatet Oman", common: "Oman" },
      urd: {
        official: "\u0633\u0644\u0637\u0646\u062a \u0639\u0645\u0627\u0646",
        common: "\u0639\u0645\u0627\u0646",
      },
      zho: {
        official: "\u963f\u66fc\u82cf\u4e39\u56fd",
        common: "\u963f\u66fc",
      },
    },
    latlng: [21, 57],
    landlocked: false,
    borders: ["SAU", "ARE", "YEM"],
    area: 309500,
    flag: "\ud83c\uddf4\ud83c\uddf2",
    demonyms: {
      eng: { f: "Omani", m: "Omani" },
      fra: { f: "Omanaise", m: "Omanais" },
    },
    callingCodes: ["+968"],
  },
  {
    name: {
      common: "Pakistan",
      official: "Islamic Republic of Pakistan",
      native: {
        eng: { official: "Islamic Republic of Pakistan", common: "Pakistan" },
        urd: {
          official:
            "\u0627\u0633\u0644\u0627\u0645\u06cc \u062c\u0645\u06c1\u0648\u0631\u06cc\u06c2 \u067e\u0627\u0643\u0633\u062a\u0627\u0646",
          common: "\u067e\u0627\u0643\u0633\u062a\u0627\u0646",
        },
      },
    },
    tld: [".pk"],
    cca2: "PK",
    ccn3: "586",
    cca3: "PAK",
    cioc: "PAK",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { PKR: { name: "Pakistani rupee", symbol: "\u20a8" } },
    idd: { root: "+9", suffixes: ["2"] },
    capital: ["Islamabad"],
    altSpellings: [
      "PK",
      "P\u0101kist\u0101n",
      "Islamic Republic of Pakistan",
      "Isl\u0101m\u012b Jumh\u016briya'eh P\u0101kist\u0101n",
    ],
    region: "Asia",
    subregion: "Southern Asia",
    languages: { eng: "English", urd: "Urdu" },
    translations: {
      ces: {
        official: "P\u00e1kist\u00e1nsk\u00e1 isl\u00e1msk\u00e1 republika",
        common: "P\u00e1kist\u00e1n",
      },
      deu: { official: "Islamische Republik Pakistan", common: "Pakistan" },
      est: { official: "Pakistani Islamivabariik", common: "Pakistan" },
      fin: {
        official: "Pakistanin islamilainen tasavalta",
        common: "Pakistan",
      },
      fra: {
        official: "R\u00e9publique islamique du Pakistan",
        common: "Pakistan",
      },
      hrv: { official: "Islamska Republika Pakistan", common: "Pakistan" },
      hun: { official: "Pakiszt\u00e1n", common: "Pakiszt\u00e1n" },
      ita: { official: "Repubblica islamica del Pakistan", common: "Pakistan" },
      jpn: {
        official: "\u30d1\u30ad\u30b9\u30bf\u30f3",
        common: "\u30d1\u30ad\u30b9\u30bf\u30f3",
      },
      kor: {
        official:
          "\ud30c\ud0a4\uc2a4\ud0c4 \uc774\uc2ac\ub78c \uacf5\ud654\uad6d",
        common: "\ud30c\ud0a4\uc2a4\ud0c4",
      },
      nld: { official: "Islamitische Republiek Pakistan", common: "Pakistan" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0627\u0633\u0644\u0627\u0645\u06cc \u067e\u0627\u06a9\u0633\u062a\u0627\u0646",
        common: "\u067e\u0627\u06a9\u0633\u062a\u0627\u0646",
      },
      pol: { official: "Islamska Republika Pakistanu", common: "Pakistan" },
      por: {
        official: "Rep\u00fablica Isl\u00e2mica do Paquist\u00e3o",
        common: "Paquist\u00e3o",
      },
      rus: {
        official:
          "\u0418\u0441\u043b\u0430\u043c\u0441\u043a\u0430\u044f \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u041f\u0430\u043a\u0438\u0441\u0442\u0430\u043d",
        common: "\u041f\u0430\u043a\u0438\u0441\u0442\u0430\u043d",
      },
      slk: {
        official: "Pakistansk\u00e1 islamsk\u00e1 republika",
        common: "Pakistan",
      },
      spa: {
        official: "Rep\u00fablica Isl\u00e1mica de Pakist\u00e1n",
        common: "Pakist\u00e1n",
      },
      swe: { official: "Islamiska republiken Pakistan", common: "Pakistan" },
      urd: {
        official:
          "\u0627\u0633\u0644\u0627\u0645\u06cc \u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u067e\u0627\u06a9\u0633\u062a\u0627\u0646",
        common: "\u067e\u0627\u06a9\u0633\u062a\u0627\u0646",
      },
      zho: {
        official:
          "\u5df4\u57fa\u65af\u5766\u4f0a\u65af\u5170\u5171\u548c\u56fd",
        common: "\u5df4\u57fa\u65af\u5766",
      },
    },
    latlng: [30, 70],
    landlocked: false,
    borders: ["AFG", "CHN", "IND", "IRN"],
    area: 881912,
    flag: "\ud83c\uddf5\ud83c\uddf0",
    demonyms: {
      eng: { f: "Pakistani", m: "Pakistani" },
      fra: { f: "Pakistanaise", m: "Pakistanais" },
    },
    callingCodes: ["+92"],
  },
  {
    name: {
      common: "Panama",
      official: "Republic of Panama",
      native: {
        spa: {
          official: "Rep\u00fablica de Panam\u00e1",
          common: "Panam\u00e1",
        },
      },
    },
    tld: [".pa"],
    cca2: "PA",
    ccn3: "591",
    cca3: "PAN",
    cioc: "PAN",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: {
      PAB: { name: "Panamanian balboa", symbol: "B/." },
      USD: { name: "United States dollar", symbol: "$" },
    },
    idd: { root: "+5", suffixes: ["07"] },
    capital: ["Panama City"],
    altSpellings: ["PA", "Republic of Panama", "Rep\u00fablica de Panam\u00e1"],
    region: "Americas",
    subregion: "Central America",
    languages: { spa: "Spanish" },
    translations: {
      ces: { official: "Panamsk\u00e1 republika", common: "Panama" },
      deu: { official: "Republik Panama", common: "Panama" },
      est: { official: "Panama Vabariik", common: "Panama" },
      fin: { official: "Panaman tasavalta", common: "Panama" },
      fra: { official: "R\u00e9publique du Panama", common: "Panama" },
      hrv: { official: "Republika Panama", common: "Panama" },
      hun: { official: "Panamai K\u00f6zt\u00e1rsas\u00e1g", common: "Panama" },
      ita: { official: "Repubblica di Panama", common: "Panama" },
      jpn: {
        official: "\u30d1\u30ca\u30de\u5171\u548c\u56fd",
        common: "\u30d1\u30ca\u30de",
      },
      kor: {
        official: "\ud30c\ub098\ub9c8 \uacf5\ud654\uad6d",
        common: "\ud30c\ub098\ub9c8",
      },
      nld: { official: "Republiek Panama", common: "Panama" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u067e\u0627\u0646\u0627\u0645\u0627",
        common: "\u067e\u0627\u0646\u0627\u0645\u0627",
      },
      pol: { official: "Republika Panamy", common: "Panama" },
      por: { official: "Rep\u00fablica do Panam\u00e1", common: "Panam\u00e1" },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u041f\u0430\u043d\u0430\u043c\u0430",
        common: "\u041f\u0430\u043d\u0430\u043c\u0430",
      },
      slk: { official: "Panamsk\u00e1 republika", common: "Panama" },
      spa: { official: "Rep\u00fablica de Panam\u00e1", common: "Panam\u00e1" },
      swe: { official: "Republiken Panama", common: "Panama" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u067e\u0627\u0646\u0627\u0645\u0627",
        common: "\u067e\u0627\u0646\u0627\u0645\u0627",
      },
      zho: {
        official: "\u5df4\u62ff\u9a6c\u5171\u548c\u56fd",
        common: "\u5df4\u62ff\u9a6c",
      },
    },
    latlng: [9, -80],
    landlocked: false,
    borders: ["COL", "CRI"],
    area: 75417,
    flag: "\ud83c\uddf5\ud83c\udde6",
    demonyms: {
      eng: { f: "Panamanian", m: "Panamanian" },
      fra: { f: "Panam\u00e9enne", m: "Panam\u00e9en" },
    },
    callingCodes: ["+507"],
  },
  {
    name: {
      common: "Pitcairn Islands",
      official: "Pitcairn Group of Islands",
      native: {
        eng: {
          official: "Pitcairn Group of Islands",
          common: "Pitcairn Islands",
        },
      },
    },
    tld: [".pn"],
    cca2: "PN",
    ccn3: "612",
    cca3: "PCN",
    cioc: "",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: { NZD: { name: "New Zealand dollar", symbol: "$" } },
    idd: { root: "+6", suffixes: ["4"] },
    capital: ["Adamstown"],
    altSpellings: [
      "PN",
      "Pitcairn",
      "Pitcairn Henderson Ducie and Oeno Islands",
    ],
    region: "Oceania",
    subregion: "Polynesia",
    languages: { eng: "English" },
    translations: {
      ces: { official: "Pitcairnovy ostrovy", common: "Pitcairnovy ostrovy" },
      deu: { official: "Pitcairninseln", common: "Pitcairninseln" },
      est: {
        official: "Pitcairni, Hendersoni, Ducie ja Oeno saar",
        common: "Pitcairn",
      },
      fin: { official: "Pitcairn", common: "Pitcairn" },
      fra: {
        official: "Groupe d'\u00eeles Pitcairn",
        common: "\u00celes Pitcairn",
      },
      hrv: {
        official: "Pitcairn skupine otoka",
        common: "Pitcairnovo oto\u010dje",
      },
      hun: { official: "Pitcairn-szigetek", common: "Pitcairn-szigetek" },
      ita: { official: "Pitcairn gruppo di isole", common: "Isole Pitcairn" },
      jpn: {
        official:
          "\u5cf6\u306e\u30d4\u30c8\u30b1\u30a2\u30f3\u30b0\u30eb\u30fc\u30d7",
        common: "\u30d4\u30c8\u30b1\u30a2\u30f3",
      },
      kor: {
        official: "\ud54f\ucf00\uc5b8 \uc81c\ub3c4",
        common: "\ud54f\ucf00\uc5b8 \uc81c\ub3c4",
      },
      nld: { official: "Pitcairn groep eilanden", common: "Pitcairneilanden" },
      per: {
        official:
          "\u062c\u0632\u0627\u06cc\u0631 \u067e\u06cc\u062a\u200c\u06a9\u0631\u0646",
        common:
          "\u062c\u0632\u0627\u06cc\u0631 \u067e\u06cc\u062a\u200c\u06a9\u0631\u0646",
      },
      pol: {
        official: "Wyspy Pitcairn, Henderson, Ducie i Oeno",
        common: "Pitcairn",
      },
      por: { official: "Pitcairn grupo de ilhas", common: "Ilhas Pitcairn" },
      rus: {
        official:
          "\u041f\u0438\u0442\u043a\u044d\u0440\u043d \u0433\u0440\u0443\u043f\u043f\u0430 \u043e\u0441\u0442\u0440\u043e\u0432\u043e\u0432",
        common:
          "\u041e\u0441\u0442\u0440\u043e\u0432\u0430 \u041f\u0438\u0442\u043a\u044d\u0440\u043d",
      },
      slk: { official: "Pitcairnove ostrovy", common: "Pitcairnove ostrovy" },
      spa: { official: "Grupo de Islas Pitcairn", common: "Islas Pitcairn" },
      swe: { official: "Pitcairn\u00f6arna", common: "Pitcairn\u00f6arna" },
      urd: {
        official:
          "\u067e\u0679\u06a9\u06cc\u0631\u0646 \u062c\u0632\u0627\u0626\u0631",
        common:
          "\u062c\u0632\u0627\u0626\u0631 \u067e\u0679\u06a9\u06cc\u0631\u0646",
      },
      zho: {
        official: "\u76ae\u7279\u51ef\u6069\u7fa4\u5c9b",
        common: "\u76ae\u7279\u51ef\u6069\u7fa4\u5c9b",
      },
    },
    latlng: [-25.06666666, -130.1],
    landlocked: false,
    borders: [],
    area: 47,
    flag: "\ud83c\uddf5\ud83c\uddf3",
    demonyms: {
      eng: { f: "Pitcairn Islander", m: "Pitcairn Islander" },
      fra: { f: "Pitcairnaise", m: "Pitcairnais" },
    },
    callingCodes: ["+64"],
  },
  {
    name: {
      common: "Peru",
      official: "Republic of Peru",
      native: {
        aym: { official: "Piruw Suyu", common: "Piruw" },
        que: { official: "Piruw Ripuwlika", common: "Piruw" },
        spa: { official: "Rep\u00fablica del Per\u00fa", common: "Per\u00fa" },
      },
    },
    tld: [".pe"],
    cca2: "PE",
    ccn3: "604",
    cca3: "PER",
    cioc: "PER",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { PEN: { name: "Peruvian sol", symbol: "S/." } },
    idd: { root: "+5", suffixes: ["1"] },
    capital: ["Lima"],
    altSpellings: ["PE", "Republic of Peru", "Rep\u00fablica del Per\u00fa"],
    region: "Americas",
    subregion: "South America",
    languages: { aym: "Aymara", que: "Quechua", spa: "Spanish" },
    translations: {
      ces: { official: "Peru\u00e1nsk\u00e1 republika", common: "Peru" },
      deu: { official: "Republik Peru", common: "Peru" },
      est: { official: "Peruu Vabariik", common: "Peruu" },
      fin: { official: "Perun tasavalta", common: "Peru" },
      fra: { official: "R\u00e9publique du P\u00e9rou", common: "P\u00e9rou" },
      hrv: { official: "Republika Peru", common: "Peru" },
      hun: { official: "Perui K\u00f6zt\u00e1rsas\u00e1g", common: "Peru" },
      ita: { official: "Repubblica del Per\u00f9", common: "Per\u00f9" },
      jpn: {
        official: "\u30da\u30eb\u30fc\u5171\u548c\u56fd",
        common: "\u30da\u30eb\u30fc",
      },
      kor: {
        official: "\ud398\ub8e8 \uacf5\ud654\uad6d",
        common: "\ud398\ub8e8",
      },
      nld: { official: "Republiek Peru", common: "Peru" },
      per: {
        official: "\u062c\u0645\u0647\u0648\u0631\u06cc \u067e\u0631\u0648",
        common: "\u067e\u0631\u0648",
      },
      pol: { official: "Republika Peru", common: "Peru" },
      por: { official: "Rep\u00fablica do Peru", common: "Per\u00fa" },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u041f\u0435\u0440\u0443",
        common: "\u041f\u0435\u0440\u0443",
      },
      slk: { official: "Peru\u00e1nska republika", common: "Peru" },
      spa: { official: "Rep\u00fablica de Per\u00fa", common: "Per\u00fa" },
      swe: { official: "Republiken Peru", common: "Peru" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u067e\u06cc\u0631\u0648",
        common: "\u067e\u06cc\u0631\u0648",
      },
      zho: {
        official: "\u79d8\u9c81\u5171\u548c\u56fd",
        common: "\u79d8\u9c81",
      },
    },
    latlng: [-10, -76],
    landlocked: false,
    borders: ["BOL", "BRA", "CHL", "COL", "ECU"],
    area: 1285216,
    flag: "\ud83c\uddf5\ud83c\uddea",
    demonyms: {
      eng: { f: "Peruvian", m: "Peruvian" },
      fra: { f: "P\u00e9ruvienne", m: "P\u00e9ruvien" },
    },
    callingCodes: ["+51"],
  },
  {
    name: {
      common: "Philippines",
      official: "Republic of the Philippines",
      native: {
        eng: { official: "Republic of the Philippines", common: "Philippines" },
        fil: { official: "Republic of the Philippines", common: "Pilipinas" },
      },
    },
    tld: [".ph"],
    cca2: "PH",
    ccn3: "608",
    cca3: "PHL",
    cioc: "PHI",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { PHP: { name: "Philippine peso", symbol: "\u20b1" } },
    idd: { root: "+6", suffixes: ["3"] },
    capital: ["Manila"],
    altSpellings: [
      "PH",
      "Republic of the Philippines",
      "Rep\u00fablika ng Pilipinas",
    ],
    region: "Asia",
    subregion: "South-Eastern Asia",
    languages: { eng: "English", fil: "Filipino" },
    translations: {
      ces: {
        official: "Filip\u00ednsk\u00e1 republika",
        common: "Filip\u00edny",
      },
      deu: { official: "Republik der Philippinen", common: "Philippinen" },
      est: { official: "Filipiini Vabariik", common: "Filipiinid" },
      fin: { official: "Filippiinien tasavalta", common: "Filippiinit" },
      fra: {
        official: "R\u00e9publique des Philippines",
        common: "Philippines",
      },
      hrv: { official: "Republika Filipini", common: "Filipini" },
      hun: {
        official: "F\u00fcl\u00f6p-szigeteki K\u00f6zt\u00e1rsas\u00e1g",
        common: "F\u00fcl\u00f6p-szigetek",
      },
      ita: { official: "Repubblica delle Filippine", common: "Filippine" },
      jpn: {
        official: "\u30d5\u30a3\u30ea\u30d4\u30f3\u5171\u548c\u56fd",
        common: "\u30d5\u30a3\u30ea\u30d4\u30f3",
      },
      kor: {
        official: "\ud544\ub9ac\ud540 \uacf5\ud654\uad6d",
        common: "\ud544\ub9ac\ud540",
      },
      nld: { official: "Republiek der Filipijnen", common: "Filipijnen" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0641\u06cc\u0644\u06cc\u067e\u06cc\u0646",
        common: "\u0641\u06cc\u0644\u06cc\u067e\u06cc\u0646",
      },
      pol: { official: "Republika Filipin", common: "Filipiny" },
      por: { official: "Rep\u00fablica das Filipinas", common: "Filipinas" },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0424\u0438\u043b\u0438\u043f\u043f\u0438\u043d\u044b",
        common: "\u0424\u0438\u043b\u0438\u043f\u043f\u0438\u043d\u044b",
      },
      slk: { official: "Filip\u00ednska republika", common: "Filip\u00edny" },
      spa: { official: "Rep\u00fablica de las Filipinas", common: "Filipinas" },
      swe: { official: "Republiken Filippinerna", common: "Filippinerna" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0641\u0644\u067e\u0627\u0626\u0646",
        common: "\u0641\u0644\u067e\u0627\u0626\u0646",
      },
      zho: {
        official: "\u83f2\u5f8b\u5bbe\u5171\u548c\u56fd",
        common: "\u83f2\u5f8b\u5bbe",
      },
    },
    latlng: [13, 122],
    landlocked: false,
    borders: [],
    area: 342353,
    flag: "\ud83c\uddf5\ud83c\udded",
    demonyms: {
      eng: { f: "Filipino", m: "Filipino" },
      fra: { f: "Philippine", m: "Philippin" },
    },
    callingCodes: ["+63"],
  },
  {
    name: {
      common: "Palau",
      official: "Republic of Palau",
      native: {
        eng: { official: "Republic of Palau", common: "Palau" },
        pau: { official: "Beluu er a Belau", common: "Belau" },
      },
    },
    tld: [".pw"],
    cca2: "PW",
    ccn3: "585",
    cca3: "PLW",
    cioc: "PLW",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { USD: { name: "United States dollar", symbol: "$" } },
    idd: { root: "+6", suffixes: ["80"] },
    capital: ["Ngerulmud"],
    altSpellings: ["PW", "Republic of Palau", "Beluu er a Belau"],
    region: "Oceania",
    subregion: "Micronesia",
    languages: { eng: "English", pau: "Palauan" },
    translations: {
      ces: { official: "Republika Palau", common: "Palau" },
      deu: { official: "Republik Palau", common: "Palau" },
      est: { official: "Belau Vabariik", common: "Belau" },
      fin: { official: "Palaun tasavalta", common: "Palau" },
      fra: {
        official: "R\u00e9publique des Palaos (Palau)",
        common: "Palaos (Palau)",
      },
      hrv: { official: "Republika Palau", common: "Palau" },
      hun: { official: "Palaui K\u00f6zt\u00e1rsas\u00e1g", common: "Palau" },
      ita: { official: "Repubblica di Palau", common: "Palau" },
      jpn: {
        official: "\u30d1\u30e9\u30aa\u5171\u548c\u56fd",
        common: "\u30d1\u30e9\u30aa",
      },
      kor: {
        official: "\ud314\ub77c\uc6b0 \uacf5\ud654\uad6d",
        common: "\ud314\ub77c\uc6b0",
      },
      nld: { official: "Republiek van Palau", common: "Palau" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u067e\u0627\u0644\u0627\u0626\u0648",
        common: "\u067e\u0627\u0644\u0627\u0626\u0648",
      },
      pol: { official: "Republika Palau", common: "Palau" },
      por: { official: "Rep\u00fablica de Palau", common: "Palau" },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u041f\u0430\u043b\u0430\u0443",
        common: "\u041f\u0430\u043b\u0430\u0443",
      },
      slk: { official: "Palausk\u00e1 republika", common: "Palau" },
      spa: { official: "Rep\u00fablica de Palau", common: "Palau" },
      swe: { official: "Republiken Palau", common: "Palau" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u067e\u0644\u0627\u0624",
        common: "\u067e\u0644\u0627\u0624",
      },
      zho: {
        official: "\u5e15\u52b3\u5171\u548c\u56fd",
        common: "\u5e15\u52b3",
      },
    },
    latlng: [7.5, 134.5],
    landlocked: false,
    borders: [],
    area: 459,
    flag: "\ud83c\uddf5\ud83c\uddfc",
    demonyms: {
      eng: { f: "Palauan", m: "Palauan" },
      fra: { f: "Paluane", m: "Paluan" },
    },
    callingCodes: ["+680"],
  },
  {
    name: {
      common: "Papua New Guinea",
      official: "Independent State of Papua New Guinea",
      native: {
        eng: {
          official: "Independent State of Papua New Guinea",
          common: "Papua New Guinea",
        },
        hmo: {
          official: "Independen Stet bilong Papua Niugini",
          common: "Papua Niu Gini",
        },
        tpi: {
          official: "Independen Stet bilong Papua Niugini",
          common: "Papua Niugini",
        },
      },
    },
    tld: [".pg"],
    cca2: "PG",
    ccn3: "598",
    cca3: "PNG",
    cioc: "PNG",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { PGK: { name: "Papua New Guinean kina", symbol: "K" } },
    idd: { root: "+6", suffixes: ["75"] },
    capital: ["Port Moresby"],
    altSpellings: [
      "PG",
      "Independent State of Papua New Guinea",
      "Independen Stet bilong Papua Niugini",
    ],
    region: "Oceania",
    subregion: "Melanesia",
    languages: { eng: "English", hmo: "Hiri Motu", tpi: "Tok Pisin" },
    translations: {
      ces: {
        official: "Nez\u00e1visl\u00fd st\u00e1t Papua Nov\u00e1 Guinea",
        common: "Papua-Nov\u00e1 Guinea",
      },
      deu: {
        official: "Unabh\u00e4ngiger Staat Papua-Neuguinea",
        common: "Papua-Neuguinea",
      },
      est: {
        official: "Paapua Uus-Guinea Iseseisvusriik",
        common: "Paapua Uus-Guinea",
      },
      fin: {
        official: "Papua-Uuden-Guinean Itsen\u00e4inen valtio",
        common: "Papua-Uusi-Guinea",
      },
      fra: {
        official:
          "\u00c9tat ind\u00e9pendant de Papouasie-Nouvelle-Guin\u00e9e",
        common: "Papouasie-Nouvelle-Guin\u00e9e",
      },
      hrv: {
        official: "Nezavisna Dr\u017eava Papui Novoj Gvineji",
        common: "Papua Nova Gvineja",
      },
      hun: {
        official: "P\u00e1pua \u00daj-Guinea F\u00fcggetlen \u00c1llam",
        common: "P\u00e1pua \u00daj-Guinea",
      },
      ita: {
        official: "Stato indipendente di Papua Nuova Guinea",
        common: "Papua Nuova Guinea",
      },
      jpn: {
        official:
          "\u30d1\u30d7\u30a2\u30cb\u30e5\u30fc\u30ae\u30cb\u30a2\u72ec\u7acb\u56fd",
        common: "\u30d1\u30d7\u30a2\u30cb\u30e5\u30fc\u30ae\u30cb\u30a2",
      },
      kor: {
        official: "\ud30c\ud478\uc544\ub274\uae30\ub2c8 \ub3c5\ub9bd\uad6d",
        common: "\ud30c\ud478\uc544\ub274\uae30\ub2c8",
      },
      nld: {
        official: "Onafhankelijke Staat Papoea -Nieuw-Guinea",
        common: "Papoea-Nieuw-Guinea",
      },
      per: {
        official:
          "\u0645\u0645\u0644\u06a9\u062a \u0645\u0633\u062a\u0642\u0644 \u067e\u0627\u067e\u0648\u0622 \u06af\u06cc\u0646\u0647\u0654 \u0646\u0648",
        common:
          "\u067e\u0627\u067e\u0648\u0622 \u06af\u06cc\u0646\u0647 \u0646\u0648",
      },
      pol: {
        official: "Niezale\u017cne Pa\u0144stwo Papui-Nowej Gwinei",
        common: "Papua-Nowa Gwinea",
      },
      por: {
        official: "Estado Independente da Papua Nova Guin\u00e9",
        common: "Papua Nova Guin\u00e9",
      },
      rus: {
        official:
          "\u041d\u0435\u0437\u0430\u0432\u0438\u0441\u0438\u043c\u043e\u0435 \u0413\u043e\u0441\u0443\u0434\u0430\u0440\u0441\u0442\u0432\u043e \u041f\u0430\u043f\u0443\u0430-\u041d\u043e\u0432\u043e\u0439 \u0413\u0432\u0438\u043d\u0435\u0438",
        common:
          "\u041f\u0430\u043f\u0443\u0430 \u2014 \u041d\u043e\u0432\u0430\u044f \u0413\u0432\u0438\u043d\u0435\u044f",
      },
      slk: {
        official: "Nez\u00e1visl\u00fd \u0161t\u00e1t Papua-Nov\u00e1 Guinea",
        common: "Papua-Nov\u00e1 Guinea",
      },
      spa: {
        official: "Estado Independiente de Pap\u00faa Nueva Guinea",
        common: "Pap\u00faa Nueva Guinea",
      },
      swe: {
        official: "Den oberoende staten Papua Nya Guinea",
        common: "Papua Nya Guinea",
      },
      urd: {
        official:
          "\u0622\u0632\u0627\u062f \u0631\u06cc\u0627\u0633\u062a\u0650 \u067e\u0627\u067e\u0648\u0627 \u0646\u06cc\u0648 \u06af\u0646\u06cc",
        common:
          "\u067e\u0627\u067e\u0648\u0627 \u0646\u06cc\u0648 \u06af\u0646\u06cc",
      },
      zho: {
        official: "\u5df4\u5e03\u4e9a\u65b0\u51e0\u5185\u4e9a",
        common: "\u5df4\u5e03\u4e9a\u65b0\u51e0\u5185\u4e9a",
      },
    },
    latlng: [-6, 147],
    landlocked: false,
    borders: ["IDN"],
    area: 462840,
    flag: "\ud83c\uddf5\ud83c\uddec",
    demonyms: {
      eng: { f: "Papua New Guinean", m: "Papua New Guinean" },
      fra: { f: "Papouasienne", m: "Papouasien" },
    },
    callingCodes: ["+675"],
  },
  {
    name: {
      common: "Poland",
      official: "Republic of Poland",
      native: { pol: { official: "Rzeczpospolita Polska", common: "Polska" } },
    },
    tld: [".pl"],
    cca2: "PL",
    ccn3: "616",
    cca3: "POL",
    cioc: "POL",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { PLN: { name: "Polish z\u0142oty", symbol: "z\u0142" } },
    idd: { root: "+4", suffixes: ["8"] },
    capital: ["Warsaw"],
    altSpellings: ["PL", "Republic of Poland", "Rzeczpospolita Polska"],
    region: "Europe",
    subregion: "Central Europe",
    languages: { pol: "Polish" },
    translations: {
      ces: { official: "Polsk\u00e1 republika", common: "Polsko" },
      deu: { official: "Republik Polen", common: "Polen" },
      est: { official: "Poola Vabariik", common: "Poola" },
      fin: { official: "Puolan tasavalta", common: "Puola" },
      fra: { official: "R\u00e9publique de Pologne", common: "Pologne" },
      hrv: { official: "Republika Poljska", common: "Poljska" },
      hun: {
        official: "Lengyel K\u00f6zt\u00e1rsas\u00e1g",
        common: "Lengyelorsz\u00e1g",
      },
      ita: { official: "Repubblica di Polonia", common: "Polonia" },
      jpn: {
        official: "\u30dd\u30fc\u30e9\u30f3\u30c9\u5171\u548c\u56fd",
        common: "\u30dd\u30fc\u30e9\u30f3\u30c9",
      },
      kor: {
        official: "\ud3f4\ub780\ub4dc \uacf5\ud654\uad6d",
        common: "\ud3f4\ub780\ub4dc",
      },
      nld: { official: "Republiek Polen", common: "Polen" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0644\u0647\u0633\u062a\u0627\u0646",
        common: "\u0644\u0647\u0633\u062a\u0627\u0646",
      },
      pol: { official: "Rzeczpospolita Polska", common: "Polska" },
      por: {
        official: "Rep\u00fablica da Pol\u00f3nia",
        common: "Pol\u00f3nia",
      },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u041f\u043e\u043b\u044c\u0448\u0430",
        common: "\u041f\u043e\u043b\u044c\u0448\u0430",
      },
      slk: { official: "Po\u013esk\u00e1 republika", common: "Po\u013esko" },
      spa: { official: "Rep\u00fablica de Polonia", common: "Polonia" },
      swe: { official: "Republiken Polen", common: "Polen" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u067e\u0648\u0644\u06cc\u0646\u0688",
        common: "\u067e\u0648\u0644\u06cc\u0646\u0688",
      },
      zho: {
        official: "\u6ce2\u5170\u5171\u548c\u56fd",
        common: "\u6ce2\u5170",
      },
    },
    latlng: [52, 20],
    landlocked: false,
    borders: ["BLR", "CZE", "DEU", "LTU", "RUS", "SVK", "UKR"],
    area: 312679,
    flag: "\ud83c\uddf5\ud83c\uddf1",
    demonyms: {
      eng: { f: "Polish", m: "Polish" },
      fra: { f: "Polonaise", m: "Polonais" },
    },
    callingCodes: ["+48"],
  },
  {
    name: {
      common: "Puerto Rico",
      official: "Commonwealth of Puerto Rico",
      native: {
        eng: { official: "Commonwealth of Puerto Rico", common: "Puerto Rico" },
        spa: {
          official: "Estado Libre Asociado de Puerto Rico",
          common: "Puerto Rico",
        },
      },
    },
    tld: [".pr"],
    cca2: "PR",
    ccn3: "630",
    cca3: "PRI",
    cioc: "PUR",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: { USD: { name: "United States dollar", symbol: "$" } },
    idd: { root: "+1", suffixes: ["787", "939"] },
    capital: ["San Juan"],
    altSpellings: [
      "PR",
      "Commonwealth of Puerto Rico",
      "Estado Libre Asociado de Puerto Rico",
    ],
    region: "Americas",
    subregion: "Caribbean",
    languages: { eng: "English", spa: "Spanish" },
    translations: {
      ces: { official: "Portoriko", common: "Portoriko" },
      deu: { official: "Freistaat Puerto Rico", common: "Puerto Rico" },
      est: { official: "Puerto Rico \u00dchendus", common: "Puerto Rico" },
      fin: { official: "Puerto Rico", common: "Puerto Rico" },
      fra: { official: "Porto Rico", common: "Porto Rico" },
      hrv: { official: "Zajednica Puerto Rico", common: "Portoriko" },
      hun: { official: "Puerto Rico", common: "Puerto Rico" },
      ita: { official: "Commonwealth di Porto Rico", common: "Porto Rico" },
      jpn: {
        official:
          "\u30d7\u30a8\u30eb\u30c8\u30ea\u30b3\u306e\u30b3\u30e2\u30f3\u30a6\u30a7\u30eb\u30b9",
        common: "\u30d7\u30a8\u30eb\u30c8\u30ea\u30b3",
      },
      kor: {
        official: "\ud478\uc5d0\ub974\ud1a0\ub9ac\ucf54",
        common: "\ud478\uc5d0\ub974\ud1a0\ub9ac\ucf54",
      },
      nld: { official: "Gemenebest van Puerto Rico", common: "Puerto Rico" },
      per: {
        official:
          "\u0642\u0644\u0645\u0631\u0648 \u0647\u0645\u0633\u0648\u062f \u067e\u0648\u0631\u062a\u0648\u0631\u06cc\u06a9\u0648",
        common: "\u067e\u0648\u0631\u062a\u0648\u0631\u06cc\u06a9\u0648",
      },
      pol: {
        official: "Wolne Stowarzyszone Pa\u0144stwo Portoryko",
        common: "Portoryko",
      },
      por: { official: "Commonwealth of Puerto Rico", common: "Porto Rico" },
      rus: {
        official:
          "\u0421\u043e\u0434\u0440\u0443\u0436\u0435\u0441\u0442\u0432\u043e \u041f\u0443\u044d\u0440\u0442\u043e-\u0420\u0438\u043a\u043e",
        common: "\u041f\u0443\u044d\u0440\u0442\u043e-\u0420\u0438\u043a\u043e",
      },
      slk: {
        official: "Portorick\u00e9 spolo\u010denstvo",
        common: "Portoriko",
      },
      spa: { official: "Asociado de Puerto Rico", common: "Puerto Rico" },
      swe: { official: "Puerto Rico", common: "Puerto Rico" },
      urd: {
        official:
          " \u062f\u0648\u0644\u062a\u0650 \u0645\u0634\u062a\u0631\u06a9\u06c1 \u067e\u0648\u0631\u0679\u0648 \u0631\u06cc\u06a9\u0648",
        common: "\u067e\u0648\u0631\u0679\u0648 \u0631\u06cc\u06a9\u0648",
      },
      zho: {
        official: "\u6ce2\u591a\u9ece\u5404\u8054\u90a6",
        common: "\u6ce2\u591a\u9ece\u5404",
      },
    },
    latlng: [18.25, -66.5],
    landlocked: false,
    borders: [],
    area: 8870,
    flag: "\ud83c\uddf5\ud83c\uddf7",
    demonyms: {
      eng: { f: "Puerto Rican", m: "Puerto Rican" },
      fra: { f: "Portoricaine", m: "Portoricain" },
    },
    callingCodes: ["+1787", "+1939"],
  },
  {
    name: {
      common: "North Korea",
      official: "Democratic People's Republic of Korea",
      native: {
        kor: {
          official:
            "\uc870\uc120\ubbfc\uc8fc\uc8fc\uc758\uc778\ubbfc\uacf5\ud654\uad6d",
          common: "\uc870\uc120",
        },
      },
    },
    tld: [".kp"],
    cca2: "KP",
    ccn3: "408",
    cca3: "PRK",
    cioc: "PRK",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { KPW: { name: "North Korean won", symbol: "\u20a9" } },
    idd: { root: "+8", suffixes: ["50"] },
    capital: ["Pyongyang"],
    altSpellings: [
      "KP",
      "Democratic People's Republic of Korea",
      "DPRK",
      "\uc870\uc120\ubbfc\uc8fc\uc8fc\uc758\uc778\ubbfc\uacf5\ud654\uad6d",
      "Chos\u014fn Minjuju\u016di Inmin Konghwaguk",
      "Korea, Democratic People's Republic of",
      "\ubd81\ud55c",
      "\ubd81\uc870\uc120",
    ],
    region: "Asia",
    subregion: "Eastern Asia",
    languages: { kor: "Korean" },
    translations: {
      ces: {
        official: "Korejsk\u00e1 lidov\u011b demokratick\u00e1 republika",
        common: "Severn\u00ed Korea",
      },
      deu: {
        official: "Demokratische Volksrepublik Korea",
        common: "Nordkorea",
      },
      est: {
        official: "Korea Rahvademokraatlik Vabariik",
        common: "P\u00f5hja-Korea",
      },
      fin: {
        official: "Korean demokraattinen kansantasavalta",
        common: "Pohjois-Korea",
      },
      fra: {
        official: "R\u00e9publique populaire d\u00e9mocratique de Cor\u00e9e",
        common: "Cor\u00e9e du Nord",
      },
      hrv: {
        official: "Demokratska Narodna Republika Koreja",
        common: "Sjeverna Koreja",
      },
      hun: {
        official: "Koreai N\u00e9pi Demokratikus K\u00f6zt\u00e1rsas\u00e1g",
        common: "\u00c9szak-Korea",
      },
      ita: {
        official: "Repubblica democratica popolare di Corea",
        common: "Corea del Nord",
      },
      jpn: {
        official:
          "\u671d\u9bae\u6c11\u4e3b\u4e3b\u7fa9\u4eba\u6c11\u5171\u548c\u56fd",
        common:
          "\u671d\u9bae\u6c11\u4e3b\u4e3b\u7fa9\u4eba\u6c11\u5171\u548c\u56fd",
      },
      kor: {
        official:
          "\uc870\uc120\ubbfc\uc8fc\uc8fc\uc758\uc778\ubbfc\uacf5\ud654\uad6d",
        common: "\uc870\uc120",
      },
      nld: {
        official: "Democratische Volksrepubliek Korea",
        common: "Noord-Korea",
      },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u062f\u0645\u0648\u06a9\u0631\u0627\u062a\u06cc\u06a9 \u062e\u0644\u0642 \u06a9\u0631\u0647",
        common: "\u06a9\u064f\u0631\u0647 \u0634\u0645\u0627\u0644\u06cc",
      },
      pol: {
        official: "Korea\u0144ska Republika Ludowo-Demokratyczna",
        common: "Korea P\u00f3\u0142nocna",
      },
      por: {
        official: "Rep\u00fablica Popular Democr\u00e1tica da Coreia",
        common: "Coreia do Norte",
      },
      rus: {
        official:
          "\u041a\u043e\u0440\u0435\u0439\u0441\u043a\u0430\u044f \u041d\u0430\u0440\u043e\u0434\u043d\u043e-\u0414\u0435\u043c\u043e\u043a\u0440\u0430\u0442\u0438\u0447\u0435\u0441\u043a\u0430\u044f \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u041a\u043e\u0440\u0435\u044f",
        common:
          "\u0421\u0435\u0432\u0435\u0440\u043d\u0430\u044f \u041a\u043e\u0440\u0435\u044f",
      },
      slk: {
        official: "K\u00f3rejsk\u00e1 \u013eudovodemokratick\u00e1 republika",
        common:
          "K\u00f3rejsk\u00e1 \u013eudovodemokratick\u00e1 republika (K\u013dR, Severn\u00e1 K\u00f3rea)",
      },
      spa: {
        official: "Rep\u00fablica Popular Democr\u00e1tica de Corea",
        common: "Corea del Norte",
      },
      swe: {
        official: "Demokratiska Folkrepubliken Korea",
        common: "Nordkorea",
      },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc \u0639\u0648\u0627\u0645\u06cc \u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u06a9\u0648\u0631\u06cc\u0627",
        common: "\u0634\u0645\u0627\u0644\u06cc \u06a9\u0648\u0631\u06cc\u0627",
      },
      zho: {
        official: "\u671d\u9c9c\u4eba\u6c11\u6c11\u4e3b\u5171\u548c\u56fd",
        common: "\u671d\u9c9c",
      },
    },
    latlng: [40, 127],
    landlocked: false,
    borders: ["CHN", "KOR", "RUS"],
    area: 120538,
    flag: "\ud83c\uddf0\ud83c\uddf5",
    demonyms: {
      eng: { f: "North Korean", m: "North Korean" },
      fra: { f: "Nord-cor\u00e9enne", m: "Nord-cor\u00e9en" },
    },
    callingCodes: ["+850"],
  },
  {
    name: {
      common: "Portugal",
      official: "Portuguese Republic",
      native: {
        por: { official: "Rep\u00fablica portugu\u00eas", common: "Portugal" },
      },
    },
    tld: [".pt"],
    cca2: "PT",
    ccn3: "620",
    cca3: "PRT",
    cioc: "POR",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { EUR: { name: "Euro", symbol: "\u20ac" } },
    idd: { root: "+3", suffixes: ["51"] },
    capital: ["Lisbon"],
    altSpellings: [
      "PT",
      "Portuguesa",
      "Portuguese Republic",
      "Rep\u00fablica Portuguesa",
    ],
    region: "Europe",
    subregion: "Southern Europe",
    languages: { por: "Portuguese" },
    translations: {
      ces: { official: "Portugalsk\u00e1 republika", common: "Portugalsko" },
      deu: { official: "Portugiesische Republik", common: "Portugal" },
      est: { official: "Portugali Vabariik", common: "Portugal" },
      fin: { official: "Portugalin tasavalta", common: "Portugali" },
      fra: { official: "R\u00e9publique portugaise", common: "Portugal" },
      hrv: { official: "Portugalska Republika", common: "Portugal" },
      hun: {
        official: "Portug\u00e1l K\u00f6zt\u00e1rsas\u00e1g",
        common: "Portug\u00e1lia",
      },
      ita: { official: "Repubblica portoghese", common: "Portogallo" },
      jpn: {
        official: "\u30dd\u30eb\u30c8\u30ac\u30eb\u5171\u548c\u56fd",
        common: "\u30dd\u30eb\u30c8\u30ac\u30eb",
      },
      kor: {
        official: "\ud3ec\ub974\ud22c\uac08 \uacf5\ud654\uad6d",
        common: "\ud3ec\ub974\ud22c\uac08",
      },
      nld: { official: "Portugese Republiek", common: "Portugal" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u067e\u0631\u062a\u063a\u0627\u0644",
        common: "\u067e\u0631\u062a\u063a\u0627\u0644",
      },
      pol: { official: "Republika Portugalska", common: "Portugalia" },
      por: { official: "Rep\u00fablica portugu\u00eas", common: "Portugal" },
      rus: {
        official:
          "\u041f\u043e\u0440\u0442\u0443\u0433\u0430\u043b\u044c\u0441\u043a\u0430\u044f \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430",
        common: "\u041f\u043e\u0440\u0442\u0443\u0433\u0430\u043b\u0438\u044f",
      },
      slk: { official: "Portugalsk\u00e1 republika", common: "Portugalsko" },
      spa: { official: "Rep\u00fablica Portuguesa", common: "Portugal" },
      swe: { official: "Republiken Portugal", common: "Portugal" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u067e\u0631\u062a\u06af\u0627\u0644",
        common: "\u067e\u0631\u062a\u06af\u0627\u0644",
      },
      zho: {
        official: "\u8461\u8404\u7259\u5171\u548c\u56fd",
        common: "\u8461\u8404\u7259",
      },
    },
    latlng: [39.5, -8],
    landlocked: false,
    borders: ["ESP"],
    area: 92090,
    flag: "\ud83c\uddf5\ud83c\uddf9",
    demonyms: {
      eng: { f: "Portuguese", m: "Portuguese" },
      fra: { f: "Portugaise", m: "Portugais" },
    },
    callingCodes: ["+351"],
  },
  {
    name: {
      common: "Paraguay",
      official: "Republic of Paraguay",
      native: {
        grn: { official: "Tet\u00e3 Paragu\u00e1i", common: "Paragu\u00e1i" },
        spa: { official: "Rep\u00fablica de Paraguay", common: "Paraguay" },
      },
    },
    tld: [".py"],
    cca2: "PY",
    ccn3: "600",
    cca3: "PRY",
    cioc: "PAR",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { PYG: { name: "Paraguayan guaran\u00ed", symbol: "\u20b2" } },
    idd: { root: "+5", suffixes: ["95"] },
    capital: ["Asunci\u00f3n"],
    altSpellings: [
      "PY",
      "Republic of Paraguay",
      "Rep\u00fablica del Paraguay",
      "Tet\u00e3 Paragu\u00e1i",
    ],
    region: "Americas",
    subregion: "South America",
    languages: { grn: "Guaran\u00ed", spa: "Spanish" },
    translations: {
      ces: { official: "Paraguaysk\u00e1 republika", common: "Paraguay" },
      deu: { official: "Republik Paraguay", common: "Paraguay" },
      est: { official: "Paraguay Vabariik", common: "Paraguay" },
      fin: { official: "Paraguayn tasavalta", common: "Paraguay" },
      fra: { official: "R\u00e9publique du Paraguay", common: "Paraguay" },
      hrv: { official: "Republika Paragvaj", common: "Paragvaj" },
      hun: {
        official: "Paraguayi K\u00f6zt\u00e1rsas\u00e1g",
        common: "Paraguay",
      },
      ita: { official: "Repubblica del Paraguay", common: "Paraguay" },
      jpn: {
        official: "\u30d1\u30e9\u30b0\u30a2\u30a4\u5171\u548c\u56fd",
        common: "\u30d1\u30e9\u30b0\u30a2\u30a4",
      },
      kor: {
        official: "\ud30c\ub77c\uacfc\uc774 \uacf5\ud654\uad6d",
        common: "\ud30c\ub77c\uacfc\uc774",
      },
      nld: { official: "Republiek Paraguay", common: "Paraguay" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u067e\u0627\u0631\u0627\u06af\u0648\u0626\u0647",
        common: "\u067e\u0627\u0631\u0627\u06af\u0648\u0626\u0647",
      },
      pol: { official: "Republika Paragwaju", common: "Paragwaj" },
      por: { official: "Rep\u00fablica do Paraguai", common: "Paraguai" },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u041f\u0430\u0440\u0430\u0433\u0432\u0430\u0439",
        common: "\u041f\u0430\u0440\u0430\u0433\u0432\u0430\u0439",
      },
      slk: { official: "Paraguajsk\u00e1 republika", common: "Paraguaj" },
      spa: { official: "Rep\u00fablica de Paraguay", common: "Paraguay" },
      swe: { official: "Republiken Paraguay", common: "Paraguay" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u067e\u06cc\u0631\u0627\u06af\u0648\u0626\u06d2",
        common: "\u067e\u06cc\u0631\u0627\u06af\u0648\u0626\u06d2",
      },
      zho: {
        official: "\u5df4\u62c9\u572d\u5171\u548c\u56fd",
        common: "\u5df4\u62c9\u572d",
      },
    },
    latlng: [-23, -58],
    landlocked: true,
    borders: ["ARG", "BOL", "BRA"],
    area: 406752,
    flag: "\ud83c\uddf5\ud83c\uddfe",
    demonyms: {
      eng: { f: "Paraguayan", m: "Paraguayan" },
      fra: { f: "Paraguayenne", m: "Paraguayen" },
    },
    callingCodes: ["+595"],
  },
  {
    name: {
      common: "Palestine",
      official: "State of Palestine",
      native: {
        ara: {
          official:
            "\u062f\u0648\u0644\u0629 \u0641\u0644\u0633\u0637\u064a\u0646",
          common: "\u0641\u0644\u0633\u0637\u064a\u0646",
        },
      },
    },
    tld: [".ps", "\u0641\u0644\u0633\u0637\u064a\u0646."],
    cca2: "PS",
    ccn3: "275",
    cca3: "PSE",
    cioc: "PLE",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: {
      EGP: { name: "Egyptian pound", symbol: "E\u00a3" },
      ILS: { name: "Israeli new shekel", symbol: "\u20aa" },
      JOD: { name: "Jordanian dinar", symbol: "JD" },
    },
    idd: { root: "+9", suffixes: ["70"] },
    capital: ["Ramallah"],
    altSpellings: [
      "PS",
      "Palestine, State of",
      "State of Palestine",
      "Dawlat Filas\u1e6din",
    ],
    region: "Asia",
    subregion: "Western Asia",
    languages: { ara: "Arabic" },
    translations: {
      ces: { official: "St\u00e1t Palestina", common: "Palestina" },
      deu: { official: "Staat Pal\u00e4stina", common: "Pal\u00e4stina" },
      est: { official: "Palestiina Riik", common: "Palestiina" },
      fin: { official: "Palestiinan valtio", common: "Palestiina" },
      fra: { official: "\u00c9tat de Palestine", common: "Palestine" },
      hrv: { official: "State of Palestine", common: "Palestina" },
      hun: { official: "Palesztin Auton\u00f3mia", common: "Palesztina" },
      ita: { official: "Stato di Palestina", common: "Palestina" },
      jpn: {
        official: "\u30d1\u30ec\u30b9\u30c1\u30ca\u81ea\u6cbb\u653f\u5e9c",
        common: "\u30d1\u30ec\u30b9\u30c1\u30ca",
      },
      kor: {
        official: "\ud314\ub808\uc2a4\ud0c0\uc778\uad6d",
        common: "\ud314\ub808\uc2a4\ud0c0\uc778",
      },
      nld: { official: "Staat Palestina", common: "Palestijnse gebieden" },
      per: {
        official:
          "\u062f\u0648\u0644\u062a \u0641\u0644\u0633\u0637\u06cc\u0646",
        common: "\u0641\u0644\u0633\u0637\u06cc\u0646",
      },
      pol: { official: "Pa\u0144stwo Palestyna", common: "Palestyna" },
      por: { official: "Estado da Palestina", common: "Palestina" },
      rus: {
        official:
          "\u0413\u043e\u0441\u0443\u0434\u0430\u0440\u0441\u0442\u0432\u043e \u041f\u0430\u043b\u0435\u0441\u0442\u0438\u043d\u0430",
        common: "\u041f\u0430\u043b\u0435\u0441\u0442\u0438\u043d\u0430",
      },
      slk: {
        official: "Palest\u00ednsky \u0161t\u00e1t",
        common: "Palest\u00edna",
      },
      spa: { official: "Estado de Palestina", common: "Palestina" },
      swe: { official: "Palestina", common: "Palestina" },
      urd: {
        official:
          "\u0631\u06cc\u0627\u0633\u062a\u0650 \u0641\u0644\u0633\u0637\u06cc\u0646",
        common: "\u0641\u0644\u0633\u0637\u06cc\u0646",
      },
      zho: {
        official: "\u5df4\u52d2\u65af\u5766\u56fd",
        common: "\u5df4\u52d2\u65af\u5766",
      },
    },
    latlng: [31.9, 35.2],
    landlocked: false,
    borders: ["ISR", "EGY", "JOR"],
    area: 6220,
    flag: "\ud83c\uddf5\ud83c\uddf8",
    demonyms: {
      eng: { f: "Palestinian", m: "Palestinian" },
      fra: { f: "Palestinienne", m: "Palestinien" },
    },
    callingCodes: ["+970"],
  },
  {
    name: {
      common: "French Polynesia",
      official: "French Polynesia",
      native: {
        fra: {
          official: "Polyn\u00e9sie fran\u00e7aise",
          common: "Polyn\u00e9sie fran\u00e7aise",
        },
      },
    },
    tld: [".pf"],
    cca2: "PF",
    ccn3: "258",
    cca3: "PYF",
    cioc: "",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: { XPF: { name: "CFP franc", symbol: "\u20a3" } },
    idd: { root: "+6", suffixes: ["89"] },
    capital: ["Papeet\u0113"],
    altSpellings: [
      "PF",
      "Polyn\u00e9sie fran\u00e7aise",
      "French Polynesia",
      "P\u014dr\u012bnetia Far\u0101ni",
    ],
    region: "Oceania",
    subregion: "Polynesia",
    languages: { fra: "French" },
    translations: {
      ces: {
        official: "Francouzsk\u00e1 Polyn\u00e9sie",
        common: "Francouzsk\u00e1 Polyn\u00e9sie",
      },
      deu: {
        official: "Franz\u00f6sisch-Polynesien",
        common: "Franz\u00f6sisch-Polynesien",
      },
      est: {
        official: "Prantsuse Pol\u00fcneesia",
        common: "Prantsuse Pol\u00fcneesia",
      },
      fin: { official: "Ranskan Polynesia", common: "Ranskan Polynesia" },
      fra: {
        official: "Polyn\u00e9sie fran\u00e7aise",
        common: "Polyn\u00e9sie fran\u00e7aise",
      },
      hrv: { official: "Francuska Polinezija", common: "Francuska Polinezija" },
      hun: {
        official: "Francia Polin\u00e9zia",
        common: "Francia Polin\u00e9zia",
      },
      ita: { official: "Polinesia Francese", common: "Polinesia Francese" },
      jpn: {
        official:
          "\u30d5\u30e9\u30f3\u30b9\u9818\u30dd\u30ea\u30cd\u30b7\u30a2",
        common: "\u30d5\u30e9\u30f3\u30b9\u9818\u30dd\u30ea\u30cd\u30b7\u30a2",
      },
      kor: {
        official: "\ud504\ub791\uc2a4\ub839 \ud3f4\ub9ac\ub124\uc2dc\uc544",
        common: "\ud504\ub791\uc2a4\ub839 \ud3f4\ub9ac\ub124\uc2dc\uc544",
      },
      nld: { official: "Frans-Polynesi\u00eb", common: "Frans-Polynesi\u00eb" },
      per: {
        official:
          "\u067e\u064f\u0644\u06cc\u200c\u0646\u0650\u0632\u06cc \u0641\u0631\u0627\u0646\u0633\u0647",
        common:
          "\u067e\u064f\u0644\u06cc\u200c\u0646\u0650\u0632\u06cc \u0641\u0631\u0627\u0646\u0633\u0647",
      },
      pol: { official: "Polinezja Francuska", common: "Polinezja Francuska" },
      por: {
        official: "Polin\u00e9sia Francesa",
        common: "Polin\u00e9sia Francesa",
      },
      rus: {
        official:
          "\u0424\u0440\u0430\u043d\u0446\u0443\u0437\u0441\u043a\u0430\u044f \u041f\u043e\u043b\u0438\u043d\u0435\u0437\u0438\u044f",
        common:
          "\u0424\u0440\u0430\u043d\u0446\u0443\u0437\u0441\u043a\u0430\u044f \u041f\u043e\u043b\u0438\u043d\u0435\u0437\u0438\u044f",
      },
      slk: {
        official: "Franc\u00fazska Polyn\u00e9zia",
        common: "Franc\u00fazska Polyn\u00e9zia",
      },
      spa: { official: "Polinesia franc\u00e9s", common: "Polinesia Francesa" },
      swe: { official: "Franska Polynesien", common: "Franska Polynesien" },
      urd: {
        official:
          "\u0641\u0631\u0627\u0646\u0633\u06cc\u0633\u06cc \u067e\u0648\u0644\u06cc\u0646\u06cc\u0634\u06cc\u0627",
        common:
          "\u0641\u0631\u0627\u0646\u0633\u06cc\u0633\u06cc \u067e\u0648\u0644\u06cc\u0646\u06cc\u0634\u06cc\u0627",
      },
      zho: {
        official: "\u6cd5\u5c5e\u6ce2\u5229\u5c3c\u897f\u4e9a",
        common: "\u6cd5\u5c5e\u6ce2\u5229\u5c3c\u897f\u4e9a",
      },
    },
    latlng: [-15, -140],
    landlocked: false,
    borders: [],
    area: 4167,
    flag: "\ud83c\uddf5\ud83c\uddeb",
    demonyms: {
      eng: { f: "French Polynesian", m: "French Polynesian" },
      fra: { f: "Polyn\u00e9sienne", m: "Polyn\u00e9sien" },
    },
    callingCodes: ["+689"],
  },
  {
    name: {
      common: "Qatar",
      official: "State of Qatar",
      native: {
        ara: {
          official: "\u062f\u0648\u0644\u0629 \u0642\u0637\u0631",
          common: "\u0642\u0637\u0631",
        },
      },
    },
    tld: [".qa", "\u0642\u0637\u0631."],
    cca2: "QA",
    ccn3: "634",
    cca3: "QAT",
    cioc: "QAT",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { QAR: { name: "Qatari riyal", symbol: "\u0631.\u0642" } },
    idd: { root: "+9", suffixes: ["74"] },
    capital: ["Doha"],
    altSpellings: ["QA", "State of Qatar", "Dawlat Qa\u1e6dar"],
    region: "Asia",
    subregion: "Western Asia",
    languages: { ara: "Arabic" },
    translations: {
      ces: { official: "St\u00e1t Katar", common: "Katar" },
      deu: { official: "Staat Katar", common: "Katar" },
      est: { official: "Katari Riik", common: "Katar" },
      fin: { official: "Qatarin valtio", common: "Qatar" },
      fra: { official: "\u00c9tat du Qatar", common: "Qatar" },
      hrv: { official: "Dr\u017eava Katar", common: "Katar" },
      hun: { official: "Katari \u00c1llam", common: "Katar" },
      ita: { official: "Stato del Qatar", common: "Qatar" },
      jpn: {
        official: "\u30ab\u30bf\u30fc\u30eb\u56fd",
        common: "\u30ab\u30bf\u30fc\u30eb",
      },
      kor: {
        official: "\uce74\ud0c0\ub974\uad6d",
        common: "\uce74\ud0c0\ub974",
      },
      nld: { official: "Staat Qatar", common: "Qatar" },
      per: {
        official: "\u062f\u0648\u0644\u062a \u0642\u0637\u0631",
        common: "\u0642\u0637\u0631",
      },
      pol: { official: "Pa\u0144stwo Katar", common: "Katar" },
      por: { official: "Estado do Qatar", common: "Catar" },
      rus: {
        official:
          "\u0413\u043e\u0441\u0443\u0434\u0430\u0440\u0441\u0442\u0432\u043e \u041a\u0430\u0442\u0430\u0440",
        common: "\u041a\u0430\u0442\u0430\u0440",
      },
      slk: { official: "Katarsk\u00fd \u0161t\u00e1t", common: "Katar" },
      spa: { official: "Estado de Qatar", common: "Catar" },
      swe: { official: "Staten Qatar", common: "Qatar" },
      urd: {
        official: "\u0631\u06cc\u0627\u0633\u062a\u0650 \u0642\u0637\u0631",
        common: "\u0642\u0637\u0631",
      },
      zho: {
        official: "\u5361\u5854\u5c14\u56fd",
        common: "\u5361\u5854\u5c14",
      },
    },
    latlng: [25.5, 51.25],
    landlocked: false,
    borders: ["SAU"],
    area: 11586,
    flag: "\ud83c\uddf6\ud83c\udde6",
    demonyms: {
      eng: { f: "Qatari", m: "Qatari" },
      fra: { f: "Qatarienne", m: "Qatarien" },
    },
    callingCodes: ["+974"],
  },
  {
    name: {
      common: "R\u00e9union",
      official: "R\u00e9union Island",
      native: {
        fra: { official: "Ile de la R\u00e9union", common: "La R\u00e9union" },
      },
    },
    tld: [".re"],
    cca2: "RE",
    ccn3: "638",
    cca3: "REU",
    cioc: "",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: { EUR: { name: "Euro", symbol: "\u20ac" } },
    idd: { root: "+2", suffixes: ["62"] },
    capital: ["Saint-Denis"],
    altSpellings: ["RE", "Reunion"],
    region: "Africa",
    subregion: "Eastern Africa",
    languages: { fra: "French" },
    translations: {
      ces: { official: "R\u00e9union", common: "R\u00e9union" },
      deu: { official: "R\u00e9union", common: "R\u00e9union" },
      est: { official: "R\u00e9unioni departemang", common: "R\u00e9union" },
      fin: { official: "R\u00e9union", common: "R\u00e9union" },
      fra: { official: "Ile de la R\u00e9union", common: "R\u00e9union" },
      hrv: { official: "R\u00e9union Island", common: "R\u00e9union" },
      hun: { official: "R\u00e9union", common: "R\u00e9union" },
      ita: { official: "R\u00e9union", common: "Riunione" },
      jpn: {
        official: "\u30ec\u30e6\u30cb\u30aa\u30f3\u5cf6",
        common: "\u30ec\u30e6\u30cb\u30aa\u30f3",
      },
      kor: {
        official: "\ub808\uc704\ub2c8\uc639",
        common: "\ub808\uc704\ub2c8\uc639",
      },
      nld: { official: "R\u00e9union", common: "R\u00e9union" },
      per: {
        official: "\u0631\u0626\u0648\u0646\u06cc\u0648\u0646",
        common: "\u0631\u0626\u0648\u0646\u06cc\u0648\u0646",
      },
      pol: { official: "Reunion", common: "Reunion" },
      por: { official: "Ilha da Reuni\u00e3o", common: "Reuni\u00e3o" },
      rus: {
        official: "\u0420\u0435\u044e\u043d\u044c\u043e\u043d",
        common: "\u0420\u0435\u044e\u043d\u044c\u043e\u043d",
      },
      slk: {
        official: "R\u00e9unionsk\u00fd z\u00e1morsk\u00fd departm\u00e1n",
        common: "R\u00e9union",
      },
      spa: { official: "Isla de la Reuni\u00f3n", common: "Reuni\u00f3n" },
      swe: { official: "R\u00e9union", common: "R\u00e9union" },
      urd: {
        official:
          "\u0631\u06d2 \u06cc\u0648\u0646\u06cc\u0648\u06ba \u062c\u0632\u06cc\u0631\u06c1",
        common: "\u0631\u06d2 \u06cc\u0648\u0646\u06cc\u0648\u06ba",
      },
      zho: {
        official: "\u7559\u5c3c\u65fa\u5c9b",
        common: "\u7559\u5c3c\u65fa\u5c9b",
      },
    },
    latlng: [-21.15, 55.5],
    landlocked: false,
    borders: [],
    area: 2511,
    flag: "\ud83c\uddf7\ud83c\uddea",
    demonyms: {
      eng: { f: "R\u00e9unionese", m: "R\u00e9unionese" },
      fra: { f: "R\u00e9unionnaise", m: "R\u00e9unionnais" },
    },
    callingCodes: ["+262"],
  },
  {
    name: {
      common: "Romania",
      official: "Romania",
      native: { ron: { official: "Rom\u00e2nia", common: "Rom\u00e2nia" } },
    },
    tld: [".ro"],
    cca2: "RO",
    ccn3: "642",
    cca3: "ROU",
    cioc: "ROU",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { RON: { name: "Romanian leu", symbol: "lei" } },
    idd: { root: "+4", suffixes: ["0"] },
    capital: ["Bucharest"],
    altSpellings: ["RO", "Rumania", "Roumania", "Rom\u00e2nia"],
    region: "Europe",
    subregion: "Southeast Europe",
    languages: { ron: "Romanian" },
    translations: {
      ces: { official: "Rumunsko", common: "Rumunsko" },
      deu: { official: "Rum\u00e4nien", common: "Rum\u00e4nien" },
      est: { official: "Rumeenia", common: "Rumeenia" },
      fin: { official: "Romania", common: "Romania" },
      fra: { official: "Roumanie", common: "Roumanie" },
      hrv: { official: "Rumunija", common: "Rumunjska" },
      hun: { official: "Rom\u00e1nia", common: "Rom\u00e1nia" },
      ita: { official: "Romania", common: "Romania" },
      jpn: {
        official: "\u30eb\u30fc\u30de\u30cb\u30a2",
        common: "\u30eb\u30fc\u30de\u30cb\u30a2",
      },
      kor: {
        official: "\ub8e8\ub9c8\ub2c8\uc544",
        common: "\ub8e8\ub9c8\ub2c8\uc544",
      },
      nld: { official: "Roemeni\u00eb", common: "Roemeni\u00eb" },
      per: {
        official: "\u0631\u0648\u0645\u0627\u0646\u06cc",
        common: "\u0631\u0648\u0645\u0627\u0646\u06cc",
      },
      pol: { official: "Rumunia", common: "Rumunia" },
      por: { official: "Rom\u00eania", common: "Rom\u00e9nia" },
      rus: {
        official: "\u0420\u0443\u043c\u044b\u043d\u0438\u044f",
        common: "\u0420\u0443\u043c\u044b\u043d\u0438\u044f",
      },
      slk: { official: "Rumunsko", common: "Rumunsko" },
      spa: { official: "Rumania", common: "Rumania" },
      swe: { official: "Rum\u00e4nien", common: "Rum\u00e4nien" },
      urd: {
        official: "\u0631\u0648\u0645\u0627\u0646\u06cc\u06c1",
        common: "\u0631\u0648\u0645\u0627\u0646\u06cc\u06c1",
      },
      zho: {
        official: "\u7f57\u9a6c\u5c3c\u4e9a",
        common: "\u7f57\u9a6c\u5c3c\u4e9a",
      },
    },
    latlng: [46, 25],
    landlocked: false,
    borders: ["BGR", "HUN", "MDA", "SRB", "UKR"],
    area: 238391,
    flag: "\ud83c\uddf7\ud83c\uddf4",
    demonyms: {
      eng: { f: "Romanian", m: "Romanian" },
      fra: { f: "Roumaine", m: "Roumain" },
    },
    callingCodes: ["+40"],
  },
  {
    name: {
      common: "Russia",
      official: "Russian Federation",
      native: {
        rus: {
          official:
            "\u0420\u043e\u0441\u0441\u0438\u0439\u0441\u043a\u0430\u044f \u0424\u0435\u0434\u0435\u0440\u0430\u0446\u0438\u044f",
          common: "\u0420\u043e\u0441\u0441\u0438\u044f",
        },
      },
    },
    tld: [".ru", ".su", ".\u0440\u0444"],
    cca2: "RU",
    ccn3: "643",
    cca3: "RUS",
    cioc: "RUS",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { RUB: { name: "Russian ruble", symbol: "\u20bd" } },
    idd: { root: "+7", suffixes: ["3", "4", "5", "8", "9"] },
    capital: ["Moscow"],
    altSpellings: [
      "RU",
      "Russian Federation",
      "\u0420\u043e\u0441\u0441\u0438\u0439\u0441\u043a\u0430\u044f \u0424\u0435\u0434\u0435\u0440\u0430\u0446\u0438\u044f",
    ],
    region: "Europe",
    subregion: "Eastern Europe",
    languages: { rus: "Russian" },
    translations: {
      ces: { official: "Rusk\u00e1 federace", common: "Rusko" },
      deu: { official: "Russische F\u00f6deration", common: "Russland" },
      est: { official: "Venemaa F\u00f6deratsioon", common: "Venemaa" },
      fin: {
        official: "Ven\u00e4j\u00e4n federaatio",
        common: "Ven\u00e4j\u00e4",
      },
      fra: { official: "F\u00e9d\u00e9ration de Russie", common: "Russie" },
      hrv: { official: "Ruska Federacija", common: "Rusija" },
      hun: {
        official: "Oroszorsz\u00e1gi F\u00f6der\u00e1ci\u00f3",
        common: "Oroszorsz\u00e1g",
      },
      ita: { official: "Federazione russa", common: "Russia" },
      jpn: {
        official: "\u30ed\u30b7\u30a2\u9023\u90a6",
        common: "\u30ed\u30b7\u30a2\u9023\u90a6",
      },
      kor: {
        official: "\ub7ec\uc2dc\uc544 \uc5f0\ubc29",
        common: "\ub7ec\uc2dc\uc544",
      },
      nld: { official: "Russische Federatie", common: "Rusland" },
      per: {
        official:
          "\u0641\u062f\u0631\u0627\u0633\u06cc\u0648\u0646 \u0631\u0648\u0633\u06cc\u0647",
        common: "\u0631\u0648\u0633\u06cc\u0647",
      },
      pol: { official: "Federacja Rosyjska", common: "Rosja" },
      por: { official: "Federa\u00e7\u00e3o Russa", common: "R\u00fassia" },
      rus: {
        official:
          "\u0420\u043e\u0441\u0441\u0438\u0439\u0441\u043a\u0430\u044f \u0424\u0435\u0434\u0435\u0440\u0430\u0446\u0438\u044f",
        common: "\u0420\u043e\u0441\u0441\u0438\u044f",
      },
      slk: { official: "Rusk\u00e1 feder\u00e1cia", common: "Rusko" },
      spa: { official: "Federaci\u00f3n de Rusia", common: "Rusia" },
      swe: { official: "Ryska federationen", common: "Ryssland" },
      urd: {
        official: "\u0631\u0648\u0633\u06cc \u0648\u0641\u0627\u0642",
        common: "\u0631\u0648\u0633",
      },
      zho: {
        official: "\u4fc4\u7f57\u65af\u8054\u90a6",
        common: "\u4fc4\u7f57\u65af",
      },
    },
    latlng: [60, 100],
    landlocked: false,
    borders: [
      "AZE",
      "BLR",
      "CHN",
      "EST",
      "FIN",
      "GEO",
      "KAZ",
      "PRK",
      "LVA",
      "LTU",
      "MNG",
      "NOR",
      "POL",
      "UKR",
    ],
    area: 17098242,
    flag: "\ud83c\uddf7\ud83c\uddfa",
    demonyms: {
      eng: { f: "Russian", m: "Russian" },
      fra: { f: "Russe", m: "Russe" },
    },
    callingCodes: ["+73", "+74", "+75", "+78", "+79"],
  },
  {
    name: {
      common: "Rwanda",
      official: "Republic of Rwanda",
      native: {
        eng: { official: "Republic of Rwanda", common: "Rwanda" },
        fra: { official: "R\u00e9publique rwandaise", common: "Rwanda" },
        kin: { official: "Repubulika y'u Rwanda", common: "Rwanda" },
      },
    },
    tld: [".rw"],
    cca2: "RW",
    ccn3: "646",
    cca3: "RWA",
    cioc: "RWA",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { RWF: { name: "Rwandan franc", symbol: "Fr" } },
    idd: { root: "+2", suffixes: ["50"] },
    capital: ["Kigali"],
    altSpellings: [
      "RW",
      "Republic of Rwanda",
      "Repubulika y'u Rwanda",
      "R\u00e9publique du Rwanda",
    ],
    region: "Africa",
    subregion: "Eastern Africa",
    languages: { eng: "English", fra: "French", kin: "Kinyarwanda" },
    translations: {
      ces: { official: "Rwandsk\u00e1 republika", common: "Rwanda" },
      deu: { official: "Republik Ruanda", common: "Ruanda" },
      est: { official: "Rwanda Vabariik", common: "Rwanda" },
      fin: { official: "Ruandan tasavalta", common: "Ruanda" },
      fra: { official: "R\u00e9publique rwandaise", common: "Rwanda" },
      hrv: { official: "Republika Ruandi", common: "Ruanda" },
      hun: { official: "Ruandai K\u00f6zt\u00e1rsas\u00e1g", common: "Ruanda" },
      ita: { official: "Repubblica del Ruanda", common: "Ruanda" },
      jpn: {
        official: "\u30eb\u30ef\u30f3\u30c0\u5171\u548c\u56fd",
        common: "\u30eb\u30ef\u30f3\u30c0",
      },
      kor: {
        official: "\ub974\uc644\ub2e4 \uacf5\ud654\uad6d",
        common: "\ub974\uc644\ub2e4",
      },
      nld: { official: "Republiek Rwanda", common: "Rwanda" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0631\u0648\u0627\u0646\u062f\u0627",
        common: "\u0631\u0648\u0627\u0646\u062f\u0627",
      },
      pol: { official: "Republika Rwandy", common: "Rwanda" },
      por: { official: "Rep\u00fablica do Ruanda", common: "Ruanda" },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0420\u0443\u0430\u043d\u0434\u0430",
        common: "\u0420\u0443\u0430\u043d\u0434\u0430",
      },
      slk: { official: "Rwandsk\u00e1 republika", common: "Rwanda" },
      spa: { official: "Rep\u00fablica de Rwanda", common: "Ruanda" },
      swe: { official: "Republiken Rwanda", common: "Rwanda" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0631\u0648\u0627\u0646\u0688\u0627",
        common: "\u0631\u0648\u0627\u0646\u0688\u0627",
      },
      zho: {
        official: "\u5362\u65fa\u8fbe\u5171\u548c\u56fd",
        common: "\u5362\u65fa\u8fbe",
      },
    },
    latlng: [-2, 30],
    landlocked: true,
    borders: ["BDI", "COD", "TZA", "UGA"],
    area: 26338,
    flag: "\ud83c\uddf7\ud83c\uddfc",
    demonyms: {
      eng: { f: "Rwandan", m: "Rwandan" },
      fra: { f: "Rwandaise", m: "Rwandais" },
    },
    callingCodes: ["+250"],
  },
  {
    name: {
      common: "Saudi Arabia",
      official: "Kingdom of Saudi Arabia",
      native: {
        ara: {
          official:
            "\u0627\u0644\u0645\u0645\u0644\u0643\u0629 \u0627\u0644\u0639\u0631\u0628\u064a\u0629 \u0627\u0644\u0633\u0639\u0648\u062f\u064a\u0629",
          common:
            "\u0627\u0644\u0639\u0631\u0628\u064a\u0629 \u0627\u0644\u0633\u0639\u0648\u062f\u064a\u0629",
        },
      },
    },
    tld: [".sa", ".\u0627\u0644\u0633\u0639\u0648\u062f\u064a\u0629"],
    cca2: "SA",
    ccn3: "682",
    cca3: "SAU",
    cioc: "KSA",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { SAR: { name: "Saudi riyal", symbol: "\u0631.\u0633" } },
    idd: { root: "+9", suffixes: ["66"] },
    capital: ["Riyadh"],
    altSpellings: [
      "Saudi",
      "SA",
      "Kingdom of Saudi Arabia",
      "Al-Mamlakah al-\u2018Arabiyyah as-Su\u2018\u016bdiyyah",
    ],
    region: "Asia",
    subregion: "Western Asia",
    languages: { ara: "Arabic" },
    translations: {
      ces: {
        official: "Sa\u00fadskoarabsk\u00e9 kr\u00e1lovstv\u00ed",
        common: "Sa\u00fadsk\u00e1 Ar\u00e1bie",
      },
      deu: {
        official: "K\u00f6nigreich Saudi-Arabien",
        common: "Saudi-Arabien",
      },
      est: { official: "Saudi Araabia Kuningriik", common: "Saudi Araabia" },
      fin: { official: "Saudi-Arabian kuningaskunta", common: "Saudi-Arabia" },
      fra: { official: "Royaume d'Arabie Saoudite", common: "Arabie Saoudite" },
      hrv: {
        official: "Kraljevina Saudijska Arabija",
        common: "Saudijska Arabija",
      },
      hun: {
        official: "Sza\u00fad-Ar\u00e1bia",
        common: "Sza\u00fad-Ar\u00e1bia",
      },
      ita: { official: "Arabia Saudita", common: "Arabia Saudita" },
      jpn: {
        official: "\u30b5\u30a6\u30b8\u30a2\u30e9\u30d3\u30a2\u738b\u56fd",
        common: "\u30b5\u30a6\u30b8\u30a2\u30e9\u30d3\u30a2",
      },
      kor: {
        official: "\uc0ac\uc6b0\ub514\uc544\ub77c\ube44\uc544 \uc655\uad6d",
        common: "\uc0ac\uc6b0\ub514\uc544\ub77c\ube44\uc544",
      },
      nld: {
        official: "Koninkrijk van Saoedi-Arabi\u00eb",
        common: "Saoedi-Arabi\u00eb",
      },
      per: {
        official:
          "\u067e\u0627\u062f\u0634\u0627\u0647\u06cc \u0639\u0631\u0628\u06cc \u0633\u064e\u0639\u0648\u062f\u06cc",
        common:
          "\u0639\u0631\u0628\u0633\u062a\u0627\u0646 \u0633\u0639\u0648\u062f\u06cc",
      },
      pol: {
        official: "Kr\u00f3lestwo Arabii Saudyjskiej",
        common: "Arabia Saudyjska",
      },
      por: {
        official: "Reino da Ar\u00e1bia Saudita",
        common: "Ar\u00e1bia Saudita",
      },
      rus: {
        official:
          "\u041a\u043e\u0440\u043e\u043b\u0435\u0432\u0441\u0442\u0432\u043e \u0421\u0430\u0443\u0434\u043e\u0432\u0441\u043a\u0430\u044f \u0410\u0440\u0430\u0432\u0438\u044f",
        common:
          "\u0421\u0430\u0443\u0434\u043e\u0432\u0441\u043a\u0430\u044f \u0410\u0440\u0430\u0432\u0438\u044f",
      },
      slk: {
        official: "Saudskoarabsk\u00e9 kr\u00e1\u013eovstvo",
        common: "Saudsk\u00e1 Ar\u00e1bia",
      },
      spa: { official: "Reino de Arabia Saudita", common: "Arabia Saud\u00ed" },
      swe: { official: "Kungad\u00f6met Saudiarabien", common: "Saudiarabien" },
      urd: {
        official:
          "\u0645\u0645\u0644\u06a9\u062a\u0650 \u0633\u0639\u0648\u062f\u06cc \u0639\u0631\u0628",
        common: "\u0633\u0639\u0648\u062f\u06cc \u0639\u0631\u0628",
      },
      zho: {
        official: "\u6c99\u7279\u963f\u62c9\u4f2f\u738b\u56fd",
        common: "\u6c99\u7279\u963f\u62c9\u4f2f",
      },
    },
    latlng: [25, 45],
    landlocked: false,
    borders: ["IRQ", "JOR", "KWT", "OMN", "QAT", "ARE", "YEM"],
    area: 2149690,
    flag: "\ud83c\uddf8\ud83c\udde6",
    demonyms: {
      eng: { f: "Saudi Arabian", m: "Saudi Arabian" },
      fra: { f: "Saoudienne", m: "Saoudien" },
    },
    callingCodes: ["+966"],
  },
  {
    name: {
      common: "Sudan",
      official: "Republic of the Sudan",
      native: {
        ara: {
          official:
            "\u062c\u0645\u0647\u0648\u0631\u064a\u0629 \u0627\u0644\u0633\u0648\u062f\u0627\u0646",
          common: "\u0627\u0644\u0633\u0648\u062f\u0627\u0646",
        },
        eng: { official: "Republic of the Sudan", common: "Sudan" },
      },
    },
    tld: [".sd"],
    cca2: "SD",
    ccn3: "729",
    cca3: "SDN",
    cioc: "SUD",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { SDG: { name: "Sudanese pound", symbol: "" } },
    idd: { root: "+2", suffixes: ["49"] },
    capital: ["Khartoum"],
    altSpellings: [
      "SD",
      "Republic of the Sudan",
      "Jumh\u016br\u012byat as-S\u016bd\u0101n",
    ],
    region: "Africa",
    subregion: "Northern Africa",
    languages: { ara: "Arabic", eng: "English" },
    translations: {
      ces: {
        official: "S\u00fad\u00e1nsk\u00e1 republika",
        common: "S\u00fad\u00e1n",
      },
      deu: { official: "Republik Sudan", common: "Sudan" },
      est: { official: "Sudaani Vabariik", common: "Sudaan" },
      fin: { official: "Sudanin tasavalta", common: "Sudan" },
      fra: { official: "R\u00e9publique du Soudan", common: "Soudan" },
      hrv: { official: "Republika Sudan", common: "Sudan" },
      hun: {
        official: "Szud\u00e1ni K\u00f6zt\u00e1rsas\u00e1g",
        common: "Szud\u00e1n",
      },
      ita: { official: "Repubblica del Sudan", common: "Sudan" },
      jpn: {
        official: "\u30b9\u30fc\u30c0\u30f3\u5171\u548c\u56fd",
        common: "\u30b9\u30fc\u30c0\u30f3",
      },
      kor: {
        official: "\uc218\ub2e8 \uacf5\ud654\uad6d",
        common: "\uc218\ub2e8",
      },
      nld: { official: "Republiek Soedan", common: "Soedan" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0633\u0648\u062f\u0627\u0646",
        common: "\u0633\u0648\u062f\u0627\u0646",
      },
      pol: { official: "Republika Sudanu", common: "Sudan" },
      por: { official: "Rep\u00fablica do Sud\u00e3o", common: "Sud\u00e3o" },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0421\u0443\u0434\u0430\u043d",
        common: "\u0421\u0443\u0434\u0430\u043d",
      },
      slk: { official: "Sud\u00e1nska republika", common: "Sud\u00e1n" },
      spa: { official: "Rep\u00fablica de Sud\u00e1n", common: "Sud\u00e1n" },
      swe: { official: "Republiken Sudan", common: "Sudan" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0633\u0648\u062f\u0627\u0646",
        common: "\u0633\u0648\u062f\u0627\u0646",
      },
      zho: {
        official: "\u82cf\u4e39\u5171\u548c\u56fd",
        common: "\u82cf\u4e39",
      },
    },
    latlng: [15, 30],
    landlocked: false,
    borders: ["CAF", "TCD", "EGY", "ERI", "ETH", "LBY", "SSD"],
    area: 1886068,
    flag: "\ud83c\uddf8\ud83c\udde9",
    demonyms: {
      eng: { f: "Sudanese", m: "Sudanese" },
      fra: { f: "Soudanaise", m: "Soudanais" },
    },
    callingCodes: ["+249"],
  },
  {
    name: {
      common: "Senegal",
      official: "Republic of Senegal",
      native: {
        fra: {
          official: "R\u00e9publique du S\u00e9n\u00e9gal",
          common: "S\u00e9n\u00e9gal",
        },
      },
    },
    tld: [".sn"],
    cca2: "SN",
    ccn3: "686",
    cca3: "SEN",
    cioc: "SEN",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { XOF: { name: "West African CFA franc", symbol: "Fr" } },
    idd: { root: "+2", suffixes: ["21"] },
    capital: ["Dakar"],
    altSpellings: [
      "SN",
      "Republic of Senegal",
      "R\u00e9publique du S\u00e9n\u00e9gal",
    ],
    region: "Africa",
    subregion: "Western Africa",
    languages: { fra: "French" },
    translations: {
      ces: { official: "Senegalsk\u00e1 republika", common: "Senegal" },
      deu: { official: "Republik Senegal", common: "Senegal" },
      est: { official: "Senegali Vabariik", common: "Senegal" },
      fin: { official: "Senegalin tasavalta", common: "Senegal" },
      fra: {
        official: "R\u00e9publique du S\u00e9n\u00e9gal",
        common: "S\u00e9n\u00e9gal",
      },
      hrv: { official: "Republika Senegal", common: "Senegal" },
      hun: {
        official: "Szeneg\u00e1li K\u00f6zt\u00e1rsas\u00e1g",
        common: "Szeneg\u00e1l",
      },
      ita: { official: "Repubblica del Senegal", common: "Senegal" },
      jpn: {
        official: "\u30bb\u30cd\u30ac\u30eb\u5171\u548c\u56fd",
        common: "\u30bb\u30cd\u30ac\u30eb",
      },
      kor: {
        official: "\uc138\ub124\uac08 \uacf5\ud654\uad6d",
        common: "\uc138\ub124\uac08",
      },
      nld: { official: "Republiek Senegal", common: "Senegal" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0633\u0646\u06af\u0627\u0644",
        common: "\u0633\u0646\u06af\u0627\u0644",
      },
      pol: { official: "Senegal", common: "Senegal" },
      por: { official: "Rep\u00fablica do Senegal", common: "Senegal" },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0421\u0435\u043d\u0435\u0433\u0430\u043b",
        common: "\u0421\u0435\u043d\u0435\u0433\u0430\u043b",
      },
      slk: { official: "Senegalsk\u00e1 republika", common: "Senegal" },
      spa: { official: "Rep\u00fablica de Senegal", common: "Senegal" },
      swe: { official: "Republiken Senegal", common: "Senegal" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0633\u06cc\u0646\u06cc\u06af\u0627\u0644",
        common: "\u0633\u06cc\u0646\u06cc\u06af\u0627\u0644",
      },
      zho: {
        official: "\u585e\u5185\u52a0\u5c14\u5171\u548c\u56fd",
        common: "\u585e\u5185\u52a0\u5c14",
      },
    },
    latlng: [14, -14],
    landlocked: false,
    borders: ["GMB", "GIN", "GNB", "MLI", "MRT"],
    area: 196722,
    flag: "\ud83c\uddf8\ud83c\uddf3",
    demonyms: {
      eng: { f: "Senegalese", m: "Senegalese" },
      fra: { f: "S\u00e9n\u00e9galaise", m: "S\u00e9n\u00e9galais" },
    },
    callingCodes: ["+221"],
  },
  {
    name: {
      common: "Singapore",
      official: "Republic of Singapore",
      native: {
        zho: {
          official: "\u65b0\u52a0\u5761\u5171\u548c\u56fd",
          common: "\u65b0\u52a0\u5761",
        },
        eng: { official: "Republic of Singapore", common: "Singapore" },
        msa: { official: "Republik Singapura", common: "Singapura" },
        tam: {
          official:
            "\u0b9a\u0bbf\u0b99\u0bcd\u0b95\u0baa\u0bcd\u0baa\u0bc2\u0bb0\u0bcd \u0b95\u0bc1\u0b9f\u0bbf\u0baf\u0bb0\u0b9a\u0bc1",
          common:
            "\u0b9a\u0bbf\u0b99\u0bcd\u0b95\u0baa\u0bcd\u0baa\u0bc2\u0bb0\u0bcd",
        },
      },
    },
    tld: [
      ".sg",
      ".\u65b0\u52a0\u5761",
      ".\u0b9a\u0bbf\u0b99\u0bcd\u0b95\u0baa\u0bcd\u0baa\u0bc2\u0bb0\u0bcd",
    ],
    cca2: "SG",
    ccn3: "702",
    cca3: "SGP",
    cioc: "SIN",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { SGD: { name: "Singapore dollar", symbol: "$" } },
    idd: { root: "+6", suffixes: ["5"] },
    capital: ["Singapore"],
    altSpellings: [
      "SG",
      "Singapura",
      "Republik Singapura",
      "\u65b0\u52a0\u5761\u5171\u548c\u56fd",
    ],
    region: "Asia",
    subregion: "South-Eastern Asia",
    languages: { zho: "Chinese", eng: "English", msa: "Malay", tam: "Tamil" },
    translations: {
      ces: { official: "Singapursk\u00e1 republika", common: "Singapur" },
      deu: { official: "Republik Singapur", common: "Singapur" },
      est: { official: "Singapuri Vabariik", common: "Singapur" },
      fin: { official: "Singaporen tasavalta", common: "Singapore" },
      fra: { official: "R\u00e9publique de Singapour", common: "Singapour" },
      hrv: { official: "Republika Singapur", common: "Singapur" },
      hun: {
        official: "Szingap\u00fari K\u00f6zt\u00e1rsas\u00e1g",
        common: "Szingap\u00far",
      },
      ita: { official: "Repubblica di Singapore", common: "Singapore" },
      jpn: {
        official: "\u30b7\u30f3\u30ac\u30dd\u30fc\u30eb\u5171\u548c\u56fd",
        common: "\u30b7\u30f3\u30ac\u30dd\u30fc\u30eb",
      },
      kor: {
        official: "\uc2f1\uac00\ud3ec\ub974 \uacf5\ud654\uad6d",
        common: "\uc2f1\uac00\ud3ec\ub974",
      },
      nld: { official: "Republiek Singapore", common: "Singapore" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0633\u0646\u06af\u0627\u067e\u0648\u0631",
        common: "\u0633\u0646\u06af\u0627\u067e\u0648\u0631",
      },
      pol: { official: "Republika Singapuru", common: "Singapur" },
      por: { official: "Rep\u00fablica de Singapura", common: "Singapura" },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0421\u0438\u043d\u0433\u0430\u043f\u0443\u0440",
        common: "\u0421\u0438\u043d\u0433\u0430\u043f\u0443\u0440",
      },
      slk: { official: "Singapursk\u00e1 republika", common: "Singapur" },
      spa: { official: "Rep\u00fablica de Singapur", common: "Singapur" },
      swe: { official: "Republiken Singapore", common: "Singapore" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0633\u0646\u06af\u0627\u067e\u0648\u0631",
        common: "\u0633\u0646\u06af\u0627\u067e\u0648\u0631",
      },
    },
    latlng: [1.36666666, 103.8],
    landlocked: false,
    borders: [],
    area: 710,
    flag: "\ud83c\uddf8\ud83c\uddec",
    demonyms: {
      eng: { f: "Singaporean", m: "Singaporean" },
      fra: { f: "Singapourienne", m: "Singapourien" },
    },
    callingCodes: ["+65"],
  },
  {
    name: {
      common: "South Georgia",
      official: "South Georgia and the South Sandwich Islands",
      native: {
        eng: {
          official: "South Georgia and the South Sandwich Islands",
          common: "South Georgia",
        },
      },
    },
    tld: [".gs"],
    cca2: "GS",
    ccn3: "239",
    cca3: "SGS",
    cioc: "",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: { SHP: { name: "Saint Helena pound", symbol: "\u00a3" } },
    idd: { root: "+5", suffixes: ["00"] },
    capital: ["King Edward Point"],
    altSpellings: ["GS", "South Georgia and the South Sandwich Islands"],
    region: "Antarctic",
    subregion: "",
    languages: { eng: "English" },
    translations: {
      ces: {
        official:
          "Ji\u017en\u00ed Georgie a Ji\u017en\u00ed Sandwichovy ostrovy",
        common: "Ji\u017en\u00ed Georgie a Ji\u017en\u00ed Sandwichovy ostrovy",
      },
      deu: {
        official: "S\u00fcdgeorgien und die S\u00fcdlichen Sandwichinseln",
        common: "S\u00fcdgeorgien und die S\u00fcdlichen Sandwichinseln",
      },
      est: {
        official: "L\u00f5una-Georgia ja L\u00f5una-Sandwichi saared",
        common: "L\u00f5una-Georgia ja L\u00f5una-Sandwichi saared",
      },
      fin: {
        official: "Etel\u00e4-Georgia ja Etel\u00e4iset Sandwichsaaret",
        common: "Etel\u00e4-Georgia ja Etel\u00e4iset Sandwichsaaret",
      },
      fra: {
        official: "G\u00e9orgie du Sud et les \u00eeles Sandwich du Sud",
        common: "G\u00e9orgie du Sud-et-les \u00celes Sandwich du Sud",
      },
      hrv: {
        official:
          "Ju\u017ena D\u017eord\u017eija i Otoci Ju\u017eni Sendvi\u010d",
        common: "Ju\u017ena Georgija i oto\u010dje Ju\u017eni Sandwich",
      },
      hun: {
        official: "D\u00e9li-Georgia \u00e9s D\u00e9li-Sandwich-szigetek",
        common: "D\u00e9li-Georgia \u00e9s D\u00e9li-Sandwich-szigetek",
      },
      ita: {
        official: "Georgia del Sud e isole Sandwich del Sud",
        common: "Georgia del Sud e Isole Sandwich Meridionali",
      },
      jpn: {
        official:
          "\u30b5\u30a6\u30b9\u30b8\u30e7\u30fc\u30b8\u30a2\u00b7\u30b5\u30a6\u30b9\u30b5\u30f3\u30c9\u30a6\u30a3\u30c3\u30c1\u8af8\u5cf6",
        common:
          "\u30b5\u30a6\u30b9\u30b8\u30e7\u30fc\u30b8\u30a2\u30fb\u30b5\u30a6\u30b9\u30b5\u30f3\u30c9\u30a6\u30a3\u30c3\u30c1\u8af8\u5cf6",
      },
      kor: { official: "\uc870\uc9c0\uc544", common: "\uc870\uc9c0\uc544" },
      nld: {
        official: "Zuid-Georgi\u00eb en de Zuidelijke Sandwich-eilanden",
        common: "Zuid-Georgia en Zuidelijke Sandwicheilanden",
      },
      per: {
        official:
          "\u062c\u0632\u0627\u06cc\u0631 \u062c\u0648\u0631\u062c\u06cc\u0627\u06cc \u062c\u0646\u0648\u0628\u06cc \u0648 \u0633\u0627\u0646\u062f\u0648\u06cc\u0686 \u062c\u0646\u0648\u0628\u06cc",
        common:
          "\u062c\u0632\u0627\u06cc\u0631 \u062c\u0648\u0631\u062c\u06cc\u0627\u06cc \u062c\u0646\u0648\u0628\u06cc \u0648 \u0633\u0627\u0646\u062f\u0648\u06cc\u0686 \u062c\u0646\u0648\u0628\u06cc",
      },
      pol: {
        official: "Georgia Po\u0142udniowa i Sandwich Po\u0142udniowy",
        common: "Georgia Po\u0142udniowa i Sandwich Po\u0142udniowy",
      },
      por: {
        official: "Ge\u00f3rgia do Sul e Sandwich do Sul",
        common: "Ilhas Ge\u00f3rgia do Sul e Sandwich do Sul",
      },
      rus: {
        official:
          "\u042e\u0436\u043d\u0430\u044f \u0413\u0435\u043e\u0440\u0433\u0438\u044f \u0438 \u042e\u0436\u043d\u044b\u0435 \u0421\u0430\u043d\u0434\u0432\u0438\u0447\u0435\u0432\u044b \u043e\u0441\u0442\u0440\u043e\u0432\u0430",
        common:
          "\u042e\u0436\u043d\u0430\u044f \u0413\u0435\u043e\u0440\u0433\u0438\u044f \u0438 \u042e\u0436\u043d\u044b\u0435 \u0421\u0430\u043d\u0434\u0432\u0438\u0447\u0435\u0432\u044b \u043e\u0441\u0442\u0440\u043e\u0432\u0430",
      },
      slk: {
        official:
          "Ju\u017en\u00e1 Georgia a Ju\u017en\u00e9 Sandwichove ostrovy",
        common: "Ju\u017en\u00e1 Georgia a Ju\u017en\u00e9 Sandwichove ostrovy",
      },
      spa: {
        official: "Georgia del Sur y las Islas Sandwich del Sur",
        common: "Islas Georgias del Sur y Sandwich del Sur",
      },
      swe: { official: "Sydgeorgien", common: "Sydgeorgien" },
      urd: {
        official:
          "\u062c\u0646\u0648\u0628\u06cc \u062c\u0627\u0631\u062c\u06cc\u0627 \u0648 \u062c\u0632\u0627\u0626\u0631 \u062c\u0646\u0648\u0628\u06cc \u0633\u06cc\u0646\u0688\u0648\u0686",
        common:
          "\u062c\u0646\u0648\u0628\u06cc \u062c\u0627\u0631\u062c\u06cc\u0627",
      },
      zho: {
        official:
          "\u5357\u4e54\u6cbb\u4e9a\u5c9b\u548c\u5357\u6851\u5a01\u5947\u7fa4\u5c9b",
        common: "\u5357\u4e54\u6cbb\u4e9a",
      },
    },
    latlng: [-54.5, -37],
    landlocked: false,
    borders: [],
    area: 3903,
    flag: "\ud83c\uddec\ud83c\uddf8",
    demonyms: {
      eng: {
        f: "South Georgian South Sandwich Islander",
        m: "South Georgian South Sandwich Islander",
      },
      fra: { f: "", m: "" },
    },
    callingCodes: ["+500"],
  },
  {
    name: {
      common: "Svalbard and Jan Mayen",
      official: "Svalbard og Jan Mayen",
      native: {
        nor: {
          official: "Svalbard og Jan Mayen",
          common: "Svalbard og Jan Mayen",
        },
      },
    },
    tld: [".sj"],
    cca2: "SJ",
    ccn3: "744",
    cca3: "SJM",
    cioc: "",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: { NOK: { name: "krone", symbol: "kr" } },
    idd: { root: "+4", suffixes: ["779"] },
    capital: ["Longyearbyen"],
    altSpellings: ["SJ", "Svalbard and Jan Mayen Islands"],
    region: "Europe",
    subregion: "Northern Europe",
    languages: { nor: "Norwegian" },
    translations: {
      ces: {
        official: "\u0160picberky a Jan Mayen",
        common: "\u0160picberky a Jan Mayen",
      },
      deu: {
        official: "Spitzbergen und Jan Mayen",
        common: "Spitzbergen und Jan Mayen",
      },
      est: { official: "Svalbard", common: "Svalbard" },
      fin: { official: "Huippuvuoret", common: "Huippuvuoret" },
      fra: { official: "Jan Mayen Svalbard", common: "Svalbard et Jan Mayen" },
      hrv: {
        official: "Svalbard og Jan Mayen",
        common: "Svalbard i Jan Mayen",
      },
      hun: {
        official: "Svalbard \u00e9s Jan Mayen",
        common: "Svalbard \u00e9s Jan Mayen",
      },
      ita: {
        official: "Svalbard og Jan Mayen",
        common: "Svalbard e Jan Mayen",
      },
      jpn: {
        official:
          "\u30b9\u30d0\u30fc\u30eb\u30d0\u30eb\u8af8\u5cf6OG\u30e4\u30f3\u30de\u30a4\u30a8\u30f3",
        common:
          "\u30b9\u30f4\u30a1\u30fc\u30eb\u30d0\u30eb\u8af8\u5cf6\u304a\u3088\u3073\u30e4\u30f3\u30de\u30a4\u30a8\u30f3\u5cf6",
      },
      kor: {
        official: "\uc2a4\ubc1c\ubc14\ub974 \uc580\ub9c8\uc60c \uc81c\ub3c4",
        common: "\uc2a4\ubc1c\ubc14\ub974 \uc580\ub9c8\uc60c \uc81c\ub3c4",
      },
      nld: {
        official: "Svalbard og Jan Mayen",
        common: "Svalbard en Jan Mayen",
      },
      per: {
        official:
          "\u0633\u0648\u0627\u0644\u0628\u0627\u0631\u062f \u0648 \u06cc\u0627\u0646 \u0645\u0627\u06cc\u0646",
        common:
          "\u0633\u0648\u0627\u0644\u0628\u0627\u0631\u062f \u0648 \u06cc\u0627\u0646 \u0645\u0627\u06cc\u0646",
      },
      pol: { official: "Svalbard i Jan Mayen", common: "Svalbard i Jan Mayen" },
      por: {
        official: "Svalbard og Jan Mayen",
        common: "Ilhas Svalbard e Jan Mayen",
      },
      rus: {
        official:
          "\u0421\u0432\u0430\u043b\u044c\u0431\u0430\u0440\u0434\u0430 \u043e\u0433 \u042f\u043d-\u041c\u0430\u0439\u0435\u043d",
        common:
          "\u0428\u043f\u0438\u0446\u0431\u0435\u0440\u0433\u0435\u043d \u0438 \u042f\u043d-\u041c\u0430\u0439\u0435\u043d",
      },
      slk: { official: "Svalbard a Jan Mayen", common: "Svalbard a Jan Mayen" },
      spa: {
        official: "Svalbard og Jan Mayen",
        common: "Islas Svalbard y Jan Mayen",
      },
      swe: {
        official: "Svalbard och Jan Mayen",
        common: "Svalbard och Jan Mayen",
      },
      urd: {
        official:
          "\u0633\u0648\u0627\u0644\u0628\u0627\u0631\u0688 \u0627\u0648\u0631 \u062c\u0627\u0646 \u0645\u06cc\u0626\u0646",
        common:
          "\u0633\u0648\u0627\u0644\u0628\u0627\u0631\u0688 \u0627\u0648\u0631 \u062c\u0627\u0646 \u0645\u06cc\u0626\u0646",
      },
      zho: {
        official: "\u65af\u74e6\u5c14\u5df4\u7279",
        common: "\u65af\u74e6\u5c14\u5df4\u7279",
      },
    },
    latlng: [78, 20],
    landlocked: false,
    borders: [],
    area: -1,
    flag: "\ud83c\uddf8\ud83c\uddef",
    demonyms: {
      eng: { f: "Norwegian", m: "Norwegian" },
      fra: { f: "", m: "" },
    },
    callingCodes: ["+4779"],
  },
  {
    name: {
      common: "Solomon Islands",
      official: "Solomon Islands",
      native: {
        eng: { official: "Solomon Islands", common: "Solomon Islands" },
      },
    },
    tld: [".sb"],
    cca2: "SB",
    ccn3: "090",
    cca3: "SLB",
    cioc: "SOL",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { SBD: { name: "Solomon Islands dollar", symbol: "$" } },
    idd: { root: "+6", suffixes: ["77"] },
    capital: ["Honiara"],
    altSpellings: ["SB"],
    region: "Oceania",
    subregion: "Melanesia",
    languages: { eng: "English" },
    translations: {
      ces: {
        official: "\u0160alamounovy ostrovy",
        common: "\u0160alamounovy ostrovy",
      },
      deu: { official: "Salomonen", common: "Salomonen" },
      est: { official: "Saalomoni Saared", common: "Saalomoni Saared" },
      fin: { official: "Salomonsaaret", common: "Salomonsaaret" },
      fra: { official: "\u00celes Salomon", common: "\u00celes Salomon" },
      hrv: { official: "Solomonski Otoci", common: "Solomonski Otoci" },
      hun: { official: "Salamon-szigetek", common: "Salamon-szigetek" },
      ita: { official: "Isole Salomone", common: "Isole Salomone" },
      jpn: {
        official: "\u30bd\u30ed\u30e2\u30f3\u8af8\u5cf6",
        common: "\u30bd\u30ed\u30e2\u30f3\u8af8\u5cf6",
      },
      kor: {
        official: "\uc194\ub85c\ubaac \uc81c\ub3c4",
        common: "\uc194\ub85c\ubaac \uc81c\ub3c4",
      },
      nld: { official: "Solomon eilanden", common: "Salomonseilanden" },
      per: {
        official:
          "\u062c\u0632\u0627\u06cc\u0631 \u0633\u0644\u06cc\u0645\u0627\u0646",
        common:
          "\u062c\u0632\u0627\u06cc\u0631 \u0633\u0644\u06cc\u0645\u0627\u0646",
      },
      pol: { official: "Wyspy Salomona", common: "Wyspy Salomona" },
      por: { official: "Ilhas Salom\u00e3o", common: "Ilhas Salom\u00e3o" },
      rus: {
        official:
          "\u0421\u043e\u043b\u043e\u043c\u043e\u043d\u043e\u0432\u044b \u043e\u0441\u0442\u0440\u043e\u0432\u0430",
        common:
          "\u0421\u043e\u043b\u043e\u043c\u043e\u043d\u043e\u0432\u044b \u041e\u0441\u0442\u0440\u043e\u0432\u0430",
      },
      slk: { official: "Salomonove ostrovy", common: "Salomonove ostrovy" },
      spa: { official: "islas Salom\u00f3n", common: "Islas Salom\u00f3n" },
      swe: { official: "Salomon\u00f6arna", common: "Salomon\u00f6arna" },
      urd: {
        official:
          "\u062c\u0632\u0627\u0626\u0631 \u0633\u0644\u06cc\u0645\u0627\u0646",
        common:
          "\u062c\u0632\u0627\u0626\u0631 \u0633\u0644\u06cc\u0645\u0627\u0646",
      },
      zho: {
        official: "\u6240\u7f57\u95e8\u7fa4\u5c9b",
        common: "\u6240\u7f57\u95e8\u7fa4\u5c9b",
      },
    },
    latlng: [-8, 159],
    landlocked: false,
    borders: [],
    area: 28896,
    flag: "\ud83c\uddf8\ud83c\udde7",
    demonyms: {
      eng: { f: "Solomon Islander", m: "Solomon Islander" },
      fra: { f: "Salomonienne", m: "Salomonien" },
    },
    callingCodes: ["+677"],
  },
  {
    name: {
      common: "Sierra Leone",
      official: "Republic of Sierra Leone",
      native: {
        eng: { official: "Republic of Sierra Leone", common: "Sierra Leone" },
      },
    },
    tld: [".sl"],
    cca2: "SL",
    ccn3: "694",
    cca3: "SLE",
    cioc: "SLE",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { SLL: { name: "Sierra Leonean leone", symbol: "Le" } },
    idd: { root: "+2", suffixes: ["32"] },
    capital: ["Freetown"],
    altSpellings: ["SL", "Republic of Sierra Leone"],
    region: "Africa",
    subregion: "Western Africa",
    languages: { eng: "English" },
    translations: {
      ces: { official: "Republika Sierra Leone", common: "Sierra Leone" },
      deu: { official: "Republik Sierra Leone", common: "Sierra Leone" },
      est: { official: "Sierra Leone Vabariik", common: "Sierra Leone" },
      fin: { official: "Sierra Leonen tasavalta", common: "Sierra Leone" },
      fra: {
        official: "R\u00e9publique de Sierra Leone",
        common: "Sierra Leone",
      },
      hrv: { official: "Republika Sijera Leone", common: "Sijera Leone" },
      hun: {
        official: "Sierra Leone K\u00f6zt\u00e1rsas\u00e1g",
        common: "Sierra Leone",
      },
      ita: {
        official: "Repubblica della Sierra Leone",
        common: "Sierra Leone",
      },
      jpn: {
        official: "\u30b7\u30a8\u30e9\u30ec\u30aa\u30cd\u5171\u548c\u56fd",
        common: "\u30b7\u30a8\u30e9\u30ec\u30aa\u30cd",
      },
      kor: {
        official: "\uc2dc\uc5d0\ub77c\ub9ac\uc628 \uacf5\ud654\uad6d",
        common: "\uc2dc\uc5d0\ub77c\ub9ac\uc628",
      },
      nld: { official: "Republiek Sierra Leone", common: "Sierra Leone" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0633\u06cc\u0631\u0627\u0644\u0626\u0648\u0646",
        common: "\u0633\u06cc\u0631\u0627\u0644\u0626\u0648\u0646",
      },
      pol: { official: "Sierra Leone", common: "Sierra Leone" },
      por: { official: "Rep\u00fablica da Serra Leoa", common: "Serra Leoa" },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0421\u044c\u0435\u0440\u0440\u0430-\u041b\u0435\u043e\u043d\u0435",
        common:
          "\u0421\u044c\u0435\u0440\u0440\u0430-\u041b\u0435\u043e\u043d\u0435",
      },
      slk: { official: "Sierraleonsk\u00e1 republika", common: "Sierra Leone" },
      spa: {
        official: "Rep\u00fablica de Sierra Leona",
        common: "Sierra Leone",
      },
      swe: { official: "Republiken Sierra Leone", common: "Sierra Leone" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0633\u06cc\u0631\u0627\u0644\u06cc\u0648\u0646",
        common: "\u0633\u06cc\u0631\u0627\u0644\u06cc\u0648\u0646",
      },
      zho: {
        official: "\u585e\u62c9\u5229\u6602\u5171\u548c\u56fd",
        common: "\u585e\u62c9\u5229\u6602",
      },
    },
    latlng: [8.5, -11.5],
    landlocked: false,
    borders: ["GIN", "LBR"],
    area: 71740,
    flag: "\ud83c\uddf8\ud83c\uddf1",
    demonyms: {
      eng: { f: "Sierra Leonean", m: "Sierra Leonean" },
      fra: { f: "Sierra-leonaise", m: "Sierra-leonais" },
    },
    callingCodes: ["+232"],
  },
  {
    name: {
      common: "El Salvador",
      official: "Republic of El Salvador",
      native: {
        spa: {
          official: "Rep\u00fablica de El Salvador",
          common: "El Salvador",
        },
      },
    },
    tld: [".sv"],
    cca2: "SV",
    ccn3: "222",
    cca3: "SLV",
    cioc: "ESA",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { USD: { name: "United States dollar", symbol: "$" } },
    idd: { root: "+5", suffixes: ["03"] },
    capital: ["San Salvador"],
    altSpellings: [
      "SV",
      "Republic of El Salvador",
      "Rep\u00fablica de El Salvador",
    ],
    region: "Americas",
    subregion: "Central America",
    languages: { spa: "Spanish" },
    translations: {
      ces: { official: "Salvadorsk\u00e1 republika", common: "Salvador" },
      cym: { official: "Gweriniaeth El Salfador", common: "El Salfador" },
      deu: { official: "Republik El Salvador", common: "El Salvador" },
      est: { official: "El Salvadori Vabariik", common: "El Salvador" },
      fin: { official: "El Salvadorin tasavalta", common: "El Salvador" },
      fra: { official: "R\u00e9publique du Salvador", common: "Salvador" },
      hrv: { official: "Republika El Salvador", common: "Salvador" },
      hun: {
        official: "Salvadori K\u00f6zt\u00e1rsas\u00e1g",
        common: "Salvador",
      },
      ita: { official: "Repubblica di El Salvador", common: "El Salvador" },
      jpn: {
        official:
          "\u30a8\u30eb\u30b5\u30eb\u30d0\u30c9\u30eb\u5171\u548c\u56fd",
        common: "\u30a8\u30eb\u30b5\u30eb\u30d0\u30c9\u30eb",
      },
      kor: {
        official: "\uc5d8\uc0b4\ubc14\ub3c4\ub974 \uacf5\ud654\uad6d",
        common: "\uc5d8\uc0b4\ubc14\ub3c4\ub974",
      },
      nld: { official: "Republiek El Salvador", common: "El Salvador" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0627\u0644\u0633\u0627\u0644\u0648\u0627\u062f\u0648\u0631",
        common: "\u0627\u0644\u0633\u0627\u0644\u0648\u0627\u062f\u0648\u0631",
      },
      pol: { official: "Republika Salwadoru", common: "Salwador" },
      por: { official: "Rep\u00fablica de El Salvador", common: "El Salvador" },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u042d\u043b\u044c-\u0421\u0430\u043b\u044c\u0432\u0430\u0434\u043e\u0440",
        common: "\u0421\u0430\u043b\u044c\u0432\u0430\u0434\u043e\u0440",
      },
      slk: {
        official: "Salv\u00e1dorsk\u00e1 republika",
        common: "Salv\u00e1dor",
      },
      spa: { official: "Rep\u00fablica de El Salvador", common: "El Salvador" },
      swe: { official: "Republiken El Salvador", common: "El Salvador" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0627\u06cc\u0644 \u0633\u06cc\u0644\u0648\u0627\u0688\u0648\u0631",
        common:
          "\u0627\u06cc\u0644 \u0633\u06cc\u0644\u0648\u0627\u0688\u0648\u0631",
      },
      zho: {
        official: "\u8428\u5c14\u74e6\u591a\u5171\u548c\u56fd",
        common: "\u8428\u5c14\u74e6\u591a",
      },
    },
    latlng: [13.83333333, -88.91666666],
    landlocked: false,
    borders: ["GTM", "HND"],
    area: 21041,
    flag: "\ud83c\uddf8\ud83c\uddfb",
    demonyms: {
      eng: { f: "Salvadoran", m: "Salvadoran" },
      fra: { f: "Salvadorienne", m: "Salvadorien" },
    },
    callingCodes: ["+503"],
  },
  {
    name: {
      common: "San Marino",
      official: "Most Serene Republic of San Marino",
      native: {
        ita: {
          official: "Serenissima Repubblica di San Marino",
          common: "San Marino",
        },
      },
    },
    tld: [".sm"],
    cca2: "SM",
    ccn3: "674",
    cca3: "SMR",
    cioc: "SMR",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { EUR: { name: "Euro", symbol: "\u20ac" } },
    idd: { root: "+3", suffixes: ["78"] },
    capital: ["City of San Marino"],
    altSpellings: ["SM", "Republic of San Marino", "Repubblica di San Marino"],
    region: "Europe",
    subregion: "Southern Europe",
    languages: { ita: "Italian" },
    translations: {
      ces: { official: "Republika San Marino", common: "San Marino" },
      deu: { official: "Republik San Marino", common: "San Marino" },
      est: { official: "San Marino Vabariik", common: "San Marino" },
      fin: {
        official: "San Marinon seesteinen tasavalta",
        common: "San Marino",
      },
      fra: {
        official: "R\u00e9publique de Saint-Marin",
        common: "Saint-Marin",
      },
      hrv: {
        official: "Ve\u0107ina Serene Republika San Marino",
        common: "San Marino",
      },
      hun: {
        official: "San Marino K\u00f6zt\u00e1rsas\u00e1g",
        common: "San Marino",
      },
      ita: {
        official: "Serenissima Repubblica di San Marino",
        common: "San Marino",
      },
      jpn: {
        official:
          "\u30b5\u30f3\u30de\u30ea\u30ce\u306e\u307b\u3068\u3093\u3069\u30bb\u30ea\u30fc\u30cc\u5171\u548c\u56fd",
        common: "\u30b5\u30f3\u30de\u30ea\u30ce",
      },
      kor: {
        official: "\uc0b0\ub9c8\ub9ac\ub178 \uacf5\ud654\uad6d",
        common: "\uc0b0\ub9c8\ub9ac\ub178",
      },
      nld: {
        official: "Meest Serene Republiek San Marino",
        common: "San Marino",
      },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0633\u0627\u0646 \u0645\u0627\u0631\u06cc\u0646\u0648",
        common: "\u0633\u0627\u0646 \u0645\u0627\u0631\u06cc\u0646\u0648",
      },
      pol: { official: "Republika San Marino", common: "San Marino" },
      por: {
        official: "Seren\u00edssima Rep\u00fablica de San Marino",
        common: "San Marino",
      },
      rus: {
        official:
          "\u0411\u043e\u043b\u044c\u0448\u0438\u043d\u0441\u0442\u0432\u043e Serene \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0421\u0430\u043d-\u041c\u0430\u0440\u0438\u043d\u043e",
        common: "\u0421\u0430\u043d-\u041c\u0430\u0440\u0438\u043d\u043e",
      },
      slk: {
        official: "Sanmar\u00ednska republika",
        common: "San Mar\u00edno",
      },
      spa: {
        official: "Seren\u00edsima Rep\u00fablica de San Marino",
        common: "San Marino",
      },
      swe: { official: "Republiken San Marino", common: "San Marino" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0633\u0627\u0646 \u0645\u0627\u0631\u06cc\u0646\u0648",
        common: "\u0633\u0627\u0646 \u0645\u0627\u0631\u06cc\u0646\u0648",
      },
      zho: {
        official: "\u5723\u9a6c\u529b\u8bfa\u5171\u548c\u56fd",
        common: "\u5723\u9a6c\u529b\u8bfa",
      },
    },
    latlng: [43.76666666, 12.41666666],
    landlocked: true,
    borders: ["ITA"],
    area: 61,
    flag: "\ud83c\uddf8\ud83c\uddf2",
    demonyms: {
      eng: { f: "Sammarinese", m: "Sammarinese" },
      fra: { f: "Saint-Marinaise", m: "Saint-Marinais" },
    },
    callingCodes: ["+378"],
  },
  {
    name: {
      common: "Somalia",
      official: "Federal Republic of Somalia",
      native: {
        ara: {
          official:
            "\u062c\u0645\u0647\u0648\u0631\u064a\u0629 \u0627\u0644\u0635\u0648\u0645\u0627\u0644\u200e\u200e",
          common: "\u0627\u0644\u0635\u0648\u0645\u0627\u0644\u200e\u200e",
        },
        som: {
          official: "Jamhuuriyadda Federaalka Soomaaliya",
          common: "Soomaaliya",
        },
      },
    },
    tld: [".so"],
    cca2: "SO",
    ccn3: "706",
    cca3: "SOM",
    cioc: "SOM",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { SOS: { name: "Somali shilling", symbol: "Sh" } },
    idd: { root: "+2", suffixes: ["52"] },
    capital: ["Mogadishu"],
    altSpellings: [
      "SO",
      "a\u1e63-\u1e62\u016bm\u0101l",
      "Federal Republic of Somalia",
      "Jamhuuriyadda Federaalka Soomaaliya",
      "Jumh\u016briyyat a\u1e63-\u1e62\u016bm\u0101l al-Fider\u0101liyya",
    ],
    region: "Africa",
    subregion: "Eastern Africa",
    languages: { ara: "Arabic", som: "Somali" },
    translations: {
      ces: {
        official: "Som\u00e1lsk\u00e1 demokratick\u00e1 republika",
        common: "Som\u00e1lsko",
      },
      deu: { official: "Bundesrepublik Somalia", common: "Somalia" },
      est: { official: "Somaalia Liitvabariik", common: "Somaalia" },
      fin: { official: "Somalian liittotasavalta", common: "Somalia" },
      fra: {
        official: "R\u00e9publique f\u00e9d\u00e9rale de Somalie",
        common: "Somalie",
      },
      hrv: { official: "Savezna Republika Somaliji", common: "Somalija" },
      hun: {
        official:
          "Szom\u00e1li Sz\u00f6vets\u00e9gi K\u00f6zt\u00e1rsas\u00e1g",
        common: "Szom\u00e1lia",
      },
      ita: { official: "Repubblica federale di Somalia", common: "Somalia" },
      jpn: {
        official: "\u30bd\u30de\u30ea\u30a2\u9023\u90a6\u5171\u548c\u56fd",
        common: "\u30bd\u30de\u30ea\u30a2",
      },
      kor: {
        official: " \uc18c\ub9d0\ub9ac\uc544 \uc5f0\ubc29 \uacf5\ud654\uad6d",
        common: "\uc18c\ub9d0\ub9ac\uc544",
      },
      nld: {
        official: "Federale Republiek Somali\u00eb",
        common: "Somali\u00eb",
      },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0641\u062f\u0631\u0627\u0644 \u0633\u0648\u0645\u0627\u0644\u06cc",
        common: "\u0633\u0648\u0645\u0627\u0644\u06cc",
      },
      pol: { official: "Federalna Republika Somalii", common: "Somalia" },
      por: {
        official: "Rep\u00fablica Federal da Som\u00e1lia",
        common: "Som\u00e1lia",
      },
      rus: {
        official:
          "\u0424\u0435\u0434\u0435\u0440\u0430\u0442\u0438\u0432\u043d\u0430\u044f \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0421\u043e\u043c\u0430\u043b\u0438",
        common: "\u0421\u043e\u043c\u0430\u043b\u0438",
      },
      slk: {
        official: "Som\u00e1lska federat\u00edvna republika",
        common: "Som\u00e1lsko",
      },
      spa: { official: "Rep\u00fablica Federal de Somalia", common: "Somalia" },
      swe: { official: "F\u00f6rbundsrepubliken Somalia", common: "Somalia" },
      urd: {
        official:
          "\u0648\u0641\u0627\u0642\u06cc \u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0635\u0648\u0645\u0627\u0644\u06cc\u06c1",
        common: "\u0635\u0648\u0645\u0627\u0644\u06cc\u06c1",
      },
      zho: {
        official: "\u7d22\u9a6c\u91cc\u5171\u548c\u56fd",
        common: "\u7d22\u9a6c\u91cc",
      },
    },
    latlng: [10, 49],
    landlocked: false,
    borders: ["DJI", "ETH", "KEN"],
    area: 637657,
    flag: "\ud83c\uddf8\ud83c\uddf4",
    demonyms: {
      eng: { f: "Somali", m: "Somali" },
      fra: { f: "Somalienne", m: "Somalien" },
    },
    callingCodes: ["+252"],
  },
  {
    name: {
      common: "Saint Pierre and Miquelon",
      official: "Saint Pierre and Miquelon",
      native: {
        fra: {
          official:
            "Collectivit\u00e9 territoriale de Saint-Pierre-et-Miquelon",
          common: "Saint-Pierre-et-Miquelon",
        },
      },
    },
    tld: [".pm"],
    cca2: "PM",
    ccn3: "666",
    cca3: "SPM",
    cioc: "",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: { EUR: { name: "Euro", symbol: "\u20ac" } },
    idd: { root: "+5", suffixes: ["08"] },
    capital: ["Saint-Pierre"],
    altSpellings: [
      "PM",
      "Collectivit\u00e9 territoriale de Saint-Pierre-et-Miquelon",
    ],
    region: "Americas",
    subregion: "North America",
    languages: { fra: "French" },
    translations: {
      ces: {
        official: "Saint-Pierre a Miquelon",
        common: "Saint-Pierre a Miquelon",
      },
      deu: {
        official: "St. Pierre und Miquelon",
        common: "St. Pierre und Miquelon",
      },
      est: {
        official: "Saint-Pierre\u2019i ja Miqueloni territoriaal\u00fchendus",
        common: "Saint-Pierre ja Miquelon",
      },
      fin: {
        official: "Saint-Pierre ja Miquelon",
        common: "Saint-Pierre ja Miquelon",
      },
      fra: {
        official: "Saint-Pierre-et-Miquelon",
        common: "Saint-Pierre-et-Miquelon",
      },
      hrv: {
        official: "Saint Pierre i Miquelon",
        common: "Sveti Petar i Mikelon",
      },
      hun: {
        official: "Saint-Pierre \u00e9s Miquelon",
        common: "Saint-Pierre \u00e9s Miquelon",
      },
      ita: {
        official: "Saint Pierre e Miquelon",
        common: "Saint-Pierre e Miquelon",
      },
      jpn: {
        official:
          "\u30b5\u30f3\u30d4\u30a8\u30fc\u30eb\u5cf6\u00b7\u30df\u30af\u30ed\u30f3\u5cf6",
        common:
          "\u30b5\u30f3\u30d4\u30a8\u30fc\u30eb\u5cf6\u30fb\u30df\u30af\u30ed\u30f3\u5cf6",
      },
      kor: {
        official: "\uc0dd\ud53c\uc5d0\ub974 \ubbf8\ud074\ub871",
        common: "\uc0dd\ud53c\uc5d0\ub974 \ubbf8\ud074\ub871",
      },
      nld: {
        official: "Saint-Pierre en Miquelon",
        common: "Saint Pierre en Miquelon",
      },
      per: {
        official:
          "\u0633\u0646-\u067e\u06cc\u0631-\u0627-\u0645\u06cc\u06a9\u0644\u0648\u0646",
        common:
          "\u0633\u0646-\u067e\u06cc\u0650\u0631 \u0648 \u0645\u06cc\u06a9\u0644\u064f\u0646",
      },
      pol: {
        official: "Saint-Pierre i Miquelon",
        common: "Saint-Pierre i Miquelon",
      },
      por: {
        official: "Saint Pierre e Miquelon",
        common: "Saint-Pierre e Miquelon",
      },
      rus: {
        official:
          "\u0421\u0435\u043d-\u041f\u044c\u0435\u0440 \u0438 \u041c\u0438\u043a\u0435\u043b\u043e\u043d",
        common:
          "\u0421\u0435\u043d-\u041f\u044c\u0435\u0440 \u0438 \u041c\u0438\u043a\u0435\u043b\u043e\u043d",
      },
      slk: {
        official: "Ostrovy Saint Pierre a Miquelon",
        common: "Saint Pierre a Miquelon",
      },
      spa: {
        official: "San Pedro y Miquel\u00f3n",
        common: "San Pedro y Miquel\u00f3n",
      },
      swe: {
        official: "Saint-Pierre och Miquelon",
        common: "Saint-Pierre och Miquelon",
      },
      urd: {
        official:
          "\u0633\u06cc\u0646\u0679 \u067e\u06cc\u0626\u0631 \u0648 \u0645\u06cc\u06a9\u06cc\u0644\u0648\u0646",
        common:
          "\u0633\u06cc\u0646\u0679 \u067e\u06cc\u0626\u0631 \u0648 \u0645\u06cc\u06a9\u06cc\u0644\u0648\u0646",
      },
      zho: {
        official: "\u5723\u76ae\u57c3\u5c14\u548c\u5bc6\u514b\u9686",
        common: "\u5723\u76ae\u57c3\u5c14\u548c\u5bc6\u514b\u9686",
      },
    },
    latlng: [46.83333333, -56.33333333],
    landlocked: false,
    borders: [],
    area: 242,
    flag: "\ud83c\uddf5\ud83c\uddf2",
    demonyms: {
      eng: {
        f: "Saint-Pierrais, Miquelonnais",
        m: "Saint-Pierrais, Miquelonnais",
      },
      fra: {
        f: "Saint-Pierraise, Miquelonaise",
        m: "Saint-Pierrais, Miquelonais",
      },
    },
    callingCodes: ["+508"],
  },
  {
    name: {
      common: "Serbia",
      official: "Republic of Serbia",
      native: {
        srp: {
          official:
            "\u0420\u0435\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0421\u0440\u0431\u0438\u0458\u0430",
          common: "\u0421\u0440\u0431\u0438\u0458\u0430",
        },
      },
    },
    tld: [".rs", ".\u0441\u0440\u0431"],
    cca2: "RS",
    ccn3: "688",
    cca3: "SRB",
    cioc: "SRB",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: {
      RSD: { name: "Serbian dinar", symbol: "\u0434\u0438\u043d." },
    },
    idd: { root: "+3", suffixes: ["81"] },
    capital: ["Belgrade"],
    altSpellings: [
      "RS",
      "Srbija",
      "Republic of Serbia",
      "\u0420\u0435\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0421\u0440\u0431\u0438\u0458\u0430",
      "Republika Srbija",
    ],
    region: "Europe",
    subregion: "Southeast Europe",
    languages: { srp: "Serbian" },
    translations: {
      ces: { official: "Srbsk\u00e1 republika", common: "Srbsko" },
      deu: { official: "Republik Serbien", common: "Serbien" },
      est: { official: "Serbia Vabariik", common: "Serbia" },
      fin: { official: "Serbian tasavalta", common: "Serbia" },
      fra: { official: "R\u00e9publique de Serbie", common: "Serbie" },
      hrv: { official: "Republika Srbija", common: "Srbija" },
      hun: { official: "Szerb K\u00f6zt\u00e1rsas\u00e1g", common: "Szerbia" },
      ita: { official: "Repubblica di Serbia", common: "Serbia" },
      jpn: {
        official: "\u30bb\u30eb\u30d3\u30a2\u5171\u548c\u56fd",
        common: "\u30bb\u30eb\u30d3\u30a2",
      },
      kor: {
        official: "\uc138\ub974\ube44\uc544 \uacf5\ud654\uad6d",
        common: "\uc138\ub974\ube44\uc544",
      },
      nld: { official: "Republiek Servi\u00eb", common: "Servi\u00eb" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0635\u0631\u0628\u0633\u062a\u0627\u0646",
        common: "\u0635\u0631\u0628\u0633\u062a\u0627\u0646",
      },
      pol: { official: "Republika Serbii", common: "Serbia" },
      por: { official: "Rep\u00fablica da S\u00e9rvia", common: "S\u00e9rvia" },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0421\u0435\u0440\u0431\u0438\u044f",
        common: "\u0421\u0435\u0440\u0431\u0438\u044f",
      },
      slk: { official: "Srbsk\u00e1 republika", common: "Srbsko" },
      spa: { official: "Rep\u00fablica de Serbia", common: "Serbia" },
      swe: { official: "Republiken Serbien", common: "Serbien" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0633\u0631\u0628\u06cc\u0627",
        common: "\u0633\u0631\u0628\u06cc\u0627",
      },
      zho: {
        official: "\u585e\u5c14\u7ef4\u4e9a\u5171\u548c\u56fd",
        common: "\u585e\u5c14\u7ef4\u4e9a",
      },
    },
    latlng: [44, 21],
    landlocked: true,
    borders: ["BIH", "BGR", "HRV", "HUN", "UNK", "MKD", "MNE", "ROU"],
    area: 88361,
    flag: "\ud83c\uddf7\ud83c\uddf8",
    demonyms: {
      eng: { f: "Serbian", m: "Serbian" },
      fra: { f: "Serbe", m: "Serbe" },
    },
    callingCodes: ["+381"],
  },
  {
    name: {
      common: "South Sudan",
      official: "Republic of South Sudan",
      native: {
        eng: { official: "Republic of South Sudan", common: "South Sudan" },
      },
    },
    tld: [".ss"],
    cca2: "SS",
    ccn3: "728",
    cca3: "SSD",
    cioc: "",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { SSP: { name: "South Sudanese pound", symbol: "\u00a3" } },
    idd: { root: "+2", suffixes: ["11"] },
    capital: ["Juba"],
    altSpellings: ["SS"],
    region: "Africa",
    subregion: "Middle Africa",
    languages: { eng: "English" },
    translations: {
      ces: {
        official: "Jihos\u00fad\u00e1nsk\u00e1 republika",
        common: "Ji\u017en\u00ed S\u00fad\u00e1n",
      },
      deu: { official: "Republik S\u00fcdsudan", common: "S\u00fcdsudan" },
      est: {
        official: "L\u00f5una-Sudaani Vabariik",
        common: "L\u00f5una-Sudaan",
      },
      fin: {
        official: "Etel\u00e4-Sudanin tasavalta",
        common: "Etel\u00e4-Sudan",
      },
      fra: {
        official: "R\u00e9publique du Soudan du Sud",
        common: "Soudan du Sud",
      },
      hrv: {
        official: "Republika Ju\u017eni Sudan",
        common: "Ju\u017eni Sudan",
      },
      hun: {
        official: "D\u00e9l-szud\u00e1ni K\u00f6zt\u00e1rsas\u00e1g",
        common: "D\u00e9l-Szud\u00e1n",
      },
      ita: {
        official: "Repubblica del Sudan del Sud",
        common: "Sudan del sud",
      },
      jpn: {
        official: "\u5357\u30b9\u30fc\u30c0\u30f3\u5171\u548c\u56fd",
        common: "\u5357\u30b9\u30fc\u30c0\u30f3",
      },
      kor: {
        official: "\ub0a8\uc218\ub2e8 \uacf5\ud654\uad6d",
        common: "\ub0a8\uc218\ub2e8",
      },
      nld: { official: "Republiek Zuid-Soedan", common: "Zuid-Soedan" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0633\u0648\u062f\u0627\u0646 \u062c\u0646\u0648\u0628\u06cc",
        common: "\u0633\u0648\u062f\u0627\u0646 \u062c\u0646\u0648\u0628\u06cc",
      },
      pol: { official: "Republika Sudanu", common: "Sudan" },
      por: {
        official: "Rep\u00fablica do Sud\u00e3o do Sul",
        common: "Sud\u00e3o do Sul",
      },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u042e\u0436\u043d\u044b\u0439 \u0421\u0443\u0434\u0430\u043d",
        common: "\u042e\u0436\u043d\u044b\u0439 \u0421\u0443\u0434\u0430\u043d",
      },
      slk: {
        official: "Juhosud\u00e1nska republika",
        common: "Ju\u017en\u00fd Sud\u00e1n",
      },
      spa: {
        official: "Rep\u00fablica de Sud\u00e1n del Sur",
        common: "Sud\u00e1n del Sur",
      },
      swe: { official: "Republiken Sydsudan", common: "Sydsudan" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u062c\u0646\u0648\u0628\u06cc \u0633\u0648\u0688\u0627\u0646",
        common: "\u062c\u0646\u0648\u0628\u06cc \u0633\u0648\u0688\u0627\u0646",
      },
      zho: {
        official: "\u5357\u82cf\u4e39\u5171\u548c\u56fd",
        common: "\u5357\u82cf\u4e39",
      },
    },
    latlng: [7, 30],
    landlocked: true,
    borders: ["CAF", "COD", "ETH", "KEN", "SDN", "UGA"],
    area: 619745,
    flag: "\ud83c\uddf8\ud83c\uddf8",
    demonyms: {
      eng: { f: "South Sudanese", m: "South Sudanese" },
      fra: { f: "Sud-Soudanaise", m: "Sud-Soudanais" },
    },
    callingCodes: ["+211"],
  },
  {
    name: {
      common: "S\u00e3o Tom\u00e9 and Pr\u00edncipe",
      official: "Democratic Republic of S\u00e3o Tom\u00e9 and Pr\u00edncipe",
      native: {
        por: {
          official:
            "Rep\u00fablica Democr\u00e1tica do S\u00e3o Tom\u00e9 e Pr\u00edncipe",
          common: "S\u00e3o Tom\u00e9 e Pr\u00edncipe",
        },
      },
    },
    tld: [".st"],
    cca2: "ST",
    ccn3: "678",
    cca3: "STP",
    cioc: "STP",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: {
      STN: { name: "S\u00e3o Tom\u00e9 and Pr\u00edncipe dobra", symbol: "Db" },
    },
    idd: { root: "+2", suffixes: ["39"] },
    capital: ["S\u00e3o Tom\u00e9"],
    altSpellings: [
      "ST",
      "Democratic Republic of S\u00e3o Tom\u00e9 and Pr\u00edncipe",
      "Sao Tome and Principe",
      "Rep\u00fablica Democr\u00e1tica de S\u00e3o Tom\u00e9 e Pr\u00edncipe",
    ],
    region: "Africa",
    subregion: "Middle Africa",
    languages: { por: "Portuguese" },
    translations: {
      ces: {
        official:
          "Demokratick\u00e1 republika Svat\u00fd Tom\u00e1\u0161 a Princ\u016fv ostrov",
        common: "Svat\u00fd Tom\u00e1\u0161 a Princ\u016fv ostrov",
      },
      deu: {
        official: "Demokratische Republik S\u00e3o Tom\u00e9 und Pr\u00edncipe",
        common: "S\u00e3o Tom\u00e9 und Pr\u00edncipe",
      },
      est: {
        official: "S\u00e3o Tom\u00e9 ja Pr\u00edncipe Demokraatlik Vabariik",
        common: "S\u00e3o Tom\u00e9 ja Pr\u00edncipe",
      },
      fin: {
        official:
          "S\u00e3o Tom\u00e9 ja Pr\u00edncipen demokraattinen tasavalta",
        common: "S\u00e3o T\u00e9me ja Pr\u00edncipe",
      },
      fra: {
        official:
          "R\u00e9publique d\u00e9mocratique de S\u00e3o Tom\u00e9 et Pr\u00edncipe",
        common: "S\u00e3o Tom\u00e9 et Pr\u00edncipe",
      },
      hrv: {
        official: "Demokratska Republika S\u00e3o Tome i Principe",
        common: "Sveti Toma i Princip",
      },
      hun: {
        official:
          "S\u00e3o Tom\u00e9 \u00e9s Pr\u00edncipe Demokratikus K\u00f6zt\u00e1rsas\u00e1g",
        common: "S\u00e3o Tom\u00e9 \u00e9s Pr\u00edncipe",
      },
      ita: {
        official:
          "Repubblica democratica di S\u00e3o Tom\u00e9 e Pr\u00edncipe",
        common: "S\u00e3o Tom\u00e9 e Pr\u00edncipe",
      },
      jpn: {
        official:
          "\u30b5\u30f3\u30c8\u30e1\u00b7\u30d7\u30ea\u30f3\u30b7\u30da\u6c11\u4e3b\u5171\u548c\u56fd",
        common: "\u30b5\u30f3\u30c8\u30e1\u30fb\u30d7\u30ea\u30f3\u30b7\u30da",
      },
      kor: {
        official:
          "\uc0c1\ud22c\uba54 \ud504\ub9b0\uc2dc\ud398 \ubbfc\uc8fc \uacf5\ud654\uad6d",
        common: "\uc0c1\ud22c\uba54 \ud504\ub9b0\uc2dc\ud398",
      },
      nld: {
        official: "Democratische Republiek Sao Tom\u00e9 en Principe",
        common: "Sao Tom\u00e9 en Principe",
      },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u062f\u0645\u0648\u06a9\u0631\u0627\u062a\u06cc\u06a9 \u0633\u0627\u0626\u0648\u062a\u0648\u0645\u0647 \u0648 \u067e\u0631\u0646\u0633\u06cc\u067e",
        common:
          "\u0633\u0627\u0626\u0648\u062a\u0648\u0645\u0647 \u0648 \u067e\u0631\u0646\u0633\u06cc\u067e",
      },
      pol: {
        official:
          "Demokratyczna Republika Wysp \u015awi\u0119tego Tomasza i Ksi\u0105\u017c\u0119cej",
        common: "Wyspy \u015awi\u0119tego Tomasza i Ksi\u0105\u017c\u0119ca",
      },
      por: {
        official:
          "Rep\u00fablica Democr\u00e1tica de S\u00e3o Tom\u00e9 e Pr\u00edncipe",
        common: "S\u00e3o Tom\u00e9 e Pr\u00edncipe",
      },
      rus: {
        official:
          "\u0414\u0435\u043c\u043e\u043a\u0440\u0430\u0442\u0438\u0447\u0435\u0441\u043a\u0430\u044f \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0421\u0430\u043d-\u0422\u043e\u043c\u0435 \u0438 \u041f\u0440\u0438\u043d\u0441\u0438\u043f\u0438",
        common:
          "\u0421\u0430\u043d-\u0422\u043e\u043c\u0435 \u0438 \u041f\u0440\u0438\u043d\u0441\u0438\u043f\u0438",
      },
      slk: {
        official:
          "Demokratick\u00e1 republika Sv\u00e4t\u00e9ho Tom\u00e1\u0161a A princovho ostrova",
        common: "Sv\u00e4t\u00fd Tom\u00e1\u0161 a Princov ostrov",
      },
      spa: {
        official:
          "Rep\u00fablica Democr\u00e1tica de Santo Tom\u00e9 y Pr\u00edncipe",
        common: "Santo Tom\u00e9 y Pr\u00edncipe",
      },
      swe: {
        official:
          "Demokratiska republiken S\u00e3o Tom\u00e9 och Pr\u00edncipe",
        common: "S\u00e3o Tom\u00e9 och Pr\u00edncipe",
      },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0633\u0627\u0624 \u0679\u0648\u0645\u06d2 \u0648 \u067e\u0631\u0646\u0633\u067e\u06d2",
        common:
          "\u0633\u0627\u0624 \u0679\u0648\u0645\u06d2 \u0648 \u067e\u0631\u0646\u0633\u067e\u06d2",
      },
      zho: {
        official:
          "\u5723\u591a\u7f8e\u548c\u666e\u6797\u897f\u6bd4\u6c11\u4e3b\u5171\u548c\u56fd",
        common: "\u5723\u591a\u7f8e\u548c\u666e\u6797\u897f\u6bd4",
      },
    },
    latlng: [1, 7],
    landlocked: false,
    borders: [],
    area: 964,
    flag: "\ud83c\uddf8\ud83c\uddf9",
    demonyms: {
      eng: { f: "Sao Tomean", m: "Sao Tomean" },
      fra: { f: "Santom\u00e9enne", m: "Santom\u00e9en" },
    },
    callingCodes: ["+239"],
  },
  {
    name: {
      common: "Suriname",
      official: "Republic of Suriname",
      native: { nld: { official: "Republiek Suriname", common: "Suriname" } },
    },
    tld: [".sr"],
    cca2: "SR",
    ccn3: "740",
    cca3: "SUR",
    cioc: "SUR",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { SRD: { name: "Surinamese dollar", symbol: "$" } },
    idd: { root: "+5", suffixes: ["97"] },
    capital: ["Paramaribo"],
    altSpellings: [
      "SR",
      "Sarnam",
      "Sranangron",
      "Republic of Suriname",
      "Republiek Suriname",
    ],
    region: "Americas",
    subregion: "South America",
    languages: { nld: "Dutch" },
    translations: {
      ces: { official: "Republika Surinam", common: "Surinam" },
      deu: { official: "Republik Suriname", common: "Suriname" },
      est: { official: "Suriname Vabariik", common: "Suriname" },
      fin: { official: "Surinamen tasavalta", common: "Suriname" },
      fra: { official: "R\u00e9publique du Suriname", common: "Surinam" },
      hrv: { official: "Republika Surinam", common: "Surinam" },
      hun: {
        official: "Suriname K\u00f6zt\u00e1rsas\u00e1g",
        common: "Suriname",
      },
      ita: { official: "Repubblica del Suriname", common: "Suriname" },
      jpn: {
        official: "\u30b9\u30ea\u30ca\u30e0\u5171\u548c\u56fd",
        common: "\u30b9\u30ea\u30ca\u30e0",
      },
      kor: {
        official: "\uc218\ub9ac\ub0a8 \uacf5\ud654\uad6d",
        common: "\uc218\ub9ac\ub0a8",
      },
      nld: { official: "Republiek Suriname", common: "Suriname" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0633\u0648\u0631\u06cc\u0646\u0627\u0645",
        common: "\u0633\u0648\u0631\u06cc\u0646\u0627\u0645",
      },
      pol: { official: "Republika Surinamu", common: "Surinam" },
      por: { official: "Rep\u00fablica do Suriname", common: "Suriname" },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0421\u0443\u0440\u0438\u043d\u0430\u043c",
        common: "\u0421\u0443\u0440\u0438\u043d\u0430\u043c",
      },
      slk: { official: "Surinamsk\u00e1 republika", common: "Surinam" },
      spa: { official: "Rep\u00fablica de Suriname", common: "Surinam" },
      swe: { official: "Republiken Surinam", common: "Surinam" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0633\u0631\u06cc\u0646\u0627\u0645",
        common: "\u0633\u0631\u06cc\u0646\u0627\u0645",
      },
      zho: {
        official: "\u82cf\u91cc\u5357\u5171\u548c\u56fd",
        common: "\u82cf\u91cc\u5357",
      },
    },
    latlng: [4, -56],
    landlocked: false,
    borders: ["BRA", "GUF", "GUY"],
    area: 163820,
    flag: "\ud83c\uddf8\ud83c\uddf7",
    demonyms: {
      eng: { f: "Surinamer", m: "Surinamer" },
      fra: { f: "Surinamaise", m: "Surinamais" },
    },
    callingCodes: ["+597"],
  },
  {
    name: {
      common: "Slovakia",
      official: "Slovak Republic",
      native: {
        slk: { official: "Slovensk\u00e1 republika", common: "Slovensko" },
      },
    },
    tld: [".sk"],
    cca2: "SK",
    ccn3: "703",
    cca3: "SVK",
    cioc: "SVK",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { EUR: { name: "Euro", symbol: "\u20ac" } },
    idd: { root: "+4", suffixes: ["21"] },
    capital: ["Bratislava"],
    altSpellings: ["SK", "Slovak Republic", "Slovensk\u00e1 republika"],
    region: "Europe",
    subregion: "Central Europe",
    languages: { slk: "Slovak" },
    translations: {
      ces: { official: "Slovensk\u00e1 republika", common: "Slovensko" },
      deu: { official: "Slowakische Republik", common: "Slowakei" },
      est: { official: "Slovaki Vabariik", common: "Slovakkia" },
      fin: { official: "Slovakian tasavalta", common: "Slovakia" },
      fra: { official: "R\u00e9publique slovaque", common: "Slovaquie" },
      hrv: { official: "slova\u010dka", common: "Slova\u010dka" },
      hun: {
        official: "Szlov\u00e1k K\u00f6zt\u00e1rsas\u00e1g",
        common: "Szlov\u00e1kia",
      },
      ita: { official: "Repubblica slovacca", common: "Slovacchia" },
      jpn: {
        official: "\u30b9\u30ed\u30d0\u30ad\u30a2\u5171\u548c\u56fd",
        common: "\u30b9\u30ed\u30d0\u30ad\u30a2",
      },
      kor: {
        official: "\uc2ac\ub85c\ubc14\ud0a4\uc544 \uacf5\ud654\uad6d",
        common: "\uc2ac\ub85c\ubc14\ud0a4\uc544",
      },
      nld: { official: "Slowaakse Republiek", common: "Slowakije" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0627\u0633\u0644\u0648\u0627\u06a9\u06cc",
        common: "\u0627\u0650\u0633\u0644\u064f\u0648\u0627\u06a9\u06cc",
      },
      pol: { official: "Republika S\u0142owacka", common: "S\u0142owacja" },
      por: { official: "Rep\u00fablica Eslovaca", common: "Eslov\u00e1quia" },
      rus: {
        official:
          "\u0421\u043b\u043e\u0432\u0430\u0446\u043a\u0430\u044f \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430",
        common: "\u0421\u043b\u043e\u0432\u0430\u043a\u0438\u044f",
      },
      slk: { official: "Slovensk\u00e1 republika", common: "Slovensko" },
      spa: {
        official: "Rep\u00fablica Eslovaca",
        common: "Rep\u00fablica Eslovaca",
      },
      swe: { official: "Republiken Slovakien", common: "Slovakien" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0633\u0644\u0648\u0648\u0627\u06a9\u06cc\u06c1",
        common: "\u0633\u0644\u0648\u0648\u0627\u06a9\u06cc\u06c1",
      },
      zho: {
        official: "\u65af\u6d1b\u4f10\u514b\u5171\u548c\u56fd",
        common: "\u65af\u6d1b\u4f10\u514b",
      },
    },
    latlng: [48.66666666, 19.5],
    landlocked: true,
    borders: ["AUT", "CZE", "HUN", "POL", "UKR"],
    area: 49037,
    flag: "\ud83c\uddf8\ud83c\uddf0",
    demonyms: {
      eng: { f: "Slovak", m: "Slovak" },
      fra: { f: "Slovaque", m: "Slovaque" },
    },
    callingCodes: ["+421"],
  },
  {
    name: {
      common: "Slovenia",
      official: "Republic of Slovenia",
      native: { slv: { official: "Republika Slovenija", common: "Slovenija" } },
    },
    tld: [".si"],
    cca2: "SI",
    ccn3: "705",
    cca3: "SVN",
    cioc: "SLO",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { EUR: { name: "Euro", symbol: "\u20ac" } },
    idd: { root: "+3", suffixes: ["86"] },
    capital: ["Ljubljana"],
    altSpellings: ["SI", "Republic of Slovenia", "Republika Slovenija"],
    region: "Europe",
    subregion: "Central Europe",
    languages: { slv: "Slovene" },
    translations: {
      ces: { official: "Slovinsk\u00e1 republika", common: "Slovinsko" },
      deu: { official: "Republik Slowenien", common: "Slowenien" },
      est: { official: "Sloveenia Vabariik", common: "Sloveenia" },
      fin: { official: "Slovenian tasavalta", common: "Slovenia" },
      fra: {
        official: "R\u00e9publique de Slov\u00e9nie",
        common: "Slov\u00e9nie",
      },
      hrv: { official: "Republika Slovenija", common: "Slovenija" },
      hun: {
        official: "Szlov\u00e9n K\u00f6zt\u00e1rsas\u00e1g",
        common: "Szlov\u00e9nia",
      },
      ita: { official: "Repubblica di Slovenia", common: "Slovenia" },
      jpn: {
        official: "\u30b9\u30ed\u30d9\u30cb\u30a2\u5171\u548c\u56fd",
        common: "\u30b9\u30ed\u30d9\u30cb\u30a2",
      },
      kor: {
        official: "\uc2ac\ub85c\ubca0\ub2c8\uc544 \uacf5\ud654\uad6d",
        common: "\uc2ac\ub85c\ubca0\ub2c8\uc544",
      },
      nld: { official: "Republiek Sloveni\u00eb", common: "Sloveni\u00eb" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0627\u0633\u0644\u0648\u0648\u0646\u06cc",
        common: "\u0627\u0633\u0644\u0648\u0648\u0646\u06cc",
      },
      pol: { official: "Republika S\u0142owenii", common: "S\u0142owenia" },
      por: {
        official: "Rep\u00fablica da Eslov\u00e9nia",
        common: "Eslov\u00e9nia",
      },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0421\u043b\u043e\u0432\u0435\u043d\u0438\u044f",
        common: "\u0421\u043b\u043e\u0432\u0435\u043d\u0438\u044f",
      },
      slk: { official: "Slovinsk\u00e1 republika", common: "Slovinsko" },
      spa: { official: "Rep\u00fablica de Eslovenia", common: "Eslovenia" },
      swe: { official: "Republiken Slovenien", common: "Slovenien" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0633\u0644\u0648\u0648\u06cc\u0646\u06cc\u0627",
        common: "\u0633\u0644\u0648\u0648\u06cc\u0646\u06cc\u0627",
      },
      zho: {
        official: "\u65af\u6d1b\u6587\u5c3c\u4e9a\u5171\u548c\u56fd",
        common: "\u65af\u6d1b\u6587\u5c3c\u4e9a",
      },
    },
    latlng: [46.11666666, 14.81666666],
    landlocked: false,
    borders: ["AUT", "HRV", "ITA", "HUN"],
    area: 20273,
    flag: "\ud83c\uddf8\ud83c\uddee",
    demonyms: {
      eng: { f: "Slovene", m: "Slovene" },
      fra: { f: "Slov\u00e8ne", m: "Slov\u00e8ne" },
    },
    callingCodes: ["+386"],
  },
  {
    name: {
      common: "Sweden",
      official: "Kingdom of Sweden",
      native: { swe: { official: "Konungariket Sverige", common: "Sverige" } },
    },
    tld: [".se"],
    cca2: "SE",
    ccn3: "752",
    cca3: "SWE",
    cioc: "SWE",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { SEK: { name: "Swedish krona", symbol: "kr" } },
    idd: { root: "+4", suffixes: ["6"] },
    capital: ["Stockholm"],
    altSpellings: ["SE", "Kingdom of Sweden", "Konungariket Sverige"],
    region: "Europe",
    subregion: "Northern Europe",
    languages: { swe: "Swedish" },
    translations: {
      ces: {
        official: "\u0160v\u00e9dsk\u00e9 kr\u00e1lovstv\u00ed",
        common: "\u0160v\u00e9dsko",
      },
      deu: { official: "K\u00f6nigreich Schweden", common: "Schweden" },
      est: { official: "Rootsi Kuningriik", common: "Rootsi" },
      fin: { official: "Ruotsin kuningaskunta", common: "Ruotsi" },
      fra: { official: "Royaume de Su\u00e8de", common: "Su\u00e8de" },
      hrv: { official: "Kraljevina \u0160vedska", common: "\u0160vedska" },
      hun: {
        official: "Sv\u00e9d Kir\u00e1lys\u00e1g",
        common: "Sv\u00e9dorsz\u00e1g",
      },
      ita: { official: "Regno di Svezia", common: "Svezia" },
      jpn: {
        official: "\u30b9\u30a6\u30a7\u30fc\u30c7\u30f3\u738b\u56fd",
        common: "\u30b9\u30a6\u30a7\u30fc\u30c7\u30f3",
      },
      kor: {
        official: "\uc2a4\uc6e8\ub374 \uc655\uad6d",
        common: "\uc2a4\uc6e8\ub374",
      },
      nld: { official: "Koninkrijk Zweden", common: "Zweden" },
      per: {
        official:
          "\u067e\u0627\u062f\u0634\u0627\u0647\u06cc \u0633\u0648\u0626\u062f",
        common: "\u0633\u0648\u0626\u062f",
      },
      pol: { official: "Kr\u00f3lestwo Szwecji", common: "Szwecja" },
      por: { official: "Reino da Su\u00e9cia", common: "Su\u00e9cia" },
      rus: {
        official:
          "\u041a\u043e\u0440\u043e\u043b\u0435\u0432\u0441\u0442\u0432\u043e \u0428\u0432\u0435\u0446\u0438\u044f",
        common: "\u0428\u0432\u0435\u0446\u0438\u044f",
      },
      slk: {
        official: "\u0160v\u00e9dske kr\u00e1\u013eovstvo",
        common: "\u0160v\u00e9dsko",
      },
      spa: { official: "Reino de Suecia", common: "Suecia" },
      swe: { official: "Konungariket Sverige", common: "Sverige" },
      urd: {
        official:
          "\u0645\u0645\u0644\u06a9\u062a\u0650 \u0633\u0648\u06cc\u0688\u0646",
        common: "\u0633\u0648\u06cc\u0688\u0646",
      },
      zho: { official: "\u745e\u5178\u738b\u56fd", common: "\u745e\u5178" },
    },
    latlng: [62, 15],
    landlocked: false,
    borders: ["FIN", "NOR"],
    area: 450295,
    flag: "\ud83c\uddf8\ud83c\uddea",
    demonyms: {
      eng: { f: "Swedish", m: "Swedish" },
      fra: { f: "Su\u00e9doise", m: "Su\u00e9dois" },
    },
    callingCodes: ["+46"],
  },
  {
    name: {
      common: "Eswatini",
      official: "Kingdom of Eswatini",
      native: {
        eng: { official: "Kingdom of Eswatini", common: "Eswatini" },
        ssw: { official: "Umbuso weSwatini", common: "eSwatini" },
      },
    },
    tld: [".sz"],
    cca2: "SZ",
    ccn3: "748",
    cca3: "SWZ",
    cioc: "SWZ",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: {
      SZL: { name: "Swazi lilangeni", symbol: "L" },
      ZAR: { name: "South African rand", symbol: "R" },
    },
    idd: { root: "+2", suffixes: ["68"] },
    capital: ["Lobamba"],
    altSpellings: [
      "SZ",
      "Swaziland",
      "weSwatini",
      "Swatini",
      "Ngwane",
      "Kingdom of Eswatini",
      "Umbuso weSwatini",
    ],
    region: "Africa",
    subregion: "Southern Africa",
    languages: { eng: "English", ssw: "Swazi" },
    translations: {
      ces: {
        official: "Svazijsk\u00e9 kr\u00e1lovstv\u00ed",
        common: "Svazijsko",
      },
      deu: { official: "K\u00f6nigreich Eswatini", common: "Swasiland" },
      est: { official: "eSwatini Kuningriik", common: "Svaasimaa" },
      fin: { official: "Swazimaan kuningaskunta", common: "Swazimaa" },
      fra: { official: "Royaume d\u2019Eswatini", common: "Swaziland" },
      hrv: { official: "Kraljevina eSwatini", common: "Svazi" },
      hun: {
        official: "Szv\u00e1zif\u00f6ldi Kir\u00e1lys\u00e1g",
        common: "Szv\u00e1zif\u00f6ld",
      },
      ita: { official: "Regno di eSwatini", common: "Swaziland" },
      jpn: {
        official: "\u30b9\u30ef\u30b8\u30e9\u30f3\u30c9\u738b\u56fd",
        common: "\u30b9\u30ef\u30b8\u30e9\u30f3\u30c9",
      },
      kor: {
        official: "\uc5d0\uc2a4\uc640\ud2f0\ub2c8 \uc655\uad6d",
        common: "\uc5d0\uc2a4\uc640\ud2f0\ub2c8",
      },
      nld: { official: "Koninkrijk eSwatini", common: "Swaziland" },
      per: {
        official:
          "\u067e\u0627\u062f\u0634\u0627\u0647\u06cc \u0633\u0648\u0627\u0632\u06cc\u0644\u0646\u062f",
        common: "\u0627\u0633\u0648\u0627\u062a\u06cc\u0646\u06cc",
      },
      pol: { official: "Kr\u00f3lestwo Suazi", common: "Suazi" },
      por: { official: "Reino de eSwatini", common: "Suazil\u00e2ndia" },
      rus: {
        official:
          "\u041a\u043e\u0440\u043e\u043b\u0435\u0432\u0441\u0442\u0432\u043e \u0421\u0432\u0430\u0437\u0438\u043b\u0435\u043d\u0434",
        common: "\u0421\u0432\u0430\u0437\u0438\u043b\u0435\u043d\u0434",
      },
      slk: {
        official: "Svazijsk\u00e9 kr\u00e1\u013eovstvo",
        common: "Svazijsko",
      },
      spa: { official: "Reino de eSwatini", common: "Suazilandia" },
      swe: { official: "Konungariket Eswatini", common: "Swaziland" },
      urd: {
        official:
          "\u0645\u0645\u0644\u06a9\u062a\u0650 \u0633\u0648\u0627\u0632\u06cc \u0644\u06cc\u0646\u0688",
        common: "\u0633\u0648\u0627\u0632\u06cc \u0644\u06cc\u0646\u0688",
      },
      zho: {
        official: "\u65af\u5a01\u58eb\u5170\u738b\u56fd",
        common: "\u65af\u5a01\u58eb\u5170",
      },
    },
    latlng: [-26.5, 31.5],
    landlocked: true,
    borders: ["MOZ", "ZAF"],
    area: 17364,
    flag: "\ud83c\uddf8\ud83c\uddff",
    demonyms: {
      eng: { f: "Swazi", m: "Swazi" },
      fra: { f: "Swazie", m: "Swazie" },
    },
    callingCodes: ["+268"],
  },
  {
    name: {
      common: "Sint Maarten",
      official: "Sint Maarten",
      native: {
        eng: { official: "Sint Maarten", common: "Sint Maarten" },
        fra: { official: "Saint-Martin", common: "Saint-Martin" },
        nld: { official: "Sint Maarten", common: "Sint Maarten" },
      },
    },
    tld: [".sx"],
    cca2: "SX",
    ccn3: "534",
    cca3: "SXM",
    cioc: "",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: {
      ANG: { name: "Netherlands Antillean guilder", symbol: "\u0192" },
    },
    idd: { root: "+1", suffixes: ["721"] },
    capital: ["Philipsburg"],
    altSpellings: ["SX", "Sint Maarten (Dutch part)"],
    region: "Americas",
    subregion: "Caribbean",
    languages: { eng: "English", fra: "French", nld: "Dutch" },
    translations: {
      ces: {
        official: "Svat\u00fd Martin",
        common: "Svat\u00fd Martin (Nizozemsko)",
      },
      deu: { official: "Sint Maarten", common: "Sint Maarten" },
      est: { official: "Sint Maarten", common: "Sint Maarten" },
      fin: { official: "Sint Maarten", common: "Sint Maarten" },
      fra: { official: "Sint Maarten", common: "Saint-Martin" },
      hrv: { official: "Sveti Martin", common: "Sveti Martin" },
      hun: { official: "Sint Maarten", common: "Sint Maarten" },
      ita: { official: "Sint Maarten", common: "Sint Maarten" },
      jpn: {
        official: "\u30b7\u30f3\u30c8\u30de\u30fc\u30eb\u30c6\u30f3\u5cf6",
        common: "\u30b7\u30f3\u30c8\u30fb\u30de\u30fc\u30eb\u30c6\u30f3",
      },
      kor: {
        official: "\uc2e0\ud2b8\ub9c8\ub974\ud134",
        common: "\uc2e0\ud2b8\ub9c8\ub974\ud134",
      },
      nld: { official: "Sint Maarten", common: "Sint Maarten" },
      per: {
        official: "\u0633\u0646 \u0645\u0627\u0631\u062a\u0646",
        common: "\u0633\u0646 \u0645\u0627\u0631\u062a\u0646",
      },
      pol: { official: "Sint Maarten", common: "Sint Maarten" },
      por: { official: "Sint Maarten", common: "S\u00e3o Martinho" },
      rus: {
        official:
          "\u0421\u0438\u043d\u0442-\u041c\u0430\u0430\u0440\u0442\u0435\u043d",
        common: "\u0421\u0438\u043d\u0442-\u041c\u0430\u0440\u0442\u0435\u043d",
      },
      slk: { official: "Sint Maarten", common: "Sint Maarten" },
      spa: { official: "Sint Maarten", common: "Sint Maarten" },
      swe: { official: "Sint Maarten", common: "Sint Maarten" },
      urd: {
        official: "\u0633\u0646\u0679 \u0645\u0627\u0631\u0679\u0646",
        common: "\u0633\u0646\u0679 \u0645\u0627\u0631\u0679\u0646",
      },
      zho: {
        official: "\u5723\u9a6c\u4e01\u5c9b",
        common: "\u5723\u9a6c\u4e01\u5c9b",
      },
    },
    latlng: [18.033333, -63.05],
    landlocked: false,
    borders: ["MAF"],
    area: 34,
    flag: "\ud83c\uddf8\ud83c\uddfd",
    demonyms: {
      eng: { f: "St. Maartener", m: "St. Maartener" },
      fra: { f: "Saint-Martinoise", m: "Saint-Martinois" },
    },
    callingCodes: ["+1721"],
  },
  {
    name: {
      common: "Seychelles",
      official: "Republic of Seychelles",
      native: {
        crs: { official: "Repiblik Sesel", common: "Sesel" },
        eng: { official: "Republic of Seychelles", common: "Seychelles" },
        fra: {
          official: "R\u00e9publique des Seychelles",
          common: "Seychelles",
        },
      },
    },
    tld: [".sc"],
    cca2: "SC",
    ccn3: "690",
    cca3: "SYC",
    cioc: "SEY",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { SCR: { name: "Seychellois rupee", symbol: "\u20a8" } },
    idd: { root: "+2", suffixes: ["48"] },
    capital: ["Victoria"],
    altSpellings: [
      "SC",
      "Republic of Seychelles",
      "Repiblik Sesel",
      "R\u00e9publique des Seychelles",
    ],
    region: "Africa",
    subregion: "Eastern Africa",
    languages: { crs: "Seychellois Creole", eng: "English", fra: "French" },
    translations: {
      ces: { official: "Seychelsk\u00e1 republika", common: "Seychely" },
      deu: { official: "Republik der Seychellen", common: "Seychellen" },
      est: { official: "Sei\u0161elli Vabariik", common: "Sei\u0161ellid" },
      fin: { official: "Seychellien tasavalta", common: "Seychellit" },
      fra: { official: "R\u00e9publique des Seychelles", common: "Seychelles" },
      hrv: { official: "Republika Sej\u0161eli", common: "Sej\u0161eli" },
      hun: {
        official: "Seychelle K\u00f6zt\u00e1rsas\u00e1g",
        common: "Seychelle-szigetek",
      },
      ita: { official: "Repubblica delle Seychelles", common: "Seychelles" },
      jpn: {
        official: "\u30bb\u30a4\u30b7\u30a7\u30eb\u5171\u548c\u56fd",
        common: "\u30bb\u30fc\u30b7\u30a7\u30eb",
      },
      kor: {
        official: "\uc138\uc774\uc178 \uacf5\ud654\uad6d",
        common: "\uc138\uc774\uc178",
      },
      nld: { official: "Republiek der Seychellen", common: "Seychellen" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0633\u06cc\u0634\u0644",
        common: "\u0633\u06cc\u0634\u0644",
      },
      pol: { official: "Republika Seszeli", common: "Seszele" },
      por: { official: "Rep\u00fablica das Seychelles", common: "Seicheles" },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0421\u0435\u0439\u0448\u0435\u043b\u044c\u0441\u043a\u0438\u0435 \u041e\u0441\u0442\u0440\u043e\u0432\u0430",
        common:
          "\u0421\u0435\u0439\u0448\u0435\u043b\u044c\u0441\u043a\u0438\u0435 \u041e\u0441\u0442\u0440\u043e\u0432\u0430",
      },
      slk: { official: "Seychelsk\u00e1 republika", common: "Seychely" },
      spa: {
        official: "Rep\u00fablica de las Seychelles",
        common: "Seychelles",
      },
      swe: { official: "Republiken Seychellerna", common: "Seychellerna" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0633\u06cc\u0686\u06cc\u0644\u06cc\u0633",
        common: "\u0633\u06cc\u0686\u06cc\u0644\u06cc\u0633",
      },
      zho: {
        official: "\u585e\u820c\u5c14\u5171\u548c\u56fd",
        common: "\u585e\u820c\u5c14",
      },
    },
    latlng: [-4.58333333, 55.66666666],
    landlocked: false,
    borders: [],
    area: 452,
    flag: "\ud83c\uddf8\ud83c\udde8",
    demonyms: {
      eng: { f: "Seychellois", m: "Seychellois" },
      fra: { f: "Seychelloise", m: "Seychellois" },
    },
    callingCodes: ["+248"],
  },
  {
    name: {
      common: "Syria",
      official: "Syrian Arab Republic",
      native: {
        ara: {
          official:
            "\u0627\u0644\u062c\u0645\u0647\u0648\u0631\u064a\u0629 \u0627\u0644\u0639\u0631\u0628\u064a\u0629 \u0627\u0644\u0633\u0648\u0631\u064a\u0629",
          common: "\u0633\u0648\u0631\u064a\u0627",
        },
      },
    },
    tld: [".sy", "\u0633\u0648\u0631\u064a\u0627."],
    cca2: "SY",
    ccn3: "760",
    cca3: "SYR",
    cioc: "SYR",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { SYP: { name: "Syrian pound", symbol: "\u00a3" } },
    idd: { root: "+9", suffixes: ["63"] },
    capital: ["Damascus"],
    altSpellings: [
      "SY",
      "Syrian Arab Republic",
      "Al-Jumh\u016br\u012byah Al-\u02bbArab\u012byah As-S\u016br\u012byah",
    ],
    region: "Asia",
    subregion: "Western Asia",
    languages: { ara: "Arabic" },
    translations: {
      ces: {
        official: "Syrsk\u00e1 arabsk\u00e1 republika",
        common: "S\u00fdrie",
      },
      deu: { official: "Arabische Republik Syrien", common: "Syrien" },
      est: {
        official: "S\u00fc\u00fcria Araabia Vabariik",
        common: "S\u00fc\u00fcria",
      },
      fin: { official: "Syyrian arabitasavalta", common: "Syyria" },
      fra: { official: "R\u00e9publique arabe syrienne", common: "Syrie" },
      hrv: { official: "Sirijska Arapska Republika", common: "Sirija" },
      hun: {
        official: "Sz\u00edriai Arab K\u00f6zt\u00e1rsas\u00e1g",
        common: "Sz\u00edria",
      },
      ita: { official: "Repubblica araba siriana", common: "Siria" },
      jpn: {
        official: "\u30b7\u30ea\u30a2\u30a2\u30e9\u30d6\u5171\u548c\u56fd",
        common: "\u30b7\u30ea\u30a2\u30fb\u30a2\u30e9\u30d6\u5171\u548c\u56fd",
      },
      kor: {
        official: "\uc2dc\ub9ac\uc544 \uc544\ub78d \uacf5\ud654\uad6d",
        common: "\uc2dc\ub9ac\uc544",
      },
      nld: { official: "Syrische Arabische Republiek", common: "Syri\u00eb" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0639\u0631\u0628\u06cc \u0633\u0648\u0631\u06cc\u0647",
        common: "\u0633\u0648\u0631\u06cc\u0647",
      },
      pol: { official: "Syryjska Republika Arabska", common: "Syria" },
      por: {
        official: "Rep\u00fablica \u00c1rabe S\u00edria",
        common: "S\u00edria",
      },
      rus: {
        official:
          "\u0421\u0438\u0440\u0438\u0439\u0441\u043a\u0430\u044f \u0410\u0440\u0430\u0431\u0441\u043a\u0430\u044f \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430",
        common: "\u0421\u0438\u0440\u0438\u044f",
      },
      slk: {
        official: "S\u00fdrska arabsk\u00e1 republika",
        common: "S\u00fdria",
      },
      spa: { official: "Rep\u00fablica \u00c1rabe Siria", common: "Siria" },
      swe: { official: "Syriska arabiska republiken", common: "Syrien" },
      urd: {
        official:
          "\u0639\u0631\u0628 \u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0633\u0648\u0631\u06cc\u06c1",
        common: "\u0633\u0648\u0631\u06cc\u06c1",
      },
      zho: {
        official: "\u53d9\u5229\u4e9a\u963f\u62c9\u4f2f\u5171\u548c\u56fd",
        common: "\u53d9\u5229\u4e9a",
      },
    },
    latlng: [35, 38],
    landlocked: false,
    borders: ["IRQ", "ISR", "JOR", "LBN", "TUR"],
    area: 185180,
    flag: "\ud83c\uddf8\ud83c\uddfe",
    demonyms: {
      eng: { f: "Syrian", m: "Syrian" },
      fra: { f: "Syrienne", m: "Syrien" },
    },
    callingCodes: ["+963"],
  },
  {
    name: {
      common: "Turks and Caicos Islands",
      official: "Turks and Caicos Islands",
      native: {
        eng: {
          official: "Turks and Caicos Islands",
          common: "Turks and Caicos Islands",
        },
      },
    },
    tld: [".tc"],
    cca2: "TC",
    ccn3: "796",
    cca3: "TCA",
    cioc: "",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: { USD: { name: "United States dollar", symbol: "$" } },
    idd: { root: "+1", suffixes: ["649"] },
    capital: ["Cockburn Town"],
    altSpellings: ["TC"],
    region: "Americas",
    subregion: "Caribbean",
    languages: { eng: "English" },
    translations: {
      ces: { official: "Turks a Caicos", common: "Turks a Caicos" },
      deu: {
        official: "Turks und Caicos Inseln",
        common: "Turks-und Caicosinseln",
      },
      est: { official: "Turksi ja Caicose saared", common: "Turks ja Caicos" },
      fin: {
        official: "Turks-ja Caicossaaret",
        common: "Turks-ja Caicossaaret",
      },
      fra: {
        official: "\u00celes Turques et Ca\u00efques",
        common: "\u00celes Turques-et-Ca\u00efques",
      },
      hrv: { official: "Otoci Turks i Caicos", common: "Otoci Turks i Caicos" },
      hun: {
        official: "Turks- \u00e9s Caicos-szigetek",
        common: "Turks- \u00e9s Caicos-szigetek",
      },
      ita: { official: "Turks e Caicos", common: "Isole Turks e Caicos" },
      jpn: {
        official:
          "\u30bf\u30fc\u30af\u30b9\u00b7\u30ab\u30a4\u30b3\u30b9\u8af8\u5cf6",
        common:
          "\u30bf\u30fc\u30af\u30b9\u30fb\u30ab\u30a4\u30b3\u30b9\u8af8\u5cf6",
      },
      kor: {
        official: "\ud130\ud06c\uc2a4 \ucf00\uc774\ucee4\uc2a4 \uc81c\ub3c4",
        common: "\ud130\ud06c\uc2a4 \ucf00\uc774\ucee4\uc2a4 \uc81c\ub3c4",
      },
      nld: {
        official: "Turks-en Caicoseilanden",
        common: "Turks-en Caicoseilanden",
      },
      per: {
        official:
          "\u062c\u0632\u0627\u06cc\u0631 \u062a\u0648\u0631\u06a9\u0633 \u0648 \u06a9\u0627\u06cc\u06a9\u0648\u0633",
        common:
          "\u062c\u0632\u0627\u06cc\u0631 \u062a\u0648\u0631\u06a9\u0633 \u0648 \u06a9\u0627\u06cc\u06a9\u0648\u0633",
      },
      pol: { official: "Turks i Caicos", common: "Turks i Caicos" },
      por: { official: "Ilhas Turks e Caicos", common: "Ilhas Turks e Caicos" },
      rus: {
        official:
          "\u0422\u0435\u0440\u043a\u0441 \u0438 \u041a\u0430\u0439\u043a\u043e\u0441 \u043e\u0441\u0442\u0440\u043e\u0432\u0430",
        common:
          "\u0422\u0435\u0440\u043a\u0441 \u0438 \u041a\u0430\u0439\u043a\u043e\u0441",
      },
      slk: { official: "Ostrovy Turks a Caicos", common: "Turks a Caicos" },
      spa: {
        official: "Islas Turcas y Caicos",
        common: "Islas Turks y Caicos",
      },
      swe: {
        official: "Turks- och Caicos\u00f6arna",
        common: "Turks- och Caicos\u00f6arna",
      },
      urd: {
        official:
          "\u062c\u0632\u0627\u0626\u0631 \u06a9\u06cc\u06a9\u0633 \u0648 \u062a\u0631\u06a9\u06cc\u06c1",
        common:
          "\u062c\u0632\u0627\u0626\u0631 \u06a9\u06cc\u06a9\u0633 \u0648 \u062a\u0631\u06a9\u06cc\u06c1",
      },
      zho: {
        official: "\u7279\u514b\u65af\u548c\u51ef\u79d1\u65af\u7fa4\u5c9b",
        common: "\u7279\u514b\u65af\u548c\u51ef\u79d1\u65af\u7fa4\u5c9b",
      },
    },
    latlng: [21.75, -71.58333333],
    landlocked: false,
    borders: [],
    area: 948,
    flag: "\ud83c\uddf9\ud83c\udde8",
    demonyms: {
      eng: { f: "Turks and Caicos Islander", m: "Turks and Caicos Islander" },
      fra: { f: "", m: "" },
    },
    callingCodes: ["+1649"],
  },
  {
    name: {
      common: "Chad",
      official: "Republic of Chad",
      native: {
        ara: {
          official:
            "\u062c\u0645\u0647\u0648\u0631\u064a\u0629 \u062a\u0634\u0627\u062f",
          common: "\u062a\u0634\u0627\u062f\u200e",
        },
        fra: { official: "R\u00e9publique du Tchad", common: "Tchad" },
      },
    },
    tld: [".td"],
    cca2: "TD",
    ccn3: "148",
    cca3: "TCD",
    cioc: "CHA",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { XAF: { name: "Central African CFA franc", symbol: "Fr" } },
    idd: { root: "+2", suffixes: ["35"] },
    capital: ["N'Djamena"],
    altSpellings: [
      "TD",
      "Tchad",
      "Republic of Chad",
      "R\u00e9publique du Tchad",
    ],
    region: "Africa",
    subregion: "Middle Africa",
    languages: { ara: "Arabic", fra: "French" },
    translations: {
      ces: { official: "\u010cadsk\u00e1 republika", common: "\u010cad" },
      cym: { official: "Gweriniaeth Tsiad", common: "Tsiad" },
      deu: { official: "Republik Tschad", common: "Tschad" },
      est: { official: "T\u0161aadi Vabariik", common: "T\u0161aad" },
      fin: { official: "T\u0161adin tasavalta", common: "T\u0161ad" },
      fra: { official: "R\u00e9publique du Tchad", common: "Tchad" },
      hrv: { official: "\u010cadu", common: "\u010cad" },
      hun: {
        official: "Cs\u00e1d K\u00f6zt\u00e1rsas\u00e1g",
        common: "Cs\u00e1d",
      },
      ita: { official: "Repubblica del Ciad", common: "Ciad" },
      jpn: {
        official: "\u30c1\u30e3\u30c9\u5171\u548c\u56fd",
        common: "\u30c1\u30e3\u30c9",
      },
      kor: {
        official: "\ucc28\ub4dc \uacf5\ud654\uad6d",
        common: "\ucc28\ub4dc",
      },
      nld: { official: "Republiek Tsjaad", common: "Tsjaad" },
      per: {
        official: "\u062c\u0645\u0647\u0648\u0631\u06cc \u0686\u0627\u062f",
        common: "\u0686\u0627\u062f",
      },
      pol: { official: "Republika Czadu", common: "Czad" },
      por: { official: "Rep\u00fablica do Chade", common: "Chade" },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0427\u0430\u0434",
        common: "\u0427\u0430\u0434",
      },
      slk: { official: "\u010cadsk\u00e1 republika", common: "\u010cad" },
      spa: { official: "Rep\u00fablica de Chad", common: "Chad" },
      swe: { official: "Republiken Tchad", common: "Tchad" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0686\u0627\u0688",
        common: "\u0686\u0627\u0688",
      },
      zho: {
        official: "\u4e4d\u5f97\u5171\u548c\u56fd",
        common: "\u4e4d\u5f97",
      },
    },
    latlng: [15, 19],
    landlocked: true,
    borders: ["CMR", "CAF", "LBY", "NER", "NGA", "SDN"],
    area: 1284000,
    flag: "\ud83c\uddf9\ud83c\udde9",
    demonyms: {
      eng: { f: "Chadian", m: "Chadian" },
      fra: { f: "Tchadienne", m: "Tchadien" },
    },
    callingCodes: ["+235"],
  },
  {
    name: {
      common: "Togo",
      official: "Togolese Republic",
      native: {
        fra: { official: "R\u00e9publique togolaise", common: "Togo" },
      },
    },
    tld: [".tg"],
    cca2: "TG",
    ccn3: "768",
    cca3: "TGO",
    cioc: "TOG",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { XOF: { name: "West African CFA franc", symbol: "Fr" } },
    idd: { root: "+2", suffixes: ["28"] },
    capital: ["Lom\u00e9"],
    altSpellings: [
      "TG",
      "Togolese",
      "Togolese Republic",
      "R\u00e9publique Togolaise",
    ],
    region: "Africa",
    subregion: "Western Africa",
    languages: { fra: "French" },
    translations: {
      ces: { official: "Republika Togo", common: "Togo" },
      deu: { official: "Republik Togo", common: "Togo" },
      est: { official: "Togo Vabariik", common: "Togo" },
      fin: { official: "Togon tasavalta", common: "Togo" },
      fra: { official: "R\u00e9publique togolaise", common: "Togo" },
      hrv: { official: "Togolese Republika", common: "Togo" },
      hun: {
        official: "Tog\u00f3i K\u00f6zt\u00e1rsas\u00e1g",
        common: "Togo",
      },
      ita: { official: "Repubblica del Togo", common: "Togo" },
      jpn: {
        official: "\u30c8\u30fc\u30b4\u5171\u548c\u56fd",
        common: "\u30c8\u30fc\u30b4",
      },
      kor: {
        official: "\ud1a0\uace0 \uacf5\ud654\uad6d",
        common: "\ud1a0\uace0",
      },
      nld: { official: "Republiek Togo", common: "Togo" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u062a\u0648\u06af\u0648",
        common: "\u062a\u0648\u06af\u0648",
      },
      pol: { official: "Republika Togijska", common: "Togo" },
      por: { official: "Rep\u00fablica do Togo", common: "Togo" },
      rus: {
        official:
          "\u0422\u043e\u0433\u043e \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430",
        common: "\u0422\u043e\u0433\u043e",
      },
      slk: { official: "Togsk\u00e1 republika", common: "Togo" },
      spa: { official: "Rep\u00fablica de Togo", common: "Togo" },
      swe: { official: "Republiken Togo", common: "Togo" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0679\u0648\u06af\u0648",
        common: "\u0679\u0648\u06af\u0648",
      },
      zho: {
        official: "\u591a\u54e5\u5171\u548c\u56fd",
        common: "\u591a\u54e5",
      },
    },
    latlng: [8, 1.16666666],
    landlocked: false,
    borders: ["BEN", "BFA", "GHA"],
    area: 56785,
    flag: "\ud83c\uddf9\ud83c\uddec",
    demonyms: {
      eng: { f: "Togolese", m: "Togolese" },
      fra: { f: "Togolaise", m: "Togolais" },
    },
    callingCodes: ["+228"],
  },
  {
    name: {
      common: "Thailand",
      official: "Kingdom of Thailand",
      native: {
        tha: {
          official:
            "\u0e23\u0e32\u0e0a\u0e2d\u0e32\u0e13\u0e32\u0e08\u0e31\u0e01\u0e23\u0e44\u0e17\u0e22",
          common: "\u0e1b\u0e23\u0e30\u0e40\u0e17\u0e28\u0e44\u0e17\u0e22",
        },
      },
    },
    tld: [".th", ".\u0e44\u0e17\u0e22"],
    cca2: "TH",
    ccn3: "764",
    cca3: "THA",
    cioc: "THA",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { THB: { name: "Thai baht", symbol: "\u0e3f" } },
    idd: { root: "+6", suffixes: ["6"] },
    capital: ["Bangkok"],
    altSpellings: [
      "TH",
      "Prathet",
      "Thai",
      "Kingdom of Thailand",
      "\u0e23\u0e32\u0e0a\u0e2d\u0e32\u0e13\u0e32\u0e08\u0e31\u0e01\u0e23\u0e44\u0e17\u0e22",
      "Ratcha Anachak Thai",
    ],
    region: "Asia",
    subregion: "South-Eastern Asia",
    languages: { tha: "Thai" },
    translations: {
      ces: { official: "Thajsk\u00e9 kr\u00e1lovstv\u00ed", common: "Thajsko" },
      deu: { official: "K\u00f6nigreich Thailand", common: "Thailand" },
      est: { official: "Tai Kuningriik", common: "Tai" },
      fin: { official: "Thaimaan kuningaskunta", common: "Thaimaa" },
      fra: { official: "Royaume de Tha\u00eflande", common: "Tha\u00eflande" },
      hrv: { official: "Kraljevina Tajland", common: "Tajland" },
      hun: {
        official: "Thaif\u00f6ldi Kir\u00e1lys\u00e1g",
        common: "Thaif\u00f6ld",
      },
      ita: { official: "Regno di Thailandia", common: "Tailandia" },
      jpn: { official: "\u30bf\u30a4\u738b\u56fd", common: "\u30bf\u30a4" },
      kor: { official: "\ud0c0\uc774 \uc655\uad6d", common: "\ud0dc\uad6d" },
      nld: { official: "Koninkrijk Thailand", common: "Thailand" },
      per: {
        official:
          "\u067e\u0627\u062f\u0634\u0627\u0647\u06cc \u062a\u0627\u06cc\u0644\u0646\u062f",
        common: "\u062a\u0627\u06cc\u0644\u0646\u062f",
      },
      pol: { official: "Kr\u00f3lestwo Tajlandii", common: "Tajlandia" },
      por: { official: "Reino da Tail\u00e2ndia", common: "Tail\u00e2ndia" },
      rus: {
        official:
          "\u041a\u043e\u0440\u043e\u043b\u0435\u0432\u0441\u0442\u0432\u043e \u0422\u0430\u0438\u043b\u0430\u043d\u0434",
        common: "\u0422\u0430\u0438\u043b\u0430\u043d\u0434",
      },
      slk: { official: "Thajsk\u00e9 kr\u00e1\u013eovstvo", common: "Thajsko" },
      spa: { official: "Reino de Tailandia", common: "Tailandia" },
      swe: { official: "Konungariket Thailand", common: "Thailand" },
      urd: {
        official:
          "\u0645\u0645\u0644\u06a9\u062a\u0650 \u062a\u06be\u0627\u0626\u06cc \u0644\u06cc\u0646\u0688",
        common: "\u062a\u06be\u0627\u0626\u06cc \u0644\u06cc\u0646\u0688",
      },
      zho: { official: "\u6cf0\u738b\u56fd", common: "\u6cf0\u56fd" },
    },
    latlng: [15, 100],
    landlocked: false,
    borders: ["MMR", "KHM", "LAO", "MYS"],
    area: 513120,
    flag: "\ud83c\uddf9\ud83c\udded",
    demonyms: {
      eng: { f: "Thai", m: "Thai" },
      fra: { f: "Tha\u00eflandaise", m: "Tha\u00eflandais" },
    },
    callingCodes: ["+66"],
  },
  {
    name: {
      common: "Tajikistan",
      official: "Republic of Tajikistan",
      native: {
        rus: {
          official:
            "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0422\u0430\u0434\u0436\u0438\u043a\u0438\u0441\u0442\u0430\u043d",
          common:
            "\u0422\u0430\u0434\u0436\u0438\u043a\u0438\u0441\u0442\u0430\u043d",
        },
        tgk: {
          official:
            "\u04b6\u0443\u043c\u04b3\u0443\u0440\u0438\u0438 \u0422\u043e\u04b7\u0438\u043a\u0438\u0441\u0442\u043e\u043d",
          common:
            "\u0422\u043e\u04b7\u0438\u043a\u0438\u0441\u0442\u043e\u043d",
        },
      },
    },
    tld: [".tj"],
    cca2: "TJ",
    ccn3: "762",
    cca3: "TJK",
    cioc: "TJK",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { TJS: { name: "Tajikistani somoni", symbol: "\u0405\u041c" } },
    idd: { root: "+9", suffixes: ["92"] },
    capital: ["Dushanbe"],
    altSpellings: [
      "TJ",
      "To\u00e7ikiston",
      "Republic of Tajikistan",
      "\u04b6\u0443\u043c\u04b3\u0443\u0440\u0438\u0438 \u0422\u043e\u04b7\u0438\u043a\u0438\u0441\u0442\u043e\u043d",
      "\u00c7umhuriyi To\u00e7ikiston",
    ],
    region: "Asia",
    subregion: "Central Asia",
    languages: { rus: "Russian", tgk: "Tajik" },
    translations: {
      ces: {
        official: "Republika T\u00e1d\u017eikist\u00e1n",
        common: "T\u00e1d\u017eikist\u00e1n",
      },
      deu: { official: "Republik Tadschikistan", common: "Tadschikistan" },
      est: {
        official: "Tad\u017eikistani Vabariik",
        common: "Tad\u017eikistan",
      },
      fin: {
        official: "Tad\u017eikistanin tasavalta",
        common: "Tad\u017eikistan",
      },
      fra: {
        official: "R\u00e9publique du Tadjikistan",
        common: "Tadjikistan",
      },
      hrv: {
        official: "Republika Tad\u017eikistan",
        common: "Ta\u0111ikistan",
      },
      hun: {
        official: "T\u00e1dzsik K\u00f6zt\u00e1rsas\u00e1g",
        common: "T\u00e1dzsikiszt\u00e1n",
      },
      ita: { official: "Repubblica del Tajikistan", common: "Tagikistan" },
      jpn: {
        official: "\u30bf\u30b8\u30ad\u30b9\u30bf\u30f3\u5171\u548c\u56fd",
        common: "\u30bf\u30b8\u30ad\u30b9\u30bf\u30f3",
      },
      kor: {
        official: "\ud0c0\uc9c0\ud0a4\uc2a4\ud0c4 \uacf5\ud654\uad6d",
        common: "\ud0c0\uc9c0\ud0a4\uc2a4\ud0c4",
      },
      nld: { official: "Tadzjikistan", common: "Tadzjikistan" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u062a\u0627\u062c\u06cc\u06a9\u0633\u062a\u0627\u0646",
        common: "\u062a\u0627\u062c\u06cc\u06a9\u0650\u0633\u062a\u0627\u0646",
      },
      pol: {
        official: "Republika Tad\u017cykistanu",
        common: "Tad\u017cykistan",
      },
      por: {
        official: "Rep\u00fablica do Tajiquist\u00e3o",
        common: "Tajiquist\u00e3o",
      },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0422\u0430\u0434\u0436\u0438\u043a\u0438\u0441\u0442\u0430\u043d",
        common:
          "\u0422\u0430\u0434\u0436\u0438\u043a\u0438\u0441\u0442\u0430\u043d",
      },
      slk: {
        official: "Ta\u01c6ick\u00e1 republika",
        common: "Ta\u01c6ikistan",
      },
      spa: {
        official: "Rep\u00fablica de Tayikist\u00e1n",
        common: "Tayikist\u00e1n",
      },
      swe: { official: "Republiken Tadzjikistan", common: "Tadzjikistan" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u062a\u0627\u062c\u06a9\u0633\u062a\u0627\u0646",
        common: "\u062a\u0627\u062c\u06a9\u0633\u062a\u0627\u0646",
      },
      zho: {
        official: "\u5854\u5409\u514b\u65af\u5766\u5171\u548c\u56fd",
        common: "\u5854\u5409\u514b\u65af\u5766",
      },
    },
    latlng: [39, 71],
    landlocked: true,
    borders: ["AFG", "CHN", "KGZ", "UZB"],
    area: 143100,
    flag: "\ud83c\uddf9\ud83c\uddef",
    demonyms: {
      eng: { f: "Tadzhik", m: "Tadzhik" },
      fra: { f: "Tadjike", m: "Tadjike" },
    },
    callingCodes: ["+992"],
  },
  {
    name: {
      common: "Tokelau",
      official: "Tokelau",
      native: {
        eng: { official: "Tokelau", common: "Tokelau" },
        smo: { official: "Tokelau", common: "Tokelau" },
        tkl: { official: "Tokelau", common: "Tokelau" },
      },
    },
    tld: [".tk"],
    cca2: "TK",
    ccn3: "772",
    cca3: "TKL",
    cioc: "",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: { NZD: { name: "New Zealand dollar", symbol: "$" } },
    idd: { root: "+6", suffixes: ["90"] },
    capital: ["Fakaofo"],
    altSpellings: ["TK"],
    region: "Oceania",
    subregion: "Polynesia",
    languages: { eng: "English", smo: "Samoan", tkl: "Tokelauan" },
    translations: {
      ces: { official: "Tokelau", common: "Tokelau" },
      deu: { official: "Tokelau", common: "Tokelau" },
      est: { official: "Tokelau", common: "Tokelau" },
      fin: { official: "Tokelau", common: "Tokelau" },
      fra: { official: "\u00celes Tokelau", common: "Tokelau" },
      hrv: { official: "Tokelau", common: "Tokelau" },
      hun: { official: "Tokelau-szigetek", common: "Tokelau-szigetek" },
      ita: { official: "Tokelau", common: "Isole Tokelau" },
      jpn: {
        official: "\u30c8\u30b1\u30e9\u30a6\u8af8\u5cf6",
        common: "\u30c8\u30b1\u30e9\u30a6",
      },
      kor: {
        official: "\ud1a0\ucf08\ub77c\uc6b0",
        common: "\ud1a0\ucf08\ub77c\uc6b0",
      },
      nld: { official: "Tokelau", common: "Tokelau" },
      per: {
        official: "\u062a\u0648\u06a9\u0644\u0627\u0626\u0648",
        common: "\u062a\u0648\u06a9\u0644\u0627\u0626\u0648",
      },
      pol: { official: "Tokelau", common: "Tokelau" },
      por: { official: "Tokelau", common: "Tokelau" },
      rus: {
        official: "\u0422\u043e\u043a\u0435\u043b\u0430\u0443",
        common: "\u0422\u043e\u043a\u0435\u043b\u0430\u0443",
      },
      slk: { official: "Tokelausk\u00e9 ostrovy", common: "Tokelau" },
      spa: { official: "Tokelau", common: "Islas Tokelau" },
      swe: { official: "Tokelau\u00f6arna", common: "Tokelau\u00f6arna" },
      urd: {
        official: "\u0679\u0648\u06a9\u06cc\u0644\u0627\u0624",
        common: "\u0679\u0648\u06a9\u06cc\u0644\u0627\u0624",
      },
      zho: { official: "\u6258\u514b\u52b3", common: "\u6258\u514b\u52b3" },
    },
    latlng: [-9, -172],
    landlocked: false,
    borders: [],
    area: 12,
    flag: "\ud83c\uddf9\ud83c\uddf0",
    demonyms: {
      eng: { f: "Tokelauan", m: "Tokelauan" },
      fra: { f: "", m: "" },
    },
    callingCodes: ["+690"],
  },
  {
    name: {
      common: "Turkmenistan",
      official: "Turkmenistan",
      native: {
        rus: {
          official:
            "\u0422\u0443\u0440\u043a\u043c\u0435\u043d\u0438\u0441\u0442\u0430\u043d",
          common: "\u0422\u0443\u0440\u043a\u043c\u0435\u043d\u0438\u044f",
        },
        tuk: { official: "T\u00fcrkmenistan", common: "T\u00fcrkmenistan" },
      },
    },
    tld: [".tm"],
    cca2: "TM",
    ccn3: "795",
    cca3: "TKM",
    cioc: "TKM",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { TMT: { name: "Turkmenistan manat", symbol: "m" } },
    idd: { root: "+9", suffixes: ["93"] },
    capital: ["Ashgabat"],
    altSpellings: ["TM"],
    region: "Asia",
    subregion: "Central Asia",
    languages: { rus: "Russian", tuk: "Turkmen" },
    translations: {
      ces: { official: "Turkmenist\u00e1n", common: "Turkmenist\u00e1n" },
      deu: { official: "Turkmenistan", common: "Turkmenistan" },
      est: { official: "T\u00fcrkmenistan", common: "T\u00fcrkmenistan" },
      fin: { official: "Turkmenistan", common: "Turkmenistan" },
      fra: { official: "Turkm\u00e9nistan", common: "Turkm\u00e9nistan" },
      hrv: { official: "Turkmenistan", common: "Turkmenistan" },
      hun: {
        official: "T\u00fcrkm\u00e9n K\u00f6zt\u00e1rsas\u00e1g",
        common: "T\u00fcrkmeniszt\u00e1n",
      },
      ita: { official: "Turkmenistan", common: "Turkmenistan" },
      jpn: {
        official: "\u30c8\u30eb\u30af\u30e1\u30cb\u30b9\u30bf\u30f3",
        common: "\u30c8\u30eb\u30af\u30e1\u30cb\u30b9\u30bf\u30f3",
      },
      kor: {
        official: "\ud22c\ub974\ud06c\uba54\ub2c8\uc2a4\ud0c4",
        common: "\ud22c\ub974\ud06c\uba54\ub2c8\uc2a4\ud0c4",
      },
      nld: { official: "Turkmenistan", common: "Turkmenistan" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u062e\u0644\u0642 \u062a\u0631\u06a9\u0645\u0646\u0633\u062a\u0627\u0646",
        common: "\u062a\u0631\u06a9\u0645\u0646\u0633\u062a\u0627\u0646",
      },
      pol: { official: "Republika Turkmenistanu", common: "Turkmenistan" },
      por: { official: "Turcomenist\u00e3o", common: "Turquemenist\u00e3o" },
      rus: {
        official:
          "\u0422\u0443\u0440\u043a\u043c\u0435\u043d\u0438\u0441\u0442\u0430\u043d",
        common: "\u0422\u0443\u0440\u043a\u043c\u0435\u043d\u0438\u044f",
      },
      slk: { official: "Turkm\u00e9nsko", common: "Turkm\u00e9nsko" },
      spa: { official: "Turkmenist\u00e1n", common: "Turkmenist\u00e1n" },
      swe: { official: "Turkmenistan", common: "Turkmenistan" },
      urd: {
        official:
          "\u062a\u0631\u06a9\u0645\u0627\u0646\u0633\u062a\u0627\u0646",
        common: "\u062a\u0631\u06a9\u0645\u0627\u0646\u0633\u062a\u0627\u0646",
      },
      zho: {
        official: "\u571f\u5e93\u66fc\u65af\u5766",
        common: "\u571f\u5e93\u66fc\u65af\u5766",
      },
    },
    latlng: [40, 60],
    landlocked: true,
    borders: ["AFG", "IRN", "KAZ", "UZB"],
    area: 488100,
    flag: "\ud83c\uddf9\ud83c\uddf2",
    demonyms: {
      eng: { f: "Turkmen", m: "Turkmen" },
      fra: { f: "Turkm\u00e8ne", m: "Turkm\u00e8ne" },
    },
    callingCodes: ["+993"],
  },
  {
    name: {
      common: "Timor-Leste",
      official: "Democratic Republic of Timor-Leste",
      native: {
        por: {
          official: "Rep\u00fablica Democr\u00e1tica de Timor-Leste",
          common: "Timor-Leste",
        },
        tet: {
          official: "Rep\u00fablika Demokr\u00e1tika Tim\u00f3r-Leste",
          common: "Tim\u00f3r-Leste",
        },
      },
    },
    tld: [".tl"],
    cca2: "TL",
    ccn3: "626",
    cca3: "TLS",
    cioc: "TLS",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { USD: { name: "United States dollar", symbol: "$" } },
    idd: { root: "+6", suffixes: ["70"] },
    capital: ["Dili"],
    altSpellings: [
      "TL",
      "East Timor",
      "Democratic Republic of Timor-Leste",
      "Rep\u00fablica Democr\u00e1tica de Timor-Leste",
      "Rep\u00fablika Demokr\u00e1tika Tim\u00f3r-Leste",
      "Tim\u00f3r Lorosa'e",
      "Timor Lorosae",
    ],
    region: "Asia",
    subregion: "South-Eastern Asia",
    languages: { por: "Portuguese", tet: "Tetum" },
    translations: {
      ces: {
        official: "Demokratick\u00e1 republika V\u00fdchodn\u00ed Timor",
        common: "V\u00fdchodn\u00ed Timor",
      },
      deu: {
        official: "Demokratische Republik Timor-Leste",
        common: "Osttimor",
      },
      est: {
        official: "Timor-Leste Demokraatlik Vabariik",
        common: "Ida-Timor",
      },
      fin: {
        official: "It\u00e4-Timorin demokraattinen tasavalta",
        common: "It\u00e4-Timor",
      },
      fra: {
        official: "R\u00e9publique d\u00e9mocratique du Timor oriental",
        common: "Timor oriental",
      },
      hrv: {
        official: "Demokratska Republika Timor-Leste",
        common: "Isto\u010dni Timor",
      },
      hun: {
        official: "Kelet-timori Demokratikus K\u00f6zt\u00e1rsas\u00e1g",
        common: "Kelet-Timor",
      },
      ita: {
        official: "Repubblica Democratica di Timor Est",
        common: "Timor Est",
      },
      jpn: {
        official:
          "\u6771\u30c6\u30a3\u30e2\u30fc\u30eb\u6c11\u4e3b\u5171\u548c\u56fd",
        common: "\u6771\u30c6\u30a3\u30e2\u30fc\u30eb",
      },
      kor: {
        official: "\ub3d9\ud2f0\ubaa8\ub974 \ubbfc\uc8fc \uacf5\ud654\uad6d",
        common: "\ub3d9\ud2f0\ubaa8\ub974",
      },
      nld: {
        official: "Democratische Republiek Oost-Timor",
        common: "Oost-Timor",
      },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u062f\u0645\u0648\u06a9\u0631\u0627\u062a\u06cc\u06a9 \u062a\u06cc\u0645\u0648\u0631 \u0634\u0631\u0642\u06cc",
        common: "\u062a\u06cc\u0645\u0648\u0631 \u0634\u0631\u0642\u06cc",
      },
      pol: {
        official: "Demokratyczna Republika Timoru Wschodniego",
        common: "Timor Wschodni",
      },
      por: {
        official: "Rep\u00fablica Democr\u00e1tica de Timor-Leste",
        common: "Timor-Leste",
      },
      rus: {
        official:
          "\u0414\u0435\u043c\u043e\u043a\u0440\u0430\u0442\u0438\u0447\u0435\u0441\u043a\u0430\u044f \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0422\u0438\u043c\u043e\u0440 -\u041b\u0435\u0448\u0442\u0438",
        common:
          "\u0412\u043e\u0441\u0442\u043e\u0447\u043d\u044b\u0439 \u0422\u0438\u043c\u043e\u0440",
      },
      slk: {
        official: "V\u00fdchodotimorsk\u00e1 demokratick\u00e1 republika",
        common: "V\u00fdchodn\u00fd Timor",
      },
      spa: {
        official: "Rep\u00fablica Democr\u00e1tica de Timor-Leste",
        common: "Timor Oriental",
      },
      swe: {
        official: "Demokratiska republiken \u00d6sttimor",
        common: "\u00d6sttimor",
      },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc \u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0645\u0634\u0631\u0642\u06cc \u062a\u06cc\u0645\u0648\u0631",
        common: "\u0645\u0634\u0631\u0642\u06cc \u062a\u06cc\u0645\u0648\u0631",
      },
      zho: {
        official: "\u4e1c\u5e1d\u6c76\u6c11\u4e3b\u5171\u548c\u56fd",
        common: "\u4e1c\u5e1d\u6c76",
      },
    },
    latlng: [-8.83333333, 125.91666666],
    landlocked: false,
    borders: ["IDN"],
    area: 14874,
    flag: "\ud83c\uddf9\ud83c\uddf1",
    demonyms: {
      eng: { f: "East Timorese", m: "East Timorese" },
      fra: { f: "Est-timoraise", m: "Est-timorais" },
    },
    callingCodes: ["+670"],
  },
  {
    name: {
      common: "Tonga",
      official: "Kingdom of Tonga",
      native: {
        eng: { official: "Kingdom of Tonga", common: "Tonga" },
        ton: { official: "Kingdom of Tonga", common: "Tonga" },
      },
    },
    tld: [".to"],
    cca2: "TO",
    ccn3: "776",
    cca3: "TON",
    cioc: "TGA",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { TOP: { name: "Tongan pa\u02bbanga", symbol: "T$" } },
    idd: { root: "+6", suffixes: ["76"] },
    capital: ["Nuku'alofa"],
    altSpellings: ["TO"],
    region: "Oceania",
    subregion: "Polynesia",
    languages: { eng: "English", ton: "Tongan" },
    translations: {
      ces: { official: "Kr\u00e1lovstv\u00ed Tonga", common: "Tonga" },
      deu: { official: "K\u00f6nigreich Tonga", common: "Tonga" },
      est: { official: "Tonga Kuningriik", common: "Tonga" },
      fin: { official: "Tongan kuningaskunta", common: "Tonga" },
      fra: { official: "Royaume des Tonga", common: "Tonga" },
      hrv: { official: "Kraljevina Tonga", common: "Tonga" },
      hun: { official: "Tongai Kir\u00e1lys\u00e1g", common: "Tonga" },
      ita: { official: "Regno di Tonga", common: "Tonga" },
      jpn: {
        official: "\u30c8\u30f3\u30ac\u738b\u56fd",
        common: "\u30c8\u30f3\u30ac",
      },
      kor: { official: "\ud1b5\uac00 \uc655\uad6d", common: "\ud1b5\uac00" },
      nld: { official: "Koninkrijk Tonga", common: "Tonga" },
      per: {
        official:
          "\u067e\u0627\u062f\u0634\u0627\u0647\u06cc \u062a\u0648\u0646\u06af\u0627",
        common: "\u062a\u0648\u0646\u06af\u0627",
      },
      pol: { official: "Kr\u00f3lestwo Tonga", common: "Tonga" },
      por: { official: "Reino de Tonga", common: "Tonga" },
      rus: {
        official:
          "\u041a\u043e\u0440\u043e\u043b\u0435\u0432\u0441\u0442\u0432\u043e \u0422\u043e\u043d\u0433\u0430",
        common: "\u0422\u043e\u043d\u0433\u0430",
      },
      slk: { official: "Tongsk\u00e9 kr\u00e1\u013eovstvo", common: "Tonga" },
      spa: { official: "Reino de Tonga", common: "Tonga" },
      swe: { official: "Konungariket Tonga", common: "Tonga" },
      urd: {
        official:
          "\u0645\u0645\u0644\u06a9\u062a\u0650 \u0679\u0648\u0646\u06af\u0627",
        common: "\u0679\u0648\u0646\u06af\u0627",
      },
      zho: { official: "\u6c64\u52a0\u738b\u56fd", common: "\u6c64\u52a0" },
    },
    latlng: [-20, -175],
    landlocked: false,
    borders: [],
    area: 747,
    flag: "\ud83c\uddf9\ud83c\uddf4",
    demonyms: {
      eng: { f: "Tongan", m: "Tongan" },
      fra: { f: "Tonguienne", m: "Tonguien" },
    },
    callingCodes: ["+676"],
  },
  {
    name: {
      common: "Trinidad and Tobago",
      official: "Republic of Trinidad and Tobago",
      native: {
        eng: {
          official: "Republic of Trinidad and Tobago",
          common: "Trinidad and Tobago",
        },
      },
    },
    tld: [".tt"],
    cca2: "TT",
    ccn3: "780",
    cca3: "TTO",
    cioc: "TTO",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { TTD: { name: "Trinidad and Tobago dollar", symbol: "$" } },
    idd: { root: "+1", suffixes: ["868"] },
    capital: ["Port of Spain"],
    altSpellings: ["TT", "Republic of Trinidad and Tobago"],
    region: "Americas",
    subregion: "Caribbean",
    languages: { eng: "English" },
    translations: {
      ces: {
        official: "Republika Trinidad a Tobago",
        common: "Trinidad a Tobago",
      },
      deu: {
        official: "Republik Trinidad und Tobago",
        common: "Trinidad und Tobago",
      },
      est: {
        official: "Trinidadi ja Tobago Vabariik",
        common: "Trinidad ja Tobago",
      },
      fin: {
        official: "Trinidadin ja Tobagon tasavalta",
        common: "Trinidad ja Tobago",
      },
      fra: {
        official: "R\u00e9publique de Trinit\u00e9-et-Tobago",
        common: "Trinit\u00e9-et-Tobago",
      },
      hrv: {
        official: "Republika Trinidad i Tobago",
        common: "Trinidad i Tobago",
      },
      hun: {
        official: "Trinidad \u00e9s Tobago K\u00f6zt\u00e1rsas\u00e1g",
        common: "Trinidad \u00e9s Tobago",
      },
      ita: {
        official: "Repubblica di Trinidad e Tobago",
        common: "Trinidad e Tobago",
      },
      jpn: {
        official:
          "\u30c8\u30ea\u30cb\u30c0\u30fc\u30c9\u00b7\u30c8\u30d0\u30b4\u5171\u548c\u56fd",
        common: "\u30c8\u30ea\u30cb\u30c0\u30fc\u30c9\u30fb\u30c8\u30d0\u30b4",
      },
      kor: {
        official:
          "\ud2b8\ub9ac\ub2c8\ub2e4\ub4dc \ud1a0\ubc14\uace0 \uacf5\ud654\uad6d",
        common: "\ud2b8\ub9ac\ub2c8\ub2e4\ub4dc \ud1a0\ubc14\uace0",
      },
      nld: {
        official: "Republiek Trinidad en Tobago",
        common: "Trinidad en Tobago",
      },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u062a\u0631\u06cc\u0646\u06cc\u062f\u0627\u062f \u0648 \u062a\u0648\u0628\u0627\u06af\u0648",
        common:
          "\u062a\u0631\u06cc\u0646\u06cc\u062f\u0627\u062f \u0648 \u062a\u0648\u0628\u0627\u06af\u0648",
      },
      pol: { official: "Trynidad i Tobago", common: "Trynidad i Tobago" },
      por: {
        official: "Rep\u00fablica de Trinidad e Tobago",
        common: "Trinidade e Tobago",
      },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0422\u0440\u0438\u043d\u0438\u0434\u0430\u0434 \u0438 \u0422\u043e\u0431\u0430\u0433\u043e",
        common:
          "\u0422\u0440\u0438\u043d\u0438\u0434\u0430\u0434 \u0438 \u0422\u043e\u0431\u0430\u0433\u043e",
      },
      slk: {
        official: "Republika Trinidad a Tobaga",
        common: "Trinidad a Tobago",
      },
      spa: {
        official: "Rep\u00fablica de Trinidad y Tobago",
        common: "Trinidad y Tobago",
      },
      swe: {
        official: "Republiken Trinidad och Tobago",
        common: "Trinidad och Tobago",
      },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0679\u0631\u06cc\u0646\u06cc\u0688\u0627\u0688 \u0648 \u0679\u0648\u0628\u0627\u06af\u0648",
        common:
          "\u0679\u0631\u06cc\u0646\u06cc\u0688\u0627\u0688 \u0648 \u0679\u0648\u0628\u0627\u06af\u0648",
      },
      zho: {
        official:
          "\u7279\u7acb\u5c3c\u8fbe\u548c\u591a\u5df4\u54e5\u5171\u548c\u56fd",
        common: "\u7279\u7acb\u5c3c\u8fbe\u548c\u591a\u5df4\u54e5",
      },
    },
    latlng: [11, -61],
    landlocked: false,
    borders: [],
    area: 5130,
    flag: "\ud83c\uddf9\ud83c\uddf9",
    demonyms: {
      eng: { f: "Trinidadian", m: "Trinidadian" },
      fra: { f: "Trinidadienne", m: "Trinidadien" },
    },
    callingCodes: ["+1868"],
  },
  {
    name: {
      common: "Tunisia",
      official: "Tunisian Republic",
      native: {
        ara: {
          official:
            "\u0627\u0644\u062c\u0645\u0647\u0648\u0631\u064a\u0629 \u0627\u0644\u062a\u0648\u0646\u0633\u064a\u0629",
          common: "\u062a\u0648\u0646\u0633",
        },
      },
    },
    tld: [".tn"],
    cca2: "TN",
    ccn3: "788",
    cca3: "TUN",
    cioc: "TUN",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { TND: { name: "Tunisian dinar", symbol: "\u062f.\u062a" } },
    idd: { root: "+2", suffixes: ["16"] },
    capital: ["Tunis"],
    altSpellings: [
      "TN",
      "Republic of Tunisia",
      "al-Jumh\u016briyyah at-T\u016bnisiyyah",
    ],
    region: "Africa",
    subregion: "Northern Africa",
    languages: { ara: "Arabic" },
    translations: {
      ces: { official: "Tunisk\u00e1 republika", common: "Tunisko" },
      deu: { official: "Tunesische Republik", common: "Tunesien" },
      est: { official: "Tuneesia Vabariik", common: "Tuneesia" },
      fin: { official: "Tunisian tasavalta", common: "Tunisia" },
      fra: { official: "R\u00e9publique tunisienne", common: "Tunisie" },
      hrv: { official: "Tuniski Republika", common: "Tunis" },
      hun: {
        official: "Tun\u00e9ziai K\u00f6zt\u00e1rsas\u00e1g",
        common: "Tun\u00e9zia",
      },
      ita: { official: "Repubblica tunisina", common: "Tunisia" },
      jpn: {
        official: "\u30c1\u30e5\u30cb\u30b8\u30a2\u5171\u548c\u56fd",
        common: "\u30c1\u30e5\u30cb\u30b8\u30a2",
      },
      kor: {
        official: "\ud280\ub2c8\uc9c0 \uacf5\ud654\uad6d",
        common: "\ud280\ub2c8\uc9c0",
      },
      nld: { official: "Republiek Tunesi\u00eb", common: "Tunesi\u00eb" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u062a\u0648\u0646\u0633",
        common: "\u062a\u0648\u0646\u0633",
      },
      pol: { official: "Republika Tunezyjska", common: "Tunezja" },
      por: {
        official: "Rep\u00fablica da Tun\u00edsia",
        common: "Tun\u00edsia",
      },
      rus: {
        official:
          "\u0422\u0443\u043d\u0438\u0441\u0441\u043a\u043e\u0439 \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0438",
        common: "\u0422\u0443\u043d\u0438\u0441",
      },
      slk: { official: "Tunisk\u00e1 republika", common: "Tunisko" },
      spa: { official: "Rep\u00fablica de T\u00fanez", common: "T\u00fanez" },
      swe: { official: "Republiken Tunisien", common: "Tunisien" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u062a\u0648\u0646\u0633",
        common: "\u062a\u0648\u0646\u0633",
      },
      zho: {
        official: "\u7a81\u5c3c\u65af\u5171\u548c\u56fd",
        common: "\u7a81\u5c3c\u65af",
      },
    },
    latlng: [34, 9],
    landlocked: false,
    borders: ["DZA", "LBY"],
    area: 163610,
    flag: "\ud83c\uddf9\ud83c\uddf3",
    demonyms: {
      eng: { f: "Tunisian", m: "Tunisian" },
      fra: { f: "Tunisienne", m: "Tunisien" },
    },
    callingCodes: ["+216"],
  },
  {
    name: {
      common: "Turkey",
      official: "Republic of Turkey",
      native: {
        tur: { official: "T\u00fcrkiye Cumhuriyeti", common: "T\u00fcrkiye" },
      },
    },
    tld: [".tr"],
    cca2: "TR",
    ccn3: "792",
    cca3: "TUR",
    cioc: "TUR",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { TRY: { name: "Turkish lira", symbol: "\u20ba" } },
    idd: { root: "+9", suffixes: ["0"] },
    capital: ["Ankara"],
    altSpellings: [
      "TR",
      "Turkiye",
      "Republic of Turkey",
      "T\u00fcrkiye Cumhuriyeti",
    ],
    region: "Asia",
    subregion: "Western Asia",
    languages: { tur: "Turkish" },
    translations: {
      ces: { official: "Tureck\u00e1 republika", common: "Turecko" },
      deu: { official: "Republik T\u00fcrkei", common: "T\u00fcrkei" },
      est: { official: "T\u00fcrgi Vabariik", common: "T\u00fcrgi" },
      fin: { official: "Turkin tasavalta", common: "Turkki" },
      fra: { official: "R\u00e9publique de Turquie", common: "Turquie" },
      hrv: { official: "Republika Turska", common: "Turska" },
      hun: {
        official: "T\u00f6r\u00f6k K\u00f6zt\u00e1rsas\u00e1g",
        common: "T\u00f6r\u00f6korsz\u00e1g",
      },
      ita: { official: "Repubblica di Turchia", common: "Turchia" },
      jpn: {
        official: "\u30c8\u30eb\u30b3\u5171\u548c\u56fd",
        common: "\u30c8\u30eb\u30b3",
      },
      kor: {
        official: "\ud130\ud0a4 \uacf5\ud654\uad6d",
        common: "\ud130\ud0a4",
      },
      nld: { official: "Republiek Turkije", common: "Turkije" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u062a\u0631\u06a9\u06cc\u0647",
        common: "\u062a\u0631\u06a9\u06cc\u0647",
      },
      pol: { official: "Republika Turcji", common: "Turcja" },
      por: { official: "Rep\u00fablica da Turquia", common: "Turquia" },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0422\u0443\u0440\u0446\u0438\u0438",
        common: "\u0422\u0443\u0440\u0446\u0438\u044f",
      },
      slk: { official: "Tureck\u00e1 republika", common: "Turecko" },
      spa: {
        official: "Rep\u00fablica de Turqu\u00eda",
        common: "Turqu\u00eda",
      },
      swe: { official: "Republiken Turkiet", common: "Turkiet" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u062a\u0631\u06a9\u06cc",
        common: "\u062a\u0631\u06a9\u06cc",
      },
      zho: {
        official: "\u571f\u8033\u5176\u5171\u548c\u56fd",
        common: "\u571f\u8033\u5176",
      },
    },
    latlng: [39, 35],
    landlocked: false,
    borders: ["ARM", "AZE", "BGR", "GEO", "GRC", "IRN", "IRQ", "SYR"],
    area: 783562,
    flag: "\ud83c\uddf9\ud83c\uddf7",
    demonyms: {
      eng: { f: "Turkish", m: "Turkish" },
      fra: { f: "Turque", m: "Turc" },
    },
    callingCodes: ["+90"],
  },
  {
    name: {
      common: "Tuvalu",
      official: "Tuvalu",
      native: {
        eng: { official: "Tuvalu", common: "Tuvalu" },
        tvl: { official: "Tuvalu", common: "Tuvalu" },
      },
    },
    tld: [".tv"],
    cca2: "TV",
    ccn3: "798",
    cca3: "TUV",
    cioc: "TUV",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: {
      AUD: { name: "Australian dollar", symbol: "$" },
      TVD: { name: "Tuvaluan dollar", symbol: "$" },
    },
    idd: { root: "+6", suffixes: ["88"] },
    capital: ["Funafuti"],
    altSpellings: ["TV"],
    region: "Oceania",
    subregion: "Polynesia",
    languages: { eng: "English", tvl: "Tuvaluan" },
    translations: {
      ces: { official: "Tuvalu", common: "Tuvalu" },
      deu: { official: "Tuvalu", common: "Tuvalu" },
      est: { official: "Tuvalu", common: "Tuvalu" },
      fin: { official: "Tuvalu", common: "Tuvalu" },
      fra: { official: "Tuvalu", common: "Tuvalu" },
      hrv: { official: "Tuvalu", common: "Tuvalu" },
      hun: { official: "Tuvalu", common: "Tuvalu" },
      ita: { official: "Tuvalu", common: "Tuvalu" },
      jpn: { official: "\u30c4\u30d0\u30eb", common: "\u30c4\u30d0\u30eb" },
      kor: { official: "\ud22c\ubc1c\ub8e8", common: "\ud22c\ubc1c\ub8e8" },
      nld: { official: "Tuvalu", common: "Tuvalu" },
      per: {
        official: "\u062a\u0648\u0648\u0627\u0644\u0648",
        common: "\u062a\u0648\u0648\u0627\u0644\u0648",
      },
      pol: { official: "Tuvalu", common: "Tuvalu" },
      por: { official: "Tuvalu", common: "Tuvalu" },
      rus: {
        official: "\u0422\u0443\u0432\u0430\u043b\u0443",
        common: "\u0422\u0443\u0432\u0430\u043b\u0443",
      },
      slk: { official: "Tuvalu", common: "Tuvalu" },
      spa: { official: "Tuvalu", common: "Tuvalu" },
      swe: { official: "Tuvalu", common: "Tuvalu" },
      urd: {
        official: "\u062a\u0648\u0648\u0627\u0644\u0648",
        common: "\u062a\u0648\u0648\u0627\u0644\u0648",
      },
      zho: { official: "\u56fe\u74e6\u5362", common: "\u56fe\u74e6\u5362" },
    },
    latlng: [-8, 178],
    landlocked: false,
    borders: [],
    area: 26,
    flag: "\ud83c\uddf9\ud83c\uddfb",
    demonyms: {
      eng: { f: "Tuvaluan", m: "Tuvaluan" },
      fra: { f: "Tuvaluane", m: "Tuvaluan" },
    },
    callingCodes: ["+688"],
  },
  {
    name: {
      common: "Taiwan",
      official: "Republic of China (Taiwan)",
      native: {
        zho: { official: "\u4e2d\u83ef\u6c11\u570b", common: "\u53f0\u7063" },
      },
    },
    tld: [".tw", ".\u53f0\u7063", ".\u53f0\u6e7e"],
    cca2: "TW",
    ccn3: "158",
    cca3: "TWN",
    cioc: "TPE",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: { TWD: { name: "New Taiwan dollar", symbol: "$" } },
    idd: { root: "+8", suffixes: ["86"] },
    capital: ["Taipei"],
    altSpellings: [
      "TW",
      "T\u00e1iw\u0101n",
      "Republic of China",
      "\u4e2d\u83ef\u6c11\u570b",
      "Zh\u014dnghu\u00e1 M\u00edngu\u00f3",
      "Chinese Taipei",
    ],
    region: "Asia",
    subregion: "Eastern Asia",
    languages: { zho: "Chinese" },
    translations: {
      ces: { official: "\u010c\u00ednsk\u00e1 republika", common: "Tchaj-wan" },
      deu: { official: "Republik China (Taiwan)", common: "Taiwan" },
      est: { official: "Taiwani", common: "Taiwan" },
      fin: { official: "Kiinan tasavalta", common: "Taiwan" },
      fra: {
        official: "R\u00e9publique de Chine (Ta\u00efwan)",
        common: "Ta\u00efwan",
      },
      hrv: { official: "Republika Kina", common: "Tajvan" },
      hun: {
        official: "K\u00ednai K\u00f6zt\u00e1rsas\u00e1g",
        common: "Tajvan",
      },
      ita: { official: "Repubblica cinese (Taiwan)", common: "Taiwan" },
      jpn: { official: "\u4e2d\u83ef\u6c11\u56fd", common: "\u53f0\u6e7e" },
      kor: { official: "\uc911\ud654\ubbfc\uad6d", common: "\ub300\ub9cc" },
      nld: { official: "Republiek China (Taiwan)", common: "Taiwan" },
      per: {
        official: "\u062c\u0645\u0647\u0648\u0631\u06cc \u0686\u06cc\u0646",
        common: "\u062a\u0627\u06cc\u0648\u0627\u0646",
      },
      pol: { official: "Republika Chi\u0144ska (Tajwan)", common: "Tajwan" },
      por: { official: "Rep\u00fablica da China", common: "Ilha Formosa" },
      rus: {
        official:
          "\u041a\u0438\u0442\u0430\u0439\u0441\u043a\u0430\u044f \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430",
        common: "\u0422\u0430\u0439\u0432\u0430\u043d\u044c",
      },
      slk: { official: "\u010c\u00ednska republika", common: "Taiwan" },
      spa: {
        official: "Rep\u00fablica de China en Taiw\u00e1n",
        common: "Taiw\u00e1n",
      },
      swe: { official: "Republiken Kina", common: "Taiwan" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0686\u06cc\u0646 (\u062a\u0627\u0626\u06cc\u0648\u0627\u0646)",
        common: "\u062a\u0627\u0626\u06cc\u0648\u0627\u0646",
      },
    },
    latlng: [23.5, 121],
    landlocked: false,
    borders: [],
    area: 36193,
    flag: "\ud83c\uddf9\ud83c\uddfc",
    demonyms: {
      eng: { f: "Taiwanese", m: "Taiwanese" },
      fra: { f: "Ta\u00efwanaise", m: "Ta\u00efwanais" },
    },
    callingCodes: ["+886"],
  },
  {
    name: {
      common: "Tanzania",
      official: "United Republic of Tanzania",
      native: {
        eng: { official: "United Republic of Tanzania", common: "Tanzania" },
        swa: {
          official: "Jamhuri ya Muungano wa Tanzania",
          common: "Tanzania",
        },
      },
    },
    tld: [".tz"],
    cca2: "TZ",
    ccn3: "834",
    cca3: "TZA",
    cioc: "TAN",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { TZS: { name: "Tanzanian shilling", symbol: "Sh" } },
    idd: { root: "+2", suffixes: ["55"] },
    capital: ["Dodoma"],
    altSpellings: [
      "TZ",
      "Tanzania, United Republic of",
      "United Republic of Tanzania",
      "Jamhuri ya Muungano wa Tanzania",
    ],
    region: "Africa",
    subregion: "Eastern Africa",
    languages: { eng: "English", swa: "Swahili" },
    translations: {
      ces: {
        official: "Sjednocen\u00e1 tanzansk\u00e1 republika",
        common: "Tanzanie",
      },
      deu: { official: "Vereinigte Republik Tansania", common: "Tansania" },
      est: { official: "Tansaania \u00dchendvabariik", common: "Tansaania" },
      fin: { official: "Tansanian yhdistynyt tasavalta", common: "Tansania" },
      fra: {
        official: "R\u00e9publique -Unie de Tanzanie",
        common: "Tanzanie",
      },
      hrv: { official: "Ujedinjena Republika Tanzanija", common: "Tanzanija" },
      hun: {
        official: "T\u00e1dzsik K\u00f6zt\u00e1rsas\u00e1g",
        common: "T\u00e1dzsikiszt\u00e1n",
      },
      ita: { official: "Repubblica Unita di Tanzania", common: "Tanzania" },
      jpn: {
        official:
          "\u30bf\u30f3\u30b6\u30cb\u30a2\u9023\u5408\u5171\u548c\u56fd",
        common: "\u30bf\u30f3\u30b6\u30cb\u30a2",
      },
      kor: {
        official: "\ud0c4\uc790\ub2c8\uc544 \uc5f0\ud569 \uacf5\ud654\uad6d",
        common: "\ud0c4\uc790\ub2c8\uc544",
      },
      nld: { official: "Verenigde Republiek Tanzania", common: "Tanzania" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0645\u062a\u062d\u062f \u062a\u0627\u0646\u0632\u0627\u0646\u06cc\u0627",
        common: "\u062a\u0627\u0646\u0632\u0627\u0646\u06cc\u0627",
      },
      pol: { official: "Zjednoczona Republika Tanzanii", common: "Tanzania" },
      por: {
        official: "Rep\u00fablica Unida da Tanz\u00e2nia",
        common: "Tanz\u00e2nia",
      },
      rus: {
        official:
          "\u041e\u0431\u044a\u0435\u0434\u0438\u043d\u0435\u043d\u043d\u0430\u044f \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0422\u0430\u043d\u0437\u0430\u043d\u0438\u044f",
        common: "\u0422\u0430\u043d\u0437\u0430\u043d\u0438\u044f",
      },
      slk: {
        official: "Tanz\u00e1nijsk\u00e1 zjednoten\u00e1 republika",
        common: "Tanz\u00e1nia",
      },
      spa: { official: "Rep\u00fablica Unida de Tanzania", common: "Tanzania" },
      swe: {
        official: "F\u00f6renade republiken Tanzania",
        common: "Tanzania",
      },
      urd: {
        official:
          "\u0645\u062a\u062d\u062f\u06c1 \u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u062a\u0646\u0632\u0627\u0646\u06cc\u06c1",
        common: "\u062a\u0646\u0632\u0627\u0646\u06cc\u06c1",
      },
      zho: {
        official: "\u5766\u6851\u5c3c\u4e9a\u8054\u5408\u5171\u548c\u56fd",
        common: "\u5766\u6851\u5c3c\u4e9a",
      },
    },
    latlng: [-6, 35],
    landlocked: false,
    borders: ["BDI", "COD", "KEN", "MWI", "MOZ", "RWA", "UGA", "ZMB"],
    area: 945087,
    flag: "\ud83c\uddf9\ud83c\uddff",
    demonyms: {
      eng: { f: "Tanzanian", m: "Tanzanian" },
      fra: { f: "Tanzanienne", m: "Tanzanien" },
    },
    callingCodes: ["+255"],
  },
  {
    name: {
      common: "Uganda",
      official: "Republic of Uganda",
      native: {
        eng: { official: "Republic of Uganda", common: "Uganda" },
        swa: { official: "Republic of Uganda", common: "Uganda" },
      },
    },
    tld: [".ug"],
    cca2: "UG",
    ccn3: "800",
    cca3: "UGA",
    cioc: "UGA",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { UGX: { name: "Ugandan shilling", symbol: "Sh" } },
    idd: { root: "+2", suffixes: ["56"] },
    capital: ["Kampala"],
    altSpellings: ["UG", "Republic of Uganda", "Jamhuri ya Uganda"],
    region: "Africa",
    subregion: "Eastern Africa",
    languages: { eng: "English", swa: "Swahili" },
    translations: {
      ces: { official: "Ugandsk\u00e1 republika", common: "Uganda" },
      deu: { official: "Republik Uganda", common: "Uganda" },
      est: { official: "Uganda Vabariik", common: "Uganda" },
      fin: { official: "Ugandan tasavalta", common: "Uganda" },
      fra: { official: "R\u00e9publique de l'Ouganda", common: "Ouganda" },
      hrv: { official: "Republika Uganda", common: "Uganda" },
      hun: { official: "Ugandai K\u00f6zt\u00e1rsas\u00e1g", common: "Uganda" },
      ita: { official: "Repubblica di Uganda", common: "Uganda" },
      jpn: {
        official: "\u30a6\u30ac\u30f3\u30c0\u5171\u548c\u56fd",
        common: "\u30a6\u30ac\u30f3\u30c0",
      },
      kor: {
        official: "\uc6b0\uac04\ub2e4 \uacf5\ud654\uad6d",
        common: "\uc6b0\uac04\ub2e4",
      },
      nld: { official: "Republiek Uganda", common: "Oeganda" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0627\u0648\u06af\u0627\u0646\u062f\u0627",
        common: "\u0627\u0648\u06af\u0627\u0646\u062f\u0627",
      },
      pol: { official: "Republika Ugandy", common: "Uganda" },
      por: { official: "Rep\u00fablica do Uganda", common: "Uganda" },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0423\u0433\u0430\u043d\u0434\u0430",
        common: "\u0423\u0433\u0430\u043d\u0434\u0430",
      },
      slk: { official: "Ugandsk\u00e1 republika", common: "Uganda" },
      spa: { official: "Rep\u00fablica de Uganda", common: "Uganda" },
      swe: { official: "Republiken Uganda", common: "Uganda" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u06cc\u0648\u06af\u0646\u0688\u0627",
        common: "\u06cc\u0648\u06af\u0646\u0688\u0627",
      },
      zho: {
        official: "\u4e4c\u5e72\u8fbe\u5171\u548c\u56fd",
        common: "\u4e4c\u5e72\u8fbe",
      },
    },
    latlng: [1, 32],
    landlocked: true,
    borders: ["COD", "KEN", "RWA", "SSD", "TZA"],
    area: 241550,
    flag: "\ud83c\uddfa\ud83c\uddec",
    demonyms: {
      eng: { f: "Ugandan", m: "Ugandan" },
      fra: { f: "Ougandaise", m: "Ougandais" },
    },
    callingCodes: ["+256"],
  },
  {
    name: {
      common: "Ukraine",
      official: "Ukraine",
      native: {
        ukr: {
          official: "\u0423\u043a\u0440\u0430\u0457\u043d\u0430",
          common: "\u0423\u043a\u0440\u0430\u0457\u043d\u0430",
        },
      },
    },
    tld: [".ua", ".\u0443\u043a\u0440"],
    cca2: "UA",
    ccn3: "804",
    cca3: "UKR",
    cioc: "UKR",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { UAH: { name: "Ukrainian hryvnia", symbol: "\u20b4" } },
    idd: { root: "+3", suffixes: ["80"] },
    capital: ["Kyiv"],
    altSpellings: ["UA", "Ukrayina"],
    region: "Europe",
    subregion: "Eastern Europe",
    languages: { ukr: "Ukrainian" },
    translations: {
      ces: { official: "Ukrajina", common: "Ukrajina" },
      deu: { official: "Ukraine", common: "Ukraine" },
      est: { official: "Ukraina", common: "Ukraina" },
      fin: { official: "Ukraina", common: "Ukraina" },
      fra: { official: "Ukraine", common: "Ukraine" },
      hrv: { official: "Ukrajina", common: "Ukrajina" },
      hun: { official: "Ukrajna", common: "Ukrajna" },
      ita: { official: "Ucraina", common: "Ucraina" },
      jpn: {
        official: "\u30a6\u30af\u30e9\u30a4\u30ca",
        common: "\u30a6\u30af\u30e9\u30a4\u30ca",
      },
      kor: {
        official: "\uc6b0\ud06c\ub77c\uc774\ub098",
        common: "\uc6b0\ud06c\ub77c\uc774\ub098",
      },
      nld: { official: "Oekra\u00efne", common: "Oekra\u00efne" },
      per: {
        official: "\u0627\u0648\u06a9\u0631\u0627\u06cc\u0646",
        common: "\u0627\u0648\u06a9\u0631\u0627\u06cc\u0646",
      },
      pol: { official: "Ukraina", common: "Ukraina" },
      por: { official: "Ucr\u00e2nia", common: "Ucr\u00e2nia" },
      rus: {
        official: "\u0423\u043a\u0440\u0430\u0438\u043d\u0430",
        common: "\u0423\u043a\u0440\u0430\u0438\u043d\u0430",
      },
      slk: { official: "Ukrajina", common: "Ukrajina" },
      spa: { official: "Ucrania", common: "Ucrania" },
      swe: { official: "Ukraina", common: "Ukraina" },
      urd: {
        official: "\u06cc\u0648\u06a9\u0631\u06cc\u0646",
        common: "\u06cc\u0648\u06a9\u0631\u06cc\u0646",
      },
      zho: { official: "\u4e4c\u514b\u5170", common: "\u4e4c\u514b\u5170" },
    },
    latlng: [49, 32],
    landlocked: false,
    borders: ["BLR", "HUN", "MDA", "POL", "ROU", "RUS", "SVK"],
    area: 603500,
    flag: "\ud83c\uddfa\ud83c\udde6",
    demonyms: {
      eng: { f: "Ukrainian", m: "Ukrainian" },
      fra: { f: "Ukrainienne", m: "Ukrainien" },
    },
    callingCodes: ["+380"],
  },
  {
    name: {
      common: "United States Minor Outlying Islands",
      official: "United States Minor Outlying Islands",
      native: {
        eng: {
          official: "United States Minor Outlying Islands",
          common: "United States Minor Outlying Islands",
        },
      },
    },
    tld: [".us"],
    cca2: "UM",
    ccn3: "581",
    cca3: "UMI",
    cioc: "",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: { USD: { name: "United States dollar", symbol: "$" } },
    idd: { root: "+2", suffixes: ["68"] },
    capital: [""],
    altSpellings: ["UM"],
    region: "Americas",
    subregion: "North America",
    languages: { eng: "English" },
    translations: {
      ces: {
        official:
          "Men\u0161\u00ed odlehl\u00e9 ostrovy Spojen\u00fdch st\u00e1t\u016f americk\u00fdch",
        common: "Men\u0161\u00ed odlehl\u00e9 ostrovy USA",
      },
      deu: {
        official: "USA, kleinere ausgelagerte Inseln",
        common: "Kleinere Inselbesitzungen der Vereinigten Staaten",
      },
      est: {
        official: "\u00dchendriikide v\u00e4ikesed hajasaared",
        common: "\u00dchendriikide hajasaared",
      },
      fin: {
        official: "Yhdysvaltain asumattomat saaret",
        common: "Yhdysvaltain asumattomat saaret",
      },
      fra: {
        official: "\u00celes mineures \u00e9loign\u00e9es des \u00c9tats-Unis",
        common: "\u00celes mineures \u00e9loign\u00e9es des \u00c9tats-Unis",
      },
      hrv: {
        official: "Mali udaljeni otoci SAD-a",
        common: "Mali udaljeni otoci SAD-a",
      },
      hun: {
        official:
          "Az Amerikai Egyes\u00fclt \u00c1llamok lakatlan k\u00fclbirtokai",
        common:
          "Az Amerikai Egyes\u00fclt \u00c1llamok lakatlan k\u00fclbirtokai",
      },
      ita: {
        official: "Stati Uniti Isole Minori",
        common: "Isole minori esterne degli Stati Uniti d'America",
      },
      jpn: {
        official:
          "\u30a2\u30e1\u30ea\u30ab\u5408\u8846\u56fd\u5916\u8af8\u5cf6",
        common: "\u5408\u8846\u56fd\u9818\u6709\u5c0f\u96e2\u5cf6",
      },
      kor: {
        official: "\ubbf8\uad6d\ub839 \uad70\uc18c \uc81c\ub3c4",
        common: "\ubbf8\uad6d\ub839 \uad70\uc18c \uc81c\ub3c4",
      },
      nld: {
        official: "Kleine afgelegen eilanden van de Verenigde Staten",
        common: "Kleine afgelegen eilanden van de Verenigde Staten",
      },
      per: {
        official:
          "\u062c\u0632\u0627\u06cc\u0631 \u06a9\u0648\u0686\u06a9 \u062d\u0627\u0634\u06cc\u0647\u200c\u0627\u06cc \u0627\u06cc\u0627\u0644\u0627\u062a \u0645\u062a\u062d\u062f\u0647 \u0622\u0645\u0631\u06cc\u06a9\u0627",
        common:
          "\u062c\u0632\u0627\u06cc\u0631 \u06a9\u0648\u0686\u06a9 \u062d\u0627\u0634\u06cc\u0647\u200c\u0627\u06cc \u0627\u06cc\u0627\u0644\u0627\u062a \u0645\u062a\u062d\u062f\u0647 \u0622\u0645\u0631\u06cc\u06a9\u0627",
      },
      pol: {
        official: "Dalekie Wyspy Mniejsze Stan\u00f3w Zjednoczonych",
        common: "Dalekie Wyspy Mniejsze Stan\u00f3w Zjednoczonych",
      },
      por: {
        official: "Estados Unidos Ilhas Menores Distantes",
        common: "Ilhas Menores Distantes dos Estados Unidos",
      },
      rus: {
        official:
          "\u0412\u043d\u0435\u0448\u043d\u0438\u0435 \u043c\u0430\u043b\u044b\u0435 \u043e\u0441\u0442\u0440\u043e\u0432\u0430 \u0421\u0428\u0410",
        common:
          "\u0412\u043d\u0435\u0448\u043d\u0438\u0435 \u043c\u0430\u043b\u044b\u0435 \u043e\u0441\u0442\u0440\u043e\u0432\u0430 \u0421\u0428\u0410",
      },
      slk: {
        official:
          "Men\u0161ie od\u013eahl\u00e9 ostrovy Spjoen\u00fdch \u0161t\u00e1tov",
        common: "Men\u0161ie od\u013eahl\u00e9 ostrovy USA",
      },
      spa: {
        official: "Estados Unidos Islas menores alejadas de",
        common: "Islas Ultramarinas Menores de Estados Unidos",
      },
      swe: {
        official:
          "F\u00f6renta staternas mindre \u00f6ar i Oceanien och V\u00e4stindien",
        common:
          "F\u00f6renta staternas mindre \u00f6ar i Oceanien och V\u00e4stindien",
      },
      urd: {
        official:
          "\u0627\u0645\u0631\u06cc\u06a9\u06cc \u0686\u06be\u0648\u0679\u06d2 \u0628\u06cc\u0631\u0648\u0646\u06cc \u062c\u0632\u0627\u0626\u0631",
        common:
          "\u0627\u0645\u0631\u06cc\u06a9\u06cc \u0686\u06be\u0648\u0679\u06d2 \u0628\u06cc\u0631\u0648\u0646\u06cc \u062c\u0632\u0627\u0626\u0631",
      },
      zho: {
        official: "\u7f8e\u56fd\u672c\u571f\u5916\u5c0f\u5c9b\u5c7f",
        common: "\u7f8e\u56fd\u672c\u571f\u5916\u5c0f\u5c9b\u5c7f",
      },
    },
    latlng: [19.3, 166.633333],
    landlocked: false,
    borders: [],
    area: 34.2,
    flag: "\ud83c\uddfa\ud83c\uddf2",
    demonyms: {
      eng: { f: "American Islander", m: "American Islander" },
      fra: { f: "", m: "" },
    },
    callingCodes: ["+268"],
  },
  {
    name: {
      common: "Uruguay",
      official: "Oriental Republic of Uruguay",
      native: {
        spa: {
          official: "Rep\u00fablica Oriental del Uruguay",
          common: "Uruguay",
        },
      },
    },
    tld: [".uy"],
    cca2: "UY",
    ccn3: "858",
    cca3: "URY",
    cioc: "URU",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { UYU: { name: "Uruguayan peso", symbol: "$" } },
    idd: { root: "+5", suffixes: ["98"] },
    capital: ["Montevideo"],
    altSpellings: [
      "UY",
      "Oriental Republic of Uruguay",
      "Rep\u00fablica Oriental del Uruguay",
    ],
    region: "Americas",
    subregion: "South America",
    languages: { spa: "Spanish" },
    translations: {
      ces: {
        official: "Uruguaysk\u00e1 v\u00fdchodn\u00ed republika",
        common: "Uruguay",
      },
      deu: { official: "Republik \u00d6stlich des Uruguay", common: "Uruguay" },
      est: { official: "Uruguay Idavabariik", common: "Uruguay" },
      fin: { official: "Uruguayn it\u00e4inen tasavalta", common: "Uruguay" },
      fra: {
        official: "R\u00e9publique orientale de l'Uruguay",
        common: "Uruguay",
      },
      hrv: { official: "Orijentalna Republika Urugvaj", common: "Urugvaj" },
      hun: {
        official: "Uruguayi Keleti K\u00f6zt\u00e1rsas\u00e1g",
        common: "Uruguay",
      },
      ita: { official: "Repubblica Orientale dell'Uruguay", common: "Uruguay" },
      jpn: {
        official:
          "\u30a6\u30eb\u30b0\u30a2\u30a4\u6771\u65b9\u5171\u548c\u56fd",
        common: "\u30a6\u30eb\u30b0\u30a2\u30a4",
      },
      kor: {
        official: "\uc6b0\ub8e8\uacfc\uc774 \ub3d9\ubc29 \uacf5\ud654\uad6d",
        common: "\uc6b0\ub8e8\uacfc\uc774",
      },
      nld: { official: "Oosterse Republiek Uruguay", common: "Uruguay" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0627\u0631\u0648\u06af\u0648\u0626\u0647",
        common: "\u0627\u0631\u0648\u06af\u0648\u0626\u0647",
      },
      pol: { official: "Wschodnia Republika Urugwaju", common: "Urugwaj" },
      por: {
        official: "Rep\u00fablica Oriental do Uruguai",
        common: "Uruguai",
      },
      rus: {
        official:
          "\u0412\u043e\u0441\u0442\u043e\u0447\u043d\u043e\u0439 \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0438 \u0423\u0440\u0443\u0433\u0432\u0430\u0439",
        common: "\u0423\u0440\u0443\u0433\u0432\u0430\u0439",
      },
      slk: {
        official: "Uruguajsk\u00e1 v\u00fdchodn\u00e1 republika",
        common: "Uruguaj",
      },
      spa: {
        official: "Rep\u00fablica Oriental del Uruguay",
        common: "Uruguay",
      },
      swe: { official: "Republiken Uruguay", common: "Uruguay" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0634\u0631\u0642\u06cc\u06c1 \u06cc\u0648\u0631\u0627\u06af\u0648\u0626\u06d2",
        common: "\u06cc\u0648\u0631\u0627\u06af\u0648\u0626\u06d2",
      },
      zho: {
        official: "\u4e4c\u62c9\u572d\u4e1c\u5cb8\u5171\u548c\u56fd",
        common: "\u4e4c\u62c9\u572d",
      },
    },
    latlng: [-33, -56],
    landlocked: false,
    borders: ["ARG", "BRA"],
    area: 181034,
    flag: "\ud83c\uddfa\ud83c\uddfe",
    demonyms: {
      eng: { f: "Uruguayan", m: "Uruguayan" },
      fra: { f: "Uruguayenne", m: "Uruguayen" },
    },
    callingCodes: ["+598"],
  },
  {
    name: {
      common: "United States",
      official: "United States of America",
      native: {
        eng: { official: "United States of America", common: "United States" },
      },
    },
    tld: [".us"],
    cca2: "US",
    ccn3: "840",
    cca3: "USA",
    cioc: "USA",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { USD: { name: "United States dollar", symbol: "$" } },
    idd: {
      root: "+1",
      suffixes: [
        "201",
        "202",
        "203",
        "205",
        "206",
        "207",
        "208",
        "209",
        "210",
        "212",
        "213",
        "214",
        "215",
        "216",
        "217",
        "218",
        "219",
        "220",
        "224",
        "225",
        "227",
        "228",
        "229",
        "231",
        "234",
        "239",
        "240",
        "248",
        "251",
        "252",
        "253",
        "254",
        "256",
        "260",
        "262",
        "267",
        "269",
        "270",
        "272",
        "274",
        "276",
        "281",
        "283",
        "301",
        "302",
        "303",
        "304",
        "305",
        "307",
        "308",
        "309",
        "310",
        "312",
        "313",
        "314",
        "315",
        "316",
        "317",
        "318",
        "319",
        "320",
        "321",
        "323",
        "325",
        "327",
        "330",
        "331",
        "334",
        "336",
        "337",
        "339",
        "346",
        "347",
        "351",
        "352",
        "360",
        "361",
        "364",
        "380",
        "385",
        "386",
        "401",
        "402",
        "404",
        "405",
        "406",
        "407",
        "408",
        "409",
        "410",
        "412",
        "413",
        "414",
        "415",
        "417",
        "419",
        "423",
        "424",
        "425",
        "430",
        "432",
        "434",
        "435",
        "440",
        "442",
        "443",
        "447",
        "458",
        "463",
        "464",
        "469",
        "470",
        "475",
        "478",
        "479",
        "480",
        "484",
        "501",
        "502",
        "503",
        "504",
        "505",
        "507",
        "508",
        "509",
        "510",
        "512",
        "513",
        "515",
        "516",
        "517",
        "518",
        "520",
        "530",
        "531",
        "534",
        "539",
        "540",
        "541",
        "551",
        "559",
        "561",
        "562",
        "563",
        "564",
        "567",
        "570",
        "571",
        "573",
        "574",
        "575",
        "580",
        "585",
        "586",
        "601",
        "602",
        "603",
        "605",
        "606",
        "607",
        "608",
        "609",
        "610",
        "612",
        "614",
        "615",
        "616",
        "617",
        "618",
        "619",
        "620",
        "623",
        "626",
        "628",
        "629",
        "630",
        "631",
        "636",
        "641",
        "646",
        "650",
        "651",
        "657",
        "660",
        "661",
        "662",
        "667",
        "669",
        "678",
        "681",
        "682",
        "701",
        "702",
        "703",
        "704",
        "706",
        "707",
        "708",
        "712",
        "713",
        "714",
        "715",
        "716",
        "717",
        "718",
        "719",
        "720",
        "724",
        "725",
        "727",
        "730",
        "731",
        "732",
        "734",
        "737",
        "740",
        "743",
        "747",
        "754",
        "757",
        "760",
        "762",
        "763",
        "765",
        "769",
        "770",
        "772",
        "773",
        "774",
        "775",
        "779",
        "781",
        "785",
        "786",
        "801",
        "802",
        "803",
        "804",
        "805",
        "806",
        "808",
        "810",
        "812",
        "813",
        "814",
        "815",
        "816",
        "817",
        "818",
        "828",
        "830",
        "831",
        "832",
        "843",
        "845",
        "847",
        "848",
        "850",
        "854",
        "856",
        "857",
        "858",
        "859",
        "860",
        "862",
        "863",
        "864",
        "865",
        "870",
        "872",
        "878",
        "901",
        "903",
        "904",
        "906",
        "907",
        "908",
        "909",
        "910",
        "912",
        "913",
        "914",
        "915",
        "916",
        "917",
        "918",
        "919",
        "920",
        "925",
        "928",
        "929",
        "930",
        "931",
        "934",
        "936",
        "937",
        "938",
        "940",
        "941",
        "947",
        "949",
        "951",
        "952",
        "954",
        "956",
        "959",
        "970",
        "971",
        "972",
        "973",
        "975",
        "978",
        "979",
        "980",
        "984",
        "985",
        "989",
      ],
    },
    capital: ["Washington D.C."],
    altSpellings: ["US", "USA", "United States of America"],
    region: "Americas",
    subregion: "North America",
    languages: { eng: "English" },
    translations: {
      ces: {
        official: "Spojen\u00e9 st\u00e1ty americk\u00e9",
        common: "Spojen\u00e9 st\u00e1ty",
      },
      deu: {
        official: "Vereinigte Staaten von Amerika",
        common: "Vereinigte Staaten",
      },
      est: {
        official: "Ameerika \u00dchendriigid",
        common: "Ameerika \u00dchendriigid",
      },
      fin: { official: "Amerikan yhdysvallat", common: "Yhdysvallat" },
      fra: {
        official: "Les \u00e9tats-unis d'Am\u00e9rique",
        common: "\u00c9tats-Unis",
      },
      hrv: {
        official: "Sjedinjene Dr\u017eave Amerike",
        common: "Sjedinjene Ameri\u010dke Dr\u017eave",
      },
      hun: {
        official: "Amerikai Egyes\u00fclt \u00c1llamok",
        common: "Amerikai Egyes\u00fclt \u00c1llamok",
      },
      ita: {
        official: "Stati Uniti d'America",
        common: "Stati Uniti d'America",
      },
      jpn: {
        official: "\u30a2\u30e1\u30ea\u30ab\u5408\u8846\u56fd",
        common: "\u30a2\u30e1\u30ea\u30ab\u5408\u8846\u56fd",
      },
      kor: {
        official: "\uc544\uba54\ub9ac\uce74 \ud569\uc911\uad6d",
        common: "\ubbf8\uad6d",
      },
      nld: {
        official: "Verenigde Staten van Amerika",
        common: "Verenigde Staten",
      },
      per: {
        official:
          "\u0627\u06cc\u0627\u0644\u0627\u062a \u0645\u062a\u062d\u062f\u0647 \u0622\u0645\u0631\u06cc\u06a9\u0627",
        common:
          "\u0627\u06cc\u0627\u0644\u0627\u062a \u0645\u062a\u062d\u062f\u0647 \u0622\u0645\u0631\u06cc\u06a9\u0627",
      },
      pol: {
        official: "Stany Zjednoczone Ameryki",
        common: "Stany Zjednoczone",
      },
      por: {
        official: "Estados Unidos da Am\u00e9rica",
        common: "Estados Unidos",
      },
      rus: {
        official:
          "\u0421\u043e\u0435\u0434\u0438\u043d\u0435\u043d\u043d\u044b\u0435 \u0428\u0442\u0430\u0442\u044b \u0410\u043c\u0435\u0440\u0438\u043a\u0438",
        common:
          "\u0421\u043e\u0435\u0434\u0438\u043d\u0451\u043d\u043d\u044b\u0435 \u0428\u0442\u0430\u0442\u044b \u0410\u043c\u0435\u0440\u0438\u043a\u0438",
      },
      slk: {
        official: "Spojen\u00e9 \u0161t\u00e1ty Americk\u00e9",
        common: "Spojen\u00e9 \u0161t\u00e1ty americk\u00e9",
      },
      spa: {
        official: "Estados Unidos de Am\u00e9rica",
        common: "Estados Unidos",
      },
      swe: { official: "Amerikas f\u00f6renta stater", common: "USA" },
      urd: {
        official:
          "\u0631\u06cc\u0627\u0633\u062a\u06c1\u0627\u0626\u06d2 \u0645\u062a\u062d\u062f\u06c1 \u0627\u0645\u0631\u06cc\u06a9\u0627",
        common:
          "\u0631\u06cc\u0627\u0633\u062a\u06c1\u0627\u0626\u06d2 \u0645\u062a\u062d\u062f\u06c1",
      },
      zho: {
        official: "\u7f8e\u5229\u575a\u5408\u4f17\u56fd",
        common: "\u7f8e\u56fd",
      },
    },
    latlng: [38, -97],
    landlocked: false,
    borders: ["CAN", "MEX"],
    area: 9372610,
    flag: "\ud83c\uddfa\ud83c\uddf8",
    demonyms: {
      eng: { f: "American", m: "American" },
      fra: { f: "Am\u00e9ricaine", m: "Am\u00e9ricain" },
    },
    callingCodes: [
      "+1201",
      "+1202",
      "+1203",
      "+1205",
      "+1206",
      "+1207",
      "+1208",
      "+1209",
      "+1210",
      "+1212",
      "+1213",
      "+1214",
      "+1215",
      "+1216",
      "+1217",
      "+1218",
      "+1219",
      "+1220",
      "+1224",
      "+1225",
      "+1227",
      "+1228",
      "+1229",
      "+1231",
      "+1234",
      "+1239",
      "+1240",
      "+1248",
      "+1251",
      "+1252",
      "+1253",
      "+1254",
      "+1256",
      "+1260",
      "+1262",
      "+1267",
      "+1269",
      "+1270",
      "+1272",
      "+1274",
      "+1276",
      "+1281",
      "+1283",
      "+1301",
      "+1302",
      "+1303",
      "+1304",
      "+1305",
      "+1307",
      "+1308",
      "+1309",
      "+1310",
      "+1312",
      "+1313",
      "+1314",
      "+1315",
      "+1316",
      "+1317",
      "+1318",
      "+1319",
      "+1320",
      "+1321",
      "+1323",
      "+1325",
      "+1327",
      "+1330",
      "+1331",
      "+1334",
      "+1336",
      "+1337",
      "+1339",
      "+1346",
      "+1347",
      "+1351",
      "+1352",
      "+1360",
      "+1361",
      "+1364",
      "+1380",
      "+1385",
      "+1386",
      "+1401",
      "+1402",
      "+1404",
      "+1405",
      "+1406",
      "+1407",
      "+1408",
      "+1409",
      "+1410",
      "+1412",
      "+1413",
      "+1414",
      "+1415",
      "+1417",
      "+1419",
      "+1423",
      "+1424",
      "+1425",
      "+1430",
      "+1432",
      "+1434",
      "+1435",
      "+1440",
      "+1442",
      "+1443",
      "+1447",
      "+1458",
      "+1463",
      "+1464",
      "+1469",
      "+1470",
      "+1475",
      "+1478",
      "+1479",
      "+1480",
      "+1484",
      "+1501",
      "+1502",
      "+1503",
      "+1504",
      "+1505",
      "+1507",
      "+1508",
      "+1509",
      "+1510",
      "+1512",
      "+1513",
      "+1515",
      "+1516",
      "+1517",
      "+1518",
      "+1520",
      "+1530",
      "+1531",
      "+1534",
      "+1539",
      "+1540",
      "+1541",
      "+1551",
      "+1559",
      "+1561",
      "+1562",
      "+1563",
      "+1564",
      "+1567",
      "+1570",
      "+1571",
      "+1573",
      "+1574",
      "+1575",
      "+1580",
      "+1585",
      "+1586",
      "+1601",
      "+1602",
      "+1603",
      "+1605",
      "+1606",
      "+1607",
      "+1608",
      "+1609",
      "+1610",
      "+1612",
      "+1614",
      "+1615",
      "+1616",
      "+1617",
      "+1618",
      "+1619",
      "+1620",
      "+1623",
      "+1626",
      "+1628",
      "+1629",
      "+1630",
      "+1631",
      "+1636",
      "+1641",
      "+1646",
      "+1650",
      "+1651",
      "+1657",
      "+1660",
      "+1661",
      "+1662",
      "+1667",
      "+1669",
      "+1678",
      "+1681",
      "+1682",
      "+1701",
      "+1702",
      "+1703",
      "+1704",
      "+1706",
      "+1707",
      "+1708",
      "+1712",
      "+1713",
      "+1714",
      "+1715",
      "+1716",
      "+1717",
      "+1718",
      "+1719",
      "+1720",
      "+1724",
      "+1725",
      "+1727",
      "+1730",
      "+1731",
      "+1732",
      "+1734",
      "+1737",
      "+1740",
      "+1743",
      "+1747",
      "+1754",
      "+1757",
      "+1760",
      "+1762",
      "+1763",
      "+1765",
      "+1769",
      "+1770",
      "+1772",
      "+1773",
      "+1774",
      "+1775",
      "+1779",
      "+1781",
      "+1785",
      "+1786",
      "+1801",
      "+1802",
      "+1803",
      "+1804",
      "+1805",
      "+1806",
      "+1808",
      "+1810",
      "+1812",
      "+1813",
      "+1814",
      "+1815",
      "+1816",
      "+1817",
      "+1818",
      "+1828",
      "+1830",
      "+1831",
      "+1832",
      "+1843",
      "+1845",
      "+1847",
      "+1848",
      "+1850",
      "+1854",
      "+1856",
      "+1857",
      "+1858",
      "+1859",
      "+1860",
      "+1862",
      "+1863",
      "+1864",
      "+1865",
      "+1870",
      "+1872",
      "+1878",
      "+1901",
      "+1903",
      "+1904",
      "+1906",
      "+1907",
      "+1908",
      "+1909",
      "+1910",
      "+1912",
      "+1913",
      "+1914",
      "+1915",
      "+1916",
      "+1917",
      "+1918",
      "+1919",
      "+1920",
      "+1925",
      "+1928",
      "+1929",
      "+1930",
      "+1931",
      "+1934",
      "+1936",
      "+1937",
      "+1938",
      "+1940",
      "+1941",
      "+1947",
      "+1949",
      "+1951",
      "+1952",
      "+1954",
      "+1956",
      "+1959",
      "+1970",
      "+1971",
      "+1972",
      "+1973",
      "+1975",
      "+1978",
      "+1979",
      "+1980",
      "+1984",
      "+1985",
      "+1989",
    ],
  },
  {
    name: {
      common: "Uzbekistan",
      official: "Republic of Uzbekistan",
      native: {
        rus: {
          official:
            "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0423\u0437\u0431\u0435\u043a\u0438\u0441\u0442\u0430\u043d",
          common:
            "\u0423\u0437\u0431\u0435\u043a\u0438\u0441\u0442\u0430\u043d",
        },
        uzb: {
          official: "O'zbekiston Respublikasi",
          common: "O\u2018zbekiston",
        },
      },
    },
    tld: [".uz"],
    cca2: "UZ",
    ccn3: "860",
    cca3: "UZB",
    cioc: "UZB",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { UZS: { name: "Uzbekistani so\u02bbm", symbol: "so'm" } },
    idd: { root: "+9", suffixes: ["98"] },
    capital: ["Tashkent"],
    altSpellings: [
      "UZ",
      "Republic of Uzbekistan",
      "O\u2018zbekiston Respublikasi",
      "\u040e\u0437\u0431\u0435\u043a\u0438\u0441\u0442\u043e\u043d \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430\u0441\u0438",
    ],
    region: "Asia",
    subregion: "Central Asia",
    languages: { rus: "Russian", uzb: "Uzbek" },
    translations: {
      ces: { official: "Republika Uzbekist\u00e1n", common: "Uzbekist\u00e1n" },
      deu: { official: "Republik Usbekistan", common: "Usbekistan" },
      est: { official: "Usbekistani Vabariik", common: "Usbekistan" },
      fin: { official: "Uzbekistanin tasavalta", common: "Uzbekistan" },
      fra: {
        official: "R\u00e9publique d'Ouzb\u00e9kistan",
        common: "Ouzb\u00e9kistan",
      },
      hrv: { official: "Republika Uzbekistan", common: "Uzbekistan" },
      hun: {
        official: "\u00dczb\u00e9g K\u00f6zt\u00e1rsas\u00e1g",
        common: "\u00dczbegiszt\u00e1n",
      },
      ita: { official: "Repubblica di Uzbekistan", common: "Uzbekistan" },
      jpn: {
        official:
          "\u30a6\u30ba\u30d9\u30ad\u30b9\u30bf\u30f3\u5171\u548c\u56fd",
        common: "\u30a6\u30ba\u30d9\u30ad\u30b9\u30bf\u30f3",
      },
      kor: {
        official: "\uc6b0\uc988\ubca0\ud0a4\uc2a4\ud0c4 \uacf5\ud654\uad6d",
        common: "\uc6b0\uc988\ubca0\ud0a4\uc2a4\ud0c4",
      },
      nld: { official: "Republiek Oezbekistan", common: "Oezbekistan" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0627\u0632\u0628\u06a9\u0633\u062a\u0627\u0646",
        common: "\u0627\u0632\u0628\u06a9\u0633\u062a\u0627\u0646",
      },
      pol: { official: "Republika Uzbekistanu", common: "Uzbekistan" },
      por: {
        official: "Rep\u00fablica do Usbequist\u00e3o",
        common: "Uzbequist\u00e3o",
      },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0423\u0437\u0431\u0435\u043a\u0438\u0441\u0442\u0430\u043d",
        common: "\u0423\u0437\u0431\u0435\u043a\u0438\u0441\u0442\u0430\u043d",
      },
      slk: { official: "Uzbeck\u00e1 republika", common: "Uzbekistan" },
      spa: {
        official: "Rep\u00fablica de Uzbekist\u00e1n",
        common: "Uzbekist\u00e1n",
      },
      swe: { official: "Republiken Uzbekistan", common: "Uzbekistan" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0627\u0632\u0628\u06a9\u0633\u062a\u0627\u0646",
        common: "\u0627\u0632\u0628\u06a9\u0633\u062a\u0627\u0646",
      },
      zho: {
        official: "\u4e4c\u5179\u522b\u514b\u65af\u5766\u5171\u548c\u56fd",
        common: "\u4e4c\u5179\u522b\u514b\u65af\u5766",
      },
    },
    latlng: [41, 64],
    landlocked: true,
    borders: ["AFG", "KAZ", "KGZ", "TJK", "TKM"],
    area: 447400,
    flag: "\ud83c\uddfa\ud83c\uddff",
    demonyms: {
      eng: { f: "Uzbekistani", m: "Uzbekistani" },
      fra: { f: "Ouzb\u00e8ke", m: "Ouzb\u00e8ke" },
    },
    callingCodes: ["+998"],
  },
  {
    name: {
      common: "Vatican City",
      official: "Vatican City State",
      native: {
        ita: {
          official: "Stato della Citt\u00e0 del Vaticano",
          common: "Vaticano",
        },
        lat: {
          official: "Status Civitatis Vatican\u00e6",
          common: "Vatican\u00e6",
        },
      },
    },
    tld: [".va"],
    cca2: "VA",
    ccn3: "336",
    cca3: "VAT",
    cioc: "",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { EUR: { name: "Euro", symbol: "\u20ac" } },
    idd: { root: "+3", suffixes: ["906698", "79"] },
    capital: ["Vatican City"],
    altSpellings: [
      "VA",
      "Holy See (Vatican City State)",
      "Vatican City State",
      "Stato della Citt\u00e0 del Vaticano",
    ],
    region: "Europe",
    subregion: "Southern Europe",
    languages: { ita: "Italian", lat: "Latin" },
    translations: {
      ces: {
        official: "M\u011bstsk\u00fd st\u00e1t Vatik\u00e1n",
        common: "Vatik\u00e1n",
      },
      deu: { official: "Staat Vatikanstadt", common: "Vatikanstadt" },
      est: { official: "Vatikani Linnriik", common: "Vatikan" },
      fin: { official: "Vatikaanin kaupunkivaltio", common: "Vatikaani" },
      fra: { official: "Cit\u00e9 du Vatican", common: "Cit\u00e9 du Vatican" },
      hrv: { official: "Vatikan", common: "Vatikan" },
      hun: { official: "Vatik\u00e1n \u00c1llam", common: "Vatik\u00e1n" },
      ita: {
        official: "Citt\u00e0 del Vaticano",
        common: "Citt\u00e0 del Vaticano",
      },
      jpn: {
        official: "\u30d0\u30c1\u30ab\u30f3\u5e02\u56fd\u306e\u72b6\u614b",
        common: "\u30d0\u30c1\u30ab\u30f3\u5e02\u56fd",
      },
      kor: {
        official: "\ubc14\ud2f0\uce78 \uc2dc\uad6d",
        common: "\ubc14\ud2f0\uce78",
      },
      nld: { official: "Vaticaanstad", common: "Vaticaanstad" },
      per: {
        official:
          "\u062f\u0648\u0644\u062a\u200c\u0634\u0647\u0631 \u0648\u0627\u062a\u06cc\u06a9\u0627\u0646",
        common: "\u0648\u0627\u062a\u06cc\u06a9\u0627\u0646",
      },
      pol: { official: "Pa\u0144stwo Watyka\u0144skie", common: "Watykan" },
      por: { official: "Cidade do Vaticano", common: "Cidade do Vaticano" },
      rus: {
        official:
          "\u0413\u043e\u0440\u043e\u0434-\u0433\u043e\u0441\u0443\u0434\u0430\u0440\u0441\u0442\u0432\u043e \u0412\u0430\u0442\u0438\u043a\u0430\u043d",
        common: "\u0412\u0430\u0442\u0438\u043a\u0430\u043d",
      },
      slk: {
        official:
          "Sv\u00e4t\u00e1 stolica (Vatik\u00e1nsky mestsk\u00fd \u0161t\u00e1t",
        common: "Vatik\u00e1n",
      },
      spa: { official: "Ciudad del Vaticano", common: "Ciudad del Vaticano" },
      swe: { official: "Vatikanstaten", common: "Vatikanstaten" },
      urd: {
        official: "\u0648\u06cc\u0679\u06cc\u06a9\u0646 \u0633\u0679\u06cc",
        common: "\u0648\u06cc\u0679\u06cc\u06a9\u0646 \u0633\u0679\u06cc",
      },
      zho: {
        official: "\u68b5\u8482\u5188\u57ce\u56fd",
        common: "\u68b5\u8482\u5188",
      },
    },
    latlng: [41.9, 12.45],
    landlocked: true,
    borders: ["ITA"],
    area: 0.44,
    flag: "\ud83c\uddfb\ud83c\udde6",
    demonyms: {
      eng: { f: "Vatican", m: "Vatican" },
      fra: { f: "Vaticane", m: "Vatican" },
    },
    callingCodes: ["+3906698", "+379"],
  },
  {
    name: {
      common: "Saint Vincent and the Grenadines",
      official: "Saint Vincent and the Grenadines",
      native: {
        eng: {
          official: "Saint Vincent and the Grenadines",
          common: "Saint Vincent and the Grenadines",
        },
      },
    },
    tld: [".vc"],
    cca2: "VC",
    ccn3: "670",
    cca3: "VCT",
    cioc: "VIN",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { XCD: { name: "Eastern Caribbean dollar", symbol: "$" } },
    idd: { root: "+1", suffixes: ["784"] },
    capital: ["Kingstown"],
    altSpellings: ["VC"],
    region: "Americas",
    subregion: "Caribbean",
    languages: { eng: "English" },
    translations: {
      ces: {
        official: "Svat\u00fd Vincenc a Grenadiny",
        common: "Svat\u00fd Vincenc a Grenadiny",
      },
      deu: {
        official: "St. Vincent und die Grenadinen",
        common: "St. Vincent und die Grenadinen",
      },
      est: {
        official: "Saint Vincent ja Grenadiinid",
        common: "Saint Vincent",
      },
      fin: {
        official: "Saint Vincent ja Grenadiinit",
        common: "Saint Vincent ja Grenadiinit",
      },
      fra: {
        official: "Saint-Vincent-et-les Grenadines",
        common: "Saint-Vincent-et-les-Grenadines",
      },
      hrv: {
        official: "Sveti Vincent i Grenadini",
        common: "Sveti Vincent i Grenadini",
      },
      hun: {
        official: "Saint Vincent \u00e9s a Grenadine-szigetek",
        common: "Saint Vincent \u00e9s a Grenadine-szigetek",
      },
      ita: {
        official: "Saint Vincent e Grenadine",
        common: "Saint Vincent e Grenadine",
      },
      jpn: {
        official:
          "\u30bb\u30f3\u30c8\u30d3\u30f3\u30bb\u30f3\u30c8\u304a\u3088\u3073\u30b0\u30ec\u30ca\u30c7\u30a3\u30fc\u30f3\u8af8\u5cf6",
        common:
          "\u30bb\u30f3\u30c8\u30d3\u30f3\u30bb\u30f3\u30c8\u304a\u3088\u3073\u30b0\u30ec\u30ca\u30c7\u30a3\u30fc\u30f3\u8af8\u5cf6",
      },
      kor: {
        official:
          "\uc138\uc778\ud2b8\ube48\uc13c\ud2b8 \uadf8\ub808\ub098\ub518",
        common: "\uc138\uc778\ud2b8\ube48\uc13c\ud2b8 \uadf8\ub808\ub098\ub518",
      },
      nld: {
        official: "Saint Vincent en de Grenadines",
        common: "Saint Vincent en de Grenadines",
      },
      per: {
        official:
          "\u0633\u0646\u062a \u0648\u06cc\u0646\u0633\u0646\u062a \u0648 \u06af\u0631\u0646\u0627\u062f\u06cc\u0646\u200c\u0647\u0627",
        common:
          "\u0633\u0646\u062a \u0648\u06cc\u0646\u0633\u0646\u062a \u0648 \u06af\u0631\u0646\u0627\u062f\u06cc\u0646\u200c\u0647\u0627",
      },
      pol: {
        official: "Saint Vincent i Grenadyny",
        common: "Saint Vincent i Grenadyny",
      },
      por: {
        official: "S\u00e3o Vicente e Granadinas",
        common: "S\u00e3o Vincente e Granadinas",
      },
      rus: {
        official:
          "\u0421\u0435\u043d\u0442-\u0412\u0438\u043d\u0441\u0435\u043d\u0442 \u0438 \u0413\u0440\u0435\u043d\u0430\u0434\u0438\u043d\u044b",
        common:
          "\u0421\u0435\u043d\u0442-\u0412\u0438\u043d\u0441\u0435\u043d\u0442 \u0438 \u0413\u0440\u0435\u043d\u0430\u0434\u0438\u043d\u044b",
      },
      slk: {
        official: "Sv\u00e4t\u00fd Vincent a Grenad\u00edny",
        common: "Sv\u00e4t\u00fd Vincent a Grenad\u00edny",
      },
      spa: {
        official: "San Vicente y las Granadinas",
        common: "San Vicente y Granadinas",
      },
      swe: {
        official: "Saint Vincent och Grenadinerna",
        common: "Saint Vincent och Grenadinerna",
      },
      urd: {
        official:
          "\u0633\u06cc\u0646\u0679 \u0648\u06cc\u0646\u0633\u06cc\u0646\u0679 \u0648 \u06af\u0631\u06cc\u0646\u0627\u0688\u0627\u0626\u0646\u0632",
        common:
          "\u0633\u06cc\u0646\u0679 \u0648\u06cc\u0646\u0633\u06cc\u0646\u0679 \u0648 \u06af\u0631\u06cc\u0646\u0627\u0688\u0627\u0626\u0646\u0632",
      },
      zho: {
        official:
          "\u5723\u6587\u68ee\u7279\u548c\u683c\u6797\u7eb3\u4e01\u65af",
        common: "\u5723\u6587\u68ee\u7279\u548c\u683c\u6797\u7eb3\u4e01\u65af",
      },
    },
    latlng: [13.25, -61.2],
    landlocked: false,
    borders: [],
    area: 389,
    flag: "\ud83c\uddfb\ud83c\udde8",
    demonyms: {
      eng: { f: "Saint Vincentian", m: "Saint Vincentian" },
      fra: { f: "Vincentaise", m: "Vincentais" },
    },
    callingCodes: ["+1784"],
  },
  {
    name: {
      common: "Venezuela",
      official: "Bolivarian Republic of Venezuela",
      native: {
        spa: {
          official: "Rep\u00fablica Bolivariana de Venezuela",
          common: "Venezuela",
        },
      },
    },
    tld: [".ve"],
    cca2: "VE",
    ccn3: "862",
    cca3: "VEN",
    cioc: "VEN",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: {
      VES: { name: "Venezuelan bol\u00edvar soberano", symbol: "Bs.S." },
    },
    idd: { root: "+5", suffixes: ["8"] },
    capital: ["Caracas"],
    altSpellings: [
      "VE",
      "Bolivarian Republic of Venezuela",
      "Venezuela, Bolivarian Republic of",
      "Rep\u00fablica Bolivariana de Venezuela",
    ],
    region: "Americas",
    subregion: "South America",
    languages: { spa: "Spanish" },
    translations: {
      ces: {
        official: "Bol\u00edvarsk\u00e1 republika Venezuela",
        common: "Venezuela",
      },
      deu: { official: "Bolivarische Republik Venezuela", common: "Venezuela" },
      est: {
        official: "Venezuela Bol\u00edvari Vabariik",
        common: "Venezuela",
      },
      fin: {
        official: "Venezuelan bolivariaainen tasavalta",
        common: "Venezuela",
      },
      fra: {
        official: "R\u00e9publique bolivarienne du Venezuela",
        common: "Venezuela",
      },
      hrv: {
        official: "BOLIVARIJANSKA Republika Venezuela",
        common: "Venezuela",
      },
      hun: {
        official: "Venezuelai Boliv\u00e1ri K\u00f6zt\u00e1rsas\u00e1g",
        common: "Venezuela",
      },
      ita: {
        official: "Repubblica Bolivariana del Venezuela",
        common: "Venezuela",
      },
      jpn: {
        official:
          "\u30d9\u30cd\u30ba\u30a8\u30e9\u00b7\u30dc\u30ea\u30d0\u30eb\u5171\u548c\u56fd",
        common:
          "\u30d9\u30cd\u30ba\u30a8\u30e9\u30fb\u30dc\u30ea\u30d0\u30eb\u5171\u548c\u56fd",
      },
      kor: {
        official:
          "\ubca0\ub124\uc218\uc5d8\ub77c \ubcfc\ub9ac\ubc14\ub974 \uacf5\ud654\uad6d",
        common: "\ubca0\ub124\uc218\uc5d8\ub77c",
      },
      nld: {
        official: "Bolivariaanse Republiek Venezuela",
        common: "Venezuela",
      },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0628\u0648\u0644\u06cc\u0648\u0627\u0631\u06cc \u0648\u0646\u0632\u0648\u0626\u0644\u0627",
        common: "\u0648\u0646\u0632\u0648\u0626\u0644\u0627",
      },
      pol: {
        official: "Boliwaria\u0144ska Republika Wenezueli",
        common: "Wenezuela",
      },
      por: {
        official: "Rep\u00fablica Bolivariana da Venezuela",
        common: "Venezuela",
      },
      rus: {
        official:
          "\u0411\u043e\u043b\u0438\u0432\u0430\u0440\u0438\u0430\u043d\u0441\u043a\u0430\u044f \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0412\u0435\u043d\u0435\u0441\u0443\u044d\u043b\u0430",
        common: "\u0412\u0435\u043d\u0435\u0441\u0443\u044d\u043b\u0430",
      },
      slk: {
        official: "Venezuelsk\u00e1 bol\u00edvarovsk\u00e1 republika",
        common: "Venezuela",
      },
      spa: {
        official: "Rep\u00fablica Bolivariana de Venezuela",
        common: "Venezuela",
      },
      swe: {
        official: "Bolivarianska republiken Venezuela",
        common: "Venezuela",
      },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0648\u06cc\u0646\u06cc\u0632\u0648\u06cc\u0644\u0627",
        common: "\u0648\u06cc\u0646\u06cc\u0632\u0648\u06cc\u0644\u0627",
      },
      zho: {
        official:
          "\u59d4\u5185\u745e\u62c9\u73bb\u5229\u74e6\u5c14\u5171\u548c\u56fd",
        common: "\u59d4\u5185\u745e\u62c9",
      },
    },
    latlng: [8, -66],
    landlocked: false,
    borders: ["BRA", "COL", "GUY"],
    area: 916445,
    flag: "\ud83c\uddfb\ud83c\uddea",
    demonyms: {
      eng: { f: "Venezuelan", m: "Venezuelan" },
      fra: {
        f: "V\u00e9n\u00e9zu\u00e9lienne",
        m: "V\u00e9n\u00e9zu\u00e9lien",
      },
    },
    callingCodes: ["+58"],
  },
  {
    name: {
      common: "British Virgin Islands",
      official: "Virgin Islands",
      native: {
        eng: { official: "Virgin Islands", common: "British Virgin Islands" },
      },
    },
    tld: [".vg"],
    cca2: "VG",
    ccn3: "092",
    cca3: "VGB",
    cioc: "IVB",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: { USD: { name: "United States dollar", symbol: "$" } },
    idd: { root: "+1", suffixes: ["284"] },
    capital: ["Road Town"],
    altSpellings: ["VG", "Virgin Islands, British"],
    region: "Americas",
    subregion: "Caribbean",
    languages: { eng: "English" },
    translations: {
      ces: {
        official: "Britsk\u00e9 Panensk\u00e9 ostrovy",
        common: "Britsk\u00e9 Panensk\u00e9 ostrovy",
      },
      deu: { official: "Jungferninseln", common: "Britische Jungferninseln" },
      est: { official: "Neitsisaared", common: "Briti Neitsisaared" },
      fin: {
        official: "Brittil\u00e4iset Neitsytsaaret",
        common: "Neitsytsaaret",
      },
      fra: {
        official: "\u00eeles Vierges",
        common: "\u00celes Vierges britanniques",
      },
      hrv: {
        official: "Djevi\u010danski Otoci",
        common: "Britanski Djevi\u010danski Otoci",
      },
      hun: { official: "Brit Virgin-szigetek", common: "Brit Virgin-szigetek" },
      ita: { official: "Isole Vergini", common: "Isole Vergini Britanniche" },
      jpn: {
        official: "\u30d0\u30fc\u30b8\u30f3\u8af8\u5cf6",
        common:
          "\u30a4\u30ae\u30ea\u30b9\u9818\u30f4\u30a1\u30fc\u30b8\u30f3\u8af8\u5cf6",
      },
      kor: {
        official: "\uc601\uad6d\ub839 \ubc84\uc9c4\uc544\uc77c\ub79c\ub4dc",
        common: "\uc601\uad6d\ub839 \ubc84\uc9c4\uc544\uc77c\ub79c\ub4dc",
      },
      nld: { official: "Maagdeneilanden", common: "Britse Maagdeneilanden" },
      per: {
        official:
          "\u062c\u0632\u0627\u06cc\u0631 \u0648\u06cc\u0631\u062c\u06cc\u0646 \u0628\u0631\u06cc\u062a\u0627\u0646\u06cc\u0627",
        common:
          "\u062c\u0632\u0627\u06cc\u0631 \u0648\u06cc\u0631\u062c\u06cc\u0646 \u0628\u0631\u06cc\u062a\u0627\u0646\u06cc\u0627",
      },
      pol: {
        official: "Brytyjskie Wyspy Dziewicze",
        common: "Brytyjskie Wyspy Dziewicze",
      },
      por: { official: "Ilhas Virgens", common: "Ilhas Virgens" },
      rus: {
        official:
          "\u0412\u0438\u0440\u0433\u0438\u043d\u0441\u043a\u0438\u0435 \u043e\u0441\u0442\u0440\u043e\u0432\u0430",
        common:
          "\u0411\u0440\u0438\u0442\u0430\u043d\u0441\u043a\u0438\u0435 \u0412\u0438\u0440\u0433\u0438\u043d\u0441\u043a\u0438\u0435 \u043e\u0441\u0442\u0440\u043e\u0432\u0430",
      },
      slk: {
        official: "Panensk\u00e9 ostrovy",
        common: "Panensk\u00e9 ostrovy",
      },
      spa: {
        official: "Islas V\u00edrgenes",
        common: "Islas V\u00edrgenes del Reino Unido",
      },
      swe: {
        official: "Brittiska Jungfru\u00f6arna",
        common: "Brittiska Jungfru\u00f6arna",
      },
      urd: {
        official:
          "\u0628\u0631\u0637\u0627\u0646\u0648\u06cc \u062c\u0632\u0627\u0626\u0631 \u0648\u0631\u062c\u0646",
        common:
          "\u0628\u0631\u0637\u0627\u0646\u0648\u06cc \u062c\u0632\u0627\u0626\u0631 \u0648\u0631\u062c\u0646",
      },
      zho: {
        official: "\u82f1\u5c5e\u7ef4\u5c14\u4eac\u7fa4\u5c9b",
        common: "\u82f1\u5c5e\u7ef4\u5c14\u4eac\u7fa4\u5c9b",
      },
    },
    latlng: [18.431383, -64.62305],
    landlocked: false,
    borders: [],
    area: 151,
    flag: "\ud83c\uddfb\ud83c\uddec",
    demonyms: {
      eng: { f: "Virgin Islander", m: "Virgin Islander" },
      fra: { f: "", m: "" },
    },
    callingCodes: ["+1284"],
  },
  {
    name: {
      common: "United States Virgin Islands",
      official: "Virgin Islands of the United States",
      native: {
        eng: {
          official: "Virgin Islands of the United States",
          common: "United States Virgin Islands",
        },
      },
    },
    tld: [".vi"],
    cca2: "VI",
    ccn3: "850",
    cca3: "VIR",
    cioc: "ISV",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: { USD: { name: "United States dollar", symbol: "$" } },
    idd: { root: "+1", suffixes: ["340"] },
    capital: ["Charlotte Amalie"],
    altSpellings: ["VI", "Virgin Islands, U.S."],
    region: "Americas",
    subregion: "Caribbean",
    languages: { eng: "English" },
    translations: {
      ces: {
        official: "Americk\u00e9 Panensk\u00e9 ostrovy",
        common: "Americk\u00e9 Panensk\u00e9 ostrovy",
      },
      deu: {
        official: "Amerikanische Jungferninseln",
        common: "Amerikanische Jungferninseln",
      },
      est: {
        official: "\u00dchendriikide Neitsisaared",
        common: "Neitsisaared, USA",
      },
      fin: { official: "Yhdysvaltain Neitsytsaaret", common: "Neitsytsaaret" },
      fra: {
        official: "\u00celes Vierges des \u00c9tats-Unis",
        common: "\u00celes Vierges des \u00c9tats-Unis",
      },
      hrv: {
        official: "Djevi\u010danski Otoci SAD",
        common: "Ameri\u010dki Djevi\u010danski Otoci",
      },
      hun: {
        official: "Amerikai Virgin-szigetek",
        common: "Amerikai Virgin-szigetek",
      },
      ita: {
        official: "Isole Vergini degli Stati Uniti",
        common: "Isole Vergini americane",
      },
      jpn: {
        official: "\u7c73\u56fd\u306e\u30d0\u30fc\u30b8\u30f3\u8af8\u5cf6",
        common:
          "\u30a2\u30e1\u30ea\u30ab\u9818\u30f4\u30a1\u30fc\u30b8\u30f3\u8af8\u5cf6",
      },
      kor: {
        official: "\ubbf8\uad6d\ub839 \ubc84\uc9c4\uc544\uc77c\ub79c\ub4dc",
        common: "\ubbf8\uad6d\ub839 \ubc84\uc9c4\uc544\uc77c\ub79c\ub4dc",
      },
      nld: {
        official: "Maagdeneilanden van de Verenigde Staten",
        common: "Amerikaanse Maagdeneilanden",
      },
      per: {
        official:
          "\u062c\u0632\u0627\u06cc\u0631 \u0648\u06cc\u0631\u062c\u06cc\u0646 \u0627\u06cc\u0627\u0644\u0627\u062a \u0645\u062a\u062d\u062f\u0647 \u0622\u0645\u0631\u06cc\u06a9\u0627",
        common:
          "\u062c\u0632\u0627\u06cc\u0631 \u0648\u06cc\u0631\u062c\u06cc\u0646 \u0627\u06cc\u0627\u0644\u0627\u062a \u0645\u062a\u062d\u062f\u0647 \u0622\u0645\u0631\u06cc\u06a9\u0627",
      },
      pol: {
        official: "Wyspy Dziewicze Stan\u00f3w Zjednoczonych",
        common: "Wyspy Dziewicze Stan\u00f3w Zjednoczonych",
      },
      por: {
        official: "Ilhas Virgens dos Estados Unidos",
        common: "Ilhas Virgens dos Estados Unidos",
      },
      rus: {
        official:
          "\u0412\u0438\u0440\u0433\u0438\u043d\u0441\u043a\u0438\u0435 \u043e\u0441\u0442\u0440\u043e\u0432\u0430 \u0421\u043e\u0435\u0434\u0438\u043d\u0435\u043d\u043d\u044b\u0445 \u0428\u0442\u0430\u0442\u043e\u0432",
        common:
          "\u0412\u0438\u0440\u0433\u0438\u043d\u0441\u043a\u0438\u0435 \u041e\u0441\u0442\u0440\u043e\u0432\u0430",
      },
      slk: {
        official: "Americk\u00e9 Panensk\u00e9 ostrovy",
        common: "Americk\u00e9 Panensk\u00e9 ostrovy",
      },
      spa: {
        official: "Islas V\u00edrgenes de los Estados Unidos",
        common: "Islas V\u00edrgenes de los Estados Unidos",
      },
      swe: {
        official: "Amerikanska Jungfru\u00f6arna",
        common: "Amerikanska Jungfru\u00f6arna",
      },
      urd: {
        official:
          "\u0627\u0645\u0631\u06cc\u06a9\u06cc \u062c\u0632\u0627\u0626\u0631 \u0648\u0631\u062c\u0646",
        common:
          "\u0627\u0645\u0631\u06cc\u06a9\u06cc \u062c\u0632\u0627\u0626\u0631 \u0648\u0631\u062c\u0646",
      },
      zho: {
        official: "\u7f8e\u5c5e\u7ef4\u5c14\u4eac\u7fa4\u5c9b",
        common: "\u7f8e\u5c5e\u7ef4\u5c14\u4eac\u7fa4\u5c9b",
      },
    },
    latlng: [18.35, -64.933333],
    landlocked: false,
    borders: [],
    area: 347,
    flag: "\ud83c\uddfb\ud83c\uddee",
    demonyms: {
      eng: { f: "Virgin Islander", m: "Virgin Islander" },
      fra: { f: "", m: "" },
    },
    callingCodes: ["+1340"],
  },
  {
    name: {
      common: "Vietnam",
      official: "Socialist Republic of Vietnam",
      native: {
        vie: {
          official:
            "C\u1ed9ng h\u00f2a x\u00e3 h\u1ed9i ch\u1ee7 ngh\u0129a Vi\u1ec7t Nam",
          common: "Vi\u1ec7t Nam",
        },
      },
    },
    tld: [".vn"],
    cca2: "VN",
    ccn3: "704",
    cca3: "VNM",
    cioc: "VIE",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: {
      VND: { name: "Vietnamese \u0111\u1ed3ng", symbol: "\u20ab" },
    },
    idd: { root: "+8", suffixes: ["4"] },
    capital: ["Hanoi"],
    altSpellings: [
      "VN",
      "Socialist Republic of Vietnam",
      "C\u1ed9ng h\u00f2a X\u00e3 h\u1ed9i ch\u1ee7 ngh\u0129a Vi\u1ec7t Nam",
      "Viet Nam",
    ],
    region: "Asia",
    subregion: "South-Eastern Asia",
    languages: { vie: "Vietnamese" },
    translations: {
      ces: {
        official: "Vietnamsk\u00e1 socialistick\u00e1 republika",
        common: "Vietnam",
      },
      deu: { official: "Sozialistische Republik Vietnam", common: "Vietnam" },
      est: { official: "Vietnami Sotsialistlik Vabariik", common: "Vietnam" },
      fin: { official: "Vietnamin sosialistinen tasavalta", common: "Vietnam" },
      fra: {
        official: "R\u00e9publique socialiste du Vi\u00eat Nam",
        common: "Vi\u00eat Nam",
      },
      hrv: {
        official: "Socijalisti\u010dka Republika Vijetnam",
        common: "Vijetnam",
      },
      hun: {
        official: "Vietn\u00e1mi Szocialista K\u00f6zt\u00e1rsas\u00e1g",
        common: "Vietn\u00e1m",
      },
      ita: { official: "Repubblica socialista del Vietnam", common: "Vietnam" },
      jpn: {
        official:
          "\u30d9\u30c8\u30ca\u30e0\u793e\u4f1a\u4e3b\u7fa9\u5171\u548c\u56fd",
        common: "\u30d9\u30c8\u30ca\u30e0",
      },
      kor: {
        official:
          "\ubca0\ud2b8\ub0a8 \uc0ac\ud68c\uc8fc\uc758 \uacf5\ud654\uad6d",
        common: "\ubca0\ud2b8\ub0a8",
      },
      nld: { official: "Socialistische Republiek Vietnam", common: "Vietnam" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0633\u0648\u0633\u06cc\u0627\u0644\u06cc\u0633\u062a\u06cc \u0648\u06cc\u062a\u0646\u0627\u0645",
        common: "\u0648\u06cc\u062a\u0646\u0627\u0645",
      },
      pol: { official: "Socjalistyczna Republika Wietnamu", common: "Wietnam" },
      por: {
        official: "Rep\u00fablica Socialista do Vietname",
        common: "Vietname",
      },
      rus: {
        official:
          "\u0421\u043e\u0446\u0438\u0430\u043b\u0438\u0441\u0442\u0438\u0447\u0435\u0441\u043a\u0430\u044f \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0412\u044c\u0435\u0442\u043d\u0430\u043c",
        common: "\u0412\u044c\u0435\u0442\u043d\u0430\u043c",
      },
      slk: {
        official: "Vietnamsk\u00e1 socialistick\u00e1 republika",
        common: "Vietnam",
      },
      spa: {
        official: "Rep\u00fablica Socialista de Vietnam",
        common: "Vietnam",
      },
      swe: { official: "Socialistiska republiken Vietnam", common: "Vietnam" },
      urd: {
        official:
          "\u0627\u0634\u062a\u0631\u0627\u06a9\u06cc \u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0648\u06cc\u062a\u0646\u0627\u0645",
        common: "\u0648\u06cc\u062a\u0646\u0627\u0645",
      },
      zho: {
        official: "\u8d8a\u5357\u793e\u4f1a\u4e3b\u4e49\u5171\u548c\u56fd",
        common: "\u8d8a\u5357",
      },
    },
    latlng: [16.16666666, 107.83333333],
    landlocked: false,
    borders: ["KHM", "CHN", "LAO"],
    area: 331212,
    flag: "\ud83c\uddfb\ud83c\uddf3",
    demonyms: {
      eng: { f: "Vietnamese", m: "Vietnamese" },
      fra: { f: "Vietnamienne", m: "Vietnamien" },
    },
    callingCodes: ["+84"],
  },
  {
    name: {
      common: "Vanuatu",
      official: "Republic of Vanuatu",
      native: {
        bis: { official: "Ripablik blong Vanuatu", common: "Vanuatu" },
        eng: { official: "Republic of Vanuatu", common: "Vanuatu" },
        fra: { official: "R\u00e9publique de Vanuatu", common: "Vanuatu" },
      },
    },
    tld: [".vu"],
    cca2: "VU",
    ccn3: "548",
    cca3: "VUT",
    cioc: "VAN",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { VUV: { name: "Vanuatu vatu", symbol: "Vt" } },
    idd: { root: "+6", suffixes: ["78"] },
    capital: ["Port Vila"],
    altSpellings: [
      "VU",
      "Republic of Vanuatu",
      "Ripablik blong Vanuatu",
      "R\u00e9publique de Vanuatu",
    ],
    region: "Oceania",
    subregion: "Melanesia",
    languages: { bis: "Bislama", eng: "English", fra: "French" },
    translations: {
      ces: { official: "Republika Vanuatu", common: "Vanuatu" },
      deu: { official: "Vanuatu", common: "Vanuatu" },
      est: { official: "Vanuatu Vabariik", common: "Vanuatu" },
      fin: { official: "Vanuatun tasavalta", common: "Vanuatu" },
      fra: { official: "R\u00e9publique de Vanuatu", common: "Vanuatu" },
      hrv: { official: "Republika Vanuatu", common: "Vanuatu" },
      hun: {
        official: "Vanuatui K\u00f6zt\u00e1rsas\u00e1g",
        common: "Vanuatu",
      },
      ita: { official: "Repubblica di Vanuatu", common: "Vanuatu" },
      jpn: {
        official: "\u30d0\u30cc\u30a2\u30c4\u5171\u548c\u56fd",
        common: "\u30d0\u30cc\u30a2\u30c4",
      },
      kor: {
        official: "\ubc14\ub204\uc544\ud22c \uacf5\ud654\uad6d",
        common: "\ubc14\ub204\uc544\ud22c",
      },
      nld: { official: "Republiek Vanuatu", common: "Vanuatu" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0648\u0627\u0646\u0648\u0627\u062a\u0648",
        common: "\u0648\u0627\u0646\u0648\u0627\u062a\u0648",
      },
      pol: { official: "Republika Vanuatu", common: "Vanuatu" },
      por: { official: "Rep\u00fablica de Vanuatu", common: "Vanuatu" },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0412\u0430\u043d\u0443\u0430\u0442\u0443",
        common: "\u0412\u0430\u043d\u0443\u0430\u0442\u0443",
      },
      slk: { official: "Vanuatsk\u00e1 republika", common: "Vanuatu" },
      spa: { official: "Rep\u00fablica de Vanuatu", common: "Vanuatu" },
      swe: { official: "Republiken Vanuatu", common: "Vanuatu" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0648\u0627\u0646\u0648\u0627\u062a\u0648",
        common: "\u0648\u0627\u0646\u0648\u0627\u062a\u0648",
      },
      zho: {
        official: "\u74e6\u52aa\u963f\u56fe\u5171\u548c\u56fd",
        common: "\u74e6\u52aa\u963f\u56fe",
      },
    },
    latlng: [-16, 167],
    landlocked: false,
    borders: [],
    area: 12189,
    flag: "\ud83c\uddfb\ud83c\uddfa",
    demonyms: {
      eng: { f: "Ni-Vanuatu", m: "Ni-Vanuatu" },
      fra: { f: "Vanuatuane", m: "Vanuatuan" },
    },
    callingCodes: ["+678"],
  },
  {
    name: {
      common: "Wallis and Futuna",
      official: "Territory of the Wallis and Futuna Islands",
      native: {
        fra: {
          official: "Territoire des \u00eeles Wallis et Futuna",
          common: "Wallis et Futuna",
        },
      },
    },
    tld: [".wf"],
    cca2: "WF",
    ccn3: "876",
    cca3: "WLF",
    cioc: "",
    independent: false,
    status: "officially-assigned",
    unMember: false,
    currencies: { XPF: { name: "CFP franc", symbol: "\u20a3" } },
    idd: { root: "+6", suffixes: ["81"] },
    capital: ["Mata-Utu"],
    altSpellings: [
      "WF",
      "Territory of the Wallis and Futuna Islands",
      "Territoire des \u00eeles Wallis et Futuna",
    ],
    region: "Oceania",
    subregion: "Polynesia",
    languages: { fra: "French" },
    translations: {
      ces: {
        official: "Teritorium ostrov\u016f Wallis a Futuna",
        common: "Wallis a Futuna",
      },
      deu: {
        official: "Gebiet der Wallis und Futuna",
        common: "Wallis und Futuna",
      },
      est: { official: "Wallise ja Futuna ala", common: "Wallis ja Futuna" },
      fin: {
        official: "Wallisin ja Futunan yhteis\u00f6",
        common: "Wallis ja Futuna",
      },
      fra: {
        official: "Territoire des \u00eeles Wallis et Futuna",
        common: "Wallis-et-Futuna",
      },
      hrv: {
        official: "Teritoriju Wallis i Futuna",
        common: "Wallis i Fortuna",
      },
      hun: {
        official: "Wallis \u00e9s Futuna",
        common: "Wallis \u00e9s Futuna",
      },
      ita: {
        official: "Territorio delle Isole Wallis e Futuna",
        common: "Wallis e Futuna",
      },
      jpn: {
        official:
          "\u30a6\u30a9\u30ea\u30b9\u00b7\u30d5\u30c4\u30ca\u8af8\u5cf6\u306e\u9818\u571f",
        common: "\u30a6\u30a9\u30ea\u30b9\u30fb\u30d5\u30c4\u30ca",
      },
      kor: {
        official: "\uc648\ub9ac\uc2a4 \ud4cc\ud280\ub098",
        common: "\uc648\ub9ac\uc2a4 \ud4cc\ud280\ub098",
      },
      nld: {
        official: "Grondgebied van de Wallis en Futuna",
        common: "Wallis en Futuna",
      },
      per: {
        official:
          "\u062c\u0632\u0627\u06cc\u0631 \u0648\u0627\u0644\u06cc\u0633 \u0648 \u0641\u0648\u062a\u0648\u0646\u0627",
        common:
          "\u0648\u0627\u0644\u06cc\u0633 \u0648 \u0641\u0648\u062a\u0648\u0646\u0627",
      },
      pol: {
        official: "Terytorium Wysp Wallis i Futuna",
        common: "Wallis i Futuna",
      },
      por: {
        official: "Territ\u00f3rio das Ilhas Wallis e Futuna",
        common: "Wallis e Futuna",
      },
      rus: {
        official:
          "\u0422\u0435\u0440\u0440\u0438\u0442\u043e\u0440\u0438\u044f \u0423\u043e\u043b\u043b\u0438\u0441 \u0438 \u0424\u0443\u0442\u0443\u043d\u0430 \u043e\u0441\u0442\u0440\u043e\u0432\u0430",
        common:
          "\u0423\u043e\u043b\u043b\u0438\u0441 \u0438 \u0424\u0443\u0442\u0443\u043d\u0430",
      },
      slk: {
        official: "Terit\u00f3rium ostrovov Wallis a Futuna",
        common: "Wallis a Futuna",
      },
      spa: {
        official: "Territorio de las Islas Wallis y Futuna",
        common: "Wallis y Futuna",
      },
      swe: {
        official: "Territoriet Wallis- och Futuna\u00f6arna",
        common: "Wallis- och Futuna\u00f6arna",
      },
      urd: {
        official:
          "\u0633\u0631 \u0632\u0645\u06cc\u0646\u0650 \u0648\u0627\u0644\u0633 \u0648 \u0641\u062a\u0648\u0646\u06c1 \u062c\u0632\u0627\u0626\u0631",
        common:
          "\u0648\u0627\u0644\u0633 \u0648 \u0641\u062a\u0648\u0646\u06c1",
      },
      zho: {
        official: "\u74e6\u5229\u65af\u548c\u5bcc\u56fe\u7eb3\u7fa4\u5c9b",
        common: "\u74e6\u5229\u65af\u548c\u5bcc\u56fe\u7eb3\u7fa4\u5c9b",
      },
    },
    latlng: [-13.3, -176.2],
    landlocked: false,
    borders: [],
    area: 142,
    flag: "\ud83c\uddfc\ud83c\uddeb",
    demonyms: {
      eng: { f: "Wallis and Futuna Islander", m: "Wallis and Futuna Islander" },
      fra: { f: "", m: "" },
    },
    callingCodes: ["+681"],
  },
  {
    name: {
      common: "Samoa",
      official: "Independent State of Samoa",
      native: {
        eng: { official: "Independent State of Samoa", common: "Samoa" },
        smo: {
          official: "Malo Sa\u02bboloto Tuto\u02bbatasi o S\u0101moa",
          common: "S\u0101moa",
        },
      },
    },
    tld: [".ws"],
    cca2: "WS",
    ccn3: "882",
    cca3: "WSM",
    cioc: "SAM",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { WST: { name: "Samoan t\u0101l\u0101", symbol: "T" } },
    idd: { root: "+6", suffixes: ["85"] },
    capital: ["Apia"],
    altSpellings: [
      "WS",
      "Independent State of Samoa",
      "Malo Sa\u02bboloto Tuto\u02bbatasi o S\u0101moa",
    ],
    region: "Oceania",
    subregion: "Polynesia",
    languages: { eng: "English", smo: "Samoan" },
    translations: {
      ces: { official: "Nez\u00e1visl\u00fd st\u00e1t Samoa", common: "Samoa" },
      deu: { official: "Unabh\u00e4ngige Staat Samoa", common: "Samoa" },
      est: { official: "Samoa Iseseisvusriik", common: "Samoa" },
      fin: { official: "Samoan itsen\u00e4inen valtio", common: "Samoa" },
      fra: { official: "Samoa", common: "Samoa" },
      hrv: { official: "Nezavisna Dr\u017eava Samoa", common: "Samoa" },
      hun: { official: "Szamoai F\u00fcggetlen \u00c1llam", common: "Szamoa" },
      ita: { official: "Stato indipendente di Samoa", common: "Samoa" },
      jpn: {
        official: "\u30b5\u30e2\u30a2\u72ec\u7acb\u56fd",
        common: "\u30b5\u30e2\u30a2",
      },
      kor: {
        official: "\uc0ac\ubaa8\uc544 \ub3c5\ub9bd\uad6d",
        common: "\uc0ac\ubaa8\uc544",
      },
      nld: { official: "Onafhankelijke Staat Samoa", common: "Samoa" },
      per: {
        official:
          "\u0627\u06cc\u0627\u0644\u062a \u0645\u0633\u062a\u0642\u0644 \u0633\u0627\u0645\u0648\u0622",
        common: "\u0633\u0627\u0645\u0648\u0622",
      },
      pol: { official: "Niezale\u017cne Pa\u0144stwo Samoa", common: "Samoa" },
      por: { official: "Estado Independente de Samoa", common: "Samoa" },
      rus: {
        official:
          "\u041d\u0435\u0437\u0430\u0432\u0438\u0441\u0438\u043c\u043e\u0435 \u0413\u043e\u0441\u0443\u0434\u0430\u0440\u0441\u0442\u0432\u043e \u0421\u0430\u043c\u043e\u0430",
        common: "\u0421\u0430\u043c\u043e\u0430",
      },
      slk: {
        official: "Nez\u00e1visl\u00fd \u0161t\u00e1tSamoa",
        common: "Samoa",
      },
      spa: { official: "Estado Independiente de Samoa", common: "Samoa" },
      swe: {
        official: "Sj\u00e4lvst\u00e4ndiga staten Samoa",
        common: "Samoa",
      },
      urd: {
        official:
          "\u0622\u0632\u0627\u062f \u0633\u0644\u0637\u0646\u062a\u0650 \u0633\u0627\u0645\u0648\u0627",
        common: "\u0633\u0627\u0645\u0648\u0648\u0627",
      },
      zho: {
        official: "\u8428\u6469\u4e9a\u72ec\u7acb\u56fd",
        common: "\u8428\u6469\u4e9a",
      },
    },
    latlng: [-13.58333333, -172.33333333],
    landlocked: false,
    borders: [],
    area: 2842,
    flag: "\ud83c\uddfc\ud83c\uddf8",
    demonyms: {
      eng: { f: "Samoan", m: "Samoan" },
      fra: { f: "Samoane", m: "Samoan" },
    },
    callingCodes: ["+685"],
  },
  {
    name: {
      common: "Yemen",
      official: "Republic of Yemen",
      native: {
        ara: {
          official:
            "\u0627\u0644\u062c\u0645\u0647\u0648\u0631\u064a\u0629 \u0627\u0644\u064a\u0645\u0646\u064a\u0629",
          common: "\u0627\u0644\u064a\u064e\u0645\u064e\u0646",
        },
      },
    },
    tld: [".ye"],
    cca2: "YE",
    ccn3: "887",
    cca3: "YEM",
    cioc: "YEM",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { YER: { name: "Yemeni rial", symbol: "\ufdfc" } },
    idd: { root: "+9", suffixes: ["67"] },
    capital: ["Sana'a"],
    altSpellings: [
      "YE",
      "Yemeni Republic",
      "al-Jumh\u016briyyah al-Yamaniyyah",
    ],
    region: "Asia",
    subregion: "Western Asia",
    languages: { ara: "Arabic" },
    translations: {
      ces: { official: "Jemensk\u00e1 republika", common: "Jemen" },
      deu: { official: "Republik Jemen", common: "Jemen" },
      est: { official: "Jeemeni Vabariik", common: "Jeemen" },
      fin: { official: "Jemenin tasavalta", common: "Jemen" },
      fra: { official: "R\u00e9publique du Y\u00e9men", common: "Y\u00e9men" },
      hrv: { official: "Republika Jemen", common: "Jemen" },
      hun: { official: "Jemeni K\u00f6zt\u00e1rsas\u00e1g", common: "Jemen" },
      ita: { official: "Repubblica dello Yemen", common: "Yemen" },
      jpn: {
        official: "\u30a4\u30a8\u30e1\u30f3\u5171\u548c\u56fd",
        common: "\u30a4\u30a8\u30e1\u30f3",
      },
      kor: {
        official: "\uc608\uba58 \uacf5\ud654\uad6d",
        common: "\uc608\uba58",
      },
      nld: { official: "Republiek Jemen", common: "Jemen" },
      per: {
        official: "\u062c\u0645\u0647\u0648\u0631\u06cc \u06cc\u0645\u0646",
        common: "\u06cc\u0645\u0646",
      },
      pol: { official: "Republika Jeme\u0144ska", common: "Jemen" },
      por: { official: "Rep\u00fablica do I\u00eamen", common: "I\u00e9men" },
      rus: {
        official:
          "\u0419\u0435\u043c\u0435\u043d\u0441\u043a\u0430\u044f \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430",
        common: "\u0419\u0435\u043c\u0435\u043d",
      },
      slk: { official: "Jemensk\u00e1 republika", common: "Jemen" },
      spa: { official: "Rep\u00fablica de Yemen", common: "Yemen" },
      swe: { official: "Republiken Jemen", common: "Jemen" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u06cc\u0645\u0646",
        common: "\u06cc\u0645\u0646",
      },
      zho: {
        official: "\u4e5f\u95e8\u5171\u548c\u56fd",
        common: "\u4e5f\u95e8",
      },
    },
    latlng: [15, 48],
    landlocked: false,
    borders: ["OMN", "SAU"],
    area: 527968,
    flag: "\ud83c\uddfe\ud83c\uddea",
    demonyms: {
      eng: { f: "Yemeni", m: "Yemeni" },
      fra: { f: "Y\u00e9m\u00e9nite", m: "Y\u00e9m\u00e9nite" },
    },
    callingCodes: ["+967"],
  },
  {
    name: {
      common: "South Africa",
      official: "Republic of South Africa",
      native: {
        afr: { official: "Republiek van Suid-Afrika", common: "South Africa" },
        eng: { official: "Republic of South Africa", common: "South Africa" },
        nbl: {
          official: "IRiphabliki yeSewula Afrika",
          common: "Sewula Afrika",
        },
        nso: {
          official: "Rephaboliki ya Afrika-Borwa ",
          common: "Afrika-Borwa",
        },
        sot: {
          official: "Rephaboliki ya Afrika Borwa",
          common: "Afrika Borwa",
        },
        ssw: {
          official: "IRiphabhulikhi yeNingizimu Afrika",
          common: "Ningizimu Afrika",
        },
        tsn: {
          official: "Rephaboliki ya Aforika Borwa",
          common: "Aforika Borwa",
        },
        tso: {
          official: "Riphabliki ra Afrika Dzonga",
          common: "Afrika Dzonga",
        },
        ven: {
          official: "Riphabu\u1e3diki ya Afurika Tshipembe",
          common: "Afurika Tshipembe",
        },
        xho: {
          official: "IRiphabliki yaseMzantsi Afrika",
          common: "Mzantsi Afrika",
        },
        zul: {
          official: "IRiphabliki yaseNingizimu Afrika",
          common: "Ningizimu Afrika",
        },
      },
    },
    tld: [".za"],
    cca2: "ZA",
    ccn3: "710",
    cca3: "ZAF",
    cioc: "RSA",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { ZAR: { name: "South African rand", symbol: "R" } },
    idd: { root: "+2", suffixes: ["7"] },
    capital: ["Pretoria", "Bloemfontein", "Cape Town"],
    altSpellings: ["ZA", "RSA", "Suid-Afrika", "Republic of South Africa"],
    region: "Africa",
    subregion: "Southern Africa",
    languages: {
      afr: "Afrikaans",
      eng: "English",
      nbl: "Southern Ndebele",
      nso: "Northern Sotho",
      sot: "Southern Sotho",
      ssw: "Swazi",
      tsn: "Tswana",
      tso: "Tsonga",
      ven: "Venda",
      xho: "Xhosa",
      zul: "Zulu",
    },
    translations: {
      ces: {
        official: "Jihoafrick\u00e1 republika",
        common: "Jihoafrick\u00e1 republika",
      },
      deu: { official: "Republik S\u00fcdafrika", common: "S\u00fcdafrika" },
      est: {
        official: "L\u00f5una-Aafrika Vabariik",
        common: "L\u00f5una-Aafrika Vabariik",
      },
      fin: {
        official: "Etel\u00e4-Afrikan tasavalta",
        common: "Etel\u00e4-Afrikka",
      },
      fra: {
        official: "R\u00e9publique d'Afrique du Sud",
        common: "Afrique du Sud",
      },
      hrv: {
        official: "Ju\u017enoafri\u010dka Republika",
        common: "Ju\u017enoafri\u010dka Republika",
      },
      hun: {
        official: "D\u00e9l-afrikai K\u00f6zt\u00e1rsas\u00e1g",
        common: "D\u00e9l-afrikai K\u00f6zt\u00e1rsas\u00e1g",
      },
      ita: { official: "Repubblica del Sud Africa", common: "Sud Africa" },
      jpn: {
        official: "\u5357\u30a2\u30d5\u30ea\u30ab\u5171\u548c\u56fd",
        common: "\u5357\u30a2\u30d5\u30ea\u30ab",
      },
      kor: {
        official: "\ub0a8\uc544\ud504\ub9ac\uce74 \uacf5\ud654\uad6d",
        common: "\ub0a8\uc544\ud504\ub9ac\uce74",
      },
      nld: { official: "Republiek Zuid-Afrika", common: "Zuid-Afrika" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0622\u0641\u0631\u06cc\u0642\u0627\u06cc \u062c\u0646\u0648\u0628\u06cc",
        common:
          "\u0622\u0641\u0631\u06cc\u0642\u0627\u06cc \u062c\u0646\u0648\u0628\u06cc",
      },
      pol: {
        official: "Republika Po\u0142udniowej Afryki",
        common: "Po\u0142udniowa Afryka",
      },
      por: {
        official: "Rep\u00fablica da \u00c1frica do Sul",
        common: "\u00c1frica do Sul",
      },
      rus: {
        official:
          "\u042e\u0436\u043d\u043e-\u0410\u0444\u0440\u0438\u043a\u0430\u043d\u0441\u043a\u0430\u044f \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430",
        common:
          "\u042e\u0436\u043d\u043e-\u0410\u0444\u0440\u0438\u043a\u0430\u043d\u0441\u043a\u0430\u044f \u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430",
      },
      slk: {
        official: "Juhoafrick\u00e1 republika",
        common: "Juhoafrick\u00e1 republika",
      },
      spa: {
        official: "Rep\u00fablica de Sud\u00e1frica",
        common: "Rep\u00fablica de Sud\u00e1frica",
      },
      swe: { official: "Republiken Sydafrika", common: "Sydafrika" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u062c\u0646\u0648\u0628\u06cc \u0627\u0641\u0631\u06cc\u0642\u0627",
        common:
          "\u062c\u0646\u0648\u0628\u06cc \u0627\u0641\u0631\u06cc\u0642\u0627",
      },
      zho: {
        official: "\u5357\u975e\u5171\u548c\u56fd",
        common: "\u5357\u975e",
      },
    },
    latlng: [-29, 24],
    landlocked: false,
    borders: ["BWA", "LSO", "MOZ", "NAM", "SWZ", "ZWE"],
    area: 1221037,
    flag: "\ud83c\uddff\ud83c\udde6",
    demonyms: {
      eng: { f: "South African", m: "South African" },
      fra: { f: "Sud-africaine", m: "Sud-africain" },
    },
    callingCodes: ["+27"],
  },
  {
    name: {
      common: "Zambia",
      official: "Republic of Zambia",
      native: { eng: { official: "Republic of Zambia", common: "Zambia" } },
    },
    tld: [".zm"],
    cca2: "ZM",
    ccn3: "894",
    cca3: "ZMB",
    cioc: "ZAM",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: { ZMW: { name: "Zambian kwacha", symbol: "ZK" } },
    idd: { root: "+2", suffixes: ["60"] },
    capital: ["Lusaka"],
    altSpellings: ["ZM", "Republic of Zambia"],
    region: "Africa",
    subregion: "Eastern Africa",
    languages: { eng: "English" },
    translations: {
      ces: { official: "Zambijsk\u00e1 republika", common: "Zambie" },
      deu: { official: "Republik Sambia", common: "Sambia" },
      est: { official: "Sambia Vabariik", common: "Sambia" },
      fin: { official: "Sambian tasavalta", common: "Sambia" },
      fra: { official: "R\u00e9publique de Zambie", common: "Zambie" },
      hrv: { official: "Republika Zambija", common: "Zambija" },
      hun: { official: "Zambiai K\u00f6zt\u00e1rsas\u00e1g", common: "Zambia" },
      ita: { official: "Repubblica di Zambia", common: "Zambia" },
      jpn: {
        official: "\u30b6\u30f3\u30d3\u30a2\u5171\u548c\u56fd",
        common: "\u30b6\u30f3\u30d3\u30a2",
      },
      kor: {
        official: "\uc7a0\ube44\uc544 \uacf5\ud654\uad6d",
        common: "\uc7a0\ube44\uc544",
      },
      nld: { official: "Republiek Zambia", common: "Zambia" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0632\u0627\u0645\u0628\u06cc\u0627",
        common: "\u0632\u0627\u0645\u0628\u06cc\u0627",
      },
      pol: { official: "Republika Zambii", common: "Zambia" },
      por: { official: "Rep\u00fablica da Z\u00e2mbia", common: "Z\u00e2mbia" },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0417\u0430\u043c\u0431\u0438\u044f",
        common: "\u0417\u0430\u043c\u0431\u0438\u044f",
      },
      slk: { official: "Zambijsk\u00e1 republika", common: "Zambia" },
      spa: { official: "Rep\u00fablica de Zambia", common: "Zambia" },
      swe: { official: "Republiken Zambia", common: "Zambia" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0632\u06cc\u0645\u0628\u06cc\u0627",
        common: "\u0632\u06cc\u0645\u0628\u06cc\u0627",
      },
      zho: {
        official: "\u8d5e\u6bd4\u4e9a\u5171\u548c\u56fd",
        common: "\u8d5e\u6bd4\u4e9a",
      },
    },
    latlng: [-15, 30],
    landlocked: true,
    borders: ["AGO", "BWA", "COD", "MWI", "MOZ", "NAM", "TZA", "ZWE"],
    area: 752612,
    flag: "\ud83c\uddff\ud83c\uddf2",
    demonyms: {
      eng: { f: "Zambian", m: "Zambian" },
      fra: { f: "Zambienne", m: "Zambien" },
    },
    callingCodes: ["+260"],
  },
  {
    name: {
      common: "Zimbabwe",
      official: "Republic of Zimbabwe",
      native: {
        bwg: { official: "Republic of Zimbabwe", common: "Zimbabwe" },
        eng: { official: "Republic of Zimbabwe", common: "Zimbabwe" },
        kck: { official: "Republic of Zimbabwe", common: "Zimbabwe" },
        khi: { official: "Republic of Zimbabwe", common: "Zimbabwe" },
        ndc: { official: "Republic of Zimbabwe", common: "Zimbabwe" },
        nde: { official: "Republic of Zimbabwe", common: "Zimbabwe" },
        nya: { official: "Republic of Zimbabwe", common: "Zimbabwe" },
        sna: { official: "Republic of Zimbabwe", common: "Zimbabwe" },
        sot: { official: "Republic of Zimbabwe", common: "Zimbabwe" },
        toi: { official: "Republic of Zimbabwe", common: "Zimbabwe" },
        tsn: { official: "Republic of Zimbabwe", common: "Zimbabwe" },
        tso: { official: "Republic of Zimbabwe", common: "Zimbabwe" },
        ven: { official: "Republic of Zimbabwe", common: "Zimbabwe" },
        xho: { official: "Republic of Zimbabwe", common: "Zimbabwe" },
        zib: { official: "Republic of Zimbabwe", common: "Zimbabwe" },
      },
    },
    tld: [".zw"],
    cca2: "ZW",
    ccn3: "716",
    cca3: "ZWE",
    cioc: "ZIM",
    independent: true,
    status: "officially-assigned",
    unMember: true,
    currencies: {
      BWP: { name: "Botswana pula", symbol: "P" },
      CNY: { name: "Chinese yuan", symbol: "\u00a5" },
      EUR: { name: "Euro", symbol: "\u20ac" },
      GBP: { name: "British pound", symbol: "\u00a3" },
      INR: { name: "Indian rupee", symbol: "\u20b9" },
      JPY: { name: "Japanese yen", symbol: "\u00a5" },
      USD: { name: "United States dollar", symbol: "$" },
      ZAR: { name: "South African rand", symbol: "Rs" },
      ZWB: { name: "Zimbabwean bonds", symbol: "$" },
    },
    idd: { root: "+2", suffixes: ["63"] },
    capital: ["Harare"],
    altSpellings: ["ZW", "Republic of Zimbabwe"],
    region: "Africa",
    subregion: "Eastern Africa",
    languages: {
      bwg: "Chibarwe",
      eng: "English",
      kck: "Kalanga",
      khi: "Khoisan",
      ndc: "Ndau",
      nde: "Northern Ndebele",
      nya: "Chewa",
      sna: "Shona",
      sot: "Sotho",
      toi: "Tonga",
      tsn: "Tswana",
      tso: "Tsonga",
      ven: "Venda",
      xho: "Xhosa",
      zib: "Zimbabwean Sign Language",
    },
    translations: {
      ces: { official: "Zimbabwsk\u00e1 republika", common: "Zimbabwe" },
      deu: { official: "Republik Simbabwe", common: "Simbabwe" },
      est: { official: "Zimbabwe Vabariik", common: "Zimbabwe" },
      fin: { official: "Zimbabwen tasavalta", common: "Zimbabwe" },
      fra: { official: "R\u00e9publique du Zimbabwe", common: "Zimbabwe" },
      hrv: { official: "Republika Zimbabve", common: "Zimbabve" },
      hun: {
        official: "Zimbabwei K\u00f6zt\u00e1rsas\u00e1g",
        common: "Zimbabwe",
      },
      ita: { official: "Repubblica dello Zimbabwe", common: "Zimbabwe" },
      jpn: {
        official: "\u30b8\u30f3\u30d0\u30d6\u30a8\u5171\u548c\u56fd",
        common: "\u30b8\u30f3\u30d0\u30d6\u30a8",
      },
      kor: {
        official: "\uc9d0\ubc14\ube0c\uc6e8 \uacf5\ud654\uad6d",
        common: "\uc9d0\ubc14\ube0c\uc6e8",
      },
      nld: { official: "Republiek Zimbabwe", common: "Zimbabwe" },
      per: {
        official:
          "\u062c\u0645\u0647\u0648\u0631\u06cc \u0632\u06cc\u0645\u0628\u0627\u0628\u0648\u0647",
        common: "\u0632\u06cc\u0645\u0628\u0627\u0628\u0648\u0647",
      },
      pol: { official: "Republika Zimbabwe", common: "Zimbabwe" },
      por: { official: "Rep\u00fablica do Zimbabwe", common: "Zimbabwe" },
      rus: {
        official:
          "\u0420\u0435\u0441\u043f\u0443\u0431\u043b\u0438\u043a\u0430 \u0417\u0438\u043c\u0431\u0430\u0431\u0432\u0435",
        common: "\u0417\u0438\u043c\u0431\u0430\u0431\u0432\u0435",
      },
      slk: { official: "Zimbabwianska republika", common: "Zimbabwe" },
      spa: { official: "Rep\u00fablica de Zimbabue", common: "Zimbabue" },
      swe: { official: "Republiken Zimbabwe", common: "Zimbabwe" },
      urd: {
        official:
          "\u062c\u0645\u06c1\u0648\u0631\u06cc\u06c1 \u0632\u0645\u0628\u0627\u0628\u0648\u06d2",
        common: "\u0632\u0645\u0628\u0627\u0628\u0648\u06d2",
      },
      zho: {
        official: "\u6d25\u5df4\u5e03\u97e6\u5171\u548c\u56fd",
        common: "\u6d25\u5df4\u5e03\u97e6",
      },
    },
    latlng: [-20, 30],
    landlocked: true,
    borders: ["BWA", "MOZ", "ZAF", "ZMB"],
    area: 390757,
    flag: "\ud83c\uddff\ud83c\uddfc",
    demonyms: {
      eng: { f: "Zimbabwean", m: "Zimbabwean" },
      fra: { f: "Zimbabw\u00e9enne", m: "Zimbabw\u00e9en" },
    },
    callingCodes: ["+263"],
  },
];

// COUNTRY_CODES.map((country) => ({
//   name: country.name.common,
//   callingCode: country.callingCodes[0],
// }));

export default function countryCodes() {
  return COUNTRY_CODES;
}
