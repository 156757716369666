import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import EditProfile from "../components/user/EditProfile";
import FlightHistory from "../components/user/FlightHistory";
import Notifications from "../components/user/Notifications";
import Security from "../components/user/Security";
import AllCtx from "../uti-functions/allCtx";
import Profile from "../images/profile.png";
import Royalty from "../images/royalty.png";

function ProfilePage() {
  let navigate = useNavigate();
  const { user } = AllCtx();

  const [profileSelected, setProfileSelected] = useState(true);
  const [historySelected, setHistorySelected] = useState(false);
  const [notificationsSelected, setNotificationsSelected] = useState(false);
  const [securitySelected, setSecuritySelected] = useState(false);
  const [royaltiesSelected, setRoyaltiesSelected] = useState(false);

  function selectProfile() {
    setProfileSelected(true);
    setHistorySelected(false);
    setNotificationsSelected(false);
    setSecuritySelected(false);
    setRoyaltiesSelected(false);
  }

  function selectHistory() {
    setProfileSelected(false);
    setHistorySelected(true);
    setNotificationsSelected(false);
    setSecuritySelected(false);
    setRoyaltiesSelected(false);
  }

  function selectSecurity() {
    setProfileSelected(false);
    setHistorySelected(false);
    setNotificationsSelected(false);
    setSecuritySelected(true);
    setRoyaltiesSelected(false);
  }

  useEffect(() => {
    if (!localStorage.getItem("userToken")) {
      navigate("/");
    }
  }, []);

  return (
    <div className="">
      <div className="services-header-bg text-2xl md:text-3xl lg:text-4xl">
        {" "}
        <h2 className="text-3xl md:text-4xl">Profile</h2>{" "}
      </div>
      <div className={` pl-4 md:pl-8 mt-16 mb-10`}>
        <div
          className={`font-semibold text-[#181d20] text-lg md:text-xl flex items-center justify-between pr-2`}
        >
          <div className="flex items-center text-sm 450:text-base md:text-xl">
            {" "}
            <span className="mr-2 450:mr-4">
              <img
                className="h-7 w-7 450:h-10 450:w-10 lg:w-14 md:h-14"
                src={Profile}
                alt=""
              />
            </span>{" "}
            <span className="mr-2">{user.firstname} </span>{" "}
            <span>{user.lastname}</span>{" "}
          </div>{" "}
          <div className="flex items-center space-x-3">
            <div className="text-right -space-y-1 text-[#181d20]">
              <p className="text-xs 450:text-sm">Royalty Count</p>{" "}
              <p className="text-xl">12</p>
            </div>{" "}
            <div>
              <img
                className="h-11 w-9 450:h-14 450:w-12"
                src={Royalty}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between  pl-4 md:pl-8 pr-2 md:pr-4">
        {/* Left Tabs */}
        <div className=" 400:w-[30%] pr-2 shadow-md rounded-md mr-8 ">
          <div
            onClick={selectProfile}
            className={`px-2 py-2 duration-300 cursor-pointer ${
              !profileSelected ? "hover:bg-gray-100" : ""
            }  ${
              profileSelected
                ? "bg-sec-color text-white shadow-md"
                : "text-gray-400"
            } rounded-md mb-4 `}
          >
            <p className={`text-xs 450:text-sm sm:text-base`}>Profile</p>
          </div>

          <div
            onClick={selectHistory}
            className={`px-2 py-2 duration-300 cursor-pointer ${
              !historySelected ? "hover:bg-gray-100" : ""
            }  ${
              historySelected
                ? "bg-sec-color text-white shadow-md"
                : "text-gray-400"
            } rounded-md mb-4 `}
          >
            <p className={`text-xs 450:text-sm sm:text-base`}>Flight History</p>
          </div>

          {/* <div
            onClick={selectNotifications}
            className={`px-2 py-2 duration-300 cursor-pointer ${
              !notificationsSelected ? "hover:bg-gray-100" : ""
            }  ${
              notificationsSelected
                ? "bg-sec-color text-white shadow-md"
                : "text-gray-400"
            } rounded-md mb-4 `}
          >
            <p className={`text-xs 450:text-sm sm:text-base`}>Notifications</p>
          </div> */}

          <div
            onClick={selectSecurity}
            className={`px-2 py-2 duration-300 cursor-pointer ${
              !securitySelected ? "hover:bg-gray-100" : ""
            }  ${
              securitySelected
                ? "bg-sec-color text-white shadow-md"
                : "text-gray-400"
            } rounded-md mb-4 `}
          >
            <p className={`text-xs 450:text-sm sm:text-base`}>Security</p>
          </div>

          {/* <div
            onClick={selectRoyalties}
            className={`px-2 py-2 duration-300 cursor-pointer ${
              !royaltiesSelected ? "hover:bg-gray-100" : ""
            }  ${
              royaltiesSelected
                ? "bg-sec-color text-white shadow-md"
                : "text-gray-400"
            } rounded-md mb-4 `}
          >
            <p className={`text-xs 450:text-sm sm:text-base`}>Royalties</p>
          </div> */}
        </div>

        {profileSelected && <EditProfile />}
        {historySelected && <FlightHistory />}
        {notificationsSelected && <Notifications />}
        {securitySelected && <Security />}
        {/* {royaltiesSelected && <Royalties />} */}
      </div>
    </div>
  );
}

export default ProfilePage;
