export const Overview = [
    {
        "title": "Our Mission",
        "content": "Desun Airlines Limited provides safety and timely aviation services to customers through efficient departure control systems (DCS) and customer friendly services.",
        "icon": "our-mission"
    },
    {
        "title": "Our Vision",
        "content": "Desun Airlines Vision is to be the unbeatable and time conscious Airlines in the aviation industry where safety, efficiency and timeliness are paramount.",
        "icon": "our-vision"
    },
    {
        "title": "Financial Information",
        "content": "Desun Airlines Limited is seeking investors to grow the Desun Air to provide safe, affordable, timeliness and efficient technological driven aviation services to a wider client. The founder and his team are seeking investors with thirst for efficiency, hunger for sustainability and drive for growth to come on board.",
        "icon": "financial-info"
    },
    {
        "title": "Our Aircraft",
        "content": "With an initial four (4) Embraer aircrafts; ERJ145XR and E190-ER, Desun Airlines Limited is poised to be the leading commercial airline in Nigeria in the next few years. The aircrafts shall provide both passengers and cargoes air lifting across Nigeria. At its onset, it shall provide aviation commercial services across a number of states in Nigeria.",
        "icon": "our-aircraft"
    }
];

export const Board = [
    {
        "name": "General Ademola Fadunsin GSS fdc rtd",
        "content": "General Ademola Fadunsin GSS fdc rtd, has served his country meritoriously with 34 years in active service in the Nigerian Army. He is a successful entrepreneur and the CEO of A.O. Demarg Nigeria Limited and Desun Solar Nigeria Limited; both companies are leading producers and distributors of quality solar solutions across Nigeria. He is the Founder of Desun Airlines Limited..",
        "title": "Chairman/CEO",
        "image": "board-directors"
    },
];

export const Management = [
    {
        "name": "Ayo Fadunsin",
        "content": "Ayo Fadunsin is a Director as well as a commercial pilot.",
        "title": "Managing Director",
        "image": "board-directors"
    },
];