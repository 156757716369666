import React from "react";
import BookTripTabForm from "../components/tab/BookTripTabForm";
import FlightDetailsOrder from "../components/booking/FlightDetailsOrder";
import PaymentDetails from "../components/booking/PaymentDetails";
import AdultDetails from "../components/booking/AdultDetails";
import ChildrenDetails from "../components/booking/ChildrenDetails";
import InfantDetails from "../components/booking/InfantDetails";

function BookingPage() {
  return (
    <div>
      <div className="  services-header-bg text-2xl md:text-3xl lg:text-4xl">
        <p>My Booking</p>
      </div>
      <BookTripTabForm btnText="Search Again" />
      <div className="md:flex w-full md:space-x-2 ">
        <div className=" px-8 md:w-[60%] overflow-y-scroll h-screen">
          <FlightDetailsOrder />

          <AdultDetails />
          <ChildrenDetails />
          <InfantDetails />
        </div>

        <PaymentDetails />
      </div>
    </div>
  );
}

export default BookingPage;
