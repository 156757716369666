import React from 'react'

function Notifications() {
  return (
    <div className="w-[80%]">
      <div className="cursor-pointer mb-4">
        <p className="text-pry-color font-semibold ">Notifications</p>
      </div>


      <div className=' items-center  '>        
      <div className='flex '>
        <div className='bg-[#F0F1F7] p-1 text-xs 500:text-base flex items-center justify-center rounded-full w-16 500:w-24 mr-4 h-16 500:h-24 '>
          <div>
          <div className='flex justify-center font-semibold text-pry-color'><p className='font-bold'>29 </p></div>
        <div className='flex justify-center font-semibold text-pry-color'><p>June </p></div>
          <div className='flex justify-center font-semibold text-pry-color'><p>2022 </p></div>
        </div>
        </div>
        
        <div className='flex items-center  text-gray-500'>
          <div className='500:space-y-2'>
          <div><p className='font-bold text-sm 500:text-base'>Flight Booking</p></div>
          <div><p className='text-xs 500:text-sm font-semibold'>Single Trip (Abuja to Lagos)</p></div>
          <div><p className='text-xs 500:text-sm'>Flight scheduled to 15-07-2022 </p></div>
         </div>
        </div>
      </div>
      </div>
      

      <div className=' items-center mt-4 '>        
      <div className='flex '>
        <div className='bg-[#F0F1F7] p-1 text-xs 500:text-base flex items-center justify-center rounded-full w-16 500:w-24 mr-4 h-16 500:h-24 '>
          <div>
          <div className='flex justify-center font-semibold text-pry-color'><p className='font-bold'>29 </p></div>
        <div className='flex justify-center font-semibold text-pry-color'><p>June </p></div>
          <div className='flex justify-center font-semibold text-pry-color'><p>2022 </p></div>
        </div>
        </div>
        
        <div className='flex items-center  text-gray-500'>
          <div className='500:space-y-2'>
          <div><p className='font-bold text-sm 500:text-base'>Flight Rescheduling</p></div>
          <div><p className='text-xs 500:text-sm font-semibold'>Single Trip (Abuja to Lagos)</p></div>
          <div><p className='text-xs 500:text-sm'>Flight rescheduled to 15-08-2022 </p></div>
         </div>
        </div>
      </div>
      </div>
      
      <div className=' items-center mt-4 '>        
      <div className='flex '>
        <div className='bg-[#F0F1F7] p-1 text-xs 500:text-base flex items-center justify-center rounded-full w-16 500:w-24 mr-4 h-16 500:h-24 '>
          <div>
          <div className='flex justify-center font-semibold text-pry-color'><p className='font-bold'>29 </p></div>
        <div className='flex justify-center font-semibold text-pry-color'><p>June </p></div>
          <div className='flex justify-center font-semibold text-pry-color'><p>2022 </p></div>
        </div>
        </div>
        
        <div className='flex items-center  text-gray-500'>
          <div className='500:space-y-2'>
          <div><p className='font-bold text-sm 500:text-base'>Flight Check-in</p></div>
          <div><p className='text-xs 500:text-sm font-semibold'>Single Trip (Abuja to Lagos)</p></div>
          <div><p className='text-xs 500:text-sm'>You checked in on 15-08-2022 </p></div>
         </div>
        </div>
      </div>
</div>
      

      
    </div>
  )
}

export default Notifications