import React, { useEffect, useState } from "react";
import AllCtx from "../../uti-functions/allCtx";

function AdultDetails() {
  const {
    // monitor,
    adult,
    children,
    infant,
    allPassengers,

    tripType,
    flightArray,
    setTheFlightArray,
    chosenFlight,
    chosenFlightID,
    setTheChosenClass,
    setTheChosenClassPrice,
    chosenClass,
    chosenClassName,
    chosenClassPrice,
    setTheChosenFlight,
    setTheChosenFlightID,
    searchBookingResponse,
    setTheSearchBookingResponse,
    setTheChosenClassName,
    setTheClassChosen,
    classChosen,
    setTheSearchResultResponse,
    searchResultResponse,
    setTheAllPassengers,
    setTheAdultArray,
    adultArray,
  } = AllCtx();

  useEffect(() => {
    if (localStorage.getItem("adultArray")) {
      setTheAdultArray(JSON.parse(localStorage.getItem("adultArray")));
  }
 },[])

  const [adultTitle, setAdultTitle] = useState("");
  const [adultGender, setAdultGender] = useState("");
  const [adultFirstName, setAdultFirstName] = useState("");
  const [adultLastName, setAdultLastName] = useState("");
  const [adultPhone, setAdultPhone] = useState("");
  const [adultEmail, setAdultEmail] = useState("");
  const [adultConfirmEmail, setAdultConfirmEmail] = useState("");
  const [passengers, setPassengers] = useState([allPassengers]);

  const [monitor, setMonitor] = useState(true);

  return (
    <div>
      {adultArray.map((Adult) => (
        // (console.log("Adult")),
        <div key={Adult.id} className="mt-10">
          <div>
            <p className="text-pry-color mb-2 font-semibold">
              {Adult.id === "Adult 1"
                ? `${Adult.id} Details (You)`
                : `${Adult.id} Details`}
            </p>
          </div>

          <div className=" border-2 rounded-md py-4 px-2 sm:px-5 md:px-2">
            <div className="mb-2">
              <p className="font-semibold text-sm">Passenger Info</p>
            </div>

            <div className="flex">
              <div className="w-20">
                <p>NAME: </p>
              </div>{" "}
              <div>
                  <p><span>{Adult.title.toUpperCase()} </span><span>{Adult.first_name.toUpperCase()}</span> <span>{ Adult.last_name.toUpperCase()}</span></p>
              </div>
            </div>

            <div className="flex">
              <div className="w-20">
                <p>GENDER: </p>
              </div>{" "}
              <div>
                <p>{Adult.gender.toUpperCase()} </p>
              </div>
            </div>

            <div className={`${Adult.id !== "Adult 1" ? "hidden" : ""} flex`}>
              <div className="w-20">
                <p>PHONE: </p>
              </div>{" "}
              <div>
                <p>{Adult.c_phone} </p>
              </div>
            </div>

            <div className={`${Adult.id !== "Adult 1" ? "hidden" : ""} flex`}>
              <div className="w-20">
                <p>EMAIL: </p>
              </div>{" "}
              <div>
                <p>{Adult.c_email} </p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default AdultDetails;
