import React, { useEffect } from "react";

import AllCtx from "../uti-functions/allCtx";
import SearchResultsDeskView from "../components/SearchResultsDeskView";
import SearchResultMobileView from "../components/SearchResultMobileView";

function SearchResultPage() {
  const { prev, next, flightArray, setTheFlightArray, setThePrev, setTheNext } =
    AllCtx();

  const flightArrayLength = flightArray.map((f) => f).map((f) => f.length) - 0;

  useEffect(() => {
    if (localStorage.getItem("flightArray")) {
      setTheFlightArray(JSON.parse(localStorage.getItem("flightArray")));
    }
  }, []);

  return (
    <div className="">
      <div className="  services-header-bg text-2xl md:text-3xl lg:text-4xl">
        {" "}
        <p>Available Flights</p>{" "}
      </div>

      <SearchResultMobileView />
      <SearchResultsDeskView />

      <div className="flex justify-center space-x-5 py-4">
        {/* Go to first  */}
        <button
          disabled={prev <= 0}
          className={`text-4xl ${
            prev <= 0 ? "text-gray-400" : "text-pry-color"
          } font-bold`}
          onClick={
            prev > 0
              ? () => {
                  setThePrev(0);
                  setTheNext(5);
                  // console.log(prev, next);
                }
              : () => {
                  setThePrev(prev);
                }
          }
        >
          &lsaquo;&lsaquo;
        </button>
        {/* Go to previous */}
        <button
          disabled={prev <= 0}
          className={`text-4xl ${
            prev <= 0 ? "text-gray-400" : "text-sec-color"
          } font-bold`}
          onClick={
            prev > 0
              ? () => {
                  // console.log(prev, next);
                  if (next === flightArrayLength) {
                    setTheNext(prev);
                    setThePrev(prev - 5);
                  } else {
                    setThePrev(prev - 5);
                    setTheNext(next - 5);
                  }
                }
              : () => {
                  setThePrev(prev);
                }
          }
        >
          &lsaquo;
        </button>

        {/* Go to next   */}
        <button
          disabled={next >= flightArrayLength}
          className={`text-4xl ${
            next >= flightArrayLength ? "text-gray-400" : "text-sec-color"
          } font-bold`}
          onClick={
            next <= flightArrayLength
              ? () => {
                  console.log(next, flightArrayLength);
                  setTheNext(next + 5);
                  setThePrev(prev + 5);
                  // console.log(prev, next);
                }
              : () => {
                  setThePrev(prev);
                }
          }
        >
          &rsaquo;
        </button>
        {/* Go to last   */}
        <button
          disabled={next >= flightArrayLength}
          className={`text-4xl ${
            next >= flightArrayLength ? "text-gray-400" : "text-pry-color"
          } font-bold`}
          onClick={
            next <= flightArrayLength
              ? () => {
                  setThePrev(next);
                  setTheNext(flightArrayLength);

                  // console.log(prev, next);
                }
              : () => {
                  setThePrev(prev);
                }
          }
        >
          &rsaquo;&rsaquo;
        </button>
      </div>
    </div>
  );
}

export default SearchResultPage;
