import React from "react";
import AllCtx from "../../uti-functions/allCtx";
import Departure from "../../images/flight-take-off.png";
import Return from "../../images/landing-flight.png";

var date;
var weekDay;
var month;
var day;
var year;

var returnDate;
var returnWeekDay;
var returnMonth;
var returnDay;
var returnYear;

function FlightHistory() {
  const {
    flightHistoryPrev,
    setTheFlightHistoryPrev,
    setTheFlightHistoryNext,
    flightHistoryNext,
    flightArray,
    user,
    setTheUser,
    userToken,
  } = AllCtx();

  const flightHistoryLength = user.flights.map((f) => f).map((f) => f.length) - 0;


  return (
    <div className="w-[80%]">
      <div className="cursor-pointer mb-4">
        <p className="text-pry-color font-semibold ">Flight History</p>
      </div>

      {user.flights ? user.flights
        ?.map(
          (flightHistory, i) => (
            (date = new Date(flightHistory.departure_date)),
            (weekDay = date.toLocaleDateString("en-US", { weekday: "short" })),
            (day = date.toLocaleDateString("en-US", { day: "numeric" })),
            (month = date.toLocaleDateString("en-US", { month: "short" })),
            (year = date.toLocaleDateString("en-US", { year: "numeric" })),
            (returnDate = new Date(flightHistory.arrival_date)),
            (returnWeekDay = returnDate.toLocaleDateString("en-US", {
              weekday: "short",
            })),
            (returnDay = returnDate.toLocaleDateString("en-US", {
              day: "numeric",
            })),
            (returnMonth = returnDate.toLocaleDateString("en-US", {
              month: "short",
            })),
            (returnYear = returnDate.toLocaleDateString("en-US", {
              year: "numeric",
            })),
            (
              <div key={i} className="px-2 450:px-5 md:px-10  bg-[#F0F1F7] py-4 shadow-md rounded-lg">
                <div>
                  <div className="flex mb-3 text-sm 400:text-base ">
                    <div className="mr-2 hidden 400:block">
                      <img src={Departure} alt="" />
                    </div>
                    <div className="mr-2">
                      <p className="font-semibold">Departure</p>
                    </div>
              
                  
                      <div className="">
                        <p className="text-sec-color">({flightHistory.class.name})</p>
                      </div>
       
                  </div>
                  {/* <div><p className='text-xs'>{ flightHistory.departing_airport}</p></div> */}
                  <div className="900:flex justify-between">
                    <div>
                      <p className="font-semibold text-pry-color text-sm 340:text-base   ">
                        {" "}
                        {`${weekDay}, ${day} ${month} ${year}`}
                      </p>
                    </div>{" "}
                    <div className="text-pry-color">
                      <div className="lg:space-x-5 flex justify-center space-x-2 md:font-semibold text-sm 340:text-sm 400:text-base">
                        <span>{flightHistory.from}</span>{" "}
                        <span className="text-sec-color">to</span>{" "}
                        <span>{flightHistory.to}</span>
                      </div>
                      <div className="flex justify-center space-x-2 text-gray-500 text-xs">
                        <span>{flightHistory.departure_time}</span>{" "}
                        <span>-</span>{" "}
                        <span>{flightHistory.arrival_time}</span>{" "}
                      </div>
                    </div>{" "}
                    <div>
                      <p className="text-right text-pry-color text-xs 900:text-base">
                        {flightHistory.duration}
                      </p>
                    </div>
                  </div>
                </div>

                {flightHistory.type === "round" && (
                  <div>
                    <hr className="my-3 border-pry-color  opacity-80 " />
                    <div>
                      <div className="flex mb-3 text-sm 400:text-base ">
                        <div className="mr-2 hidden 400:block">
                          <img src={Return} alt="" />
                        </div>
                        <div className="mr-2">
                          <p className="font-semibold">Return</p>
                        </div>
                   
                          <div className="">
                            <p className="text-sec-color">({flightHistory.class.name})</p>
                          </div>
                
                      </div>
                      {/* <div><p className='text-xs'>{ flightHistory.departing_airport}</p></div> */}
                      <div className="900:flex justify-between">
                        <div>
                          <p className="font-semibold text-pry-color text-sm 340:text-base   ">
                            {" "}
                            {`${returnWeekDay}, ${returnDay} ${returnMonth} ${returnYear}`}
                          </p>
                        </div>{" "}
                        <div className="text-pry-color">
                          <div className="lg:space-x-5 flex justify-center space-x-2 md:font-semibold text-sm 340:text-sm 400:text-base">
                            <span>{flightHistory.to}</span>{" "}
                            <span className="text-sec-color">to</span>{" "}
                            <span>{flightHistory.from}</span>
                          </div>
                          <div className="flex justify-center space-x-2 text-gray-500 text-xs">
                            <span>{flightHistory.return_departure_time}</span>{" "}
                            <span>-</span>{" "}
                            <span>{flightHistory.return_arrival_time}</span>{" "}
                          </div>
                        </div>{" "}
                        <div>
                          <p className="text-right text-pry-color text-xs 900:text-base">
                            {flightHistory.duration}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )
          )
        )
        .filter((a, i) => i >= flightHistoryPrev && i < flightHistoryNext) : <div><p>You have not had any trip with us yet.</p></div> }

      <div className="flex justify-center space-x-5 py-4">
        {/* Go to first  */}
        <button
          disabled={flightHistoryPrev <= 0}
          className={`text-4xl ${
            flightHistoryPrev <= 0 ? "text-gray-400" : "text-pry-color"
          } font-bold`}
          onClick={
            flightHistoryPrev > 0
              ? () => {
                  setTheFlightHistoryPrev(0);
                  setTheFlightHistoryNext(5);
                  // console.log(flightHistoryPrev, flightHistoryNext);
                }
              : setTheFlightHistoryPrev(flightHistoryPrev)
          }
        >
          &lsaquo;&lsaquo;
        </button>
        {/* Go to previous */}
        <button
          disabled={flightHistoryPrev <= 0}
          className={`text-4xl ${
            flightHistoryPrev <= 0 ? "text-gray-400" : "text-sec-color"
          } font-bold`}
          onClick={
            flightHistoryPrev > 0
              ? () => {
                  // console.log(flightHistoryPrev, flightHistoryNext);
                  if (flightHistoryNext === flightHistoryLength) {
                    setTheFlightHistoryNext(flightHistoryPrev);
                    setTheFlightHistoryPrev(flightHistoryPrev - 5);
                  } else {
                    setTheFlightHistoryPrev(flightHistoryPrev - 5);
                    setTheFlightHistoryNext(flightHistoryNext - 5);
                  }
                }
              : setTheFlightHistoryPrev(flightHistoryPrev)
          }
        >
          &lsaquo;
        </button>

        {/* Go to flightHistoryNext   */}
        <button
          disabled={flightHistoryNext >= flightHistoryLength}
          className={`text-4xl ${
            flightHistoryNext >= flightHistoryLength
              ? "text-gray-400"
              : "text-sec-color"
          } font-bold`}
          onClick={
            flightHistoryNext <= flightHistoryLength
              ? () => {
                  console.log(flightHistoryNext, flightHistoryLength);
                  setTheFlightHistoryNext(flightHistoryNext + 5);
                  setTheFlightHistoryPrev(flightHistoryPrev + 5);
                  // console.log(flightHistoryPrev, flightHistoryNext);
                }
              : setTheFlightHistoryPrev(flightHistoryPrev)
          }
        >
          &rsaquo;
        </button>
        {/* Go to last   */}
        <button
          disabled={flightHistoryNext >= flightHistoryLength}
          className={`text-4xl ${
            flightHistoryNext >= flightHistoryLength
              ? "text-gray-400"
              : "text-pry-color"
          } font-bold`}
          onClick={
            flightHistoryNext <= flightHistoryLength
              ? () => {
                  setTheFlightHistoryPrev(flightHistoryNext);
                  setTheFlightHistoryNext(flightHistoryLength);

                  // console.log(flightHistoryPrev, flightHistoryNext);
                }
              : setTheFlightHistoryPrev(flightHistoryPrev)
          }
        >
          &rsaquo;&rsaquo;
        </button>
      </div>
    </div>
  );
}

export default FlightHistory;
