import React, { useEffect, useState } from "react";
import countryCodeFunction from "../../store/country-codes";

import AllCtx from "../../uti-functions/allCtx";

function ManageAdultDetails() {
  const countryCodes = countryCodeFunction();



  const {
    // monitor,
    adult,
    children,
    infant,
    allPassengers,

    tripType,
    flightArray,
    setTheFlightArray,
    chosenFlight,
    chosenFlightID,
    setTheChosenClass,
    setTheChosenClassPrice,
    chosenClass,
    chosenClassName,
    chosenClassPrice,
    setTheChosenFlight,
    setTheChosenFlightID,
    searchBookingResponse,
    setTheSearchBookingResponse,
    setTheChosenClassName,
    setTheClassChosen,
    classChosen,
    setTheSearchResultResponse,
    searchResultResponse,
    setTheAllPassengers,
    setTheAdultArray,
    adultArray,
    setTheChildrenArray, setTheInfantArray
  } = AllCtx(); 

  var { childrenArray, infantArray } = AllCtx();

  const [adultTitle, setAdultTitle] = useState("");
  const [adultGender, setAdultGender] = useState("");
  const [adultFirstName, setAdultFirstName] = useState("");
  const [adultLastName, setAdultLastName] = useState("");
  const [adultPhone, setAdultPhone] = useState("");
  const [adultEmail, setAdultEmail] = useState("");
  const [adultConfirmEmail, setAdultConfirmEmail] = useState("");

  const [monitor, setMonitor] = useState(true);

  const [countryCode, setCountryCode] = useState("+234");

  const [localPhone, setLocalPhone] = useState("");

  useEffect(() => {
    if (localStorage.getItem("adultArray")) {
      setTheAdultArray(JSON.parse(localStorage.getItem("adultArray")));
  }
 },[])

  function handleAdultTitleChange(adultID, titlePicked) {
    let newAdultArray = [...adultArray];
    let adultInputToChange = newAdultArray.find(
      (adult) => adult.id === adultID
    );

    adultInputToChange.title = titlePicked;

    setTheAdultArray(newAdultArray);
    setAdultTitle(adultInputToChange.title);

    return adultInputToChange.title;
  }

  function handleAdultGenderChange(adultID, genderPicked) {
    let newAdultArray = [...adultArray];
    let adultInputToChange = newAdultArray.find(
      (adult) => adult.id === adultID
    );
    adultInputToChange.gender = genderPicked;
    setTheAdultArray(newAdultArray);
    setAdultGender(adultInputToChange.gender);
    return adultInputToChange.gender;
  }

  function handleAdultFirstNameChange(adultID, firstNameTyped) {
    let newAdultArray = [...adultArray];
    let adultInputToChange = newAdultArray.find(
      (adult) => adult.id === adultID
    );
    adultInputToChange.first_name = firstNameTyped;
    setTheAdultArray(newAdultArray);
    setAdultFirstName(adultInputToChange.first_name);

    return adultInputToChange.first_name;
  }

  function handleAdultLastNameChange(adultID, lastNameTyped) {
    let newAdultArray = [...adultArray];
    let adultInputToChange = newAdultArray.find(
      (adult) => adult.id === adultID
    );
    adultInputToChange.last_name = lastNameTyped;
    setTheAdultArray(newAdultArray);
    setAdultLastName(adultInputToChange.last_name);
    return adultInputToChange.last_name;
  }

  function handleAdultPhoneChange(adultID, adultPhoneTyped) {
    let newAdultArray = [...adultArray];
    let adultInputToChange = newAdultArray.find(
      (adult) => adult.id === adultID
    );
    adultInputToChange.c_phone = adultPhoneTyped;
    setTheAdultArray(newAdultArray);
    setAdultPhone(adultInputToChange.c_phone);
    return adultInputToChange.c_phone;
  }

  function handleAdultEmailChange(adultID, adultEmailTyped) {
    let newAdultArray = [...adultArray];
    let adultInputToChange = newAdultArray.find(
      (adult) => adult.id === adultID
    );
    adultInputToChange.c_email = adultEmailTyped;
    setTheAdultArray(newAdultArray);
    setAdultEmail(adultInputToChange.c_email);
    return adultInputToChange.c_email;
}


  return (
    <div>
      {adultArray.map((Adult) => (
        <div key={Adult.id} className="mt-4">
          <div>
            <p className="text-pry-color mb-2 font-semibold">
              {Adult.id === "Adult 1"
                ? `${Adult.id} Details (You)`
                : `${Adult.id} Details`}
            </p>
          </div>

          <div className=" border-2 rounded-md py-4 px-2 sm:px-5 md:px-2">
            <div className="mb-2">
              <p className="font-semibold text-sm">Passenger Info</p>
            </div>
            <div className="md:flex md:space-y-0 space-y-4 justify-between mb-3">
              {/* Title Select  */}
              <div className="md:w-[15%]">
                <div className="text-sm text-gray-500">Title</div>
                <select
                  className="text-xs w-full border-gray-300 rounded border-1"
                  name="title"
                  id="title"
                    onClick={() => {
                    setTheAllPassengers(adultArray, childrenArray, infantArray);
                  }}
                  onChange={(e) => {
                    handleAdultTitleChange(Adult.id, e.target.value);
                    console.log(Adult.title);

                    console.log("adultArray", adultArray);
                    setTheAllPassengers(adultArray, childrenArray, infantArray);
                    console.log("allPa", allPassengers);
                  }}
                  value={Adult.title}
                >
                  {/* <option value="">Title</option> */}
                  <option value="Mr">Mr</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Miss">Miss</option>
                  <option value="Hon">Hon</option>
                  <option value="Prof">Prof</option>
                  <option value="Engnr">Engnr</option>
                  <option value="Dr">Dr</option>
                  <option value="Chief">Chief</option>
                  <option value="King">King</option>
                  <option value="Emir">Emir</option>
                </select>
              </div>
              {/*  First Name   */}
              <div className="md:w-[26%]">
                <div className="text-sm text-gray-500">First Name</div>{" "}
                <input
                  placeholder="First Name"
                    onClick={() => {
                    setTheAllPassengers(adultArray, childrenArray, infantArray);
                  }}
                  onChange={(e) => {
                    handleAdultFirstNameChange(Adult.id, e.target.value);

                    console.log("adultArray", adultArray);
                    setTheAllPassengers(adultArray, childrenArray, infantArray);
                    console.log("allPa", allPassengers);
                  }}
                  className="text-xs w-full border-gray-300 rounded border-1"
                  type="text"
                  name=""
                  id=""
                  value={Adult.first_name}
                />
              </div>

              {/*  Last Name   */}
              <div className="md:w-[26%] ">
                <div className="text-sm text-gray-500">Last Name</div>{" "}
                <input
                  placeholder="Surname"
                    onClick={() => {
                    setTheAllPassengers(adultArray, childrenArray, infantArray);
                  }}
                  onChange={(e) => {
                    handleAdultLastNameChange(Adult.id, e.target.value);

                    console.log("adultArray", adultArray);
                    setTheAllPassengers(adultArray, childrenArray, infantArray);
                    console.log("allPa", allPassengers);
                  }}
                  className="text-xs w-full border-gray-300 rounded border-1"
                  type="text"
                  name=""
                  id=""
                  value={Adult.last_name}
                />
              </div>

              {/* Gender Select  */}

              <div className="md:w-[26%]">
                <div className="text-sm text-gray-500" htmlFor="">
                  Gender
                </div>
                <select
                  className="text-xs w-full border-gray-300 rounded border-1"
                  name="title"
                  id="title"
                    onClick={() => {
                    setTheAllPassengers(adultArray, childrenArray, infantArray);
                  }}
                  onChange={(e) => {
                    handleAdultGenderChange(Adult.id, e.target.value);
                    console.log(Adult.gender);

                    console.log("adultArray", adultArray);
                    setTheAllPassengers(adultArray, childrenArray, infantArray);
                    console.log("allPa", allPassengers);
                  }}
                  value={Adult.gender}
                >
                  <option value="">Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Undisclosed">Undisclosed</option>
                </select>
              </div>
            </div>
            <div className={`${Adult.id !== "Adult 1" ? "hidden" : ""}`}>
              <div className="mb-2">
                <p className="font-semibold text-sm">Contact Details</p>
              </div>
              <div className="md:flex justify-between md:space-x-1 mb-3 md:space-y-0 space-y-4">
                {/* Phone  */}
                <div className="md:w-[100%]">
                  <div className="text-sm text-gray-500">Phone Number</div>
                  <div className="flex  ">
                    <select
                      onClick={() => {
                        setTheAllPassengers(
                          adultArray,
                          childrenArray,
                          infantArray
                        );
                      }}
                      onChange={(e) => {
                         setCountryCode(e.target.value)
                        handleAdultPhoneChange(
                          "Adult 1",
                          `${e.target.value}${localPhone}`
                        );

                       

                        console.log("adultArray", adultArray);
                        setTheAllPassengers(
                          adultArray,
                          childrenArray,
                          infantArray
                        );
                        console.log("allPa", allPassengers);
                      }}
                      name=""
                      id=""
                      className="!appearance-none text-xs w-[40%] md:w-[40%] border-gray-300 rounded border-1"
                    >
                      <option value="+234"> Nigeria (+234)</option>
                      {countryCodes.map((country) => (
                        <option key={country.name.common} value={country.callingCodes[0] || "+234"}>{` ${
                          country.name.common ? country.name.common : "(LNG)"
                        }  (${
                          country.callingCodes[0]
                            ? country.callingCodes[0]
                            : "+982"
                        })`}</option>
                      ))}
                    </select>{" "}
                    <input
                      //          onMouseOver={() => {
                      //   setTheAllPassengers(adultArray, childrenArray, infantArray);
                      // }}
                      onClick={() => {
                        setTheAllPassengers(
                          adultArray,
                          childrenArray,
                          infantArray
                        );
                      }}
                      onChange={(e) => {
                        setLocalPhone(e.target.value)
                        handleAdultPhoneChange(
                          Adult.id,
                          `${countryCode}${e.target.value}`
                        );
                        setLocalPhone(e.target.value);
                        console.log("adultArray", adultArray);
                        setTheAllPassengers(
                          adultArray,
                          childrenArray,
                          infantArray
                        );
                        console.log("allPa", allPassengers);
                      }}
                      className="text-xs w-[90%] border-gray-300 rounded border-1"
                      placeholder={Adult.c_phone || "E.g 7030114611"}
                      type="number"
                      // value={Adult.c_phone}
                    />
                  </div>
                </div>
                {/* Email  */}
                <div disabled className="md:w-[45%]">
                  <div className="text-sm text-gray-500">Email</div>
                  <input
                      onClick={() => {
                    setTheAllPassengers(adultArray, childrenArray, infantArray);
                  }}
                    onChange={(e) => {
                      handleAdultEmailChange(Adult.id, e.target.value);

                      console.log("adultArray", adultArray);
                      setTheAllPassengers(
                        adultArray,
                        childrenArray,
                        infantArray
                      );
                      console.log("allPa", allPassengers);
                    }}
                    className="text-xs  w-full outline-1 border-solid border-gray-300 rounded border-1"
                    placeholder="Enter Email"
                    type="text"
                    value={Adult.c_email}
                    
                  />
                </div>{" "}
              
          
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ManageAdultDetails;
