import React, { useRef, useState } from "react";
import BookingReferenceIcon from "../../images/booking-reference.png";
import LastName from "../../images/lastname.png";
import { useNavigate } from "react-router-dom";
import AllCtx from "../../uti-functions/allCtx";

function ManageBookingTabForm() {
  const {
    setTheAllPassengers,
      setTheShowNotification, setTheNotificationText,
    user,
    adultArray,
    setTheFlightRefID,
    childrenArray,
    infantArray,
    userToken,
    setTheChosenClassName,
    chosenClassName,
    chosenClassPrice,
    setTheAdultArray,
    setTheChildrenArray,
    setTheInfantArray,
    setTheChosenClassPrice,
    setTheChosenClassID,
    setTheChosenFlightID,
    chosenFlight,
    chosenClassTax,
    chosenClassFees,
    chosenClassSurge,
        setTheChosenClassTax,
    setTheChosenClassFees,
    setTheChosenClassSurge,
    setTheTotalPrice, totalPrice, baseUrl, setThePassengerFlight,
    passengerFlight
  } = AllCtx();

  const [response, setResponse] = useState("");
    const [sending, setSending] = useState(false);
    const [canRunTimeOut, setCanRunTimeOut] = useState(true)
  let navigate = useNavigate();

  const referenceNumberRef = useRef();
  const lastNameRef = useRef();

  async function verifyReferenceID(e) {
e.preventDefault();

    const refNumInput = referenceNumberRef.current.value;
    const lastNameInput = lastNameRef.current.value;

   if(!refNumInput ||
    refNumInput.trim() === "" || !lastNameInput ||
     lastNameInput.trim() === "") {
     setResponse('You must provide the needed details.')
     return
    }

    try {
      
      setSending(true);
      setResponse("Please wait...");

      const response = await fetch(
        `${baseUrl}/server/api/desunapi/v1/manage_booking`,
        {
          method: "POST",
          body: JSON.stringify({
            _ref_id: refNumInput,
            _last_name: lastNameInput,
          }),
          headers: {
            "Content-Type": "application/json",
            Useremail: `${user.email}`,
            Usertoken: `${userToken}`,
          },
        }
      );

      const data = await response.json();
      console.log(data);

      // return

      if (!response.ok) {
        setResponse(data.message);
        setSending(false);
        return;
      }

      data.map(passFlight => {
        setThePassengerFlight(passFlight)
        localStorage.setItem("passengerFlight", JSON.stringify(passFlight));

      })

    

        setTheFlightRefID(refNumInput);
        
      data.map((flight) => {
        setTheChosenClassName(flight.class.name);
        localStorage.setItem(
          "chosenClassName",
          JSON.stringify(flight.class.name)
        );

        setTheChosenClassPrice(flight.class.cul_price);
        localStorage.setItem(
          "chosenClassPrice",
          JSON.stringify(flight.class.cul_price)
        );

        setTheChosenClassFees(flight.class.fees)
        setTheChosenClassSurge(flight.class.surcharges)
        setTheChosenClassTax(flight.class.taxes)
        setTheTotalPrice(flight.class.total_price)

        setTheAdultArray(
          flight.ps_details
            .map((passengers) => passengers)
            .filter((pass) => pass.type === "adult")
        );
        localStorage.setItem(
          "adultArray",
          JSON.stringify(
            flight.ps_details
              .map((passengers) => passengers)
              .filter((pass) => pass.type === "adult")
          )
        );
        // console.log(adultArray);

        setTheChildrenArray(
          flight.ps_details
            .map((passengers) => passengers)
            .filter((pass) => pass.type === "child")
        );
        localStorage.setItem(
          "childrenArray",
          JSON.stringify(
            flight.ps_details
              .map((passengers) => passengers)
              .filter((pass) => pass.type === "child")
          )
        );
        // console.log(childrenArray);

        setTheInfantArray(
          flight.ps_details
            .map((passengers) => passengers)
            .filter((pass) => pass.type === "infant")
        );
        localStorage.setItem(
          "infantArray",
          JSON.stringify(
            flight.ps_details
              .map((passengers) => passengers)
              .filter((pass) => pass.type === "infant")
          )
        );
        // console.log(infantArray);

        setTheAllPassengers(
          flight.ps_details
            .map((passengers) => passengers)
            .filter((pass) => pass.type === "adult"),
        
            flight.ps_details
            .map((passengers) => passengers)
            .filter((pass) => pass.type === "child"),
          
          flight.ps_details
          .map((passengers) => passengers)
          .filter((pass) => pass.type === "infant")
        );

        setTheChosenFlightID(flight.ID);
        localStorage.setItem("chosenFlight", JSON.stringify(chosenFlight));

        
          
          setTheShowNotification(true);
          setTheNotificationText("Booking details loaded!");
          navigate("/manage-booking");
          if (canRunTimeOut) {
            const timer = setTimeout(() => {
              setTheShowNotification(false);
              setTheNotificationText("");
            
            }, 4000);
          
            return () => clearTimeout(timer);
          }
          setResponse("");
          setSending(false);

      });
    } catch (error) {
      console.log(error);
      setSending(false);
      setResponse("An error occurred, please retry.");
    }
  }

  return (
    <form onSubmit={verifyReferenceID} className="tab-form">
      <div className="text-xl">
        <p>Manage your booking</p>
      </div>
      <div className="md:flex justify-between space-y-3 md:space-y-0 md:space-x-3 mt-4">
        <div className="manage-booking-reference-div pl-1">
          <div>
            <img src={BookingReferenceIcon} alt="" />
          </div>
          <input required 
            ref={referenceNumberRef}
            type="text"
            placeholder="Booking references"
            className="placeholder-pry-color border-none focus:outline-none focus:ring focus:ring-transparent w-full"
          />
        </div>
        <div className="manage-booking-lastname-div pl-1">
          <div>
            <img src={LastName} alt="" />
          </div>
          <input required
            ref={lastNameRef}
            type="text"
            placeholder="Last Name"
            className="placeholder-pry-color border-none focus:outline-none focus:ring focus:ring-transparent w-full"
          />
        </div>
        <div  className=" flex justify-end items-center   mt-5 px-3 md:px-0 
} ">
          <button type='submit' disabled={sending} className={`bg-sec-color text-white px-2 md:text-xs md:py-4 py-2 rounded-md`}>Manage Booking</button>
              </div>
            
          </div>
          <div className='flex justify-center mt-4 '><p className='text-sec-color text-base'>{ response}</p></div>
    </form>
  );
}

export default ManageBookingTabForm;
