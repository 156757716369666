import React from "react";
import { useNavigate } from "react-router-dom";

function Exclusive() {
  const navigate = useNavigate();
  return (
    <div>
      <div className="exclusive mt-32 py-24  text-center">
        <h1 className="text-white font-amsterdam font-light text-3xl pb-3">
          Exclusive Flight
        </h1>
        <p className="text-white mt-4  text-sm font-semibold">
          Book private jets, helicopters and get to your destination in class
        </p>
        <button
          onClick={() => {
            navigate("/services/private-hire");
          }}
          className="btn mt-14 px-3 py-1"
        >
          Find out more
        </button>
      </div>
    </div>
  );
}

export default Exclusive;
