const FAQ = [
  {
    question: "How do I make a reservation? ",
    answer:
      "From the Desun Air Navigation Bar, hover or click on the BOOKING link. Fill in the details of the flight you want, choose from the list of flights, fill the passenger details and Click on Continue Booking. Your booking ID will be generated. Know that the booking ID will be invalidated if not paid after 24 hours.",
  },
  {
    question: "Can I change the name on an existing reservation? ",
    answer:
      "Yes, you can. Having generated your booking ID without payment, you can hover or click on the BOOKING link on the Navigation Bar, choose Manage Booking, fill in your booking ID, and the last name of the principal adult, and hit Manage Booking. Edit the details you want and click on Continue Booking.",
  },
  {
    question: "Can the status of my flight be checked? ",
    answer:
      "Yes, you can check the status of your flight. From the Navigation Bar, hover or click on FLY link. Further click on Fly  Status, then input the details of your flight, and hit the Check button.",
  },
  {
    question: "Do I need to call your customer care service to reconfirm my flight?  ",
    answer:
      "Not compulsorily, but double-checking can be necessary.",
  },
  {
    question: "How do I reach out to the customer care for enquiries on other services aside booking a flight.",
    answer:
      "From the Navigation Bar links, hover or click on the ABOUT link. Choose the Contact Us button, and fill in your enquiries on the next page.",
  },
  {
    question: "Are there extra charges for passengers in wheelchairs? ",
    answer:
      "No.",
  },
  {
    question: "How do I check in online and when does check-in open/close? ",
    answer:
      "You can check-in your paid booking using your individual reference ID. From the Navigation Bar, hover or click on FLY. Click Check-in and fill your individual reference ID with your last-name. Pick your seat number and Check In to generate Boarding Pass. ",
  },
  {
    question: "Are there special rewards for flying with Desun Air? ",
    answer:
      "Yes. The more you fly with us, the higher your Royalty which can be used to make some payment in the nearest future. Terms and Conditions apply.",
  },
];


export default function faqs() {
    return FAQ
}
