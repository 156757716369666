import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useNavigate } from "react-router-dom";
import { destinations } from "../store/destinations";

function Destinations() {
  const [citiesPerScreen, setCitiesPerScreen] = useState(3);

  const theDestinations = destinations();

  let navigate = useNavigate();

  useEffect(() => {
    if (window.innerWidth < 500) {
      setCitiesPerScreen(2);
    } else {
      setCitiesPerScreen(3);
    }

    // console.log(window.innerWidth);
  }, [window.innerWidth]);

  const settings = {
    // dots: true,
    infinite: true,
    slidesToShow: citiesPerScreen,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
  };

  return (
    <div className="mb-20   " id="destination">
      <h2 className="mb-12 text-sec-color pt-8 px-4 font-bold md:text-4xl ">
        DESTINATIONS
      </h2>
      <div className="">
        <Slider className=" " {...settings}>
          {theDestinations.map((city) => (
            <div
              key={city.cityID}
              onClick={() => {
                navigate("/destination/" + city.cityID);
              }}
              className="px-1 cursor-pointer "
            >
              <img
                className=" "
                src={require("../images/" + city.cityPreviewImage + ".webp")}
                alt=""
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default Destinations;
