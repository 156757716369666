import React, { useState } from "react";
import AllCtx from "../../uti-functions/allCtx";
import { useNavigate } from "react-router-dom";

function PaymentDetails(props) {
  let navigate = useNavigate();
  const [response, setResponse] = useState("");
  const [confirming, setConfirming] = useState(false);
  const [canRunTimeOut, setCanRunTimeOut] = useState(true);

  const {
    baseUrl,
    totalPrice,
    setTheTotalPrice,
    userToken,
    user,
    flightRefID,
    setTheFlightRefID,
    setTheNotificationText,
    setTheShowNotification,
    tripType,
    adult,
    children,
    infant,
    allPassengers,
    adultArray,
    childrenArray,
    infantArray,
    flightArray,
    setTheFlightArray,
    chosenFlight,
    chosenFlightID,
    setTheChosenClass,
    setTheChosenClassPrice,
    chosenClass,
    chosenClassName,
    chosenClassPrice,
    setTheChosenFlight,
    setTheChosenFlightID,
    searchBookingResponse,
    setTheSearchBookingResponse,
    setTheChosenClassName,
    setTheClassChosen,
    classChosen,
    setTheSearchResultResponse,
    searchResultResponse,
    chosenClassTax,
    chosenClassFees,
    chosenClassSurge,
    setTheChosenClassTax,
    setTheChosenClassFees,
    setTheChosenClassSurge,
    setThePassengerFlight,
    passengerFlight,
    setThePaymentDetails,
    paymentDetails, setThePayStackTotalPrice
  } = AllCtx();

  const bookingDetails = {
    _flight_id: chosenFlightID,
    _trip_type: tripType,
    class: chosenClassName.toLowerCase(),
    _ps_adult: adult,
    _ps_children: children,
    _ps_infant: infant,
    _cul_price: chosenClassPrice,
    _ps_details: allPassengers,
    _booking_id: flightRefID,
    _ref_id: flightRefID,
  };

  async function confirmData() {

    if (adultArray[0].c_email !== adultArray[0].c_verify_email) {
      setResponse("Email does not match.");
      return;
    }

    if (
      !chosenClassTax ||
      chosenClassTax.trim() === "" ||
      !chosenClassFees ||
      chosenClassFees.trim() === "" ||
      !chosenClassSurge ||
      chosenClassSurge.trim() === "" ||
      !chosenClassPrice ||
      chosenClassPrice.trim() === ""
    ) {
      setResponse(
        "Seems your browser refreshed, go back to pick flight again."
      );
      return;
    }

    if (adult >= 1) {
      for (let i = 0; i < adult; i++) {
        if (
          !adultArray[i].title ||
          adultArray[i].title.trim() === "" ||
          !adultArray[i].gender ||
          adultArray[i].gender.trim() === "" ||
          !adultArray[i].first_name ||
          adultArray[i].first_name.trim() === "" ||
          !adultArray[i].last_name ||
          adultArray[i].last_name.trim() === "" ||
          !adultArray[0].c_email ||
          adultArray[0].c_email.trim() === ""
        ) {
          setResponse("Fill all important fields!");
          return;
        }
      }
    }

    if (children > 0) {
      for (let i = 0; i < children; i++) {
        if (
          !childrenArray[i].title ||
          childrenArray[i].title.trim() === "" ||
          !childrenArray[i].gender ||
          childrenArray[i].gender.trim() === "" ||
          !childrenArray[i].first_name ||
          childrenArray[i].first_name.trim() === "" ||
          !childrenArray[i].last_name ||
          childrenArray[i].last_name.trim() === "" ||
          !childrenArray[i].c_dob ||
          childrenArray[i].c_dob.trim() === ""
        ) {
          setResponse("Fill all important fields!");
          return;
        }
      }
    }

    if (infant > 0) {
      for (let i = 0; i < infant; i++) {
        if (
          !infantArray[i].title ||
          infantArray[i].title.trim() === "" ||
          !infantArray[i].gender ||
          infantArray[i].gender.trim() === "" ||
          !infantArray[i].first_name ||
          infantArray[i].first_name.trim() === "" ||
          !infantArray[i].last_name ||
          infantArray[i].last_name.trim() === "" ||
          !infantArray[i].c_dob ||
          infantArray[i].c_dob.trim() === ""
        ) {
          setResponse("Fill all important fields!");
          return;
        }
      }
    }

    try {
      setThePaymentDetails({
        name: `${adultArray[0].first_name} ${adultArray[0].last_name}`,
        phone: adultArray[0].c_phone.trim(),
        email: adultArray[0].c_email.trim(),
      });

      // console.log(paymentDetails);
      // return
      setConfirming(true);
      setResponse("Please wait...");
      console.log(bookingDetails);
      const response = await fetch(
        `${baseUrl}/server/api/desunapi/v1/booking`,
        {
          // mode: 'no-cors',
          method: "POST",
          body: JSON.stringify(bookingDetails),
          headers: {
            "Content-Type": "application/json",
            Useremail: `${user.email}`,
            Usertoken: `${userToken}`,
          },
        }
      );

      const data = await response.json();

      console.log(data);

      // return

      if (!response.ok) {
        setResponse("Failed! Please try again.");
        setConfirming(false);

        console.log("Failed! Please try again.");
        throw new Error("Failed! Please try again.");
      }

      setTheChosenClassPrice(data.data.price);
      setTheChosenClassFees(data.data.fees);
      setTheChosenClassSurge(data.data.surcharges);
      setTheChosenClassTax(data.data.taxes);
      setTheTotalPrice(data.data.total_price);
      setThePayStackTotalPrice(data.data.paystack_total_price
        );

      setTheFlightRefID(data.data.refference_id);

      setResponse("");

      setConfirming(false);

      // return
      // localStorage.setItem(
      //   "flightRefID",
      //   JSON.stringify(data.data.refference_id)
      // );

      localStorage.setItem("adultArray", JSON.stringify(adultArray));
      localStorage.setItem("childrenArray", JSON.stringify(childrenArray));

      localStorage.setItem("infantArray", JSON.stringify(infantArray));

      navigate("/verify-booking");

      setTheShowNotification(true);
      setTheNotificationText("Flight booked successfully!");

      if (canRunTimeOut) {
        const timer = setTimeout(() => {
          setTheShowNotification(false);
          setTheNotificationText("");
        }, 5000);

        return () => clearTimeout(timer);
      }
    } catch (error) {
      console.log(error);
      setResponse("Error! Please try again.");
      setConfirming(false);
    }
  }

  return (
    <div className="md:w-[35%] px-8 md:px-0 ">
      <div className="mb-3 md:mt-0 mt-3 text-pry-color font-semibold">
        <p>Payment Details</p>
      </div>
      <div className="border-2 rounded-md px-3 py-4">
        <div className="mb-3 border-2 space-y-3 px-2 py-2 rounded-md">
          <div className="flex justify-between">
            <p>Base Fare</p>
            <p>₦{chosenClassPrice}</p>
          </div>
          <div className="flex justify-between">
            <p>Surcharge</p>
            <p>₦{chosenClassSurge}</p>
          </div>
          <div className="flex justify-between">
            <p>Fees</p>
            <p>₦{chosenClassFees}</p>
          </div>

          <div className="flex justify-between">
            <p>Tax</p>
            <p>₦{chosenClassTax}</p>
          </div>
        </div>

        <hr className="mb-3" />
        {/* 
        <div className="mb-3">
          <div className="flex justify-between">
            <p>Total Fare</p> <p>₦{chosenClassPrice  }</p>
          </div>
          <div className="flex justify-between">
            <p>Add Ons</p> <p>₦0</p>
          </div>
        </div> */}

        <hr className="mb-3" />

        <div className="flex justify-between">
          <p>Price You Pay</p>{" "}
          <p className="font-bold text-pry-color">₦{totalPrice}</p>
        </div>
      </div>

      <div className="mt-2 flex h-6 justify-center items-center text-sec-color">
        <p>{response}</p>
      </div>
      <div>
        <button
          onClick={confirmData}
          className="bg-sec-color w-full text-white mt-4 rounded-md py-2 hover:bg-orange-400"
        >
          Continue Booking
        </button>
      </div>
    </div>
  );
}

export default PaymentDetails;
