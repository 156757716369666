import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from "react-dom/client";
import './styles/index.css';
import './styles/header.css';
import './styles/tabs/general-tab.css';
import './styles/tabs/booktrip-tab-form.css';
import './styles/dropdowns/destination-dropdown.css';
import './styles/dropdowns/booking-dropdown.css';
import './styles/dropdowns/fly-dropdown.css';
import './styles/dropdowns/about-us-dropdown.css';
import './styles/dropdowns/services-dropdown.css';
import './styles/tabs/manage-booking-form.css';
import './styles/tabs/check-in.css';
import './styles/tabs/flight-status.css';
import './styles/services.css';
import './styles/exclusive.css';
import './styles/footer.css';
import 'flowbite'
import App from './App';
import { AllContextProvider } from './context-store/AllContext';
import { BrowserRouter } from 'react-router-dom';

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(
  <React.StrictMode>
    <AllContextProvider>
      {/* <BrowserRouter> */}
      <App />
      {/* </BrowserRouter> */}
  
   </AllContextProvider>
  </React.StrictMode>
);

