import React, { useEffect, useState } from "react";
import AllCtx from "../../uti-functions/allCtx";

function ManageInfantDetails() {
  const {
    // monitor,
    adult,
    children,
    infant,
    allPassengers,

    tripType,
    flightArray,
    setTheFlightArray,
    chosenFlight,
    chosenFlightID,
    setTheChosenClass,
    setTheChosenClassPrice,
    chosenClass,
    chosenClassName,
    chosenClassPrice,
    setTheChosenFlight,
    setTheChosenFlightID,
    searchBookingResponse,
    setTheSearchBookingResponse,
    setTheChosenClassName,
    setTheClassChosen,
    classChosen,
    setTheSearchResultResponse,
    searchResultResponse,
    setTheAllPassengers,
    setTheInfantArray,
    adultArray,
    childrenArray,
    infantArray,
  } = AllCtx();



  const [infantTitle, setInfantTitle] = useState("");
  const [infantGender, setInfantGender] = useState("");
  const [infantFirstName, setInfantFirstName] = useState("");
  const [infantLastName, setInfantLastName] = useState("");
  const [infantDOB, setInfantDOB] = useState("");

  const [monitor, setMonitor] = useState(true);



  function handleInfantTitleChange(infantID, titlePicked) {
    let newInfantArray = [...infantArray];
    let infantInputToChange = newInfantArray.find(
      (infant) => infant.id === infantID
    );

    infantInputToChange.title = titlePicked;

    setTheInfantArray(newInfantArray);
    setInfantTitle(infantInputToChange.title);

    return infantInputToChange.title;
  }

  function handleInfantGenderChange(infantID, genderPicked) {
    let newInfantArray = [...infantArray];
    let infantInputToChange = newInfantArray.find(
      (infant) => infant.id === infantID
    );
    infantInputToChange.gender = genderPicked;
    setTheInfantArray(newInfantArray);
    setInfantGender(infantInputToChange.gender);
    return infantInputToChange.gender;
  }

  function handleInfantFirstNameChange(infantID, firstNameTyped) {
    let newInfantArray = [...infantArray];
    let infantInputToChange = newInfantArray.find(
      (infant) => infant.id === infantID
    );
    infantInputToChange.first_name = firstNameTyped;
    setTheInfantArray(newInfantArray);
    setInfantFirstName(infantInputToChange.first_name);

    return infantInputToChange.first_name;
  }

  function handleInfantLastNameChange(infantID, lastNameTyped) {
    let newInfantArray = [...infantArray];
    let infantInputToChange = newInfantArray.find(
      (infant) => infant.id === infantID
    );
    infantInputToChange.last_name = lastNameTyped;
    setTheInfantArray(newInfantArray);
    setInfantLastName(infantInputToChange.last_name);
    return infantInputToChange.last_name;
  }

  function handleInfantDOBChange(infantID, dobChosen) {
    let newInfantArray = [...infantArray];
    let infantInputToChange = newInfantArray.find(
      (infant) => infant.id === infantID
    );
    infantInputToChange.c_dob = dobChosen;
    setTheInfantArray(newInfantArray);
    setInfantDOB(infantInputToChange.c_dob);
    return infantInputToChange.c_dob;
  }

  return (
    <div>
      {infantArray.map((infant) => (
        <div key={infant.id} className="mt-4">
          <div>
            <p className="text-pry-color mb-2 font-semibold">
              {
                 `${infant.id} Details`}
            </p>
          </div>

          <div className=" border-2 rounded-md py-4 px-2 sm:px-5 md:px-2">
            <div className="mb-2">
              <p className="font-semibold text-sm">Passenger Info</p>
            </div>
            <div className="md:flex md:space-y-0 space-y-4 justify-between mb-3">
              {/* Title Select  */}
              <div className="md:w-[20%]">
                <div className="text-sm text-gray-500">Title</div>
                <select
                  className="text-xs w-full border-gray-300 rounded border-1"
                  name="title"
                  id="title"
                    onClick={() => {
                    setTheAllPassengers(adultArray, childrenArray, infantArray);
                  }}
                  onChange={(e) => {
                    handleInfantTitleChange(infant.id, e.target.value);
                    console.log(infant.title);

                    console.log("infantArray", infantArray);
                    setTheAllPassengers(adultArray, childrenArray, infantArray);
                    console.log("allPa", allPassengers);
                  }}
                  value={infant.title}
                >
                  {/* <option value="">Select Title</option> */}
                  <option value="Master">Master</option>
                  <option value="Miss">Miss</option>
                  <option value="Ms">Ms</option>
                </select>
              </div>
              {/*  First Name   */}
              <div className="md:w-[38%]">
                <div className="text-sm text-gray-500">First Name</div>{" "}
                <input
                  placeholder="First Name"
                    onClick={() => {
                    setTheAllPassengers(adultArray, childrenArray, infantArray);
                  }}
                  onChange={(e) => {
                    handleInfantFirstNameChange(infant.id, e.target.value);

                    console.log("infantArray", infantArray);
                    setTheAllPassengers(adultArray, childrenArray, infantArray);
                    console.log("allPa", allPassengers);
                  }}
                  className="text-xs w-full border-gray-300 rounded border-1"
                  type="text"
                  name=""
                  id=""
                   value={infant.first_name}
                />
              </div>

              {/*  Last Name   */}
              <div className="md:w-[38%] ">
                <div className="text-sm text-gray-500">Last Name</div>{" "}
                <input
                  placeholder="Surname"
                    onClick={() => {
                    setTheAllPassengers(adultArray, childrenArray, infantArray);
                  }}
                  onChange={(e) => {
                    handleInfantLastNameChange(infant.id, e.target.value);

                    console.log("infantArray", infantArray);
                    setTheAllPassengers(adultArray, childrenArray, infantArray);
                    console.log("allPa", allPassengers);
                  }}
                  className="text-xs w-full border-gray-300 rounded border-1"
                  type="text"
                  name=""
                  id=""
                     value={infant.last_name}
                />
              </div>

      
                  </div>
                  <div className="md:flex justify-between mb-3 md:space-y-0 space-y-4">
           
           {/* DOB  */}
           <div className="md:w-[45%]">
             <div className="text-sm text-gray-500">DOB</div>
             <input
                 onClick={() => {
                    setTheAllPassengers(adultArray, childrenArray, infantArray);
                  }}
               onChange={(e) => {
                 handleInfantDOBChange(infant.id, e.target.value);

                 console.log("infantArray", infantArray);
                 setTheAllPassengers(
                   adultArray,
                   childrenArray,
                   infantArray
                 );
                 console.log("allPa", allPassengers);
               }}
               className="text-xs w-full outline-1 border-solid border-gray-300 rounded border-1"
               placeholder="Enter Email"
                  type="date"
                     value={infant.c_dob}
             />
           </div>{" "}
          
                              {/* Gender Select  */}

              <div className="md:w-[45%]">
                <div className="text-sm text-gray-500" htmlFor="">
                  Gender
                </div>
                <select
                  className="text-xs w-full border-gray-300 rounded border-1"
                  name="gender"
                  id="gender"
                  onClick={() => {
                    setTheAllPassengers(adultArray, childrenArray, infantArray);
                  }}
                 
                  onChange={(e) => {
                    handleInfantGenderChange(infant.id, e.target.value);
                    console.log(infant.gender);

                    console.log("infantArray", infantArray);
                    setTheAllPassengers(adultArray, childrenArray, infantArray);
             console.log(adultArray, childrenArray, infantArray);
                    console.log("allPa", allPassengers);
                  }}
                   value={infant.gender}
                >
                  <option value="">Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Undisclosed">Undisclosed</option>
                </select>
              </div>
         </div>
        
          </div>
        </div>
      ))}
    </div>
  );
}

export default ManageInfantDetails;
