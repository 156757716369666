import React, { useRef } from 'react'
import { useState } from 'react';
import AllCtx from "../../uti-functions/allCtx";


function Security() {
const {user, userToken, baseUrl} = AllCtx();


  const currentPasswordRef = useRef();
  const newPasswordRef = useRef();
  const confirmNewPasswordRef = useRef();
  

  const [saving, setSaving] = useState(false)
  const [response, setResponse] = useState('') 


async  function updatePassword() {
      const currentPasswordInput = currentPasswordRef.current.value;
    const newPasswordInput = newPasswordRef.current.value;
    const confirmPasswordInput = confirmNewPasswordRef.current.value;



    try {
      setSaving(true)
      setResponse('Please wait...')

      const response = await fetch(
        `${baseUrl}/server/api/desunapi/v1/update_password`,
        {
          method: "POST",
          body: JSON.stringify({
            _current_password: currentPasswordInput,
            _new_password: newPasswordInput,
            _email: user.email
          }),
          headers: {
            "Content-Type": "application/json",
            Useremail: `${user.email}`,
            Usertoken: `${userToken}`,
          },
        }
      );

      const data = await response.json();
      console.log(data);

      if (!response.ok) {
        console.log(response.ok);
        data.map(resp => {
          setSaving(false)
        setResponse(resp.message)
        console.log(resp.message);
          
        })
        return;
      }


      data.map(resp => {
        if (resp.status === "fail") {
          setSaving(false)
          setResponse(resp.message)
        
        }
        return
      })
      
      setSaving(false)
      setResponse('Password changed successfully!')
      currentPasswordRef.current.value = ''
      newPasswordRef.current.value = ''
      confirmNewPasswordRef.current.value = ''



    } catch (error) {
      console.log(error);
      setSaving(false)
      setResponse("Error! Something went wrong.")
    }
}

  return (

    
    <div className="w-[80%]">
    <div
   
      className="cursor-pointer mb-4"
    >
      <p className="text-pry-color font-semibold ">Password &amp; Security</p>
    </div>

    <div

      className=""
    >
    
      <div className="md:w-1/2 ">
        <div className="mb-2">
          <p className="text-sec-color">Change Password</p>
        </div>

        <div className="w-full text-xs 450:text-sm mb-4">
          <p className="font-semibold">Current Password</p>
          <input required  
                
               ref={currentPasswordRef}
            className=" w-full h-8 border-gray-300 rounded-md"
            type="password"
            name=""
            id=""
          />
          </div>
          
        <div className="w-full text-xs 450:text-sm mb-4">
          <p className="font-semibold">New Password</p>
          <input required
                
             ref={newPasswordRef}
            className="w-full h-8 border-gray-300 rounded-md"
            type="password"
            name=""
            id=""
          />
          </div>

          <div className="w-full text-xs 450:text-sm mb-4">
          <p className="font-semibold">Confirm New Password</p>
          <input required
                
             ref={confirmNewPasswordRef}
            className="w-full h-8 border-gray-300 rounded-md"
            type="password"
            name=""
            id=""
          />
          </div>
          

      </div>
    </div>

    <div className="flex justify-center h-8 ">
      <p className="text-sec-color">{response}</p>
    </div>

    <div onClick={updatePassword} disabled={saving} className="mt-2">
      <button className="bg-sec-color text-sm px-5 rounded-md shadow-md text-white py-1">
        Save
      </button>
    </div>
  </div>


  )
}

export default Security