import React from "react";
import Exclusive from "../components/Exclusive";
import Header from "../components/Header";
import Destinations from "../components/Destinations";
import Services from "../components/Services";
import TabHeader from "../components/tab/TabHeader";

function Homepage() {
  return (
    <div>
      <Header />
      <TabHeader />
      <Services />
      <Destinations />
      <Exclusive />
    </div>
  );
}

export default Homepage;
