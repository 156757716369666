import React, { useState } from "react";
import Booking from "../images/white-booktrip-mobile-icon.svg";
import ArrowDown from "../images/arrow-down.svg";
import ArrowRight from "../images/black-arrow-right.svg";
import Fly from "../images/lil-flight-icon2.svg";
import Hand from "../images/hand.svg";
import About from "../images/lil-flight-icon3.svg";
import Destination from "../images/landing-flight.png";
import Help from "../images/phone-icon.svg";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { destinations } from "../store/destinations";
import AllCtx from "../uti-functions/allCtx";

function MobileNav(props) {
  const { selectTheTab1, selectTheTab2, selectTheTab3, selectTheTab4 } =
    AllCtx();

  const theDestinations = destinations();

  const [bookingClicked, setBookingClicked] = useState(false);
  // const [mobileNavClicked, setMobileNavClicked] = useState(false)
  const [flyClicked, setFlyClicked] = useState(false);
  const [aboutClicked, setAboutClicked] = useState(false);
  const [servicesClicked, setServicesClicked] = useState(false);
  const [destinationClicked, setDestinationClicked] = useState(false);

  function toggleBooking() {
    setBookingClicked(!bookingClicked);
    setFlyClicked(false);
    setAboutClicked(false);
    setServicesClicked(false);
    setDestinationClicked(false);
  }

  function toggleFly() {
    setBookingClicked(false);
    setFlyClicked(!flyClicked);
    setAboutClicked(false);
    setServicesClicked(false);
    setDestinationClicked(false);
  }

  function toggleAbout() {
    setBookingClicked(false);
    setFlyClicked(false);
    setAboutClicked(!aboutClicked);
    setServicesClicked(false);
    setDestinationClicked(false);
  }

  function toggleServices() {
    setBookingClicked(false);
    setFlyClicked(false);
    setAboutClicked(false);
    setServicesClicked(!servicesClicked);
    setDestinationClicked(false);
  }

  function toggleDestination() {
    setBookingClicked(false);
    setFlyClicked(false);
    setAboutClicked(false);
    setServicesClicked(false);
    setDestinationClicked(!destinationClicked);
  }

  return (
    <div
      className={`sm:hidden ${
        props.mobileNavClicked ? "translate-x-0" : "translate-x-full"
      }  text-pry-color fixed   duration-700 bg-white h-[80%]  px-16 shadow-xl w-full z-30 pt-14 space-y-6 overflow-scroll `}
    >
      <div>
        <div className="flex justify-between items-center mt-3">
          <div className="flex justify-start items-center space-x-7 font-bold  ">
            <div>
              <img className="h-10 w-7" src={Booking} alt="" />
            </div>
            <h2
              className="cursor-pointer hover:text-sec-color duration-200"
              onClick={toggleBooking}
            >
              Booking
            </h2>
          </div>
          <div onClick={toggleBooking}>
            <img
              className="cursor-pointer"
              src={bookingClicked ? ArrowDown : ArrowRight}
              alt=""
            />
          </div>
        </div>
        {bookingClicked && (
          <div className={`mt-7 space-y-5 text-xl px-14`}>
            <HashLink
              onClick={props.toggleMobileNav}
              className="no-underline text-pry-color"
              to="/#tab"
            >
              <p
                onClick={selectTheTab1}
                className="cursor-pointer hover:text-sec-color"
              >
                Search Flight
              </p>
            </HashLink>
            {/* <p className="cursor-pointer hover:text-sec-color">
              Flight Schedule
            </p> */}
            <HashLink
              onClick={props.toggleMobileNav}
              className="no-underline text-pry-color "
              to="/#tab"
            >
              <p
                onClick={selectTheTab2}
                className="cursor-pointer hover:text-sec-color pt-4"
              >
                Manage Booking
              </p>
            </HashLink>
          </div>
        )}
      </div>

      <div className="shadow-sm pb-2">
        <div className="flex justify-between items-center mt-3 ">
          <div className="flex justify-start items-center space-x-7 font-bold  ">
            <div>
              <img className="h-10 w-7" src={Fly} alt="" />
            </div>
            <h2
              className="cursor-pointer hover:text-sec-color duration-200"
              onClick={toggleFly}
            >
              Fly
            </h2>
          </div>
          <div onClick={toggleFly}>
            <img
              className="cursor-pointer"
              src={flyClicked ? ArrowDown : ArrowRight}
              alt=""
            />
          </div>
        </div>
        {flyClicked && (
          <div className={`mt-7 space-y-5 text-xl px-14 shadow-sm pb-2`}>
            {/* <p className="cursor-pointer hover:text-sec-color">
              Upgrade Flight
            </p> */}
            <HashLink
              onClick={props.toggleMobileNav}
              className="no-underline text-pry-color "
              to="/#tab"
            >
              <p
                onClick={selectTheTab3}
                className="cursor-pointer hover:text-sec-color pt-4"
              >
                Check In
              </p>
            </HashLink>

            <HashLink
              onClick={props.toggleMobileNav}
              className="no-underline text-pry-color "
              to="/#tab"
            >
              <p
                onClick={selectTheTab4}
                className="cursor-pointer hover:text-sec-color pt-4"
              >
                Flight Status
              </p>
            </HashLink>
            {/* <p className="cursor-pointer hover:text-sec-color">
              Change Bookings
            </p> */}
          </div>
        )}
      </div>

      <div className="shadow-sm pb-2">
        <div className="flex justify-between items-center mt-3 ">
          <div className="flex justify-start items-center space-x-7 font-bold  ">
            <div>
              <img className="h-10 w-7" src={About} alt="" />
            </div>
            <h2
              className="cursor-pointer hover:text-sec-color duration-200"
              onClick={toggleAbout}
            >
              About
            </h2>
          </div>
          <div onClick={toggleAbout}>
            <img
              className="cursor-pointer"
              src={aboutClicked ? ArrowDown : ArrowRight}
              alt=""
            />
          </div>
        </div>
        {aboutClicked && (
          <div className={`mt-7 space-y-5 text-xl px-14 shadow-sm pb-2`}>
            <Link
              onClick={props.toggleMobileNav}
              className="no-underline text-pry-color"
              to="/about"
            >
              {" "}
              <p className="cursor-pointer hover:text-sec-color pt-4">
                About Desun Air
              </p>
            </Link>

            <Link
              onClick={props.toggleMobileNav}
              className="no-underline text-pry-color"
              to="/contact"
            >
              {" "}
              <p className="cursor-pointer hover:text-sec-color pt-4">
                Contact Us
              </p>
            </Link>

            <Link
              onClick={props.toggleMobileNav}
              className="no-underline text-pry-color"
              to="/privacy-policy"
            >
              {" "}
              <p className="cursor-pointer hover:text-sec-color pt-4">
              Privacy Policy
              </p>
            </Link>
        
            {/* <Link
              onClick={props.toggleMobileNav}
              className="no-underline text-pry-color"
              to="/about"
            >
              {" "}
              <p className="cursor-pointer hover:text-sec-color pt-4">Mission &amp; Vision</p>
            </Link> */}

            {/* <p className="cursor-pointer hover:text-sec-color">Support</p> */}

            <Link
              onClick={props.toggleMobileNav}
              className="no-underline text-pry-color"
              to="/faq"
            >
              {" "}
              <p className="cursor-pointer hover:text-sec-color mt-4">FAQ</p>
            </Link>
          </div>
        )}
      </div>

      <div className="shadow-sm pb-2">
        <div className="flex justify-between items-center mt-3 ">
          <div className="flex justify-start items-center space-x-7 font-bold  ">
            <div>
              <img className="h-10 w-7" src={Hand} alt="" />
            </div>
            <h2
              className="cursor-pointer hover:text-sec-color duration-200"
              onClick={toggleServices}
            >
              Services
            </h2>
          </div>
          <div onClick={toggleServices}>
            <img
              className="cursor-pointer"
              src={servicesClicked ? ArrowDown : ArrowRight}
              alt=""
            />
          </div>
        </div>
        {servicesClicked && (
          <div className={`mt-7 space-y-5 text-xl px-14 shadow-sm pb-2`}>
            <Link
              onClick={props.toggleMobileNav}
              className="no-underline text-pry-color"
              to="/services/training"
            >
              {" "}
              <p className="pt-4 cursor-pointer hover:text-sec-color">
                Training
              </p>
            </Link>

            <Link
              onClick={props.toggleMobileNav}
              className="no-underline text-pry-color"
              to="/services/cargo"
            >
              {" "}
              <p className="pt-4 cursor-pointer hover:text-sec-color">Cargo</p>
            </Link>

            <Link
              onClick={props.toggleMobileNav}
              className="no-underline text-pry-color"
              to="/services/repairs"
            >
              {" "}
              <p className="pt-4 cursor-pointer hover:text-sec-color">
                Repairs
              </p>
            </Link>

            <Link
              onClick={props.toggleMobileNav}
              className="no-underline text-pry-color"
              to="/services/private-hire"
            >
              {" "}
              <p className="pt-4 cursor-pointer hover:text-sec-color">
                Private Charter
              </p>
            </Link>

            <Link
              onClick={props.toggleMobileNav}
              className="no-underline text-pry-color"
              to="/services/purchase-parts"
            >
              {" "}
              <p className="pt-4 cursor-pointer hover:text-sec-color">
                Purchase Parts
              </p>
            </Link>
          </div>
        )}
      </div>

      <div className="shadow-sm pb-2">
        <div className="flex justify-between items-center mt-3 ">
          <div className="flex justify-start items-center space-x-7 font-bold  ">
            <div>
              <img className="h-10 w-7" src={Destination} alt="" />
            </div>
            <h2
              className="cursor-pointer hover:text-sec-color duration-200"
              onClick={toggleDestination}
            >
              Destination
            </h2>
          </div>
          <div onClick={toggleDestination}>
            <img
              className="cursor-pointer"
              src={destinationClicked ? ArrowDown : ArrowRight}
              alt=""
            />
          </div>
        </div>
        {destinationClicked && (
          <div className={`mt-7 space-y-5 text-xl px-14 shadow-sm pb-2`}>
            {theDestinations.map((dest) => (
              <Link
                key={dest.cityID}
                onClick={props.toggleMobileNav}
                className="no-underline text-pry-color"
                to={`/destination/${dest.cityID}`}
              >
                {" "}
                <p className="pt-4 cursor-pointer hover:text-sec-color">
                  {dest.cityName}
                </p>
              </Link>
            ))}
          </div>
        )}
      </div>
      <hr />

      <div className=" pb-2 ">
        <div className="flex justify-between items-center mt-3 ">
          <div className="flex justify-start items-center space-x-7 font-bold mb-10 ">
            <div>
              <img className="h-10 w-7" src={Help} alt="" />
            </div>
            <Link className="no-underline text-pry-color" to="/contact">
              <h2
                onClick={props.toggleMobileNav}
                className="cursor-pointer hover:text-sec-color"
              >
                Help
              </h2>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileNav;
