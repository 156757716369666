import React from "react";
import FAQ from "../images/faq.svg";
import faqs from "../store/faq";

function FaqPage() {
  const theFaqs = faqs();

  return (
    <div className="bg-mid-color pb-4">
      <div className="bg-[url('../images/header-bg-for-services.png')] bg-cover bg-center bg-no-repeat text-2xl md:text-3xl lg:text-4xl ">
        {" "}
        <div className="px-12 md:h-22">
          <img src={FAQ} alt="" />
        </div>{" "}
      </div>

      <div className="px-12 flex justify-center md:justify-start">
        <h1 className="mt-12 text-sec-color mb-6 text-lg md:text-2xl ">
          Frequently Asked Questions
        </h1>
      </div>

      <div className="grid md:grid-cols-2 px-12 gap-x-16 gap-y-8 mb-20">
        {theFaqs.map((faq) => (
          <div key={faq.question}>
            <p className="font-semibold text-lg text-pry-color ">
              {faq.question}
            </p>
            <div>
              <p className="md:leading-7 leading-relaxed text-justify">
                {faq.answer}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className="bg-[#dedfea] text-center md:leading-7 leading-relaxed text-lg py-5">
        <p>
          <span className="font-semibold">Have more questions?</span> Reach us
          at <a href="mailto:info@desunair.com">info@desunair.com</a> or
          +234(0)8037415154{" "}
        </p>
      </div>
    </div>
  );
}

export default FaqPage;
