import React, { useEffect, useState } from "react";
import AllCtx from "../uti-functions/allCtx";
import Flight from "../images/flight.svg";
import FlightReturn from "../images/flight-return.svg";
import Clock from "../images/clock.svg";
import { useNavigate } from "react-router-dom";

function SearchResultsDeskView() {
  


  let navigate = useNavigate();

  const {
    
    setTheTotalPrice,
    setTheFlightRefID,
    first, last, prev, next,
    adult,
    tripType,

    adultArray,
    flightArray,
    setTheFlightArray,
    chosenFlight,
    chosenFlightID,
    setTheChosenClass,
    setTheChosenClassPrice,
    chosenClass,
    chosenClassName,
    chosenClassPrice,
    setTheChosenFlight,
    setTheChosenFlightID,
    searchBookingResponse,
    setTheSearchBookingResponse,
    setTheChosenClassName,
    setTheClassChosen,
    classChosen,
    setTheSearchResultResponse,
    searchResultResponse,
    setTheAdultArray,
    setTheMonitor,
    setTheChildrenArray,
    setTheInfantArray,
    chosenClassTax,
    chosenClassFees,
    chosenClassSurge,
        setTheChosenClassTax,
    setTheChosenClassFees,
    setTheChosenClassSurge,
  } = AllCtx();
  var date;
  var weekDay;
  var month;
  var day;
  var year;

  useEffect(() => {
    // adultArray=[]
  }, []);

  return (
    <div className="bg-mid-color">
      {flightArray.length === 0 ? (
       <div className='flex justify-center items-center text-pry-color font-semibold mt-10'> <p className="hidden md:block">No flight information available.</p></div>
      ) : (
        flightArray.map((flightList, i) =>
          flightList
            
            .map(
              (flight, i) => (
                (date = new Date(flight.departure_date)),
                (weekDay = date.toLocaleDateString("en-US", {
                  weekday: "short",
                })),
                (day = date.toLocaleDateString("en-US", { day: "numeric" })),
                (month = date.toLocaleDateString("en-US", { month: "short" })),
                (year = date.toLocaleDateString("en-US", { year: "numeric" })),
                (
                  <div
                    key={i}
                    // i={i}
                    className="hidden md:block px-5 pt-5 text-pry-color"
                  >
                    <div className="text-gray-500 mb-2 flex space-x-4">
                      {/* <p>Departure Flight</p>{" "} */}
                      <p className="text-sec-color font-bold">{`${weekDay}, ${day} ${month} ${year}`}</p>
                      <p>(Flight {i+1})</p>
                    </div>
                    <div className="flex justify-between  items-center">
                      <div className="font-semibold">
                        {" "}
                        <p className="">
                          {tripType === "single" && (
                            <span className="text-sec-color ">From </span>
                          )}
                          {flight.from}
                        </p>
                      </div>{" "}
                      <div>-----------------------------------------</div>{" "}
                      <div>
                        <div>
                          {" "}
                          <img src={Flight} alt="" />
                        </div>
                        {tripType === "round" && (
                          <div>
                            {" "}
                            <img src={FlightReturn} alt="" />
                          </div>
                        )}
                      </div>{" "}
                      <div>-----------------------------------------</div>{" "}
                      <div className="font-semibold">
                        {" "}
                        <p>
                          {tripType === "single" && (
                            <span className="text-sec-color">To</span>
                          )}{" "}
                          {flight.to} 
                        </p>
                      </div>
                    </div>
                    <div className="flex mt-5 shadow-sm py-5 px-5 justify-between space-x-4">
                      <div>
                        <p className="font-semibold">{flight.departure_time}</p>
                        <p className="font-semibold text-xs -mt-1 text-black">
                          {flight.from} 
                        </p>
                        <p className="text-gray-400 text-xs">
                          {flight.title}
                        </p>
                        <p className="text-gray-400 text-xs">
                         {flight.departure_airport}
                        </p>
                        <div className="flex justify-end ">
                          <button
                            onClick={() => {
                              console.log("Chosen Flight is ", chosenFlight);

                              setTheChosenFlight(flight.ID);

                              if (classChosen) {
                                navigate("/booking");
                                setTheClassChosen(false);

                                localStorage.setItem(
                                  "adultArray",
                                  JSON.stringify(adultArray)
                                );
                                setTheAdultArray([]);
                                setTheChildrenArray([]);
                                setTheInfantArray([]);
                                setTheFlightRefID('')
                              } else {
                                setTheSearchResultResponse(
                                  "Select a class before you continue."
                                );
                              }
                            }}
                            className="text-white px-4 py-1 rounded-md shadow-md bg-sec-color hover:bg-orange-800 cursor-pointer text-xl font-bold  mt-5"
                          >
                            Continue
                          </button>
                        </div>
                      </div>
                      <div>
                        <div className="flex justify-center">
                          <img src={Clock} alt="" />
                        </div>
                        <div className="flex justify-center text-center text-xs text-gray-400">
                          <p>{flight.duration}</p>
                        </div>
                        <div className="flex justify-center text-xs text-gray-400">
                          {/* <p>1 stop(s)</p> */}
                        </div>
                      </div>
                      <div className="text-right">
                        <p className="font-semibold text-right">
                          {flight.arrival_time}{" "}
                        </p>
                        <p className="font-semibold text-xs -mt-1 text-black text-right">
                          {flight.to}
                        </p>
                        <p className="text-gray-400 text-xs">
                          {flight.title}
                        </p>
                        <p className="text-gray-400 text-xs">
                         {flight.arrival_airport}
                        </p>
                      </div>

                      <div className="w-[70%]">
                      <div className='mt-4 text-center text-xl text-sec-color font-semibold '>Flight Classes Available</div>
                        <div className="flex lg:space-x-10 justify-center ">
                          {flight.class.map((flightClass) => (
                          flightClass &&  <div
                              key={flightClass?.name}
                              onMouseOver={() => {
                                setTheChosenFlight(flight.ID);
                              }}
                              onClick={() => {
                                setTheClassChosen(true);
                                setTheChosenClass(flightClass.name);
                                console.log(
                                  flight.ID,
                                  flightClass.name,
                                  flightClass.cul_price
                                );
                                setTheChosenFlightID(flight.ID);
                                localStorage.setItem(
                                  "chosenFlight",
                                  JSON.stringify(chosenFlight)
                                );
                                setTheChosenClassName(flightClass.name);
                                setTheChosenClassPrice(flightClass.cul_price);

                                localStorage.setItem(
                                  "chosenClassName",
                                  JSON.stringify(flightClass.name)
                                );
                                localStorage.setItem(
                                  "chosenClassPrice",
                                  JSON.stringify(flightClass.cul_price)
                                );

                                setTheChosenClassFees(flightClass.fees)
                                setTheChosenClassSurge(flightClass.surcharges)
                                setTheChosenClassTax(flightClass.taxes)
                                setTheTotalPrice(flightClass.total_price)

                                // console.log(flightClass.cul_price, flightClass.fees, flightClass.surge, +flightClass.taxes );
                              }}
                              className={`${
                                chosenClassName === flightClass?.name &&
                                chosenFlightID === flight.ID &&
                                classChosen
                                  ? "bg-sec-color text-white"
                                  : "hover:bg-gray-300"
                              } cursor-pointer shadow-sm px-2 h-20 text-xs rounded-md space-y-2 pt-4 duration-300`}
                            >
                              <div className="flex justify-center text-center">
                                {" "}
                                <p className="text-sm">{flightClass?.name}</p>
                              </div>
                              <div className="flex justify-center">
                                {" "}
                                <p className="font-semibold text-black">
                                  ₦{flightClass?.total_price}
                                </p>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="flex justify-center mt-5 text-red-500">
                          <p>{searchResultResponse}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )
            ).filter((a, i) => i >= prev && i < next)
        )
      )}
    
    </div>
  );
}

export default SearchResultsDeskView;
