import React, { useState, useEffect } from "react";
import AllCtx from "../../uti-functions/allCtx";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

function FlightStatusComponent() {
  let navigate = useNavigate();
  const [response, setResponse] = useState("");
  const [selectingSeat, setSelectingSeat] = useState(false);
  const [canRunTimeOut, setCanRunTimeOut] = useState(true);
  const [chosenSeat, setChosenSeat] = useState("");
  const [seatClicked, setSeatClicked] = useState(false);

  const {
    flightSeatArray,
    userToken,
    user,
    flightRefID,
    setTheFlightRefID,
    setTheNotificationText,
    setTheShowNotification,
    tripType,
    adult,
    children,
    infant,
    allPassengers,
    adultArray,
    childrenArray,
    infantArray,
    flightArray,
    setTheFlightArray,
    chosenFlight,
    chosenFlightID,
    setTheChosenClass,
    setTheChosenClassPrice,
    chosenClass,
    chosenClassName,
    chosenClassPrice,
    setTheChosenFlight,
    setTheChosenFlightID,
    searchBookingResponse,
    setTheSearchBookingResponse,
    setTheChosenClassName,
    setTheClassChosen,
    classChosen,
    setTheSearchResultResponse,
    searchResultResponse,
    chosenClassTax,
    chosenClassFees,
    chosenClassSurge,
    setTheChosenClassTax,
    setTheChosenClassFees,
    setTheChosenClassSurge,
    setTheFlightSeatArray,
    flightStatus, setTheFlightStatus
  } = AllCtx();

//   useEffect(() => {
//   setTheFlightStatus('publish')
// },[])



  return (
    <div className="md:w-[35%] px-8 md:px-0 ">
      <div className="mb-3 md:mt-0 mt-3 text-pry-color font-semibold">
        <p>Flight Status</p>
      </div>
      <div className="border-2 rounded-md px-3 py-4">
     
        
        <div className=' text-lg font-semibold flex justify-center text-pry-color '><p>Your flight </p></div>

        <div className='flex justify-center mt-5 font-bold text-2xl'>
          {flightStatus === 'publish' && <p>HAS NOT TAKEN OFF.</p>}

          {flightStatus === 'cancelled' && <div>
            <div className='flex justify-center'><p className='text-red-600'>HAS BEEN CANCELLED.</p></div>
            <div className='flex justify-center text-base mt-5'> <Link to='/contact'><p>Contact Desun Air</p></Link>  </div>
          </div>}
          
          {flightStatus === 'delayed' && <div>
            <div className='flex justify-center'><p className='text-sec-color'>IS BEING DELAYED.</p></div>
            <div className='flex justify-center text-base mt-5'> <Link to='/contact'><p>Contact Desun Air</p></Link>  </div>
          </div>}
          
          {flightStatus === 'in-transit' && <p className='text-pry-color'>IS IN TRANSIT.</p>}

          {flightStatus === 'landed' && <p className='text-green-600'>HAS LANDED SAFELY</p>}
        </div>




      </div>
      <div className='flex justify-center mt-5 '><button onClick={() => {
        navigate('/')
      }} className='w-full bg-sec-color text-white py-3 rounded-lg hover:bg-orange-500'>OK</button></div>
    </div>
  );
}

export default FlightStatusComponent;
