import React, { useRef, useState } from "react";
import MailIcon from "../images/email-icon.png";
import PhoneIcon from "../images/phone-icon.png";
import LocationIcon from "../images/location-icon.png";
import AllCtx from "../uti-functions/allCtx";

function Contact() {
  const { baseUrl } = AllCtx();

  const [response, setResponse] = useState("");
  const [sending, setSending] = useState(false);

  const emailRef = useRef();
  const nameRef = useRef();
  const messageRef = useRef();

  async function sendMessage(e) {
    e.preventDefault();

    const enteredEmail = emailRef.current.value;
    const enteredName = nameRef.current.value;
    const enteredMessage = messageRef.current.value;

    if (
      !enteredEmail ||
      enteredEmail.trim() === "" ||
      !enteredName ||
      enteredName.trim() === "" ||
      !enteredMessage ||
      enteredMessage.trim() === ""
    ) {
      setResponse("Fill all inputs!");
      return;
    }

    try {
      setResponse("Sending email...");
      setSending(true);
      const response = await fetch(
        `${baseUrl}/server/api/desunapi/v1/contact_us`,
        {
          method: "POST",
          body: JSON.stringify({
            _cs_name: enteredName,
            _cs_email: enteredEmail,
            _cs_message: enteredMessage,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();

      if (!response.ok) {
        setResponse("Something went wrong, retry!");
        setSending(false);
        throw new Error(data.message || "Something went wrong, retry!");
      }

      setResponse("Email sent successfully!");
      messageRef.current.value = "";
      nameRef.current.value = "";
      emailRef.current.value = "";

      setSending(false);
    } catch (error) {
      console.log(error);
      setResponse("Failed to send email.");
      setSending(false);
    }
  }

  return (
    <div className="">
      <div className="services-header-bg text-2xl md:text-3xl lg:text-4xl">
        {" "}
        <h2 className="text-3xl md:text-4xl">Contact Us</h2>{" "}
      </div>

      <form
        onSubmit={sendMessage}
        className='  bg-[url("../images/contact-bg.png")] bg-cover bg-center bg-no-repeat   mx-8 md:mx-12 lg:mx-20 shadow-lg py-8 px-4 rounded-lg my-4 text-pry-color '
      >
        <div className="flex w-full md:justify-center items-center  mb-4 ">
          <h1 className="font-semibold text-3xl md:text-4xl">
            Contact Desun Airlines
          </h1>
        </div>
        <div className="flex w-full md:justify-center items-center  mb-4 ">
          <p>Send us a message, we will like to hear from you.</p>
        </div>
        <div className="font-bold md:ml-[20%] mb-6">
          <p className="mb-2" htmlFor="name">
            Name
          </p>
          <input
            required
            ref={nameRef}
            className="w-full md:w-[70%] border-pry-color rounded-lg"
            name="name"
            type="text"
          />
        </div>
        <div className="font-bold md:ml-[20%] mb-6">
          <p className="mb-2" htmlFor="email">
            Email Address
          </p>
          <input
            required
            ref={emailRef}
            className="w-full md:w-[70%] border-pry-color rounded-lg"
            name="email"
            type="text"
          />
        </div>

        <div className="font-bold md:ml-[20%] mb-2">
          <p className="mb-2" htmlFor="message">
            Message
          </p>
          <textarea
            required
            ref={messageRef}
            rows="5"
            className="w-full md:w-[70%] border-pry-color rounded-lg"
            name="message"
          />
        </div>
        <div
          className={`mb-2 w-full items-center text-base flex h-6  justify-center ${
            response !== "Email sent successfully!"
              ? "text-red-700"
              : "text-green-700"
          }`}
        >
          {response}
        </div>
        <div className="mb-6 w-full flex justify-end md:pr-[24%]">
          <button
            type="submit"
            disabled={sending}
            className={`${
              sending
                ? "bg-gray-500 text-gray-300"
                : "bg-sec-color hover:bg-orange-700"
            }  text-white px-10 py-2 rounded-lg `}
          >
            Send
          </button>
        </div>
      </form>
      <div className="bg-[#f5811e19] text-pry-color space-y-4 md:py-10 md:flex py-5 px-8 md:px-12 lg:px-20">
        <div className="flex w-full md:w-1/3 space-x-5 items-center">
          <div>
            <img src={MailIcon} alt="" />
          </div>
          <div>
            <a href="mail:info@desunair.com">info@desunair.com </a>
          </div>
        </div>
        <div className="flex w-full md:w-1/3 space-x-5 items-center">
          <div>
            <img src={PhoneIcon} alt="" />
          </div>
          <div>
            <p>2348037415154 </p>
          </div>
        </div>
        <div className="flex w-full md:w-1/3 space-x-5 items-center">
          <div>
            <img src={LocationIcon} alt="" />
          </div>
          <div>
            <p>62A, Abidjan Street, Zone 3, Wuse, Abuja. </p>
          </div>
        </div>
      </div>

      <div className='bg-[url("../images/newsletter-bg.png")] bg-cover bg-center bg-no-repeat text-center py-14'>
        <h1 className="text-white font-normal text-4xl md:text-5xl lg:text-6xl">
          Subscribe to our news
        </h1>
        <p className="text-white mt-7 text-xs  md:text-lg">
          For information on flight updates, discounts and all services.
        </p>
        <div className="flex justify-center items-center mt-8 space-x-2">
          <div className="">
            <input
              placeholder="Enter your email address here"
              type="text"
              className="bg-mid-color rounded-md "
            />
          </div>
          <div className="">
            <button className="bg-sec-color text-xs py-3 sm:px-4 px-3 text-white rounded-md ">
              Send Email
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
