import React, { useState } from "react";
import About from "../images/about-page.png";
import { HashLink } from "react-router-hash-link";
import Aircraft1 from "../images/desun-aircraft1.jpg.png";
import Aircraft2 from "../images/desun-aircraft2.jpg.png";
import Map1 from "../images/map1.webp";
import Map2 from "../images/map2.jpg";
import Aodemarg from "../images/pat-aodemarg.png";
import Desunstart from "../images/pat-desunstart.png";
import { Overview, Board, Management } from "../store/about";

function AboutPage() {
  const [clickedAboutUs, setClickedAboutUs] = useState("overview");
  const OverviewDisp = ({ Overview }) => {
    return (
      <>
        <div className="w-full md:p-16 sm:p-4 p-2">
          <div className="border-2 border-gray-300 border-b-8 p-4 space-y-8 rounded-lg">
            <span className="flex mx-auto items-center justify-center space-x-2">
              <h3 className="font-sans font-semibold text-5xl text-sec-color">
                Desun
              </h3>
              <h3 className="font-sans font-semibold text-5xl text-pry-color">
                Air
              </h3>
            </span>
            <span className="font-serif sm:text-lg text-center flex mx-auto items-center sm:px-7">
              In 2022, multifaceted and intuitive entrepreneur, Fadunsin Ayo
              Ademola, saw the need to provide safe, affordable and efficient
              aviation services for transport of passengers, mail and cargo for
              commercial and other related purposes by assembling
              purpose-driven, dedicated and selfless professionals to actualize
              his dream. Setting out with an initial five (5) aircraft for both
              local and international flights, it has well-structured growth
              plan to increase the number of aircrafts to 30, covering over 200
              routes and destinations across the continents of the world.
            </span>
          </div>
        </div>
        <div className="w-full md:p-16 sm:p-4 p-2 py-8 md:py-0 grid grid-cols-1 md:grid-cols-2 gap-4">
          {Overview.map((item, index) => (
            <div
              className="border-2 border-gray-300 border-b-8 sm:p-4 p-2 w-full flex rounded-lg"
              key={index}
            >
              <div className="w-4/12 md:3/12">
                <img src={require("../images/" + item.icon + ".png")} alt="" />
              </div>
              <div className="w-8/12 md:w-9/12 grid">
                <span className="text-pry-color font-bold font-sans text-lg">
                  {item.title}
                </span>
                <span className="font-sans">{item.content}</span>
              </div>
            </div>
          ))}
        </div>
        <div className="flex flex-col mx-auto items-center justify-center h-max p-4 py-8 space-y-8">
          <div>
            <span className="flex mx-auto items-center justify-center space-x-2">
              <h3 className="font-sans font-bold text-5xl text-sec-color">
                Our
              </h3>
              <h3 className="font-sans font-bold text-5xl text-pry-color">
                Routes
              </h3>
            </span>
          </div>
          <div className="w-full mx-auto flex items-center justify-center">
            <img src={Map1} alt="" className="sm:w-[50%]" />
          </div>
        </div>
        <div className="flex flex-col mx-auto items-center justify-center h-max p-4 py-8 space-y-8">
          <div>
            <span className="flex mx-auto items-center justify-center space-x-2">
              <h3 className="font-sans font-bold text-5xl text-sec-color">
                Our
              </h3>
              <h3 className="font-sans font-bold text-5xl text-pry-color">
                Partners
              </h3>
            </span>
          </div>
          <div className="w-full flex mx-auto items-center justify-center space-x-4">
            <div className="w-[150px]">
              <img src={Aodemarg} alt="" />
            </div>
            <div className="w-[150px]">
              <img src={Desunstart} alt="" />
            </div>
          </div>
        </div>
      </>
    );
  };

  const BoardDisp = ({ Board }) => {
    return (
      <>
        <div>
          <div className="w-full md:p-16 sm:p-4 p-2 py-8 md:py-8">
            <span className="flex mx-auto items-center justify-center space-x-2">
              <h3 className="font-sans font-bold text-3xl text-sec-color">
                {clickedAboutUs == "board" ? "Board of" : "Our"}
              </h3>
              <h3 className="font-sans font-bold text-3xl text-pry-color">
                {clickedAboutUs == "board" ? "Director" : "Management"}
              </h3>
            </span>
          </div>
          <div className="w-full md:p-16 sm:p-4 p-2 py-8 md:py-0   mx-auto flex items-center justify-center flex-wrap">
            {Board.map((item, index) => (
              <div
                className="h-max max-w-sm w-full lg:w[30%] md:w-[45%] group"
                key={index}
              >
                <div className="w-full max-h-[415px] group-hover:hidden  group-hover:cursor-pointer">
                  <img
                    src={require("../images/" + item.image + ".png")}
                    alt=""
                    className="max-w-[350px]"
                  />
                </div>
                <div className="max-w-[350px] w-full h-[415px] border-2 border-gray-300 border-b-8 p-4 gap-2 hidden group-hover:grid group-hover:cursor-pointer">
                  <span className="text-pry-color font-sans font-bold text-lg text-center">
                    {item.title}
                  </span>
                  <span className="font-sans text-center text-ellipsis">
                    {item.content}
                  </span>
                </div>
                <div className="grid py-4 max-w-[350px]">
                  <span className="text-pry-color font-sans text-xl px-2 sm:text-2xl font-bold text-center cursor-pointer">
                    {item.name}
                  </span>
                  <span className="font-sans text-lg px-2 sm:text-xl font-semibold text-center cursor-pointer">
                    {item.title}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  };
  return (
    <div className="">
      <div className="flex w-full mx-auto items-center justify-center md:p-4 p-2">
        <ul className="flex items-center md:space-x-8 space-x-4 text-pry-color font-sans md:text-lg sm:text-base text-xs font-bold">
          <li
            className={
              clickedAboutUs == "overview"
                ? "text-sec-color cursor-not-allowed"
                : "cursor-pointer"
            }
            onClick={() => {
              setClickedAboutUs("overview");
            }}
          >
            Overview
          </li>
          <li
            className={
              clickedAboutUs == "management"
                ? "text-sec-color cursor-not-allowed"
                : "cursor-pointer"
            }
            onClick={() => {
              setClickedAboutUs("management");
            }}
          >
            Management
          </li>
          <li
            className={
              clickedAboutUs == "board"
                ? "text-sec-color cursor-not-allowed"
                : "cursor-pointer"
            }
            onClick={() => {
              setClickedAboutUs("board");
            }}
          >
            Board Of Directors
          </li>
        </ul>
      </div>
      <div className="services-header-bg text-2xl md:text-3xl lg:text-4xl">
        <span>About Us</span>{" "}
      </div>
      {clickedAboutUs == "overview" ? <OverviewDisp Overview={Overview} /> : ""}
      {clickedAboutUs == "management" ? <BoardDisp Board={Management} /> : ""}
      {clickedAboutUs == "board" ? <BoardDisp Board={Board} /> : ""}
      {/* <div className="md:flex  px-8 md:space-x-10 pt-5 md:items-center">
        <div className="md:w-1/2 ">
          <img className=" w-full" src={About} alt="" />
        </div>
        <div className="md:w-1/2">
          <h2 className="text-pry-color font-bold mt-5 md:mt-0  mb-4 text-3xl">
            About <span className="text-sec-color">Desun</span> Air
          </h2>
          <p className="mb-4 pb-4 text-justify  text-pry-color  text-lg lg:leading-10 leading-relaxed">
            In 2022, multifaceted and intuitive entrepreneur, General Ademola
            Fadunsin rtd, saw the need to provide safe, affordable and efficient
            aviation services for transport of passengers, mails and cargoes for
            commercial and other related purposes by assembling purpose-driven,
            dedicated and selfless professionals to actualize his dream. Setting
            out with an initial four(4) aircrafts for domestic flights. Desun
            Airlines has well-structured growth plan to increase the number of
            aircrafts as required covering over twenty(20) routes in Nigeria.
          </p>
        </div>
      </div>

      <div className="p-8 md:flex md:space-x-8 text-justify ">
        <div className="space-x-5 mb-5">
          <span className="text-pry-color font-bold text-xl">MISSION:</span>
          <span className="text-justify text-pry-color text-lg md:leading-7 leading-relaxed">
            Desun Airlines Limited provides safety and timely aviation services
            to customers through efficient departure control systems (DCS) and
            customer friendly services.
          </span>
        </div>

        <div className="space-x-5">
          <span className="text-pry-color font-bold text-xl">VISION:</span>
          <span className="text-justify text-pry-color text-lg md:leading-7 leading-relaxed">
            Desun Airlines Vision is to be the unbeatable and time conscious
            Airlines in the aviation industry where safety, efficiency and
            timeliness are paramount.{" "}
          </span>
        </div>
      </div>

      <div className="text-pry-color p-8">
        <h1 className="mb-2">
          OUR <span className="text-sec-color">SERVICES</span>{" "}
        </h1>
        <p className="mb-3 italic font-semibold">
          Desun Airlines Services cover a broad interest in the aviation
          industry in Nigeria. Our services include the following:{" "}
        </p>
        <ul className="grid md:grid-cols-2 text-justify gap-8 md:leading-7 leading-relaxed">
          <li>
            <span className="font-extrabold text-sec-color">&gt;&gt;</span>{" "}
            Desun Airlines Limited shall provide safe, affordable and efficient
            aviation services for transport of passengers, mails and cargoes for
            commercial and other purposes.{" "}
          </li>
          <li>
            <span className="font-extrabold text-sec-color">&gt;&gt;</span> It
            shall acquire by purchase, lease or hire or in any other form,
            aircrafts, hovercrafts, helicopters and simulators. In addition, it
            repairs, overhauls, reconstructs, assembles or reconditions the same
            or other ancillary machines, parts and accessories.{" "}
          </li>
          <li>
            <span className="font-extrabold text-sec-color">&gt;&gt;</span> The
            company shall acquire and fabricate any parts, accessories,
            instruments of aircrafts, hovercrafts or simulators and other
            ancillary machines.{" "}
          </li>
          <li>
            <span className="font-extrabold text-sec-color">&gt;&gt;</span>{" "}
            Desun Airlines Limited shall acquire, construct, maintain, repair
            and generally keep in good condition workshops, gliders, airports,
            aerodromes, airfields, landing grounds and terminal buildings.{" "}
          </li>
          <li>
            <span className="font-extrabold text-sec-color">&gt;&gt;</span> It
            shall also provide training for individuals and professionals in the
            aviation industry.{" "}
          </li>
        </ul>
      </div> */}

      {/* <div className="md:flex text-justify md:space-x-8 p-8 text-pry-color  ">
        <div className="md:w-1/2  mb-6">
          <h1 className="mb-2">CHIEF EXECUTIVE OFFICER</h1>
          <p className="md:leading-7 leading-relaxed">
            <span className="text-xl font-semibold text-sec-color">
              Chairman/CEO:
            </span>{" "}
            General Ademola Fadunsin GSS fdc rtd, has served his country
            meritoriously with 34 years in active service in the Nigerian Army.
            He is a successful entrepreneur and the CEO of A.O. Demarg Nigeria
            Limited and Desun Solar Nigeria Limited; both companies are leading
            producers and distributors of quality solar solutions across
            Nigeria. He is the Founder of Desun Airlines Limited.
          </p>

          <p className="md:leading-7 leading-relaxed mt-3">
            <span className="text-xl font-semibold text-sec-color">
              Director:
            </span>{" "}
            Ayo Fadunsin is a Director as well as a commercial pilot.
          </p>
        </div>
        <div className="md:w-1/2 ">
          <h1 className="mb-2">
            <span className="text-sec-color">FINANCIAL</span> INFORMATION
          </h1>
          <p className="md:leading-7 leading-relaxed">
            Desun Airlines Limited is seeking investors to grow the Desun Air to
            provide safe, affordable, timeliness and efficient technological
            driven aviation services to a wider client. The founder and his team
            are seeking investors with thirst for efficiency, hunger for
            sustainability and drive for growth to come on board.{" "}
          </p>
        </div>
      </div> */}

      {/* <div className="text-pry-color p-8 space-y-3 bg-white">
        <h1>
          <span className="text-sec-color">OUR</span> AIRCRAFTS
        </h1>
        <p className="md:leading-7 leading-relaxed text-justify">
          With an initial four (4) Embraer aircrafts; <b>ERJ145XR</b> and{" "}
          <b>E190-ER</b>, Desun Airlines Limited is poised to be the leading
          commercial airline in Nigeria in the next few years. The aircrafts
          shall provide both passengers and cargoes air lifting across Nigeria.
          At its onset, it shall provide aviation commercial services across a
          number of states in Nigeria.{" "}
        </p>
        <div className="md:flex md:space-x-8">
          <div className="md:w-1/2">
            <div>
              <img src={Aircraft1} alt="" />
            </div>
            <div className="flex justify-center">
              {" "}
              <p>ERJ145XR</p>
            </div>
          </div>
          <div className="md:w-1/2">
            <div>
              <img src={Aircraft2} alt="" />
            </div>
            <div className="flex justify-center">
              {" "}
              <p>E190-ER</p>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="p-8 text-pry-color ">
        <h1 className="mb-2">
          OUR <span className="text-sec-color">ROUTES</span>
        </h1>
        <p className="font-semibold italic mb-4">
          Desun Air shall fly passengers and cargoes across the following
          routes:{" "}
        </p>

        <div className=" ">
          <div className=" flex font-bold mb-2 items-center justify-center">
            <div className="w-[56%] text-center   border-r-black">
              <p>CITY PAIR</p>
            </div>
            <div className="w-[28%] text-center">
              <p>TOTAL DISTANCE IN NAUTICAL MILES (NM)</p>
            </div>
          </div>
          <div className="flex justify-center my-2 items-center">
            <div className="w-[28%] font-semibold">
              <p>DEPARTURE</p>
            </div>
            <div className="w-[28%] font-semibold">
              <p>ARRIVAL</p>
            </div>
            <div className="w-[28%] text-center invisible">
              <p>.</p>
            </div>
          </div>
          <div className="flex justify-center my-2 items-center">
            <div className="w-[28%]">
              <p>ABUJA</p>
            </div>
            <div className="w-[28%]">
              <p>ASABA</p>
            </div>
            <div className="w-[28%] text-center">
              <p>172 </p>
            </div>
          </div>
          <div className="flex justify-center my-2 items-center">
            <div className="w-[28%]">
              <p>ABUJA</p>
            </div>
            <div className="w-[28%]">
              <p>BAUCHI </p>
            </div>
            <div className="w-[28%] text-center">
              <p>172 </p>
            </div>
          </div>
          <div className="flex justify-center my-2 items-center">
            <div className="w-[28%]">
              <p>ABUJA</p>
            </div>
            <div className="w-[28%]">
              <p>BENIN</p>
            </div>
            <div className="w-[28%] text-center">
              <p>189</p>
            </div>
          </div>
          <div className="flex justify-center my-2 items-center">
            <div className="w-[28%]">
              <p>ABUJA</p>
            </div>
            <div className="w-[28%]">
              <p>CALABAR </p>
            </div>
            <div className="w-[28%] text-center">
              <p>249 </p>
            </div>
          </div>
          <div className="flex justify-center my-2 items-center">
            <div className="w-[28%]">
              <p>ABUJA</p>
            </div>
            <div className="w-[28%]">
              <p>GOMBE </p>
            </div>
            <div className="w-[28%] text-center">
              <p>229 </p>
            </div>
          </div>
          <div className="flex justify-center my-2 items-center">
            <div className="w-[28%]">
              <p>ABUJA</p>
            </div>
            <div className="w-[28%]">
              <p>IBADAN </p>
            </div>
            <div className="w-[28%] text-center">
              <p>217 </p>
            </div>
          </div>
          <div className="flex justify-center my-2 items-center">
            <div className="w-[28%]">
              <p>ABUJA</p>
            </div>
            <div className="w-[28%]">
              <p>JOS </p>
            </div>
            <div className="w-[28%] text-center">
              <p>104 </p>
            </div>
          </div>
          <div className="flex justify-center my-2 items-center">
            <div className="w-[28%]">
              <p>ABUJA</p>
            </div>
            <div className="w-[28%]">
              <p>KADUNA </p>
            </div>
            <div className="w-[28%] text-center">
              <p>101 </p>
            </div>
          </div>
          <div className="flex justify-center my-2 items-center">
            <div className="w-[28%]">
              <p>ABUJA</p>
            </div>
            <div className="w-[28%]">
              <p>KANO </p>
            </div>
            <div className="w-[28%] text-center">
              <p>197 </p>
            </div>
          </div>
          <div className="flex justify-center my-2 items-center">
            <div className="w-[28%]">
              <p>ABUJA</p>
            </div>
            <div className="w-[28%]">
              <p>LAGOS </p>
            </div>
            <div className="w-[28%] text-center">
              <p>275 </p>
            </div>
          </div>
          <div className="flex justify-center my-2 items-center">
            <div className="w-[28%]">
              <p>ABUJA</p>
            </div>
            <div className="w-[28%]">
              <p>MAKURDI </p>
            </div>
            <div className="w-[28%] text-center">
              <p>113 </p>
            </div>
          </div>
          <div className="flex justify-center my-2 items-center">
            <div className="w-[28%]">
              <p>ABUJA</p>
            </div>
            <div className="w-[28%]">
              <p>OWERRI </p>
            </div>
            <div className="w-[28%] text-center">
              <p>214 </p>
            </div>
          </div>
          <div className="flex justify-center my-2 items-center">
            <div className="w-[28%]">
              <p>ABUJA</p>
            </div>
            <div className="w-[28%]">
              <p>PORTHARCOURT</p>
            </div>
            <div className="w-[28%] text-center">
              <p>240 </p>
            </div>
          </div>
          <div className="flex justify-center my-2 items-center">
            <div className="w-[28%]">
              <p>ABUJA</p>
            </div>
            <div className="w-[28%]">
              <p>YENEGOA </p>
            </div>
            <div className="w-[28%] text-center">
              <p>249 </p>
            </div>
          </div>
          <div className="flex justify-center my-2 items-center">
            <div className="w-[28%]">
              <p>ABUJA</p>
            </div>
            <div className="w-[28%]">
              <p>YOLA</p>
            </div>
            <div className="w-[28%] text-center">
              <p>307</p>
            </div>
          </div>
          <div className="flex justify-center my-2 items-center">
            <div className="w-[28%]">
              <p>BENIN</p>
            </div>
            <div className="w-[28%]">
              <p>LAGOS</p>
            </div>
            <div className="w-[28%] text-center">
              <p>{}</p>
            </div>
          </div>

          <div className="flex justify-center my-2 items-center">
            <div className="w-[28%]">
              <p>CALABAR</p>
            </div>
            <div className="w-[28%]">
              <p>LAGOS</p>
            </div>
            <div className="w-[28%] text-center">
              <p>{}</p>
            </div>
          </div>

          <div className="flex justify-center my-2 items-center">
            <div className="w-[28%]">
              <p>LAGOS</p>
            </div>
            <div className="w-[28%]">
              <p>ENUGU</p>
            </div>
            <div className="w-[28%] text-center">
              <p>{}</p>
            </div>
          </div>

          <div className="flex justify-center my-2 items-center">
            <div className="w-[28%]">
              <p>LAGOS</p>
            </div>
            <div className="w-[28%]">
              <p>PORTHARCOURT</p>
            </div>
            <div className="w-[28%] text-center">
              <p>{}</p>
            </div>
          </div>

          <div className="flex justify-center my-2 items-center">
            <div className="w-[28%]">
              <p>PORTHARCOURT</p>
            </div>
            <div className="w-[28%]">
              <p>ABUJA</p>
            </div>
            <div className="w-[28%] text-center">
              <p>{}</p>
            </div>
          </div>

          <div className="flex justify-center my-2 items-center">
            <div className="w-[28%]">
              <p>ENUGU</p>
            </div>
            <div className="w-[28%]">
              <p>ABUJA</p>
            </div>
            <div className="w-[28%] text-center">
              <p>{}</p>
            </div>
          </div>

          <div className="flex justify-center my-2 items-center">
            <div className="w-[28%]">
              <p>PORTHARCOURT</p>
            </div>
            <div className="w-[28%]">
              <p>LAGOS</p>
            </div>
            <div className="w-[28%] text-center">
              <p>{}</p>
            </div>
          </div>

          <div className="flex justify-center my-2 items-center">
            <div className="w-[28%]">
              <p>LAGOS</p>
            </div>
            <div className="w-[28%]">
              <p>ABUJA</p>
            </div>
            <div className="w-[28%] text-center">
              <p>{}</p>
            </div>
          </div>

          <div className="flex justify-center my-2 items-center">
            <div className="w-[28%]">
              <p>OWERRI</p>
            </div>
            <div className="w-[28%]">
              <p>ABUJA</p>
            </div>
            <div className="w-[28%] text-center">
              <p>{}</p>
            </div>
          </div>

          <div className="flex justify-center my-2 items-center">
            <div className="w-[28%]">
              <p>YOLA</p>
            </div>
            <div className="w-[28%]">
              <p>ABUJA</p>
            </div>
            <div className="w-[28%] text-center">
              <p>{}</p>
            </div>
          </div>

          <div className="flex justify-center my-2 items-center">
            <div className="w-[28%]">
              <p>ABUJA</p>
            </div>
            <div className="w-[28%]">
              <p>MAIDUGURI</p>
            </div>
            <div className="w-[28%] text-center">
              <p>{}</p>
            </div>
          </div>

          <div className="flex justify-center my-2 items-center">
            <div className="w-[28%]">
              <p>MAIDUGURI</p>
            </div>
            <div className="w-[28%]">
              <p>ABUJA</p>
            </div>
            <div className="w-[28%] text-center">
              <p>{}</p>
            </div>
          </div>

          <div className="flex justify-center my-2 items-center">
            <div className="w-[28%]">
              <p>MAKURDI</p>
            </div>
            <div className="w-[28%]">
              <p>ABUJA</p>
            </div>
            <div className="w-[28%] text-center">
              <p>{}</p>
            </div>
          </div>

          <div className="flex justify-center my-2 items-center">
            <div className="w-[28%]">
              <p>JOS</p>
            </div>
            <div className="w-[28%]">
              <p>ABUJA</p>
            </div>
            <div className="w-[28%] text-center">
              <p>{}</p>
            </div>
          </div>

          <div className="flex justify-center my-2 items-center">
            <div className="w-[28%]">
              <p>ABUJA</p>
            </div>
            <div className="w-[28%]">
              <p>JOS</p>
            </div>
            <div className="w-[28%] text-center">
              <p>{}</p>
            </div>
          </div>

          <div className="flex justify-center my-2 items-center">
            <div className="w-[28%]">
              <p>KANO</p>
            </div>
            <div className="w-[28%]">
              <p>ABUJA</p>
            </div>
            <div className="w-[28%] text-center">
              <p>{}</p>
            </div>
          </div>

          <div className="flex justify-center my-2 items-center">
            <div className="w-[28%]">
              <p>GOMBE</p>
            </div>
            <div className="w-[28%]">
              <p>ABUJA</p>
            </div>
            <div className="w-[28%] text-center">
              <p>{}</p>
            </div>
          </div>

          <div className="flex justify-center my-2 items-center">
            <div className="w-[28%]">
              <p>KADUNA</p>
            </div>
            <div className="w-[28%]">
              <p>ABUJA</p>
            </div>
            <div className="w-[28%] text-center">
              <p>{}</p>
            </div>
          </div>
        </div>

        <div className="md:flex mt-8 md:space-x-8">
          <div className="md:w-1/2">
            <img src={Map1} alt="" />{" "}
          </div>
          <div className="md:w-1/2">
            <img src={Map2} alt="" />{" "}
          </div>
        </div>
      </div> */}

      {/* <div className="text-pry-color p-8">
        <h1>PARTNERS</h1>
        <p className="italic font-semibold">
          Desun Airlines Limited is affiliated with:{" "}
        </p>
        <ul>
          <li>
            <span className="font-extrabold text-sec-color">&gt;&gt;</span>{" "}
            A.O.Demarg Nigeria Limited{" "}
          </li>
          <li>
            <span className="font-extrabold text-sec-color">&gt;&gt;</span>{" "}
            Desun Solar Nigeria Limited{" "}
          </li>
        </ul>
      </div> */}
    </div>
  );
}

export default AboutPage;
