import React, { useState, useEffect } from "react";
import AllCtx from "../../uti-functions/allCtx";
import { useNavigate } from "react-router-dom";

function SelectSeat(props) {
  let navigate = useNavigate();
  const [response, setResponse] = useState("");
  const [selectingSeat, setSelectingSeat] = useState(false);
  const [canRunTimeOut, setCanRunTimeOut] = useState(true);
  const [chosenSeat, setChosenSeat] = useState("");
  const [seatClicked, setSeatClicked] = useState(false);

  const {
    flightSeatArray,
    userToken,
    user,
    flightRefID,
    setTheFlightRefID,
    setTheIndividualRefID,
    setTheNotificationText,
    setTheShowNotification,
    tripType,
    adult,
    children,
    infant,
    allPassengers,
    adultArray,
    childrenArray,
    infantArray,
    flightArray,
    setTheFlightArray,
    chosenFlight,
    chosenFlightID,
    setTheChosenClass,
    setTheChosenClassPrice,
    chosenClass,
    chosenClassName,
    chosenClassPrice,
    setTheChosenFlight,
    setTheChosenFlightID,
    searchBookingResponse,
    setTheSearchBookingResponse,
    setTheChosenClassName,
    setTheClassChosen,
    classChosen,
    setTheSearchResultResponse,
    searchResultResponse,
    chosenClassTax,
    chosenClassFees,
    chosenClassSurge,
    setTheChosenClassTax,
    setTheChosenClassFees,
    setTheChosenClassSurge,
    setTheFlightSeatArray,
    individualRefID, baseUrl
  } = AllCtx();

  useEffect(() => {
    if (localStorage.getItem("flightSeatArray")) {
      setTheFlightSeatArray(
        JSON.parse(localStorage.getItem("flightSeatArray"))
      );
    }
    // console.log(flightSeatArray);
  }, []);

  const checkInDetails = {
    _seat_number: chosenSeat,
    _ref_id: individualRefID,
  };

  async function selectSeatHandler() {
    console.log(checkInDetails);

    if (!chosenSeat) {
      setResponse("You must choose a seat before you check in.");
      setSelectingSeat(false);
      return;
    }

    if (!individualRefID) {
      setResponse(
        "Seems your page refreshed. Go back to the Check In tab to load your reference ID again."
      );
      setSelectingSeat(false);
      return;
    }

    try {
      setSelectingSeat(true);
      setResponse("Please wait...");
      // console.log(bookingDetails);
      const response = await fetch(
        `${baseUrl}/server/api/desunapi/v1/check_in/update`,
        {
          // mode: 'no-cors',
          method: "POST",
          body: JSON.stringify(checkInDetails),
          headers: {
            "Content-Type": "application/json",
            Useremail: `${user.email}`,
            Usertoken: `${userToken}`,
          },
        }
      );

      const data = await response.json();

      console.log(data);

      // return

      if (!response.ok) {
        setResponse("Failed! Please try again.");
        setSelectingSeat(false);

        console.log("Failed! Please try again.");

        return;
      }

      setTheIndividualRefID(data.ps_details.ref_id )
      setTheFlightRefID(data.booking_ref_id);
      setTheNotificationText("You have successfully checked in.");
      navigate("/success-check-in");
      if (canRunTimeOut) {
        const timer = setTimeout(() => {
          setTheShowNotification(false);
          setTheNotificationText("");
        }, 4000);

        return () => clearTimeout(timer);
      }

      setResponse("");

      setSelectingSeat(false);
    } catch (error) {
      console.log(error);
      setResponse("Error! Please try again.");
      setSelectingSeat(false);
    }
  }

  return (
    <div className="md:w-[35%] px-8 md:px-0 ">
      <div className="mb-3 md:mt-0 mt-3 text-pry-color font-semibold">
        <p>Flight Seat Order</p>
      </div>
      <div className="border-2 rounded-md px-3 py-4">
        <div className="rounded-tl-[110px] rounded-tr-[110px] px-3 pt-32 h-[1200px] bg-gradient-to-b from-pry-color to-white">
          <div className="h-full bg-transparent flex justify-center">
            <div className="h-full rounded-tl-full  w-[40%] bg-white">
              <div className="mt-[5rem] flex justify-center text-pry-color font-semibold">
                <div className="grid grid-cols-3 gap-2 450:gap-y-3 450:gap-x-5  sm:gap-x-8 md:gap-x-3 lg:gap-x-5">
                  <p className="w-3 h-3 400:h-4 400:w-4 450:w-5 450:h-5 text-center">
                    A
                  </p>
                  <p className="w-3 h-3 400:h-4 400:w-4 450:w-5 450:h-5 text-center">
                    B
                  </p>
                  <p className="w-3 h-3 400:h-4 400:w-4 450:w-5 450:h-5 text-center">
                    C
                  </p>
                  {flightSeatArray.map(
                    (seat) =>
                      (seat.name.includes("A") ||
                        seat.name.includes("B") ||
                        seat.name.includes("C")) && (
                        <div
                          key={seat.name}
                          disabled={seat.status === "unavailable"}
                          onMouseOver={() => {
                            // setChosenSeat(seat.name)
                          }}
                          onClick={() => {
                            setChosenSeat(seat.name);
                            setSeatClicked(true);
                            console.log(seat.name);
                          }}
                          className={`hover:scale-125 cursor-pointer ${
                            chosenSeat === seat.name ? "scale-125" : ""
                          } h-3 w-3 400:h-4 400:w-4 450:w-5 450:h-5 ${
                            seat.status === "available" &&
                            chosenSeat !== seat.name
                              ? "bg-green-600"
                              : chosenSeat === seat.name && seatClicked
                              ? "bg-pry-color"
                              : "bg-gray-600"
                          }`}
                        >
                          {}
                        </div>
                      )
                  )}
                </div>
              </div>{" "}
            </div>
            <div className="h-full w-[10%]  bg-pry-color">
              <div className="mt-[5rem] flex justify-center text-white font-semibold">
                <div className=" grid grid-cols-1 gap-2 450:gap-y-3 450:gap-x-5 sm:gap-x-8 md:gap-x-3 lg:gap-x-5">
                  {" "}
                  <p className="w-3 h-3  400:h-4 400:w-4 450:w-5 450:h-5 text-center"></p>
                  {[
                    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
                    18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
                  ].map((row, i) => (
                    <p
                      key={i}
                      className=" flex items-center justify-center w-3 h-3 400:h-4 400:w-4 450:w-5 450:h-5 text-center"
                    >
                      {row}
                    </p>
                  ))}
                </div>
              </div>{" "}
            </div>
            <div className="h-full rounded-tr-full  w-[40%] bg-white">
              <div className="mt-[5rem] flex justify-center text-pry-color font-semibold">
                <div className="grid grid-cols-3 gap-2 450:gap-y-3 450:gap-x-5  sm:gap-x-8 md:gap-x-3 lg:gap-x-5">
                  <p className="w-3 h-3 400:h-4 400:w-4 450:w-5 450:h-5 text-center">
                    D
                  </p>
                  <p className="w-3 h-3 400:h-4 400:w-4 450:w-5 450:h-5 text-center">
                    E
                  </p>
                  <p className="w-3 h-3 400:h-4 400:w-4 450:w-5 450:h-5 text-center">
                    F
                  </p>
                  {flightSeatArray.map(
                    (seat) =>
                      (seat.name.includes("D") ||
                        seat.name.includes("E") ||
                        seat.name.includes("F")) && (
                        <div key={seat.name}
                          disabled={seat.status === "unavailable"}
                          onMouseOver={() => {
                            // setChosenSeat(seat.name)
                          }}
                          onClick={() => {
                            setChosenSeat(seat.name);
                            setSeatClicked(true);
                            console.log(seat.name);
                          }}
                          className={`hover:scale-125 cursor-pointer ${
                            chosenSeat === seat.name ? "scale-125" : ""
                          } h-3 w-3 400:h-4 400:w-4 450:w-5 450:h-5 ${
                            seat.status === "available" &&
                            chosenSeat !== seat.name
                              ? "bg-green-600"
                              : chosenSeat === seat.name && seatClicked
                              ? "bg-pry-color"
                              : "bg-gray-600"
                          } `}
                        >
                          {}
                        </div>
                      )
                  )}
                </div>
              </div>{" "}
            </div>
          </div>
        </div>
        <div className=" h-6 flex justify-center text-sec-color mt-4 mb-3">
          <p className='text-xs font-semibold'>{response}</p>
        </div>
        <div className="flex justify-end m-4 mt-6">
          <button
            onClick={selectSeatHandler}
            className="px-4 bg-sec-color text-white py-2 rounded-md"
          >
            Check In
          </button>
        </div>
      </div>
    </div>
  );
}

export default SelectSeat;
