import React from "react";
import AllCtx from "../uti-functions/allCtx";
import Success from "../images/success.gif";
import { useNavigate } from "react-router-dom";

function SuccessPayment() {
  const { flightRefID } = AllCtx();
  let navigate = useNavigate();

  return (
    <div className="">
      <div className=" mb-10 justify-center  services-header-bg  text-2xl md:text-3xl lg:text-4xl ">
        <p className="text-green-600">Payment Successful</p>
      </div>

      <div className=" px-4  text-green-600 font-semibold text-lg">
        <div className=" flex justify-center items-center">
          <img className="h-20 w-20" src={Success} alt="" />
        </div>
        <div className=" flex justify-center items-center mb-5">
          {" "}
          <p>
            Payment for the booking {" "}
            <span className="text-pry-color font-bold">{`${flightRefID}`}</span>{" "}
            was successful!
          </p>
        </div>

        <div className=" flex justify-center items-center">
          {" "}
          <p>
            We have sent the reference ID for each passenger to your email
            address.
          </p>
        </div>
        <div className="flex justify-center mt-16">
          <button
            onClick={() => {
              navigate("/");
            }}
            className="bg-sec-color font-bold text-white px-5 py-3 rounded-lg"
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
}

export default SuccessPayment;
