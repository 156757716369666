import React from "react";
import AllCtx from "../uti-functions/allCtx";
import DurationAirplane from "../images/duration-airplane.svg";
import DurationAirplaneReturn from "../images/duration-airplane-return.svg";
import ShowMoreIcon from "../images/show-more-icon.svg";
import { useNavigate } from "react-router-dom";

function SearchResultMobileView() {
  let navigate = useNavigate();

  const {
    setTheTotalPrice,
    setTheFlightRefID,
     prev, next,
    adultArray,
    flightArray,
    setTheFlightArray,
    chosenFlight,
    chosenFlightID,
    setTheChosenClass,
    setTheChosenClassPrice,
    chosenClass,
    chosenClassName,
    chosenClassPrice,
    setTheChosenFlight,
    setTheChosenFlightID,
    searchBookingResponse,
    setTheSearchBookingResponse,
    setTheChosenClassName,
    setTheClassChosen,
    classChosen,
    setTheSearchResultResponse,
    searchResultResponse,
    setTheAdultArray,
    setTheChildrenArray, tripType,
    setTheInfantArray,
    chosenClassTax,
    chosenClassFees,
    chosenClassSurge,
        setTheChosenClassTax,
    setTheChosenClassFees,
    setTheChosenClassSurge,
    
  } = AllCtx();

  var date;
  var weekDay;
  var month;
  var day;
  var year;

  return (
    <div>
      {flightArray.length === 0 ? (
        <div className='flex justify-center items-center text-pry-color font-semibold mt-10'>        <p className="block md:hidden">No flight information available.</p>
</div>
      ) : (
        flightArray.map((flightList) =>
          flightList.map(
            (flight, i) => (
              (date = new Date(flight.departure_date)),
              (weekDay = date.toLocaleDateString("en-US", {
                weekday: "short",
              })),
              (day = date.toLocaleDateString("en-US", { day: "numeric" })),
              (month = date.toLocaleDateString("en-US", { month: "short" })),
              (year = date.toLocaleDateString("en-US", { year: "numeric" })),
              (
                <div
                  key={i}
                  className="block md:hidden text-pry-color px-5 pt-5 "
                >
                  <div className="flex justify-center mb-2 font-semibold">
                    <p>{`${weekDay}, ${day} ${month} ${year}`}</p>
                  </div>
                  <div className="flex space-x-4">
                    <div className="w-[40%]">
                      <div className="mb-2">
                        <h1>{flight.departure_time}</h1>
                      </div>
                      <div className="mb-3">
                        <p className="text-sm sm:text-xl font-semibold ">
                        {tripType === 'single' && <span className="text-sec-color">From</span>} {flight.from}
                        </p>{" "}
                        <p className="text-xs sm:text-sm text-gray-400">
                         {flight.departure_airport}
                        </p>
                      </div>
                      {/* <div className="text-xs sm:text-sm text-gray-500 mb-3">
                    <p>{flight.title}</p>
                  </div> */}
                    </div>

                    <div>
                      <div>
                        {" "}
                        <div className="flex justify-center items-center">
                          <div className='w-20'>
                            <img src={DurationAirplane} alt="" />
                            
                          {tripType === 'round' &&<img className='mt-2 ' src={DurationAirplaneReturn} alt="" />}
                        </div>
                        </div>{" "}
                        <div className="flex text-center justify-center items-center text-xs text-gray-400">
                        <p>{flight.title}</p>
                         
                        </div>
                        <div className="text-xs text-center sm:text-xs text-gray-500 mt-3">
                        <p>{flight.duration}</p>
                        </div>
                      </div>
                    </div>

                    <div className="w-[40%] text-right">
                      <div className="mb-2">
                        <h1>{flight.arrival_time}</h1>
                      </div>
                      <div className="mb-3">
                        <p className="text-sm sm:text-xl font-semibold">
                          {" "}
                          {tripType === 'single' && <span className="text-sec-color">To</span>} {flight.to}
                        </p>{" "}
                        <p className="text-xs sm:text-sm text-gray-400">
                         {flight.arrival_airport}
                        </p>
                      </div>
                      {/* <div className="text-xs sm:text-sm text-gray-500 mb-3">
                    <p>{flight.title}</p>
                  </div> */}
                    </div>
                  </div>
                  <div className='mt-4 text-center text-xl text-sec-color font-semibold '>Flight Classes Available</div>
                  <div className="grid  grid-cols-3 space-x-2 text-xs  text-center">
                    {flight.class.map((flightClass, i) => (
                
                flightClass &&    <div
                        onMouseOver={() => {
                          setTheChosenFlight(flight.ID);
                          // setTheChosenFlightID(flight.ID);
                        }}
                        onClick={() => {
                          setTheClassChosen(true);
 
                          setTheChosenClass(flightClass.name);
                          console.log(
                            flight.ID,
                            flightClass.name,
                            flightClass.cul_price
                          );
                          setTheChosenFlightID(flight.ID);
                          localStorage.setItem(
                            "chosenFlight",
                            JSON.stringify(chosenFlight)
                          );
                          setTheChosenClassName(flightClass.name);
                        
                          localStorage.setItem(
                            "chosenClassName",
                            JSON.stringify(chosenClassName)
                          );

                          setTheChosenClassPrice(flightClass.cul_price)
                          localStorage.setItem(
                            "chosenClassPrice",
                            JSON.stringify(chosenClassPrice)
                          );

                          setTheChosenClassFees(flightClass.fees)
                          setTheChosenClassSurge(flightClass.surcharges)
                          setTheChosenClassTax(flightClass.taxes)
                          setTheTotalPrice(flightClass.total_price)
                        }}
                        key={i}
                        className={`${
                          chosenClassName === flightClass?.name &&
                          chosenFlightID === flight.ID &&
                          classChosen
                            ? "bg-sec-color text-white"
                            : "hover:bg-gray-300"
                        } mb-4 shadow-sm  cursor-pointer w-[55%] py-2 duration-300 rounded-md mt-2`}
                      >
                        <p>{flightClass?.name}</p>
                        <p className="font-semibold text-black">
                          (₦{flightClass?.total_price})
                        </p>
                      </div>
                    ))}
                  </div>
                  <div className="flex justify-center pt-3 text-red-500">
                    <p>{searchResultResponse}</p>
                  </div>

                  <div className="flex mt-5 mb-5   text-sec-color items-center text-sm">
                    <p
                      onMouseOver={() => {
                        setTheChosenFlight(flight.ID);
                      }}
                      onClick={() => {
                        setTheChosenFlight(flight.ID);
                        

                        if (classChosen) {
                          navigate("/booking");
                          setTheClassChosen(false);

                          localStorage.setItem(
                            "adultArray",
                            JSON.stringify(adultArray)
                          );
                          setTheAdultArray([]) 
                          setTheChildrenArray([])
                          setTheInfantArray([])
                          setTheFlightRefID('')
                        } else {
                          setTheSearchResultResponse(
                            "Select a class before you continue."
                          );
                        }
                      }}
                      className="pr-2 pb-1 cursor-pointer "
                    >
                      Continue
                    </p>
                    <img
                      onMouseOver={() => {
                        setTheChosenFlight(flight.ID);
                      }}
                      onClick={() => {
                        setTheChosenFlight(flight.ID);

                        if (classChosen) {
                          navigate("/booking");
                          setTheClassChosen(false);
                        } else {
                          setTheSearchResultResponse(
                            "Select a class before you continue."
                          );
                          setTheClassChosen(false);
                        }
                      }}
                      className="h-5 w-5 cursor-pointer"
                      src={ShowMoreIcon}
                      alt=""
                    />
                  </div>
                  <hr />
                </div>
              )
            )
          ).filter((a, i) => i >= prev && i < next)
        )
      )}
    </div>
  );
}

export default SearchResultMobileView;
