import React, { useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import AllCtx from "../uti-functions/allCtx";

function ResetPassword() {
  const { baseUrl } = AllCtx();

  const [searchParams, setSearchParams] = useSearchParams();

  const [response, setResponse] = useState("");
  const [sending, setSending] = useState(false);

  const newPasswordRef = useRef();
  const confirmNewPasswordRef = useRef();

  async function resetPassword(e) {
    e.preventDefault();

    const enteredNewPassword = newPasswordRef.current.value;
    const enteredConfirmNewPassword = confirmNewPasswordRef.current.value;
    // const paramEmail = searchParams.get('rp_email')
    // const paramEmail = searchParams.get("rp_email");
    const paramKey = searchParams.get("rp_key");
    const paramLogin = searchParams.get("rp_login");

    if (!enteredNewPassword || enteredNewPassword.trim() === "") {
      setResponse("Fill all inputs!");
      return;
    }

    if (enteredNewPassword !== enteredConfirmNewPassword) {
      setResponse("Passwords must match!");
      return;
    }

    if (!paramKey || paramKey.trim() === "") {
      setResponse("You did not request a password reset.");
    }
    // return;

    try {
      setResponse("Sending...");
      setSending(true);
      const response = await fetch(
        `${baseUrl}/server/api/desunapi/v1/reset_password`,
        {
          method: "POST",
          body: JSON.stringify({
            _password: enteredNewPassword,
            _key: paramKey,
            _login: paramLogin,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();
      console.log(data);

      if (!response.ok) {
        data.map((dat) => {
          setResponse(dat.message);
          console.log(dat.message);
          setSending(false);
        });

        return;
      }

      data.map((dat) => {
        setResponse(dat.message);
        console.log(dat.message);
      });

      setSending(false);
    } catch (error) {
      console.log(error);
      setResponse("Failed to send.");
      setSending(false);
    }
  }

  return (
    <div className="">
      <div className="services-header-bg text-2xl md:text-3xl lg:text-4xl">
        {" "}
        <h2 className="text-3xl md:text-4xl">Reset Password</h2>{" "}
      </div>

      <form
        onSubmit={resetPassword}
        className='  bg-[url("../images/contact-bg.png")] bg-cover bg-center bg-no-repeat   mx-8 md:mx-12 lg:mx-20 shadow-lg py-8 px-4 rounded-lg my-4 text-pry-color '
      >
        <div className="flex w-full md:justify-center items-center  mb-4 ">
          <h1 className="font-semibold text-3xl md:text-4xl">
            You Can Reset Your Password
          </h1>
        </div>
        <div className="flex w-full md:justify-center items-center  mb-4 ">
          <p>
            You must have got the reset link from us before you can reset your
            password here.
          </p>
        </div>

        <div className="font-semibold md:ml-[20%] mb-6">
          <p className="mb-2" htmlFor="">
            New Password
          </p>
          <input
            required
            ref={newPasswordRef}
            className="w-full md:w-[70%] border-pry-color rounded-lg"
            name=""
            type="password"
          />
        </div>

        <div className="font-semibold md:ml-[20%] mb-6">
          <p className="mb-2" htmlFor="">
            Confirm New Password
          </p>
          <input
            ref={confirmNewPasswordRef}
            required
            className="w-full md:w-[70%] border-pry-color rounded-lg"
            name=""
            type="password"
          />
        </div>

        <div
          className={`mb-2 w-full items-center text-base flex h-6  justify-center ${
            response !== "Email sent successfully!"
              ? "text-red-700"
              : "text-green-700"
          }`}
        >
          {response}
        </div>
        <div className="mb-6 w-full flex justify-end md:pr-[24%]">
          <button
            type="submit"
            disabled={sending}
            className={`${
              sending
                ? "bg-gray-500 text-gray-300"
                : "bg-sec-color hover:bg-orange-700"
            }  text-white px-10 py-2 rounded-lg `}
          >
            Reset
          </button>
        </div>
      </form>
    </div>
  );
}

export default ResetPassword;
