import React, { useRef, useState } from "react";
import BookingReferenceIcon from "../../images/booking-reference.png";
import LastName from "../../images/lastname.png";
import AllCtx from "../../uti-functions/allCtx";
import { useNavigate } from "react-router-dom";

function FlightStatusTab() {
  const {
    chosenClassTax,
    chosenClassFees,
    chosenClassSurge,
    setTheTotalPrice,
    setTheChosenClassTax,
    setTheChosenClassFees,
    setTheChosenClassSurge,
    setTheAllPassengers,
    setTheShowNotification,
    setTheNotificationText,
    user,
    adultArray,
    setTheFlightRefID,
    childrenArray,
    infantArray,
    userToken,
    setTheChosenClassName,
    chosenClassName,
    chosenClassPrice,
    setTheAdultArray,
    setTheChildrenArray,
    setTheInfantArray,
    setTheChosenClassPrice,
    setTheChosenClassID,
    setTheChosenFlightID,
    chosenFlight,
    setTheCheckInPassenger,
      setTheFlightSeatArray,
      setTheFlightStatus,
    setTheIndividualRefID,
    baseUrl,
    setTheChosenFlight,
    setThePassengerFlight,
    passengerFlight
  } = AllCtx();

  const [response, setResponse] = useState("");
  const [sending, setSending] = useState(false);
  const [canRunTimeOut, setCanRunTimeOut] = useState(true);
  let navigate = useNavigate();

  const referenceNumberRef = useRef();
  const lastNameRef = useRef();

  async function checkFlightStatus(e) {
e.preventDefault();

    const refNumInput = referenceNumberRef.current.value;
    const lastNameInput = lastNameRef.current.value;

    if(!refNumInput ||
      refNumInput.trim() === "" || !lastNameInput ||
       lastNameInput.trim() === "") {
       setResponse('You must provide the needed details.')
       return
      }

    try {
      setSending(true);
      setResponse("Please wait...");

      const response = await fetch(
        `${baseUrl}/server/api/desunapi/v1/flight_status`,
        {
          method: "POST",
          body: JSON.stringify({
            _ref_id: refNumInput,
            _last_name: lastNameInput,
          }),
          headers: {
            "Content-Type": "application/json",
            Useremail: `${user.email}`,
            Usertoken: `${userToken}`,
          },
        }
      );

      const data = await response.json();
      console.log(data);

      // return; 

      if (!response.ok) {
        setResponse(data.message);
        setSending(false);
        return;
      }

      if (data.status === "fail") {
        setSending(false);
        setResponse(data.message);
        return;
      }

 console.log(data.flight_status);

        // return

      setTheChosenFlightID(data.ID);
      // setTheChosenFlight(data)
      // localStorage.setItem("chosenFlight", JSON.stringify(data));
      setThePassengerFlight(data)
      localStorage.setItem("passengerFlight", JSON.stringify(data));
      setTheCheckInPassenger(data.ps_details);
      localStorage.setItem("checkInPassenger", JSON.stringify(data.ps_details));
      setTheFlightRefID(data.booking_ref_id); //Not really using this for now


  
      setTheFlightStatus(data.flight_status)
      localStorage.setItem("flightStatus", JSON.stringify(data.flight_status));
      setTheChosenClassName(data.class.name);
      localStorage.setItem("chosenClassName", JSON.stringify(data.class.name));



   
 

 

      navigate("/flight-status");

      setResponse("");
      setSending(false);
    } catch (error) {
      console.log(error);
      setSending(false);
      setResponse("An error occurred, please retry.");
    }
  }

  return (
    <form  onSubmit={checkFlightStatus} className="tab-form">
      <div className="text-xl">
        <p>Check the status of your flight.</p>
      </div>
      <div className="md:flex  md:px-0 space-y-3 md:space-y-0 justify-between mt-4 md:space-x-3">
        <div className="check-in-reference-div pl-2">
          <div>
            <img src={BookingReferenceIcon} alt="" />
          </div>
          <input required
            ref={referenceNumberRef}
            type="text"
            placeholder="Passenger reference ID"
            className="placeholder-pry-color border-none focus:outline-none focus:ring focus:ring-transparent w-full"
          />
        </div>
        <div className="check-in-lastname-div pl-2">
          <div>
            <img src={LastName} alt="" />
          </div>
          <input required
            ref={lastNameRef}
            type="text"
            placeholder="Last Name"
            className="placeholder-pry-color border-none focus:outline-none focus:ring focus:ring-transparent w-full"
          />
        </div>
        <div className="flex justify-end items-center   mt-5 px-3 md:px-0 md:text-sm ">
          <button
           type='submit'
            className="bg-sec-color text-white px-8 md:px-5 py-3 rounded-md "
          >
           Check Status
          </button>
        </div>
      </div>

      <div className="flex justify-center mt-4 ">
        <p className="text-sec-color text-base">{response}</p>
      </div>
    </form>
  );
}

export default FlightStatusTab;
