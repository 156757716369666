import React, { useRef, useState } from "react";
import BookingReferenceIcon from "../../images/booking-reference.png";
import LastName from "../../images/lastname.png";
import AllCtx from "../../uti-functions/allCtx";
import { useNavigate } from "react-router-dom";

function CheckInTabForm() {
  const {
    chosenClassTax,
    chosenClassFees,
    chosenClassSurge,
    setTheTotalPrice,
    setTheChosenClassTax,
    setTheChosenClassFees,
    setTheChosenClassSurge,
    setTheAllPassengers,
    setTheShowNotification,
    setTheNotificationText,
    user,
    adultArray,
    setTheFlightRefID,
    childrenArray,
    infantArray,
    userToken,
    setTheChosenClassName,
    chosenClassName,
    chosenClassPrice,
    setTheAdultArray,
    setTheChildrenArray,
    setTheInfantArray,
    setTheChosenClassPrice,
    setTheChosenClassID,
    setTheChosenFlightID,
    chosenFlight,
    setTheCheckInPassenger,
    setTheFlightSeatArray,
    setTheIndividualRefID, baseUrl, setThePassengerFlight,
    passengerFlight
  } = AllCtx();

  const [response, setResponse] = useState("");
  const [sending, setSending] = useState(false);
  const [canRunTimeOut, setCanRunTimeOut] = useState(true);
  let navigate = useNavigate();

  const referenceNumberRef = useRef();
  const lastNameRef = useRef();

  async function verifyReferenceID(e) {
    e.preventDefault();
    const refNumInput = referenceNumberRef.current.value;
    const lastNameInput = lastNameRef.current.value;

    if(!refNumInput ||
      refNumInput.trim() === "" || !lastNameInput ||
       lastNameInput.trim() === "") {
       setResponse('You must provide the needed details.')
       return
      }

    try {
      setSending(true);
      setResponse("Please wait...");

      const response = await fetch(
        `${baseUrl}/server/api/desunapi/v1/check_in`,
        {
          method: "POST",
          body: JSON.stringify({
            _ref_id: refNumInput,
            _last_name: lastNameInput,
          }),
          headers: {
            "Content-Type": "application/json",
            Useremail: `${user.email}`,
            Usertoken: `${userToken}`,
          },
        }
      );

      const data = await response.json();
      console.log(data);

      // return;

      if (!response.ok) {
        setResponse(data.message);
        setSending(false);
        return;
      }

      if (data.status === 'fail') {
        setSending(false)
        setResponse(data.message)
        return
      }

      if (data.ps_details.check_in === true) {
        setThePassengerFlight(data)
        localStorage.setItem("passengerFlight", JSON.stringify(data));
        setTheCheckInPassenger(data.ps_details)
        localStorage.setItem("checkInPassenger", JSON.stringify(data.ps_details));
        setTheFlightRefID(data.booking_ref_id);
        localStorage.setItem("flightRefID", JSON.stringify(data.booking_ref_id));
        setTheIndividualRefID(data.ps_details.ref_id);
        localStorage.setItem("individualRefID", JSON.stringify(data.ps_details.ref_id));
        console.log('Checked in already.');
        setTheNotificationText("You have previously checked in.");
        setTheChosenFlightID(data.ID);
        navigate("/success-check-in")
        
        if (canRunTimeOut) {
          const timer = setTimeout(() => {
            setTheShowNotification(false);
            setTheNotificationText("");
          }, 4000);

          return () => clearTimeout(timer);
        }
        setResponse("")
        setSending(false)
        return
      }

      

      
      setThePassengerFlight(data)
      localStorage.setItem("passengerFlight", JSON.stringify(data));
      setTheFlightSeatArray(data.flight_seats)
      localStorage.setItem("flightSeatArray", JSON.stringify(data.flight_seats));
     setTheCheckInPassenger(data.ps_details)
     setTheFlightRefID(data.booking_ref_id);
      setTheIndividualRefID(data.ps_details.ref_id);

      localStorage.setItem("checkInPassenger", JSON.stringify(data.ps_details));
      localStorage.setItem("flightRefID", JSON.stringify(data.booking_ref_id));
      localStorage.setItem("individualRefID", JSON.stringify(data.ps_details.ref_id));


  
        setTheChosenClassName(data.class.name);
        localStorage.setItem(
          "chosenClassName",
          JSON.stringify(data.class.name)
        );

        setTheChosenClassPrice(data.class.cul_price);
        localStorage.setItem(
          "chosenClassPrice",
          JSON.stringify(data.class.cul_price)
        );

        setTheChosenClassFees(data.class.fees);
        setTheChosenClassSurge(data.class.surcharges);
        setTheChosenClassTax(data.class.taxes);
      setTheChosenFlightID(data.ID);
      setTheTotalPrice(data.class.total_price)
        localStorage.setItem("chosenFlight", JSON.stringify(chosenFlight));

        setTheShowNotification(true);
        setTheNotificationText("Flight details loaded!");
        navigate("/check-in");
        if (canRunTimeOut) {
          const timer = setTimeout(() => {
            setTheShowNotification(false);
            setTheNotificationText("");
          }, 4000);

          return () => clearTimeout(timer);
        }
        setResponse("");
        setSending(false);
   
    } catch (error) {
      console.log(error);
      setSending(false);
      setResponse("An error occurred, please retry.");
    }
  }

  return (
    <form onSubmit={verifyReferenceID} className="tab-form">
      <div className="text-xl">
        <p>Check in to pick seat.</p>
      </div>
      <div className="md:flex  md:px-0 space-y-3 md:space-y-0 justify-between mt-4 md:space-x-3">
        <div className="check-in-reference-div pl-2">
          <div>
            <img src={BookingReferenceIcon} alt="" />
          </div>
          <input required
            ref={referenceNumberRef}
            type="text"
            placeholder="Passenger reference ID"
            className="placeholder-pry-color border-none focus:outline-none focus:ring focus:ring-transparent w-full"
          />
        </div>
        <div className="check-in-lastname-div pl-2">
          <div>
            <img src={LastName} alt="" />
          </div>
          <input required
            ref={lastNameRef}
            type="text"
            placeholder="Last Name"
            className="placeholder-pry-color border-none focus:outline-none focus:ring focus:ring-transparent w-full"
          />
        </div>
        <div className="flex justify-end items-center   mt-5 px-3 md:px-0 md:text-sm ">
          <button
        type='submit'
            className="bg-sec-color text-white px-8 md:px-5 py-3 rounded-md "
          >
            Check In
          </button>
        </div>
      </div>

      <div className="flex justify-center mt-4 ">
        <p className="text-sec-color text-base">{response}</p>
      </div>
    </form>
  );
}

export default CheckInTabForm;
