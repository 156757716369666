const DESTINATIONS = [
  {
    cityID: "lagos",
    cityName: "Lagos",
    touristAttraction:
      "Lekki Conversation Center, Tarkwa Bay Beach, Nike Art Centre, National Museum, Kalakuta Museum.",
    cityText:
      "Lagos Airport, officially known as Muritala Muhammed International Airport (MMIA), is the busiest and largest international airport serving Lagos, Nigeria. Lagos State (Centre of Excellence) is both the most populous and smallest area in Nigeria.",
    cityPreviewImage: "dest-lagos",
    cityImage1: "dest-p-lagos",
    cityImage2: "dest-p-lagos-1",
    cityImage3: "dest-p-lagos-2",
    tourTitle1: "Muritala Mohammed Airport",
    tourTitle2: "Beach Experience",
  },

  // {
  //   cityID: "yenegoa",
  //   cityName: "Yenegoa",
  //   touristAttraction:
  //     "Tourist attraction for Bayelsa includes places like Ox-Bow Lake, Mungo Park Residence, Okpoama Beach, Mangrove Creeks, Apoi creek Forest etc. ",
  //   cityText:
  //     "Bayelsa Airport is located in Bayelsa State. The nearest airport to Bayelsa is Warri. Bayelsa State (Glory of all Lands) is one of the states in southern part of Nigeria, located in the core of the Niger Delta region. Bayelsa State was created in 1996 and was carved out from Rivers State. Bayelsa state is home to Oloibiri (the first oil well in Nigeria discovered in 1956) which marked the beginning of Nigeria's economic base shift from agriculture to oil and gas.",
  //   cityPreviewImage: "destination-bayelsa",
  //   cityImage1: "lagos-page1",
  //   cityImage2: "lagos-page2",
  //   cityImage3: "lagos-page3",
  //   tourTitle1: "Muritala Mohammed Airport",
  //   tourTitle2: "Beach Experience",
  // },

  {
    cityID: "owerri",
    cityName: "Owerri",
    touristAttraction:
      "Tourist attraction for Imo state includes and not limited to the following: Oguta Blue Lake, Orashi River etc",
    cityText:
      "Owerri is one of the attractive cities in Imo State, and the capital city of the state. The Airport is situated at Ngor Okpola Local Government Area pm a piece of land bounded by three communities; Umuohiagu, Obiangwu and Logara, Imo State.",
    cityPreviewImage: "dest-owerri",
    cityImage1: "dest-p-owerri",
    cityImage2: "dest-p-owerri-1",
    cityImage3: "dest-p-owerri-2",
    tourTitle1: "Sam Mbakwe International Cargo Airport",
    tourTitle2: "Oguta Blue Lake",
  },

  {
    cityID: "abuja",
    cityName: "Abuja",
    touristAttraction:
      "Places to visit in Abuja includes Jabi Boat Club, Jabi Lake Mall, Dunes, Thought Pyramid Art Centre. etc.",
    cityText:
      "Nnamdi Azikiwe International Airport is located in Abuja, Federal Capital Territory, Nigeria, and is the main airport serving the Nigerian capital city. Abuja (Centre of Unity) is the capital city of Nigeria, in the middle of the country. The skyline of the city, which was built largely in the 1980s, is dominated by Aso Rock, an enormous monolith. It rises up behind the Presidential Complex, which houses the residence and offices of the Nigerian president in the Three Arms Zone on the eastern edge of the city.",
    cityPreviewImage: "dest-abuja",
    cityImage1: "dest-p-abuja",
    cityImage2: "dest-p-abuja-1",
    cityImage3: "dest-p-abuja-2",
    tourTitle1: "Nnamdi Azikiwe International Airport",
    tourTitle2: "Zuma Rock",
  },

  {
    cityID: "kano",
    cityName: "Kano",
    touristAttraction:
      "Places to visit in Kano include places like Kurmi Market, Dala Hills, Gidan Makama Museum, Kano Zoo etc.",
    cityText:
      "The Airport in Kano is called Mallam Aminu Kano International Airport Kano State (Centre of Commerce) is one of the 36 states of Nigeria, located in the northern region of the country. According to the national census done in 2006, Kano State is the most populous in Nigeria.",
    cityPreviewImage: "dest-kano",
    cityImage1: "dest-p-kano",
    cityImage2: "dest-p-kano-1",
    cityImage3: "dest-p-kano-2",
    tourTitle1: "Mallam Aminu Kano International Airport",
    tourTitle2: "Visit The Emir",
  },

  {
    cityID: "porthacourt",
    cityName: "Port Harcourt",
    touristAttraction:
      "Tourist attraction for Rivers includes the following: Port Harcourt Pleasure Park, Finima Beach/Nature Park, Ikuru Atlantic beach, Port Harcourt Zoo etc",
    cityText:
      "Port Harcourt International Airport (PHC) is located in Omagwa, Port Harcourt. Rivers State (Treasure Base of the Nation) is a state in the Niger Delta region of southern Nigeria. Formed in 1967.",
    cityPreviewImage: "dest-portharcourt",
    cityImage1: "dest-p-ph",
    cityImage2: "dest-p-ph-1",
    cityImage3: "dest-p-ph-2",
    tourTitle1: "Portharcourt Airport",
    tourTitle2: "Portharcourt Pleasure Park",
  },

  {
    cityID: "enugu",
    cityName: "Enugu",
    touristAttraction:
      "Places to visit in Enugu include Obudu Mountain Resort, etc.",
    cityText:
      "Akanu Ibiam International Airport, also known as Enugu Airport, is an airport serving Enugu, the capital city of Enugu State. Enugu State (Coal City State) is located in the South-East geopolitical zone of Nigeria, bordered to the north by the states of Benue and Kogi, Ebonyi State to the east and southeast, Abia State to the south, and Anambra State to the west. Enugu became a major coal mining area and the only significant one in West Africa. The Eastern Line railway connecting Enugu with Port Harcourt was completed in 1916 in order to export the coal through its seaport of which the city was created for this purpose.",
    cityPreviewImage: "dest-enugu",
    cityImage1: "dest-p-enugu",
    cityImage2: "dest-p-enugu-1",
    cityImage3: "dest-p-enugu-2",
    tourTitle1: "Akanu Ibiam International Airport",
    tourTitle2: "Explore Coal City",
  },

  {
    cityID: "jos",
    cityName: "Jos",
    touristAttraction:
      "Located in Plateau, Nigeria, Jos is home to an impressive selection of attractions and experiences, making it well worth a visit",
    cityText:
      "Yakubu Gowon Airport, also known as Jos Airport, is an airport serving Jos, the capital of the Plateau State of Nigeria. The Airport was named after the former Head of State, General Yakubu Gowon as a mark of honour. The Airport is located at Heipang in Barkin Ladi Local Government Area of Plateau State. Plateau State (Home of Peace and Tourism) is the twelfth-largest state in Nigeria. Approximately in the centre of the country, it is geographically unique due to its boundaries of elevated hills surrounding the Jos Plateau, its capital, and the entire plateau itself. Plateau State is described as 'The Home of Peace and Tourism' ",
    cityPreviewImage: "dest-jos",
    cityImage1: "dest-p-jos",
    cityImage2: "dest-p-jos-1",
    cityImage3: "dest-p-jos-2",
    tourTitle1: "Yakubu Gowon Airport",
    tourTitle2: "Explore Jos City",
  },

  {
    cityID: "makurdi",
    cityName: "Makurdi",
    touristAttraction:
      "Ikyogen HillsIkyogen Hill, Enumabia Warm Spring, Katsina-Ala River, Anwase-Abande Ranges, Montane Games Reserve, Dajo Pottery, Makurdi etc.",
    cityText:
      "Makurdi Airport commenced operation in 1983. The Airport is located at Fili along Makurdi Gboko road.",
    cityPreviewImage: "dest-makurdi",
    cityImage1: "dest-p-makurdi",
    cityImage2: "dest-p-makurdi-1",
    cityImage3: "dest-p-makurdi-2",
    tourTitle1: "Makurdi Airport",
    tourTitle2: "Explore Makurdi City",
  },

  // {
  //   cityID: "adamawa",
  //   cityName: "Adamawa",
  //   touristAttraction:
  //     "Mandara Mountains, Sukur Kingdom, Kiri Dam, Koma Hills, Ruwan Zafi etc. ",
  //   cityText:
  //     "Yola Airport is an airport serving Yola, which is the capital of Adamawa State of Nigeria. Adamawa State (Land of Beauty) is located in the North-East geopolitical zone of Nigeria, bordered by Borno to the northwest, Gombe to the west, and Taraba to the southwest while its eastern border forms part of the national border with Cameroon. ",
  //   cityPreviewImage: "destination-adamawa",
  //   cityImage1: "enugu-page1",
  //   cityImage2: "enugu-page2",
  //   cityImage3: "enugu-page3",
  //   tourTitle1: "Akanu Ibam Airport",
  //   tourTitle2: "Explore Coal City",
  // },

  // {
  //   cityID: "ibadan",
  //   cityName: "Ibadan",
  //   touristAttraction:
  //     "Oyo state is known to have nice places to visit. They include: Agodi park, idere hills, etc ",
  //   cityText:
  //     "Oyo State Airport is located in Ibadan. Oyo State (Pace Setter State) is an inland state in southwestern Nigeria. Its capital is Ibadan, the third most populous city in the country and formerly the second most populous city in Africa. Oyo State is bordered to the north by Kwara State, to the east by Osun State, and to the southwest by Ogun State and the Republic of Benin. Oyo State is noted for being the site of the first university in Nigeria known as University of Ibadan, founded in 1948. The state economy remains largely agrarian, with the western city of Shaki being described as the state's breadbasket.",
  //   cityPreviewImage: "destination-oyo",
  //   cityImage1: "enugu-page1",
  //   cityImage2: "enugu-page2",
  //   cityImage3: "enugu-page3",
  //   tourTitle1: "Akanu Ibam Airport",
  //   tourTitle2: "Explore Coal City",
  // },

  // {
  //   cityID: "asaba",
  //   cityName: "Asaba",
  //   touristAttraction:
  //     "Park vega waterpark, shiloh beach resort and apartments, Nelson Mandela Gardens, Mungo park house. etc.",
  //   cityText:
  //     "Asaba Airport is an airport serving Asaba, the capital of Delta State, Nigeria. Delta State (The Big Heart) is a state in the South-South geopolitical zone of Nigeria. Named after the Niger Delta—a large part of which is in the state—the state was formed from the former Bendel State in August 27, 1991.",
  //   cityPreviewImage: "destination-delta",
  //   cityImage1: "asaba-page1",
  //   cityImage2: "asaba-page2",
  //   cityImage3: "asaba-page3",
  //   tourTitle1: "Asaba International Airport",
  //   tourTitle2: "Stephen Okechukwu Keshi Stadium ",
  // },

  // {
  //   cityID: "bauchi",
  //   cityName: "Bauchi",
  //   touristAttraction:
  //     "The Emir's Palace, Tafawa Balewa Tomb, Unity Park, Shehu Azare Park, The Abubakar Tafawa Balewa Stadium, Yankari National Park and Game Reserve",
  //   cityText:
  //     "Bauchi State International Airport is a new airport serving the city of Bauchi, the capital of Bauchi State, Nigeria. Bauchi State (Pearl of Tourism) is a state in the North-East geopolitical zone of Nigeria, bordered by Kano and Jigawa to the north, Taraba and Plateau to the south, Gombe and Yobe to the east, and Kaduna to the west. It takes its name from the historic town of Bauchi, which also serves as its capital city ",
  //   cityPreviewImage: "destination-bauchi",
  //   cityImage1: "bauchi-page1",
  //   cityImage2: "bauchi-page2",
  //   cityImage3: "bauchi-page3",
  //   tourTitle1: "Sir Abubakar Tafawa Balewa Airport",
  //   tourTitle2: "Yankari National Park and Game Reserve",
  // },

  {
    cityID: "kaduna",
    cityName: "Kaduna",
    touristAttraction: "Kajuru Castle, Barcode Lounge, Kamuku National Park.",
    cityText:
      "Kaduna International Airport is located in Kaduna State. Kaduna State (Centre of Learning) is a state in northern Nigeria. The state capital is its namesake, Kaduna which happens to be the 8th largest city in the country as at 2006..",
    cityPreviewImage: "dest-kaduna",
    cityImage1: "dest-p-kaduna",
    cityImage2: "dest-p-kaduna-1",
    cityImage3: "dest-p-kaduna-2",
    tourTitle1: "Kaduna International Airport",
    tourTitle2: "Yankari National Park and Game Reserve",
  },

  {
    cityID: "benin",
    cityName: "Benin",
    touristAttraction:
      "Benin Airport is an airport serving Benin City, the capital of Edo State in Nigeria.",
    cityText:
      "This is one of the oldest airports in the country. The Airport was constructed in 1956 by the former Public Works Department (PWD) and upgraded in between 1975 and 1978 before it was finally handed over to the Nigerian Airports Authority (NAA).Benin Airport is located at Ogba – Oko community about 2km southwest of the ancient Benin Kingdom. The nearest community to the airport is Ekenwa aerodrome.",
    cityPreviewImage: "dest-benin",
    cityImage1: "dest-p-benini",
    cityImage2: "dest-p-benin-1",
    cityImage3: "dest-p-benin-2",
    tourTitle1: "Benin Airport",
    tourTitle2: "Explore Benin City",
  },
  {
    cityID: "calabar",
    cityName: "Calabar",
    touristAttraction:
      "Places to visit Agbokim Waterfalls, Kwa Falls, Slave History Museum, Obudu Mountain Resort, Tinapa Resort etc",
    cityText:
      "Cross River State has only one Airport called “Margaret Ekpo International Airport” and it is located in Calabar. Cross River State is known for holding the largest carnival in Africa. Various annual festivals are Cross River State Carnival usually between 26th -29th December in Calabar, Yakurr Leboku Yam festival (28 August) in Ugep and the Calabar Boat Regatta. Cross River State is the leading tourism state in Nigeria.",
    cityPreviewImage: "dest-calabar",
    cityImage1: "dest-p-calabar",
    cityImage2: "dest-p-calabar-1",
    cityImage3: "dest-p-calabar-2",
    tourTitle1: "Margaret Ekpo International Airport",
    tourTitle2: "Explore Calabar City",
  },

  {
    cityID: "gombe",
    cityName: "Gombe",
    touristAttraction:
      "Buba Yero Tomb, The Tomb of Sultan Attahiru, Bima Hill, Kilang Hill, The Tula Plateau, Kanawa Plantations, The Kalam Hill, etc.",
    cityText:
      "Gombe Lawanti International Airport is also known as Sani Abacha International Airport. It is located at . Currently, the international airport serves Gombe State, a northeastern state in Nigeria. Gombe Lawanti International Airport got its name because of its nearness to Lawanti, a small village in Gombe State, Nigeria.",
    cityPreviewImage: "dest-gombe",
    cityImage1: "dest-p-gombe",
    cityImage2: "dest-p-gombe-1",
    cityImage3: "dest-p-gombe-2",
    tourTitle1: "Gombe Lawanti International Airport",
    tourTitle2: "Explore Gombe City",
  },

  // {
  //   cityID: "maiduguri",
  //   cityName: "Maiduguri",
  //   touristAttraction:
  //     "Buba Yero Tomb, The Tomb of Sultan Attahiru, Bima Hill, Kilang Hill, The Tula Plateau, Kanawa Plantations, The Kalam Hill, etc. ",
  //   cityText:
  //     "Sani Abacha International Airport is an airport serving Gombe, the capital of Gombe State of Nigeria. It was recently built along the Bauchi to Gombe road by the village of Lawanti. Gombe State (Jewel of the Savannah) is a state in northeastern Nigeria, bordered to the north and northeast by the state of Borno and Yobe, to the south by Taraba State, to the southeast by Adamawa State, and to the west by Bauchi State. It is an agrarian state located within the expansive savannah, endowed with over 13 different types of minerals harnessed for various uses ",
  //   cityPreviewImage: "dest-maidugri",
  //   cityImage1: "gombe-page1",
  //   cityImage2: "gombe-page2",
  //   cityImage3: "gombe-page3",
  //   tourTitle1: "Placeholder",
  //   tourTitle2: "Placeholder",
  // },

  {
    cityID: "yola",
    cityName: "Yola",
    touristAttraction:
      "Buba Yero Tomb, The Tomb of Sultan Attahiru, Bima Hill, Kilang Hill, The Tula Plateau, Kanawa Plantations, The Kalam Hill, etc.",
    cityText:
      "The Airport was commissioned on the 27th October, 1984. There was skeletal, operation since 1978 until it was upgraded to an International Airport in 2002. The Airport is located at Jimeta, about 8km Northwest of Yola.The nearest towns are Kofere, Dunwire and Burloure villages.",
    cityPreviewImage: "dest-yola",
    cityImage1: "dest-p-yola",
    cityImage2: "dest-p-yola-1",
    cityImage3: "dest-p-yola-2",
    tourTitle1: "Yola Airport",
    tourTitle2: "Explore Yola City",
  },
];

export function destinations() {
  return DESTINATIONS;
}

export function clickedDestination(cityId) {
  return DESTINATIONS.find((city) => city.cityID === cityId);
}
