import { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";

const AllContext = createContext();
export function AllContextProvider(props) {
  //States
  const [flightArray, setFlightArray] = useState([]);
  const [searchBookingResponse, setSearchBookingResponse] = useState("");
  const [roundTrip, setRoundTrip] = useState(false);
  const [tripType, setTripType] = useState("single");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [displayedFrom, setDisplayedFrom] = useState("");
  const [displayedTo, setDisplayedTo] = useState("");
  const [departureDate, setDepartureDate] = useState("");
  const [returnDate, setReturnDate] = useState("");
  const [adult, setAdult] = useState(1);
  const [children, setChildren] = useState(0);
  const [infant, setInfant] = useState(0);
  const [chosenFlight, setChosenFlight] = useState([]);
  const [chosenFlightID, setChosenFlightID] = useState();
  const [chosenClass, setChosenClass] = useState([]);
  const [chosenClassName, setChosenClassName] = useState("");
  const [chosenClassTax, setChosenClassTax] = useState("");
  const [chosenClassFees, setChosenClassFees] = useState("");
  const [chosenClassSurge, setChosenClassSurge] = useState("");
  const [chosenClassPrice, setChosenClassPrice] = useState("");
  const [classChosen, setClassChosen] = useState(false);
  const [monitor, setMonitor] = useState(false);
  const [searchResultResponse, setSearchResultResponse] = useState("");
  const [adultArray, setAdultArray] = useState(new Array(adult));
  const [childrenArray, setChildrenArray] = useState(new Array(children));
  const [infantArray, setInfantArray] = useState(new Array(infant));
  const [allPassengers, setAllPassengers] = useState([
    { ...adultArray, ...childrenArray, ...infantArray },
  ]);
  const [prev, setPrev] = useState(0);
  const [flightHistoryPrev, setFlightHistoryPrev] = useState(0);
  const [flightHistoryNext, setFlightHistoryNext] = useState(5);
  const [notificationsPrev, setNotificationsPrev] = useState(0);
  const [notificationsNext, setNotificationsNext] = useState(5);
  const [next, setNext] = useState(5);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState({});
  const [userToken, setUserToken] = useState();
  const [showNotification, setShowNotification] = useState(false);
  const [signingOut, setSigningOut] = useState(false);
  const [notificationText, setNotificationText] = useState("");
  const [flightRefID, setFlightRefID] = useState(null);
  const [tab1Selected, setTab1Selected] = useState(true);
  const [tab2Selected, setTab2Selected] = useState(false);
  const [tab3Selected, setTab3Selected] = useState(false);
  const [tab4Selected, setTab4Selected] = useState(false);
  const [totalPrice, setTotalPrice] = useState("");
  const [payStackTotalPrice, setPayStackTotalPrice] = useState("");
  const [checkInPassenger, setCheckInPassenger] = useState({});
  const [flightSeatArray, setFlightSeatArray] = useState([]);
  const [flightStatus, setFlightStatus] = useState("null");
  const [individualRefID, setIndividualRefID] = useState(null);
  const [passengerFlight, setPassengerFlight] = useState({});

  const [paymentDetails, setPaymentDetails] = useState({});

  // const baseUrl = "http://dev.desunair.com";

  const baseUrl = "https://desunair.com"; 

  // const baseUrl = "https://prod.desunair.com";
  //const baseUrl = 'https://desunair.com'

  //Context object
  const context = {
    baseUrl, payStackTotalPrice,
    paymentDetails,
    flightStatus,
    setTheFlightStatus,
    flightSeatArray,
    totalPrice,
    setTheTotalPrice,
    chosenClassTax,
    chosenClassFees,
    chosenClassSurge,
    tab1Selected,
    tab2Selected,
    tab3Selected,
    tab4Selected,
    setTheFlightHistoryPrev,
    setTheFlightHistoryNext,
    setTheNotificationsPrev,
    setTheNotificationsNext,
    flightHistoryNext,
    flightHistoryPrev,
    notificationsNext,
    notificationsPrev,
    setTheFlightRefID,
    flightRefID,
    showNotification,
    setTheShowNotification,
    setTheNotificationText,
    notificationText,
    userToken,
    setTheUserToken,
    user,
    setTheUser,
    isLoggedIn,
    setTheIsLoggedIn,
    prev,
    next,
    monitor,
    allPassengers,
    adultArray,
    childrenArray,
    infantArray,
    searchResultResponse,
    classChosen,
    chosenClass,
    chosenFlightID,
    chosenClassName,
    chosenClassPrice,
    flightArray: flightArray,
    searchBookingResponse: searchBookingResponse,
    roundTrip,
    tripType,
    from,
    to,
    displayedFrom,
    displayedTo,
    departureDate,
    returnDate,
    adult,
    children,
    infant,
    chosenFlight,

    setTheChosenClassTax,
    setThePaymentDetails,
    setTheChosenClassFees,
    setTheChosenClassSurge,
    selectTheTab1,
    selectTheTab2,
    selectTheTab3,
    selectTheTab4,
    setTheClassChosen,
    setTheFlightArray: setTheFlightArray,
    setTheSearchBookingResponse: setTheSearchBookingResponse,
    setTheTripType,
    setTheRoundTrip,
    setTheAdult,
    setTheFrom,
    setTheTo,
    setTheChildren,
    setTheDepartureDate,
    setTheReturnDate,
    setTheDisplayedFrom,
    setTheDisplayedTo,
    setTheInfant,
    setTheChosenFlight,
    setTheChosenFlightID,
    setTheChosenClass,
    setTheChosenClassPrice,
    setTheChosenClassName,
    setTheChosenClassPrice,
    setTheSearchResultResponse,
    retrieveSavedChosenFlight,
    setTheAllPassengers,
    setTheAdultArray,
    toggleMonitor,
    setTheInfantArray,
    setTheChildrenArray,
    setThePrev,
    setTheNext,
    checkInPassenger,
    setTheCheckInPassenger,
    setTheFlightSeatArray,
    individualRefID,
    setTheIndividualRefID,
    setThePassengerFlight,
    passengerFlight, setThePayStackTotalPrice,
  };

  return (
    <AllContext.Provider value={context}>{props.children}</AllContext.Provider>
  );

  // Functions

  function setThePayStackTotalPrice(value) {
    setPayStackTotalPrice(value)
  }

  function setThePaymentDetails(value) {
    setPaymentDetails(value);
  }

  function setTheIndividualRefID(value) {
    setIndividualRefID(value);
  }

  function setTheFlightStatus(value) {
    setFlightStatus(value);
  }

  function setTheFlightSeatArray(value) {
    setFlightSeatArray(value);
  }

  function setTheCheckInPassenger(value) {
    setCheckInPassenger(value);
  }

  function setTheTotalPrice(value) {
    setTotalPrice(value);
  }

  function setTheChosenClassTax(value) {
    setChosenClassTax(value);
  }

  function setTheChosenClassFees(value) {
    setChosenClassFees(value);
  }

  function setTheChosenClassSurge(value) {
    setChosenClassSurge(value);
  }

  function setTheNotificationsNext(value) {
    setNotificationsNext(value);
  }

  function setTheNotificationsPrev(value) {
    setNotificationsPrev(value);
  }

  function setTheFlightHistoryNext(value) {
    setFlightHistoryNext(value);
  }

  function setTheFlightHistoryPrev(value) {
    setFlightHistoryPrev(value);
  }

  function setTheFlightRefID(value) {
    setFlightRefID(value);
  }

  function setTheShowNotification(value) {
    setShowNotification(value);
  }

  function setTheNotificationText(value) {
    setNotificationText(value);
  }

  function setTheUserToken(value) {
    setUserToken(value);
  }

  function setTheUser(value) {
    setUser(value);
  }

  function setTheIsLoggedIn(value) {
    setIsLoggedIn(value);
  }

  function toggleMonitor() {
    setMonitor(!monitor);
  }

  function setTheNext(value) {
    setNext(value);
  }

  function setThePrev(value) {
    setPrev(value);
  }

  function setTheSearchResultResponse(value) {
    setSearchResultResponse(value);
  }

  function setTheClassChosen(value) {
    setClassChosen(value);
  }

  function setTheAdultArray(value) {
    setAdultArray(value);
  }

  function setTheChildrenArray(value) {
    setChildrenArray(value);
  }

  function setTheInfantArray(value) {
    setInfantArray(value);
  }

  function setTheChosenClassPrice(value) {
    setChosenClassPrice(value);
  }

  function setTheChosenClassName(value) {
    setChosenClassName(value);
  }

  function setTheAllPassengers(value1, value2, value3) {
    setAllPassengers([...value1, ...value2, ...value3]);
  }

  function setTheChosenFlightID(value) {
    setChosenFlightID(value);
  }

  function setTheChosenClass(className) {
    setChosenClass(
      chosenFlight.map((flightList) =>
        flightList.class.find((flightClass) => flightClass.name === className)
      )
    );
  }

  function setTheRoundTrip(value) {
    setRoundTrip(value);
  }

  function setTheTripType(value) {
    setTripType(value);
  }

  function setTheFrom(value) {
    setFrom(value);
  }

  function setTheTo(value) {
    setTo(value);
  }

  function setTheDisplayedFrom(value) {
    setDisplayedFrom(value);
  }

  function setTheDisplayedTo(value) {
    setDisplayedTo(value);
  }

  function setTheDepartureDate(value) {
    setDepartureDate(value);
  }

  function setTheReturnDate(value) {
    setReturnDate(value);
  }

  function setTheAdult(value) {
    setAdult(value);
  }

  function setTheChildren(value) {
    setChildren(value);
  }

  function setTheInfant(value) {
    setInfant(value);
  }

  function setTheFlightArray(value) {
    setFlightArray(value);
  }

  function setTheSearchBookingResponse(value) {
    setSearchBookingResponse(value);
  }

  function setTheChosenFlight(flightID) {
    setChosenFlight(
      flightArray.map((flightList) =>
        flightList.find((flight) => flight.ID === flightID)
      )
    );
  }

  function setThePassengerFlight(value) {
    setPassengerFlight(value);
  }

  function retrieveSavedChosenFlight(value) {
    setChosenFlight(value);
  }

  function selectTheTab1() {
    setTab1Selected(true);
    setTab2Selected(false);
    setTab3Selected(false);
    setTab4Selected(false);
  }

  function selectTheTab2() {
    setTab1Selected(false);
    setTab2Selected(true);
    setTab3Selected(false);
    setTab4Selected(false);
  }

  function selectTheTab3() {
    setTab1Selected(false);
    setTab2Selected(false);
    setTab3Selected(true);
    setTab4Selected(false);
  }

  function selectTheTab4() {
    setTab1Selected(false);
    setTab2Selected(false);
    setTab3Selected(false);
    setTab4Selected(true);
  }
}

export default AllContext;
