import React, { useEffect, useState } from "react";
import AllCtx from "../../uti-functions/allCtx";

function ChildrenDetails() {
  const {
    // monitor,
    adult,
    children,
    infant,
    allPassengers,

    tripType,
    flightArray,
    setTheFlightArray,
    chosenFlight,
    chosenFlightID,
    setTheChosenClass,
    setTheChosenClassPrice,
    chosenClass,
    chosenClassName,
    chosenClassPrice,
    setTheChosenFlight,
    setTheChosenFlightID,
    searchBookingResponse,
    setTheSearchBookingResponse,
    setTheChosenClassName,
    setTheClassChosen,
    classChosen,
    setTheSearchResultResponse,
    searchResultResponse,
    setTheAllPassengers,
    setTheChildrenArray,
    adultArray,
    childrenArray,
    setTheAdultArray
  } = AllCtx();

  useEffect(() => {
    if (localStorage.getItem("childrenArray")) {
      setTheChildrenArray(JSON.parse(localStorage.getItem("childrenArray")));
  }
 }, [])

  const [childrenTitle, setChildrenTitle] = useState("");
  const [childrenGender, setChildrenGender] = useState("");
  const [childrenFirstName, setChildrenFirstName] = useState("");
  const [childrenLastName, setChildrenLastName] = useState("");
  const [childrenDOB, setChildrenDOB] = useState("");

  const [monitor, setMonitor] = useState(true);




  return (
    <div>
      {childrenArray.map((child) => (
        <div key={child.id} className="mt-10">
          <div>
            <p className="text-pry-color mb-2 font-semibold">
              {
                 `${child.id} Details`}
            </p>
          </div>

          <div className=" border-2 rounded-md py-4 px-2 sm:px-5 md:px-2">
            <div className="mb-2">
              <p className="font-semibold text-sm">Passenger Info</p>
            </div>
      
            <div className="flex">
              <div className="w-20">
                <p>NAME: </p>
              </div>{" "}
              <div>
                <p><span>{child.title.toUpperCase()}</span> <span>{child.first_name.toUpperCase()}</span> <span>{ child.last_name.toUpperCase()}</span></p>
              </div>
            </div>

            <div className="flex">
              <div className="w-20">
                <p>GENDER: </p>
              </div>{" "}
              <div>
                <p>{child.gender.toUpperCase()} </p>
              </div>
            </div>

       
           
        
          </div>
        </div>
      ))}
    </div>
  );
}

export default ChildrenDetails;
