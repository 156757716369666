import React from "react";
import PPaymentDetails from "../components/payment/PPaymentDetails";

function PaymentPage() {
  return (
    <div className="">
      <div className=" mb-10  services-header-bg text-2xl md:text-3xl lg:text-4xl">
        <p>Payment</p>
      </div>

      <PPaymentDetails />
    </div>
  );
}

export default PaymentPage;
