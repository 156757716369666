const SERVICES = [
    {
        id: 's1',
        title: 'Training',
        linkToPage: '/services/training',
        image: 'service-training'
    },

    {
        id: 's2',
        title: 'Private Charter',
        linkToPage: '/services/private-hire',
        image: 'service-private-hire'
    },
    {
        id: 's3',
        title: 'Repairs',
        linkToPage: '/services/repairs',
        image: 'service-repairs'
    },
    {
        id: 's4',
        title: 'Cargo',
        linkToPage: '/services/cargo',
        image: 'service-cargo'
    },

    {
        id: 's5',
        title: 'Purchase',
        linkToPage: '/services/purchase-parts',
        image: 'service-purchase-part'
    }
];

export default function services() {
    return SERVICES
}