import React from "react";
import Logo from "../images/logo.webp";
import FacebookIcon from "../images/facebook-icon.png";
import TwitterIcon from "../images/twitter-icon.png";
import InstagramIcon from "../images/instagram-icon.png";
import EmailIcon from "../images/email-icon.png";
import LocationIcon from "../images/location-icon.png";
import PhoneIcon from "../images/phone-icon.png";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

function Footer() {
  return (
    <div className="md:flex bg-[#F0F1F7] px-8 md:space-x-4 sm:px-12 md:px-16 lg:px-20 py-5 mt-48">
      <div className="w-[50%] md:w-[30%]">
        <div>
          <img src={Logo} alt="" />
        </div>
        <div>
          <p className="font-bold text-pry-color mt-4">
            <span className="text-sec-color">Desun</span> Airlines provides
            safe, affordable and efficient aviation services .
          </p>
        </div>
        <div className="flex space-x-3 mt-3">
          <a href="https://facebook.com/desnair">
            <img src={FacebookIcon} alt="" />
          </a>
          <a href="https://twitter.com/desunair">
            <img src={TwitterIcon} alt="" />
          </a>
          <a href="https://instagram.com/desunair">
            <img src={InstagramIcon} alt="" />
          </a>
        </div>
        <p className="text-pry-color text-sm mt-6 my-3">
          Copyright © 2022. Desun Airlines. All rights reserved.
        </p>
        <Link className="md:hidden text-pry-color" to="/privacy-policy">
          <p className="">Privacy Policy</p>
        </Link>
      </div>

      <div className="text-pry-color md:text-xs lg:text-base space-y-3 hidden md:block w-[10%]">
        <p className="text-sec-color font-bold">Services</p>
        <Link className="no-underline text-pry-color" to="/services/repairs">
          <p className="pt-3 hover:text-sec-color  font-semibold">Repairs</p>
        </Link>
        <Link className="no-underline text-pry-color" to="/services/private-hire">
          <p className="pt-3 hover:text-sec-color  font-semibold">Quick Hire</p>
        </Link>
        <Link className="no-underline text-pry-color" to="/services/purchase-parts">
          <p className="pt-3 hover:text-sec-color  font-semibold">Purchase</p>
        </Link>
        <Link className="no-underline text-pry-color" to="/services/training">
          <p className="pt-3 hover:text-sec-color  font-semibold">Training</p>
        </Link>
        <Link className="no-underline text-pry-color" to="/services/cargo">
          <p className="pt-3 hover:text-sec-color  font-semibold">Cargo</p>
        </Link>
      </div>

      <div className="text-pry-color md:text-xs lg:text-base space-y-3 hidden md:block w-[10%]">
        <p className="text-sec-color font-bold">Booking</p>
        <HashLink className="no-underline text-pry-color" to='/#tab'>
        <p className="pt-3 hover:text-sec-color  font-semibold">Manage Booking</p>
       </HashLink>
       <HashLink className="no-underline text-pry-color" to='/#tab'>
        <p className="pt-3 hover:text-sec-color  font-semibold">Search Flight</p>
       </HashLink>
        {/* <p>Flight Schedule</p> */}
      </div>

      <div className="text-pry-color md:text-xs lg:text-base space-y-3 hidden md:block w-[10%]">
        <p className="text-sec-color font-bold">Fly</p>
        <HashLink className="no-underline text-pry-color" to='/#tab'>
        <p className="pt-3 hover:text-sec-color  font-semibold">Check-in</p>
       </HashLink>
       <HashLink className="no-underline text-pry-color" to='/#tab'>
        <p className="pt-3 hover:text-sec-color  font-semibold">Flight Status</p>
       </HashLink>
      </div>

      <div className="text-pry-color md:text-xs lg:text-base space-y-3 hidden md:block w-[10%]">
        <p className="text-sec-color font-bold">About</p>
        <HashLink className="no-underline text-pry-color" to='/about'>
        <p className="pt-3 hover:text-sec-color  font-semibold">About Us</p>
       </HashLink>
      
        <HashLink className="no-underline text-pry-color" to='/faq'>
        <p className="pt-3 hover:text-sec-color  font-semibold">FAQ</p>
       </HashLink>
       
       <HashLink className="no-underline text-pry-color" to='/#destination'>
        <p className="pt-3 hover:text-sec-color  font-semibold">Destinations</p>
       </HashLink>

          <HashLink className="no-underline text-pry-color" to='/privacy-policy'>
        <p className="pt-3 hover:text-sec-color  font-semibold">Privacy Policy</p>
       </HashLink>

      </div>

      <div className="text-pry-color md:text-xs lg:text-base space-y-3 hidden md:block w-[30%]">
        <p className="text-sec-color font-bold">Contact</p>
        <div className="flex items-center space-x-3">
          <div>
            <img src={EmailIcon} alt="" />
          </div>
          <a href="mailto:info@desunair.com">info@desunair.com </a>
        </div>
        <div className="flex items-center space-x-3">
          <div>
            <img src={LocationIcon} alt="" />
          </div>
          <p>62A, Abidjan Street, Zone 3, Wuse, Abuja </p>
        </div>
        <div className="flex items-center space-x-3">
          <div>
            <img src={PhoneIcon} alt="" />
          </div>
          <p>+234 (0) 80 374 15154 </p>
        </div>
        <div className="flex mt-1 items-center space-x-3">
          <div>
            <img src={EmailIcon} alt="" />
          </div>
          <HashLink className="no-underline text-pry-color" to='/contact'>
        <p className=" hover:text-sec-color  font-semibold">Contact</p>
       </HashLink>
        </div>
   
      </div>
    </div>
  );
}

export default Footer;
