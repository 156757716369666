import React from "react";
import Repairs from "../images/service-repairs.webp";
import Appointment from "../images/join-us-icon.png";
import { HashLink } from "react-router-hash-link";

function RepairsPage() {
  return (
    <div className="">
      <div className="services-header-bg text-2xl md:text-3xl lg:text-4xl">
        {" "}
        <p>Repairs</p>{" "}
      </div>

      <div className="md:flex  px-8 md:space-x-7 pt-5 md:items-center">
        <div className="md:w-1/2 ">
          <img src={Repairs} alt="" />
        </div>
        <div className="md:w-1/2">
          <h2 className="text-pry-color font-bold  mb-4 text-3xl">
            <span className="text-sec-color">Desun</span> Repairs
          </h2>
          <p className="text-justify text-pry-color text-xl md:leading-10 leading-relaxed">
            Desun Airlines Limited shall acquire, constructs, maintains, repairs
            and generally keep in good condition workshops, gliders, airports,
            aerodromes, airfields, landing grounds and terminal buildings.
          </p>

          <HashLink
            to="/#services"
            className="no-underline text-black flex items-center space-x-3 my-8 text-sm "
          >
            <p className="cursor-pointer font-semibold hover:text-sec-color">
              Back to Services
            </p>{" "}
            <img className="h-6 w-6 cursor-pointer" src={Appointment} alt="" />
          </HashLink>
        </div>
      </div>
    </div>
  );
}

export default RepairsPage;
