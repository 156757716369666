import React from 'react'

function NotFound() {
  return (
      <div className="px-5 md:px-10 lg:px-16 text-pry-color text-[30px] text-center my-20">
          404! Page not found.
    </div>
  )
}

export default NotFound