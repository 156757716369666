import React, { useEffect, useState } from "react";
import { useRef } from "react";
// import "react-phone-number-input/style.css";
// import PhoneInput from "react-phone-number-input";
import countryCodeFunction from "../../store/country-codes";

import AllCtx from "../../uti-functions/allCtx";

function AdultDetails() {
  const countryCodes = countryCodeFunction();

  // const countryCode

  const {
    adult,
    allPassengers,
    setTheAllPassengers,
    setTheAdultArray,
    adultArray,
  } = AllCtx();

  var { childrenArray, infantArray } = AllCtx();
  const [adultTitle, setAdultTitle] = useState("");
  const [adultGender, setAdultGender] = useState("");
  const [adultFirstName, setAdultFirstName] = useState("");
  const [adultLastName, setAdultLastName] = useState("");
  const [adultPhone, setAdultPhone] = useState("");
  const [adultEmail, setAdultEmail] = useState("");
  const [adultConfirmEmail, setAdultConfirmEmail] = useState("");

  const [monitor, setMonitor] = useState(true);

  const [countryCode, setCountryCode] = useState("+234");

  const [localPhone, setLocalPhone] = useState("");

  // const adultDefaultTitle = "Mr"

  useEffect(() => {
    for (var i = 0; i < adult; i++) {
      if (monitor) {
        // Using monitor to make this run only first time the page is loading
        adultArray[i] = {
          type: "adult",
          title: "Mr",
          first_name: adultFirstName,
          last_name: adultLastName,
          gender: adultGender,
          c_phone: adultPhone,
          c_email: adultEmail,
          c_verify_email: adultConfirmEmail,
          id: "Adult " + (i + 1),
        };
      }

      localStorage.setItem("adultArray", JSON.stringify(adultArray));

      setMonitor(false);
    }
  }, [monitor]);

  function handleAdultTitleChange(adultID, titlePicked) {
    let newAdultArray = [...adultArray];
    let adultInputToChange = newAdultArray.find(
      (adult) => adult.id === adultID
    );

    adultInputToChange.title = titlePicked;

    setTheAdultArray(newAdultArray);
    setAdultTitle(adultInputToChange.title);

    return adultInputToChange.title;
  }

  function handleAdultGenderChange(adultID, genderPicked) {
    let newAdultArray = [...adultArray];
    let adultInputToChange = newAdultArray.find(
      (adult) => adult.id === adultID
    );
    adultInputToChange.gender = genderPicked;
    setTheAdultArray(newAdultArray);
    setAdultGender(adultInputToChange.gender);
    return adultInputToChange.gender;
  }

  function handleAdultFirstNameChange(adultID, firstNameTyped) {
    let newAdultArray = [...adultArray];
    let adultInputToChange = newAdultArray.find(
      (adult) => adult.id === adultID
    );
    adultInputToChange.first_name = firstNameTyped;
    setTheAdultArray(newAdultArray);
    setAdultFirstName(adultInputToChange.first_name);

    return adultInputToChange.first_name;
  }

  function handleAdultLastNameChange(adultID, lastNameTyped) {
    let newAdultArray = [...adultArray];
    let adultInputToChange = newAdultArray.find(
      (adult) => adult.id === adultID
    );
    adultInputToChange.last_name = lastNameTyped;
    setTheAdultArray(newAdultArray);
    setAdultLastName(adultInputToChange.last_name);
    return adultInputToChange.last_name;
  }

  function handleAdultPhoneChange(adultID, adultPhoneTyped) {
    let newAdultArray = [...adultArray];
    let adultInputToChange = newAdultArray.find(
      (adult) => adult.id === adultID
    );
    adultInputToChange.c_phone = adultPhoneTyped;
    setTheAdultArray(newAdultArray);
    setAdultPhone(adultInputToChange.c_phone);
    return adultInputToChange.c_phone;
  }

  function handleAdultEmailChange(adultID, adultEmailTyped) {
    let newAdultArray = [...adultArray];
    let adultInputToChange = newAdultArray.find(
      (adult) => adult.id === adultID
    );
    adultInputToChange.c_email = adultEmailTyped;
    setTheAdultArray(newAdultArray);
    setAdultEmail(adultInputToChange.c_email.replace(/\s+/g, ""));
    return adultInputToChange.c_email.replace(/\s+/g, "");
  }

  function handleAdultConfirmEmailChange(adultID, adultConfirmEmailTyped) {
    let newAdultArray = [...adultArray];
    let adultInputToChange = newAdultArray.find(
      (adult) => adult.id === adultID
    );
    adultInputToChange.c_verify_email = adultConfirmEmailTyped;
    setTheAdultArray(newAdultArray);
    setAdultConfirmEmail(adultInputToChange.c_verify_email.replace(/\s+/g, ""));
    return adultInputToChange.c_verify_email.replace(/\s+/g, "");
  }

  return (
    <div>
      {adultArray?.map((Adult) => (
        <div key={Adult.id} className="mt-4">
          <div>
            <p className="text-pry-color mb-2 font-semibold">
              {Adult.id === "Adult 1"
                ? `${Adult.id} Details (Principal Adult)`
                : `${Adult.id} Details`}
            </p>
          </div>
          {/* { isLoggedIn && Adult.id === 'Adult 1' &&  <div className='mb-2'><p className='text-xs text-sec-color'>Since you are logged in, your registered details will be used as the principal adult.</p></div>} */}
          <div
            /* disabled={isLoggedIn && Adult.id === 'Adult 1'} */ className=" border-2 rounded-md py-4 px-2 sm:px-5 md:px-2"
          >
            <div className="mb-2">
              <p className="font-semibold text-sm">Passenger Info</p>
            </div>

            <div className="md:flex md:space-y-0 space-y-4 justify-between mb-3">
              {/* Title Select  */}
              <div className="md:w-15%">
                <div className="text-sm text-gray-500">Title</div>
                <select
                  required
                  className="text-xs w-full border-gray-300 rounded border-1"
                  name="title"
                  id="title"
                  // onMouseOver={(e) => {
                  //   handleAdultTitleChange(Adult.id, e.target.value);
                  //   setTheAllPassengers(adultArray, childrenArray, infantArray);
                  // }}
                  onClick={() => {
                    setTheAllPassengers(adultArray, childrenArray, infantArray);
                  }}
                  onChange={(e) => {
                    handleAdultTitleChange(Adult.id, e.target.value);
                    console.log(Adult.title);

                    console.log("adultArray", adultArray);
                    setTheAllPassengers(adultArray, childrenArray, infantArray);
                    console.log("allPa", allPassengers);
                  }}
                  // value={localStorage.getItem('user') && localStorage.getItem('userToken') && Adult.id === "Adult 1" ? user.email : "Adult.title"}
                  // defaultValue="Mr"
                >
                
                  <option value="Mr" >Mr</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Miss">Miss</option>
                  <option value="Hon">Hon</option>
                  <option value="Prof">Prof</option>
                  <option value="Engnr">Engnr</option>
                  <option value="Dr">Dr</option>
                  <option value="Chief">Chief</option>
                  <option value="King">King</option>
                  <option value="Emir">Emir</option>
                </select>
              </div>
              {/*  First Name   */}
              <div className="md:w-[26%]">
                <div className="text-sm text-gray-500">First Name</div>{" "}
                <input
                  required
                  placeholder="First Name"
                  // onMouseOver={(e) => {
                  //   handleAdultFirstNameChange(Adult.id, e.target.value);
                  //   setTheAllPassengers(adultArray, childrenArray, infantArray);
                  // }}
                  onClick={() => {
                    setTheAllPassengers(adultArray, childrenArray, infantArray);
                  }}
                  onChange={(e) => {
                    handleAdultFirstNameChange(Adult.id, e.target.value);

                    console.log("adultArray", adultArray);
                    setTheAllPassengers(adultArray, childrenArray, infantArray);
                    console.log("allPa", allPassengers);
                  }}
                  className="text-xs w-full border-gray-300 rounded border-1"
                  type="text"
                  name=""
                  id=""
                  // value={localStorage.getItem('user') && localStorage.getItem('userToken') && Adult.id === "Adult 1" ? user.email : "Adult.title"}
                  // value={Adult.first_name}
                />
              </div>

              {/*  Last Name   */}
              <div className="md:w-[26%] ">
                <div className="text-sm text-gray-500">Last Name</div>{" "}
                <input
                  required
                  placeholder="Surname"
                  //        onMouseOver={() => {
                  //   setTheAllPassengers(adultArray, childrenArray, infantArray);
                  // }}
                  onClick={() => {
                    setTheAllPassengers(adultArray, childrenArray, infantArray);
                  }}
                  onChange={(e) => {
                    handleAdultLastNameChange(Adult.id, e.target.value);

                    console.log("adultArray", adultArray);
                    setTheAllPassengers(adultArray, childrenArray, infantArray);
                    console.log("allPa", allPassengers);
                  }}
                  className="text-xs w-full border-gray-300 rounded border-1"
                  type="text"
                  name=""
                  id=""
                  // value={Adult.last_name}
                />
              </div>

              {/* Gender Select  */}

              <div className="md:w-[26%]">
                <div className="text-sm text-gray-500" htmlFor="">
                  Gender
                </div>
                <select
                  required
                  className="text-xs w-full border-gray-300 rounded border-1"
                  name="gender"
                  id="gender"
                  //        onMouseOver={() => {
                  //   setTheAllPassengers(adultArray, childrenArray, infantArray);
                  // }}
                  onClick={() => {
                    setTheAllPassengers(adultArray, childrenArray, infantArray);
                  }}
                  onChange={(e) => {
                    handleAdultGenderChange(Adult.id, e.target.value);
                    console.log(Adult.gender);

                    console.log("adultArray", adultArray);
                    setTheAllPassengers(adultArray, childrenArray, infantArray);
                    console.log("allPa", allPassengers);
                  }}
                  // value={Adult.gender}
                >
                  <option value="">Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Undisclosed">Undisclosed</option>
                </select>
              </div>
            </div>
            <div className={`${Adult.id !== "Adult 1" ? "hidden" : ""}`}>
              <div className="mb-2">
                <p className="font-semibold text-sm">Contact Details</p>
              </div>
              <div className="md:flex justify-between mb-3 md:space-y-0 space-y-4">
                {/* Phone  */}
                <div className="md:w-[100%]">
                  <div className="text-sm text-gray-500">Phone Number</div>
                  <div className="flex  ">
                    <select
                      onClick={() => {
                        setTheAllPassengers(
                          adultArray,
                          childrenArray,
                          infantArray
                        );
                      }}
                      onChange={(e) => {
                         setCountryCode(e.target.value)
                        handleAdultPhoneChange(
                          "Adult 1",
                          `${e.target.value}${localPhone}`
                        );

                       

                        console.log("adultArray", adultArray);
                        setTheAllPassengers(
                          adultArray,
                          childrenArray,
                          infantArray
                        );
                        console.log("allPa", allPassengers);
                      }}
                      name=""
                      id=""
                      className="!appearance-none text-xs w-[40%] border-gray-300 rounded border-1"
                    >
                      <option value="+234"> Nigeria (+234)</option>
                      {countryCodes.map((country) => (
                        <option key={country.name.common} value={country.callingCodes[0] || "+234"}>{` ${
                          country.name.common ? country.name.common : "(LNG)"
                        }  (${
                          country.callingCodes[0]
                            ? country.callingCodes[0]
                            : "+982"
                        })`}</option>
                      ))}
                    </select>{" "}
                    <input
                      //          onMouseOver={() => {
                      //   setTheAllPassengers(adultArray, childrenArray, infantArray);
                      // }}
                      onClick={() => {
                        setTheAllPassengers(
                          adultArray,
                          childrenArray,
                          infantArray
                        );
                      }}
                      onChange={(e) => {
                        setLocalPhone(e.target.value)
                        handleAdultPhoneChange(
                          Adult.id,
                          `${countryCode}${e.target.value}`
                        );
                        setLocalPhone(e.target.value);
                        console.log("adultArray", adultArray);
                        setTheAllPassengers(
                          adultArray,
                          childrenArray,
                          infantArray
                        );
                        console.log("allPa", allPassengers);
                      }}
                      className="text-xs w-[58%] border-gray-300 rounded border-1"
                      placeholder="E.g 7030114611"
                      type="number"
                      // value={Adult.c_phone}
                    />
                  </div>
                </div>
              </div>

              <div className="md:flex justify-between mb-3 md:space-y-0 space-y-4">
                {/* Email  */}
                <div className="md:w-[48%]">
                  <div className="text-sm text-gray-500">Email</div>
                  <input
                    required
                    //  value={Adult.c_email}
                    //          onMouseOver={() => {
                    //   setTheAllPassengers(adultArray, childrenArray, infantArray);
                    // }}
                    onClick={() => {
                      setTheAllPassengers(
                        adultArray,
                        childrenArray,
                        infantArray
                      );
                    }}
                    onChange={(e) => {
                      handleAdultEmailChange(Adult.id, e.target.value);

                      console.log("adultArray", adultArray);
                      setTheAllPassengers(
                        adultArray,
                        childrenArray,
                        infantArray
                      );
                      console.log("allPa", allPassengers);
                    }}
                    className="text-xs w-full outline-1 border-solid border-gray-300 rounded border-1"
                    placeholder="Enter Email"
                    type="text"
                    value={adultEmail}
                  />
                </div>{" "}
                {/* Confirm Email */}
                <div className="md:w-[48%]">
                  <div className="text-sm text-gray-500">Confirm Email</div>
                  <input
                    required
                    //  value={Adult.c_verify_email}
                    onMouseOver={() => {
                      setTheAllPassengers(
                        adultArray,
                        childrenArray,
                        infantArray
                      );
                    }}
                    onClick={() => {
                      setTheAllPassengers(
                        adultArray,
                        childrenArray,
                        infantArray
                      );
                    }}
                    onChange={(e) => {
                      handleAdultConfirmEmailChange(Adult.id, e.target.value);

                      console.log("adultArray", adultArray);
                      setTheAllPassengers(
                        adultArray,
                        childrenArray,
                        infantArray
                      );
                      console.log("allPa", allPassengers);
                    }}
                    className="text-xs w-full border-gray-300 rounded border-1"
                    placeholder="Enter Email"
                    type="text"
                    value={adultConfirmEmail}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default AdultDetails;
