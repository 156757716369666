import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import MapImage from "../../images/map.png";
import RightArrow from "../../images/right-arrow.png";
import LandingFlight from "../../images/landing-flight.png";
import { destinations } from "../../store/destinations";

function DestinationDropdown(props) {
  const theDestinations = destinations();

  return (
    <div onMouseLeave={props.hoverNone} className="destination-whole-div fixed top-[4.7rem] z-30">
      <div className="destination-img-div ">
        <img src={MapImage} alt="" />
      </div>
      <div className="destination-right-div">
        <div className="space-y-5 w-full">
          <div
            onClick={props.hoverNone}
            className="destination-right-div-top cursor-pointer"
          >
            <div>
              <img src={LandingFlight} alt="" />
            </div>
            <HashLink className="no-underline text-pry-color" to="/#destination">
              <p className='hover:text-sec-color '>Destination</p>
            </HashLink>
          </div>
          <div className="grid grid-cols-3  ">
            {theDestinations.map((dest) => (
              <div key={dest.cityID} onClick={props.hoverNone} className="   mb-2 ">
                <Link
                  className="no-underline  text-pry-color"
                  to={`/destination/${dest.cityID}`}
                >
                  <div className="flex items-center justify-start text-center space-x-2">
                    {" "}
                    <div className="flex justify-center items-center text-center">
                      {" "}
                      <p className=" text-xs font-semibold  cursor-pointer hover:text-sec-color">
                        {dest.cityName}
                      </p>
                    </div>
                    <div className="flex justify-center items-center">
                      {" "}
                      <img src={RightArrow} alt="" />
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DestinationDropdown;
