import React from "react";
import servicesFunction from "../store/services";
import RightArrow from "../images/right-arrow2.png";
import { Link } from "react-router-dom";

function Services() {
  const services = servicesFunction();

  return (
    <div id="services" className=" px-4 md:px-4 lg:px-8 py-10 mt-10">
      <h2 className="font-bold text-sec-color pb-12 lg:text-4xl md:text-3xl sm:text-2xl: my-4">
        OUR SERVICES{" "}
      </h2>
      {/* <p className='text-pry-color text-lg text-justify pb-3'>Desun Airlines Services cover a broad interest in the aviation industry in Nigeria. Our services include the following: </p> */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 md:grid-cols-3 xl:grid-cols-5 justify-between items-center">
        {services.map((eachService) => (
          <div
            key={eachService.title}
            className=" shadow-md  mb-10 rounded-lg pb-4"
          >
            <Link className="no-underline" to={`${eachService.linkToPage}`}>
              <div className="service-image  ">
                <img
                  className=" w-full md:w-full sm:h-32  h-60 rounded-t-lg"
                  src={require("../images/" + eachService.image + ".svg")}
                  alt=""
                />
              </div>
              <p className="service-title pl-2 sm:text-base md:text-lg text-center font-bold">
                {eachService.title}
              </p>
              {/* <Link className="no-underline" to={`${eachService.linkToPage}`}> */}
              {/* <div className="flex pl-2 no-underline text-sec-color text-xs sm:text-sm md:text-base items-center space-x-2">
                <p>Read More</p>
                <div>
                  <img className="w-3" src={RightArrow} alt="" />{" "}
                </div>
              </div> */}
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Services;
