import React, { useRef } from "react";
import AllCtx from "../uti-functions/allCtx";
import Success from "../images/success.gif";
import { useState } from "react";
import { useEffect } from "react";

function SuccessCheckIn() {
  const [response, setResponse] = useState("");
  const [requesting, setRequesting] = useState(false);

  const emailRef = useRef();
  const {
    baseUrl,
    individualRefID,
    userToken,
    user,
    checkInPassenger,
    flightRefID,
    setTheCheckInPassenger,
    setTheFlightRefID,
    setTheIndividualRefID,
  } = AllCtx();

  useEffect(() => {
    if (localStorage.getItem("checkInPassenger")) {
      setTheCheckInPassenger(
        JSON.parse(localStorage.getItem("checkInPassenger"))
      );
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("flightRefID")) {
      setTheFlightRefID(JSON.parse(localStorage.getItem("flightRefID")));
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("individualRefID")) {
      setTheIndividualRefID(
        JSON.parse(localStorage.getItem("individualRefID"))
      );
    }
  }, []);

  async function requestBoardingPass() {
    const emailInput = emailRef.current.value;

    if (!emailInput || emailInput.trim() === "") {
      setResponse(
        "You must input an email address before you request for Boarding Pass."
      );
      setRequesting(false);
      return;
    }

    try {
      setRequesting(true);
      setResponse("Requesting Boarding Pass, please wait...");

      const response = await fetch(
        `${baseUrl}/server/api/desunapi/v1/send_boarding_pass`,
        {
          method: "POST",
          body: JSON.stringify({
            _ref_id: individualRefID,
            _booking_ref_id: flightRefID,
            _last_name: checkInPassenger.last_name,
            _email: emailInput,
          }),
          headers: {
            "Content-Type": "application/json",
            Useremail: `${user.email}`,
            Usertoken: `${userToken}`,
          },
        }
      );

      if (!response.ok) {
        setResponse("Failed! Please try again.");
        setRequesting(false);

        console.log("Failed! Please try again.");

        return;
      }

      const data = await response.json();

      // console.log(data);

      setResponse(`Boarding Pass has been sent to ${emailInput}.`);
      emailRef.current.value = "";
      setRequesting(false);
    } catch (error) {
      console.log(error);
      setResponse("Error! Please try again.");
      setRequesting(false);
    }
  }

  return (
    <div className="">
      <div className=" mb-10 justify-center  services-header-bg  text-2xl md:text-3xl lg:text-4xl ">
        <p className="text-green-600">Checking in Successful</p>
      </div>

      <div className=" text-green-600 px-4 font-semibold text-lg">
        <div className=" flex justify-center items-center">
          <img className="h-20 w-20" src={Success} alt="" />
        </div>
        <div className=" flex justify-center text-center items-center mb-5">
          {" "}
          <p>
            You have checked in the reference ID{" "}
            <span className="text-pry-color font-bold ">{`${individualRefID}`}</span>{" "}
            successful!
          </p>
        </div>

        <div className=" mb-8 flex justify-center text-center items-center">
          {" "}
          <p>
            Click{" "}
            <a
              href={`${baseUrl}/server/api/desunapi/v1/send_boarding_pass?_ref_id=${individualRefID}&_booking_ref_id=${flightRefID}&_last_name=${checkInPassenger.last_name}`}
              className="font-bold underline underline-offset-2 cursor-pointer hover:text-sec-color"
            >
              HERE
            </a>{" "}
            to download your Boarding Pass or input your email address below to
            receive your Boarding Pass.
          </p>
        </div>

        <div className="font-bold flex justify-center  mb-6">
          <div>
            <input
              placeholder="Input email address"
              required
              ref={emailRef}
              className="w-full  border-pry-color rounded-lg"
              name="email"
              type="text"
            />
          </div>
        </div>

        <div className="h-5 flex justify-center text-sec-color">
          <p>{response}</p>
        </div>

        <div className="flex justify-center mt-16">
          <button
            onClick={() => {
              requestBoardingPass();
            }}
            className="bg-sec-color font-bold text-white px-5 py-3 text-sm rounded-lg"
          >
            Request Boarding Pass
          </button>
        </div>
      </div>
    </div>
  );
}

export default SuccessCheckIn;
