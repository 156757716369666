import React, { useEffect, useState } from "react";
import SearchIcon from "../../images/blue-search-icon.png";
import TakeOff from "../../images/flight-take-off.png";
import Landing from "../../images/landing-flight.png";
import CalendarIcon from "../../images/calendar-icon.png";
import CabinClass from "../../images/cabin-class.png";
import ToFro from "../../images/to-fro.svg";
import AllCtx from "../../uti-functions/allCtx";
import { useNavigate } from "react-router-dom";

function BookTripTabForm(props) {


  const {
    baseUrl,
    flightArray,
    setTheFlightArray,
    searchBookingResponse,
    setTheSearchBookingResponse,
    setTheTripType,
    setTheRoundTrip,
    setTheAdult,
    setTheFrom,
    setTheTo,
    setTheChildren,
    setTheDepartureDate,
    setTheReturnDate,
    setTheDisplayedFrom,
    setTheDisplayedTo,
    setTheInfant,
    roundTrip,
    tripType,
    from,
    to,
    displayedFrom,
    displayedTo,
    departureDate,
    returnDate,
    adult,
    children,
    infant,
  } = AllCtx();


  // const devEnv = ``;
  // const prodEnv = "https://desunair.com/server/api/desunapi/v1";
  let navigate = useNavigate();

  const [searchingFlights, setSearchingFlights] = useState(false);


  function falseRoundTrip() {
    setTheRoundTrip(false);
  }

  function trueRoundTrip() {
    setTheRoundTrip(true);
  }

  async function searchFlight(e) {
    e.preventDefault();
    if (!tripType || !from || !to || !departureDate) {
      setTheSearchBookingResponse("Fill in important flight details.");
      console.log("Fill in important flight details.");
      return;
    }

    console.log("Trip Type is - " + tripType);
    console.log("From - " + from);
    console.log("To - " + to);
    console.log("Departure Date is - " + departureDate);
    console.log("Return Date is - " + returnDate);

    try {
      setSearchingFlights(true);
      const response = await fetch(
        `${baseUrl}/server/api/desunapi/v1/flightquery?_trip_type=${tripType}&_route_to=${to}&_route_from=${from}&_departure_date=${departureDate}&_arrival_date=${returnDate}&_ps_adult=${adult}&_ps_children=${children}&_ps_infant=${infant}`
      );

      const data = await response.json();

    // return

      if (!response.ok) {
        setSearchingFlights(false);
        setTheSearchBookingResponse("No flight available for your query.");
        console.log("No flight available for your query.");
        return;
      }

      console.log(data);

      // return

      const status = data.map((result) => result.status);

      if (status === "fail") {
        setSearchingFlights(false);
        setTheSearchBookingResponse("No flight available for your query.");
        console.log("No flight available for your query.");
        return;
      }

      setTheSearchBookingResponse("");
      console.log("Success! Flight(s) available.");

      const flights = data.map((result) => result.flights);
      setSearchingFlights(false);
      setTheFlightArray(flights);
      localStorage.setItem("flightArray", JSON.stringify(flights));
      console.log(flights);
      navigate("/search-results");
    } catch (error) {
      setSearchingFlights(false);
      setTheSearchBookingResponse(
        "Something went wrong! Check internet connection."
      );
      console.log("Something went wrong, try again.");
    }
  }

  return (
    <form onSubmit={searchFlight} className="tab-form ">
      <div className="radio-n-search-div ">
        {/* Radio buttons  */}
        <div className="only-radio-buttons ">
          <div className="input-n-label">
            <input
              onClick={falseRoundTrip}
              htmlFor=""
              id="flight-type"
              name="flight-type"
              type="radio"
              value="single"
              onChange={(e) => {
                setTheTripType("single");
                localStorage.setItem(
                  "tripType",
                  JSON.stringify(e.target.value)
                );
              }}
            />{" "}
            {/* Desktop TripType */}
            <label>One Way</label>
          </div>
          <div className="input-n-label">
            <input
              onClick={trueRoundTrip}
              htmlFor=""
              id="round-trip"
              name="flight-type"
              value='round'
              onChange={(e) => {
                setTheTripType("round");
                localStorage.setItem(
                  "tripType",
                  JSON.stringify(e.target.value)
                );
              }}
              type="radio"
            />{" "}
            {/* Desktop TripType */}
            <label>Round Trip</label>
          </div>
        </div>
        {/* <div className="input-n-search-div">
          <input
            placeholder="SEARCH"
            className="search-input focus:outline-none focus:ring focus:ring-transparent"
            type="text"
          />{" "}
          <div className="cursor-pointer ">
            <img src={SearchIcon} alt="" />
          </div>
        </div> */}
      </div>
      <div className="div-under-trip-radios">
        <div
          className={`${roundTrip ? "md:w-[18%]" : "md:w-[22%]"} each-trip-div`}
        >
          <div className="flight-n-input">
            <div>
              <img src={TakeOff} alt="" />
            </div>{" "}
            {/* From Location Select */}
            <select
              onChange={(e) => {
                setTheFrom(e.target.value);
                setTheDisplayedFrom(e.target.selectedOptions[0].id);
                localStorage.setItem(
                  "displayedFrom",
                  JSON.stringify(e.target.selectedOptions[0].id)
                );
                localStorage.setItem("from", JSON.stringify(e.target.value));
                console.log(e.target.selectedOptions[0].id);
              }}
              className="from-input text-sm focus:outline-none focus:ring focus:ring-transparent"
              type="text"
              //  value={}
            >
              <option id="" value={from || ""}>
                {displayedFrom || "From"}
              </option>
              <option id="ABIA" value="AB">
                ABIA
              </option>
              <option id="ABUJA" value="FC">
                ABUJA
              </option>
              <option id="ADAMAWA" value="AD">
                ADAMAWA
              </option>
              <option id="AKWA IBOM" value="AK">
                AKWA IBOM
              </option>
              <option id="ANAMBRA" value="AN">
                ANAMBRA
              </option>
              <option id="BAUCHI" value="BA">
                BAUCHI
              </option>
              <option id="BAYELSA" value="BY">
                BAYELSA
              </option>
              <option id="BENUE" value="BE">
                BENUE
              </option>
              <option id="BORNO" value="BO">
                BORNO
              </option>
              <option id="CROSS RIVER" value="CR">
                CROSS RIVER
              </option>
              <option id="DELTA" value="DE">
                DELTA
              </option>
              <option id="EBOYIN" value="EB">
                EBOYIN
              </option>
              <option id="EDO" value="ED">
                EDO
              </option>
              <option id="EKITI" value="EK">
                EKITI
              </option>
              <option id="GOMBE" value="GO">
                GOMBE
              </option>
              <option id="IMO" value="IM">
                IMO
              </option>
              <option id="JIGAWA" value="JI">
                JIGAWA
              </option>
              <option id="KADUNA" value="KD">
                KADUNA
              </option>
              <option id="KANO" value="KN">
                KANO
              </option>
              <option id="KASTINA" value="KT">
                KASTINA
              </option>
              <option id="KEBBI" value="KE">
                KEBBI
              </option>
              <option id="KOGI" value="KO">
                KOGI
              </option>
              <option id="KWARA" value="KW">
                KWARA
              </option>
              <option id="LAGOS" value="LA">
                LAGOS
              </option>
              <option id="NASARAWA" value="NA">
                NASARAWA
              </option>
              <option id="NIGER" value="NI">
                NIGER
              </option>
              <option id="OGUN" value="OG">
                OGUN
              </option>
              <option id="ONDO" value="ON">
                ONDO
              </option>
              <option id="OSUN" value="OS">
                OSUN
              </option>
              <option id="OYO" value="OY">
                OYO
              </option>
              <option id="PLATEAU" value="PL">
                PLATEAU
              </option>
              <option id="RIVERS" value="RI">
                RIVERS
              </option>
              <option id="SOKOTO" value="SO">
                SOKOTO
              </option>
              <option id="TARABA" value="TA">
                TARABA
              </option>
              <option id="YOBE" value="YO">
                YOBE
              </option>
              <option id="ZAMFARA" value="FC">
                ZAMFARA
              </option>
            </select>
          </div>
          <p className="hidden md:flex">Flight from?</p>
        </div>
        <div className="md:hidden   bg-transparent  ">
          <img className="w-12 h-12 -my-6 ml-[80%] " src={ToFro} alt="" />
        </div>
        <div
          className={`${
            roundTrip ? "md:w-[18%]" : "md:w-[22%]"
          } each-trip-div `}
        >
          <div className="flight-n-input">
            <div>
              <img src={Landing} alt="" />
            </div>{" "}
            {/* To Location Select */}
            <select
              onChange={(e) => {
                setTheTo(e.target.value);
                setTheDisplayedTo(e.target.selectedOptions[0].id);
                localStorage.setItem(
                  "displayedTo",
                  JSON.stringify(e.target.selectedOptions[0].id)
                );
                localStorage.setItem("to", JSON.stringify(e.target.value));
                console.log(e.target.selectedOptions[0].id);
              }}
              className="from-input text-sm focus:outline-none focus:ring focus:ring-transparent bg-transparent  "
              type="text"
              placeholder="To"
            >
              <option id="" value={to || ""}>
                {displayedTo || "To"}
              </option>
              <option id="ABIA" value="AB">
                ABIA
              </option>
              <option id="ABUJA" value="FC">
                ABUJA
              </option>
              <option id="ADAMAWA" value="AD">
                ADAMAWA
              </option>
              <option id="AKWA IBOM" value="AK">
                AKWA IBOM
              </option>
              <option id="ANAMBRA" value="AN">
                ANAMBRA
              </option>
              <option id="BAUCHI" value="BA">
                BAUCHI
              </option>
              <option id="BAYELSA" value="BY">
                BAYELSA
              </option>
              <option id="BENUE" value="BE">
                BENUE
              </option>
              <option id="BORNO" value="BO">
                BORNO
              </option>
              <option id="CROSS RIVER" value="CR">
                CROSS RIVER
              </option>
              <option id="DELTA" value="DE">
                DELTA
              </option>
              <option id="EBOYIN" value="EB">
                EBOYIN
              </option>
              <option id="EDO" value="ED">
                EDO
              </option>
              <option id="EKITI" value="EK">
                EKITI
              </option>
              <option id="GOMBE" value="GO">
                GOMBE
              </option>
              <option id="IMO" value="IM">
                IMO
              </option>
              <option id="JIGAWA" value="JI">
                JIGAWA
              </option>
              <option id="KADUNA" value="KD">
                KADUNA
              </option>
              <option id="KANO" value="KN">
                KANO
              </option>
              <option id="KASTINA" value="KT">
                KASTINA
              </option>
              <option id="KEBBI" value="KE">
                KEBBI
              </option>
              <option id="KOGI" value="KO">
                KOGI
              </option>
              <option id="KWARA" value="KW">
                KWARA
              </option>
              <option id="LAGOS" value="LA">
                LAGOS
              </option>
              <option id="NASARAWA" value="NA">
                NASARAWA
              </option>
              <option id="NIGER" value="NI">
                NIGER
              </option>
              <option id="OGUN" value="OG">
                OGUN
              </option>
              <option id="ONDO" value="ON">
                ONDO
              </option>
              <option id="OSUN" value="OS">
                OSUN
              </option>
              <option id="OYO" value="OY">
                OYO
              </option>
              <option id="PLATEAU" value="PL">
                PLATEAU
              </option>
              <option id="RIVERS" value="RI">
                RIVERS
              </option>
              <option id="SOKOTO" value="SO">
                SOKOTO
              </option>
              <option id="TARABA" value="TA">
                TARABA
              </option>
              <option id="YOBE" value="YO">
                YOBE
              </option>
              <option id="ZAMFARA" value="FC">
                ZAMFARA
              </option>
            </select>
          </div>
          <p className="hidden md:flex">Where to?</p>
        </div>

        <div className="bg-transparent md:hidden md:text-xs flex w-full ">
          <div
            className={` ${
              roundTrip ? " w-1/3" : "w-1/2"
            } border-pry-color border-[1.8px] rounded-l-lg px-2`}
          >
            <div>
              <p>Trip</p>
            </div>
            {/* Mobile TripType */}
            <select
              name="one-way"
              id=""
              value={tripType}
              onChange={(e) => {
                setTheTripType(e.target.value);
                e.target.value !== "round"
                  ? setTheRoundTrip(false)
                  : setTheRoundTrip(true);
                setTheTripType(e.target.value);
                localStorage.setItem(
                  "tripType",
                  JSON.stringify(e.target.value)
                );
              }}
              className="focus:outline-none focus:ring focus:ring-transparent border-none w-full pl-0 font-bold  md:text-xl block "
            >
              <option value="single"> One Way</option>
              <option value="round">Round Trip</option>
              {/* <option value="special-round-trip"> Special Round Trip</option> */}
            </select>
          </div>

          <div
            className={`${
              roundTrip ? " w-1/3" : "w-1/2 rounded-r-lg"
            } border-pry-color border-t-[1.8px] border-r-[1.8px] border-b-[1.8px] px-2`}
          >
            <p>Depart</p>
            {/* Mobile Departure Date */}
            <input
              // onMouseOver='(this.type)'
              onChange={(e) => {
                setTheDepartureDate(e.target.value);
                localStorage.setItem(
                  "departureDate",
                  JSON.stringify(e.target.value)
                );
              }}
              type="date"
              // value='2022-98-09'
              // placeholder="ddddd"
              className="w-full border-none pl-0 font-bold text-sm"
            />
          </div>
          {roundTrip ? (
            <div className=" w-1/3 border-pry-color border-t-[1.8px] border-r-[1.8px] border-b-[1.8px] px-2 rounded-r-lg">
              <p>Return</p>
              {/* Mobile Return Date */}
              <input
                onChange={(e) => {
                  setTheReturnDate(e.target.value);
                  localStorage.setItem(
                    "returnDate",
                    JSON.stringify(e.target.value)
                  );
                }}
                disabled={!roundTrip}
                type="date"
                className="w-full border-none pl-0 font-bold text-sm"
              />
            </div>
          ) : (
            ""
          )}
        </div>

        <div
          className={`${
            roundTrip ? "md:w-[18%]" : "md:w-[22%]"
          } each-trip-div `}
        >
          <div className="flight-n-input hidden md:flex">
            <div>
              <img src={CalendarIcon} alt="" />
            </div>{" "}
            {/* Desktop Departure Date */}
            <input
              onChange={(e) => {
                setTheDepartureDate(e.target.value);
                localStorage.setItem(
                  "departureDate",
                  JSON.stringify(e.target.value)
                );
              }}
              className="from-input text-sm"
              type="date"
              placeholder="Departure"
              // value='ddd'
            />{" "}
          </div>
          <p className="hidden md:flex">Departure</p>
        </div>

        {roundTrip && (
          <div className="each-trip-div">
            <div className="flight-n-input hidden md:flex">
              <div>
                <img src={CalendarIcon} alt="" />
              </div>{" "}
              {/* Desktop Return Date */}
              <input
                onChange={(e) => {
                  setTheReturnDate(e.target.value);
                  localStorage.setItem(
                    "returnDate",
                    JSON.stringify(e.target.value)
                  );
                }}
                className="from-input text-sm"
                type="date"
                placeholder="Return"
              />{" "}
            </div>
            <p className="hidden md:flex">Return</p>
          </div>
        )}

        <div className="each-trip-div md:w-[26%] ">
          <div className="flight-n-input pt-1">
            {/* Adult select  */}
            <select
              onChange={(e) => {
                setTheAdult(+(e.target.value));
                console.log(adult);
                localStorage.setItem("adult", JSON.stringify(+(e.target.value)));
              }}
              className="w-full sm:text-xs focus:outline-none focus:ring focus:ring-transparent border-r-2 border-0 "
              name="adult"
              id=""
            >
              <option value={adult || 1}> {adult || 1}</option>
              <option value={1}> 1</option>
              <option value={2}> 2</option>
              <option value={3}> 3</option>
              <option value={4}> 4</option>
              <option value={5}> 5</option>
              <option value={6}> 6</option>
              <option value={7}> 7</option>
              <option value={8}> 8</option>
              <option value={9}> 9</option>
              <option value={10}> 10</option>
            </select>

            {/* Children select  */}
            <select
              onChange={(e) => {
                setTheChildren(+(e.target.value));
                localStorage.setItem(
                  "children",
                  JSON.stringify(+(e.target.value))
                );
              }}
              className="w-full sm:text-xs  focus:outline-none focus:ring focus:ring-transparent border-r-2 border-0 "
              name="children"
              id=""
            >
              <option value={children || 0}> {children || 0}</option>
              <option value={0}> 0</option>
              <option value={1}> 1</option>
              <option value={2}> 2</option>
              <option value={3}> 3</option>
              <option value={4}> 4</option>
              <option value={5}> 5</option>
              <option value={6}> 6</option>
              <option value={7}> 7</option>
              <option value={8}> 8</option>
              <option value={9}> 9</option>
              <option value={10}> 10</option>
            </select>

            {/* Infant select  */}
            <select
              onChange={(e) => {
                setTheInfant(+(e.target.value));
                localStorage.setItem("infant", JSON.stringify(+(e.target.value)));
              }}
              className="w-full sm:text-xs focus:outline-none focus:ring focus:ring-transparent  border-0 "
              name="infant"
              id=""
            >
              <option value={infant || 0}> {infant || 0}</option>
              <option value={0}> 0</option>
              <option value={1}> 1</option>
              <option value={2}> 2</option>
              <option value={3}> 3</option>
              <option value={4}> 4</option>
              <option value={5}> 5</option>
              <option value={6}> 6</option>
              <option value={7}> 7</option>
              <option value={8}> 8</option>
              <option value={9}> 9</option>
              <option value={10}> 10</option>
            </select>
          </div>
          <div className="flex ">
            <div className="w-1/3 flex justify-center">
              {" "}
              <p>Adult</p>
            </div>
            <div className="w-1/3 flex justify-center">
              {" "}
              <p>Child(ren)</p>
            </div>
            <div className="w-1/3 flex justify-center">
              {" "}
              <p>Infant</p>
            </div>
          </div>
        </div>
      </div>
      <div className={`font-bold mt-5 px-5 flex justify-center items-center`}>
        <p>
          {searchingFlights ? "Searching flights..." : searchBookingResponse}
        </p>
      </div>
      <div className="tab-btn-div">
        {" "}
        <button
          disabled={searchingFlights}
          className={`${
            searchingFlights
              ? "bg-gray-500 text-gray-300"
              : "bg-sec-color hover:bg-orange-700"
          }  text-white px-10 py-2 rounded-md `}
        >
          {props.btnText || "Book Trip"}
        </button>{" "}
      </div>
    </form>
  );
}

export default BookTripTabForm;
