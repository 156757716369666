import React, { useEffect } from "react";
import StatusFlightDetails from "../components/flight-status/StatusFlightDetails";
import FlightStatusComponent from "../components/flight-status/FlightStatusComponent";
import AllCtx from "../uti-functions/allCtx";


function FlightStatusPage() {
const {setTheFlightStatus, setTheCheckInPassenger, setTheChosenFlight, setThePassengerFlight,
  passengerFlight} = AllCtx();

  useEffect(() => {
    if (localStorage.getItem("flightStatus")) {
      setTheFlightStatus(
        JSON.parse(localStorage.getItem("flightStatus"))
      );
    }
  }, []); 

  useEffect(() => {
    if (localStorage.getItem("checkInPassenger")) {
      setTheCheckInPassenger(
        JSON.parse(localStorage.getItem("checkInPassenger"))
      );
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("passengerFlight")) {
      setThePassengerFlight(
        JSON.parse(localStorage.getItem("passengerFlight"))
      );
    }
  }, []);


  return (
    <div>
      <div className=" mb-10  services-header-bg text-2xl md:text-3xl lg:text-4xl">
        <p>Flight Status</p>
      </div>

      <div className="md:flex w-full md:space-x-2 ">
        <div className=" px-8 md:w-[60%] ">
          <StatusFlightDetails />
        </div> 

        <FlightStatusComponent />
      </div>
    </div>
  );
}

export default FlightStatusPage;
