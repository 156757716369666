import React from "react";
import BookTripTabForm from "../components/tab/BookTripTabForm";
import VerifyPaymentDetails from "../components/verify/VerifyPaymentDetails";
import VerifyAdultDetails from "../components/verify/VerifyAdultDetails";
import VerifyChildrenDetails from "../components/verify/VerifyChildrenDetails";
import VerifyInfantDetails from "../components/verify/VerifyInfantDetails";
import VerifyFlightDetailsOrder from "../components/verify/VerifyFlightDetailsOrder";

function VerifyBookingPage() {
  return (
    <div>
      <div className="  services-header-bg text-2xl md:text-3xl lg:text-4xl">
        <p>Booking Details</p>
      </div>
      <BookTripTabForm btnText="Search Again" />
      <div className="md:flex w-full md:space-x-2 ">
        <div className=" px-8 md:w-[60%] overflow-y-scroll h-auto">
          <VerifyFlightDetailsOrder />

          <VerifyAdultDetails />
          <VerifyChildrenDetails />
          <VerifyInfantDetails />
        </div>

        <VerifyPaymentDetails />
      </div>
    </div>
  );
}

export default VerifyBookingPage;
