import React, { useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import BookFlight from "../../images/book-flight.png";
import LilFlightIcon from "../../images/lil-flight-icon.png";
import RightArrow from "../../images/right-arrow.png";
import AllCtx from "../../uti-functions/allCtx";


function BookingDropdown(props) {
const {selectTheTab1, selectTheTab2, selectTheTab3, selectTheTab4} = AllCtx();



  return (
    <div onMouseLeave={props.hoverNone} className=" booking-whole-div fixed top-[4.7rem] z-30">
      <div className="booking-img-div ">
        <img src={BookFlight} alt="" />
      </div>
      <div className="booking-right-div">
        <div className="space-y-5 w-full">
          <div onClick={props.hoverNone}  className="booking-right-div-top">
            <div>
              <img src={LilFlightIcon} alt="" />
            </div>
            <HashLink className="no-underline text-pry-color" to="/#tab">
              <p className='hover:text-sec-color'>Booking</p>
            </HashLink>
          </div>
          <div  className="booking-right-div-mid ">
            <div
              onClick={() => {
                props.hoverNone();
                selectTheTab1()

              }} 
              className=" w-1/2 cursor-pointer  flex items-center space-x-2"
            >
              <HashLink className='no-underline text-pry-color' to='/#tab'>
              <p className='hover:text-sec-color text-xs font-semibold'>Search Flight</p>
            </HashLink>
              <div>
                <img src={RightArrow} alt="" />
              </div>
            </div>
            <div
              onClick={props.hoverNone}
              className=" w-1/2 cursor-pointer flex items-center space-x-2"
            >
             <HashLink className='no-underline text-pry-color' to='/#tab'> 

             <p onClick={selectTheTab2} className='hover:text-sec-color text-xs font-semibold'>Manage Booking</p>
             </HashLink>
              <div>
                <img src={RightArrow} alt="" />
              </div>
            </div>
          </div>
          {/* <div className="booking-right-div-bottom">
            <div
              onClick={props.hoverNone}
              className=" w-1/2 cursor-pointer flex items-center space-x-2"
            >
              <p>Flight Schedule</p>
              <div>
                <img src={RightArrow} alt="" />
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default BookingDropdown;
