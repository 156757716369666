import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import AboutUSImage from "../../images/about-us.png";
import HandIcon from "../../images/hand.png";
import RightArrow from "../../images/right-arrow.png";

function ServicesDropdown(props) {
  return (
    <div onMouseLeave={props.hoverNone} className=" bg-opacity-90 bg-white  text-pry-color ml-[27%] lg:ml-[30%] w-[58%] hidden md:flex space-x-8 lg:space-x-20 text-sm lg:text-base fixed top-[4.7rem] z-30">
      <div className="services-dd-img-div ">
        <img src={AboutUSImage} alt="" />
      </div>
      <div className="services-dd-right-div">
        <div className="space-y-5 w-full">
          <div onClick={props.hoverNone} className="services-dd-right-div-top cursor-pointer">
            <div>
              <img src={HandIcon} alt="" />
            </div>
            <HashLink className='no-underline text-pry-color' to='/#services'>
              <p className='hover:text-sec-color text-xs font-semibold'>Services</p>
            </HashLink>
          </div>
          <div className="services-dd-right-div-mid">
            <div onClick={props.hoverNone} className=" w-1/3 cursor-pointer text-sec-color flex items-center space-x-2">
            <Link className='no-underline text-pry-color' to='/services/training'>
              <p className='hover:text-sec-color text-xs font-semibold'>Training</p>
            </Link>
              <div>
                <img onClick={props.hoverNone} src={RightArrow} alt="" />
              </div>
            </div>
            <div onClick={props.hoverNone} className=" w-1/3 cursor-pointer flex items-center space-x-2">
              <Link className='no-underline text-pry-color' to='/services/private-hire'>
              <p className='hover:text-sec-color text-xs font-semibold'>Private Charter</p>
            </Link>
              <div>
                <img  src={RightArrow} alt="" />
              </div>
            </div>
            <div onClick={props.hoverNone} className=" w-1/3 cursor-pointer flex items-center space-x-2">
            <Link className='no-underline text-pry-color' to='services/repairs'>
              <p className='hover:text-sec-color text-xs font-semibold'>Repairs</p>
            </Link>
              <div>
                <img  src={RightArrow} alt="" />
              </div>
            </div>
          </div>
          <div className="services-dd-right-div-bottom">
            {/* <div onClick={props.hoverNone} className=" w-1/3 cursor-pointer flex items-center space-x-2">
            <Link className='no-underline text-pry-color' to='/'>
              <p className='hover:text-sec-color text-xs font-semibold'>Mission &amp; Vision</p>
            </Link>
              <div>
                <img  src={RightArrow} alt="" />
              </div>
            </div> */}
            <div onClick={props.hoverNone} className=" w-1/3 cursor-pointer flex items-center space-x-2">
            <Link className='no-underline text-pry-color' to='/services/cargo'>
              <p className='hover:text-sec-color text-xs font-semibold'>Cargo</p>
            </Link>
              <div>
                <img  src={RightArrow} alt="" />
              </div>
            </div>
            <div onClick={props.hoverNone} className=" w-1/3 cursor-pointer flex items-center space-x-2">
            <Link className='no-underline text-pry-color' to='/services/purchase-parts'>
              <p className='hover:text-sec-color text-xs font-semibold'>Purchase Parts</p>
            </Link>
              <div>
                <img  src={RightArrow} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServicesDropdown;
