import React from 'react'
import AllCtx from "../../uti-functions/allCtx";

function Notification() {
const {showNotification, setTheShowNotification, notificationText, setTheNotificationText} = AllCtx();


  return (
      <div>
          {showNotification &&    <div className='bg-green-500 mr-6 text-white text-lg mt-5 z-[100] flex justify-center items-center fixed right-0 rounded-3xl bg-opacity-90 py-2 px-8' ><p>{notificationText}</p></div>}
 </div>
  )
}

export default Notification