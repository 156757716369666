import React from "react";
import { useParams } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Appointment from "../images/join-us-icon.png";
import { clickedDestination } from "../store/destinations";

function DestinationDetails() {
  let { cityID } = useParams();
  const theClickedDestination = clickedDestination(cityID);

  return (
    <div className=" bg-mid-color pb-3">
      <div className="services-header-bg text-2xl md:text-3xl lg:text-4xl">
        {" "}
        <p>{theClickedDestination.cityName}</p>{" "}
      </div>

      <div className="md:flex  px-8 md:space-x-7 pt-5 md:items-center">
        <div className="md:w-1/2 ">
          <img
            className="w-full"
            src={require("../images/" +
              theClickedDestination.cityImage1 +
              ".webp")}
            alt=""
          />
        </div>
        <div className="md:w-1/2 md:pt-0 pt-8">
          <h2 className="text-pry-color font-bold  mb-4 text-3xl">
            <span className="text-sec-color">Welcome to</span>{" "}
            {theClickedDestination.cityName}
          </h2>
          <p className="text-justify  md:leading-7 leading-relaxed text-pry-color ">
            {theClickedDestination.cityText}
          </p>
          <HashLink className="no-underline text-black" to="/#destination">
            <div className="flex  items-center space-x-3 my-8 text-sm  ">
              <p className="cursor-pointer hover:text-sec-color">
                Back to Destinations
              </p>{" "}
              <img
                className="h-6 w-6 cursor-pointer"
                src={Appointment}
                alt=""
              />
            </div>
          </HashLink>
        </div>
      </div>
      <div className="p-8">
        <h2 className="my-3 text-sec-color font-bold sm:text-2xl md:text-3xl lg:text-4xl">
          {" "}
          {theClickedDestination.cityName} Tourist Attractions
        </h2>
        <div className="mb-3">
          <p className="text-pry-color text-justify md:leading-10 leading-relaxed">
            {theClickedDestination.touristAttraction}
          </p>
        </div>
        <div className=" md:flex md:space-x-3 justify-between">
          <div className="md:w-1/2">
            <div>
              <img
                src={require("../images/" +
                  theClickedDestination.cityImage2 +
                  ".webp")}
                alt=""
              />
            </div>
            <h2 className="text-sec-color my-3">
              {theClickedDestination.tourTitle1}
            </h2>
            {/* <p className='mb-6 text-justify'>{theClickedDestination.tourText1}</p> */}
          </div>
          <div className="md:w-1/2">
            <div>
              <img
                src={require("../images/" +
                  theClickedDestination.cityImage3 +
                  ".webp")}
                alt=""
              />
            </div>
            <h2 className="text-sec-color my-3">
              {theClickedDestination.tourTitle2}
            </h2>
            {/* <p className='text-justify'>{theClickedDestination.tourText2}</p> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DestinationDetails;
