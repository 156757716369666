import React, { useEffect, useState } from "react";
import logo from "../images/login-logo.png";
import Flight from "../images/login-fight.png";
import { Link } from "react-router-dom";
import { useRef } from "react";
import AllCtx from "../uti-functions/allCtx";
import { useNavigate } from "react-router-dom";

function LoginPage() {
  let navigate = useNavigate();
  const {
    setTheIsLoggedIn,
    setTheShowNotification,
    isLoggedIn,
    setTheUser,
    setTheUserToken,
    setTheNotificationText,
    baseUrl,
  } = AllCtx();

  const [response, setResponse] = useState("");
  const [loggingIn, setLoggingIn] = useState(false);
  const [canRunTimeOut, setCanRunTimeOut] = useState(true);

  if (
    localStorage.getItem("userToken") &&
    localStorage.getItem("user") &&
    isLoggedIn
  ) {
    navigate("/");
  }

  const emailRef = useRef();
  const passwordRef = useRef();

  async function loginHandler() {
    const filledEmail = emailRef.current.value;
    const filledPassword = passwordRef.current.value;

    if (
      !filledEmail ||
      !filledPassword ||
      filledEmail.trim().length === 0 ||
      filledPassword.trim().length === 0
    ) {
      setResponse("Fill all fields!");
      return;
    }

    try {
      setLoggingIn(true);
      setResponse("Please wait...");
      const response = await fetch(`${baseUrl}/server/api/desunapi/v1/login`, {
        method: "POST",
        body: JSON.stringify({
          email: filledEmail,
          password: filledPassword,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();
      // console.log(data);

      if (!response.ok) {
        setLoggingIn(false);

        data.map((dat) => {
          const message = dat.message;
          console.log(message || "Failed! Please try again.");
          setResponse(message);
        });
        return;
      }

      data.map((User) => {
        setTheUserToken(User.token);
        setTheUser(User.user_info);

        localStorage.setItem("user", JSON.stringify(User.user_info));
        localStorage.setItem("userToken", JSON.stringify(User.token));
      });
      setTheIsLoggedIn(true);
      setTheShowNotification(true);
      setTheNotificationText("Successfully logged in!");
      localStorage.setItem('lastLoggedIn', JSON.stringify(Date.now()))
      navigate("/");
      if (canRunTimeOut) {
        const timer = setTimeout(() => {
          setTheShowNotification(false);
          setTheNotificationText("");
        }, 4000);

        return () => clearTimeout(timer);
      }

      setResponse("");

      setLoggingIn(false);
    } catch (error) {
      console.log(error);
      setResponse("Error! Please try again.");
      setLoggingIn(false);
    }
  }

  return (
    <div className="sm:px-10 px-5 py-40 bg-[url('../images/login-bg.png')] bg-cover bg-center bg-no-repeat">
      <div className=" shadow-2xl rounded-2xl pl-6 sm:pl-12 bg-gradient-to-r from-[#B2C2DF] to-white r py-6">
        <div className="mb-10">
          <img src={logo} alt="" />
        </div>
        <div className="flex">
          <div className="sm:w-[80%] md:w-[50%] w-full pr-3">
            <div className="mb-5">
              <p className="text-pry-color text-3xl font-bold">Login</p>
            </div>
            <div className="mb-4">
              <input
                className="rounded-md w-full placeholder-pry-color shadow-md border-none"
                placeholder="email"
                type="text"
                ref={emailRef}
              />
            </div>
            <div className="mb-4">
              <input
                className="rounded-md w-full placeholder-pry-color shadow-md border-none"
                placeholder="password"
                type="password"
                ref={passwordRef}
              />
            </div>
            <div className="my-2 h-6 flex items-center justify-center text-pry-color">
              <p>{response}</p>
            </div>
            <div className="mb-6">
              <button
                onClick={loginHandler}
                className="bg-sec-color hover:bg-orange-400 px-8 text-white py-1 rounded-md shadow-md"
              >
                Login
              </button>
            </div>

            <div className="text-sm text-pry-color">
              <Link
                to="/forgot-password"
                className="text-sec-color mb-2 cursor-pointer font-semibold  w-1/2"
              >
                Forgot Password?
              </Link>
              <p>
                New to Desun Air?{" "}
                <Link
                  to="/sign-up"
                  className="text-sec-color no-underline cursor-pointer font-semibold"
                >
                  Sign Up
                </Link>
              </p>
            </div>
          </div>{" "}
          <div className="hidden sm:flex justify-center items-center   ">
            <img className="w-96" src={Flight} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
