import React, { useEffect, useState } from "react";
import logo from "../images/login-logo.png";
import Flight from "../images/login-fight.png";
import { Link } from "react-router-dom";
import { useRef } from "react";
import AllCtx from "../uti-functions/allCtx";
import { useNavigate } from "react-router-dom";

function SignUpPage() {
  let navigate = useNavigate();
  const {
    baseUrl,
    isLoggedIn,
    setTheNotificationText,
    setTheShowNotification,
    setTheIsLoggedIn,
    setTheUser,
    setTheUserToken,
  } = AllCtx();

  const [response, setResponse] = useState("");
  const [signingUp, setSigningUp] = useState(false);
  const [canRunTimeOut, setCanRunTimeOut] = useState(true);

  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();

  if (
    localStorage.getItem("userToken") &&
    localStorage.getItem("user") &&
    isLoggedIn
  ) {
    navigate("/");
  }

  async function signUpHandler(e) {
    e.preventDefault();
    const filledFirstName = firstNameRef.current.value;
    const filledLastName = lastNameRef.current.value;
    const filledEmail = emailRef.current.value;
    const filledPassword = passwordRef.current.value;
    const filledConfirmPassword = confirmPasswordRef.current.value;

    if (
      !filledFirstName ||
      !filledLastName ||
      !filledEmail ||
      !filledPassword ||
      !filledConfirmPassword ||
      filledFirstName.trim().length === 0 ||
      filledConfirmPassword.trim().length === 0 ||
      filledEmail.trim().length === 0 ||
      filledPassword.trim().length === 0 ||
      filledLastName.trim().length === 0
    ) {
      setResponse("Fill all fields!");
      return;
    }

    if (filledPassword !== filledConfirmPassword) {
      setResponse("Password does not match.");
      return;
    }

    try {
      setSigningUp(true);
      setResponse("Please wait...");

      const response = await fetch(
        `${baseUrl}/server/api/desunapi/v1/register`,
        {
          method: "POST",
          body: JSON.stringify({
            _first_name: filledFirstName,
            _last_name: filledLastName,
            _email: filledEmail,
            _password: filledPassword,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();

      if (!response.ok) {
        setSigningUp(false);
        data.map((dat) => {
          const message = dat.message;
          console.log(message || "Failed! Please try again.");
          setResponse(message);
        });

        return;
      }
      console.log(data);
      data.map((User) => {
        setTheUserToken(User.token);
        setTheUser(User.user_info);
        console.log(User.user_info);
        localStorage.setItem("user", JSON.stringify(User.user_info));
        localStorage.setItem("userToken", JSON.stringify(User.token));
      });
      setTheIsLoggedIn(true);
      setTheShowNotification(true);
      setTheNotificationText(
        "Registration successful! You have been logged in!"
      );
      navigate("/");
      if (canRunTimeOut) {
        const timer = setTimeout(() => {
          setTheShowNotification(false);
          setTheNotificationText("");
        }, 3000);

        return () => clearTimeout(timer);
      }
      setResponse("");

      setSigningUp(false);

      if (isLoggedIn) {
        const timer = setTimeout(() => {
          navigate("/");
          setResponse("");
        }, 2000);

        return () => clearTimeout(timer);
      }
      setSigningUp(false);
    } catch (error) {
      console.log(error);
      setResponse("Error! Please try again.");
      setSigningUp(false);
    }
  }

  return (
    // (console.log(user)),
    <div className="sm:px-10 px-5 py-40 bg-[url('../images/login-bg.png')] bg-cover bg-center bg-no-repeat">
      <div className=" shadow-2xl rounded-2xl pl-6 sm:pl-12 bg-gradient-to-r from-[#B2C2DF] to-white r py-6">
        <div className="mb-10">
          <img src={logo} alt="" />
        </div>
        <div className="flex">
          <form onSubmit={signUpHandler} className="lg:w-[50%] w-full pr-3">
            <div className="mb-5">
              <p className="text-pry-color text-3xl font-bold">Sign Up</p>
            </div>
            <div className="mb-4 flex justify-between">
              <input
                className="rounded-md w-[45%]  placeholder-pry-color shadow-md border-none"
                placeholder="First Name"
                type="text"
                ref={firstNameRef}
              />{" "}
              <input
                className="rounded-md w-[45%]  placeholder-pry-color shadow-md border-none"
                placeholder="Last Name"
                type="text"
                ref={lastNameRef}
              />
            </div>

            <div className="mb-4">
              <input
                className="rounded-md w-full placeholder-pry-color shadow-md border-none"
                placeholder="Email"
                type="text"
                ref={emailRef}
              />
            </div>
            <div className="mb-4">
              <input
                className="rounded-md w-full placeholder-pry-color shadow-md border-none"
                placeholder="Password"
                type="password"
                ref={passwordRef}
              />
            </div>
            <div className="mb-4">
              <input
                className="rounded-md w-full placeholder-pry-color shadow-md border-none"
                placeholder="Confirm Password"
                type="password"
                ref={confirmPasswordRef}
              />
            </div>
            <div className="my-2 h-6 flex items-center justify-center text-pry-color">
              <p>{response}</p>
            </div>
            <div className="mb-6">
              <button
                // onClick={signUpHandler}
                type="submit"
                className="bg-sec-color hover:bg-orange-400 px-8 text-white py-1 rounded-md shadow-md"
              >
                Sign Up
              </button>
            </div>
            <div className="text-sm text-pry-color">
              <p>
                Already a member?{" "}
                <Link
                  to="/login"
                  className="text-sec-color no-underline cursor-pointer font-semibold"
                >
                  Log In
                </Link>
              </p>
            </div>
          </form>{" "}
          <div className="hidden lg:flex ">
            <img src={Flight} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUpPage;
