import React, { useEffect, useState } from "react";

import SliderImage1 from "../images/slider1.webp";
import SliderImage2 from "../images/slider2.webp";
import SliderImage3 from "../images/slider3.webp";
import Carousel from "./Carousel";

function Header() {
  return (
    <div
      className=" bg-transparent relative duration-700"
      id="default-carousel"
      data-carousel="slide"
    >
      <Carousel/>
      {/* <div
        id="animation-carousel "
        className="cursor-pointer relative"
        data-carousel="slide"
       
      
      >
        <div  className={`carousel-wrapper `}>
          <div
            data-carousel-item="active"
            className="carousel-item-div duration-700 "
          >
            <img src={SliderImage1} className="img-style" alt="" />
          </div>
          <div data-carousel-item="active" className="carousel-item-div duration-700 ">
            <img src={SliderImage2} className="img-style" alt="" />
          </div>
          <div data-carousel-item="active" className="carousel-item-div duration-700 ">
            <img src={SliderImage3} className="img-style" alt="" />
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default Header;
