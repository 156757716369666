import React, { useEffect } from "react";
import AllCtx from "../../uti-functions/allCtx";
import Departure from "../../images/flight-take-off.png";
import Return from "../../images/landing-flight.png";
import Duration from "../../images/duration-icon.png";

function CheckFlightDetailsOrder() {
  const {
    checkInPassenger,
    setTheCheckInPassenger,
    flightRefID,
    adult,
    children,
    infant,
    tripType,
    flightArray,
    setTheFlightArray,
    chosenFlight,

    chosenClassName,
    chosenClassPrice,
    chosenClassTax, 
    chosenClassFees,
    chosenClassSurge,
    totalPrice,
    flightSeatArray, setThePassengerFlight,
    passengerFlight
  } = AllCtx();

  var date = new Date(passengerFlight.departure_date)
  console.log(passengerFlight);
  var weekDay = date.toLocaleDateString("en-US", { weekday: "short" })
  var month = date.toLocaleDateString("en-US", { day: "numeric" })
  var day = date.toLocaleDateString("en-US", { month: "short" })
  var year = date.toLocaleDateString("en-US", { year: "numeric" })
 

  return (
    <div className=" ">
      <p className="text-pry-color mb-3 font-semibold">Flight Details </p>

  
          
             {/* Flight details */}
            <div  className="  border-2 pl-2 rounded-md p-4">
              <div className=" mb-4 ">
                <div className="flex items-center space-x-3 mb-3">
                  <div className="hidden 400:block">
                    <img src={Departure} alt="" />
                  </div>
                  <p className="text-sm 450:text-base">Departure</p>{" "}
                  <div className="flex items-center justify-center">
                    {" "}
                    <p className="text-xs -mb-1   text-sec-color  ">
                      ({passengerFlight.class?.name})
                    </p>
                  </div>
                  <div>
                    <p className="text-xs 450:text-sm text-gray-500">
                      {passengerFlight.duration} journey
                    </p>
                  </div>
                </div>
                <div className="md:flex md:justify-between  pb-2 ">
                  <div className="flex mb-2 md:mb-0 justify-center">
                    <p className="font-bold text-lg text-pry-color sm:text-2xl md:text-sm lg:text-xl ">
                      {`${weekDay}, ${day} ${month} ${year}`}
                    </p>
                  </div>
                  <div className="flex justify-center">
                    <div className="mb-2 md:mb-0 flex items-center text-base sm:text-xl md:text-sm lg:text-xl space-x-3 ">
                      <div className="flex items-center   space-x-3 sm:space-x-5 md:space-x-4">
                        <div className="">
                          <p className="font-semibold">{passengerFlight.from}</p>
                        </div>
                        <div>
                          <img src={Duration} alt="" />
                        </div>
                        <div>
                          <p className="font-semibold">{passengerFlight.to}</p>
                        </div>{" "}
                      </div>{" "}
                    </div>
                  </div>{" "}
                  <div className="flex justify-center">
                    {" "}
                    <div className="flex text-gray-500 space-x-2 items-center text-base sm:text-xs lg:text-base">
                      {" "}
                      <div>
                        <p>
                          {" "}
                          {passengerFlight.departure_time} to {passengerFlight.arrival_time}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-between text-xs text-gray-400 mt-3">
                  {" "}
                  <div className="w-[40%]">
                    <p>{passengerFlight.departure_airport}</p>
                  </div>{" "}
                  <div>
                    <p className="text-sec-color">to</p>
                  </div>{" "}
                  <div className="w-[40%] text-right">
                    <p>{passengerFlight.arrival_airport}</p>
                  </div>{" "}
                </div>
                {tripType === "round" && <hr className="mt-5" />}
              </div>

              {tripType === "round" &&
                ((date = new Date(passengerFlight.return_departure_date)),
                (weekDay = date.toLocaleDateString("en-US", {
                  weekday: "short",
                })),
                (day = date.toLocaleDateString("en-US", { day: "numeric" })),
                (month = date.toLocaleDateString("en-US", { month: "short" })),
                (year = date.toLocaleDateString("en-US", { year: "numeric" })),
                (
                  <div className=" mb-4 ">
                    <div className="flex items-center space-x-2 mb-3">
                      <div className="hidden 400:block">
                        <img src={Return} alt="" />
                      </div>
                      <div>
                        {" "}
                        <p className="text-sm 450:text-base">Return</p>
                      </div>{" "}
                      <div className="flex items-center justify-center">
                        {" "}
                        <p className="text-xs  -mb-1   text-sec-color  ">
                          ({chosenClassName})
                        </p>
                      </div>
                      <div>
                        <p className="text-xs 450:text-sm text-gray-500">
                          {passengerFlight.duration} journey
                        </p>
                      </div>
                    </div>
                    <div className="md:flex md:justify-between  pb-2 ">
                      <div className="flex mb-2 md:mb-0 justify-center">
                        <p className="font-bold text-lg text-pry-color sm:text-2xl md:text-sm lg:text-xl ">
                          {`${weekDay}, ${day} ${month} ${year}`}
                        </p>
                      </div>
                      <div className="flex justify-center">
                        <div className="mb-2 md:mb-0 flex items-center text-base sm:text-xl md:text-sm lg:text-xl space-x-3 ">
                          <div className="flex items-center   space-x-3 sm:space-x-5 md:space-x-4">
                            <div className="">
                              <p className="font-semibold">{passengerFlight.to}</p>
                            </div>
                            <div>
                              <img src={Duration} alt="" />
                            </div>
                            <div>
                              <p className="font-semibold">{passengerFlight.from}</p>
                            </div>{" "}
                          </div>{" "}
                        </div>
                      </div>{" "}
                      <div className="flex justify-center">
                        {" "}
                        <div className="flex text-gray-500 space-x-2 items-center text-base sm:text-xs lg:text-base">
                          {" "}
                          <div>
                            <p>
                              {" "}
                              {passengerFlight.return_departure_time} to{" "}
                              {passengerFlight.return_arrival_time}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex justify-between text-xs text-gray-400 mt-3">
                      {" "}
                      <div className="w-[40%]">
                        <p>{passengerFlight.return_departure_airport}</p>
                      </div>{" "}
                      <div>
                        <p className="text-sec-color">back to</p>
                      </div>{" "}
                      <div className="w-[40%] text-right">
                        <p>{passengerFlight.return_arrival_airport}</p>
                      </div>{" "}
                    </div>
                  </div>
                ))}

              {flightRefID && (
                <div className="">
                  <div className=" px-1 rounded-full    py-2 bg-pry-color  shadow-md  ">
                    <div className="flex  justify-center items-center">
                      <p className="text-white  truncate font-semibold text-xs 450:text-base sm:text-lg lg:text-2xl md:text-lg">
                        {flightRefID || "No booking found."}
                      </p>
                    </div>
                  </div>
                  <div className="flex mt-1 justify-center items-center ">
                    <p className="text-justify text-sm">
                      Copy and save your Reference ID somewhere for checking in, managing booking,
                      and referencing purpose.
                    </p>
                  </div>
                </div>
              )}
            </div>
          
      

      {/* Payments */}
      <div className=" ">
        <div className="border-2 mt-3 rounded-md px-3 py-4">
          <div className="mb-3 border-2 space-y-3 px-2 py-2 rounded-md">
            <div className="flex justify-between">
              <p>Base Fare</p>
              <p>₦{passengerFlight.class?.cul_price}</p>
            </div>
            <div className="flex justify-between">
              <p>Surcharge</p>
              <p>₦{passengerFlight.class?.surcharges}</p>
            </div>
            <div className="flex justify-between">
              <p>Fees</p>
              <p>₦{passengerFlight.class?.fees}</p>
            </div>

            <div className="flex justify-between">
              <p>Tax</p>
              <p>₦{passengerFlight.class?.taxes}</p>
            </div>
          </div>

          <hr className="mb-3" />

        </div>
      </div>

      {/* Total Price  */}
      <div className="  border-2 pl-2 rounded-md p-4 mt-4">
        <div className="450:flex items-center flex-row-reverse justify-center 450:justify-evenly     ">
          <div className="flex justify-center">
            <p className="text-sm 450:text-base text-pry-color">
              {passengerFlight.class?.name}{" "}
            </p>
          </div>
          <div className="flex justify-center mt-4 450:mt-0">
            <p className="font-bold text-3xl">₦{passengerFlight.class?.total_price}</p>
          </div>
        </div>
        <div className="flex justify-center mt-1">
          <p className="text-sm text-gray-400">Total price paid</p>
        </div>
      </div>

      <div className="mt-4 border-2 p-4 rounded-md font-semibold ">
        <div className="flex justify-center mb-2">
          {" "}
          <p className="font-semibold text-sm ">Your Details</p>{" "}
        </div>
        <div className="flex">
          {" "}
          <div className="w-20">
            <p>NAME: </p>{" "}
          </div>{" "}
          <div>
            {" "}
            <span>{checkInPassenger.first_name?.toUpperCase()}</span>{" "}
            <span>{checkInPassenger.last_name?.toUpperCase()}</span>
          </div>{" "}
        </div>
        <div className="flex">
          {" "}
          <span className="w-20">GENDER: </span>{" "}
          <span>{checkInPassenger.gender?.toUpperCase()}</span>{" "}
        </div>
        {checkInPassenger.type === "adult" && (
          <div className="flex">
            {" "}
            <span className="w-20">PHONE - </span>{" "}
            <span>{checkInPassenger.c_phone}</span>{" "}
          </div>
        )}

        {checkInPassenger.type === "adult" && (
          <div className="flex">
            {" "}
            <span className="w-20">EMAIL - </span>{" "}
            <span>{checkInPassenger.c_email}</span>{" "}
          </div>
        )}
      </div>

      {/* Seat Keys  */}
      <div className="  border-2 pl-4 rounded-md p-4 mt-4">
        <div className="mb-4 flex justify-center">
          <p className="text-sm font-semibold">Seat Keys</p>
        </div>
        <div className="450:flex 450:justify-between mt-4 400:mt-0 ">
          <div className="mb-8 450:mb-4  flex">
            <div className="h-6 w-6 bg-gray-400 mr-3"></div>
            <p className="md:text-sm">Occupied</p>
          </div>{" "}
          <div className="flex mb-8 450:mb-4 ">
            <div className="h-6 w-6 bg-green-600 mr-3"></div>{" "}
            <p className="md:text-sm">Available</p>
          </div>
        </div>
        <div className="450:flex 450:justify-between 400:mt-4">
          {" "}
          <div className="flex  mb-8 450:mb-4">
            {" "}
            <div className="h-6 w-6 bg-pry-color mr-3"></div>{" "}
            <p className="md:text-sm">Chosen Seat</p>
          </div>{" "}
          <div className="flex ">
            {" "}
            <div className="h-6 w-6 bg-sec-color mr-3"></div>{" "}
            <p className="md:text-sm">Reserved</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckFlightDetailsOrder;
