import React, { useEffect } from "react";
import AllCtx from "../uti-functions/allCtx";
import CheckInFlightDetailsOrder from "../components/check-in/CheckInFlightDetailsOrder";
import SelectSeat from "../components/check-in/SelectSeat";
function CheckInPage() {
  const { setTheAdultArray, setTheCheckInPassenger, setTheChildrenArray, setTheInfantArray, setThePassengerFlight } = AllCtx();

  useEffect(() => {
    if (localStorage.getItem("infantArray")) {
      setTheInfantArray(JSON.parse(localStorage.getItem("infantArray")));
    }

    if (localStorage.getItem("childrenArray")) {
      setTheChildrenArray(JSON.parse(localStorage.getItem("childrenArray")));
    }
    if (localStorage.getItem("adultArray")) {
      setTheAdultArray(JSON.parse(localStorage.getItem("adultArray")));
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("passengerFlight")) {
      setThePassengerFlight(
        JSON.parse(localStorage.getItem("passengerFlight"))
      );
    }
  }, []); 

  useEffect(() => {
    if (localStorage.getItem("checkInPassenger")) {
      setTheCheckInPassenger(
        JSON.parse(localStorage.getItem("checkInPassenger"))
      );
    }
  }, []); 

  return (
    <div>
      <div className=" mb-10  services-header-bg text-2xl md:text-3xl lg:text-4xl">
        <p>Check In</p>
      </div>
      <div className="md:flex w-full md:space-x-2 ">
        <div className=" px-8 md:w-[60%] ">
          <CheckInFlightDetailsOrder />
        </div>
        <SelectSeat />
      </div>
    </div>
  );
}

export default CheckInPage;
