import React, { useRef } from "react";
import { useState } from "react";
import AllCtx from "../../uti-functions/allCtx";

function EditProfile() {
  const { user, setTheUser, userToken, baseUrl } = AllCtx();

  const [editingProfile, setEditingProfile] = useState(false);
  const [response, setResponse] = useState("");
  const [saving, setSaving] = useState(false);
  const [dobType, setDobType] = useState('text')

  // const firstNameRef = useRef();
  // const surnameRef = useRef();
  // const dobRef = useRef();
  const occupationRef = useRef();
  // const emailRef = useRef();
  // const phoneRef = useRef();
  // const countryRef = useRef();
  // const cityRef = useRef();

  function editProfile() {
    setEditingProfile(true);
  }

  function doneEditingProfile() {
    setEditingProfile(false);
  }

  async function saveProfile() {
    // const firstNameInput = firstNameRef.current.value;
    // const surnameInput = surnameRef.current.value;
    // const dobInput = dobRef.current.value;
    const occupationInput = occupationRef.current.value;
    // const emailInput = emailRef.current.value;
    // const phoneInput = phoneRef.current.value;
    // const countryInput = countryRef.current.value;
    // const cityInput = cityRef.current.value;

    if (
      !user.firstname ||
      user.firstname.trim() === "" ||
      !user.lastname ||
      user.lastname.trim() === "" ||
      !user.email ||
      user.email.trim() === ""
    ) {
      setResponse("Fill all inputs!");
      return;
    }

const userDetails = {
  _first_name: user.firstname,
  _last_name: user.lastname,
  _phone_number: user.phone_number,
  _dob: user.dob,
  _email: user.email,
  _city: user.city,
  _country: user.country,
  // occupation: occupationInput,
}

    try {
      console.log(userDetails);
      setSaving(true);
      setResponse("Saving...");

      const response = await fetch(
        `${baseUrl}/server/api/desunapi/v1/update_user`,
        {
          method: "POST",
          body: JSON.stringify(userDetails),
          headers: {
            "Content-Type": "application/json",
            Useremail: `${user.email}`,
            Usertoken: `${userToken}`,
          },
        }
      );

      const data = await response.json();
      console.log(data);

      if (!response.ok) {
        console.log("Something went wrong, retry!");
        setResponse("Something went wrong, retry!");
        setSaving(false);
         return
      }

      data.map((User) => {
        // setTheUserToken(User.token)
         setTheUser(User.user_info);
         console.log(User.user_info);
         localStorage.setItem("user", JSON.stringify(User.user_info));
     
       });

      console.log("Profile updated successfully!");
      setResponse("Profile updated successfully!");
      setSaving(false);

      doneEditingProfile();
    } catch (error) {
      console.log(error);
      setResponse("Error! Failed to update profile.!");
      setSaving(false);
    }
  }

  function handleChange(toChange, value) {
    let newUser = { ...user };
    newUser[toChange] = value;
    setTheUser(newUser);
  }



  return (
    <div className="w-[80%]">
      <div
        onClick={editProfile}
        disabled={editingProfile}
        className="cursor-pointer mb-4"
      >
        <p className="text-pry-color font-semibold ">Edit Profile</p>
      </div>

      <div
        disabled={!editingProfile}
        className="md:flex justify-between md:space-x-8 "
      >
        <div className="md:w-1/2 ">
          <div className="mb-2">
            <p className="text-sec-color">Personal Info</p>
          </div>
          <div className="flex space-x-2 mb-4 w-full text-xs 450:text-sm">
            <div className="w-1/2">
              <p className="font-semibold">First Name</p>
              <input
                onChange={(e) => {
                  handleChange("firstname", e.target.value)
                }}
             
                value={user.firstname}
                className="w-full h-8 border-gray-300 rounded-md"
                type="text"
                name=""
                id=""
              />
            </div>
            <div className="w-1/2">
              <p className="font-semibold">Surname</p>
              <input
                   onChange={(e) => {
                    handleChange("lastname", e.target.value)
                }}
                value={user.lastname}
                className="w-full h-8 border-gray-300 rounded-md"
                type="text"
                name=""
                id=""
              />
            </div>
          </div>
          <div className="w-full text-xs 450:text-sm mb-4">
            <p className="font-semibold">Date of Birth</p>
            <input
                   onChange={(e) => {
                handleChange("dob", e.target.value)
                }}
                value={user.dob}
              className="w-full h-8 border-gray-300 rounded-md"
              type={dobType}
              onFocus={() => {
                setDobType('date')
              }}
              onBlur={() => {
                setDobType('text')
              }}
              
              name=""
              id=""
            />
          </div>
          <div className="w-full text-xs 450:text-sm">
            <p className="font-semibold">Occupation</p>
            <input
                  // onChange={(e) => {
                  //   handleChange("occupation", e.target.value)
                  //   }}
                  //   value={user.occupation}
              ref={occupationRef}
              className="w-full h-8 border-gray-300 rounded-md"
              type="text"
              name=""
              id=""
            />
          </div>
        </div>

        <div className="md:w-1/2 ">
          <div className="mb-2">
            <p className="text-sec-color">Contact Info</p>
          </div>

          <div className="w-full text-xs 450:text-sm mb-4">
            <p className="font-semibold">Email</p>
            <input disabled 
                  onChange={(e) => {
                    handleChange("email", e.target.value)
                    }}
                    value={user.email}
              className="bg-gray-200 w-full h-8 border-gray-300 rounded-md"
              type="text"
              name=""
              id=""
            />
          </div>
          <div className="w-full text-xs 450:text-sm mb-4">
            <p className="font-semibold">Phone Number</p>
            <input
                  onChange={(e) => {
                    handleChange("phone_number", e.target.value)
                    }}
                    value={user.phone_number}
              className="w-full h-8 border-gray-300 rounded-md"
              type="number"
              name=""
              id=""
            />
          </div>
          <div className="flex space-x-2 mb-4 w-full text-xs 450:text-sm">
            <div className="w-1/2">
              <p className="font-semibold">Country</p>
              <input
                    onChange={(e) => {
                      handleChange("country", e.target.value)
                      }}
                      value={user.country}
                className="w-full h-8 border-gray-300 rounded-md"
                type="text"
                name=""
                id=""
              />
            </div>
            <div className="w-1/2">
              <p className="font-semibold">City</p>
              <input
                    onChange={(e) => {
                      handleChange("city", e.target.value)
                      }}
                      value={user.city}
                className="w-full h-8 border-gray-300 rounded-md"
                type="text"
                name=""
                id=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center h-8 ">
        <p className="text-sec-color">{response}</p>
      </div>

      <div onClick={saveProfile} disabled={!editingProfile} className="mt-2">
        <button className="bg-sec-color text-sm px-5 rounded-md shadow-md text-white py-1">
          Save
        </button>
      </div>
    </div>
  );
}

export default EditProfile;
