import "./styles/App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from "./pages/Homepage";
import TrainingPage from "./pages/TrainingPage";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import { useEffect, useState } from "react";

import BookingDropdown from "./components/dropdowns/BookingDropdown";
import FlyDropdown from "./components/dropdowns/FlyDropdown";
import AboutUsDropdown from "./components/dropdowns/AboutUsDropdown";
import DestinationDropdown from "./components/dropdowns/DestinationDropdown";
import MobileNav from "./components/MobileNav";
import PrivateHirePage from "./pages/PrivateHirePage";
import RepairsPage from "./pages/RepairsPage";
import CargoPage from "./pages/CargoPage";
import PurchasePartsPage from "./pages/PurchasePartsPage";
import AboutPage from "./pages/AboutPage";
import Contact from "./pages/Contact";
import ServicesDropdown from "./components/dropdowns/ServicesDropdown";
import SearchResultPage from "./pages/SearchResultPage";
import DestinationDetails from "./pages/DestinationDetails";
import AllCtx from "./uti-functions/allCtx";
import BookingPage from "./pages/BookingPage";
import ManageBookingPage from "./pages/ManageBookingPage";
import VerifyBookingPage from "./pages/VerifyBookingPage";
import LoginPage from "./pages/LoginPage";
import SignUpPage from "./pages/SignUpPage";
import FaqPage from "./pages/FaqPage";
import { useNavigate } from "react-router-dom";
import LogOutNotification from "./components/notifications/Notification";
import ProfilePage from "./pages/ProfilePage";
import ForgotPassword from "./pages/ForgotPassword";
import TestPage from "./pages/TestPage";
import ResetPassword from "./pages/ResetPassword";
import CheckInPage from "./pages/CheckInPage";
import PaymentPage from "./pages/PaymentPage";
import SuccessPayment from "./pages/SuccessPayment";
import FailedPaymentVerification from "./pages/FailedPaymentVerification";
import SuccessCheckIn from "./pages/SuccessCheckIn";
import FlightStatusPage from "./pages/FlightStatusPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import NotFound from "./components/NotFound";


function App() {
  // let navigate = useNavigate();

  
  const [mobileNavClicked, setMobileNavClicked] = useState(false);
  const [homeHover, setHomeHover] = useState(false);
  const [bookingHover, setBookingHover] = useState(false);
  const [flyHover, setFlyHover] = useState(false);
  const [aboutHover, setAboutHover] = useState(false);
  const [servicesHover, setServicesHover] = useState(false);
  const [destinationHover, setDestinationHover] = useState(false);
      const [signingOut, setSigningOut] = useState(false);
 

  const {
    setTheFlightRefID,
    adult,
    tripType,
    from,
    to,
    displayedFrom,
    displayedTo,
    departureDate,
    returnDate,
    children,
    infant,
    chosenClassName,
    chosenClassPrice,

    setTheChosenClassPrice,
    setTheChosenClassName,
    setTheTripType,
    setTheRoundTrip,
    setTheAdult,
    setTheFrom,
    setTheTo,
    setTheChildren,
    setTheDepartureDate,
    setTheReturnDate,
    setTheDisplayedFrom,
    setTheDisplayedTo,
    setTheInfant,
    setTheChosenFlight,
    setTheFlightArray,
    retrieveSavedChosenFlight,
    setTheIsLoggedIn,
    setTheUser,
    user, setTheUserToken, userToken
  } = AllCtx();

  if (localStorage.getItem('lastLoggedIn')) {
    
    if ((Date.now() - JSON.parse( localStorage.getItem('lastLoggedIn'))) >= 3600000) {
      localStorage.removeItem("user");
      localStorage.removeItem("userToken");
      localStorage.removeItem("individualRefID");
      localStorage.removeItem("flightStatus");
      localStorage.removeItem("checkInPassenger");
      localStorage.removeItem("flightRefID");
      localStorage.removeItem("passengerFlight");
      localStorage.removeItem("lastLoggedIn");
      setTheIsLoggedIn(false);
      console.log("Logged out");
      // navigate("/")
    
  } 
  }

  useEffect(() => {
    if (localStorage.getItem('userToken')) {
      setTheIsLoggedIn(true)
      setTheUser(JSON.parse(localStorage.getItem("user")))
      setTheUserToken(JSON.parse(localStorage.getItem("userToken")))
    } else {
      setTheIsLoggedIn(false);
      localStorage.removeItem('user')
    
  }
},[])


  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 0) {
        hoverNone();
      }
    });
  }, []);

  useEffect(() => {
    if (localStorage.getItem("flightRefID")) {
    setTheFlightRefID(JSON.parse(localStorage.getItem("flightRefID")))
  }
},[])

  useEffect(() => {
    if (localStorage.getItem("flightArray")) {
      setTheFlightArray(JSON.parse(localStorage.getItem("flightArray")));
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("chosenClassPrice")) {
      setTheChosenClassPrice(
        JSON.parse(localStorage.getItem("chosenClassPrice"))
      );
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("chosenClassName")) {
      setTheChosenClassName(
        JSON.parse(localStorage.getItem("chosenClassName"))
      );
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("chosenFlight")) {
      retrieveSavedChosenFlight(
        JSON.parse(localStorage.getItem("chosenFlight"))
      );
    }
  }, []);

  // useEffect(() => {
  //   if (localStorage.getItem("departureDate")) {
  //     setTheDepartureDate(JSON.parse(localStorage.getItem("departureDate")));
  //   }
  // }, []);

  // useEffect(() => {
  //   if (localStorage.getItem("returnDate")) {
  //     setTheReturnDate(JSON.parse(localStorage.getItem("returnDate")));
  //   }
  // }, []);

  // useEffect(() => {
  //   if (localStorage.getItem("infant")) {
  //     setTheInfant(JSON.parse(localStorage.getItem("infant")));
     
  //   }
  // }, []);

  // useEffect(() => {
  //   if (localStorage.getItem("children")) {
  //     setTheChildren(JSON.parse(localStorage.getItem("children")));
  //   }
  // }, []);

  // useEffect(() => {
  //   if (localStorage.getItem("displayedTo")) {
  //     setTheDisplayedTo(JSON.parse(localStorage.getItem("displayedTo")));
  //   }
  // }, []);

  // useEffect(() => {
  //   if (localStorage.getItem("displayedFrom")) {
  //     setTheDisplayedFrom(JSON.parse(localStorage.getItem("displayedFrom")));
  //   }
  // }, []);

  // useEffect(() => {
  //   if (localStorage.getItem("to")) {
  //     setTheTo(JSON.parse(localStorage.getItem("to")));
  //   }
  // }, []);

  // useEffect(() => {
  //   if (localStorage.getItem("from")) {
  //     setTheFrom(JSON.parse(localStorage.getItem("from")));
  //   }
  // }, []);

  // useEffect(() => {
  //   if (localStorage.getItem("tripType")) {
  //     setTheTripType(JSON.parse(localStorage.getItem("tripType")));
  //   }
  // }, []);

  // useEffect(() => {
  //   if (localStorage.getItem("adult")) {
  //     setTheAdult(JSON.parse(localStorage.getItem("adult")));
  //   }
  // }, []);



  function toggleMobileNav() {
    setMobileNavClicked(!mobileNavClicked);
  }

  function hoverHome() {
    setHomeHover(true);
    setBookingHover(false);
    setFlyHover(false);
    setAboutHover(false);
    setServicesHover(false);
    setDestinationHover(false);
  }

  function hoverBooking() {
    setHomeHover(false);
    setBookingHover(true);
    setFlyHover(false);
    setAboutHover(false);
    setServicesHover(false);
    setDestinationHover(false);
  }

  function hoverFly() {
    setHomeHover(false);
    setBookingHover(false);
    setFlyHover(true);
    setAboutHover(false);
    setServicesHover(false);
    setDestinationHover(false);
  }

  function hoverAbout() {
    setHomeHover(false);
    setBookingHover(false);
    setFlyHover(false);
    setAboutHover(true);
    setServicesHover(false);
    setDestinationHover(false);
  }

  function hoverServices() {
    setHomeHover(false);
    setBookingHover(false);
    setFlyHover(false);
    setAboutHover(false);
    setServicesHover(true);
    setDestinationHover(false);
  }

  function hoverDestination() {
    setHomeHover(false);
    setBookingHover(false);
    setFlyHover(false);
    setAboutHover(false);
    setServicesHover(false);
    setDestinationHover(true);
  }

  function hoverNone() {
    setHomeHover(false);
    setBookingHover(false);
    setFlyHover(false);
    setAboutHover(false);
    setServicesHover(false);
    setDestinationHover(false);
  }

  return (
    // (console.log(user)),
    <BrowserRouter>
      <Navbar
        hoverHome={hoverHome}
        homeHover={homeHover}
        hoverBooking={hoverBooking}
        bookingHover={bookingHover}
        hoverFly={hoverFly}
        hoverAbout={hoverAbout}
        aboutHover={aboutHover}
        flyHover={flyHover}
        hoverServices={hoverServices}
        servicesHover={servicesHover}
        hoverDestination={hoverDestination}
        destinationHover={destinationHover}
        mobileNavClicked={mobileNavClicked}
        toggleMobileNav={toggleMobileNav}
      />
      {
        <MobileNav
          hoverNone={hoverNone}
          toggleMobileNav={toggleMobileNav}
          mobileNavClicked={mobileNavClicked}
        />
      }
      {  <LogOutNotification/> }
      {bookingHover && <BookingDropdown hoverNone={hoverNone} />}
      {flyHover && <FlyDropdown hoverNone={hoverNone} />}
      {aboutHover && <AboutUsDropdown hoverNone={hoverNone} />}
      {servicesHover && <ServicesDropdown hoverNone={hoverNone} />}
      {destinationHover && <DestinationDropdown hoverNone={hoverNone} />}

      <Routes>
        <Route exact path="/" element={<Homepage />} />

        <Route path="/services/training" element={<TrainingPage />} />
        <Route path="/services/private-hire" element={<PrivateHirePage />} />
        <Route path="/services/repairs" element={<RepairsPage />} />
        <Route path="/services/cargo" element={<CargoPage />} />
        <Route
          path="/services/purchase-parts"
          element={<PurchasePartsPage />}
        />
        <Route path="/destination/:cityID" element={<DestinationDetails />} />

        <Route path="/about" element={<AboutPage />} />


        <Route path="/contact" element={<Contact />} />
        <Route path="/booking" element={<BookingPage />} />
        <Route path="/manage-booking" element={<ManageBookingPage />} />
        <Route path="/search-results" element={<SearchResultPage />} />
        <Route path="/faq" element={<FaqPage />} />
        <Route path="/verify-booking" element={<VerifyBookingPage/> } />
        <Route path="/login" element={<LoginPage/> } />
        <Route path="/sign-up" element={<SignUpPage/> } />
        <Route path="/profile" element={<ProfilePage/> } />
        <Route path="/forgot-password" element={<ForgotPassword/> } />
        <Route path="/reset-password" element={<ResetPassword/> } />
        <Route path="/check-in" element={<CheckInPage/> } />
        {/* <Route path="/payment" element={<PaymentPage/> } /> */}
        <Route path="/success" element={<SuccessPayment/> } />
        <Route path="/failed-payment-verification" element={<FailedPaymentVerification/> } />
        <Route path="/success-check-in" element={<SuccessCheckIn/> } />
        <Route path="/flight-status" element={<FlightStatusPage/> } />
        {/* <Route path="/test" element={<TestPage/> } /> */}
        <Route path="/privacy-policy" element={<PrivacyPolicy/> } />
        <Route path="*" element={<NotFound/> } />
      </Routes>

      <Footer />
    </BrowserRouter>
  );
}

export default App;
