import React, { useEffect } from "react";
import AllCtx from "../../uti-functions/allCtx";
import Departure from "../../images/flight-take-off.png";
import Return from "../../images/landing-flight.png";
import Duration from "../../images/duration-icon.png";

function StatusFlightDetails() {
  const {
    checkInPassenger,
    setTheCheckInPassenger,
    flightRefID,
    adult,
    children,
    infant,
    tripType,
    flightArray,
    setTheFlightArray,
    passengerFlight,
    chosenFlightID,
    setTheChosenClass,
    setTheChosenClassPrice,
    chosenClass,
    chosenClassName,
    chosenClassPrice,
    setTheChosenFlight,
    setTheChosenFlightID,
    searchBookingResponse,
    setTheSearchBookingResponse,
    setTheChosenClassName,
    setTheClassChosen,
    classChosen,
    setTheSearchResultResponse,
    searchResultResponse,
    adultArray,
    childrenArray,
    infantArray,
    allPassengers,
    setTheAllPassengers,
    chosenClassTax,
    chosenClassFees,
    chosenClassSurge,
    totalPrice,
    flightSeatArray,
    setThePassengerFlight,
    
  } = AllCtx();

  // useEffect(() => {
  //   if (localStorage.getItem("passengerFlight")) {
  //     setThePassengerFlight( 
  //       JSON.parse(localStorage.getItem("passengerFlight"))
  //     );
  //   }
  // }, []); 

  console.log(passengerFlight);

  var date = new Date(passengerFlight.departure_date)
  console.log(passengerFlight);
  var weekDay = date.toLocaleDateString("en-US", { weekday: "short" })
  var month = date.toLocaleDateString("en-US", { day: "numeric" })
  var day = date.toLocaleDateString("en-US", { month: "short" })
  var year = date.toLocaleDateString("en-US", { year: "numeric" })


  return (
    <div className=" ">
      <p className="text-pry-color mb-3 font-semibold">Flight Details </p>

             {/* Flight details */}
            <div  className="  border-2 pl-2 rounded-md p-4">
              <div className=" mb-4 ">
                <div className="flex items-center space-x-3 mb-3">
                  <div className="hidden 400:block">
                    <img src={Departure} alt="" />
                  </div>
                  <p className="text-sm 450:text-base">Departure</p>{" "}
                  <div className="flex items-center justify-center">
                    {" "}
                    <p className="text-xs -mb-1   text-sec-color  ">
                      ({chosenClassName})
                    </p>
                  </div>
                  <div>
                    <p className="text-xs 450:text-sm text-gray-500">
                      {passengerFlight.duration} journey
                    </p>
                  </div>
                </div>
                <div className="md:flex md:justify-between  pb-2 ">
                  <div className="flex mb-2 md:mb-0 justify-center">
                    <p className="font-bold text-lg text-pry-color sm:text-2xl md:text-sm lg:text-xl ">
                      {`${weekDay}, ${day} ${month} ${year}`}
                    </p>
                  </div>
                  <div className="flex justify-center">
                    <div className="mb-2 md:mb-0 flex items-center text-base sm:text-xl md:text-sm lg:text-xl space-x-3 ">
                      <div className="flex items-center   space-x-3 sm:space-x-5 md:space-x-4">
                        <div className="">
                          <p className="font-semibold">{passengerFlight.from}</p>
                        </div>
                        <div>
                          <img src={Duration} alt="" />
                        </div>
                        <div>
                          <p className="font-semibold">{passengerFlight.to}</p>
                        </div>{" "}
                      </div>{" "}
                    </div>
                  </div>{" "}
                  <div className="flex justify-center">
                    {" "}
                    <div className="flex text-gray-500 space-x-2 items-center text-base sm:text-xs lg:text-base">
                      {" "}
                      <div>
                        <p>
                          {" "}
                          {passengerFlight.departure_time} to {passengerFlight.arrival_time}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-between text-xs text-gray-400 mt-3">
                  {" "}
                  <div className="w-[40%]">
                    <p>{passengerFlight.departure_airport}</p>
                  </div>{" "}
                  <div>
                    <p className="text-sec-color">to</p>
                  </div>{" "}
                  <div className="w-[40%] text-right">
                    <p>{passengerFlight.arrival_airport}</p>
                  </div>{" "}
                </div>
                {tripType === "round" && <hr className="mt-5" />}
              </div>

              {tripType === "round" &&
          (
            (date = new Date(passengerFlight.return_departure_date)),
                (weekDay = date.toLocaleDateString("en-US", {
                  weekday: "short",
                })),
                (day = date.toLocaleDateString("en-US", { day: "numeric" })),
                (month = date.toLocaleDateString("en-US", { month: "short" })),
                (year = date.toLocaleDateString("en-US", { year: "numeric" })),
                (
                  <div className=" mb-4 ">
                    <div className="flex items-center space-x-2 mb-3">
                      <div className="hidden 400:block">
                        <img src={Return} alt="" />
                      </div>
                      <div>
                        {" "}
                        <p className="text-sm 450:text-base">Return</p>
                      </div>{" "}
                      <div className="flex items-center justify-center">
                        {" "}
                        <p className="text-xs  -mb-1   text-sec-color  ">
                          ({chosenClassName})
                        </p>
                      </div>
                      <div>
                        <p className="text-xs 450:text-sm text-gray-500">
                          {passengerFlight.duration} journey
                        </p>
                      </div>
                    </div>
                    <div className="md:flex md:justify-between  pb-2 ">
                      <div className="flex mb-2 md:mb-0 justify-center">
                        <p className="font-bold text-lg text-pry-color sm:text-2xl md:text-sm lg:text-xl ">
                          {`${weekDay}, ${day} ${month} ${year}`}
                        </p>
                      </div>
                      <div className="flex justify-center">
                        <div className="mb-2 md:mb-0 flex items-center text-base sm:text-xl md:text-sm lg:text-xl space-x-3 ">
                          <div className="flex items-center   space-x-3 sm:space-x-5 md:space-x-4">
                            <div className="">
                              <p className="font-semibold">{passengerFlight.to}</p>
                            </div>
                            <div>
                              <img src={Duration} alt="" />
                            </div>
                            <div>
                              <p className="font-semibold">{passengerFlight.from}</p>
                            </div>{" "}
                          </div>{" "}
                        </div>
                      </div>{" "}
                      <div className="flex justify-center">
                        {" "}
                        <div className="flex text-gray-500 space-x-2 items-center text-base sm:text-xs lg:text-base">
                          {" "}
                          <div>
                            <p>
                              {" "}
                              {passengerFlight.return_departure_time} to{" "}
                              {passengerFlight.return_arrival_time}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex justify-between text-xs text-gray-400 mt-3">
                      {" "}
                      <div className="w-[40%]">
                        <p>{passengerFlight.return_departure_airport}</p>
                      </div>{" "}
                      <div>
                        <p className="text-sec-color">back to</p>
                      </div>{" "}
                      <div className="w-[40%] text-right">
                        <p>{passengerFlight.return_arrival_airport}</p>
                      </div>{" "}
                    </div>
                  </div>
                ))}

     
            </div>
          
        
 

 



      <div className="mt-4 border-2 p-4 rounded-md font-semibold ">
        <div className="flex justify-center mb-2">
          {" "}
          <p className="font-semibold text-sm ">Passenger Details</p>{" "}
        </div>
        <div className="flex">
          {" "}
          <div className="w-20">
            <p>NAME: </p>{" "}
          </div>{" "}
          <div>
            {" "}
            <span>{checkInPassenger.first_name?.toUpperCase()}</span>{" "}
            <span>{checkInPassenger.last_name?.toUpperCase()}</span>
          </div>{" "}
        </div>
        <div className="flex">
          {" "}
          <span className="w-20">GENDER: </span>{" "}
          <span>{checkInPassenger.gender?.toUpperCase()}</span>
        </div> 
        <div className="flex">
          {" "}
          <span className="w-20">SEAT: </span>{" "}
          <span>{checkInPassenger.seat_number?.toUpperCase()}</span>
        </div>
        {checkInPassenger.type === "adult" && (
          <div className="flex">
            {" "}
            <span className="w-20">PHONE - </span>{" "}
            <span>{checkInPassenger.c_phone}</span>{" "}
          </div>
        )}

        {checkInPassenger.type === "adult" && (
          <div className="flex">
            {" "}
            <span className="w-20">EMAIL - </span>{" "}
            <span>{checkInPassenger.c_email}</span>{" "}
          </div>
        )}
      </div>


    </div>
  );
}

export default StatusFlightDetails;
