import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import AboutUSImage from "../../images/about-us.png";
import LilFlightIcon3 from "../../images/lil-flight-icon3.png";
import RightArrow from "../../images/right-arrow.png";

function AboutUsDropdown(props) {
  return (
    <div onMouseLeave={props.hoverNone} className="about-whole-div fixed top-[4.7rem] z-30">
      <div className="about-img-div ">
        <img src={AboutUSImage} alt="" />
      </div>
      <div className="about-right-div">
        <div className="space-y-5 w-full">
          <div onClick={props.hoverNone} className="about-right-div-top cursor-pointer">
            <div>
              <img src={LilFlightIcon3} alt="" />
            </div>
            <Link className='no-underline text-pry-color' to='/about'>
              <p className='hover:text-sec-color'>About Us</p>
            </Link>
          </div>
          <div className="about-right-div-mid">
            {/* <div onClick={props.hoverNone} className=" w-1/3 cursor-pointer text-sec-color flex items-center space-x-2">
            <Link className='no-underline text-pry-color' to='/about'>
              <p>Desun Air</p>
            </Link>
              <div>
                <img onClick={props.hoverNone} src={RightArrow} alt="" />
              </div>
            </div> */}
            <div onClick={props.hoverNone} className=" w-1/3 cursor-pointer flex items-center space-x-2">
              <Link className='no-underline text-pry-color' to='/contact'>
              <p className="text-xs font-semibold hover:text-sec-color">Contact Us</p>
            </Link>
              <div>
                <img  src={RightArrow} alt="" />
              </div>
            </div>
            <div onClick={props.hoverNone} className=" w-1/3 cursor-pointer flex items-center space-x-2">
            <Link className='no-underline text-pry-color' to='/privacy-policy'>
              <p className="text-xs font-semibold hover:text-sec-color">Privacy Policy</p>
            </Link>
              <div>
                <img  src={RightArrow} alt="" />
              </div>
            </div>
            <div onClick={props.hoverNone} className=" w-1/3 cursor-pointer flex items-center space-x-2">
            <Link className='no-underline text-pry-color' to='/faq'>
              <p className="text-xs font-semibold hover:text-sec-color">FAQ</p>
            </Link>
              <div>
                <img  src={RightArrow} alt="" />
              </div>
            </div>
          </div>
          <div className="about-right-div-bottom">
            {/* <div onClick={props.hoverNone} className=" w-1/3 cursor-pointer flex items-center space-x-2">
            <Link className='no-underline text-pry-color' to='/'>
              <p>Mission &amp; Vision</p>
            </Link>
              <div>
                <img  src={RightArrow} alt="" />
              </div>
            </div> */}
            {/* <div onClick={props.hoverNone} className=" w-1/3 cursor-pointer flex items-center space-x-2">
            <Link className='no-underline text-pry-color' to='/'>
              <p>Support</p>
            </Link>
              <div>
                <img  src={RightArrow} alt="" />
              </div>
            </div> */}
      
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUsDropdown;
