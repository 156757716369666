import React, { useEffect } from "react";
import AllCtx from "../../uti-functions/allCtx";
import Departure from "../../images/flight-take-off.png";
import Return from "../../images/landing-flight.png";
import Duration from "../../images/duration-icon.png";

function VerifyFlightDetailsOrder() {
  const {
    flightRefID,
    setTheFlightRefID,
    adult,
    children,
    infant,
    tripType,
    flightArray,
    setTheFlightArray,
    chosenFlight,
    chosenFlightID,
    setTheChosenClass,
    setTheChosenClassPrice,
    chosenClass,
    chosenClassName,
    chosenClassPrice,
    setTheChosenFlight,
    setTheChosenFlightID,
    searchBookingResponse,
    setTheSearchBookingResponse,
    setTheChosenClassName,
    setTheClassChosen,
    classChosen,
    setTheSearchResultResponse,
    searchResultResponse,
    adultArray,
    childrenArray,
    infantArray,
    allPassengers,
    setTheAllPassengers,
  } = AllCtx();

  var date;
  var weekDay;
  var month;
  var day;
  var year;

  return (
    <div className=" ">
      <p className="text-pry-color mb-3 font-semibold">Flight Details Order</p>

      {chosenFlight.map(
        (flight) => (
          (date = new Date(flight.departure_date)),
          (weekDay = date.toLocaleDateString("en-US", { weekday: "short" })),
          (day = date.toLocaleDateString("en-US", { day: "numeric" })),
          (month = date.toLocaleDateString("en-US", { month: "short" })),
          (year = date.toLocaleDateString("en-US", { year: "numeric" })),
          (
            <div key={flight.ID} className="  border-2 pl-2 rounded-md p-4">
              <div className=" mb-4 ">
                <div className="flex items-center space-x-3 mb-3">
                  <div className="hidden 400:block">
                    <img src={Departure} alt="" />
                  </div>
                  <p className="text-sm 450:text-base">Departure</p>{" "}
                  <div className="flex items-center justify-center">
                    {" "}
                    <p className="text-xs -mb-1   text-sec-color  ">
                      ({chosenClassName})
                    </p>
                  </div>
                  <div>
                    <p className="text-xs 450:text-sm text-gray-500">
                      {flight.duration} journey
                    </p>
                  </div>
                </div>
                <div className="md:flex md:justify-between  pb-2 ">
                  <div className="flex mb-2 md:mb-0 justify-center">
                    <p className="font-bold text-lg text-pry-color sm:text-2xl md:text-sm lg:text-xl ">
                      {`${weekDay}, ${day} ${month} ${year}`}
                    </p>
                  </div>
                  <div className="flex justify-center">
                    <div className="mb-2 md:mb-0 flex items-center text-base sm:text-xl md:text-sm lg:text-xl space-x-3 ">
                      <div className="flex items-center   space-x-3 sm:space-x-5 md:space-x-4">
                        <div className="">
                          <p className="font-semibold">{flight.from}</p>
                        </div>
                        <div>
                          <img src={Duration} alt="" />
                        </div>
                        <div>
                          <p className="font-semibold">{flight.to}</p>
                        </div>{" "}
                      </div>{" "}
                    </div>
                  </div>{" "}
                  <div className="flex justify-center">
                    {" "}
                    <div className="flex text-gray-500 space-x-2 items-center text-base sm:text-xs lg:text-base">
                      {" "}
                      <div>
                        <p>
                          {flight.departure_time} to {flight.arrival_time}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex   justify-between text-xs text-gray-400 mt-3">
                  {" "}
                  <div className="w-[40%]">
                    <p>{flight.departure_airport}</p>
                  </div>{" "}
                  <div>
                    <p className="text-sec-color"> to</p>
                  </div>{" "}
                  <div className="w-[40%] text-right">
                    <p>{flight.arrival_airport}</p>
                  </div>{" "}
                </div>

                {tripType === "round" && <hr className="mt-5" />}
              </div>

              {tripType === "round" &&
                ((date = new Date(flight.return_departure_date)),
                (weekDay = date.toLocaleDateString("en-US", {
                  weekday: "short",
                })),
                (day = date.toLocaleDateString("en-US", { day: "numeric" })),
                (month = date.toLocaleDateString("en-US", { month: "short" })),
                (year = date.toLocaleDateString("en-US", { year: "numeric" })),
                (
                  <div className=" mb-4 ">
                    <div className="flex items-center space-x-2 mb-3">
                      <div className="hidden 400:block">
                        <img src={Return} alt="" />
                      </div>
                      <div>
                        {" "}
                        <p className="text-sm 450:text-base">Return</p>
                      </div>{" "}
                      <div className="flex items-center justify-center">
                        {" "}
                        <p className="text-xs  -mb-1   text-sec-color  ">
                          ({chosenClassName})
                        </p>
                      </div>
                      <div>
                        <p className="text-xs 450:text-sm text-gray-500">
                          {flight.duration} journey
                        </p>
                      </div>
                    </div>
                    <div className="md:flex md:justify-between  pb-2 ">
                      <div className="flex mb-2 md:mb-0 justify-center">
                        <p className="font-bold text-lg text-pry-color sm:text-2xl md:text-sm lg:text-xl ">
                          {`${weekDay}, ${day} ${month} ${year}`}
                        </p>
                      </div>
                      <div className="flex justify-center">
                        <div className="mb-2 md:mb-0 flex items-center text-base sm:text-xl md:text-sm lg:text-xl space-x-3 ">
                          <div className="flex items-center   space-x-3 sm:space-x-5 md:space-x-4">
                            <div className="">
                              <p className="font-semibold">{flight.to}</p>
                            </div>
                            <div>
                              <img src={Duration} alt="" />
                            </div>
                            <div>
                              <p className="font-semibold">{flight.from}</p>
                            </div>{" "}
                          </div>{" "}
                        </div>
                      </div>{" "}
                      <div className="flex justify-center">
                        {" "}
                        <div className="flex text-gray-500 space-x-2 items-center text-base sm:text-xs lg:text-base">
                          {" "}
                          <div>
                            <p>
                              {flight.return_departure_time} to{" "}
                              {flight.return_arrival_time}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex justify-between text-xs text-gray-400 mt-3">
                      {" "}
                      <div className="w-[40%]">
                        <p>{flight.return_departure_airport}</p>
                      </div>{" "}
                      <div>
                        <p className="text-sec-color">back to</p>
                      </div>{" "}
                      <div className="w-[40%] text-right">
                        <p>{flight.return_arrival_airport}</p>
                      </div>{" "}
                    </div>
                  </div>
                ))}
              {flightRefID && (
                <div className="">
                  <div className=" px-1 rounded-full    py-2 bg-pry-color  shadow-md  ">
                    <div className="flex  justify-center items-center">
                      <p className="text-white  truncate font-semibold text-xs 450:text-base sm:text-lg lg:text-2xl md:text-lg">
                        {flightRefID || "No booking found."}
                      </p>
                    </div>
                  </div>
                  <div className="flex mt-1 justify-center items-center ">
                    <p className="text-justify text-sm">
                    Copy and save your Reference ID somewhere for checking in, managing booking,
                      and referencing purpose.
                    </p>
                  </div>
                </div>
              )}
            </div>
          )
        )
      )}
    </div>
  );
}

export default VerifyFlightDetailsOrder;
