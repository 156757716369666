import React from "react";
import { HashLink } from "react-router-hash-link";
import Cargo from "../images/cargo-page.webp";
import Appointment from "../images/join-us-icon.png";

function CargoPage() {
  return (
    <div className="  bg-mid-color pb-3">
      <div className="services-header-bg text-2xl md:text-3xl lg:text-4xl">
        <p>Cargo</p>{" "}
      </div>

      <div className="md:flex  px-8 md:space-x-7 pt-5 md:items-center">
        <div className="md:w-1/2 ">
          <img src={Cargo} alt="" />
        </div>
        <div className="md:w-1/2">
          <h2 className="text-pry-color font-bold  mb-4 text-3xl">
            <span className="text-sec-color">Desun Air</span> Cargo
          </h2>
          <p className="text-justify text-pry-color text-xl md:leading-10 leading-relaxed">
            Desun Airlines Limited shall provide safe, affordable and efficient
            aviation services for transport of passengers, mails and cargoes for
            commercial and other purposes.
          </p>
          <HashLink
            to="/#services"
            className="no-underline text-black flex items-center space-x-3 my-8 text-sm "
          >
            <p className="cursor-pointer font-semibold hover:text-sec-color">
              Back to Services
            </p>{" "}
            <img className="h-6 w-6 cursor-pointer" src={Appointment} alt="" />
          </HashLink>
        </div>
      </div>
    </div>
  );
}

export default CargoPage;
